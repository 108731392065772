import React, { useState, useEffect } from 'react';
import { Modal, Backdrop, Fade, Button, TextField, Autocomplete, InputAdornment, IconButton } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useFormik } from "formik";
import { Operationvalidation } from "../../../helper/schemas";
import axios from "../../../helper/axios";
import { useUser } from "../../../Provider";
import { useParams } from 'react-router-dom/cjs/react-router-dom';
import { ToastContainer, toast } from 'react-toastify'
const initialValues = {
  date: "",
  time: "",
  medicinecategory: "",
  medicinecode: "",
  dosage: "",
  remarks: "",
};
const UpdateIpdMedication = ({ medicationData, onClose }) => {
  const { user, userHospitalcode, userrole1, isAuthRole, isAuthorized, gettokenId, token } = useUser();
  const [isFormVisible, setFormVisibility] = useState(false);
  const { admitnumber } = useParams();
  console.log({ admitnumber })
  const [data, setData] = useState([]);
  const [category1, setCategory] = useState([]);
  const [openAutocompleted, setOpenAutocompleted] = useState(false);
  const [openAutocomplete, setOpenAutocomplete] = useState(false);
  let Showdate = new Date();
  let ShowTodaydate =
    Showdate.getFullYear() +
    "-" +
    (Showdate.getMonth() + 1) +
    "-" +
    Showdate.getDate();
  const [currentDate, setCurrentDate] = useState(ShowTodaydate);

  const loadHospitalcode = async () => {
    //when userHospitalcode and userrole1 is not undefined then execute /gethospitalcode
    if (userHospitalcode !== undefined && userrole1 !== undefined) {
      const res = await axios.get(`/getIPDhospitalcode/${admitnumber}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          hospitalcode: userHospitalcode, // Include the hospitalcode in the query parameters
          userrole: userrole1, // Include the userrole in the query parameters
        },
      });
      setData(res.data[0]);
      // console.log(res.data[0]);
    };
  }
  const [selectedCategory, setSelectedCategory] = useState('');
  const [medicineNames, setMedicineNames] = useState([]);
  const handleExpandClick = () => {
    setOpenAutocomplete(!openAutocomplete);
  };
  const handleExpandClicked = () => {
    setOpenAutocompleted(!openAutocompleted);
  };
  const handleChanges = (event, newValue) => {
  if (newValue) {
    setSelectedCategory(newValue.medicinecategoryid);
    // Fetch medicine names based on the selected category
    fetchMedicineNames(newValue.medicinecategoryid);
    // Clear the selected medicine name
    setValues({
      ...values,
      medicinecode: ""  // Clear the selected medicine code
    });
  }
};

  // const handleChanges = (event, newValue) => {
  //   if (newValue) {
  //     setSelectedCategory(newValue.medicinecategoryid);
  //     // Fetch medicine names based on the selected category
  //     fetchMedicineNames(newValue.medicinecategoryid);
  //   } else {
  //     setSelectedCategory('');
  //     // You may want to clear the medicine names if the category is cleared
  //     setMedicineNames([]);
  //   }
  // };
  // const handleCategoryChange = (event) => {
  //   const selectedCategory = event.target.value;
  //   setSelectedCategory(selectedCategory);
  //   // Fetch operation names based on the selected category
  //   // You need to implement the API endpoint for fetching operation names based on the category
  //   // Update the endpoint and parameters accordingly
  //   fetchMedicineNames(selectedCategory);
  // };
  const fetchMedicineCateoryNames = async (selectedHospitalCode) => {
    try {
      const response = await axios.get("/medicinecategory", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          hospitalcode: selectedHospitalCode, // Use the selected hospital code
        },
      });

      if (response.status === 200) {
        setCategory(response.data);
      } else {
        console.error(`Failed to fetch available floor numbers with status: ${response.status}`);
      }
    } catch (error) {
      console.error('Error fetching available floor numbers:', error.message);
    }
  };
  const fetchMedicineNames = async (selectedCategory) => {
    try {
      const response = await axios.get("/medicinenames", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          hospitalcode: data.hospitalcode,
          category: selectedCategory,
        },
      });

      if (response.status === 200) {
        setMedicineNames(response.data)
      } else {
        console.error(`Failed to fetch medicine names with status: ${response.status}`);
      }
    } catch (error) {
      console.error('Error fetching medicine names:', error.message);
    }
  };
  const getIpdOpeartiondetails = async () => {
    try {
      if (userHospitalcode !== undefined && userrole1 !== undefined) {
        const res = await axios.get(`/getipdmedication/${admitnumber}/${medicationData}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: {
            userHospitalcode: userHospitalcode,
            userrole: userrole1,
          },
        });

        if (res.status === 200) {
          setValues(res.data[0]);
          setSelectedCategory(res.data[0].medicinecategoryid)
          fetchMedicineNames(res.data[0].medicinecategoryid);
          console.log(res.data[0])
        } else {
          alert(`Request failed with status: ${res.status}`);
        }
      }
    } catch (error) {
      console.log(`An error occurred: ${error.message}`);
    }
  };
  useEffect(() => {
    const loadData = async () => {
      await getIpdOpeartiondetails();
      await fetchMedicineCateoryNames(data.hospitalcode);
      await loadHospitalcode();
    };

    loadData();

  }, [userHospitalcode, userrole1, isAuthorized, data.hospitalcode]);


  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleReset,
    handleSubmit,
    resetForm,
    setValues,
  } = useFormik({
    initialValues,
    // validationSchema: Operationvalidation,
    onSubmit: async (values, action) => {
      try {

        if (userHospitalcode !== undefined && userrole1 !== undefined) {
          const data = new FormData();

          data.append("document", JSON.stringify(values));
          data.append("currentDate", JSON.stringify(currentDate));
          data.append("gettokenId", JSON.stringify(gettokenId));
          console.log(JSON.stringify(values))
          data.append("selectedCategory", JSON.stringify(selectedCategory));
          const response = await axios.post(`/updatemedication/${admitnumber}/${medicationData}`, data, {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "multipart/form-data",
            },

          });
          if (response.status === 200) {
            toast.success("Data Saved")
          }
        }
      } catch (err) {

      }
      action.resetForm();
    },

  });


  return (
    <Modal
      open={true} // Set to true to open the modal
      onClose={onClose}
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      closeAfterTransition
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={true}>
        <div style={{ backgroundColor: 'white', padding: '20px', borderRadius: '8px', maxWidth: '81%', margin: 'auto', marginTop: '50px' }}>
          <h2>Update Operation</h2>
          <form onSubmit={handleSubmit} style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
            <div className="patient-new primary-info">
              <div className="row">
                <div className="col-md-12">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="col-md-12 mt-3">
                        <div className="row">
                          <div className="col-md-4">
                            <label
                              htmlFor="date"
                              className="col-form-label leftone"
                            >
                              Date
                            </label>
                          </div>
                          <div className="col-md-8">
                            <input
                              type="date"
                              autoComplete="off"
                              name="date"
                              id="date"
                              placeholder="Date"
                              value={values.date}
                              onChange={handleChange}
                              onBlur={handleBlur}

                              className="form-control"
                            />
                            {errors.date && touched.date ? (
                              <p className="form-erroremployee">{errors.date}</p>
                            ) : null}
                          </div>
                        </div>
                      </div>


                      <div className="col-md-12 mt-3">
                        <div className="row">
                          <div className="col-md-4">
                            <label
                              htmlFor="medicinecategory"
                              className="col-form-label leftone"
                            >
                              Medicine Category
                            </label>
                          </div>
                          <div className="col-md-8">

                            <Autocomplete
                              freeSolo
                              options={category1}
                              getOptionLabel={(code) => code.medicinecategory}
                              value={category1.find((code) => code.medicinecategoryid === selectedCategory) || null}
                              open={openAutocompleted}
                              onOpen={() => setOpenAutocompleted(true)}
                              onClose={() => setOpenAutocompleted(false)}
                              onChange={handleChanges} // Call handleChange when the value changes
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  placeholder="Select Medicine Category"
                                  InputProps={{
                                    ...params.InputProps,
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        <IconButton onClick={handleExpandClicked}>
                                          <ExpandMoreIcon />
                                        </IconButton>
                                      </InputAdornment>
                                    ),
                                  }}
                                />
                              )}
                            />

                            {/* {errors.medicinecategory && touched.medicinecategory ? (
                            <p className="form-erroremployee">{errors.medicinecategory}</p>
                          ) : null} */}
                          </div>
                        </div>
                      </div>


                      <div className="col-md-12 mt-3">
                        <div className="row">
                          <div className="col-md-4">
                            <label
                              htmlFor="dosage"
                              className="col-form-label leftone"
                            >
                              Dosage
                            </label>
                          </div>
                          <div className="col-md-8">
                            <input
                              type="text"
                              autoComplete="off"
                              name="dosage"
                              id="dosage"
                              placeholder="Dosage"
                              value={values.dosage}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              style={{ width: '73%', height: '32px', border: '1px solid #ced4da ', color: '#495057' }}
                            />
                            {errors.dosage && touched.dosage ? (
                              <p className="form-erroremployee">{errors.dosage}</p>
                            ) : null}

                          </div>
                        </div>
                      </div>


                    </div>
                    <div className="col-md-6">

                      <div className="col-md-12 mt-3">
                        <div className="row">
                          <div className="col-md-4">
                            <label
                              htmlFor="time"
                              className="col-form-label leftone"
                            >
                              Time
                            </label>
                          </div>
                          <div className="col-md-8">
                            <input
                              type="time"
                              autoComplete="off"
                              name="time"
                              id="time"
                              placeholder="Time"
                              value={values.time}
                              onChange={handleChange}
                              onBlur={handleBlur}

                              className="form-control"
                            />
                            {errors.time && touched.time ? (
                              <p className="form-erroremployee">{errors.time}</p>
                            ) : null}
                          </div>
                        </div>
                      </div>

                      <div className="col-md-12 mt-3">
                        <div className="row">
                          <div className="col-md-4">
                            <label
                              htmlFor="medicinecode"
                              className="col-form-label leftone"
                            >
                              Medicine Name
                            </label>
                          </div>
                          <div className="col-md-8">
                            <Autocomplete
                              id="medicinecode"
                              options={medicineNames}
                              getOptionLabel={(option) => option.medicinename}
                              value={medicineNames.find((option) => option.medicinecode === values.medicinecode) || null}
                              onChange={(event, newValue) => {
                                handleChange({ target: { name: 'medicinecode', value: newValue ? newValue.medicinecode : '' } });
                              }}
                              onBlur={handleBlur}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  placeholder="Select"

                                />
                              )}
                            />
                            {errors.medicinename && touched.medicinename ? (
                              <p className="form-erroremployee">{errors.medicinename}</p>
                            ) : null}

                          </div>
                        </div>
                      </div>


                      <div className="col-md-12 mt-3">
                        <div className="row">
                          <div className="col-md-4">
                            <label
                              htmlFor="remarks"
                              className="col-form-label "
                            >
                              Remarks
                            </label>
                          </div>
                          <div className="col-md-8">
                            <input
                              type="text"
                              autoComplete="off"
                              name="remarks"
                              id="remarks"
                              placeholder="Remarks"
                              value={values.remarks}
                              onChange={handleChange}
                              onBlur={handleBlur}

                              className="form-control"
                            />
                            {errors.remarks && touched.remarks ? (
                              <p className="form-erroremployee">{errors.remarks}</p>
                            ) : null}

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-11 mt-6 text-center">
              <Button type="submit" variant="contained" color="primary" size="small" style={{ marginRight: '10px' }}>
                Submit
              </Button>
              <Button variant="contained" size="small" onClick={onClose}>
                Cancel
              </Button>
            </div>
          </form>
        </div>
      </Fade>
    </Modal>
  );
};

export default UpdateIpdMedication;
