import React, { useState, useEffect } from "react";
import axios from '../helper/axios';
import Autocomplete from "@mui/material/Autocomplete";
import { TextField, InputAdornment, IconButton } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useUser } from "../Provider";

const SearchOperationName = ({ onOperationChange, hospitalvalue, selectedCategory }) => {
    const { userrole1, token } = useUser();
    const [data,setOperationNames] = useState([]);
    const [selectedOperation, setSelectedOperation] = useState('');
    const [openAutocomplete, setOpenAutocomplete] = useState(false);

    useEffect(() => {
        fetchOperationNames();
        // Clear selected Operation when category changes
        setSelectedOperation('');
    }, [hospitalvalue, selectedCategory, userrole1]);

    const fetchOperationNames = async (category) => {
        try {
          const response = await axios.get("/getoperationnames", {
            headers: {
              Authorization: `Bearer ${token}`,
            },
            params: {
              hospitalcode: hospitalvalue,
              category:selectedCategory,
            },
          });
    
          if (response.status === 200) {
            setOperationNames(response.data);
          } else {
            console.error(`Failed to fetch operation names with status: ${response.status}`);
          }
        } catch (error) {
          console.error('Error fetching operation names:', error.message);
        }
      };

    const handleMedicineChange = (event, value) => {
        if (value) {
            setSelectedOperation(value);
            onOperationChange(value);
        } else {
            // Handle the case when no value is selected
            setSelectedOperation('');
        }
    };

    const getOptionLabel = (option) => {
        // Handle the case when option is null or undefined
        return option?.operationname || '';
    };
    const handleExpandClick = () => {
      setOpenAutocomplete(!openAutocomplete);
  };

    return (
        <div className="searchbar">
            <Autocomplete
                freeSolo
                id="free-solo"
                options={data}
                getOptionLabel={getOptionLabel}
                open={openAutocomplete}
                onOpen={() => setOpenAutocomplete(true)}
                onClose={() => setOpenAutocomplete(false)}
                value={selectedOperation} // Set the value of the selected medicine
                onChange={handleMedicineChange}
                renderInput={(params) =>
                    <TextField
                        {...params}
                        placeholder="Select Medicine"
                        InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton onClick={handleExpandClick}>
                                        <ExpandMoreIcon />
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                }
            />
        </div>
    );
};

export default SearchOperationName;
