import React from 'react'
import { NavLink } from "react-router-dom";

const Landingpage = () => {
  return (
    <div>
    <nav class="navbar navbar-expand-lg bg-body-tertiary">
        <div class="container-fluid">
            <a class="navbar-brand" href="#">
                <img src="./assets/images/arrogya.svg" alt="" width="200" height="40"
                    class="d-inline-block align-text-top"/></a>
            <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav"
                aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse" id="navbarNav">
                <ul class="navbar-nav">
                    <li class="nav-item">
                        <a class="nav-link active" aria-current="page" href="#">Home</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" href="#">Service</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" href="#">Features</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" href="#">Client</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" href="#">Price</a>
                    </li>
                </ul>
            </div>
            {/* <button type="button" class="btn btn-primary login-btn">Login</button> */}
            <NavLink to="/login" className="btn btn-primary login-btn">Login</NavLink>

        </div>
    </nav>
    <div class="container">
        <div class="row">
            <div class="col-md-6">
                <div class="tag_line">
                    <p class="tag_line_thin">your partner in <b>HEALTH</b></p>
                    <p class="tag_line_bold">and <b>WELLNESS</b></p>
                    <p class="para">
                        Elevate healthcare efficiency with our SaaS hospital management software. Streamline operations
                        seamlessly, empower care providers, and enhance patient experiences. Your all-in-one solution
                        for a healthier, connected future.
                    </p>
                    <button class="book_demo">book demo</button>
                    <div class="image_container">
                        <a href="https://www.facebook.com/bbstechsolution/" target="_blank"><img class="image_spacing"
                                src="./assets/images/facebook.svg" /></a>
                        <a href="https://www.instagram.com/bbstechsolution/" target="_blank"><img class="image_spacing"
                                src="./assets/images/instagram.svg" /></a>
                        <a href="https://twitter.com/bbstechsolution" target="_blank"><img class="image_spacing"
                                src="./assets/images/twitter.svg" /></a>
                        <a href="https://www.linkedin.com/company/82602705" target="_blank"><img class="image_spacing"
                                src="./assets/images/linked-in.svg" /></a>
                    </div>
                </div>
            </div>
            <div class="col-md-6 hospital-img">
                <img src="./assets/images/arrogya-landing-01.svg" alt="" class="img-fluid"/>
            </div>
        </div>
    </div>

    {/* <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.3.2/dist/js/bootstrap.bundle.min.js"
        integrity="sha384-C6RzsynM9kWDrMNeT87bh95OGNyZPhcTNXj1NW7RuBCsyN/o0jlpcV8Qyq46cDfL"
        crossorigin="anonymous"></script> */}
    </div>
  )
}

export default Landingpage