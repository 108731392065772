import { useState, useEffect } from "react";
import axios from '../helper/axios';
import Autocomplete from "@mui/material/Autocomplete";
import {
  TextField,
  InputAdornment,
  IconButton,
} from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {  toast } from 'react-toastify';
import {  useHistory } from 'react-router-dom';
import { useUser } from "../Provider";
const BillingSearchPerPatient = ({ onSearchChange, searchValue }) => {
  const {user, userHospitalcode, userrole1, isAuthRole,isAuthorized,gettokenId,token  } = useUser();
  const history = useHistory();
  //const token = localStorage.getItem("jwtToken");
  const [value, setValue] = useState("");
  const [data, setData] = useState([]);
  const [open, setOpen] = useState(false); // State to control dropdown visibility
  const [selectedValue, setSelectedValue] = useState(null); // State to store selected value

//when page load or refresh happen this function will got called
useEffect(() => {
  const loadData = async () => {
   // await loadAllDetails();
    //loadAllAppointment(); will wait for loadAllDetails(); to got execute
    await loadOpd();
  };

  loadData();
}, [userrole1,userHospitalcode]);


const loadOpd = async () => {
    try {
      if (searchValue.patientid !== undefined && userHospitalcode !== undefined && userrole1 !== undefined) {
        const res = await axios.get(`/getbillingspatient/${searchValue.patientid}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: {
            hospitalcode: userHospitalcode,
            userrole: userrole1,
          },
        });
        setData(res.data);
        console.log(res.data);
      }
    } catch (error) {
      // Error handling
    }
  };
  

  const onSearch = (searchTerm, item) => {
    console.log(item +"------------")
    console.log(value)
    // setValue("")
    onSearchChange(item);
    setSelectedValue(item);
  };

  const handleExpandClick = () => {
      setOpen(!open);
  };
  return (
    <>
      <div className="searchbar" >
   
          <Autocomplete
             freeSolo
             id="free-solo"
            options={data}
            getOptionLabel={(option) => `${option.billnumber}`}
            open={open}
            onOpen={() => setOpen(true)}
            onClose={() => setOpen(false)}
            value={selectedValue}
            onChange={(event, newValue) => {
                onSearch(null, newValue);
                setSelectedValue(newValue);
            }}
            renderInput={(params) => 
            <TextField
             {...params} 
             InputProps={{
              ...params.InputProps,
              endAdornment: (
                  <InputAdornment position="end">
                      <IconButton onClick={handleExpandClick}>
                          <ExpandMoreIcon />
                      </IconButton>
                  </InputAdornment>
              ),
          }}
           //  onChange={(event) => setValue(event.target.value)} 
              />
            }
            renderOption={(props, option) => (
              <li {...props}>
                {option.billnumber}
              </li>
            )}
          />
        </div>
 
    </>
  );
}
export default BillingSearchPerPatient;