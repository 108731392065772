import React, { useState, useEffect } from "react";
import { Table, Button, Modal, Form } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { IoTrashOutline } from "react-icons/io5";
import axios from "../../../helper/axios";
import { useUser } from "../../../Provider";
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import { Helmet } from "react-helmet";
import { NavLink, useHistory } from "react-router-dom";
import MUIDataTable from "mui-datatables";

const ListViewStaff = () => {
  const {user, userHospitalcode, userrole1, isAuthRole,isAuthorized,gettokenId,token  } = useUser();
  console.log(user, userHospitalcode, userrole1, isAuthRole,isAuthorized,gettokenId )
  const history = useHistory();
  const [data, setData] = useState([]);
  const currentDate = new Date();
  const day = currentDate.getDate();
  const month = currentDate.getMonth() + 1; // Months are zero-indexed, so add 1
  const year = currentDate.getFullYear();
  
  // Format the date as DD-MM-YYYY
  const formattedDate = `${day}-${month}-${year}`;
  //const token = sessionStorage.getItem("jwtToken");


  const loadAllStaffs = async () => {
    try{
  if (userHospitalcode !== undefined && userrole1 !== undefined) { 
const res = await axios.get("/GetStaffs", {
  headers: {
    Authorization: `Bearer ${token}`,
  },
  params: {
    hospitalcode: userHospitalcode, // Include the hospitalcode in the query parameters
    userrole: userrole1, // Include the userrole in the query parameters
  },
});
setData(res.data);
console.log(res.data);
}
    } catch(error){
      // Handle the error here, e.g., set an error state or display an error message.
      console.error("Error loading staff data:", error);
    }

  };

  useEffect(() => {
    // if (!sessionStorage.getItem("jwtToken")) {
    //   return history.push("/");
    // }
    const loadData = async () => {
      await loadAllStaffs();
      // await loadAllDetails();
    };

    loadData();
  }, [ userrole1, userHospitalcode,isAuthorized,isAuthRole]);


  const cancelStaff = async (empid) => {
    // Display a confirmation dialog
    const isConfirmed = window.confirm("Do you want to delete this staff?");
  
    if (isConfirmed) {
      try {
        console.log(empid);
        await axios.post(`/deletestaff/${empid}`,null,  {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        // After successfully delete the staff, refresh the staff list.
        loadAllStaffs();
      } catch (error) {
        // Handle any error here.
       alert("Error deleteing staff:", error);
      }
    } else {
      // User clicked "Cancel" in the confirmation dialog, do nothing.
    }
  };
  

  let columns;
  if (isAuthorized) {
   columns = [
    {
     name: "empid",
     label: "Emp ID",
     options: {
      filter: true,
      sort: true,
     }
    },
    {
     name: "fname",
     label: "First Name",
     options: {
      filter: true,
      sort: true,

      customBodyRender: (value, tableMeta) => {
        // Assuming the employee id is also available in your data
        const empid = tableMeta.rowData[0]; // Adjust this index as needed
  
        return (
          <NavLink to={`/listviewstaff/${empid}`} className="addlink">{value}</NavLink>
         );
        }
     }
    },
    {
     name: "lname",
     label: "Last Name",
     options: {
      filter: true,
      sort: true
     }
    },
    {
     name: "phoneno",
     label: "Mobile No",
     options: {
      filter: true,
      sort: true,
     }
    },
    {
     name: "gender",
     label: "Gender",
     options: {
      filter: true,
      sort: true,
     }
    },
    {
     name: "email",
     label: "Email",
     options: {
      filter: true,
      sort: true,
      }
    },
    {
      name: "hospitalcode",
      label: "Hospital Code",
      options: {
       filter: true,
       sort: true,
       customBodyRender: (value) => (value !== null && value !== undefined) ? value.toString().padStart(5, '0') : ""
      }
     },
    {
      name: "",
      label: "",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          const newD = data.find((e) => {
            return (
              e.empid === tableMeta.rowData[0] &&
              e.fname === tableMeta.rowData[1] &&
              e.lname === tableMeta.rowData[2] &&
              e.phoneno === tableMeta.rowData[3] &&
              e.gender === tableMeta.rowData[4] &&
              e.email === tableMeta.rowData[5]
             
              
            );
          });
          
          console.log('newD:', newD);
          return (
            <>
              {/* <button
                className="btn btn-danger btn-sm"
                 onClick={() =>cancelStaff(newD.empid)}

              >
                Delete
              </button> */}
              <IconButton color="secondary" onClick={() =>cancelStaff(newD.empid)}>
              <DeleteIcon />
            </IconButton>
            </>
          );
        },
      },
    },
   ];
  }
else{
  columns = [
    {
     name: "empid",
     label: "Emp ID",
     options: {
      filter: true,
      sort: true,
     }
    },
    {
     name: "fname",
     label: "First Name",
     options: {
      filter: true,
      sort: true,

      customBodyRender: (value, tableMeta) => {
        // Assuming the employee id is also available in your data
        const empid = tableMeta.rowData[0]; // Adjust this index as needed
  
        return (
          <NavLink to={`/listviewstaff/${empid}`} className="addlink">{value}</NavLink>
         );
        }
     }
    },
    {
     name: "lname",
     label: "Last Name",
     options: {
      filter: true,
      sort: true
     }
    },
    {
     name: "phoneno",
     label: "Mobile No",
     options: {
      filter: true,
      sort: true,
     }
    },
    {
     name: "gender",
     label: "Gender",
     options: {
      filter: true,
      sort: true,
     }
    },
    {
     name: "email",
     label: "Email",
     options: {
      filter: true,
      sort: true,
      }
    },


    {
      name: "",
      label: "",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          const newD = data.find((e) => {
            return (
              e.empid === tableMeta.rowData[0] &&
              e.fname === tableMeta.rowData[1] &&
              e.lname === tableMeta.rowData[2] &&
              e.phoneno === tableMeta.rowData[3] &&
              e.gender === tableMeta.rowData[4] &&
              e.email === tableMeta.rowData[5] 

            );
          });
          
          console.log('newD:', newD);
          return (
            <>
              {/* <button
              className="btn btn-danger btn-sm"
              onClick={() => cancelStaff(newD.empid)}
                      >
                Delete
            </button> */}
            <IconButton color="secondary" onClick={() =>cancelStaff(newD.empid)}>
              <DeleteIcon />
            </IconButton>
            </>
          );
        },
      },
    },

   ];

  }
   const options = {
    selectableRows: false,
    onRowClick: ((props) => {
      console.log(props)
       }),

    
    downloadOptions: {
      filename: `Staff_list_${formattedDate}`, // Customize the filename here to download
      filterOptions: {
        useDisplayedColumnsOnly: true,
        useDisplayedRowsOnly: true,
        // },
    },
  },
   };




  //  const options = {
  //   selectableRows: false,
  //   onRowClick: ((props) => {
  //     console.log(props)
  //   })
  // };
  const currentDomain = window.location.hostname;
  return (

    
    <>
     <Helmet>
        <title>Staff Record List | {currentDomain} | Hospital Management Software | Smart Solutions for Hospital Efficiency</title>
      </Helmet>
       <NavLink to='/UserRegistration'><button className="btn btn-success btn-sm mr-2" style={{margin: "10px"}}>  <svg xmlns="http://www.w3.org/2000/svg" width="19" height="18" style={{ paddingRight: '4px' }} viewBox="0 0 100 100">
        <line x1="10" y1="50" x2="90" y2="50" stroke="white" stroke-width="10" />
        <line x1="50" y1="10" x2="50" y2="90" stroke="white" stroke-width="10" />
      </svg>New Staff</button></NavLink>

       <MUIDataTable
        title={"Staff List"}
        data={data}
        columns={columns}
        options={options}
        className="custom-mui-datatable"
      />
    </>

   
  );
}
export default ListViewStaff;
// import React, { useState, useEffect } from "react";
// import { Table, Button, Modal, Form } from "react-bootstrap";
// import "bootstrap/dist/css/bootstrap.min.css";
// import { IoTrashOutline } from "react-icons/io5";
// import axios from "../../../helper/axios";
// import { useUser } from "../../../Provider";
// import { Helmet } from "react-helmet";
// import { NavLink, useHistory } from "react-router-dom";
// import MUIDataTable from "mui-datatables";


// const ListViewStaff = () => {
//   const {user, userHospitalcode, userrole1, isAuthRole,isAuthorized,gettokenId  } = useUser();
//   console.log(user, userHospitalcode, userrole1, isAuthRole,isAuthorized,gettokenId )
//   const history = useHistory();
//   const [data, setData] = useState([]);
//   const currentDate = new Date();
//   const day = currentDate.getDate();
//   const month = currentDate.getMonth() + 1; // Months are zero-indexed, so add 1
//   const year = currentDate.getFullYear();
  
//   // Format the date as DD-MM-YYYY
//   const formattedDate = `${day}-${month}-${year}`;
//   const token = sessionStorage.getItem("jwtToken");
//   useEffect(() => {
//     // if (!sessionStorage.getItem("jwtToken")) {
//     //   return history.push("/");
//     // }
//     const loadData = async () => {
//       await loadAllStaffs();
//       // await loadAllDetails();
//     };

//     loadData();
//   }, [userrole1,userHospitalcode,isAuthorized,isAuthRole]);
//     const loadAllStaffs = async () => {
//       if (userHospitalcode !== undefined && userrole1 !== undefined) { 
//     const res = await axios.get("/GetStaffs", {
//       headers: {
//         Authorization: `Bearer ${token}`,
//       },
//       params: {
//         hospitalcode: userHospitalcode, // Include the hospitalcode in the query parameters
//         userrole: userrole1, // Include the userrole in the query parameters
//       },
//     });
//     setData(res.data);
//     console.log(res.data);
//   };}

//   let columns;
//   if (isAuthorized) {
//    columns = [
//     {
//      name: "empid",
//      label: "Emp ID",
//      options: {
//       filter: true,
//       sort: true,
//      }
//     },
//     {
//      name: "fname",
//      label: "First Name",
//      options: {
//       filter: true,
//       sort: true,

//       customBodyRender: (value, tableMeta) => {
//         // Assuming the patient id is also available in your data
//         const empid = tableMeta.rowData[0]; // Adjust this index as needed
  
//         return (
//           <NavLink to={`/listviewstaff/${empid}`} className="addlink">{value}</NavLink>
//          );
//         }
//      }
//     },
//     {
//      name: "lname",
//      label: "Last Name",
//      options: {
//       filter: true,
//       sort: true
//      }
//     },
//     {
//      name: "phoneno",
//      label: "Mobile No",
//      options: {
//       filter: true,
//       sort: true,
//      }
//     },
//     {
//      name: "gender",
//      label: "Gender",
//      options: {
//       filter: true,
//       sort: true,
//      }
//     },
//     {
//      name: "email",
//      label: "Email",
//      options: {
//       filter: true,
//       sort: true,
//       }
//     },
//    ];
//   }
// else{
//   columns = [
//     {
//      name: "empid",
//      label: "Emp ID",
//      options: {
//       filter: true,
//       sort: true,
//      }
//     },
//     {
//      name: "fname",
//      label: "First Name",
//      options: {
//       filter: true,
//       sort: true,

//       customBodyRender: (value, tableMeta) => {
//         // Assuming the patient id is also available in your data
//         const empid = tableMeta.rowData[0]; // Adjust this index as needed
  
//         return (
//           <NavLink to={`/listviewstaff/${empid}`} className="addlink">{value}</NavLink>
//          );
//         }
//      }
//     },
//     {
//      name: "lname",
//      label: "Last Name",
//      options: {
//       filter: true,
//       sort: true
//      }
//     },
//     {
//      name: "phoneno",
//      label: "Mobile No",
//      options: {
//       filter: true,
//       sort: true,
//      }
//     },
//     {
//      name: "gender",
//      label: "Gender",
//      options: {
//       filter: true,
//       sort: true,
//      }
//     },
//     {
//      name: "email",
//      label: "Email",
//      options: {
//       filter: true,
//       sort: true,
//       }
//     },
//    ];
// }

//    const options = {
//     selectableRows: false,
//     onRowClick: ((props) => {
//       console.log(props)
//        }),
//     downloadOptions: {
//       filename: `Staff_list_${formattedDate}`, // Customize the filename here to download
//       filterOptions: {
//         useDisplayedColumnsOnly: true,
//         useDisplayedRowsOnly: true,
//         // },
//     },
//   },
//    };

//   return (

//     <>
//        <NavLink to='/UserRegistration'><button className="btn btn-success btn-sm mr-2" style={{margin: "10px"}}>  <svg xmlns="http://www.w3.org/2000/svg" width="19" height="18" style={{ paddingRight: '4px' }} viewBox="0 0 100 100">
//         <line x1="10" y1="50" x2="90" y2="50" stroke="white" stroke-width="10" />
//         <line x1="50" y1="10" x2="50" y2="90" stroke="white" stroke-width="10" />
//       </svg>New Staff</button></NavLink>

//        <MUIDataTable
//         title={"Staff List"}
//         data={data}
//         columns={columns}
//         options={options}
//         className="custom-mui-datatable"
//       />
//     </>

   
//   );
// };

// export default ListViewStaff;
