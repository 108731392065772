import React from "react";
import { NavLink } from "react-router-dom";
import Header from "../../Component/Header/Header";
import Sidebar from "../../Component/Sidebar";
import { UserProvider } from "../../Provider";
import OperationSetup from "../Operation/OperationPages/OperationSetup";
import ListViewOperation from "../Operation/OperationPages/ListViewOperation";
import OperationCategory from "./OperationPages/OperationCategory";
import ListViewCategory from "./OperationPages/ListViewCategory";
import EditCategory from "./OperationPages/EditCategory";
import EditOperation from "./OperationPages/EditOperation";


export const OperationSetupComponent = () => {
  return (

    <UserProvider>  <div className=" ">
    <Header />
  <div className="row" style={{ marginTop: '50px' }}>
    {/* Sidebar */}
    <div className="col-md-2">
      <Sidebar />
    </div>
    
    {/* Main Content */}
    <div className="col-md-10">
    <OperationSetup />
    </div>
  </div>
</div></UserProvider>
          
  );
};

export const ListViewOperationComponent = () => {
  return (
    <UserProvider> <div className=" ">
      <Header />
      <div
        className="row"
        style={{ marginTop: "50px" }}
      >
        {/* Sidebar */}
        <div className="col-md-2">
          <Sidebar />
        </div>

        {/* Main Content */}
        <div className="col-md-10">
       
        <ListViewOperation/>
        
        </div>
      </div>
    </div></UserProvider>
  );
};

export const OperationCategoryComponent = () => {
  return (

    <UserProvider>  <div className=" ">
    <Header />
  <div className="row" style={{ marginTop: '50px' }}>
    {/* Sidebar */}
    <div className="col-md-2">
      <Sidebar />
    </div>
    
    {/* Main Content */}
    <div className="col-md-10">
    <OperationCategory />
    </div>
  </div>
</div></UserProvider>
          
  );
};


export const ListViewCategoryComponent = () => {
  return (
    <UserProvider> <div className=" ">
      <Header />
      <div
        className="row"
        style={{ marginTop: "50px" }}
      >
        {/* Sidebar */}
        <div className="col-md-2">
          <Sidebar />
        </div>

        {/* Main Content */}
        <div className="col-md-10">
        
        <ListViewCategory/>
        
        </div>
      </div>
    </div> </UserProvider>
  );
};

export const EditCategoryComponent = () => {
  return (
    <UserProvider> <div className=" ">
    <Header />
  <div className="row" style={{ marginTop: '50px' }}>
    {/* Sidebar */}
    <div className="col-md-2">
      <Sidebar />
    </div>
    
    {/* Main Content */}
    <div className="col-md-10">
  
    <EditCategory />
    
    </div>
  </div>
</div></UserProvider>
            
  );
};



export const EditOperationComponent = () => {
  return (
    <UserProvider> <div className=" ">
    <Header />
  <div className="row" style={{ marginTop: '50px' }}>
    {/* Sidebar */}
    <div className="col-md-2">
      <Sidebar />
    </div>
    
    {/* Main Content */}
    <div className="col-md-10">
  
    <EditOperation />
    
    </div>
  </div>
</div></UserProvider>
            
  );
};

