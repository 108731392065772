
import React, { useState, useEffect } from 'react';
import MUIDataTable from "mui-datatables";
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import PrintIcon from '@mui/icons-material/Print';
import axios from "../../../helper/axios";
import { useUser } from "../../../Provider";
import { useParams } from 'react-router-dom/cjs/react-router-dom';
import PrintTransaction from '../../../Component/PrintTransaction/PrintTransaction';
import { Modal } from 'react-bootstrap';
import UpdateIpdOperationPopUp from './UpdateIpdOperationPopUp';
import UpdateIpdpayment from './UpdateIpdpayment';
import PaymentForm from './PaymentForm';

const PaymentTable = ({hideForm}) => {
  const { admitnumber } = useParams();
  const { userHospitalcode, userrole1, isAuthRole, isAuthorized, gettokenId ,token} = useUser();
 // const token = sessionStorage.getItem("jwtToken");
  const [paymentData, setPaymentData] = useState([]);
  const [totalAmount, setTotalAmount] = useState(0);
  const [ipdtransaction, setIpdtransaction] = useState();
  const [isPopup, setIsPopup] = useState(false);
  const [isEditFormVisible, setEditFormVisibility] = useState(false);
  const [editTransactionData, setEditTransactionData] = useState(null);
 ;
  const getpaymentdetails = async () => {
    try {
      if (userHospitalcode !== undefined && userrole1 !== undefined) {
        const res = await axios.get(`/getpaymentdetails/${admitnumber}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        console.log(res.data)
        if (res.status === 200) {
          setPaymentData(res.data);
          const total = res.data.reduce((acc, row) => acc + parseFloat(row.paymentamount), 0);
          setTotalAmount(total);
        } else {
          alert(`Request failed with status: ${res.status}`);
        }
      }
    } catch (error) {
      console.log(`An error occurred: ${error.message}`);
    }
  };

  useEffect(() => {
    const loadData = async () => {
      await getpaymentdetails()
    };

    loadData();
  }, [userHospitalcode, userrole1, isAuthorized]);
  const handleChargeAdded = () => {
    // Function to fetch the updated NurseNote data
    getpaymentdetails();
  };
  const handleClose = () => {
    setIsPopup(false)
  } 
  const handlePrint = async (value, tableMeta, updateValue) => {
    console.log(paymentData);
    console.log(tableMeta.rowData[0])
    if (tableMeta.rowData && tableMeta.rowData[0]) {
        const newD =paymentData.find((e) =>
         e.transactionid === tableMeta.rowData[0]);

        if (newD) {
            console.log(newD);
            await setIpdtransaction(tableMeta.rowData[0]);
            await setIsPopup(true);
        } else {
            console.error('Data not found for the specified operation number.');
        }
    } else {
        console.error('Invalid tableMeta.rowData.');
    }
};
  const formatOperationTime = (inputDateString) => {
    const inputDate = new Date(inputDateString);
  
    // Format the date as MM/DD/YYYY hh:mm am/pm in the user's local time zone
    const formattedDate = inputDate.toLocaleString('en-US', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      hour12: true,
      timeZone: 'UTC', // Change this to the server's time zone
    });
  
    return formattedDate;
  };
  const columns = [
    { name: "transactionid", label: "Transaction ID" },
    { name: "paymentdate", label: "Date", options: {
      customBodyRender: (value) => formatOperationTime(value),
    },},
    { name: "paymentnote", label: "Note" },
    { name: "paymentmode", label: "Payment Mode" },
    { name: "paymentamount", label: "Paid Amount (GHS)" },
    {
      name: "actions",
      label: "Actions",
      options: {
        customBodyRender: (value, tableMeta) => (
          <div>
            <IconButton color="primary" onClick={() => handlePrint(value, tableMeta)}>
              <PrintIcon />
            </IconButton>
            <IconButton color="primary" onClick={() => handleEdit(value, tableMeta)}>
              <EditIcon />
            </IconButton>
            <IconButton color="secondary" onClick={() => handleDelete(value, tableMeta)}>
              <DeleteIcon />
            </IconButton>
          </div>
        ),
      },
    },
  ];

  const options = {
    filter: true,
    selectableRows: "none",
    viewColumns: false,
    print: false,
    download: false,
  };

  const handleEdit = (value, tableMeta, updateValue) => {
    const paymentId=tableMeta.rowData[0]
    console.log(paymentId)
    setEditTransactionData(paymentId);
    setEditFormVisibility(true);
  };
  // const handleEdit = (paymentId) => {
  //   // Handle edit action
  //   console.log(`Edit action for payment ID: ${paymentId}`);
  // };
  const handleDelete = async (value, tableMeta, updateValue) => {
    const paymentId=tableMeta.rowData[0]
  console.log(paymentId)
    // Display a confirmation dialog with patient's name
    const isConfirmed = window.confirm(`Do you want to delete ${paymentId}?`);
  
    if (isConfirmed) {
      try {
        await axios.post(`/canceltransaction/${admitnumber}/${paymentId}`, null, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
  
        getpaymentdetails();
      } catch (error) {
        // Handle any error here.
        alert("Error cancelling transaction:", error);
      }
    } else {
      // User clicked "Cancel" in the confirmation dialog, do nothing.
    }
  };


  return (
    <div>
       {!hideForm && <PaymentForm onPaymentAdded={handleChargeAdded} /> }
      <MUIDataTable
        title="Payment Details"
        data={paymentData}
        columns={columns}
        options={options}
      />
       {isPopup==true?
          <>

          <Modal show={true}  animation={false} onHide={handleClose} size="lg">
            <Modal.Header closeButton>
             
            </Modal.Header>
            <Modal.Body>
            {ipdtransaction==undefined?""
              :
              <PrintTransaction ipdtransaction={ipdtransaction}/>}
            </Modal.Body>
          </Modal>
        </> 
        :
        ""}
      <div style={{ marginTop: "20px", fontWeight: "bold" }}>
        Total Amount: {totalAmount}
      </div>
       {/* ... your existing code ... */}
    {isEditFormVisible && editTransactionData && (
      <UpdateIpdpayment
      paymentData={editTransactionData}
      onClose={() => setEditFormVisibility(false)}
       />
    )}
    </div>
  );
};

export default PaymentTable;
