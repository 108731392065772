import React, { useState, useEffect } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from '../../helper/axios';
import { Link,useHistory } from 'react-router-dom';
import API from '../../helper/axios';
import { useUser } from "../../Provider";

const Header = () => {
  const { userHospitalcode, userrole1, isAuthorized, token } = useUser();
  const history = useHistory();
  //const token = sessionStorage.getItem('jwtToken')
  const [data, setData] = useState([]);
  
  //when page load or refresh happen this function will got called
  useEffect(() => {
    const loadData = async () => {
      //await loadAllDetails();
      //loadHospitalLogo(); will wait for loadAllDetails(); to got execute
      await loadHospitalLogo();
    };
    loadData();
  }, [userHospitalcode, userrole1, isAuthorized]);
   //get all hospital code
  const loadHospitalLogo = async () => {
    //when userHospitalcode is not undefined
    if (userHospitalcode !== undefined ) {
      console.log(userHospitalcode)
      const res = await axios.get(`/gethospitallogo/${userHospitalcode}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          hospitalcode: userHospitalcode, // Include the hospitalcode in the query parameters
          //userrole: userrole1, // Include the userrole in the query parameters
        },
      });
      setData(res.data[0]);
      console.log(res.data);
    };
  }
  const logout = async () => {
    // Prompt the user to confirm logout
    const confirmed = window.confirm("Do you want to log out?");
    if (confirmed) {
      try {
        // Call the logout API endpoint
        const response = await axios.post('/logout', null, {
          headers: { Authorization: `Bearer ${token}` },
        });
  
        // Check if the logout was successful
        if (response.status === 200) {
          // Remove JWT token from localStorage
          localStorage.removeItem('jwtToken');
          // Redirect the user to the homepage after logout
          history.push("/");
        }
      } catch (error) {
        // Handle errors, such as token expiration or invalid token
        if (error.response.status === 403) {
          alert('Token Expired. Please login again.');
          localStorage.removeItem('jwtToken');
          history.push('/');
        } else if (error.response.status === 401) {
          alert('Invalid token. Please login again.');
          localStorage.removeItem('jwtToken');
          history.push('/');
        } else {
          alert('Error: ' + error.response.data.error);
        }
      }
    }
  };
  // const logout = async () => {
  //   try {
  //     const response = await axios.post('/logout', null, {
  //       headers: { Authorization: `Bearer ${token}` },
  //     });
  
  //     console.log(response.status);
  //     if (response.status === 200) {
  //      localStorage.removeItem('jwtToken');
  //       const confirmed = window.confirm("Do you want to log out?");
  //       if (confirmed) {
  //         history.push("/");
  //       }
  //     }
  //   } catch (error) {
  //     if (error.response.status === 403) {
  //       alert('Token Expired. Please login again.');
  //      localStorage.removeItem('jwtToken');
  //       history.push('/');
  //     } else if (error.response.status === 401) {
  //       alert('Invalid token. Please login again.');
  //      localStorage.removeItem('jwtToken');
  //       history.push('/');
  //     } else {
  //       alert('Error: ' + error.response.data.error);
  //     }
  //   }
  // };
  
  return (
    <div className="head" style={{ position: 'fixed', top: '0', left: '0', right: '0', zIndex: '999' }}>
      <div className="row">
        <div className="col-2 d-flex justify-content-center align-items-center">
          {/* Logo */}
          {/* <img src="/bbslogo.png" alt="Logo" /> */}
          <img src="/bbslogo.svg" alt="Logo" style={{height: "49px" , width: "100%"}} />
        </div>
        <div className="col-8 d-flex justify-content-center align-items-center">
          {/* Hospital Name */}
          <img src={`${API.defaults.baseURL}/uploadimage/hospitallogo/${data.hospitallogo}`}
            style={{ width: "5%", marginBottom: "0px", float: "left" }}
          />
          {/* <h4 className="mx-2">{data.hospitalname.charAt(0).toUpperCase() + data.hospitalname.slice(1)}</h4> */}
          <h4 className="mx-2">
             {/* {data.hospitalname ? (data.hospitalname.toUpperCase()) : ("HOSPITAL NAME NOT AVAILABLE")} */}
              {data.hospitalname ? (data.hospitalname) : ("HOSPITAL NAME NOT AVAILABLE")}
             {/* Aarogyasaarthi.com */}
          </h4>
        </div>
        <div className="col-2 d-flex justify-content-center align-items-center">
  {/* Logout Button */}
  <button class="btn btn-danger btn-sm mr-2" onClick={logout}>Logout</button>
</div>
      </div>
    </div>
  );
};

export default Header;
