import React, { useState, useEffect } from 'react';
import { Typography, Button, Select, MenuItem, FormControl, InputLabel, Autocomplete, TextField, InputAdornment, IconButton } from '@mui/material';
import axios from "../../../helper/axios";
import { useFormik } from "formik";
import { useUser } from "../../../Provider";
import { ToastContainer, toast } from 'react-toastify';
import { useHistory } from "react-router-dom";
import Searchbar from '../../../Component/Searchbar';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import * as Yup from 'yup';
import HospitalCodeSearch from "../../../Component/HospitalCodeSearch";
import SearchSupplier from '../../../Component/SearchSupplier';
const validationSchema = Yup.object().shape({
  // hospitalcode: Yup.string().required('Hospital Code is required'),
  supplier: Yup.string().required('Supplier is required'),
});
const initialValues = {
  // bedname: "",
  // bedtypeid: "",
  supplier: "",
  hospitalcode: "",
  total: "",
  discountPercentage: "",
};
const PharmacyPurchase = () => {
  // Use the 'useUser' hook to get user-related information
  const { user, userHospitalcode, userrole1, isAuthRole, isAuthorized, gettokenId, token } = useUser();
  const history = useHistory();
  let Showdate = new Date();
  let ShowTodaydate =
    Showdate.getFullYear() +
    "-" +
    (Showdate.getMonth() + 1) +
    "-" +
    Showdate.getDate();
  const [currentDate, setCurrentDate] = useState(ShowTodaydate);
  const [category1, setCategory] = useState([]);
  const [data, setData] = useState([]);
  const [supplier, setSupplier] = useState([]);
  const [medicines, setMedicines] = useState([{
    medicinecategory: '', medicinecode: '', batchno: '',
    expirydate: '', mrp: "", packquantity: "", quantity: '', purchaseprice: "", saleprice: '', tax: "", amount: ""
  }]);
  const [medicineNames, setMedicineNames] = useState({});
  const [searchValue, setSearchValue] = useState("");
  const [total, setTotal] = useState(0); // Initialize total to 0
  const [extraCharge, setExtraCharge] = useState(0);
  const [discountBy, setDiscountBy] = useState("");
  const [discountPercentage, setDiscountPercentage] = useState(0);
  const [finalAmount, setFinalAmount] = useState(0);
  // Function to handle changes in medicine-related fields


  // Formik hook for form handling
  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    resetForm,
    setValues
  } = useFormik({
    initialValues,
    validationSchema,
    // validationSchema: getValidationSchema(), // Apply validation schema
    onSubmit: async (values, action) => {

      // if (!searchValue) {
      //   toast.error('Please enter a search value.');
      //   return; // Stop form submission
      // }
      // Check if any of the medicinecategory field is empty
      const ismedicinecategory = medicines.some((test) => test.medicinecategory === "");
      console.log("???????????????????", ismedicinecategory)
      if (ismedicinecategory) {
        toast.error("Medicine category field cannot be empty.");
        return;
      }
      // Check if any of the medicinename field is empty
      const ismedicinename = medicines.some((test) => test.medicinecode === "");
      console.log(ismedicinename)
      if (ismedicinename) {
        toast.error("Medicine name field cannot be empty .");
        return;
      }
      // Check if any of the batchno field is empty
      const isbatchno = medicines.some((test) => test.batchno === "");
      console.log(isbatchno)
      if (isbatchno) {
        toast.error("Batch number field cannot be empty .");
        return;
      }
      const isexpirydate = medicines.some((test) => test.expirydate === "");
      console.log(isexpirydate)
      if (isexpirydate) {
        toast.error("Expiry date field cannot be empty .");
        return;
      }
      const ismrp = medicines.some((test) => test.mrp === "");
      console.log(ismrp)
      if (ismrp) {
        toast.error("MRP cannot be empty .");
        return;
      }
      // Check if any of the quantity field is empty
      const isSalePrice = medicines.some((test) => test.saleprice === "");
      console.log(isSalePrice)
      if (isSalePrice) {
        toast.error("Sale price field cannot be empty .");
        return;
      }
      // Check if any of the amount field is empty
      const isPurchasePrice = medicines.some((test) => test.purchaseprice === "");
      if (isPurchasePrice) {
        toast.error("Purchase price field cannot be empty .");
        return;
      }
      const isPackQuantity = medicines.some((test) => test.packquantity === "");
      if (isPackQuantity) {
        toast.error("Packing quantity field cannot be empty .");
        return;
      }

      const isQuantity = medicines.some((test) => test.quantity === "");
      if (isQuantity) {
        toast.error("Quantity field cannot be empty .");
        return;
      }



      try {
        // Create form data for submission
        const data = new FormData();
        data.append("document", JSON.stringify(values));
        // data.append("currentDate", JSON.stringify(currentDate));
        data.append("gettokenId", JSON.stringify(gettokenId));
        data.append("userHospitalcode", JSON.stringify(userHospitalcode));
        data.append('hospitalcode', values.hospitalcode);
        data.append("medicines", JSON.stringify(medicines));
        data.append("finalAmount", JSON.stringify(finalAmount));
        data.append("currentDate", JSON.stringify(currentDate));
        data.append("extraCharge", JSON.stringify(extraCharge));
        data.append("discountBy", JSON.stringify(discountBy));
        data.append("discountP", JSON.stringify(discountBy));
        data.append("total", JSON.stringify(total));
        data.append("discountPercentage", JSON.stringify(discountPercentage));

        // data.append("supplier", JSON.stringify(medicinemanufacturerid));
        data.append("medicineNames", JSON.stringify(medicineNames));
console.log(JSON.stringify(values))


        // Make API call to add bed
        const response = await axios.post("/addpurchase", data, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        });

        if (response.status === 200) {
          return history.push("/pharmacypurchase");
        }
      } catch (error) {
        console.log(error)
        toast("Something Went Wrong")
      }
    },
  });

  // Function to load hospital codes
  const loadHospitalcode = async () => {
    if (userHospitalcode !== undefined && userrole1 !== undefined) {
      const res = await axios.get("/gethospitalcode", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {

        },
      });
      setData(res.data);
      console.log("kjdfbndkjfv", res.data)
    };
  }
  const [selectedCategory, setSelectedCategory] = useState("");
  const [searchValues, setSearchValues] = useState("");
  //Hospital code search
  const onSearchItems = (searchTerm) => {
    console.log("------", searchTerm)
    setSearchValues(searchTerm);
    if (searchTerm && searchTerm.hospitalcode) {
      setValues({
        ...values,
        hospitalcode: searchTerm.hospitalcode,
        // Update other fields as needed
      });
    }
  };
const handleSupplierSearch = (searchTerm) => {
  console.log("------", searchTerm)

  if (searchTerm && searchTerm.medicinemanufacturerid) {
    setValues({
      ...values,
      supplier: searchTerm.medicinemanufacturerid,
      // Update other fields as needed
    });
  }
};
  const handleChanges = (e, index, field, searchvalue) => {
    const { value } = e.target;
    const medicinecode = searchvalue ? searchvalue.medicinecode : "";

    setMedicines((prevMedicines) => {
      return prevMedicines.map((medicine, i) => {
        if (i === index) {
          // Update the field value according to the field being changed
          const updatedMedicine = {
            ...medicine,
            [field]: field === 'medicinecode' ? medicinecode : value,
            batchno: field === 'batchno' ? value : medicine.batchno || "",
            expirydate: field === 'expirydate' ? value : medicine.expirydate || "",
            mrp: field === 'mrp' ? value : medicine.mrp || "",
            packquantity: field === 'packquantity' ? value : medicine.packquantity || "",
            quantity: field === 'quantity' ? value : medicine.quantity || "",
            purchaseprice: field === 'purchaseprice' ? value : medicine.purchaseprice || "",
            saleprice: field === 'saleprice' ? value : medicine.saleprice || "",
            tax: field === 'tax' ? value : medicine.tax || "",
          };

          // Calculate the amount for the updated medicine
          const quantity = parseFloat(updatedMedicine.quantity) || 0;
          const purchaseprice = parseFloat(updatedMedicine.purchaseprice) || 0;
          const tax = parseFloat(updatedMedicine.tax) || 0;
          const amount = (quantity * purchaseprice) + ((quantity * purchaseprice * tax) / 100); // Calculate amount with tax
          updatedMedicine.amount = amount.toFixed(2); // Round to 2 decimal places

          return updatedMedicine;
        }
        return medicine;
      });
    });
  };
  const handleCategoryChangeForRow = async (newValue, index) => {
    const selectedCategory = newValue.medicinecategoryid; // Convert to string
    console.log(selectedCategory)
    setSelectedCategory(selectedCategory);
    setMedicines(prevMedicines => {
      const updatedMedicines = [...prevMedicines];
      if (updatedMedicines[index]) {
        updatedMedicines[index] = {
          medicinecategory: selectedCategory,

        };
      }
      return updatedMedicines;
    });
    await fetchMedicineNames(selectedCategory, index);
  };
  // Function to handle deletion of a specific row
  const handleDeleteRow = (index) => {
    setMedicines((prevMedicines) => {
      const updatedMedicines = prevMedicines.filter((_medicine, i) => i !== index);
      return updatedMedicines;
    });
  }
  // Function to fetch medicine category names based on hospital code
  const fetchMedicineCateoryNames = async (selectedHospitalCode) => {
    try {
      const response = await axios.get("/medicinecategory", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          hospitalcode: selectedHospitalCode,
        },
      });

      if (response.status === 200) {
        setCategory(response.data);
      } else {
        console.error(`Failed to fetch available floor numbers with status: ${response.status}`);
      }
    } catch (error) {
      console.error('Error fetching available floor numbers:', error.message);
    }
  };
  // Function to fetch medicine names based on selected category
  const fetchMedicineNames = async (selectedCategory, index) => {
    try {
      const response = await axios.get("/medicinenames", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          hospitalcode: values.hospitalcode || userHospitalcode,
          category: selectedCategory,
        },
      });

      if (response.status === 200) {
        setMedicineNames((prevMedicineNames) => {
          const updatedMedicineNames = { ...prevMedicineNames };
          updatedMedicineNames[index] = response.data;
          return updatedMedicineNames;
        });
      } else {
        console.error(`Failed to fetch medicine names with status: ${response.status}`);
      }
    } catch (error) {
      console.error('Error fetching medicine names:', error.message);
    }
  };
  useEffect(() => {
    const loadData = async () => {
      await loadHospitalcode();

      const selectedHospitalCode = values.hospitalcode || userHospitalcode;
      fetchMedicineCateoryNames(selectedHospitalCode);
      loadAllMedicineManufacturer(selectedHospitalCode);
      // Fetch the initial list of medicine names based on the initial category value
      //   if (values.medicinecategory) {
      //     await fetchMedicineNames(values.medicinecategory);
      //   }
    };
    loadData();
  }, [userHospitalcode, userrole1, isAuthorized, values.hospitalcode]);



  // Function to load all medicine manufacturers based on selected hospital code
  const loadAllMedicineManufacturer = async (selectedHospitalCode) => {
    try {
      const res = await axios.get("/getmedicinemanufacturer", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          hospitalcode: selectedHospitalCode,
        },
      });

      if (res.status === 200) {
        setSupplier(res.data);
      } else {
        console.error(`Failed to fetch supplier names with status: ${res.status}`);
      }
    } catch (error) {
      console.error('Error fetching supplier names:', error.message);
    }
  };
  useEffect(() => {
    const totalAmount = medicines.reduce((acc, curr) => acc + parseFloat(curr.amount || 0), 0);
    setTotal(totalAmount);
  }, [medicines]);

  useEffect(() => {
    const calculateFinalAmount = () => {
      let calculatedFinalAmount = total + parseFloat(extraCharge) - (total * parseFloat(discountPercentage) / 100);
      setFinalAmount(calculatedFinalAmount.toFixed(2));
    };
    calculateFinalAmount();
  }, [total, extraCharge, discountPercentage]);
  return (
    <div>
      <form onSubmit={handleSubmit} >
        <div className="patient-new primary-info">
          <div className="row  ml-4">
            <div className="col-md-12 ">
              <div className="row">


                <div className="col-md-6">
                  <div className="row">
                    <div className="col-md-4">
                      <label className="col-form-label">Select Supplier</label>
                    </div>
                    <div className="col-md-4">
                    <SearchSupplier onSupplierChange={handleSupplierSearch} hospitalvalue= {values.hospitalcode || userHospitalcode}/>
                      {/* <select
                        type="text"
                        autoComplete="off"
                        name="supplier"
                        id="supplier"
                        placeholder="Select Supplier"
                        value={values.supplier}
                        onChange={handleChange}
                        style={{ width: '100%', height: '32px', border: '1px solid #ced4da', color: '#495057' }}
                      >
                        <option>Select</option>
                        
                        {supplier.map((supplierItem) => (
                          <option key={supplierItem.medicinemanufacturerid} value={supplierItem.medicinemanufacturerid}>
                            {supplierItem.suppliername}
                          </option>
                        ))}
                      </select> */}
                      {isAuthorized && errors.supplier && touched.supplier ? (
                        <p className="form-erroremployee">{errors.supplier}</p>
                      ) : null}
                    </div>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="row">
                    {isAuthorized ? (<div className="col-md-12 mt-3">
                      <div className="row">
                        <div className="col-md-4">
                          <label
                            htmlFor="hospitalcode"
                            className="col-form-label leftone"
                          >
                            Hospital Code
                          </label>
                        </div>
                        <div className="col-md-8">
                          {/* Conditionally render the dropdown and input field */}
                          {isAuthorized ? (
                            <HospitalCodeSearch onSearchItem={onSearchItems} />
                          ) : (
                            <input
                              type="text"
                              name="hospitalcode"
                              id="hospitalcode"
                              value={userHospitalcode}
                              disabled={true} // Set the input as disabled
                              className="form-control "
                            />
                          )}
                          {isAuthorized && errors.hospitalcode && touched.hospitalcode ? (
                            <p className="form-erroremployee">{errors.hospitalcode}</p>
                          ) : null}
                        </div></div></div>) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <table>
            <thead>
              <tr style={{ paddingRight: '10px' }}>
                <th>Medicine Category</th>
                <th>Medicine</th>
                <th>Batch No </th>
                <th>Expiry Date</th>
                <th>MRP ($)</th>
                <th>Sale Price ($)</th>
                <th>Packing Qty</th>
                <th>Quantity</th>
                <th>Purchase Price ($) </th>
                <th>Tax</th>
                <th>Amount</th>
                <th>Delete</th>
              </tr>
            </thead>
            <tbody>
              {medicines.map((medicine, index) => (
                <tr key={index}>
                  <td style={{ paddingRight: '10px' }}>
                    <Autocomplete
                      freeSolo
                      id="free-solo"
                      options={category1}
                      getOptionLabel={(option) => option.medicinecategory || ""}
                      //sx={{ width: 230 }}
                      //    value={medicine.medicinecategory}
                      onChange={(e, newValue) => handleCategoryChangeForRow(newValue, index)}
                      renderInput={(params) =>
                        <TextField
                          {...params}
                          placeholder="Select Category"
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton onClick={params.inputProps.onClick}>
                                  <ExpandMoreIcon />
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                      }
                    />
                  </td>
                  <td>
                    <Autocomplete
                      freeSolo
                      id={`medicinecode-${index}`}
                      options={medicineNames[index] || []}
                      getOptionLabel={(option) => option.medicinename || ''}
                      //sx={{ width: 230 }}
                      // value={medicine.medicinename}
                      onChange={(e, newValue) => handleChanges(e, index, "medicinecode", newValue)}
                      renderInput={(params) =>
                        <TextField
                          {...params}
                          placeholder="Select Medicine"
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton onClick={params.inputProps.onClick}>
                                  <ExpandMoreIcon />
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                      }
                    />
                    {isAuthorized && errors.medicinecode && touched.medicinecode ? (
                      <p className="form-erroremployee">{errors.medicinecode}</p>
                    ) : null}
                  </td>
                  <td>
                    <input
                      type="text"
                      autoComplete="off"
                      name="batchno"
                      id="batchno"
                      placeholder="batchno"
                      value={medicine.batchno}
                      onChange={(e) => handleChanges(e, index, "batchno")}
                      style={{ width: '100%', height: '32px', border: '1px solid #ced4da ', color: '#495057' }}
                    />
                  </td>

                  <td>
                    <input
                      type="month"
                      autoComplete="off"
                      name="expirydate"
                      id="expirydate"
                      placeholder="MM/YYYY"
                      value={medicine.expirydate}
                      onChange={(e) => handleChanges(e, index, "expirydate")}

                      style={{ width: '100%', height: '32px', border: '1px solid #ced4da ', color: '#495057' }}
                    />
                    {/* <option>Select</option>
                                    
                                </select> */}
                  </td>
                  <td>
                    <input
                      type="text"
                      autoComplete="off"
                      name="mrp"
                      id="mrp"
                      placeholder="mrp"
                      value={medicine.mrp}
                      onChange={(e) => handleChanges(e, index, "mrp")}

                      style={{ width: '100%', height: '32px', border: '1px solid #ced4da ', color: '#495057' }}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      autoComplete="off"
                      name="saleprice"
                      id="saleprice"
                      placeholder="saleprice"
                      value={medicine.saleprice}
                      onChange={(e) => handleChanges(e, index, "saleprice")}

                      style={{ width: '100%', height: '32px', border: '1px solid #ced4da ', color: '#495057' }}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      autoComplete="off"
                      name="packquantity"
                      id="packquantity"
                      placeholder="packquantity"
                      value={medicine.packquantity}
                      onChange={(e) => handleChanges(e, index, "packquantity")}
                      style={{ width: '100%', height: '32px', border: '1px solid #ced4da ', color: '#495057' }}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      autoComplete="off"
                      name="quantity"
                      id="quantity"
                      placeholder="quantity"
                      value={medicine.quantity}
                      onChange={(e) => handleChanges(e, index, "quantity")}
                      style={{ width: '100%', height: '32px', border: '1px solid #ced4da ', color: '#495057' }}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      autoComplete="off"
                      name="purchaseprice"
                      id="purchaseprice"
                      placeholder="purchaseprice"
                      value={medicine.purchaseprice}
                      onChange={(e) => handleChanges(e, index, "purchaseprice")}

                      style={{ width: '100%', height: '32px', border: '1px solid #ced4da ', color: '#495057' }}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      autoComplete="off"
                      name="tax"
                      id="tax"
                      placeholder="tax"
                      value={medicine.tax}
                      onChange={(e) => handleChanges(e, index, "tax")}

                      style={{ width: '100%', height: '32px', border: '1px solid #ced4da ', color: '#495057' }}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      autoComplete="off"
                      name="amount"
                      id="amount"
                      placeholder="amount"
                      value={medicine.amount}
                      onChange={(e) => handleChanges(e, index, "amount")}

                      style={{ width: '100%', height: '32px', border: '1px solid #ced4da ', color: '#495057' }}
                    />
                  </td>
                  <td>
                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      style={{ width: 'auto' }}
                      onClick={(e) => handleDeleteRow(index)}
                    >
                      Delete
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <Button variant="contained" color="primary" size="small" style={{ width: 'auto' }}
            onClick={() => setMedicines([...medicines, {
              medicinecategory: '', medicinename: '', batchno: '',
              expirydate: '', mrp: "", packquantity: "", quantity: '', purchaseprice: "", saleprice: '', tax: "", amount: ""
            }])}>
            Add Medicine
          </Button>
          <div className="col-md-4" style={{ marginLeft: "64%" }}>
            <div className="row ml-4">
              <div className="col-md-12 mt-3">
                <div className="row">
                  <div className="col-md-1"></div>
                  <div className="col-md-5">
                    <label className="col-form-label">Total</label>
                  </div>
                  <div className="col-md-6">
                    <input
                      style={{ width: "100%" }}
                      type="number"
                      autoComplete="off"
                      name="total"
                      id="total"
                      placeholder="Total"
                      className="form-control"
                      value={total}
                      onChange={(e) =>
                        setTotal(parseFloat(e.target.value) || 0)
                      }
                    />
                  </div>
                </div>
              </div>

              <div className="col-md-12 mt-3" >
                <div className="row">
                  <div className="col-md-1"></div>
                  <div className="col-md-5">
                    <label className="col-form-label">Extra Charge</label>
                  </div>
                  <div className="col-md-6">
                    <input
                      style={{ width: "100%" }}
                      type="number"
                      autoComplete="off"
                      name="extraCharge"
                      id="extraCharge"
                      className="form-control"
                      placeholder="Extra Charge"
                      value={extraCharge}
                      onChange={(e) =>
                        setExtraCharge(parseFloat(e.target.value) || 0)
                      }
                    />
                  </div>
                </div>
              </div>

              <div className="col-md-12 mt-3">
                <div className="row">
                  <div className="col-md-1"></div>
                  <div className="col-md-5">
                    <label className="col-form-label">Discount By</label>
                  </div>
                  <div className="col-md-6">
                    <input
                      style={{ width: "100%" }}
                      type="text"
                      autoComplete="off"
                      name="discountBy"
                      id="discountBy"
                      className="form-control"
                      placeholder="Discount By"
                      value={discountBy || ""} // Access the value with the 'discountBy' key
                    //   onChange={(e) => handleEdit(e)}
                    />
                  </div>
                </div>
              </div>

              <div className="col-md-12 mt-3">
                <div className="row">
                  <div className="col-md-1"></div>
                  <div className="col-md-5">
                    <label className="col-form-label">Discount %</label>
                  </div>
                  <div className="col-md-6">
                    <input
                      style={{ width: "100%" }}
                      type="number"
                      autoComplete="off"
                      name="discountPercentage"
                      id="discountPercentage"
                      className="form-control"
                      placeholder="Discount Percentage"
                      value={discountPercentage}
                      onChange={(e) =>
                        setDiscountPercentage(parseFloat(e.target.value) || 0)
                      }
                    />
                  </div>
                </div>
              </div>

              <div className="col-md-12 mt-3">
                <div className="row">
                  <div className="col-md-1"></div>
                  <div className="col-md-5">
                    <label className="col-form-label">Final Amount</label>
                  </div>
                  <div className="col-md-6">
                    <input
                      style={{ width: "100%" }}
                      type="number"
                      autoComplete="off"
                      name="finalAmount"
                      id="finalAmount"
                      className="form-control"
                      placeholder="Final Amount"
                      value={finalAmount}
                      readOnly // Assuming this is a calculated field
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-11 mt-6 text-center">
            <Button type="submit" variant="contained" color="primary" size="small" style={{ width: 'auto' }}>
              Submit
            </Button>
          </div>
        </div>
      </form>
      <ToastContainer />
    </div>
  )
}

export default PharmacyPurchase