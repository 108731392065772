import React, { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import API from "../../helper/axios";
import axios from "../../helper/axios";
import { useUser } from '../../Provider';
import PrintComponentReport from './PrintComponentReport';
//import PrintComponent from './PrintComponent'; // Import PrintComponent
const PrintRadiologyReport = (props) => {
  const { userHospitalcode, userrole1, isAuthRole,isAuthorized,gettokenId,token } = useUser();
  const [data, setReportData] = useState([]);
  const [datafinding, setReportDataFinding] = useState([]);
  const [hospitalcodedata, setHospitalcodedata] = useState({});
  const [datas, setDatas] = useState([]);
console.log(props.radiologyreportid)
  useEffect(() => {
      const getRadiologyReport = async () => {
          try {
              const res = await axios.get(`/getradiologyreport/${props.billnumber}/${props.radiologyreportid}`, {
                  headers: {
                      Authorization: `Bearer ${token}`,
                  },
                  params: {
                    hospitalcode: userHospitalcode,
                    userrole: userrole1,
                  },
              });
              if (res.status === 200) {
                  setReportData(res.data);
                  setHospitalcodedata(prevHospitalcodedata => ({
                      ...prevHospitalcodedata,
                      ...res.data[0]
                  }));
              } else {
                  alert(`Unexpected status code: ${res.status}`);
              }
          } catch (error) {
              alert(`Data not found`);
          }
      };
      const getRadiologyReportFinding = async () => {
        try {
            const res = await axios.get(`/getradiologyreportfindings/${props.billnumber}/${props.radiologyreportid}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                params: {
                  hospitalcode: userHospitalcode,
                  userrole: userrole1,
                },
            });
            if (res.status === 200) {
              setReportDataFinding(res.data);
            } else {
                alert(`Unexpected status code: ${res.status}`);
            }
        } catch (error) {
            alert(`Data not found`);
        }
    };
      const loadHospitalLogo = async () => {
          if (hospitalcodedata.hospitalcode !== undefined) {
              try {
                  const res = await axios.get(`/gethospitallogo/${hospitalcodedata.hospitalcode}`, {
                      headers: {
                          Authorization: `Bearer ${token}`,
                      },
                      params: {
                          hospitalcode: hospitalcodedata.hospitalcode,
                      },
                  });
                  setDatas(res.data[0]);
              } catch (error) {
                  console.error(`Error loading hospital logo: ${error.message}`);
              }
          } else {
              console.warn('Hospital code is undefined. Skipping hospital logo loading.');
          }
      };

      const loadData = async () => {
          await getRadiologyReport();
          await getRadiologyReportFinding();
          if (hospitalcodedata.hospitalcode) {
              await loadHospitalLogo(hospitalcodedata.hospitalcode);
          }
      };
      loadData();
  }, [props.billnumber, token, hospitalcodedata.hospitalcode]);

  const commonContent = (
      <div>
          {datas.attachment2 ? (
              <img
                  src={`${API.defaults.baseURL}/uploadimage/attachtwo/${datas.attachment2}`}
                  style={{ width: '100%', marginBottom: "1px", height: "20%" }}
                  alt="Attachment 2"
              />
          ) : (
              <div>
                  <img
                      src={`${API.defaults.baseURL}/uploadimage/hospitallogo/${datas.hospitallogo}`}
                      style={{ width: "15%", marginBottom: "5px", float: "left" }}
                      alt="Hospital Logo"
                  />
                  <h4 style={{ textAlign: "center" }}>
                      {datas.hospitalname ? datas.hospitalname.toUpperCase() : "HOSPITAL NAME NOT AVAILABLE"}
                  </h4>
                  <h6 style={{ textAlign: "center" }}>
                      {datas.address}
                  </h6>
                  <h6 style={{ textAlign: "center" }}>
                      Mobile: +91-{datas.phone}
                  </h6>
              </div>
          )}
      </div>
  );

  const heading = (

      <table className="print-components">
          <thead>

              <tr>
                  <td><b>Bill Number:</b>{hospitalcodedata.billno} </td>
                  <td><b>Mobile:{hospitalcodedata.phoneno}</b></td>
              </tr>
              <tr>
                  <td><b>Booking Date:</b>{hospitalcodedata.billdate}</td>
                  <td><b>Name:</b>{hospitalcodedata.patientname}</td>
              </tr>
              <tr>
                  <td><b>Email:</b></td>
                  <td><b>Address:</b></td>
              </tr>
          </thead>
      </table>

  );  
  return (

    <PrintComponentReport commonContent={commonContent} heading={heading}>
    <Grid container direction="column" spacing={2}>
      <Grid item>
        <h1>Radiology Report</h1>
      </Grid>
       <Grid item>
      <div>
        <h5>Patient Information</h5>
        <p>Name:{hospitalcodedata.patientname} </p>
        <p>Age:{hospitalcodedata.age} </p>
        <p>Sex: {hospitalcodedata.gender}</p>
        <p>Medical History: {hospitalcodedata.medicalhistory}</p>
      </div>
    </Grid>
    <Grid item>
    <div>
        <h5>Imaging Study Information</h5>
        <p>Type of Imaging Study:{hospitalcodedata.radiologycategoryname}</p>
        <p>Body Part/Area Imaged: {hospitalcodedata.bodyPart}</p>
        <p>Reason for Study: {hospitalcodedata.reason}</p>
      </div>
    </Grid>
    <Grid item>
      <div>
        <h5>Summary of Findings</h5>
        <p>{hospitalcodedata.summaryfinding}</p>
      </div>
    </Grid>
    <Grid item>
      <div>
        <h5>Detailed Description of Findings</h5>
        <ul>
          {datafinding.map((finding, index) => (
            <li key={index}>
              <p>Findings: {finding.description}</p>
              <p>Location: {finding.location}</p>
            </li>
          ))}
        </ul>
      </div>
    </Grid>
       <Grid item>
      <div>
        <h5>Impression</h5>
        <p>{hospitalcodedata.impression}</p>
      </div>
    </Grid>
    <Grid item>
      <div>
        <h5>Recommendations</h5>
        <p>{hospitalcodedata.recommendations}</p>
      </div>
    </Grid>
    <Grid item>
      <div>
        <p>Signature: {hospitalcodedata.signature}</p>
        <p>Date:{hospitalcodedata.date} </p>
      </div>
    </Grid>
    {/* <Grid item>
      <div>
        <h5>Additional Notes</h5>
        <p>Patient scheduled for MRI next week.</p>
      </div>
    </Grid> */}
      
    </Grid>
    </PrintComponentReport>
  );
};

export default PrintRadiologyReport;
