import React, { useState, useEffect } from 'react';
import { Typography, Button, TextField, Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import { useUser } from '../../../Provider';
import { Operationvalidation } from "../../../helper/schemas";
import { useFormik } from "formik";
import { useParams } from 'react-router-dom/cjs/react-router-dom';
import axios from "../../../helper/axios";
import { ToastContainer, toast } from 'react-toastify';
import DoctorSearchIds from '../../../Component/DoctorSearchIds';
import SearchAnesthetist from '../../../Component/SearchAnesthetist';
import SearchOTTechnician from '../../../Component/SearchOTTechnician';
import SearchOTAssistant from '../../../Component/SearchOTAssistant';
import SearchOperationCategory from '../../../Component/SearchOperationCategory';
import SearchOperationName from '../../../Component/SearchOperationName';

const getCurrentDate = () => {
  const now = new Date();
  const year = now.getFullYear();
  const month = String(now.getMonth() + 1).padStart(2, '0');
  const day = String(now.getDate()).padStart(2, '0');
  const hours = String(now.getHours()).padStart(2, '0');
  const minutes = String(now.getMinutes()).padStart(2, '0');
  const currentDateTimeString = `${year}-${month}-${day}T${hours}:${minutes}`;
  return currentDateTimeString;
};
const initialValues = {
  doctorids: "",
  categoryid: "",
  operationdate: getCurrentDate(),
  assistant1: "",
  anesthetist: "",
  ott: "",
  remark: "",
  operationcode: "",
  assistant2: "",
  anesthesiatype: "",
  otassistant: "",
  result: ""
};
const OperationForm = ({ onOperationAdded }) => {
  const { user, userHospitalcode, userrole1, isAuthRole, isAuthorized, gettokenId, token } = useUser();
  const [isFormVisible, setFormVisibility] = useState(false);
  const { admitnumber } = useParams();
  console.log({ admitnumber })
  const [data, setData] = useState([]);
  const [doctor, setDoctor] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('');
  const loadHospitalcode = async () => {
    //when userHospitalcode and userrole1 is not undefined then execute /gethospitalcode
    if (userHospitalcode !== undefined && userrole1 !== undefined) {
      const res = await axios.get(`/getIPDhospitalcode/${admitnumber}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          hospitalcode: userHospitalcode, // Include the hospitalcode in the query parameters
          userrole: userrole1, // Include the userrole in the query parameters
        },
      });
      setData(res.data[0]);
      // console.log(res.data[0]);
    };
  }
  //on Category change
  const handleOperationChanges = (searchTerms) => {
    console.log("---------------", searchTerms)
    // setCategory(searchTerms); // Update the searchValue state with the search term
    const selectedCategory = searchTerms.categoryid;
    setSelectedCategory(selectedCategory);
    setValues({
      ...values,
      categoryid: searchTerms.categoryid,
    })
  };
  const handleMedicineChange = (searchTerms) => {
    console.log("---------------", searchTerms)
    // setOperationNames(searchTerms); // Update the searchValue state with the search term
    setValues({
      ...values,
      operationcode: searchTerms.operationcode,
    })
  };
  useEffect(() => {
    const loadData = async () => {
      await loadHospitalcode();
    };
    loadData();
  }, [userHospitalcode, userrole1, isAuthorized, data.hospitalcode]);
  const handleOperation = () => {
    setFormVisibility(!isFormVisible); // Toggle form visibility
  };
  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleReset,
    handleSubmit,
    resetForm,
    setValues,
  } = useFormik({
    initialValues,
    validationSchema: Operationvalidation,
    onSubmit: async (values, action) => {
      try {
        if (userHospitalcode !== undefined && userrole1 !== undefined) {
          const data = new FormData();
          data.append("document", JSON.stringify(values));
          console.log(JSON.stringify(values))
          data.append("selectedCategory", JSON.stringify(selectedCategory));
          const response = await axios.post(`/newoperation/${admitnumber}`, data, {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "multipart/form-data",
            },
          });
          if (response.status === 200) {
            onOperationAdded();
            toast.success("Data Saved")
          }
        }
      } catch (err) {
      }
      action.resetForm();
    },

  });
  //Doctor onSearch Function
  const handleSearchChanges = (searchTerms) => {
    console.log("---------------", searchTerms)
    //setDoctor(searchTerms); // Update the searchValue state with the search term
    setValues({
      ...values,
      doctorids: searchTerms.doctorcode,
      assistant1: searchTerms.doctorcode,
      assistant2: searchTerms.doctorcode,
    })
  };
  // Anesthetist onsearch
  const handleAnesthetistChanges = (searchTerms) => {
    console.log("---------------", searchTerms)
    setValues({
      ...values,
      anesthetist: searchTerms.anesthetistid,
    })
  };
  // OTTechnician onsearch
  const handleOTTechnicianChanges = (searchTerms) => {
    console.log("---------------", searchTerms)
    setValues({
      ...values,
      ott: searchTerms.ottechnicianid,
    })
  };
  // OT Assistant onsearch
  const handleOTAssistant = (searchTerms) => {
    console.log("---------------", searchTerms)
    setValues({
      ...values,
      otassistant: searchTerms.otassistantid,
    })
  };
  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography variant="h6" style={{ padding: '20px' }}>
          Add Operation
        </Typography>
        <Button variant="contained" color="primary" onClick={handleOperation}>
          {isFormVisible ? 'Close Form' : 'Add Operation'}
        </Button>
      </div>
      {isFormVisible && (
        <form onSubmit={handleSubmit} style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
          <div className="patient-new primary-info">
            <div className="row">
              <div className="col-md-12">
                <div className="row">
                  <div className="col-md-6">
                    <div className="col-md-12 mt-3">
                      <div className="row">
                        <div className="col-md-4">
                          <label
                            htmlFor="categoryid"
                            className="col-form-label leftone"
                          >
                            Operation Category
                          </label>
                        </div>
                        <div className="col-md-8">
                          <SearchOperationCategory onCategoryChange={handleOperationChanges}
                            hospitalvalue={data.hospitalcode} />
                          {errors.categoryid && touched.categoryid ? (
                            <p className="form-erroremployee">{errors.categoryid}</p>
                          ) : null}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12 mt-3">
                      <div className="row">
                        <div className="col-md-4">
                          <label
                            htmlFor="operationdate"
                            className="col-form-label leftone"
                          >
                            Operation Date
                          </label>
                        </div>
                        <div className="col-md-8">
                          <input
                            type="datetime-local"
                            autoComplete="off"
                            name="operationdate"
                            id="operationdate"
                            placeholder="Operation Date"
                            value={values.operationdate}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            min={getCurrentDate()}
                            className="form-control"
                          />
                          {errors.operationdate && touched.operationdate ? (
                            <p className="form-erroremployee">{errors.operationdate}</p>
                          ) : null}
                        </div>
                      </div>
                    </div>

                    <div className="col-md-12 mt-3">
                      <div className="row">
                        <div className="col-md-4">
                          <label
                            htmlFor="assistant1"
                            className="col-form-label "
                          >
                            Assistant Consultant 1
                          </label>
                        </div>
                        <div className="col-md-8">
                          <DoctorSearchIds
                            onSearchChanges={handleSearchChanges}
                            hospitalvalue={data.hospitalcode}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12 mt-3">
                      <div className="row">
                        <div className="col-md-4">
                          <label
                            htmlFor="anesthetist"
                            className="col-form-label "
                          >
                            Anesthetist
                          </label>
                        </div>
                        <div className="col-md-8">
                          <SearchAnesthetist
                            onSearchChanges={handleAnesthetistChanges}
                            hospitalvalue={data.hospitalcode}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12 mt-3">
                      <div className="row">
                        <div className="col-md-4">
                          <label
                            htmlFor="ott"
                            className="col-form-label "
                          >
                            OT Technician
                          </label>
                        </div>
                        <div className="col-md-8">
                          <SearchOTTechnician
                            onSearchChanges={handleOTTechnicianChanges}
                            hospitalvalue={data.hospitalcode}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12 mt-3">
                      <div className="row">
                        <div className="col-md-4">
                          <label
                            htmlFor="remark"
                            className="col-form-label "
                          >
                            Remark
                          </label>
                        </div>
                        <div className="col-md-8">
                          <input
                            type="text"
                            autoComplete="off"
                            name="remark"
                            id="remark"
                            placeholder="remark"
                            value={values.remark}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            style={{ width: '73%', height: '32px', border: '1px solid #ced4da ', color: '#495057' }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="col-md-12 mt-3">
                      <div className="row">
                        <div className="col-md-4">
                          <label
                            htmlFor="operationcode"
                            className="col-form-label leftone"
                          >
                            Operation Name
                          </label>
                        </div>
                        <div className="col-md-8">
                          <SearchOperationName onOperationChange={handleMedicineChange} selectedCategory={selectedCategory}
                            hospitalvalue={data.hospitalcode} />
                          {errors.operationcode && touched.operationcode ? (
                            <p className="form-erroremployee">{errors.operationcode}</p>
                          ) : null}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12 mt-3">
                      <div className="row">
                        <div className="col-md-4">
                          <label
                            htmlFor="doctorids"
                            className="col-form-label leftone"
                          >
                            Consultant Doctor
                          </label>
                        </div>
                        <div className="col-md-8">
                          <DoctorSearchIds
                            onSearchChanges={handleSearchChanges}
                            hospitalvalue={data.hospitalcode}
                          />
                          {errors.doctorids && touched.doctorids ? (
                            <p className="form-erroremployee">{errors.doctorids}</p>
                          ) : null}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12 mt-3">
                      <div className="row">
                        <div className="col-md-4">
                          <label
                            htmlFor="assistant2"
                            className="col-form-label "
                          >
                            Assistant Consultant 2
                          </label>
                        </div>
                        <div className="col-md-8">
                          <DoctorSearchIds
                            onSearchChanges={handleSearchChanges}
                            hospitalvalue={data.hospitalcode}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12 mt-3">
                      <div className="row">
                        <div className="col-md-4">
                          <label
                            htmlFor="anesthesiatype"
                            className="col-form-label "
                          >
                            Anesthesia Type
                          </label>
                        </div>
                        <div className="col-md-8">
                          <input
                            type="text"
                            autoComplete="off"
                            name="anesthesiatype"
                            id="anesthesiatype"
                            placeholder="Anesthesia Type"
                            value={values.anesthesiatype}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            style={{ width: '73%', height: '32px', border: '1px solid #ced4da ', color: '#495057' }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12 mt-3">
                      <div className="row">
                        <div className="col-md-4">
                          <label
                            htmlFor="otassistant"
                            className="col-form-label "
                          >
                            OT Assistant
                          </label>
                        </div>
                        <div className="col-md-8">
                          <SearchOTAssistant
                            onSearchChanges={handleOTAssistant}
                            hospitalvalue={data.hospitalcode}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12 mt-3">
                      <div className="row">
                        <div className="col-md-4">
                          <label
                            htmlFor="result"
                            className="col-form-label "
                          >
                            Result
                          </label>
                        </div>
                        <div className="col-md-8">
                          <input
                            type="text"
                            autoComplete="off"
                            name="result"
                            id="result"
                            placeholder="Result"
                            value={values.result}
                            onChange={handleChange}
                            onBlur={handleBlur}

                            className="form-control"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-11 mt-6 text-center">
            <Button type="submit" variant="contained" color="primary" size="small" style={{ width: 'auto' }}>
              Submit
            </Button>
          </div>
          <ToastContainer />
        </form>
      )}
    </div>
  );
};

export default OperationForm;



