import React, { useState, useEffect } from 'react';
import { FormControl, InputLabel, MenuItem, Select, Checkbox, ListItemText } from '@mui/material';
import { useUser } from '../../../Provider';
import axios from "../../../helper/axios";
const MultiSelectRadiologyTest = ({hospitalCode, onTestCategoriesChange }) => {
    const {userHospitalcode, userrole1,gettokenId,token  } = useUser();
  const [testCategories, setTestCategories] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const fetchTestCategories = async () => {
    try {
      if (hospitalCode !== undefined && userrole1 !== undefined) {
        const response = await axios.get("/getradiologycategory", {
            headers: {
              Authorization: `Bearer ${token}`,
            },
            params: {
              hospitalcode: hospitalCode,
              userrole: userrole1,
            },
          });
         console.log(response.data)
          if (response.status === 200) {
            // Assuming response.data is an array of test categories
            const testData = response.data;
            setTestCategories(testData);
          } else {
            console.error(`Failed to fetch test categories with status: ${response.status}`);
          }
      } else {
        console.log("userHospitalcode is undefined");
      }
    } catch (error) {
      alert(`No data found`);
    }
  };
  useEffect(() => {
    fetchTestCategories();
  }, [userrole1,userHospitalcode]); // Fetch test categories when selectedHospitalCode changes

  const handleChange = (event) => {
    const selectedValues = event.target.value;
    setSelectedOptions(selectedValues);
    onTestCategoriesChange(selectedValues); // Pass selected values to parent component
  };

  return (
    <FormControl fullWidth>
      <InputLabel id="multi-select-dropdown-label">Select Options</InputLabel>
      <Select
        labelId="multi-select-dropdown-label"
        id="multi-select-dropdown"
        multiple
        value={selectedOptions}
        onChange={handleChange}
        renderValue={(selected) => selected.join(', ')}
      >
        {testCategories.map((category) => (
          <MenuItem key={category.chargecode} value={category.radiologycategoryname}>
            <Checkbox checked={selectedOptions.indexOf(category.radiologycategoryname) > -1} />
            <ListItemText primary={category.radiologycategoryname} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export default MultiSelectRadiologyTest;
