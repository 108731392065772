import React, { useState, useEffect } from 'react';
import {  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper,TextareaAutosize } from '@mui/material';
import axios from "../../../helper/axios";
import { useUser } from "../../../Provider";
import { useParams } from 'react-router-dom/cjs/react-router-dom';
const BedTable = () => {
  const { admitnumber } = useParams();
  console.log({ admitnumber })
  const { user, userHospitalcode, userrole1, isAuthRole, isAuthorized, gettokenId,token } = useUser();
  const[bedData,setBedData]=useState([]) ;
  const getBedDetails = async () => {
    try {
        if (userHospitalcode !== undefined && userrole1 !== undefined) {
            const res = await axios.get(`/getipdbeddetails/${admitnumber}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },    
            });
            console.log(res.data)
            // Check if the response status code is  200
            if (res.status === 200) {
                setBedData(res.data);
            } else {
                // Handle unexpected response status codes (e.g., 404, 500, etc.) as errors.
                alert(`Request failed with status: ${res.status}`);
                // Optionally, you can set an error state or display an error message to the user.
            }
        }
    }
    catch (error) {
        // Handle network errors or other exceptions.
       console.log(`An error occurred: ${error.message}`);
        // Optionally, you can set an error state or display an error message to the user.
    }
};
useEffect(() => {
  const loadData = async () => {
      await getBedDetails()
  };
  loadData();
}, [userHospitalcode, userrole1, isAuthorized]);

    return (
        <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell style={{ fontWeight: 'bold' }}>Bed Group</TableCell>
              <TableCell style={{ fontWeight: 'bold' }}>Bed</TableCell>
              <TableCell style={{ fontWeight: 'bold' }}>From Date</TableCell>
              <TableCell style={{ fontWeight: 'bold' }}>To Date</TableCell>
              <TableCell style={{ fontWeight: 'bold' }}>Active Bed</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {bedData.map((row, index) => (
              <TableRow key={index}>
                <TableCell>{row.groupname}</TableCell>
                <TableCell>{row.bedname}</TableCell>
                <TableCell>{row.admitdate}</TableCell>
                <TableCell>{row.dischargedate}</TableCell>
                <TableCell>{row.dischargedate ? 'No' : 'Yes'}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };
  export default BedTable;