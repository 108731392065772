import React from 'react'
import UserRegistration from './StaffPages/UserRegistration'
import EditStaff from './StaffPages/EditStaff'
import ListViewStaff from './StaffPages/ListViewStaff'
import StaffDetailEdit from './StaffPages/StaffDetailEdit'
import Header from '../../Component/Header/Header'
import Sidebar from '../../Component/Sidebar'

import { UserProvider } from "../../Provider";

export const Staffreg = () => {

    // return (
    //   <div>
    //       <UserRegistration/>
    //   </div>
    // )
  
    return (
  
      <UserProvider> <div className=" ">
      <Header />
    <div className="row" style={{ marginTop: '50px' }}>
      {/* Sidebar */}
      <div className="col-md-2">
        <Sidebar />
      </div>
      
      {/* Main Content */}
      <div className="col-md-10">
        
      <UserRegistration/>
      </div>
    </div>
  </div></UserProvider>
    )
  
  }

  // 
  export const StaffEditComponent = () => {
    return (
      <UserProvider> <div className=" ">
      <Header />
    <div className="row" style={{ marginTop: '50px' }}>
      {/* Sidebar */}
      <div className="col-md-2">
        <Sidebar />
      </div>
      
      {/* Main Content */}
      <div className="col-md-10">
     
      <EditStaff />
      
      </div>
    </div>
  </div>     </UserProvider>  
    );
  };

  export const ListViewComponent = () => {
    return (
      <UserProvider>  <div className=" ">
        <Header />
        <div
          className="row"
          style={{ marginTop: "50px" }}
        >
          {/* Sidebar */}
          <div className="col-md-2">
            <Sidebar />
          </div>
  
          {/* Main Content */}
          <div className="col-md-10">
         
          <ListViewStaff />
        
          </div>
        </div>
      </div></UserProvider>
    );
  };




  export const StaffEditDetailsComponent = () => {
    return (
      <UserProvider>  <div className=" ">
      <Header />
    <div className="row" style={{ marginTop: '50px' }}>
      {/* Sidebar */}
      <div className="col-md-2">
        <Sidebar />
      </div>
      
      {/* Main Content */}
      <div className="col-md-10">
     
      <StaffDetailEdit />
      
      </div>
    </div>
  </div>
  </UserProvider>
    );
  };
  
  