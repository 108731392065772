import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import axios from "../../../helper/axios";
import { useUser } from "../../../Provider";
import { Helmet } from "react-helmet";
import { NavLink } from "react-router-dom";
import MUIDataTable from "mui-datatables";
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

const ListViewBed = () => {
  // Extract user details and functions from the context
  const {user, userHospitalcode, userrole1, isAuthRole, isAuthorized, gettokenId, token} = useUser();
  console.log(user, userHospitalcode, userrole1, isAuthRole, isAuthorized, gettokenId);

  // State variable to store bed data
  const [data, setData] = useState([]);

  // Get the current date
  const currentDate = new Date();
  const day = currentDate.getDate();
  const month = currentDate.getMonth() + 1; // Months are zero-indexed, so add 1  
  const year = currentDate.getFullYear();
  // Format the date as DD-MM-YYYY
  const formattedDate = `${day}-${month}-${year}`;

  // State variable to store user role
  const [userRole, setRole] = useState();
  
  // useEffect to load bed details on component mount or when dependencies change
  useEffect(() => {
    const loadData = async () => {
      await loadAllBeds();
    };

    loadData();
  }, [userHospitalcode, userrole1, isAuthorized]);

  // Function to fetch all bed details
  const loadAllBeds = async () => {
    try {
      if (userHospitalcode !== undefined && userrole1 !== undefined) {
        console.log("userHospitalcode:", userHospitalcode);
        console.log("userrole1:", userrole1);
        const res = await axios.get("/GetBeds", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: {
            hospitalcode: userHospitalcode,
            userrole: userrole1,
          },
        });
        setData(res.data);
        console.log(res.data);
      }
    } catch (error) {
      // Handle any error here.
      alert("Error fetching beds:", error);
    }
  };

  // Function to delete a bed
  const cancelBed = async (bedid) => {
    // Display a confirmation dialog
    const isConfirmed = window.confirm("Do you want to delete this bed ?");
    
    if (isConfirmed) {
      try {
        console.log("bedid:", bedid);
        await axios.post(`/deletebed/${bedid}`, null, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        // After successfully deleting the bed, refresh the bed list.
        loadAllBeds();
      } catch (error) {
        // Handle any error here.
        alert("Error deleting bed:", error);
      }
    } else {
      // User clicked "Cancel" in the confirmation dialog, do nothing.
    }
  };

  // Define columns for MUI DataTable based on user authorization
  let columns;
  if (isAuthorized) {
    columns = [
      {
        name: "bedname",
        label: "Bed Name",
        options: {
          filter: true,
          sort: true,
        }
      },
      {
        name: "bedtype",
        label: "Bed Type",
        options: {
          filter: true,
          sort: true,
        }
      },
      {
        name: "groupname",
        label: "Bed Group",
        options: {
          filter: true,
          sort: true,
        }
      },
      {
        name: "floorname",
        label: "Floor Name",
        options: {
          filter: true,
          sort: true,
        }
      },

      {
        name: "hospitalcode",
        label: "Hospital Code",
        options: {
          filter: true,
          sort: true,
          customBodyRender: (value) => (value !== null && value !== undefined) ? value.toString().padStart(5, '0') : ""
        }
      },
      {
        name: "",
        label: "Action",
        options: {
          customBodyRender: (value, tableMeta, updateValue) => {
            const newD = data.find((e) => {
              return (
                e.bedname === tableMeta.rowData[0] &&
                e.bedtype === tableMeta.rowData[1] &&
                e.groupname === tableMeta.rowData[2] &&
                e.floorname === tableMeta.rowData[3] &&
                e.hospitalcode === tableMeta.rowData[4]
              );
            });
            console.log('newD:', newD);
            return (
              <>
               <IconButton color="secondary" onClick={() => cancelBed(newD.bedid)}>
              <DeleteIcon />
            </IconButton>
                <NavLink to={`/editbed/${newD.bedid}`}>
                  {/* <button className="btn btn-primary btn-sm ml-2" style={{marginLeft: '30px'}}>
                    <FaEdit /> Edit
                  </button> */}
                    <IconButton color="primary" >
                <EditIcon />
              </IconButton>
                </NavLink>
              </>
            );
          },
        },
      },
    ];
  } else {
    columns = [
      {
        name: "bedname",
        label: "Bed Name",
        options: {
          filter: true,
          sort: true,
        }
      },
      {
        name: "bedtype",
        label: "Bed Type",
        options: {
          filter: true,
          sort: true,
        }
      },
      {
        name: "groupname",
        label: "Bed Group",
        options: {
          filter: true,
          sort: true,
        }
      },
      {
        name: "floorname",
        label: "Floor Name",
        options: {
          filter: true,
          sort: true,
        }
      },
      {
        name: "",
        label: "Action",
        options: {
          customBodyRender: (value, tableMeta, updateValue) => {
            const newD = data.find((e) => {
              return (
                e.bedname === tableMeta.rowData[0] &&
                e.bedtype === tableMeta.rowData[1] &&
                e.groupname === tableMeta.rowData[2] &&
                e.floorname === tableMeta.rowData[3] 
               
              );
            });
            console.log('newD:', newD);
            return (
              <>
               <IconButton color="secondary" onClick={() =>  cancelBed(newD.bedid)}>
              <DeleteIcon />
            </IconButton>

                <NavLink to={`/editbed/${newD.bedid}`}>
                <IconButton color="primary" >
                <EditIcon />
              </IconButton>
                </NavLink>
                 
              </>
            );
          },
        },
      },
    ];
  }

  // Options configuration for MUI DataTable
  const options = {
    selectableRows: false,
    onRowClick: ((props) => {
      console.log(props);
    }),
    downloadOptions: {
      filename: `Bed_list_${formattedDate}`, // Customize the filename here to download
      filterOptions: {
        useDisplayedColumnsOnly: true,
        useDisplayedRowsOnly: true,
      },
    },
  };

  // Get the current domain for the page title
  const currentDomain = window.location.hostname;

  return (
    <>
      <Helmet>
        <title>Bed List | {currentDomain} | Hospital Management Software | Smart Solutions for Hospital Efficiency</title>
      </Helmet>

      {/* Navigation links to other related pages */}
      <NavLink to='/addbed'><button className="btn btn-success btn-sm mr-2" style={{margin: "10px"}}>
        <svg xmlns="http://www.w3.org/2000/svg" width="19" height="18" style={{ paddingRight: '4px' }} viewBox="0 0 100 100">
          <line x1="10" y1="50" x2="90" y2="50" stroke="white" stroke-width="10" />
          <line x1="50" y1="10" x2="50" y2="90" stroke="white" stroke-width="10" />
        </svg>
        Add Bed
      </button></NavLink>

      <NavLink to='/listviewfloor'><button className="btn btn-success btn-sm mr-2" style={{margin: "10px"}}>
        <svg xmlns="http://www.w3.org/2000/svg" width="19" height="18" style={{ paddingRight: '4px' }} viewBox="0 0 100 100">
          <line x1="10" y1="50" x2="90" y2="50" stroke="white" stroke-width="10" />
          <line x1="50" y1="10" x2="50" y2="90" stroke="white" stroke-width="10" />
        </svg>
        Floor
      </button></NavLink>


      <NavLink to='/listviewbedgroup'><button className="btn btn-success btn-sm mr-2" style={{margin: "10px"}}>
        <svg xmlns="http://www.w3.org/2000/svg" width="19" height="18" style={{ paddingRight: '4px' }} viewBox="0 0 100 100">
          <line x1="10" y1="50" x2="90" y2="50" stroke="white" stroke-width="10" />
          <line x1="50" y1="10" x2="50" y2="90" stroke="white" stroke-width="10" />
        </svg>
        Bed Group
      </button></NavLink>


      <NavLink to='/listviewbedtype'><button className="btn btn-success btn-sm mr-2" style={{margin: "10px"}}>
        <svg xmlns="http://www.w3.org/2000/svg" width="19" height="18" style={{ paddingRight: '4px' }} viewBox="0 0 100 100">
          <line x1="10" y1="50" x2="90" y2="50" stroke="white" stroke-width="10" />
          <line x1="50" y1="10" x2="50" y2="90" stroke="white" stroke-width="10" />
        </svg>
        Bed Type
      </button></NavLink>

      {/* MUI DataTable for displaying bed list */}
      <MUIDataTable
        title={"Bed List"}
        data={data}
        columns={columns}
        options={options}
        className="custom-mui-datatable"
      />
    </>
  );
};

export default ListViewBed;