import React, { useState, useEffect } from "react";
import { Modal, } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import axios from "../../../helper/axios";
import { Helmet } from "react-helmet";
import { NavLink } from "react-router-dom";
import PrintTemplateBillingView from "../../../Component/PrintTemplateBillingView/PrintTemplateBillingView";
import MUIDataTable from "mui-datatables";
import IconButton from '@mui/material/IconButton';
import PrintIcon from '@mui/icons-material/Print';
import DeleteIcon from '@mui/icons-material/Delete';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { useUser } from "../../../Provider";
const ListViewBilling = () => {
  const {user, userHospitalcode, userrole1, isAuthRole,isAuthorized,gettokenId,token  } = useUser();
  const [data, setData] = useState([]);
  const [patientId, setPid] = useState();
  const [billId, setBid] = useState();
  const [isPopup, setIsPopup] = useState(false);
  const currentDate = new Date();
  const day = currentDate.getDate();
  const month = currentDate.getMonth() + 1; // Months are zero-indexed, so add 1
  const year = currentDate.getFullYear();
  const [dateRange, setDateRange] = useState({
    startDate: null,
    endDate: null,
    key: 'selection', // Add the 'key' property
  });
  const formatDate = (date) => {
    if (!date) return ''; // Handle the case when date is null or undefined

    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-indexed, so add 1
    const day = date.getDate().toString().padStart(2, '0');

    return `${year}-${month}-${day}`;
  };

  useEffect(() => {
    const loadData = async () => {
      await loadAllPatients();
    };

    loadData();
  }, [userrole1,userHospitalcode]);
  // Format the date as DD-MM-YYYY
  const formattedDate = `${day}-${month}-${year}`;
 // const token = sessionStorage.getItem("jwtToken");
  let columns;
  if (isAuthorized) {
   columns = [
    {
      name: "billnumber",
      label: "Bill Number",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta) => {
          // Assuming the patient id is also available in your data
          const billnumber= tableMeta.rowData[0]; // Adjust this index as needed

          return (
            <NavLink to={`/editbilling/${billnumber}`} className="addlink">{value}</NavLink>
          );
        }
      },
    },
    {
      name: "patientid",
      label: "Patient Id",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "patientname",
      label: "Patient Name",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "subtotal",
      label: "Bill Amount",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "createdon",
      label: "Billing Date",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "chargecodes", // New column for displaying chargecodes
      label: "Description",
      options: {
        filter: true,
        sort: true, // You can't sort this column
       // customBodyRender: This function is typically used to customize the rendering of cell content within the table's body.
        customBodyRender: (value) => value.join(", "), // Render chargecodes as a comma-separated list
      },
    },
    {
      name: "hospitalcode",
      label: "Hospital Code",
      options: {
       filter: true,
       sort: true,
       customBodyRender: (value) => (value !== null && value !== undefined) ? value.toString().padStart(5, '0') : ""
      }
     },
    
    {
      name: "",
      label: "Action",
      options: {
          customBodyRender: (value, tableMeta, updateValue) => {
            const newD = data.find((e) => {
              return (
                e.patientid == tableMeta.rowData[1]
                && e.patientname == tableMeta.rowData[2]
                && e.billnumber == tableMeta.rowData[0]
                && e.subtotal == tableMeta.rowData[3]
                && e.createdon == tableMeta.rowData[4]
              );
            });
            if (newD) {
          return (
            <>
              <IconButton color="primary" onClick={() => printForm(value, tableMeta, updateValue)}>
            <PrintIcon  />
          </IconButton>
            <IconButton color="secondary" onClick={() => cancelBilling(newD.billnumber)}>
              <DeleteIcon />
            </IconButton>
              {/* <button
                className="btn btn-danger btn-sm"
                onClick={() => cancelBilling(newD.billnumber)}
              >
                Cancel
              </button> */}
            </>
          );
            }
        },
      },
    },
  ];
}
else{
  columns = [
    {
      name: "billnumber",
      label: "Bill Number",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta) => {
          // Assuming the patient id is also available in your data
          const billnumber= tableMeta.rowData[0]; // Adjust this index as needed

          return (
            <NavLink to={`/editbilling/${billnumber}`} className="addlink">{value}</NavLink>
          );
        }
      },
    },
    {
      name: "patientid",
      label: "Patient Id",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "patientname",
      label: "Patient Name",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "subtotal",
      label: "Bill Amount",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "createdon",
      label: "Billing Date",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "chargecodes", // New column for displaying chargecodes
      label: "Description",
      options: {
        filter: true,
        sort: true, // You can't sort this column
       // customBodyRender: This function is typically used to customize the rendering of cell content within the table's body.
        customBodyRender: (value) => value.join(", "), // Render chargecodes as a comma-separated list
      },
    },
   
    {
      name: "",
      label: "Action",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          const newD = data.find((e) => {
            return (
              e.patientid == tableMeta.rowData[1]
              && e.patientname == tableMeta.rowData[2]
              && e.billnumber == tableMeta.rowData[0]
              && e.subtotal == tableMeta.rowData[3]
              && e.createdon == tableMeta.rowData[4]
            );
          });
          return (
            <>
             <IconButton color="primary" onClick={() => printForm(value, tableMeta, updateValue)}>
            <PrintIcon  />
          </IconButton>
             <IconButton color="secondary" onClick={() => cancelBilling(newD.billnumber)}>
              <DeleteIcon />
            </IconButton>
             
            </>
          );
        },
      },
    },
  ];
}
  // preprocessData: This function takes originalData as input, which seems to be an array of objects with properties like billid,
  // chargecode, etc. It processes this data to group rows with the same billid together and collect their chargecode values into an array. 
  // The result is an array of objects with unique billid values and their associated chargecodes.
  const preprocessData = (originalData) => {
    const processedData = [];
    const uniqueBillIds = new Set();

    originalData.forEach((row) => {
      const billId = row.billid;

      if (!uniqueBillIds.has(billId)) {
        uniqueBillIds.add(billId);
        processedData.push({
          ...row,
          chargecodes: [row.chargename], // Initialize an array with the first chargecode
        });
      } else {
        // Find the existing row with the same billId and add the chargecode
        const existingRow = processedData.find((item) => item.billid === billId);
        existingRow.chargecodes.push(row.chargename);
      }
    });

    return processedData;
  };

  
  const cancelBilling = async (billnumber) => {
    // Display a confirmation dialog
    const isConfirmed = window.confirm("Do you want to cancel this bill?");
  
    if (isConfirmed) {
      try {
        await axios.post(`/cancelbill/${billnumber}`, null, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        
        //alert("Bill has been canceled.");
        
        // After successfully canceling the bill, refresh the bill list.
        loadAllPatients();
      } catch (error) {
        // Handle any error here.
       alert("Error cancelling bill:", error);
      }
    } else {
      // User clicked "Cancel" in the confirmation dialog, do nothing.
    }
  };
  // loadAllPatients: This is an asynchronous function that makes a GET request to a backend API endpoint ("/GetPatientsBilling") to retrieve patient billing data. 
  // It sorts the data based on the appointmentdate property in descending order and then processes the sorted data using the preprocessData function.
  //  The processed data is stored in the data state variable.

  const loadAllPatients = async () => {
    try {
     if (userHospitalcode !== undefined && userrole1 !== undefined) {
        console.log(userHospitalcode);
      const res = await axios.get("/GetPatientsBilling", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          hospitalcode: userHospitalcode, // Include the hospitalcode in the query parameters
          userrole: userrole1, // Include the userrole in the query parameters
        },
      });
  console.log(res.data)
      if (res.status === 200) {
        const sortedList = [...res.data].sort((a, b) => {
          const dateA = new Date(a.appointmentdate);
          const dateB = new Date(b.appointmentdate);
          return dateB - dateA;
        });
        setData(preprocessData(sortedList));
        console.log(preprocessData(sortedList))
      } else {
        // Handle other status codes if needed
        alert(`Unexpected status code: ${res.status}`);
      }
    }else {
      console.log("userHospitalcode is undefined");
    }
    } catch (error) {
      // Handle the error here
     alert(`An error occurred: ${error.message}`);
  
      // You can display an error message to the user or take other actions as needed.
    }
  };
 
  //handleClose: This function is used to set the isPopup state to false, presumably to close a popup or modal.
  const handleClose = () => {
    setIsPopup(false)
  }

  const printForm = async (value, tableMeta, updateValue) => {
    const newD = data.find((e) => {
      return e.patientid == tableMeta.rowData[1]
        && e.patientname == tableMeta.rowData[2]
        && e.billnumber == tableMeta.rowData[0]
        && e.subtotal == tableMeta.rowData[3]
        && e.createdon == tableMeta.rowData[4]
    });

    await setBid(newD.billid);
    await setPid(tableMeta.rowData[1]);
    await setIsPopup(true);
  };

  const options = {
    selectableRows: false,
    onRowClick: ((props) => {
      console.log(props)
    }),
    downloadOptions: {
      filename: `Billing_${formattedDate}`,// Customize the filename here to download
      filterOptions: {
        useDisplayedColumnsOnly: true,
        useDisplayedRowsOnly: true,
      },
    },
  };
  const currentDomain = window.location.hostname;
  return (
    <>
      <div style={{marginLeft: "auto"}}>
      <Helmet>
        <title>Billing Records | {currentDomain} | Hospital Management Software | Smart Solutions for Hospital Efficiency</title>
      </Helmet>
      <NavLink to="/billingpage">
        <button className="btn btn-success btn-sm mr-2" style={{margin: "10px"}}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="19"
            height="18"
            style={{ paddingRight: "4px" }}
            viewBox="0 0 100 100"
          >
            <line
              x1="10"
              y1="50"
              x2="90"
              y2="50"
              stroke="white"
              stroke-width="10"
            />
            <line
              x1="50"
              y1="10"
              x2="50"
              y2="90"
              stroke="white"
              stroke-width="10"
            />
          </svg>
          New Bill
        </button>
      </NavLink>
      </div>
      <MUIDataTable
        title={"Bill List"}
        data={data}
        columns={columns}
        options={options}
        className="custom-mui-datatable"
      />


        {isPopup==true?
          <>

          <Modal show={true}  animation={false} onHide={handleClose} size="lg">
            <Modal.Header closeButton>
              <Modal.Title>Bill Id:- {billId}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            {billId==undefined?""
              :
              <PrintTemplateBillingView billId={billId} patientId={patientId} handleClose={handleClose}/>}
              {/* <PrintTemplateBillingView bId={billId} patientId={patientId} handleClose={handleClose}/> */}
            </Modal.Body>
          </Modal>
        </> 
        :
        ""}
    </>


  );
};

export default ListViewBilling;