import React, { useState, useEffect } from "react";
import { Table, Button, Modal, Form } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { IoTrashOutline } from "react-icons/io5";
import axios from "../../../helper/axios";
import { useUser } from "../../../Provider";
import { Helmet } from "react-helmet";
import { NavLink, useHistory } from "react-router-dom";
import MUIDataTable from "mui-datatables";

const DischargedView = () => {
  const {user, userHospitalcode, userrole1, isAuthRole,isAuthorized,gettokenId,token  } = useUser();
  console.log(user, userHospitalcode, userrole1, isAuthRole,isAuthorized,gettokenId )
  const history = useHistory();
  const [data, setData] = useState([]);
  const currentDate = new Date();
  const day = currentDate.getDate();
  const month = currentDate.getMonth() + 1; // Months are zero-indexed, so add 1  
  const year = currentDate.getFullYear();
  // Format the date as DD-MM-YYYY
  const formattedDate = `${day}-${month}-${year}`;
  //const token = sessionStorage.getItem("jwtToken");
  const [userRole, setRole] = useState();
  
//when page load or refresh happen this function will got called
useEffect(() => {
  const loadData = async () => {
    // await loadAllDetails();
    //loadAllAppointment(); will wait for loadAllDetails(); to got execute
    await loadAllPatients();
  };

  loadData();
}, [userHospitalcode, userrole1, isAuthorized]);


  const loadAllPatients = async () => {
    try{
    if (userHospitalcode !== undefined && userrole1 !== undefined) {
    const res = await axios.get("/getdischargeds", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
          params: {
            hospitalcode: userHospitalcode, // Include the hospitalcode in the query parameters
            userrole: userrole1, // Include the userrole in the query parameters
          },
    });
    setData(res.data);
    console.log(res.data);
  };}catch (error) {
    // Handle any error here.
   alert("Error deleteing hospital:", error);
  }
} 
 
  let columns;
  if (isAuthorized) {
   columns = [
    {
     name: "admitnumber",
     label: "IPD Number",
     options: {
      filter: true,
      sort: true,
      customBodyRender: (value, tableMeta) => {
        // Assuming the patient id is also available in your data
        const admitnumber = tableMeta.rowData[0]; // Adjust this index as needed
  
        return (
          <NavLink to={`/editipdpatientadmit/${admitnumber}`} className="addlink">{value}</NavLink>
         );
        }
     }
    },
    {
     name: "patientname",
     label: "Patient Name",
     options: {
      filter: true,
      sort: true,
    

     }
    },
 
    {
     name: "phoneno",
     label: "Mobile No",
     options: {
      filter: true,
      sort: true,
     }
    },
    // {
    //  name: "address",
    //  label: "Address",
    //  options: {
    //   filter: true,
    //   sort: true,
    //  }
    // },
    // {
    //  name: "bloodgroup",
    //  label: "Blood Group",
    //  options: {
    //   filter: true,
    //   sort: true,
    //   }
    // },
    {
      name: "hospitalcode",
      label: "Hospital Code",
      options: {
       filter: true,
       sort: true,
       customBodyRender: (value) => (value !== null && value !== undefined) ? value.toString().padStart(5, '0') : ""
      }
     },

    //  {
    //     name: "bedname",
    //     label: "Bed Name",
    //     options: {
    //      filter: true,
    //      sort: true,
    //      }
    //    },

       {
        name: "admitdate",
        label: "Admit Date",
        options: {
         filter: true,
         sort: true,
        }
       },

       {
        name: "dischargedate",
        label: "Dischared Date",
        options: {
         filter: true,
         sort: true,
        }
       },
    //  {
    //   name: "",
    //   label: "",
    //   options: {
    //     customBodyRender: (value, tableMeta, updateValue) => {
    //       const newD = data.find((e) => {
    //         return (
    //           e.admitnumber === tableMeta.rowData[0] &&
    //           e.patientname === tableMeta.rowData[1] &&
    //         //   e.gaurdianname === tableMeta.rowData[2] &&
    //           e.phoneno ===  tableMeta.rowData[2] &&
    //         //   e.address === tableMeta.rowData[4] &&
    //           // e.bloodgroup === tableMeta.rowData[3]&&
    //           e.hospitalcode === tableMeta.rowData[3] &&
    //           e.admitdate === tableMeta.rowData[4] &&
    //           e.dischargeddate === tableMeta.rowData[5]
    //         );
    //       });
    //       console.log('newD:', newD);
     
    //         if (newD) {
    //       return (
    //         <>
    //           <button
    //             className="btn btn-danger btn-sm"
    //             onClick={() => cancelAdmit(newD.admitnumber)}
    //           >
    //             Cancel
    //           </button>
    //         </>
    //       );
    //         }
    //     },
    //   },
    // },
   ];

  }
  else{
    columns = [
      {
       name: "admitnumber",
       label: "IPD Number",
       options: {
        filter: true,
        sort: true,
        // customBodyRender: (value, tableMeta) => {
        //   // Assuming the patient id is also available in your data
        //   const admitnumber = tableMeta.rowData[0]; // Adjust this index as needed
    
        //   return (
        //     <NavLink to={`/editipdpatientadmit/${admitnumber}`} className="addlink">{value}</NavLink>
        //    );
        //   }
       }
      },
      {
       name: "patientname",
       label: "Patient Name",
       options: {
        filter: true,
        sort: true,
      
      //  customBodyRender: (value, tableMeta) => {
      //   // Assuming the patient id is also available in your data
      //   const patientid = tableMeta.rowData[0]; // Adjust this index as needed
  
        // return (
        //   <NavLink to={`/listviewpatients/${patientid}`} className="addlink">{value}</NavLink>
        //  );
        // }
       }
      },
    //   {
    //    name: "gaurdianname",
    //    label: "Guardian Name",
    //    options: {
    //     filter: true,
    //     sort: true
    //    }
    //   },
      {
       name: "phoneno",
       label: "Mobile No",
       options: {
        filter: true,
        sort: true,
       }
      },
    //   {
    //    name: "address",
    //    label: "Address",
    //    options: {
    //     filter: true,
    //     sort: true,
    //    }
    //   },
      // {
      //  name: "bloodgroup",
      //  label: "Blood Group",
      //  options: {
      //   filter: true,
      //   sort: true,
      //   }
      // },
    //   {
    //     name: "bedname",
    //     label: "Bed Name",
    //     options: {
    //      filter: true,
    //      sort: true,
    //      }
    //    },

      {
        name: "admitdate",
        label: "Admit Date",
        options: {
         filter: true,
         sort: true,
        }
       },

       {
        name: "dischargedate",
        label: "Dischared Date",
        options: {
         filter: true,
         sort: true,
        }
       },
    //   {
    //     name: "",
    //     label: "",
    //     options: {
    //       customBodyRender: (value, tableMeta, updateValue) => {
    //         const newD = data.find((e) => {
    //           return (
    //             e.admitnumber === tableMeta.rowData[0] &&
    //           e.patientname === tableMeta.rowData[1] &&
    //         //   e.gaurdianname === tableMeta.rowData[2] &&
    //           e.phoneno ===  tableMeta.rowData[2] &&
    //         //   e.address === tableMeta.rowData[4] &&
    //           // e.bloodgroup === tableMeta.rowData[3] &&
    //           e.admitdate === tableMeta.rowData[3] &&
    //           e.dischargeddate === tableMeta.rowData[4]
    //           );
    //         });
    //         console.log('newD:', newD);
            
    //           return (
    //             <>
    //               <button
    //                 className="btn btn-danger btn-sm"
    //                 onClick={() => cancelAdmit(newD.admitnumber)}
    //               >
    //                 Cancel
    //               </button>
    //             </>
    //           );
              
    //       },
    //     },
    //   },
     ];
  }
   const options = {
    selectableRows: false,
    onRowClick: ((props) => {
      console.log(props)
       }),
    downloadOptions: {
      filename: `Patient_list_${formattedDate}`, // Customize the filename here to download
      filterOptions: {
        useDisplayedColumnsOnly: true,
        useDisplayedRowsOnly: true,
        },
    },
  };
  //  const options = {
  //   selectableRows: false,
  //   onRowClick: ((props) => {
  //     console.log(props)
  //   })
  // };
  const currentDomain = window.location.hostname;

  return (

    <>
     <Helmet>
        <title> Discharged List | {currentDomain} | Hospital Management Software | Smart Solutions for Hospital Efficiency</title>
      </Helmet>
      {/* <NavLink to='/dischargedview'><button className="btn btn-success btn-sm mr-2" style={{margin: "10px"}}>  <svg xmlns="http://www.w3.org/2000/svg" width="19" height="18" style={{ paddingRight: '4px' }} viewBox="0 0 100 100">
        <line x1="10" y1="50" x2="90" y2="50" stroke="white" stroke-width="10" />
        <line x1="50" y1="10" x2="50" y2="90" stroke="white" stroke-width="10" />
      </svg>Discharged Patient List</button></NavLink> */}
       <MUIDataTable
        title={"IPD Discharged Patient"}
        data={data}
        columns={columns}
        options={options}
        className="custom-mui-datatable"
      />
    </>

   
  );
};

export default DischargedView;
