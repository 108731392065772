import React from "react";
import { useFormik } from "formik";
import { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useHistory } from "react-router-dom";
import axios from "../../../helper/axios";
import { Table, Button, Modal, Form } from "react-bootstrap";
import { patientregistration } from "../../../helper/schemas";
import { ToastContainer, toast } from 'react-toastify';
import * as Yup from "yup";  // Import Yup for validation
import { useUser } from "../../../Provider";
import HospitalCodeSearch from "../../../Component/HospitalCodeSearch";

const ValidationSchema = Yup.object().shape({
 categoryname: Yup.string().required('Enter the categoryname'),
 hospitalcode: Yup.string().required("Please select the hospitalcode"),
 chargetype:Yup.string().required('Enter the chargetype'),
 chargeamount:Yup.number()
 .required('Enter the chargeamount') 
 .positive("chargeamount must be a positive number"),
 tax: Yup.number().min(0, "Tax must be a non-negative number")
});
const ValidationSchema1 = Yup.object().shape({
  categoryname: Yup.string().required('Enter the categoryname'),
  chargetype:Yup.string().required('Enter the chargetype'),
  chargeamount:Yup.number()
  .required('Enter the chargeamount') 
  .positive("chargeamount must be a positive number"),
  tax: Yup.number().min(0, "Tax must be a non-negative number")
});

const initialValues = {
    categoryname: "",
    chargetype:"",
    chargeamount: "",
    tax:0,
    hospitalcode:"" 
};

const AddPathologyCategory = () => {
  const { user, userHospitalcode, userrole1, isAuthRole, isAuthorized, gettokenId,token } = useUser();
  const history = useHistory();
  const [message, setMessage] = useState("");
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  // const [category1, setCategory] = useState([]);
  // initialised isAuthorized value as false
  // const [isAuthorized, setIsAuthorized] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  //get jwttoken from sessionStorage
  // const token = sessionStorage.getItem("jwtToken");
  //get current date
  let Showdate = new Date();
  let ShowTodaydate =
    Showdate.getFullYear() +
    "-" +
    (Showdate.getMonth() + 1) +
    "-" +
    Showdate.getDate();
  const [currentDate, setCurrentDate] = useState(ShowTodaydate);

  const getValidationSchema = () => {
    if (userHospitalcode === 1) {
      return ValidationSchema;

    } else  {
      return ValidationSchema1;
    }
  }

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    resetForm,
    setValues
  } = useFormik({
    initialValues,
    validationSchema: getValidationSchema, // Apply validation schema
    onSubmit: async (values, action) => {
      try{
      const data = new FormData();
      data.append("document", JSON.stringify(values));
      data.append("currentDate", JSON.stringify(currentDate));
      data.append("gettokenId", JSON.stringify(gettokenId));
      data.append("userHospitalcode", JSON.stringify(userHospitalcode));
      console.log(JSON.stringify(values));
    //   console.log(JSON.stringify(hospitalcode));

      const response = await axios.post("/addpathologycategory", data, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      });
      console.log(
        "🚀 ~ file: Registration.jsx ~ line 11 ~ Registration ~ values",
        values
      );
      if (response.status === 200) {
        return history.push("/listviewpathologycategory");
      }
    } catch (error) {
      console.log(error)
      toast("Something Went Wrong")
    }
    },
  });


  // const handleSearchChange = async (searchTerm) => {
  //   setSearchValue(searchTerm);
  //   const selectedHospitalCode = searchTerm.hospitalcode || userHospitalcode;
  //   await fetchCateoryNames(selectedHospitalCode); // Fetch floor names based on the selected hospital code
  // };
  const handleReset = () => {
    // Reset the form using Formik's resetForm function
    resetForm();
  };
  const [data, setData] = useState([]);
  const [userRole, setRole] = useState();
  // const [gettokenId, setTokenId] = useState();
  // const [userHospitalcode, setUserHospitalcode] = useState();
  // const [userrole1, setUserrole1] = useState();
  // Function to get login user's details
  const [searchValues, setSearchValues] = useState("");
  //Hospital code search
  const onSearchItems = (searchTerm) => {
    console.log("------", searchTerm)
    setSearchValues(searchTerm);
    if (searchTerm && searchTerm.hospitalcode) {
      setValues({
        ...values,
        hospitalcode: searchTerm.hospitalcode,
        // Update other fields as needed
      });
    }
  };

  //when page load or refresh happen this function will got called
  useEffect(() => {
    const loadData = async () => {
      // await loadAllDetails();
      // loadHospitalcode(); will wait for loadAllDetails(); to got execute
      await loadHospitalcode();
      // const selectedHospitalCode = values.hospitalcode || userHospitalcode;
      // await fetchCateoryNames(selectedHospitalCode);
    };
    loadData();
  }, [userHospitalcode, userrole1, isAuthorized]);

  //  get all hospital 
  const loadHospitalcode = async () => {
    //when userHospitalcode and userrole1 is not undefined then execute /gethospitalcode
    if (userHospitalcode !== undefined && userrole1 !== undefined) {
      const res = await axios.get("/gethospitalcode", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          hospitalcode: userHospitalcode, // Include the hospitalcode in the query parameters
          userrole: userrole1, // Include the userrole in the query parameters
        },
      });
      setData(res.data);
      console.log(res.data);
    };
  }

  const currentDomain = window.location.hostname;

  return (
    <div className="p-3 pt-0 appointment">
       <Helmet>
        <title>Aarogyasaarthi | {currentDomain} | Add Pathology Category</title>
      </Helmet>
      <form onSubmit={handleSubmit}>
        
        <div class="fill header"><h3 class="float-left"> Add Pathology Category</h3> </div>
        <div className="patient-new primary-info">
          <div className="row">
            <div className="col-md-12">
              <div className="row">
                <div className="col-md-6">
                  <div className="col-md-12 mt-3">
                    <div className="row">
                      <div className="col-md-4">
                        <label
                          htmlFor="chargetype"
                          className="col-form-label leftone"
                        >
                          Charges Type
                        </label>
                      </div>
                      <div className="col-md-8">
                        <input
                          type="text"
                          autoComplete="off"
                          name="chargetype"
                          id="chargetype"
                          placeholder="Charge Type"
                          value={values.chargetype}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className="form-control ember-text-field text-left ember-view"
                        />
                        {errors.chargetype && touched.chargetype ? (
                          <p className="form-erroremployee">{errors.chargetype}</p>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12 mt-3">
              <div className="row">
  
                <div className="col-md-4">
                  <label
                    htmlFor="categoryname"
                    className="col-form-label leftone"
                  >
                   Category Name
                  </label>
                </div>
                <div className="col-md-8">
                    <input
                      type="text"
                      autoComplete="off"
                      name="categoryname"
                      id="categoryname"
                      placeholder="Category Name"
                      value={values.categoryname}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      style={{ width: '73%', height: '32px', border: '1px solid #ced4da', color: '#495057' }}
                    />                
                  {errors.categoryname && touched.categoryname ? (
                    <p className="form-erroremployee">{errors.categoryname}</p>
                  ) : null}
                </div></div></div>
                {isAuthorized ? (   <div className="col-md-12 mt-3">
                    <div className="row">
                      <div className="col-md-4">
                        <label
                          htmlFor="tax"
                          className="col-form-label "
                        >
                          Charges Tax
                        </label>
                      </div>
                      <div className="col-md-8">
                        <input
                          type="text"
                          autoComplete="off"
                          name="tax"
                          id="tax"
                          placeholder="Charge tax"
                          value={values.tax}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className="form-control ember-text-field text-left ember-view"
                        />
                        {errors.tax && touched.tax ? (
                          <p className="form-erroremployee">{errors.tax}</p>
                        ) : null}
                      </div>
                    </div>
                </div>  ) : null}
                </div>
                <div className="col-md-6">
                {isAuthorized ? (<div className="col-md-12 mt-3">
              <div className="row">
  
                <div className="col-md-4">
                  <label
                    htmlFor="hospitalcode"
                    className="col-form-label leftone"
                  >
                    Hospital Code
                  </label>
                </div>
                <div className="col-md-8">
                  {/* Conditionally render the dropdown and input field */}
                  {isAuthorized ? (
                      
                         <HospitalCodeSearch  onSearchItem={onSearchItems} />
                       
                    // <select
                    //   type="text"
                    //   autoComplete="off"
                    //   name="hospitalcode"
                    //   id="hospitalcode"
                    //   placeholder="Hospital Code"
                    //   value={values.hospitalcode}
                    //   onChange={handleChange}
                    //   onBlur={handleBlur}
                    //   style={{ width: '73%', height: '32px', border: '1px solid #ced4da', color: '#495057' }}
                    // >
                    //   <option>Select</option>
                    //   {data.map((code) => (
                    //     <option value={code.hospitalcode} key={code.id}>
                    //       {code.hospitalcode}
                    //     </option>
                    //   ))}
                    // </select>
                  ) : (
                    <input
                      type="text"
                      name="hospitalcode"
                      id="hospitalcode"
                      value={userHospitalcode}
                      disabled={true} // Set the input as disabled
                      className="form-control "
                    />
                  )}
                  {isAuthorized && errors.hospitalcode && touched.hospitalcode ? (
                    <p className="form-erroremployee">{errors.hospitalcode}</p>
                  ) : null}
                </div></div></div>) : null}
                <div className="col-md-12 mt-3">
                    <div className="row">
                      <div className="col-md-4">
                        <label
                          htmlFor="chargeamount"
                          className="col-form-label leftone"
                        >
                          Charge Amount
                        </label>
                      </div>
                      <div className="col-md-8">
                        <input
                          type="number"
                          autoComplete="off"
                          name="chargeamount"
                          id="chargeamount"
                          placeholder="Charge Amount"
                          value={values.chargeamount}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className="form-control ember-text-field text-left ember-view"
                        />
                        {errors.chargeamount && touched.chargeamount ? (
                          <p className="form-erroremployee">{errors.chargeamount}</p>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  {!isAuthorized ? (   <div className="col-md-12 mt-3">
                    <div className="row">
                      <div className="col-md-4">
                        <label
                          htmlFor="tax"
                          className="col-form-label "
                        >
                          Charges Tax
                        </label>
                      </div>
                      <div className="col-md-8">
                        <input
                          type="number"
                          autoComplete="off"
                          name="tax"
                          id="tax"
                          placeholder="Charge Tax"
                          value={values.tax}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className="form-control ember-text-field text-left ember-view"
                        />
                        {errors.tax && touched.tax ? (
                          <p className="form-erroremployee">{errors.tax}</p>
                        ) : null}
                      </div>
                    </div>
                </div>  ) : null}
              </div>
                </div>
                </div>
            </div>
          </div>
      
        <div className="col-md-6  mt-3">
          <Button
            type="submit"
            variant="success"
            className="mx-3"
          >
            Save
          </Button>
          <Button
            variant="primary"
            className="mx-3"
            onClick={handleReset}
          >
            Reset
          </Button>
        </div>
      </form>
    </div>
  );
};

export default AddPathologyCategory;
  
