
// export default ListOfPathologyReport;
import React, { useState, useEffect } from "react";
import { Table, Button, Modal, Form } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import axios from "../../../helper/axios";
import { IoTrashOutline } from "react-icons/io5";
import { Helmet } from "react-helmet";
import { NavLink, useHistory } from "react-router-dom";
import PrintTemplateBillingView from "../../../Component/PrintTemplateBillingView/PrintTemplateBillingView";
import MUIDataTable from "mui-datatables";
import IconButton from '@mui/material/IconButton';
import PrintIcon from '@mui/icons-material/Print';
import DeleteIcon from '@mui/icons-material/Delete';
import { DateRangePicker } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { useUser } from "../../../Provider";
import PathologyReportTemplate from "../../../Component/PrintReport/PathologyReport";
import File from "../../../Component/PrintReport/File";
import EditIcon from '@mui/icons-material/Edit';




const ListOfPathologyReport = () => {
  const {user, userHospitalcode, userrole1, userdesignation, isAuthRole,isAuthorized,gettokenId,token  } = useUser();
  console.log("++++++",userdesignation);
  const history = useHistory();
  const [data, setData] = useState([]);
  const [patientId, setPid] = useState();
  const [billnumber, setBid] = useState();
  const [isPopup, setIsPopup] = useState(false);
  const currentDate = new Date();
  const day = currentDate.getDate();
  const month = currentDate.getMonth() + 1; // Months are zero-indexed, so add 1
  const year = currentDate.getFullYear();
  const [dateRange, setDateRange] = useState({
    startDate: null,
    endDate: null,
    key: 'selection', // Add the 'key' property
  });
  const cancelBilling = async (billingno) => {
    // Display a confirmation dialog
    const isConfirmed = window.confirm("Do you want to cancel this bill?");
  
    if (isConfirmed) {
      try {
        await axios.post(`/cancelbillpathology/${billingno}`, null, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        
        //alert("Bill has been canceled.");
        
        // After successfully canceling the bill, refresh the bill list.
        loadReport();
      } catch (error) {
        // Handle any error here.
       alert("Error cancelling report:");
      }
    } else {
      // User clicked "Cancel" in the confirmation dialog, do nothing.
    }
  };
  useEffect(() => {
    const loadData = async () => {
     // await loadAllDetails();
      //loadReport(); will wait for loadAllDetails(); to got execute
      await loadReport();
    };

    loadData();
  }, [userrole1,userHospitalcode]);
  // Format the date as DD-MM-YYYY
  const formattedDate = `${day}-${month}-${year}`;
 // const token = sessionStorage.getItem("jwtToken");
  let columns;
  if (isAuthorized) {
    columns = [
      {
        name: "billingno",
        label: "Bill Number",
        options: {
          filter: true,
          sort: true,
        },
      },
      {
        name: "patientname",
        label: "Patient Name",
        options: {
          filter: true,
          sort: true,
        },
      },
      {
        name: "createdon",
        label: "Report Date",
        options: {
          filter: true,
          sort: true,
        },
      },
      {
        name: "testcategory",
        label: "Test Name",
        options: {
          filter: true,
          sort: true,
          customBodyRender: (value) => value.join(", "),
        },
      },
      {
        name: "hospitalcode",
        label: "Hospital Code",
        options: {
          filter: true,
          sort: true,
          customBodyRender: (value) =>
            value !== null && value !== undefined ? value.toString().padStart(5, "0") : "",
        },
      },
      {
        name: "",
        label: "",
        options: {
          customBodyRender: (value, tableMeta, updateValue) => {
            const newD = data.find(
              (e) =>
                e.billingno === tableMeta.rowData[0] &&
                e.patientname === tableMeta.rowData[1] &&
                e.createdon === tableMeta.rowData[2] &&
                e.testcategory === tableMeta.rowData[3] &&
                e.hospitalcode === tableMeta.rowData[4]
            );
            if (newD) {
              return (
                <>
                  <IconButton color="primary" onClick={() => printForm(value, tableMeta, updateValue)}>
                    <PrintIcon />
                  </IconButton>
                      <IconButton color="secondary" onClick={() => cancelBilling(newD.billingno)}>
                        <DeleteIcon />
                      </IconButton>
                      {userdesignation === "Pathologist" && (
                    <>
                      <NavLink to={`/editpathology/${newD.billingno}`}>
                        <IconButton color="primary">
                          <EditIcon />
                        </IconButton>
                      </NavLink>
                    </>
                  )}
                </>
              );
            }
          },
        },
      },
    ];
  } else {
    columns = [
      {
        name: "billingno",
        label: "Bill Number",
        options: {
          filter: true,
          sort: true,
        },
      },
  
      {
        name: "patientname",
        label: "Patient Name",
        options: {
          filter: true,
          sort: true,
        },
      },
      {
        name: "createdon",
        label: "Report Date",
        options: {
          filter: true,
          sort: true,
        },
      },
      {
        name: "testcategory",
        label: "Test Name",
        options: {
          filter: true,
          sort: true,
          customBodyRender: (value) => value.join(", "),
        },
      },
      {
        name: "",
        label: "",
        options: {
          customBodyRender: (value, tableMeta, updateValue) => {
            const newD = data.find(
              (e) =>
                e.billingno === tableMeta.rowData[0] &&
                e.patientname === tableMeta.rowData[1] &&
                e.createdon === tableMeta.rowData[2] &&
                e.testcategory === tableMeta.rowData[3]
            );
            if (newD) {
              return (
                <>
                  <IconButton color="primary" onClick={() => printForm(value, tableMeta, updateValue)}>
                    <PrintIcon />
                  </IconButton>
                      <IconButton color="secondary" onClick={() => cancelBilling(newD.billingno)}>
                        <DeleteIcon />
                      </IconButton>
                      {userdesignation === "Pathologist" && (
                    <>
                      <NavLink to={`/editpathology/${newD.billingno}`}>
                        <IconButton color="primary">
                          <EditIcon />
                        </IconButton>
                      </NavLink>
                    </>
                  )}
                </>
              );
            }
          },
        },
      },
    ];
  }
  
  // preprocessData: This function takes originalData as input, which seems to be an array of objects with properties like billnumber,
  // chargecode, etc. It processes this data to group rows with the same billnumber together and collect their chargecode values into an array. 
  // The result is an array of objects with unique billnumber values and their associated testcategory.
  // const preprocessData = (originalData) => {
  //   const processedData = {};
    
  //   originalData.forEach((row) => {
  //     const billnumber = row.billingno;
  
  //     if (!processedData[billnumber]) {
  //       processedData[billnumber] = {
  //         billingno: billnumber,
  //         patientname: row.patientname,
  //         createdon: row.createdon,
  //         testcategory: [row.categoryname],
  //         hospitalcode: row.hospitalcode,
  //       };
  //     } else {
  //       processedData[billnumber].testcategory.push(row.categoryname);
  //     }
  //   });
  
  //   return Object.values(processedData);
  // };
  const preprocessData = (originalData) => {
    const processedData = {};
  
    originalData.forEach((row) => {
      const billnumber = row.billingno;
  
      if (!processedData[billnumber]) {
        processedData[billnumber] = {
          billingno: billnumber,
          patientname: row.patientname,
          createdon: row.createdon,
          testcategory: new Set([row.categoryname]), // Use a Set to store unique categorynames
          hospitalcode: row.hospitalcode,
          
        };
      } else {
        processedData[billnumber].testcategory.add(row.categoryname); // Add categoryname to the Set
      }
    });
  
    // Convert the Set values to an array
    Object.values(processedData).forEach((data) => {
      data.testcategory = Array.from(data.testcategory);
    });
  
    return Object.values(processedData);
  };
  
  const loadReport = async () => {
    try {
      if (userHospitalcode !== undefined && userrole1 !== undefined) {
        console.log(userHospitalcode);
        const res = await axios.get("/getpathologyreport", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: {
            hospitalcode: userHospitalcode,
            userrole: userrole1,
          },
        });
  
        console.log("{{{{{{{{{{{{{{{{{{",res.data);
  
        if (res.status === 200) {
          const sortedList = [...res.data].sort((a, b) => {
            const dateA = new Date(a.appointmentdate);
            const dateB = new Date(b.appointmentdate);
            return dateB - dateA;
          });
          
          setData(preprocessData(sortedList));
          console.log(preprocessData(sortedList));
        } else {
          alert(`No Pathology report found`);
        }
      } else {
        console.log("userHospitalcode is undefined");
      }
    } catch (error) {
      alert(`No Pathology report found`);
      console.lof(error)
    }
  };
  
  
  //handleClose: This function is used to set the isPopup state to false, presumably to close a popup or modal.
  const handleClose = () => {
    setIsPopup(false)
  }

  const printForm = async (value, tableMeta, updateValue) => {
    const newD = data.find((e) => {
      return e.billingno == tableMeta.rowData[0]
      && e.patientname == tableMeta.rowData[1]
      && e.createdon == tableMeta.rowData[2]
      && e.testcategory == tableMeta.rowData[3]
    });
    await setBid(tableMeta.rowData[0]);
    await setIsPopup(true);
  };

  const options = {
    selectableRows: false,
    onRowClick: ((props) => {
      console.log(props)
    }),
    downloadOptions: {
      filename: `Report_${formattedDate}`,// Customize the filename here to download
      filterOptions: {
        useDisplayedColumnsOnly: true,
        useDisplayedRowsOnly: true,
      },
    },
  };
  const currentDomain = window.location.hostname;
  return (
    <>
      <div style={{marginLeft: "auto"}}>
      <Helmet>
        <title>Report Records | {currentDomain} | Hospital Management Software | Smart Solutions for Hospital Efficiency</title>
      </Helmet>
      {/* <NavLink to="/addpathologyreport">
        <button className="btn btn-success btn-sm mr-2" style={{margin: "10px"}}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="19"
            height="18"
            style={{ paddingRight: "4px" }}
            viewBox="0 0 100 100"
          >
            <line
              x1="10"
              y1="50"
              x2="90"
              y2="50"
              stroke="white"
              stroke-width="10"
            />
            <line
              x1="50"
              y1="10"
              x2="50"
              y2="90"
              stroke="white"
              stroke-width="10"
            />
          </svg>
          Add Pathology Report
        </button>
      </NavLink> */}
      <NavLink to="/addpathologyreport">
  {userdesignation === "Pathologist" && (
    <button className="btn btn-success btn-sm mr-2" style={{ margin: "10px" }}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="19"
        height="18"
        style={{ paddingRight: "4px" }}
        viewBox="0 0 100 100"
      >
        <line
          x1="10"
          y1="50"
          x2="90"
          y2="50"
          stroke="white"
          strokeWidth="10" // Changed from stroke-width to strokeWidth
        />
        <line
          x1="50"
          y1="10"
          x2="50"
          y2="90"
          stroke="white"
          strokeWidth="10" // Changed from stroke-width to strokeWidth
        />
      </svg>
      Add Pathology Report
    </button>
  )}
</NavLink>
      </div>
      <MUIDataTable
        title={"Pathology Report List"}
        data={data}
        columns={columns}
        options={options}
        className="custom-mui-datatable"
      />


        {isPopup==true?
          <>

          <Modal show={true}  animation={false} onHide={handleClose} size="lg">
            <Modal.Header closeButton>
              {/* <Modal.Title>Bill Id:- {billnumber}</Modal.Title> */}
            </Modal.Header>
            <Modal.Body>
            {billnumber==undefined?""
              :
              // <PathologyReportTemplate billnumber={billnumber}  handleClose={handleClose}/>}
                 <File billnumber={billnumber}  handleClose={handleClose}/>}
            </Modal.Body>
          </Modal>
        </> 
        :
        ""}
    </>


  );
};

export default ListOfPathologyReport;