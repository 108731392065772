import React from "react";
import { NavLink } from "react-router-dom";
import Header from "../../Component/Header/Header";
import Sidebar from "../../Component/Sidebar";
import { UserProvider } from "../../Provider";
import AddMedicine from "./MedicinePage/AddMedicine";
import AddMedicineCategory from "./MedicinePage/AddMedicineCategory";
import ListViewMedicineCategory from "./MedicinePage/ListViewMedicineCategory";
import ListViewMedicine from "./MedicinePage/ListViewMedicine";
import EditMedicineCategory from "./MedicinePage/EditMedicineCategory";
import EditMedicine from "./MedicinePage/EditMedicine";
import AddMedicineUnit from "./MedicinePage/MedicineUnit";
import ListViewMedicineUnit from "./MedicinePage/ListViewMedicineUnit";
import EditMedicineUnit from "./MedicinePage/EditMedicineUnit";
import AddMedicineManufacturer from "./MedicinePage/AddMedicineManufacturer";
import ListViewMedicineManufacturer from "./MedicinePage/ListViewMedicineManufacturer";
import EditMedicineManufacturer from "./MedicinePage/EditMedicineManufacturer";
import MedicineBilling from "./MedicinePage/MedicineBilling";
import MedicineBillingLists from "./MedicinePage/MedicineBillingLists";
import PharmacyPurchaseList from "./MedicinePage/PharmacyPurchaseList";
import PharmacyPurchase from "./MedicinePage/PharmacyPurchase";
import EditPurchase from "./MedicinePage/EditPurchase";
import EditPharmacyBilling from "./MedicinePage/EditPharmacyBilling";



export const MedicineChategory = () => {
  return (

    <UserProvider>  <div className=" ">
    <Header />
  <div className="row" style={{ marginTop: '50px' }}>
    {/* Sidebar */}
    <div className="col-md-2">
      <Sidebar />
    </div>
    
    {/* Main Content */}
    <div className="col-md-10">
   <AddMedicineCategory/>
    </div>
  </div>
</div></UserProvider>
          
  );
};

export const ListViewMedicineChategory = () => {
  return (
    <UserProvider> <div className=" ">
      <Header />
      <div
        className="row"
        style={{ marginTop: "50px" }}
      >
        {/* Sidebar */}
        <div className="col-md-2">
          <Sidebar />
        </div>

        {/* Main Content */}
        <div className="col-md-10">
        <ListViewMedicineCategory/>
        </div>
      </div>
    </div></UserProvider>
  );
};

export const AddMedicines = () => {
  return (

    <UserProvider>  <div className=" ">
    <Header />
  <div className="row" style={{ marginTop: '50px' }}>
    {/* Sidebar */}
    <div className="col-md-2">
      <Sidebar />
    </div>
    
    {/* Main Content */}
    <div className="col-md-10">
    <AddMedicine/>
    </div>
  </div>
</div></UserProvider>
          
  );
};
export const ListViewMedicinesComponent = () => {
  return (
    <UserProvider> <div className=" ">
      <Header />
      <div
        className="row"
        style={{ marginTop: "50px" }}
      >
        {/* Sidebar */}
        <div className="col-md-2">
          <Sidebar />
        </div>

        {/* Main Content */}
        <div className="col-md-10">
        <ListViewMedicine/>
        </div>
      </div>
    </div> </UserProvider>
  );
};


export const EditMedicineCategoryComponent = () => {
  return (
    <UserProvider> <div className=" ">
    <Header />
  <div className="row" style={{ marginTop: '50px' }}>
    {/* Sidebar */}
    <div className="col-md-2">
      <Sidebar />
    </div>
    
    {/* Main Content */}
    <div className="col-md-10">
  
    <EditMedicineCategory/>
    
    </div>
  </div>
</div></UserProvider>
            
  );
};


export const EditMedicineComponent = () => {
  return (
    <UserProvider> <div className=" ">
    <Header />
  <div className="row" style={{ marginTop: '50px' }}>
    {/* Sidebar */}
    <div className="col-md-2">
      <Sidebar />
    </div>
    
    {/* Main Content */}
    <div className="col-md-10">
  
    <EditMedicine/>
    
    </div>
  </div>
</div></UserProvider>
            
  );
};

export const AddMedicineUnitComponent = () => {
  return (

    <UserProvider>  <div className=" ">
    <Header />
  <div className="row" style={{ marginTop: '50px' }}>
    {/* Sidebar */}
    <div className="col-md-2">
      <Sidebar />
    </div>
    
    {/* Main Content */}
    <div className="col-md-10">
    <AddMedicineUnit/>
    </div>
  </div>
</div></UserProvider>
          
  );
};


export const ListViewMedicineUnitComponent = () => {
  return (
    <UserProvider> <div className=" ">
      <Header />
      <div
        className="row"
        style={{ marginTop: "50px" }}
      >
        {/* Sidebar */}
        <div className="col-md-2">
          <Sidebar />
        </div>

        {/* Main Content */}
        <div className="col-md-10">
        <ListViewMedicineUnit/>
        </div>
      </div>
    </div> </UserProvider>
  );
};



export const EditMedicineUnitComponent = () => {
  return (
    <UserProvider> <div className=" ">
    <Header />
  <div className="row" style={{ marginTop: '50px' }}>
    {/* Sidebar */}
    <div className="col-md-2">
      <Sidebar />
    </div>
    
    {/* Main Content */}
    <div className="col-md-10">
  
    <EditMedicineUnit/>
    
    </div>
  </div>
</div></UserProvider>
            
  );
};



export const AddMedicineManufacturerComponent = () => {
  return (

    <UserProvider>  <div className=" ">
    <Header />
  <div className="row" style={{ marginTop: '50px' }}>
    {/* Sidebar */}
    <div className="col-md-2">
      <Sidebar />
    </div>
    
    {/* Main Content */}
    <div className="col-md-10">
    <AddMedicineManufacturer/>
    </div>
  </div>
</div></UserProvider>
          
  );
};



export const ListViewMedicineManufacturerComponent = () => {
  return (
    <UserProvider> <div className=" ">
      <Header />
      <div
        className="row"
        style={{ marginTop: "50px" }}
      >
        {/* Sidebar */}
        <div className="col-md-2">
          <Sidebar />
        </div>

        {/* Main Content */}
        <div className="col-md-10">
        <ListViewMedicineManufacturer/>
        </div>
      </div>
    </div> </UserProvider>
  );
};



export const EditMedicineManufacturerComponent = () => {
  return (
    <UserProvider> <div className=" ">
    <Header />
  <div className="row" style={{ marginTop: '50px' }}>
    {/* Sidebar */}
    <div className="col-md-2">
      <Sidebar />
    </div>
    
    {/* Main Content */}
    <div className="col-md-10">
  
    <EditMedicineManufacturer/>
    
    </div>
  </div>
</div></UserProvider>
  );
};
//medicine billing
export const MedicineBillings = () => {
  return (
    <UserProvider> <div className=" ">
    <Header />
  <div className="row" style={{ marginTop: '50px' }}>
    {/* Sidebar */}
    <div className="col-md-2">
      <Sidebar />
    </div>
    
    {/* Main Content */}
    <div className="col-md-10">
  
    <MedicineBilling/>
    
    </div>
  </div>
</div></UserProvider>
            
  );
};
//medicine billing list
export const MedicineBillingList = () => {
  return (
    <UserProvider> <div className=" ">
    <Header />
  <div className="row" style={{ marginTop: '50px' }}>
    {/* Sidebar */}
    <div className="col-md-2">
      <Sidebar />
    </div>
    
    {/* Main Content */}
    <div className="col-md-10">
  
    {/* <MedicineBilling/> */}
    <MedicineBillingLists/>
    </div>
  </div>
</div></UserProvider>
            
  );
};

//purchase list
export const PurchaseList = () => {
  return (
    <UserProvider> <div className=" ">
    <Header />
  <div className="row" style={{ marginTop: '50px' }}>
    {/* Sidebar */}
    <div className="col-md-2">
      <Sidebar />
    </div>
    
    {/* Main Content */}
    <div className="col-md-10">
  
    {/* <MedicineBilling/> */}
    <PharmacyPurchaseList/>
    </div>
  </div>
</div></UserProvider>
            
  );
};
//purchase list
export const PurchaseMedicine = () => {
  return (
    <UserProvider> <div className=" ">
    <Header />
  <div className="row" style={{ marginTop: '50px' }}>
    {/* Sidebar */}
    <div className="col-md-2">
      <Sidebar />
    </div>
    
    {/* Main Content */}
    <div className="col-md-10">
  
    {/* <MedicineBilling/> */}
    <PharmacyPurchase/>
    </div>
  </div>
</div></UserProvider>
            
  );
};



export const EditPurchaseComponent = () => {
  return (
    <UserProvider> <div className=" ">
    <Header />
  <div className="row" style={{ marginTop: '50px' }}>
    {/* Sidebar */}
    <div className="col-md-2">
      <Sidebar />
    </div>
    
    {/* Main Content */}
    <div className="col-md-10">
  
    <EditPurchase/>
    
    </div>
  </div>
</div></UserProvider>
            
  );
};


export const EditPharmacyBillingDetails = () => {
  return (
    <UserProvider> <div className=" ">
    <Header />
  <div className="row" style={{ marginTop: '50px' }}>
    {/* Sidebar */}
    <div className="col-md-2">
      <Sidebar />
    </div>
    
    {/* Main Content */}
    <div className="col-md-10">
  
    <EditPharmacyBilling/>
    
    </div>
  </div>
</div></UserProvider>
            
  );
};
