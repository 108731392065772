//in this patient details will be displayed and can edit the details of patients

import axios from "../../../helper/axios";
import React, { useEffect, useState } from "react";
import { NavLink, useHistory, useParams } from "react-router-dom";
import { GrEdit } from "react-icons/gr";
// import Cookies from "js-cookie";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Table, Button, Modal, Form } from 'react-bootstrap';
import { useUser } from "../../../Provider";
import { ToastContainer, toast } from 'react-toastify';
import { Helmet } from "react-helmet";
import { useFormik } from 'formik';
import * as Yup from "yup";
import { EditMedicineCategoryValidation, EditMedicineCategoryValidation1} from "../../../helper/schemas/index"




const initialValues = {
    medicinecategory: "",
    hospitalcode: "",
}


const EditMedicineCategory = () => {
    const { user, userHospitalcode, userrole1, isAuthRole, isAuthorized, gettokenId, token } = useUser();
    const history = useHistory();
  const { medicinecategoryid } = useParams();
  //   console.log("id " + Id);

  // const [gettokenId, setTokenId] = useState();
  const [gettokenRole, setTokenRole] = useState();
  const [data, setData] = useState([]);
  //const token = sessionStorage.getItem("jwtToken");
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  //when page load or refresh happen this function will got called
  useEffect(() => {
    const loadData = async () => {
      //loadHospitalcode(); will wait for loadAllDetails(); to got execute
      await loadHospitalcode();
      await loadAllCategory();
    };
    loadData();
  }, [userHospitalcode, userrole1, isAuthorized]);
  //get all hospital code
  const loadHospitalcode = async () => {
    //when userHospitalcode and userrole1 is not undefined then execute /gethospitalcode
    try{
    if (userHospitalcode !== undefined && userrole1 !== undefined) {
      const res = await axios.get("/gethospitalcode", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          hospitalcode: userHospitalcode, // Include the hospitalcode in the query parameters
          userrole: userrole1, // Include the userrole in the query parameters
        },
      });
      setData(res.data);
      console.log(res.data);
    };
  }catch(error){
    toast.error("Something Went Wrong")
  }
  }
 
  //get patient details(one person)
  const loadAllCategory = async () => {
    try {
      if (userHospitalcode !== undefined && userrole1 !== undefined) {
        const res = await axios.get(`/GetMedicineCategory/${medicinecategoryid}`, {
          headers: {
            Authorization: `Bearer ${token}`,
            Role: userrole1,
          },
          params: {
            hospitalcode: userHospitalcode, // Include the hospitalcode in the query parameters
            userrole: userrole1, // Include the userrole in the query parameters
          },
        }
        );
        const newProduct = await res.data;
        setValues(newProduct);
      }
    } catch(error){
      if (error.response.status === 403) {
        alert('Unauthorised');
      } else if (error.response.status === 401) {
        alert('Error: ' + error.response.data.error);
      } else {
        alert('Error: ' + error.response.data.error);
      }
    }
  };
  
 //get current date
 let Showdate = new Date();
 let ShowTodaydate =
   Showdate.getFullYear() +
   "-" +
   (Showdate.getMonth() + 1) +
   "-" +
   Showdate.getDate();
 const [currentDate, setCurrentDate] = useState(ShowTodaydate);


 const getValidationSchema = () => {
  if (userHospitalcode === 1) {
    return EditMedicineCategoryValidation;

  } else  {
    return EditMedicineCategoryValidation1;
  }
}



 const {
  values,
  errors,
  touched,
  setValues,
  handleChange,
  handleBlur,
  resetForm,
  handleSubmit,
} = useFormik({
  initialValues,
  validationSchema: getValidationSchema, // Apply validation schema
  onSubmit: async (values, { resetForm }) => {
     // console.log(values); // Log the values to check if they are captured correctly

     try {
      // Validate the form using Yup schema
     if (userHospitalcode !== undefined && userrole1 !== undefined) {
     const data = new FormData();
     data.append("document", JSON.stringify(values));
     data.append("currentDate", JSON.stringify(currentDate));
     data.append("gettokenId", JSON.stringify(gettokenId));
     const response = await axios.post(`/MedicineCategoryUpdate/${medicinecategoryid}`,data,
       {
         headers: {
           Authorization: `Bearer ${token}`,
           Role: userrole1,
           "Content-Type": "multipart/form-data",
         },
         params: {
           hospitalcode: userHospitalcode, // Include the hospitalcode in the query parameters
           userrole: userrole1, // Include the userrole in the query parameters
         },
       }
     );
     if (response.status === 200) {
       // setSuccess(true);
       // setError(false);
       return history.push("/listmedicinecatagory");
     } else {
       console.error("Server returned a non-200 status code:", response.status);
     }
   } }catch (err) {
  
    alert("Error occurred while submitting:", err);
   }
  },
});
const currentDomain = window.location.hostname;

  return (
    <>
     <Helmet>
        <title>Update Medicine Category Details | {currentDomain} | Hospital Management Software | Smart Solutions for Hospital Efficiency</title>
      </Helmet>
      <div className="appointment p-2">
        <form onSubmit={handleSubmit}>
          {/* <h5> Patient Registration Form</h5> */}
          <div class="fill header"><h3 class="float-left"> Update Medicine Category  </h3> </div>
          <div className="patient-new primary-info">
          <div className="row">
            <div className="col-md-12">
              <div className="row">
                <div className="col-md-6">

                  <div className="col-md-12 mt-3">
                    <div className="row">

                      <div className="col-md-4">
                        <label
                          htmlFor="medicinecategory"
                          className="col-form-label leftone"
                        >
                          Medicine Category
                        </label>
                      </div>
                      <div className="col-md-8">
                        <input
                          type="text"
                          autoComplete="off"
                          name="medicinecategory"
                          id="medicinecategory"
                          placeholder="Medicine Category"
                          value={values.medicinecategory}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          style={{ width: '73%', height: '32px', border: '1px solid #ced4da', color: '#495057' }}
                        />
                        {errors.medicinecategory && touched.medicinecategory ? (
                          <p className="form-erroremployee">{errors.medicinecategory}</p>
                        ) : null}
                      </div></div></div>

                </div>
                <div className="col-md-6">
                {isAuthorized ? (<div className="col-md-12 mt-3">
                    <div className="row">

                      <div className="col-md-4">
                        <label
                          htmlFor="hospitalcode"
                          className="col-form-label leftone"
                        >
                          Hospital Code
                        </label>
                      </div>
                      <div className="col-md-8">
                        {/* Conditionally render the dropdown and input field */}
                        {isAuthorized ? (
                          <input
                            type="text"
                            autoComplete="off"
                            name="hospitalcode"
                            id="hospitalcode"
                            placeholder="Hospital Code"
                            value={values.hospitalcode}
                            // onChange={handleChange}
                            // onBlur={handleBlur}
                            disabled={true} // Set the input as disabled
                            // style={{ width: '73%', height: '32px', border: '1px solid #ced4da', color: '#495057' }}
                            className="form-control "
                          >
                           
                          </input>
                        ) : (
                          <input
                            type="text"
                            name="hospitalcode"
                            id="hospitalcode"
                            value={userHospitalcode}
                            disabled={true} // Set the input as disabled
                            className="form-control "
                          />
                        )}
                        {isAuthorized && errors.hospitalcode && touched.hospitalcode ? (
                          <p className="form-erroremployee">{errors.hospitalcode}</p>
                        ) : null}
                      </div></div></div>) : null}






                </div>
              </div>
            </div>
          </div>
        </div>
          
          <div className="modal-buttons">
          <Button type="submit" variant="success" className="mx-3">
          Update
        </Button>
            {/* <button
              className="input-button"
              type="reset"
            >
              Cancel
            </button> */}
          </div>
          
        </form>
      </div>
    </>
  );
};
export default EditMedicineCategory;
