import React, { useState, useEffect } from 'react';
import { Typography, Button, TextField, Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import axios from "../../../helper/axios";
import { useUser } from "../../../Provider";
import { ToastContainer, toast } from 'react-toastify';
import Searchbar from '../../../Component/Searchbar';
import { useFormik } from "formik";
import PatientByHospital from '../../../Component/PatientByHospital';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';

// Initial form values
const initialValues = {
  hospitalcode: "",
  patientname:"",
  patientid:""
};

const EditPharmacyBilling = () => {
  // Use the 'useUser' hook to get user-related information
  const { user, userHospitalcode, userrole1, isAuthRole, isAuthorized, gettokenId, token } = useUser();
  const { medicinebillingid } = useParams();
  const [category1, setCategory] = useState([]);
  const [medicines, setMedicines] = useState([{ medicinecategoryid: '',medicinecode: '', batchno: '', expirydate: '', quantity: '', availablequantity: '', saleprice: '', tax: '', amount: '' }]);
  const [medicineNames, setMedicineNames] = useState({});
  const [searchValue, setSearchValue] = useState("");
  const [total, setTotal] = useState(0); // Initialize total to 0
  const [extraCharge, setExtraCharge] = useState(0);
  const [discountBy, setDiscountBy] = useState("");
  const [discountPercentage, setDiscountPercentage] = useState(0);
  const [finalAmount, setFinalAmount] = useState(0); // Initialize finalAmount to 0
  const [data, setData] = useState([])
  const [batchnos,setBatchNumbers] = useState({})
  let Showdate = new Date();
  let ShowTodaydate =
    Showdate.getFullYear() +
    "-" +
    (Showdate.getMonth() + 1) +
    "-" +
    Showdate.getDate();
  const [currentDate, setCurrentDate] = useState(ShowTodaydate);
  // Formik hook to manage form state and handle form submission
  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    resetForm,
    setValues,
  } = useFormik({
    initialValues,
    // validationSchema: getValidationSchema(), // Apply validation schema
    onSubmit: async (values, action) => {
          // Check if searchValue is empty
    // if (!searchValue) {
    //   toast.error('Please enter a search value.');
    //   return; // Stop form submission
    // }
     // Check if any of the medicinecategory field is empty
     const ismedicinecategory = medicines.some((test) => test.medicinecategoryid === "");
     console.log(ismedicinecategory)
     if (ismedicinecategory) {
       toast.error("Medicinecategory cannot be empty .");
       return;
     }
    // Check if any of the medicinename field is empty
    const ismedicinename = medicines.some((test) => test.medicinecode === "");
    console.log(ismedicinename)
    if (ismedicinename) {
      toast.error("Medicinename cannot be empty .");
      return;
    }
    // Check if any of the batchno field is empty
    const isbatchno = medicines.some((test) => test.batchno === "");
    console.log(isbatchno)
    if (isbatchno) {
      toast.error("Medicinename cannot be empty .");
      return;
    }
    // Check if any of the quantity field is empty
    const isAnyRateEmpty = medicines.some((test) => test.quantity === "");
    console.log(isAnyRateEmpty)
    if (isAnyRateEmpty) {
      toast.error("Quantity field cannot be empty .");
      return;
    }
       // Check if any of the amount field is empty
    const isAnyresult = medicines.some((test) => test.amount === "");
    if (isAnyresult) {
      toast.error("Amount field cannot be empty .");
      return;
    }
      try {
        const data = new FormData();
        data.append("document", JSON.stringify(values));
          data.append("medicines", JSON.stringify(medicines));
          data.append("extraCharge", JSON.stringify(extraCharge));
          data.append("discountBy", JSON.stringify(discountBy));
          data.append("discountPercentage", JSON.stringify(discountPercentage));
          data.append("finalAmount", JSON.stringify(finalAmount));
          data.append("total", JSON.stringify(total));
          data.append("gettokenId", JSON.stringify(gettokenId));
          data.append("searchValue", JSON.stringify(searchValue));
          data.append("currentDate", JSON.stringify(currentDate));
        console.log(medicines)
        console.log(extraCharge)
        console.log(finalAmount)
        console.log(total)
        const response = await axios.post("/pharmacy", data, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        });

        if (response.status === 200) {
          // return history.push("/listviewfloor");
        }
      } catch (error) {
        console.log(error);
        toast.error("Something Went Wrong");
      }
    },
  });
  const getPatientDetails = async () => {
    try {
      const res = await axios.get(`/getbillingpharmacy/${medicinebillingid}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (res.status === 200) {
        // If the request is successful (status code 200), set the patient data.
        //setPatientData(res.data[0]);
        setValues(res.data[0]);
      } else {
        // Handle unexpected response status codes, e.g., 404 or 500.
        alert(`Unexpected status code: ${res.status}`);
      }
    } catch (error) {
      // Handle network errors or other exceptions.
      alert(`An error occurred: ${error.message}`);
      // Optionally, you can set an error state or display an error message to the user.
    }
  };
  const getPatientBillDetails = async () => {
    try {
      const res = await axios.get(
        `/billingpharmacydetails/${medicinebillingid}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (res.status === 200) {
        // If the request is successful (status code 200), set the bill items data.
       // setMedicines(res.data);
        setMedicines(res.data);
        for (let i = 0; i < res.data.length; i++) {
          const medicineItem = res.data[i];
          if (medicineItem) {
    await fetchMedicineNames(medicineItem.medicinecategoryid, i);
    const selectedMedicineCode = medicineItem.medicinecode; // Assuming medicinecode is set in fetchMedicineNames
    await fetchBatchNumbers( values.hospitalcode,selectedMedicineCode, i);
    const selectedBatchNo = medicineItem.batchno;
    await fetchBatchNumberDetails(values.hospitalcode, selectedMedicineCode,selectedBatchNo, i)
}

        }
    
      } else {
        // Handle unexpected response status codes, e.g., 404 or 500.
        alert(`Unexpected status code: ${res.status}`);
      }
    } catch (error) {
      // Handle network errors or other exceptions.
      alert(`An error occurred: ${error.message}`);
      // Optionally, you can set an error state or display an error message to the user.
    }
  };
  const handleChanges = (event, index, field) => {
    const { name, value } = event.target;
    setMedicines((prevMedicines) => {
      const updatedMedicines = [...prevMedicines];
      if (updatedMedicines[index]) {
        updatedMedicines[index] = {
          ...updatedMedicines[index],
          [field]: value,
        };
      }
      return updatedMedicines;
    });
    
    // If the field being changed is "medicinename", fetch and update batch numbers
    if (field === "medicinecode") {
      const selectedMedicineCode = event.target.value;
      fetchBatchNumbers(values.hospitalcode,selectedMedicineCode,  index);
    }
  };
    // Function to handle category change for a specific row
    const handleCategoryChangeForRow = async (event, index) => {
      const selectedCategory = event.target.value;
      setMedicines((prevMedicines) => {
        const updatedMedicines = [...prevMedicines];
        if (updatedMedicines[index]) {
          updatedMedicines[index] = {
            ...updatedMedicines[index],
            medicinecategoryid: selectedCategory,
            medicinecode: "",
          };
        }
        return updatedMedicines;
      });
  
      await fetchMedicineNames(selectedCategory, index);
    };
  const handleChangeBatch = (e, index, field) => {
    const { value } = e.target;
    setMedicines((prevMedicines) => {
      const updatedMedicines = prevMedicines.map((medicine, i) => {
        if (i === index) {
          return { ...medicine, [field]: value };
        }
        return medicine;
      });
  
      // If either the medicine name or the batch number is changed, fetch batch details
      if (field === "medicinecode" || field === "batchno") {
        const selectedHospitalCode= value.hospitalcode;
        const selectedMedicineCode = field === "medicinecode" ? value : updatedMedicines[index].medicinecode;
        const selectedBatchNo = field === "batchno" ? value : updatedMedicines[index].batchno;
        fetchBatchNumbers(selectedHospitalCode, selectedMedicineCode, index);
        fetchBatchNumberDetails(selectedHospitalCode,selectedMedicineCode, selectedBatchNo, index);
      }
  
      // Calculate the amount for the medicine at the given index
      const updatedMedicine = updatedMedicines[index];
      console.log(updatedMedicine )
      //  const quantity = parseFloat(updatedMedicine.quantity) || 0;
      //  const salePrice = parseFloat(updatedMedicine.saleprice) || 0;
      // const tax = parseFloat(updatedMedicine.tax) || 0;
      //  const amount = (quantity * salePrice) + ((quantity * salePrice * tax) / 100); // Calculate amount with tax
      // updatedMedicine.amount = amount.toFixed(2); // Round to 2 decimal places
  
      return updatedMedicines;
    });
  };
  const handleChangeamount = (event, index, field) => {
    const { value } = event.target;
    setMedicines((prevMedicines) => {
      const updatedMedicines = prevMedicines.map((medicine, i) => {
        if (i === index) {
          return { ...medicine, [field]: value };
        }
        return medicine;
      });
  
      // Calculate the amount for the medicine at the given index
      const updatedMedicine = updatedMedicines[index];
      const quantity = parseFloat(updatedMedicine.quantity) || 0;
      const salePrice = parseFloat(updatedMedicine.saleprice) || 0;
      const tax = parseFloat(updatedMedicine.tax) || 0;
      const amount = (quantity * salePrice) + ((quantity * salePrice * tax) / 100); // Calculate amount with tax
      updatedMedicine.amount = amount.toFixed(2); // Round to 2 decimal places
  
      return updatedMedicines;
    });
  };
  
  // Function to fetch batch numbers based on selected medicine name
  const fetchBatchNumbers = async (selectedHospitalCode,selectedMedicineCode, index) => {
    try {
      const response = await axios.get("/batchnumbers", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          hospitalcode: selectedHospitalCode,
          medicinename: selectedMedicineCode,
        },
      });
     console.log(response.data)
      if (response.status === 200) {
        setBatchNumbers((prevBatchNumbers) => {
          const updatedBatchNumbers = { ...prevBatchNumbers };
          updatedBatchNumbers[index] = response.data;
          return updatedBatchNumbers;
        });
      } else {
        console.error(`Failed to fetch batch numbers with status: ${response.status}`);
      }
    } catch (error) {
      console.error('Error fetching batch numbers:', error.message);
    }
  };
const fetchBatchNumberDetails = async (selectedHospitalCode,selectedMedicineCode, selectedBatchNo, index) => {
  try {
    const response = await axios.get("/batchnumberdetails", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        hospitalcode: values.hospitalcode,
        medicinename: selectedMedicineCode,
        batchno: selectedBatchNo
      },
    });

    if (response.status === 200) {
      const batchDetails = response.data;

      setMedicines((prevMedicines) => {
        const updatedMedicines = [...prevMedicines];
        const updatedMedicine = { ...updatedMedicines[index] };
        updatedMedicine.saleprice = batchDetails[0].saleprice;
        updatedMedicine.tax = batchDetails[0].tax;
        updatedMedicine.expirydate = batchDetails[0].expirydate; // You might also want to update expirydate
        updatedMedicine.availablequantity = batchDetails[0].availablequantity;
        updatedMedicines[index] = updatedMedicine;
        return updatedMedicines;
      });
    } else {
      console.error(`Failed to fetch batch details with status: ${response.status}`);
    }
  } catch (error) {
    console.error('Error fetching batch details:', error.message);
  }
};

  // Function to handle deletion of a specific row
  const handleDeleteRow = (index) => {
    setMedicines((prevMedicines) => {
      const updatedMedicines = prevMedicines.filter((_medicine, i) => i !== index);
      return updatedMedicines;
    });
  }

  // Function to fetch medicine category names based on hospital code
  const fetchMedicineCateoryNames = async (selectedHospitalCode) => {
    try {
      const response = await axios.get("/medicinecategory", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          hospitalcode: selectedHospitalCode,
        },
      });

      if (response.status === 200) {
        setCategory(response.data);
      } else {
        console.error(`Error: ${response.status}`);
      }
    } catch (error) {
      console.error('Error :', error.message);
    }
  };

  // Function to fetch medicine names based on selected category
  const fetchMedicineNames = async (selectedCategory,index) => {
    try {
      const response = await axios.get("/medicinenames", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          hospitalcode: values.hospitalcode,
          category: selectedCategory,
        },
      });

      if (response.status === 200) {
        setMedicineNames((prevMedicineNames) => {
          const updatedMedicineNames = { ...prevMedicineNames };
          updatedMedicineNames[index] = response.data;
          return updatedMedicineNames;
        });
     
      } else {
        console.error(`Failed to fetch medicine names with status: ${response.status}`);
      }
    } catch (error) {
      console.error('Error fetching medicine names:', error.message);
    }
  };
  // Function to load hospital code information
  const loadHospitalcode = async () => {

    const res = await axios.get("/gethospitalcode", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    setData(res.data);
    console.log(res.data)
  }

  useEffect(() => {
    loadHospitalcode();
    const loadData = async () => {
      const selectedHospitalCode = values.hospitalcode;
      await  getPatientDetails();
      await  getPatientBillDetails();
      await fetchMedicineCateoryNames(selectedHospitalCode);
      await fetchMedicineNames(selectedHospitalCode)
      await fetchBatchNumbers(selectedHospitalCode)
      await fetchBatchNumberDetails(selectedHospitalCode)
    };
    loadData();
  }, [userHospitalcode, userrole1, isAuthorized,values.hospitalcode]);

  const handleSearchChange = async (searchTerm) => {
    if (searchTerm) {
      console.log(searchTerm);
      setSearchValue(searchTerm); // Update the searchValue state with the search term
    } else {
      setSearchValue("");
    }
  };

  // // Calculate total amount
  // useEffect(() => {
  //     const totalAmount = medicines.reduce((acc, curr) => acc + parseFloat(curr.amount || 0), 0);
  //     setTotal(totalAmount);
  // }, [medicines]);
  useEffect(() => {
    const totalAmount = medicines.reduce((acc, curr) => acc + parseFloat(curr.amount || 0), 0);
    setTotal(totalAmount);
  }, [medicines]);

  useEffect(() => {
    const calculateFinalAmount = () => {
      let calculatedFinalAmount = total + parseFloat(extraCharge) - (total * parseFloat(discountPercentage) / 100);
      setFinalAmount(calculatedFinalAmount.toFixed(2));
    };
    calculateFinalAmount();
  }, [total, extraCharge, discountPercentage]);

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <div>
          <div className="row ml-4">
            <div className="col-md-6">
              {/* Hospital Code section */}
              <div className="row">
                {isAuthorized && (
                  <div className="col-md-12 mt-3">
                    <div className="row">
                      <div className="col-md-4">
                        <label htmlFor="hospitalcode" className="col-form-label leftone">
                          Hospital Code
                        </label>
                      </div>
                      <div className="col-md-6">
                        {isAuthorized ? (
                          <input
                            type="text"
                            autoComplete="off"
                            name="hospitalcode"
                            id="hospitalcode"
                            placeholder="Hospital Code"
                            value={values.hospitalcode}
                            // onChange={(e) => {
                            //   handleChange(e);
                            //   const selectedHospitalCode = e.target.value;
                            //   fetchMedicineCateoryNames(selectedHospitalCode);
                            // }}
                            style={{ width: '100%', height: '32px', border: '1px solid #ced4da', color: '#495057' }}
                          />
                           
                        ) : (
                          <input
                            type="text"
                            name="hospitalcode"
                            id="hospitalcode"
                            value={userHospitalcode}
                            disabled={true}
                            className="form-control "
                          />
                        )}
                        {/* {isAuthorized && errors.hospitalcode && touched.hospitalcode ? (
                  <p className="form-erroremployee">{errors.hospitalcode}</p>
                ) : null} */}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="col-md-6">
              {/* Search Patient section */}
              <div className="row">
                <div className="col-md-12 mt-3">
                  <div className="row">
                    <div className="col-md-4">
                      <label className="col-form-label"> Patient</label>
                    </div>
                    <div className="col-md-6">
                    <input
                            type="text"
                            autoComplete="off"
                            name="patientname"
                            id="patientname"
                            placeholder="Hospital Code"
                            value={values.patientname}
                            // onChange={(e) => {
                            //   handleChange(e);
                            //   const selectedHospitalCode = e.target.value;
                            //   fetchMedicineCateoryNames(selectedHospitalCode);
                            // }}
                            style={{ width: '100%', height: '32px', border: '1px solid #ced4da', color: '#495057' }}
                          />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div>
          <table>
            <thead>
              <tr style={{ paddingRight: '10px' }}>
                <th>Medicine Category</th>
                <th>Medicine</th>
                <th>BatchNo </th>
                <th>Expiry Date</th>
                <th>Previous Quantity</th>
                <th>Available Qty</th>
                <th>Sale Price </th>
                <th>Tax</th>
                <th>Amount </th>
                <th>Delete</th>
              </tr>
            </thead>
            <tbody>
              {medicines.map((medicine, index) => (
                <tr key={index}>
                  <td style={{ paddingRight: '10px' }}>
                    <select
                      type="text"
                      autoComplete="off"
                      name="medicinecategoryid"
                      id="medicinecategoryid"
                      placeholder="Medicine Category"
                      value={medicine.medicinecategoryid}
                      onChange={(e) => handleCategoryChangeForRow(e, index, "medicinecategoryid")}
                      style={{ width: '100%', height: '32px', border: '1px solid #ced4da ', color: '#495057' }}
                    >
                      <option>Select</option>
                      {category1.map((code) => (
                        <option value={code.medicinecategoryid} key={code.medicinecategoryid}>
                          {code.medicinecategory}
                        </option>
                      ))}
                    </select>
                  </td>
                  <td>
                    <select
                      type="text"
                      autoComplete="off"
                      name="medicinecode"
                      id="medicinecode"
                      placeholder="Medicine Name"
                      value={medicine.medicinecode}
                      onChange={(e) => handleChanges(e, index, "medicinecode")}
                      style={{ width: '100%', height: '32px', border: '1px solid #ced4da ', color: '#495057' }}
                    >
                      <option>Select</option>
                      {medicineNames[index]?.map((name) => (
                        <option value={name.medicinecode} key={name.medicinecode}>
                          {name.medicinename}
                        </option>
                      ))}
                    </select>
                  </td>
                  <td>
                    <select
                      type="text"
                      autoComplete="off"
                      name="batchno"
                      id="batchno"
                      placeholder="batchno"
                      value={medicine.batchno}
                      onChange={(e) => handleChangeBatch(e, index, "batchno")}
                      style={{ width: '100%', height: '32px', border: '1px solid #ced4da ', color: '#495057' }}
                    >
                      <option>Select</option>
                      {batchnos[index]?.map((name) => (
                        <option value={name.batchno} key={name.batchno}>
                          {name.batchno}
                        </option>
                      ))}
                    </select>
                  </td>
                  <td>
                    <input
                      type="month"
                      autoComplete="off"
                      name="expirydate"
                      id="expirydate"
                      placeholder="MM/YYYY"
                      value={medicine.expirydate}
                      onChange={(e) =>handleChangeamount(e, index, "expirydate")}

                      style={{ width: '100%', height: '32px', border: '1px solid #ced4da ', color: '#495057',background:'#ced4da' }}
                    />
                    {/* <option>Select</option>
                                    
                                </select> */}
                  </td>
                  <td>
                    <input
                      type="text"
                      autoComplete="off"
                      name="quantity"
                      id="quantity"
                      placeholder="quantity"
                      value={medicine.quantity}
                      onChange={(e) =>handleChangeamount(e, index, "quantity")}
                      style={{ width: '100%', height: '32px', border: '1px solid #ced4da ', color: '#495057' }}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      autoComplete="off"
                      name="availablequantity"
                      id="availablequantity"
                      placeholder="availablequantity"
                      value={medicine.availablequantity}
                      disabled
                      style={{ width: '100%', height: '32px', border: '1px solid #ced4da ', color: '#495057',background:'#ced4da' }}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      autoComplete="off"
                      name="saleprice"
                      id="saleprice"
                      placeholder="saleprice"
                      value={medicine.saleprice}
                      disabled
                      style={{ width: '100%', height: '32px', border: '1px solid #ced4da ', color: '#495057',background:'#ced4da' }}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      autoComplete="off"
                      name="tax"
                      id="tax"
                      placeholder="tax"
                      value={medicine.tax}
                      disabled
                      style={{ width: '100%', height: '32px', border: '1px solid #ced4da ', color: '#495057',background:'#ced4da' }}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      autoComplete="off"
                      name="amount"
                      id="amount"
                      placeholder="amount"
                      value={medicine.amount}
                      onChange={(e) =>handleChangeamount(e, index, "amount")}

                      style={{ width: '100%', height: '32px', border: '1px solid #ced4da ', color: '#495057' }}
                    />
                  </td>
                  <td>
                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      style={{ width: 'auto' }}
                      onClick={(e) => handleDeleteRow(index)}
                    >
                      Delete
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <Button variant="contained" color="primary" size="small" style={{ width: 'auto' }}
            onClick={() => setMedicines([...medicines, { medicinecategoryid: '', medicinecode: '', batchno: '', expirydate: '', quantity: '', availablequantity: '', saleprice: '', tax: '', amount: '' }])}>
            Add Medicine
          </Button>
          <div className="col-md-4" style={{ marginLeft: "63%" }}>
            <div className="row ml-4">
              <div className="col-md-12 mt-3">
                <div className="row">
                  <div className="col-md-1"></div>
                  <div className="col-md-5">
                    <label className="col-form-label">Total</label>
                  </div>
                  <div className="col-md-6">
                    <input
                      style={{ width: "100%" }}
                      type="number"
                      autoComplete="off"
                      name="total"
                      id="total"
                      placeholder="Total"
                      className="form-control"
                      value={total}
                      onChange={(e) =>
                        setTotal(parseFloat(e.target.value) || 0)
                      }
                    />
                  </div>
                </div>
              </div>

              <div className="col-md-12 mt-3">
                <div className="row">
                  <div className="col-md-1"></div>
                  <div className="col-md-5">
                    <label className="col-form-label">Extra Charge</label>
                  </div>
                  <div className="col-md-6">
                    <input
                      style={{ width: "100%" }}
                      type="number"
                      autoComplete="off"
                      name="extraCharge"
                      id="extraCharge"
                      className="form-control"
                      placeholder="Extra Charge"
                      value={extraCharge}
                      onChange={(e) =>
                        setExtraCharge(parseFloat(e.target.value) || 0)
                      }
                    />
                  </div>
                </div>
              </div>

              <div className="col-md-12 mt-3">
                <div className="row">
                  <div className="col-md-1"></div>
                  <div className="col-md-5">
                    <label className="col-form-label">Discount By</label>
                  </div>
                  <div className="col-md-6">
                    <input
                      style={{ width: "100%" }}
                      type="text"
                      autoComplete="off"
                      name="discountBy"
                      id="discountBy"
                      className="form-control"
                      placeholder="Discount By"
                      value={discountBy.discountBy || ""} // Access the value with the 'discountBy' key
                    //  onChange={(e) => handleEdit(e)}
                    />
                  </div>
                </div>
              </div>

              <div className="col-md-12 mt-3">
                <div className="row">
                  <div className="col-md-1"></div>
                  <div className="col-md-5">
                    <label className="col-form-label">Discount %</label>
                  </div>
                  <div className="col-md-6">
                    <input
                      style={{ width: "100%" }}
                      type="number"
                      autoComplete="off"
                      name="discountPercentage"
                      id="discountPercentage"
                      className="form-control"
                      placeholder="Discount Percentage"
                      value={discountPercentage}
                      onChange={(e) =>
                        setDiscountPercentage(parseFloat(e.target.value) || 0)
                      }
                    />
                  </div>
                </div>
              </div>

              <div className="col-md-12 mt-3">
                <div className="row">
                  <div className="col-md-1"></div>
                  <div className="col-md-5">
                    <label className="col-form-label">Final Amount</label>
                  </div>
                  <div className="col-md-6">
                    <input
                      style={{ width: "100%" }}
                      type="number"
                      autoComplete="off"
                      name="finalAmount"
                      id="finalAmount"
                      className="form-control"
                      placeholder="Final Amount"
                      value={finalAmount}
                      readOnly // Assuming this is a calculated field
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-11 mt-6 text-center">
            <Button type="submit" variant="contained" color="primary" size="small" style={{ width: 'auto' }}>
              Submit
            </Button>
          </div>
      </form>
      <ToastContainer />
    </div>
  )
}

export default EditPharmacyBilling

