import React from "react";
import { useFormik } from "formik";
import { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useHistory } from "react-router-dom";
import axios from "../../../helper/axios";
import { Table, Button, Modal, Form } from "react-bootstrap";
import { ParametervalidationSchema } from "../../../helper/schemas";
import { ParametervalidationSchema2 } from "../../../helper/schemas";
import { ToastContainer, toast } from 'react-toastify';
import * as Yup from "yup";  // Import Yup for validation
import { useUser } from "../../../Provider";
import HospitalCodeSearch from "../../../Component/HospitalCodeSearch";
import SearchUnit from '../../../Component/SearchUnit';
import SearchPathologyCategory from '../../../Component/SearchPathologyCategory';
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { TextField, Autocomplete, InputAdornment, IconButton } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const initialValues = {
  categoryname: "",
  parametername: "",
  referencerange: "",
  hospitalcode: "",
  unitid: "",
  description: "",
};

const UpdatePathologyParameter = () => {
  const { user, userHospitalcode, userrole1, isAuthRole, isAuthorized, gettokenId, token } = useUser();
  const history = useHistory();
  const { pathologycode } = useParams();
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [category, setCategory] = useState([]);
  const [unit, setUnit] = useState([]);
  //get current date
  let Showdate = new Date();
  let ShowTodaydate =
    Showdate.getFullYear() +
    "-" +
    (Showdate.getMonth() + 1) +
    "-" +
    Showdate.getDate();
  const [currentDate, setCurrentDate] = useState(ShowTodaydate);

  const getValidationSchema = () => {
    if (userHospitalcode === 1) {
      return ParametervalidationSchema;

    } else {
      return ParametervalidationSchema2;
    }
  }

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    resetForm,
    setValues
  } = useFormik({
    initialValues,
    validationSchema: getValidationSchema, // Apply validation schema
    onSubmit: async (values, action) => {
      try {
        const data = new FormData();
        data.append("document", JSON.stringify(values));
        data.append("currentDate", JSON.stringify(currentDate));
        data.append("gettokenId", JSON.stringify(gettokenId));
        data.append("userHospitalcode", JSON.stringify(userHospitalcode));
        console.log(JSON.stringify(values));
        //   console.log(JSON.stringify(hospitalcode));

        const response = await axios.post(`/updatepathologyparameter/${pathologycode}`, data, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        });
        console.log(
          "🚀 ~ file: Registration.jsx ~ line 11 ~ Registration ~ values",
          values
        );
        console.log(
          "🚀 ~ file: Registration.jsx ~ line 11 ~ Registration ~ values",
          response.status
        );
        if (response.status === 200) {
          return history.push("/listviewpathology");
        }
      } catch (error) {
        console.log(error)
        toast("Something Went Wrong")
      }
    },
  });
  const handleReset = () => {
    // Reset the form using Formik's resetForm function
    resetForm();
  };
  const [data, setData] = useState([]);

  const fetchUnit = async (selectedHospitalCode) => {
    try {
      const response = await axios.get("/getunit", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          hospitalcode:selectedHospitalCode,
        },
      });

      if (response.status === 200) {
        setUnit(response.data);
      } else {
        console.error(`Failed to fetch available unit with status: ${response.status}`);
      }
    } catch (error) {
      console.error('Error fetching available unit:', error.message);
    }
  };

  //when page load or refresh happen this function will got called
  useEffect(() => {
    const loadData = async () => {
      const selectedHospitalCode = values.hospitalcode || userHospitalcode;
      await fetchcategoryName(selectedHospitalCode);
      await fetchUnit(selectedHospitalCode)
      await loadAllParameter();
    };
    loadData();
  }, [userHospitalcode, userrole1, isAuthorized, values.hospitalcode]);

  const fetchcategoryName = async (selectedHospitalCode) => {
    try {
      const response = await axios.get("/pathologycategory", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          hospitalcode: selectedHospitalCode, // Use the selected hospital code
        },
      });

      if (response.status === 200) {
        setCategory(response.data);
      } else {
        console.error(`Failed to fetch available category name with status: ${response.status}`);
      }
    } catch (error) {
      console.error('Error fetching available category name:', error.message);
    }
  };

  const loadAllParameter = async () => {
    try {
      if (userHospitalcode !== undefined && userrole1 !== undefined) {
        console.log("userHospitalcode:", userHospitalcode); // Add this line
        console.log("userrole1:", userrole1); // Add this line
        const res = await axios.get(`/getpathologyparameter/${pathologycode}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: {
            hospitalcode: userHospitalcode, // Include the hospital code in the query parameters
            userrole: userrole1, // Include the user role in the query parameters
          },
        });
        setValues(res.data[0]);
        console.log(res.data);
      }
    } catch (error) {
      // Handle any error here.
     // alert("Error deleting hospital:", error);
    }
  };
  const [openAutocompleted, setOpenAutocompleted] = useState(false);
  const handleExpandClicked = () => {
    setOpenAutocompleted(!openAutocompleted);
  };
  const [openAutocomplete, setOpenAutocomplete] = useState(false);
  const handleExpandClick = () => {
    setOpenAutocomplete(!openAutocomplete);
  };
  const currentDomain = window.location.hostname;

  return (
    <div className="p-3 pt-0 appointment">
      <Helmet>
        <title>Aarogyasaarthi | {currentDomain} | Modify Pathology Parameter</title>
      </Helmet>
      <form onSubmit={handleSubmit}>
        {success && <div>Add Pathology Parameter</div>}
        {error && <div>Error in add bed type please try again</div>}
        <div class="fill header"><h3 class="float-left"> Modify Pathology Parameter</h3> </div>
        <div className="patient-new primary-info">
          <div className="row">
            <div className="col-md-12">
              <div className="row">
                <div className="col-md-6">
                  <div className="col-md-12 mt-3">
                    <div className="row">

                      <div className="col-md-4">
                        <label
                          htmlFor="chargecode"
                          className="col-form-label leftone"
                        >
                          Pathology Category
                        </label>
                      </div>
                      <div className="col-md-8">
                        <Autocomplete
                          freeSolo
                          id="free-solo"
                          options={category}
                          getOptionLabel={(code) => code.categoryname}
                          value={category.find((code) => code.chargecode === values.chargecode) || null}
                          open={openAutocompleted}
                          onOpen={() => setOpenAutocompleted(true)}
                          onClose={() => setOpenAutocompleted(false)}
                          onChange={(event, newValue) => {
                            setValues({
                              ...values,
                              chargecode: newValue ? newValue.chargecode : ""
                            });
                          }}
                          onBlur={handleBlur}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              placeholder="Select code"
                              InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <IconButton onClick={handleExpandClicked}>
                                      <ExpandMoreIcon />
                                    </IconButton>
                                  </InputAdornment>
                                ),
                                //style: { width: '74%', padding: '0px ', border: "none" } // Adjust width here
                              }}
                            />
                          )}
                        />
                        {errors.chargecode && touched.chargecode ? (
                          <p className="form-erroremployee">{errors.chargecode}</p>
                        ) : null}
                      </div></div></div>

                  <div className="col-md-12 mt-3">
                    <div className="row">

                      <div className="col-md-4">
                        <label
                          htmlFor="parametername"
                          className="col-form-label leftone"
                        >
                          Parameter Name
                        </label>
                      </div>
                      <div className="col-md-8">
                        <input
                          type="text"
                          autoComplete="off"
                          name="parametername"
                          id="parametername"
                          placeholder="Parameter Name"
                          value={values.parametername}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          style={{ width: '73%', height: '32px', border: '1px solid #ced4da', color: '#495057' }}
                        />
                        {errors.parametername && touched.parametername ? (
                          <p className="form-erroremployee">{errors.parametername}</p>
                        ) : null}
                      </div></div></div>

                  <div className="col-md-12 mt-3">
                    <div className="row">

                      <div className="col-md-4">
                        <label
                          htmlFor="description"
                          className="col-form-label leftone"
                        >
                          Description
                        </label>
                      </div>
                      <div className="col-md-8">
                        <textarea
                          type="text"
                          autoComplete="off"
                          name="description"
                          id="description"
                          placeholder="Description"
                          value={values.description}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          style={{ width: '73%', height: '32px', border: '1px solid #ced4da', color: '#495057' }}
                        />



                        {errors.description && touched.description ? (
                          <p className="form-erroremployee">{errors.description}</p>
                        ) : null}
                      </div></div></div>



                </div>
                <div className="col-md-6">
                {isAuthorized ? (<div className="col-md-12 mt-3">
              <div className="row">
  
                <div className="col-md-4">
                  <label
                    htmlFor="hospitalcode"
                    className="col-form-label leftone"
                  >
                    Hospital Code
                  </label>
                </div>
                <div className="col-md-8">
                  {/* Conditionally render the dropdown and input field */}
                  {isAuthorized ? (
                          <input
                            type="text"
                            autoComplete="off"
                            name="hospitalcode"
                            id="hospitalcode"
                            placeholder="Hospital Code"
                            value={values.hospitalcode}
                            // onChange={handleChange}
                            // onBlur={handleBlur}
                            disabled={true} // Set the input as disabled
                            // style={{ width: '73%', height: '32px', border: '1px solid #ced4da', color: '#495057' }}
                            className="form-control "
                          >
                           
                          </input>
                        ) : (
                          <input
                            type="text"
                            name="hospitalcode"
                            id="hospitalcode"
                            value={userHospitalcode}
                            disabled={true} // Set the input as disabled
                            className="form-control "
                          />
                        )}
                        {isAuthorized && errors.hospitalcode && touched.hospitalcode ? (
                          <p className="form-erroremployee">{errors.hospitalcode}</p>
                        ) : null}
                </div></div></div>) : null}
                  <div className="col-md-12 mt-3">
                    <div className="row">

                      <div className="col-md-4">
                        <label
                          htmlFor="referencerange"
                          className="col-form-label leftone"
                        >
                          Reference Range
                        </label>
                      </div>
                      <div className="col-md-8">
                        <input
                          type="text"
                          autoComplete="off"
                          name="referencerange"
                          id="referencerange"
                          placeholder="Reference Range"
                          value={values.referencerange}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          style={{ width: '73%', height: '32px', border: '1px solid #ced4da', color: '#495057' }}
                        />
                        {errors.referencerange && touched.referencerange ? (
                          <p className="form-erroremployee">{errors.referencerange}</p>
                        ) : null}
                      </div></div></div>


                  <div className="col-md-12 mt-3">
                    <div className="row">
                      <div className="col-md-4">
                        <label
                          htmlFor="unitid"
                          className="col-form-label leftone"
                        >
                          Unit
                        </label>
                      </div>
                      <div className="col-md-8">
                      {/* <SearchUnit
                      onUnitChange={handleSearchChanges}
                      hospitalvalue={values.hospitalcode || userHospitalcode}
                      
                      /> */}
                        <Autocomplete
                          freeSolo
                          id="free-solo"
                          options={unit}
                          getOptionLabel={(code) => code.unitname}
                          value={unit.find((code) => code.unitid === values.unitid) || null}
                          open={openAutocomplete}
                          onOpen={() => setOpenAutocomplete(true)}
                          onClose={() => setOpenAutocomplete(false)}
                          onChange={(event, newValue) => {
                            setValues({
                              ...values,
                              unitid: newValue ? newValue.unitid : ""
                            });
                          }}
                          onBlur={handleBlur}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              placeholder="Select code"
                              InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <IconButton onClick={handleExpandClick}>
                                      <ExpandMoreIcon />
                                    </IconButton>
                                  </InputAdornment>
                                ),
                                //style: { width: '74%', padding: '0px ', border: "none" } // Adjust width here
                              }}
                            />
                          )}
                        />
                        {errors.unitid && touched.unitid ? (
                          <p className="form-erroremployee">{errors.unitid}</p>
                        ) : null}
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-6  mt-3">
          <Button
            type="submit"
            variant="success"
            className="mx-3"
          >
            Update
          </Button>
          <Button
            variant="primary"
            className="mx-3"
            onClick={handleReset}
          >
            Reset
          </Button>
        </div>
        <ToastContainer />
      </form>
    </div>
  );
};

export default UpdatePathologyParameter;