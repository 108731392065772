import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";

const ProtectedRoute = ({ component: Component }) => {
  const history = useHistory();

  useEffect(() => {
    const token = localStorage.getItem("jwtToken"); // Get the token from sessionStorage

    if (!token) {
      // Redirect to the login page if the token is not found
      history.push("/");
    }
  }, [history]);

  return <Component />;
};

export default ProtectedRoute;
