import React, { useState, useEffect } from 'react';
import { Typography, Button, Select, MenuItem, FormControl, InputLabel, Autocomplete, TextField, InputAdornment, IconButton } from '@mui/material';
import axios from "../../../helper/axios";
import { useFormik } from "formik";
import { Prescriptionvalidation } from "../../../helper/schemas";
import { useUser } from "../../../Provider";
import { useParams } from 'react-router-dom/cjs/react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Import the styles
import MultiSelectDropdown from '../../Pathology/PathologyPages/MultiSelectDropdown';
import MultiSelectRadiologyTest from '../../Radiology/RadiologyPages/MultiSelectRadiologyTest';
import DoctorSearchIds from '../../../Component/DoctorSearchIds';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
const getCurrentDate = () => {
  const now = new Date();
  const year = now.getFullYear();
  const month = String(now.getMonth() + 1).padStart(2, '0');
  const day = String(now.getDate()).padStart(2, '0');
  const hours = String(now.getHours()).padStart(2, '0');
  const minutes = String(now.getMinutes()).padStart(2, '0');
  const currentDateTimeString = `${year}-${month}-${day}`;
  return currentDateTimeString;
};
// Initial values for the form fields
const initialValues = {
  date: getCurrentDate(),
  doctorids: "",
  findingcategory: "",
  description: "",
  //pathology: "",
  // radiology: "",
};

const PrescriptionForm = ({ onPrescriptionAdd }) => {
  // Retrieve 'admitnumber' from the URL parameters
  const { admitnumber } = useParams();
  console.log({ admitnumber });

  // Use the 'useUser' hook to get user-related information
  const { user, userHospitalcode, userrole1, isAuthRole, isAuthorized, gettokenId, token } = useUser();
  console.log(user, userHospitalcode, userrole1, isAuthRole, isAuthorized, gettokenId);
  const [selectedPathologyCategories, setSelectedPathologyCategories] = useState([]);
  const [selectedTestCategories, setSelectedTestCategories] = useState([]);
  const handlePathologyCategoriesChange = (selectedPathologyCategories) => {
    setSelectedPathologyCategories(selectedPathologyCategories);
  };
  const handleTestCategoriesChange = (selectedCategories) => {
    setSelectedTestCategories(selectedCategories);
  };
  // State variables to manage form visibility, category data, and other data
  const [isFormVisible, setFormVisibility] = useState(false);
  const [category1, setCategory] = useState([]);
  const [data, setData] = useState([]);
  const [doctor, setDoctor] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [openAutocomplete, setOpenAutocomplete] = useState(false);
  const [openAutocompleted, setOpenAutocompleted] = useState(false);
  const handleExpandClick = () => {
    setOpenAutocomplete(!openAutocomplete);
};
const handleExpandClicked = () => {
  setOpenAutocompleted(!openAutocompleted);
};
  // Get today's date to initialize the form field
  let Showdate = new Date();
  let ShowTodaydate =
    Showdate.getFullYear() +
    "-" +
    (Showdate.getMonth() + 1) +
    "-" +
    Showdate.getDate();
  const [currentDate, setCurrentDate] = useState(ShowTodaydate);
  //Doctor onSearch Function
  const handleSearchChanges = (searchTerms) => {
    console.log("---------------", searchTerms)
    setDoctor(searchTerms); // Update the searchValue state with the search term
    setValues({
      ...values,
      doctorids: searchTerms.doctorcode
    })
  };
  // Function to toggle form visibility
  const handleAddMedication = () => {
    setFormVisibility(!isFormVisible);
  };

  // Formik hook for form state management and validation
  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleReset,
    handleSubmit,
    resetForm,
    setValues,
  } = useFormik({
    initialValues,
    validationSchema: Prescriptionvalidation,
    onSubmit: async (values, action) => {
      try {
        const data = new FormData();
        // Append form data to the FormData object
        data.append("document", JSON.stringify(values));
        data.append("selectedCategory", JSON.stringify(selectedCategory));
        data.append("selectedPathologyCategories", JSON.stringify(selectedPathologyCategories));
        data.append("selectedTestCategories", JSON.stringify(selectedTestCategories));
        data.append("currentDate", JSON.stringify(currentDate));
        data.append("gettokenId", JSON.stringify(gettokenId));
        data.append("medicines", JSON.stringify(medicines));
        data.append("editorContent", JSON.stringify(editorContent));
        data.append("footereditorContent", JSON.stringify(footereditorContent));
        console.log(JSON.stringify(values))
        console.log(JSON.stringify(medicines))
        console.log(JSON.stringify(selectedPathologyCategories))
        console.log(JSON.stringify(footereditorContent))

        // Make a POST request to save Prescription data
        const response = await axios.post(`/prescription/${admitnumber}`, data, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        });

        // Display success message if the request is successful
        if (response.status === 200) {
          onPrescriptionAdd();
          toast.success("Data Saved");
        }
      } catch (err) {
        // Handle errors
      }
      // Reset the form after submission
      action.resetForm();
    },
  });

  // State variables for managing medicine-related data
  const [medicines, setMedicines] = useState([{ medicinecategory: '', medicinename: '', dose: '', doseinterval: '', doseduration: '', instruction: '' }]);
  const [medicineNames, setMedicineNames] = useState({});

  const handleCategoryChangeForRow = async (newValue, index) => {
      const selectedCategory = newValue.medicinecategoryid; // Convert to string
      console.log(selectedCategory )
    setSelectedCategory(selectedCategory);
    setMedicines(prevMedicines => {
      const updatedMedicines = [...prevMedicines];
      if (updatedMedicines[index]) {
        updatedMedicines[index] = {
          medicinecategory: selectedCategory,
          
        };
      }
      return updatedMedicines;
    });
    await fetchMedicineNames(selectedCategory, index);
  };
  // Function to fetch medicine names based on selected category
  const fetchMedicineNames = async (selectedCategory, index) => {
    try {
      const response = await axios.get("/medicinenames", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          hospitalcode: data.hospitalcode,
          category: selectedCategory,
        },
      });

      if (response.status === 200) {
        setMedicineNames((prevMedicineNames) => {
          const updatedMedicineNames = { ...prevMedicineNames };
          updatedMedicineNames[index] = response.data;
          return updatedMedicineNames;
        });
      } else {
        console.error(`Failed to fetch medicine names with status: ${response.status}`);
      }
    } catch (error) {
      console.error('Error fetching medicine names:', error.message);
    }
  };
  const handleChanges = (e, index, field, searchvalue) => {
    const medicinecode = searchvalue ? searchvalue.medicinecode : "";
    setMedicines((prevMedicines) => {
      return prevMedicines.map((medicine, i) => {
        if (i === index) {
          return {
            ...medicine,
            [field]: field === 'medicinename' ? medicinecode : "", // Set medicinecode to medicinename field
            dose: field === 'dose' ? e.target.value : medicine.dose || "", // If changing dose field, set its value otherwise keep the previous value
            doseinterval: field === 'doseinterval' ? e.target.value : medicine.doseinterval || "", // If changing doseinterval field, set its value otherwise keep the previous value
            doseduration: field === 'doseduration' ? e.target.value : medicine.doseduration || "", // If changing doseduration field, set its value otherwise keep the previous value
            instruction: field === 'instruction' ? e.target.value : medicine.instruction || "", // If changing instruction field, set its value otherwise keep the previous value
          };
        }
        return medicine;
      });
    });
  };

  // Function to handle deletion of a specific row
  const handleDeleteRow = (index) => {
    setMedicines((prevMedicines) => {
      const updatedMedicines = prevMedicines.filter((_medicine, i) => i !== index);
      return updatedMedicines;
    });

    // Fetch medicine names based on the remaining medicine categories
    setMedicines((updatedMedicines) => {
      updatedMedicines.forEach(async (medicine, i) => {
        await fetchMedicineNames(medicine.medicinecategory, i);
      });
      return updatedMedicines;
    });
  };

  // Function to load hospital code based on user information
  const loadHospitalcode = async () => {
    if (userHospitalcode !== undefined && userrole1 !== undefined) {
      const res = await axios.get(`/getIPDhospitalcode/${admitnumber}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          hospitalcode: userHospitalcode,
          userrole: userrole1,
        },
      });
      setData(res.data[0]);
      console.log(res.data);
    };
  };

  // Function to fetch medicine category names based on hospital code
  const fetchMedicineCateoryNames = async (selectedHospitalCode) => {
    try {
      const response = await axios.get("/medicinecategory", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          hospitalcode: selectedHospitalCode,
        },
      });

      if (response.status === 200) {
        setCategory(response.data);
      } else {
        console.error(`Failed to fetch available floor numbers with status: ${response.status}`);
      }
    } catch (error) {
      console.error('Error fetching available floor numbers:', error.message);
    }
  };

  // Function to get doctor details
  const getDoctordetails = async () => {

    try {
      if (userHospitalcode !== undefined && userrole1 !== undefined) {
        const res = await axios.get(`/getdoctorids`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: {
            hospitalcode: data.hospitalcode, // Include the hospitalcode in the query parameters
            userrole: userrole1, // Include the userrole in the query parameters
          },
        });
        console.log(res.data)
        // Check if the response status code is  200
        if (res.status === 200) {
          setDoctor(res.data);
        } else {
          // Handle unexpected response status codes (e.g., 404, 500, etc.) as errors.
          alert(`Request failed with status: ${res.status}`);
          // Optionally, you can set an error state or display an error message to the user.
        }
      }
    }
    catch (error) {
      // Handle network errors or other exceptions.
      console.log(`An error occurred: ${error.message}`);
      // Optionally, you can set an error state or display an error message to the user.
    }
  };

  useEffect(() => {
    const loadData = async () => {
      await loadHospitalcode();
      await getDoctordetails(data.hospitalcode);
      const selectedHospitalCode = data.hospitalcode || userHospitalcode;
      await fetchMedicineCateoryNames(selectedHospitalCode);
      // Fetch the initial list of medicine names based on the initial category value
      if (values.medicinecategory) {
        await fetchMedicineNames(values.medicinecategory);
      }
    };
    loadData();
  }, [userHospitalcode, userrole1, isAuthorized, data.hospitalcode]);
  // State variable for the text editor content
  const [editorContent, setEditorContent] = useState('');
  const [footereditorContent, setFooterEditorContent] = useState('');
  // Handler for text editor content change
  const handleEditorChange = (content) => {
    setEditorContent(content);
  };
  const handleFooterEditorChange = (content) => {
    setFooterEditorContent(content)
  };
  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography variant="h6" style={{ padding: '20px' }}>
          Add Prescription
        </Typography>
        <Button variant="contained" color="primary" onClick={handleAddMedication}>
          {isFormVisible ? 'Close Form' : 'Add Prescription'}
        </Button>
      </div>
      {isFormVisible && (
        <form onSubmit={handleSubmit} style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
          <div className="patient-new primary-info">
            Header Note
            {/* ReactQuill component */}
            <ReactQuill
              theme="snow"
              value={editorContent}
              onChange={handleEditorChange}
            />
            <div className="row">
              <div className="col-md-12">
                <div className="row">
                  <div className="col-md-6">
                    <div className="col-md-12 mt-3">
                      <div className="row">
                        <div className="col-md-4">
                          <label
                            htmlFor="date"
                            className="col-form-label "
                          >
                            Date
                          </label>
                        </div>
                        <div className="col-md-8">
                          <input
                            type="date"
                            autoComplete="off"
                            name="date"
                            id="date"
                            placeholder="Date"
                            value={values.date}
                            min={getCurrentDate()}
                            onChange={handleChange}
                            onBlur={handleBlur}

                            className="form-control"
                          />
                          {errors.date && touched.date ? (
                            <p className="form-erroremployee">{errors.date}</p>
                          ) : null}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12 mt-3">
                      <div className="row">
                        <div className="col-md-4">
                          <label
                            htmlFor="findingcategory"
                            className="col-form-label leftone"
                          >

                            Finding Category
                          </label>
                        </div>
                        <div className="col-md-8">
                          <input
                            type="text"
                            autoComplete="off"
                            name="findingcategory"
                            id="findingcategory"
                            placeholder="findingcategory"
                            value={values.findingcategory}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            style={{ width: '73%', height: '32px', border: '1px solid #ced4da ', color: '#495057' }}
                          />
                          {errors.findingcategory && touched.findingcategory ? (
                            <p className="form-erroremployee">{errors.findingcategory}</p>
                          ) : null}

                        </div>
                      </div>
                    </div>
                    <div className="col-md-12 mt-3">
                      <div className="row">
                        <div className="col-md-4">
                          <label
                            htmlFor="pathology"
                            className="col-form-label "
                          >

                            Pathology Test
                          </label>
                        </div>
                        <div className="col-md-6">
                          <MultiSelectDropdown onPathologyCategoriesChange={handlePathologyCategoriesChange} hospitalCode={data.hospitalcode} />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="col-md-12 mt-3">
                      <div className="row">
                        <div className="col-md-4">
                          <label
                            htmlFor="doctorids"
                            className="col-form-label leftone"
                          >
                            Prescribe By
                          </label>
                        </div>
                        <div className="col-md-8">
                          <DoctorSearchIds
                            onSearchChanges={handleSearchChanges}
                            hospitalvalue={data.hospitalcode}
                          />
                          {errors.doctorids && touched.doctorids ? (
                            <p className="form-erroremployee">{errors.doctorids}</p>
                          ) : null}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12 mt-3">
                      <div className="row">
                        <div className="col-md-4">
                          <label
                            htmlFor="description"
                            className="col-form-label leftone"
                          >
                            Finding Description
                          </label>
                        </div>
                        <div className="col-md-8">
                          <textarea
                            // style={{ width: "210px", height: "50px" }}
                            rows="1"
                            cols="30"
                            type="text"
                            autoComplete="off"
                            name="description"
                            id="description"
                            placeholder="description"
                            value={values.description}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            style={{ width: '73%', height: '32px', border: '1px solid #ced4da ', color: '#495057' }}
                          />
                          {errors.description && touched.description ? (
                            <p className="form-erroremployee">{errors.description}</p>
                          ) : null}

                        </div>
                      </div>
                    </div>
                    <div className="col-md-12 mt-3">
                      <div className="row">
                        <div className="col-md-4">
                          <label
                            htmlFor="radiology"
                            className="col-form-label"
                          >
                            Radiology Test
                          </label>
                        </div>
                        <div className="col-md-6">
                          <MultiSelectRadiologyTest onTestCategoriesChange={handleTestCategoriesChange} hospitalCode={data.hospitalcode} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <table>
              <thead>
                <tr style={{ paddingRight: '10px' }}>
                  <th>Medicine Category</th>
                  <th>Medicine</th>
                  <th>Dosage</th>
                  <th>Dose Interval</th>
                  <th>Dose Duration</th>
                  <th>Instruction</th>
                  <th>Delete</th>
                </tr>
              </thead>
              <tbody>
                {medicines.map((medicine, index) => (
                  <tr key={index}>
                    <td style={{ paddingRight: '10px' }}>

                      <Autocomplete
                        freeSolo
                        id="free-solo"
                        options={category1}
                        getOptionLabel={(option) => option.medicinecategory || ""}
                       // sx={{ width: 230 }}
                    //    value={medicine.medicinecategory}
                    open={openAutocompleted}
                    onOpen={() => setOpenAutocompleted(true)}
                    onClose={() => setOpenAutocompleted(false)}
                        onChange={(e, newValue) => handleCategoryChangeForRow(newValue, index)}
                        renderInput={(params) =>
                          <TextField
                            {...params}
                            placeholder="Select Category"
                            InputProps={{
                              ...params.InputProps,
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton onClick={handleExpandClicked}>
                                    <ExpandMoreIcon />
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                          />
                        }
                      />
                    </td>

                    <td>
                      <Autocomplete
                        freeSolo
                        id={`medicinename-${index}`}
                        options={medicineNames[index] || []}
                        getOptionLabel={(option) => option.medicinename || ''}
                       
                       // value={medicine.medicinename}
                       open={openAutocomplete}
                       onOpen={() => setOpenAutocomplete(true)}
                       onClose={() => setOpenAutocomplete(false)}
                        onChange={(e, newValue) => handleChanges(e, index, "medicinename", newValue)}
                        renderInput={(params) =>
                          <TextField
                            {...params}
                            placeholder="Select Medicine"
                            InputProps={{
                              ...params.InputProps,
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton onClick={handleExpandClick}>
                                    <ExpandMoreIcon />
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                          />
                        }
                      />

                    </td>
                    <td>
                      <input
                        type="text"
                        autoComplete="off"
                        name="dose"
                        id="dose"
                        placeholder="dose"
                        value={medicine.dose}
                        onChange={(e) => handleChanges(e, index, "dose")}
                        style={{ width: '100%', height: '32px', border: '1px solid #ced4da ', color: '#495057' }}
                      />
                    </td>
                    <td>
                      <select
                        type="text"
                        autoComplete="off"
                        name="doseinterval"
                        id="doseinterval"
                        placeholder="doseinterval"
                        value={medicine.doseinterval}
                        onChange={(e) => handleChanges(e, index, "doseinterval")}

                        style={{ width: '100%', height: '32px', border: '1px solid #ced4da ', color: '#495057' }}
                      >
                        <option>Select</option>
                        <option>1 times a day</option>
                        <option>2 times a day</option>
                        <option>3 times a day</option>
                        <option>4 times a day</option>
                        <option>5 times a day</option>
                        <option>6 times a day</option>
                      </select>
                    </td>
                    <td>
                      <input
                        type="text"
                        autoComplete="off"
                        name="doseduration"
                        id="doseduration"
                        placeholder="doseduration"
                        value={medicine.doseduration}
                        onChange={(e) => handleChanges(e, index, "doseduration")}
                        style={{ width: '100%', height: '32px', border: '1px solid #ced4da ', color: '#495057' }}
                      />
                    </td>
                    <td>
                      <textarea
                        // style={{ width: "210px", height: "50px" }}
                        rows="1"
                        cols="30"
                        type="text"
                        autoComplete="off"
                        name="instruction"
                        id="instruction"
                        placeholder="Instruction"
                        value={medicine.instruction}
                        onChange={(e) => handleChanges(e, index, "instruction")}

                        style={{ width: '100%', height: '32px', border: '1px solid #ced4da ', color: '#495057' }}
                      />
                    </td>
                    <td>
                      <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        style={{ width: 'auto' }}
                        onClick={(e) => handleDeleteRow(index)}
                      >
                        Delete
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <Button variant="contained" color="primary" size="small" style={{ width: 'auto' }}
              onClick={() => setMedicines([...medicines, { medicinecategory: '', medicinename: '', dose: '', doseinterval: '', doseduration: '', instruction: '' }])}>
              Add Medicine
            </Button>
          </div>
          Footer Note
          {/* ReactQuill component */}
          <ReactQuill
            theme="snow"
            value={footereditorContent}
            onChange={handleFooterEditorChange}
          />
          <div className="col-md-11 mt-6 text-center">
            <Button type="submit" variant="contained" color="primary" size="small" style={{ width: 'auto' }}>
              Submit
            </Button>
          </div>
          <ToastContainer />
        </form>
      )}
    </div>
  );
};

export default PrescriptionForm;

