
import { useEffect, useRef, useState } from 'react'
import { useFormik } from "formik";
import { Helmet } from "react-helmet";
import axios from "./../../helper/axios";
import { useHistory } from 'react-router-dom';
//for date picker calender need to import all tha files
import { DateRangePicker } from 'react-date-range'
import format from 'date-fns/format'
import { addDays } from 'date-fns'
import { subDays } from 'date-fns'
import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';
import { GrDocumentDownload } from "react-icons/gr";
import {FaFileDownload } from "react-icons/fa";
import Apointmentsearch from '../../Component/Apointmentsearch';
import { useUser } from '../../Provider';
import Billingsearch from '../../Component/Billingsearch';
import HospitalCodeSearch from '../../Component/HospitalCodeSearch';

// import ReactHtmlTableToExcel from 'react-html-table-to-excel'
//Each property is assigned an empty string as its initial value for this form field value
const initialValues = {
  opdnumber: "",
  opdtype:"",
  hospitalcode:""
   
  };
  
// Function to filter duplicate billnumbers and concatenate corresponding chargenames
const filterAndConcatenateChargenames = (data) => {
    const uniqueBillnumbers = [];
    const filteredData = [];
  
    data.forEach((item) => {
      const { billnumber, chargename , patientid,patientname,subtotal,createdon,hospitalcode} = item;
  
      if (!uniqueBillnumbers.includes(billnumber)) {
        uniqueBillnumbers.push(billnumber);
        filteredData.push({ billnumber, chargename,patientid,patientname,subtotal,createdon,hospitalcode });
      } else {
        const index = filteredData.findIndex((el) => el.billnumber === billnumber);
        if (index !== -1) {
          filteredData[index].chargename += `, ${chargename}`;
        }
      }
    });
  
    return filteredData;
  };
const BillingReport = () => {
    //     The useHistory hook is typically used in a functional component within
  //      a React application to access the browser's history object and manipulate the browser's URL programmatically.  
  // //**the history object to navigate the user to a different route by calling  return history.push('/'); */
  const history = useHistory();
  const {user, userHospitalcode, userrole1, isAuthRole,isAuthorized,gettokenId,token  } = useUser();
  const [searchValue, setSearchValue] = useState("");
  const handleSearchChange = (searchTerm) => {
    console.log(searchTerm)
    setSearchValue(searchTerm); // Update the searchValue state with the search term
  };
  const onSearchItems = (searchTerm) => {
    console.log("------",searchTerm)
   // setSearchValues(searchTerm);
    setValues({
      ...values,
      hospitalcode: searchTerm.hospitalcode,
      // Update other fields as needed
    });
  };
  const { values, errors, touched, handleBlur, handleChange, handleReset, handleSubmit, setValues } =
    useFormik({
      initialValues,
      onSubmit: async (values, action,) => {
        try {
          if (userHospitalcode !== undefined && userrole1 !== undefined) {
          // It extracts the startDate from an array called range and calculates the timezoneOffset in milliseconds based on the user's local time zone.
          // It creates a new Date object called adjustedDate, which is the original startDate adjusted by the timezoneOffset.
          // It logs the adjusted date in the format "YYYY-MM-DD" using toISOString() and slice() methods.
          // It creates a new FormData object called data.
          // It appends two key-value pairs to the data object: "document" with the JSON stringified value of values,
          // and "startDate" with the adjusted date in the format "YYYY-MM-DD".
          // It appends another key-value pair "endDate" with the endDate from range in the format "YYYY-MM-DD".
          const startDate = range[0].startDate;
          const endDate = range[0].endDate;
          const timezoneOffset = startDate.getTimezoneOffset() * 60000; // Convert minutes to milliseconds
          const adjustedDate = new Date(startDate.getTime() - timezoneOffset);
          const adjustedDates = new Date(endDate.getTime() - timezoneOffset);
          console.log(adjustedDate.toISOString().slice(0, 10));
          //when i directlly used range[0].startDate.toISOString().slice(0, 10) the output was yesterday date so I used 
          //adjustedDate.toISOString().slice(0, 10) to get start date 
          const data = new FormData();
          data.append("document", JSON.stringify(values));
          data.append("searchValue", JSON.stringify(searchValue));
          data.append("startDate", adjustedDate.toISOString().slice(0, 10)); // Add start date 
          data.append("endDate", adjustedDates.toISOString().slice(0, 10)); // Add end date 
          const response = await axios.post("/billingpost", data, {
            //send token header to authorize the api
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "multipart/form-data",
            },
            params: {
              hospitalcodes: userHospitalcode, // Include the hospitalcode in the query parameters
              userrole: userrole1, // Include the userrole in the query parameters
            },
          });
          if (response.data.length === 0) {
            // If the response data is an empty array, display a message
            alert("No data found");
            setDataSupply(response.data);
          } else {
            // Otherwise, set the dataSupply state with the response data
            console.log(response.data);
            setDataSupply(response.data);
          }
          }
        } catch (err) {

        }
        action.resetForm();
      },

    });
  const [totalPrice, setTotalPrice] = useState(0);
  const [dataSupply, setDataSupply] = useState([]);

  // date state
  const [range, setRange] = useState([
    {
      endDate: new Date(),
      startDate: subDays(new Date(), 7),
      key: 'selection'
    }
  ])
  console.log(range)
  // open close
  const [open, setOpen] = useState(false)
  // get the target element to toggle 
  const refOne = useRef(null)
  useEffect(() => {
    //loadHospitalcode();
    if (!localStorage.getItem('jwtToken')) {
      return history.push('/');
      
    }
    // event listeners
    document.addEventListener("keydown", hideOnEscape, true)
    document.addEventListener("click", hideOnClickOutside, true)
  }, [])
  // hide dropdown on ESC press
  const hideOnEscape = (e) => {
    // console.log(e.key)
    if (e.key === "Escape") {
      setOpen(false)
    }
  }
  // Hide dropdown on outside click
  const hideOnClickOutside = (e) => {
    // console.log(refOne.current)
    // console.log(e.target)
    if (refOne.current && !refOne.current.contains(e.target)) {
      setOpen(false)
    }
  }
  //function is for downloding the table data 
  const handleDownload = (event) => {
    event.preventDefault();
    // Create a new workbook and worksheet
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.table_to_sheet(document.getElementById('supplyitem'));//need to provide the id of specific table here (id is supplyitem) .

    // Add the worksheet to the workbook
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

    // Convert the workbook to an Excel file
    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });

    // Save the file using FileSaver.js
    const data = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    saveAs(data, 'Billing_report.xlsx');
  };
  const currentDomain = window.location.hostname;
  return (
    <div>
<Helmet>
        <title>Billing Report | {currentDomain} | Hospital Management Software | Smart Solutions for Hospital Efficiency</title>
      </Helmet>
      <div className="">
        <form onSubmit={handleSubmit}>
          <div className="input-employeecontainer">
          <div className="input-blockemployee">
              <label htmlFor="date" className="input-labelemployee">
                Created on
              </label>
              <div className="calendarWrap">
                <input
                  type="text"
                  name="date"
                  id="date"
                  value={`${format(range[0].startDate, "dd/MM/yyyy")} to ${format(range[0].endDate, "dd/MM/yyyy")}`}
                  readOnly
                  onClick={() => setOpen(open => !open)}
                />
                <div ref={refOne}>
                  {open &&
                    <DateRangePicker
                      onChange={item => setRange([item.selection])}
                      editableDateInputs={true}
                      moveRangeOnFirstSelection={false}
                      ranges={range}
                      months={1}
                      direction="vertical"
                      className="calendarElement"
                    />
                  }
                </div>
              </div>
            </div>
            <div className="input-blockemployee">
              <label htmlFor="idforrequisition" className="input-labelemployee">
               Billing Number
              </label>
              <div className="inputerroremployee">
                {/* <input
                  type="text"
                  autoComplete="off"
                  name="idforrequisition"
                  id="idforrequisition"
                  value={values.idforrequisition}
                  onChange={handleChange}
                  onBlur={handleBlur}
                >
                  
                </input> */}
                 <Billingsearch onSearchChange={handleSearchChange} />
              </div>
            </div>
            {/* <div className="input-blockemployee">
              <label htmlFor="opdtype" className="input-labelemployee">
               OPD type
              </label>
              <div className="inputerroremployee">
                <select
                  type="text"
                  autoComplete="off"
                  name="opdtype"
                  id="opdtype"
                  value={values.opdtype}
                  onChange={handleChange}
                  onBlur={handleBlur}
                >
                 <option>Select OPD type</option>
                 <option>New</option>
                 <option>Visit</option>
                </select>
              </div>
            </div> */}
          </div>
          <div className="input-employeecontainer">
       
            {isAuthorized ? (  <div className="input-blockemployee">
              <label htmlFor="hospitalcode" className="input-labelemployee">
               Hospital code
              </label>
                <div className="inputerroremployee">
                <HospitalCodeSearch onSearchItem={onSearchItems} />
                {/* <select
                  type="text"
                  autoComplete="off"
                  name="hospitalcode"
                  id="hospitalcode"
                  value={values.hospitalcode}
                  onChange={handleChange}
                  onBlur={handleBlur}
                >
                  <option>Select Hospitalcode </option>
                  {data.map(data => (
                    <option value={data.hospitalcode} key={data.Id} >{data.hospitalcode}</option>
                  ))
                  }
                </select> */}
              </div>
            </div> ) : null}
          </div>
          
          <div className='buttonDisplay'>
            <button className="allbuttonget">Get Details</button>
            <FaFileDownload className='download' onClick={handleDownload}/>
          </div>
          <br />
          <div className='container2'>
            <table id="supplyitem">
              <thead>
                <tr>
                  <th>Billing Number</th>
                  <th>Patient Id</th>
                  <th>Patient Name </th>
                  <th>Bill Amount</th>
                  <th>Bill Date</th>
                  <th>Description</th>
                  {isAuthorized ? ( <th>Hospital Code</th>) : null}
                  {/* <th>Months</th>  
                  <th>Years</th>  */}
                </tr>
              </thead>

              <tbody>
                
                {filterAndConcatenateChargenames(dataSupply).map((value, key) => (
                    <tr key={key}>
                    <td>{value.billnumber}</td>
                           <td>{value.patientid}</td>
                           <td>{value.patientname}</td>
                           <td>{value.subtotal}</td>
                           <td>{value.createdon}</td>
                           <td>{value.chargename}</td>
                           {isAuthorized ? (<td>{value.hospitalcode}</td>) : null}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </form>
      </div>
    </div>
  )
}

export default BillingReport