import * as Yup from "yup";
export const newappointmentvalidation = Yup.object({
   
  patientName: Yup.string().required("Please enter the patient name"),
  //:Yup.date().required("Please enter appointment date"),
  // address: Yup.string().required("Please enter the address"),
  // mobileNo:Yup.string().min(10).max(10).required("Please enter the mobile no"),
  // age: Yup.string().required("Please enter the age"),
  // gender: Yup.string().required("Please enter the gender"),
  //gaurdianname: Yup.string().required("Please enter the gaurdian name"),
   
  });
  export const patientregistration = Yup.object({
    // patientname: Yup.string().required("Please enter the patient name"),
    patientname: Yup.string()
  .matches(/^[a-zA-Z\s]+$/, "Patient name should only contain letters")
  .required("Please enter the patient name"),
  phoneno: Yup.string()
  .matches(/^[0-9]+$/, "Mobile number should only contain numbers")
  .min(10, "Mobile number must be at least 10 characters")
  .max(10, "Mobile number must be 10 characters")
  .required("Mobile number is required"),
  email: Yup.string().email("Email must be a valid").nullable(),
  age: Yup.number()
  .typeError("Age must be a number")
  .positive("Age must be a positive number")
  .integer("Age must be an integer")
  .max(999, "Age must be at most 3 digits")
  .required("Please enter the age"),
  address: Yup.string().required("Please enter the  address"),
  gender: Yup.string().required("Gender is required"),
  //hospitalcode: Yup.string().required("Hospital code is required"),
  city: Yup.string().matches(/^[a-zA-Z\s]+$/, "City should only contain letters").nullable(),
  state: Yup.string().matches(/^[a-zA-Z\s]+$/, "State should only contain letters").nullable(),
  bloodgroup: Yup.string()
  .matches(/^[a-zA-Z+\- ]+$/, "Blood group should only contain letters, +, -, or spaces").nullable(),
  occupation: Yup.string()
  .matches(/^[a-zA-Z+\- ]+$/, "Occupation should only contain letters, +, -, or spaces").nullable(),
  gaurdianname: Yup.string().matches(/^[a-zA-Z\s]+$/, "Gaurdian name should only contain letters").nullable(),
  });
  export const patientregistrations = Yup.object({
    // patientname: Yup.string().required("Please enter the patient name"),
    patientname: Yup.string()
    .matches(/^[a-zA-Z\s]+$/, "Patient name should only contain letters")
    .required("Please enter the patient name"),
    phoneno: Yup.string()
    .matches(/^[0-9]+$/, "Mobile Number should only contain numbers")
    .min(10, "Mobile number must be at least 10 characters")
    .max(10, "Mobile number must be 10 characters")
    .required("Mobile number is required"),
    email: Yup.string().email("Email must be a valid").nullable(),
    age: Yup.number()
    .typeError("Age must be a number")
    .positive("Age must be a positive number")
    .integer("Age must be an integer")
    .max(999, "Age must be at most 3 digits")
    .required("Please enter the age"),
    address: Yup.string().required("Please enter the  address"),
    gender: Yup.string().required("Gender is required"),
    //hospitalcode: Yup.string().required("Hospital Code is required"),
    city: Yup.string().matches(/^[a-zA-Z\s]+$/, "City should only contain letters").nullable(),
    state: Yup.string().matches(/^[a-zA-Z\s]+$/, "State should only contain letters").nullable(),
    bloodgroup: Yup.string()
    .matches(/^[a-zA-Z+\- ]+$/, "Blood group should only contain letters, +, -, or spaces").nullable(),
    occupation: Yup.string()
    .matches(/^[a-zA-Z+\- ]+$/, "Occupation should only contain letters, +, -, or spaces").nullable(),
    gaurdianname: Yup.string().matches(/^[a-zA-Z\s]+$/, " Gaurdian name should only contain letters").nullable(),
  });
  
  export const IdpvalidationSchema = Yup.object({
    patientname: Yup.string().required("Please enter the patient name"),
    phoneno: Yup.string()
    .matches(/^[0-9]+$/, "Mobile Number should only contain numbers")
    .min(10, "Mobile number must be at least 10 characters")
    .max(10, "Mobile number must be 10 characters")
    .required("Mobile number is required"),
    email: Yup.string().email("Email must be a valid"),
    age: Yup.number()
    .positive("Age must be a positive number")
    .integer("Age must be an integer")
    .required("Please enter the age"),
    address: Yup.string().required("Please enter the  address"),
    gender: Yup.string().required("Gender is required"),
    hospitalcode: Yup.string().required("Hospital Code is required"),
    bedname: Yup.string().required("Please enter the bed name"),
    bedtype: Yup.string().required("Please select the bed type"),
    groupname: Yup.string().required("Please select the bed group name"),
    hospitalcode: Yup.string().required("Please enter the hospital code"),
    admitedby: Yup.string().required("Please enter the admitted by"),
    admitdate: Yup.string().required("Please enter the admit date"),
  });
  // export const userregistration = Yup.object({
  //   firstname: Yup.string().required("Please enter the first name"),
  //   lastname: Yup.string().required("Please enter the last name"),
  //   email: Yup.string().email().required("Please enter the email"),
  //   password: Yup.string().min(6).required("Please enter the password"),
  //   cpassword: Yup.string().required().oneOf([Yup.ref('password'), null]),
  //   gender: Yup.string().required("Please enter the gender"),
  //   role: Yup.string().required("Please select the role"),
  //   phoneno: Yup.string().matches(/^[0-9]+$/, "Phone Number should only contain numbers")
  //   .min(10, "Phone Number must be at least 10 characters")
  //   .max(10, "Phone Number must be 10 characters")
  //   .required("Phone Number is required"),
  //   designation: Yup.string().required("Please select designation the gender"),
  //   hospitalcode: Yup.string().required("Please enter the hospital code")
    
  // });
  export const chargeValidation = Yup.object({
    chargetype: Yup.string().required("Please enter the charge type"),
    chargename: Yup.string().required("Please enter the charge name"),
    chargeamount: Yup.number()
    .positive("chargeamount must be a positive number").required("Please enter the charge amount"),
    tax:Yup.number()
    .moreThan(-1, "Tax must be a positive number or zero")
    .nullable(),
  });
  export const chargeValidationHospital = Yup.object({
    chargetype: Yup.string().required("Please enter the charge type"),
    chargename: Yup.string().required("Please enter the charge name"),
    chargeamount: Yup.number()
    .positive("Charge amount must be a positive number").required("Please enter the charge amount"),
    tax:Yup.number()
    .moreThan(-1, "Tax must be a positive number or zero")
    .nullable(),
    hospitalcode: Yup.string().required("Please enter the hospital code"),
    
  });
  export const hospitalregistration = Yup.object({
    hospitalname: Yup.string().required("Please enter the hospital name"),
    hospital: Yup.string().required("Please enter the hospital short name"),
    phoneno: Yup.string().matches(/^[0-9]+$/, "Phone number should only contain numbers")
    .min(10, "Phone number must be at least 10 characters")
    .max(10, "Phone number must be 10 characters")
    .required("Phone number is required"),
    address: Yup.string().required("Please enter the  address"),
    // workhour: Yup.number()
    // .positive("Working hour must be a positive number"),
    workhour: Yup.string().matches(/^(?:[01]\d|2[0-3])(?::[0-5]\d)?(?:[-–](?:[01]\d|2[0-3])(?::[0-5]\d)?)?$/, "Working hours should be in 24x7 format (e.g., 09:00-17:00)"),
    registrationnumber: Yup.number()
    .integer("Registration number must be an integer")
    .positive("Registration number must be a positive number")
    .required("Please enter the registration number"),
    email: Yup.string().required("Please enter the email"),
    numberofusers: Yup.number()
    .positive("Number of users must be a positive number"),
    licenserate: Yup.number()
   .positive("License rate must be a positive number"),
    amountpaid: Yup.number()
   .positive("Amount paid must be a positive number"),
    birthcertificate: Yup.mixed()
      .nullable()
      .test(
        'fileSize',
        'File size should be less than 3MB',
        (value) => !value || (value && value.size <= 3000000)
      ),
    deathcertificate: Yup.mixed()
      .nullable()
      .test(
        'fileSize',
        'File size should be less than 3MB',
        (value) => !value || (value && value.size <= 3000000)
      ),
    attachone: Yup.mixed().required('Please attach a file')
      .test(
        'fileSize',
        'File size should be less then 3MB',
        (value) => value && value.size <= 3000000
      )
    .test(
      'fileType',
      'Unsupported file type',
      (value) => value && ['image/jpeg', 'image/png', 'image/jpg'].includes(value.type)
    )
    ,
    hospitallogo: Yup.mixed().required('Please attach a file')
    .test(
      'fileSize',
      'File size should be less then 3MB',
      (value) => value && value.size <= 3000000,
      
    )
    .test(
      'fileType',
      'Unsupported file type',
      (value) => value && ['image/jpeg', 'image/png', 'image/jpg'].includes(value.type)
    )
    ,
    attachtwo: Yup.mixed().required('Please attach a file')
    .test(
      'fileSize',
      'File size should be less then 3MB',
      (value) => value && value.size <= 3000000
     )
    //.test(
    //   'fileType',
    //   'Unsupported file type',
    //   (value) => value && ['image/jpeg', 'image/png', 'image/jpg'].includes(value.type)
    // )
    ,

    // hospitalfavicon: Yup.mixed().required('Please attach a file'),
    // attachthree: Yup.mixed().required('Please attach a file'),



  });
  export const Nursenotevalidation = Yup.object({
    date: Yup.date().required("Please enter the date"),
    nurseid: Yup.string().required("Please enter the nurse name"),
    note: Yup.string().required("Please enter the note"),
    comment: Yup.string().required("Please enter the comment"),
  });

  export const Operationvalidation = Yup.object({
    operationdate: Yup.date().required("Please enter the date"),
    //categoryid:Yup.string().required("Please enter the category"),
    operationcode:Yup.string().required("Please select the operation category to select the operation name"),
    //doctorids:Yup.string().required("Please enter the doctor name"),
  });
  export const paymentvalidation = Yup.object({
   // date: Yup.date().required("Please enter the date"),
    amount: Yup.string().required("Please enter the amount"),
    
  });
  export const consultent = Yup.object({
    applieddate: Yup.date().required("Please enter the date"),
    doctorids: Yup.string().required("Please enter the doctor name"),
    instructiondate:Yup.date().required("Please enter the date"),
    instruction:Yup.string().required("Please enter the instruction"),
  }); 
  export const Medicationvalidation= Yup.object({
    date: Yup.date().required("Please enter the date"),
    time: Yup.string().required("Please enter the time"),
    //medicinecategory:Yup.string().required("Please enter the  medicine category"),
    medicinename:Yup.string().required("Please select the medicinecategory to select the medicine name"),
   // remarks:Yup.string().required("Please enter the remarks"),
    dosage:Yup.string().required("Please enter the dosage"),
  }); 

  export const Medicineunit = Yup.object().shape({
    unit: Yup.string().matches(/^[0-9]+$/, "Unit should only contain numbers").required('Unit is required'),
   hospitalcode: Yup.string().required("Please select the hospitalcode"),
  
   
  });
  export const Medicineunit1 = Yup.object().shape({
    unit: Yup.string().matches(/^[0-9]+$/, "Unit should only contain numbers").required('Unit is required'),
  
  });
  
  export const AddMedicineValidation = Yup.object().shape({
  medicinename: Yup.string().required('Medicine name is required'),
  hospitalcode: Yup.string().required("Please select the hospital code"),
  medicinecategoryid: Yup.string().required("Please select the category"),
  medicinemanufacturerid: Yup.string().required("Please select the supplier"),
  //medicineunitid: Yup.string().required("Please select the unit"),
  // sellingprice: Yup.string().required("Please enter the selling price"),
  // buyingprice: Yup.string().required("Please enter the buying price"),
});
export const AddMedicineValidation1 = Yup.object().shape({
  medicinename: Yup.string().required('Medicine name is required'),
  medicinecategoryid: Yup.string().required("Please select the category"),
  medicinemanufacturerid: Yup.string().required("Please select the supplier"),
  //medicineunitid: Yup.string().required("Please select the unit"),
  // sellingprice: Yup.string().required("Please enter the selling price"),
  // buyingprice: Yup.string().required("Please enter the buying price"),
});
export const EditMedicineValidation = Yup.object().shape({
  medicinename: Yup.string().required('Medicine name is required'),
  hospitalcode: Yup.string().required("Please select the hospitalcode"),
  medicinecategoryid: Yup.string().required("Please select the category"),
 // sellingprice: Yup.string().required("Please enter the selling price"),
  //buyingprice: Yup.string().required("Please enter the buying price"),
 
});


export const EditMedicineValidation1 = Yup.object().shape({
  medicinename: Yup.string().required('Medicine name is required'),
  medicinecategoryid: Yup.string().required("Please select the category"),
 // sellingprice: Yup.string().required("Please enter the selling price"),
 // buyingprice: Yup.string().required("Please enter the buying price"),
});


export const AddMedicineCategoryValidation = Yup.object().shape({
  medicinecategory: Yup.string().required('Medicine category is required'),
  hospitalcode: Yup.string().required("Please select the hospitalcode"),

});


export const AddMedicineCategoryValidation1 = Yup.object().shape({
  medicinecategory: Yup.string().required('Medicine category is required'),
 
 
});


export const EditMedicineCategoryValidation = Yup.object().shape({
  medicinecategory: Yup.string().required('Medicine category is required'),
  hospitalcode: Yup.string().required("Please select the hospital code"),

});


export const EditMedicineCategoryValidation1 = Yup.object().shape({
  medicinecategory: Yup.string().required('Medicine category is required'),
 
 
});


export const AddMedicineManufacturerValidation = Yup.object().shape({
  suppliername: Yup.string().required('Supplier name is required')
  .matches(/^[a-zA-Z\s]+$/, "Supplier name should only contain letters"),
  address: Yup.string().required('Address is required'),
  details : Yup.string().required('Details is required'),
  phoneno: Yup.string()
    .matches(/^[0-9]+$/, "Mobile number should only contain numbers")
    .min(10, "Mobile number must be at least 10 characters")
    .max(10, "Mobile number must be 10 characters")
    .required("Mobile number is required"),
    email: Yup.string().email().required("Please enter the email"),
   hospitalcode: Yup.string().required("Please select the hospital code"),

});


export const AddMedicineManufacturerValidation1 = Yup.object().shape({
  suppliername: Yup.string().required('Supplier name is required')
  .matches(/^[a-zA-Z\s]+$/, "Supplier name should only contain letters"),
  address: Yup.string().required('Address is required'),
  details : Yup.string().required('Details is required'),
  phoneno: Yup.string()
    .matches(/^[0-9]+$/, "Mobile number should only contain numbers")
    .min(10, "Mobile number must be at least 10 characters")
    .max(10, "Mobile number must be 10 characters")
    .required("Mobile number is required"),
    email: Yup.string().email().required("Please enter the email"),
 
});



export const EditMedicineManufacturerValidation = Yup.object().shape({
 // companyname: Yup.string().required('Company name is required'),
  suppliername: Yup.string().required('Supplier name is required'),
  address: Yup.string().required('Address is required'),
  details : Yup.string().required('Details is required'),
  phoneno: Yup.string()
    .matches(/^[0-9]+$/, "Mobile number should only contain numbers")
    .min(10, "Mobile number must be at least 10 characters")
    .max(10, "Mobile number must be 10 characters")
    .required("Mobile number is required"),
    email: Yup.string().email().required("Please enter the email"),
   hospitalcode: Yup.string().required("Please select the hospital code"),

});

export const EditMedicineManufacturerValidation1 = Yup.object().shape({
 // companyname: Yup.string().required('Company name is required'),
  suppliername: Yup.string().required('Supplier name is required'),
  address: Yup.string().required('Address is required'),
  details : Yup.string().required('Details is required'),
  phoneno: Yup.string()
    .matches(/^[0-9]+$/, "Mobile number should only contain numbers")
    .min(10, "Mobile number must be at least 10 characters")
    .max(10, "Mobile number must be 10 characters")
    .required("Mobile number is required"),
    email: Yup.string().email().required("Please enter the email"),
 
});
export const pathologyreportvalidation = Yup.object().shape({
  hospitalcode: Yup.string().required('Hospital code is required'),
  name:Yup.string().required('Patient name is required'),
});
export const pathologyreportvalidation2 = Yup.object().shape({
 // hospitalcode: Yup.string().required('Hospital Code is required'),
 name:Yup.string().required('Patient name is required'),
});
export const ParametervalidationSchema = Yup.object().shape({
  chargecode: Yup.string()
    .required("Pathology category is required"),
  parametername: Yup.string()
    .required("Parameter name is required"),
  referencerange: Yup.string()
    .required("Reference range is required"),
  hospitalcode: Yup.string()
    .required("Hospital code is required"),
  unitid: Yup.string()
    .required("Unit is required"),
  description: Yup.string()
    .required("Description is required"),
});

export const ParametervalidationSchema2 = Yup.object().shape({
  chargecode: Yup.string()
    .required("Pathology category is required"),
  parametername: Yup.string()
    .required("Parameter name is required"),
  referencerange: Yup.string()
    .required("Reference range is required"),
  unitid: Yup.string()
    .required("Unit is required"),
  description: Yup.string()
    .required("Description is required"),
});



export const validationRadiologyReport = Yup.object().shape({
  hospitalcode: Yup.string().required('Hospital code is required'),
  name: Yup.string().required('Name is required'),
  age: Yup.number()
    .required('Age is required')
    .positive('Age must be a positive number')
    .integer('Age must be an integer'),
  sex: Yup.string().required('Sex is required'),
  medicalHistory: Yup.string().required('Medical history is required'),
  radiologycategoryid: Yup.string().required('Type of imaging study is required'),
  bodyPart: Yup.string().required('Body part/area imaged is required'),
  reason: Yup.string().required('Reason for study is required'),
  summary: Yup.string().required('Summary of findings is required'),
  impression: Yup.string().required('Impression is required'),
  recommendations: Yup.string().required('Recommendations are required'),
  signature: Yup.string().required('Signature is required'),
  date: Yup.date().required('Date is required').nullable(),
});


export const validationRadiologyReport2 = Yup.object().shape({
  //hospitalcode: Yup.string().required('Hospital code is required'),
  name: Yup.string().required('Name is required'),
  age: Yup.number()
    .required('Age is required')
    .positive('Age must be a positive number')
    .integer('Age must be an integer'),
  sex: Yup.string().required('Sex is required'),
  medicalHistory: Yup.string().required('Medical History is required'),
  radiologycategoryid: Yup.string().required('Type of Imaging Study is required'),
  bodyPart: Yup.string().required('Body Part/Area Imaged is required'),
  reason: Yup.string().required('Reason for Study is required'),
  summary: Yup.string().required('Summary of Findings is required'),
  impression: Yup.string().required('Impression is required'),
  recommendations: Yup.string().required('Recommendations are required'),
  signature: Yup.string().required('Signature is required'),
  date: Yup.date().required('Date is required').nullable(),
});

export const Prescriptionvalidation= Yup.object({
 
  findingcategory:Yup.string().required("Please enter findingcategory"),
  //doctorids:Yup.string().required("Please select the doctor"),
  description:Yup.string().required("Please enter the finding description"),
}); 

export const AddOperationCategoryValidation = Yup.object().shape({
  category: Yup.string().required('Operation category is required'),
  hospitalcode: Yup.string().required("Please select the hospitalcode"),
});
export const AddOperationCategoryValidation1 = Yup.object().shape({
  category: Yup.string().required('Operation category is required'),
});
export const AddOperationValidation = Yup.object().shape({
  operationname: Yup.string().required('Operation name is required'),
  hospitalcode: Yup.string().required("Please select the hospital code"),
  categoryid: Yup.string().required("Please select the category"),
  
});
export const AddOperationValidation1 = Yup.object().shape({
  operationname: Yup.string().required('Operation name is required'),
  categoryid: Yup.string().required("Please select the category"),
  
});