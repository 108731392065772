import React from 'react'
import Login from './LoginAndRegistrationPages/Login'

// export const Loginpage = () => {
//   return (
//     <div className=" ">
//     <Header />
//   <div className="row" style={{ marginTop: '50px' }}>
//     {/* Sidebar */}
//     <div className="col-md-2">
//       <Sidebar />
//     </div>
    
//     {/* Main Content */}
//     <div className="col-md-10">
//     <UserRegistration/>
//     </div>
//   </div>
// </div>
//   )
// }

export const Loginpageforlogin = () => {
    return (
      <div>
          <Login/>
      </div>
    )
  }