import React from "react";
import { useFormik } from "formik";
import { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useHistory } from "react-router-dom";
import axios from "../../../helper/axios";
import { Table, Button, Modal, Form } from "react-bootstrap";
import { AddOperationValidation, AddOperationValidation1, patientregistration } from "../../../helper/schemas";
import { ToastContainer, toast } from 'react-toastify';
import * as Yup from "yup";  // Import Yup for validation
import { useUser } from "../../../Provider";
import HospitalCodeSearch from "../../../Component/HospitalCodeSearch";
import SearchOperationCategory from "../../../Component/SearchOperationCategory";
const initialValues = {
  operationname: "",
  hospitalcode: "",
  categoryid: "",
};
const OperationSetup = () => {
  const { user, userHospitalcode, userrole1, isAuthRole, isAuthorized, gettokenId, token } = useUser();
  const history = useHistory();
  const [message, setMessage] = useState("");
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [category1, setCategory] = useState([]);
  // initialised isAuthorized value as false
  const [searchValue, setSearchValue] = useState("");
  const getValidationSchema = () => {
    if (userHospitalcode === 1) {
      return AddOperationValidation;

    } else  {
      return AddOperationValidation1;
    }
  }

  //get current date
  let Showdate = new Date();
  let ShowTodaydate =
    Showdate.getFullYear() +
    "-" +
    (Showdate.getMonth() + 1) +
    "-" +
    Showdate.getDate();
  const [currentDate, setCurrentDate] = useState(ShowTodaydate);
  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    resetForm,
    setValues
  } = useFormik({
    initialValues,
   validationSchema: getValidationSchema, // Apply validation schema
    onSubmit: async (values, action) => {
      try {
        const data = new FormData();
        data.append("document", JSON.stringify(values));
        data.append("currentDate", JSON.stringify(currentDate));
        data.append("gettokenId", JSON.stringify(gettokenId));
        data.append("userHospitalcode", JSON.stringify(userHospitalcode));
        console.log(JSON.stringify(values));
        const response = await axios.post("/addoperation", data, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        });
        console.log(
          "🚀 ~ file: Registration.jsx ~ line 11 ~ Registration ~ values",
          values
        );
        if (response.status === 200) {
          return history.push("/listviewoperations");
        }
      } catch (error) {
        console.log(error)
        toast("Something Went Wrong")
      }
    },
  });
  //Hospitalcode search
  const onSearchItems = (searchTerm) => {
    console.log("------", searchTerm)
    setValues({
      ...values,
      hospitalcode: searchTerm.hospitalcode,
      // Update other fields as needed
    });
  };
  const handleReset = () => {
    // Reset the form using Formik's resetForm function
    resetForm();
  };
  const [data, setData] = useState([]);
  const [userRole, setRole] = useState();
  //when page load or refresh happen this function will got called
  useEffect(() => {
    const loadData = async () => {
      await loadHospitalcode();
      const selectedHospitalCode = values.hospitalcode || userHospitalcode;
      //await fetchCateoryNames(selectedHospitalCode);
    };
    loadData();
  }, [userHospitalcode, userrole1, isAuthorized, values.hospitalcode]);
  //on Category change
  const handleOperationChanges = (searchTerms) => {
    console.log("---------------", searchTerms)
    // setCategory(searchTerms); // Update the searchValue state with the search term
    const selectedCategory = searchTerms.categoryid;
    setCategory(selectedCategory);
    setValues({
      ...values,
      categoryid: searchTerms.categoryid,
    })
  };
  //  get all hospital 
  const loadHospitalcode = async () => {
    //when userHospitalcode and userrole1 is not undefined then execute /gethospitalcode
    if (userHospitalcode !== undefined && userrole1 !== undefined) {
      const res = await axios.get("/gethospitalcode", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          hospitalcode: userHospitalcode, // Include the hospitalcode in the query parameters
          userrole: userrole1, // Include the userrole in the query parameters
        },
      });
      setData(res.data);
      console.log(res.data);
    };
  }
  const currentDomain = window.location.hostname;

  return (
    <div className="p-3 pt-0 appointment">
      <Helmet>
        <title>Aarogyasaarthi | {currentDomain} | Add Operation</title>
      </Helmet>
      <form onSubmit={handleSubmit}>
        {success && <div>Add Operation</div>}
        {error && <div>Error in add bed type please try again</div>}
        <div class="fill header"><h3 class="float-left"> Add Operation</h3> </div>
        <div className="patient-new primary-info">
          <div className="row">
            <div className="col-md-12">
              <div className="row">
                <div className="col-md-6">

                  <div className="col-md-12 mt-3">
                    <div className="row">

                      <div className="col-md-4">
                        <label
                          htmlFor="operationname"
                          className="col-form-label leftone"
                        >
                          Operation Name
                        </label>
                      </div>
                      <div className="col-md-8">
                        <input
                          type="text"
                          autoComplete="off"
                          name="operationname"
                          id="operationname"
                          placeholder="Operation Name"
                          value={values.operationname}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          style={{ width: '73%', height: '32px', border: '1px solid #ced4da', color: '#495057' }}
                        />
                        {errors.operationname && touched.operationname ? (
                          <p className="form-erroremployee">{errors.operationname}</p>
                        ) : null}
                      </div></div></div>

                  {isAuthorized ? (<div className="col-md-12 mt-3">
                    <div className="row">

                      <div className="col-md-4">
                        <label
                          htmlFor="hospitalcode"
                          className="col-form-label leftone"
                        >
                          Hospital Code
                        </label>
                      </div>
                      <div className="col-md-8">
                        {/* Conditionally render the dropdown and input field */}
                        {isAuthorized ? (
                          <HospitalCodeSearch onSearchItem={onSearchItems} />
                        ) : (
                          <input
                            type="text"
                            name="hospitalcode"
                            id="hospitalcode"
                            value={userHospitalcode}
                            disabled={true} // Set the input as disabled
                            className="form-control "
                          />
                        )}
                        {isAuthorized && errors.hospitalcode && touched.hospitalcode ? (
                          <p className="form-erroremployee">{errors.hospitalcode}</p>
                        ) : null}
                      </div></div></div>) : null}
                </div>
                <div className="col-md-6">
                  <div className="col-md-12 mt-3">
                    <div className="row">
                      <div className="col-md-4">
                        <label
                          htmlFor="categoryid"
                          className="col-form-label leftone"
                        >
                          Category
                        </label>
                      </div>
                      <div className="col-md-8">
                        <SearchOperationCategory onCategoryChange={handleOperationChanges}
                          hospitalvalue={values.hospitalcode || userHospitalcode} />
                        {errors.categoryid && touched.categoryid ? (
                          <p className="form-erroremployee">{errors.categoryid}</p>
                        ) : null}
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-6  mt-3">
          <Button
            type="submit"
            variant="success"
            className="mx-3"
          >
            Save
          </Button>
          <Button
            variant="primary"
            className="mx-3"
            onClick={handleReset}
          >
            Reset
          </Button>
        </div>
      </form>
    </div>
  );
};
export default OperationSetup;
