import React from "react";
import { useFormik } from "formik";
import { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useHistory } from "react-router-dom";
import axios from "../../../helper/axios";
import { Table, Button, Modal, Form } from "react-bootstrap";
import { patientregistration } from "../../../helper/schemas";
import { ToastContainer, toast } from 'react-toastify';
import * as Yup from "yup";  // Import Yup for validation
import { useUser } from "../../../Provider";
import HospitalCodeSearch from "../../../Component/HospitalCodeSearch";
import SearchBedType from "../../../Component/SearchBedType";
import SearchBedGroup from "../../../Component/SearchBedGroup";


// Define validation schema for authorized and unauthorized users
const ValidationSchema = Yup.object().shape({
  bedname: Yup.string().required('Bed name is required'),
  hospitalcode: Yup.string().required("Please select the hospital code"),
  bedgroupid: Yup.string().required("Please select the bed group name"),
  bedtypeid: Yup.string().required("Please select the bed type"),
});

const ValidationSchema1 = Yup.object().shape({
  bedname: Yup.string().required('Bed name is required'),
  bedgroupid: Yup.string().required("Please select the bed group name"),
  bedtypeid: Yup.string().required("Please select the bed type"),
});

// Initial form values
const initialValues = {
  bedname: "",
  bedtypeid: "",
  bedgroupid: "",
  hospitalcode: "",
};

const AddBed = () => {
  // Extract user details and functions from custom hook
  const { user, userHospitalcode, userrole1, isAuthRole, isAuthorized, gettokenId, token } = useUser();
  const history = useHistory();
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [currentDate, setCurrentDate] = useState(new Date().toISOString().slice(0, 10));

  // Function to determine which validation schema to use based on user role and hospital code
  const getValidationSchema = () => {
    if (userHospitalcode === 1) {
      return ValidationSchema;
    } else {
      return ValidationSchema1;
    }
  }

  // Formik hook for form handling
  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    resetForm,
    setValues
  } = useFormik({
    initialValues,
    validationSchema: getValidationSchema(), // Apply validation schema
    onSubmit: async (values, action) => {
      try {
        // Create form data for submission
        const data = new FormData();
        data.append("document", JSON.stringify(values));
        data.append("currentDate", JSON.stringify(currentDate));
        data.append("gettokenId", JSON.stringify(gettokenId));
        data.append("userHospitalcode", JSON.stringify(userHospitalcode));
        data.append('hospitalcode', values.hospitalcode);

        // Make API call to add bed
        const response = await axios.post("/addbed", data, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        });

        if (response.status === 200) {
          return history.push("/listviewbed");
        }
      } catch (error) {
        console.log(error)
        toast("Something Went Wrong")
      }
    },
  });
 //Hospitalcode search
 const onSearchItems = (searchTerm) => {
  console.log("------",searchTerm)
  setValues({
    ...values,
    hospitalcode: searchTerm.hospitalcode,
    // Update other fields as needed
  });
};
  // Function to reset the form
  const handleReset = () => {
    resetForm();
  };
  //search Bed Type
  const handleBedTypeChanges  = (searchTerms) => {
    console.log("---------------", searchTerms)
    setValues({
      ...values,
      bedtypeid: searchTerms.bedtypeid,
    })
  };
const handleBedGroupChanges = (searchTerms) => {
  console.log("---------------", searchTerms)
  setValues({
    ...values,
    bedgroupid: searchTerms.bedgroupid,
  })
};
  // State variables for data and bed types/groups
  // const [data, setData] = useState([]);
  const [bedTypes, setBedTypes] = useState([]);
  const [bedGroups, setBedGroups] = useState([]);

  // Effect hook to load data on component mount or when dependencies change
  useEffect(() => {
    const loadData = async () => {
      //await loadHospitalcode();
    };
    loadData();
  }, [userHospitalcode, userrole1, isAuthorized, values.hospitalcode]);

  // Function to load hospital codes
  // const loadHospitalcode = async () => {
  //   if (userHospitalcode !== undefined && userrole1 !== undefined) {
  //     const res = await axios.get("/gethospitalcode", {
  //       headers: {
  //         Authorization: `Bearer ${token}`,
  //       },
  //       params: {
  //         hospitalcode: userHospitalcode,
  //         userrole: userrole1,
  //       },
  //     });
  //     setData(res.data);
  //   };
  // }

  // Function to fetch bed types
  // const fetchBedTypes = async (selectedHospitalCode) => {
  //   try {
  //     const response = await axios.get("/getbedtype", {
  //       headers: {
  //         Authorization: `Bearer ${token}`,
  //       },
  //       params: {
  //         hospitalcode: selectedHospitalCode,
  //       },
  //     });

  //     if (response.status === 200) {
  //       setBedTypes(response.data);
  //     } else {
  //       console.error(`Failed to fetch available bed types with status: ${response.status}`);
  //     }
  //   } catch (error) {
  //     console.error('Error fetching available bed types:', error.message);
  //   }
  // };

  // Function to fetch bed groups
  // const fetchBedGroups = async (selectedHospitalCode) => {
  //   try {
  //     const response = await axios.get("/getbedgroup", {
  //       headers: {
  //         Authorization: `Bearer ${token}`,
  //       },
  //       params: {
  //         hospitalcode: selectedHospitalCode,
  //       },
  //     });

  //     if (response.status === 200) {
  //       setBedGroups(response.data);
  //     } else {
  //       console.error(`Failed to fetch available bed groups with status: ${response.status}`);
  //     }
  //   } catch (error) {
  //     console.error('Error fetching available bed groups:', error.message);
  //   }
  // };

  // Get the current domain for page title
  const currentDomain = window.location.hostname;

  return (
    <div className="p-3 pt-0 appointment">
      <Helmet>
        <title>Add Bed | {currentDomain} | Hospital Management Software | Smart Solutions for Hospital Efficiency</title>
      </Helmet>
      <form onSubmit={handleSubmit}>
        {success && <div>Add Bed</div>}
        {error && <div>Error in add bed. Please try again</div>}
        <div className="fill header">
          <h3 className="float-left"> Add Bed </h3>
        </div>
        <div className="patient-new primary-info">
          <div className="row">
            <div className="col-md-12">
              <div className="row">
                <div className="col-md-6">
                  <div className="col-md-12 mt-3">
                    <div className="row">
                      <div className="col-md-4">
                        <label htmlFor="bedname" className="col-form-label leftone">
                          Bed Name
                        </label>
                      </div>
                      <div className="col-md-8">
                        <input
                          type="text"
                          autoComplete="off"
                          name="bedname"
                          id="bedname"
                          placeholder="Bed Name"
                          value={values.bedname}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          style={{ width: '73%', height: '32px', border: '1px solid #ced4da', color: '#495057' }}
                        />
                        {errors.bedname && touched.bedname ? (
                          <p className="form-erroremployee">{errors.bedname}</p>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12 mt-3">
                    <div className="row">
                      <div className="col-md-4">
                        <label htmlFor="groupname" className="col-form-label leftone">
                          Bed Group Name
                        </label>
                      </div>
                      <div className="col-md-8">
                      <SearchBedGroup
                          onBedGroupChange={handleBedGroupChanges}
                            hospitalvalue={values.hospitalcode || userHospitalcode}
                          />
                        {/* <select
                          type="text"
                          autoComplete="off"
                          name="bedgroupid"
                          id="bedgroupid"
                          placeholder="Group Name"
                          value={values.bedgroupid}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          style={{ width: '73%', height: '32px', border: '1px solid #ced4da', color: '#495057' }}
                        >
                          <option>Select</option>
                          {bedGroups.map((code) => (
                            <option value={code.bedgroupid} key={code.id}>
                              {code.groupname}-{code.floorname}
                            </option>
                          ))}
                        </select> */}
                        {errors.bedgroupid && touched.bedgroupid ? (
                          <p className="form-erroremployee">{errors.bedgroupid}</p>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  {isAuthorized ? (
                    <div className="col-md-12 mt-3">
                      <div className="row">
                        <div className="col-md-4">
                          <label htmlFor="hospitalcode" className="col-form-label leftone">
                            Hospital Code
                          </label>
                        </div>
                        <div className="col-md-8">
                          {isAuthorized ? (
                             <HospitalCodeSearch onSearchItem={onSearchItems} />
                            // <select
                            //   type="text"
                            //   autoComplete="off"
                            //   name="hospitalcode"
                            //   id="hospitalcode"
                            //   placeholder="Hospital Code"
                            //   value={values.hospitalcode}
                            //   onChange={handleChange}
                            //   onBlur={handleBlur}
                            //   onSearchChange={(e) => handleSearchChange(e.target.value)}
                            //   style={{ width: '73%', height: '32px', border: '1px solid #ced4da', color: '#495057' }}
                            // >
                            //   <option>Select</option>
                            //   {data.map((code) => (
                            //     <option value={code.hospitalcode} key={code.id}>
                            //       {code.hospitalcode}
                            //     </option>
                            //   ))}
                            // </select>
                          ) : (
                            <input
                              type="text"
                              name="hospitalcode"
                              id="hospitalcode"
                              value={userHospitalcode}
                              disabled={true}
                              className="form-control "
                            />
                          )}
                          {isAuthorized && errors.hospitalcode && touched.hospitalcode ? (
                            <p className="form-erroremployee">{errors.hospitalcode}</p>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  ) : null}

                  <div className="col-md-12 mt-3">
                    <div className="row">
                      <div className="col-md-4">
                        <label htmlFor="bedtype" className="col-form-label leftone">
                          Bed Type
                        </label>
                      </div>
                      <div className="col-md-8">
                      <SearchBedType
                          onBedTypeChange={handleBedTypeChanges}
                            hospitalvalue={values.hospitalcode || userHospitalcode}
                          />
                        {/* <select
                          type="text"
                          autoComplete="off"
                          name="bedtypeid"
                          id="bedtypeid"
                          placeholder="Bed Type"
                          value={values.bedtypeid}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          style={{ width: '73%', height: '32px', border: '1px solid #ced4da ', color: '#495057' }}
                        >
                          <option>Select</option>
                          {bedTypes.map((code) => (
                            <option value={code.bedtypeid} key={code.id}>
                              {code.bedtype}
                            </option>
                          ))}
                        </select> */}
                        {errors.bedtypeid && touched.bedtypeid ? (
                          <p className="form-erroremployee">{errors.bedtypeid}</p>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6 mt-3">
            <Button type="submit" variant="success" className="mx-3">
              Save
            </Button>
            <Button variant="primary" className="mx-3" onClick={handleReset}>
              Reset
            </Button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default AddBed;