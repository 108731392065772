//in this patient details will be displayed along with edit button

import axios from "../../../helper/axios";
import React, { useEffect, useState } from "react";
import { NavLink, useHistory, useParams } from "react-router-dom";
import { GrEdit } from "react-icons/gr";
import "bootstrap/dist/css/bootstrap.min.css";
import { Table, Button, Modal, Form } from "react-bootstrap";
// import Cookies from "js-cookie";
import { useUser } from "../../../Provider";
import { Helmet } from "react-helmet";
const EditStaff = () => {
  const history = useHistory();
  const { user, userHospitalcode, userrole1, isAuthRole, isAuthorized, gettokenId,token } = useUser();
  console.log(user, userHospitalcode, userrole1, isAuthRole, isAuthorized, gettokenId)
  const { empid } = useParams();
  console.log("id " + empid);
  const [staffedit, setStaffEdit] = useState({
    fname: "",
    lname: "",
    email: "",
    password: "",
    dob: "",
    gender: "",
    maritalstatus: "",
    role: "",
    email: "",
    phoneno: "",
    bloodgroup: "",
    state: "",
    hospitalcode: "",
    city: "",
    cpassword: "",
    designation: "",
    doctorfees: "",


  });
  const [gettokenRole, setTokenRole] = useState();
  const [data, setData] = useState();
  // const [editPatient, setEditPatient] = useState();
  //const token = sessionStorage.getItem("jwtToken");

  //   useEffect(() => {
  //     // if (!sessionStorage.getItem("jwtToken")) {
  //     //   return history.push("/");
  //     // }
  //     loadAllDetails();
  //     editUser();
  //     // console.log("Role get------" + gettokenRole);
  //     // console.log(" get------" + gettokenId);
  //   }, [gettokenRole, gettokenId]); // Add dependencies here
   //get all hospital code
 const loadHospitalcode = async () => {
  //when userHospitalcode and userrole1 is not undefined then execute /gethospitalcode
  try{
  if (userHospitalcode !== undefined && userrole1 !== undefined) {
    const res = await axios.get("/gethospitalcode", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        hospitalcode: userHospitalcode, // Include the hospitalcode in the query parameters
        userrole: userrole1, // Include the userrole in the query parameters
      },
    });
    setData(res.data);
    console.log(res.data);
  }
}catch(error){
  // Handle the error here
  console.error("Error loading hospital code:", error);
}
 };

  const loadAllStaffs = async () => {
    try{
    if (userHospitalcode !== undefined && userrole1 !== undefined ) {
    const res = await axios.get(`/GetStaff/${empid}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        hospitalcode: userHospitalcode, // Include the hospitalcode in the query parameters
        userrole: userrole1, // Include the userrole in the query parameters
      },
    });
    const newProduct = await res.data;
    setStaffEdit(newProduct);
    console.log(newProduct)
  }
} catch(error){
  // Handle the error here,
  console.error("Error loading staff data:", error);
}
  };


  // const loadAllStaffs = async () => {
  //   const token = sessionStorage.getItem("jwtToken");
  //   const res = await axios.get("/GetStaff", {
  //     headers: {
  //       Authorization: `Bearer ${token}`,
  //     },
  //   });
  //   setData(res.data);
  //   console.log(res.data);
  // };

  // useEffect(() => {
  //   // if (!sessionStorage.getItem("jwtToken")) {
  //   //   return history.push("/");
  //   // }
  //   loadAllStaffs();
  //   // allempdetails();
  // }, []);
  useEffect(() => {
    const loadData = async () => {
      //loadHospitalcode(); will wait for loadAllDetails(); to got execute
      await loadHospitalcode();
      await  loadAllStaffs();
    };
    loadData();
    
  }, [userHospitalcode, userrole1, isAuthorized]);
  // const allempdetails = async () => {
  //   const res = await axios.get(`/GetStaff/${empid}`, {
  //     headers: {
  //       Authorization: `Bearer ${token}`,
  //     },
  //   });
  //   const newProduct = await res.data;
  //   setStaffEdit(newProduct);
  // };

  // const [success, setSuccess] = useState(false);
  // const [error, setError] = useState(false);
  const currentDomain = window.location.hostname;
  return (
    <>
      <div className="appointment p-2">
      <Helmet>
        <title>Staff Detail | {currentDomain} | Hospital Management Software | Smart Solutions for Hospital Efficiency</title>
      </Helmet>
        <div className="editbutt">
          <button className="input-butt">
            <NavLink to={`/listviewstaff/${empid}/edit`}>
              <GrEdit />
            </NavLink>
          </button>
          <br></br>
        </div>
        <form>
          {/* <h5> Staff Details</h5> */}
          <div class="fill header"><h3 class="float-left"> Staff Details  </h3> </div>
          <div className="patient-new primary-info">
            <div className="row">
              <div className="col-md-12">
                <div className="row">
                  <div className="col-md-6">
                    <div className="col-md-12 mt-3">
                      <div className="row">
                        <div className="col-md-4">
                          <label
                            htmlFor="fname"
                            className="col-form-label leftone"
                          >
                            First Name
                          </label>
                        </div>
                        <div className="col-md-8">
                          <input
                            type="text"
                            autoComplete="off"
                            name="fname"
                            id="fname"
                            placeholder="First Name"
                            value={staffedit.fname}
                            disabled
                            className="form-control "
                          />
                        </div>
                      </div>
                    </div>

                    <div className="col-md-12 mt-3">
                      <div className="row">
                        <div className="col-md-4">
                          <label
                            htmlFor="email"
                            className="col-form-label leftone"
                          >
                            Email
                          </label>
                        </div>
                        <div className="col-md-8">
                          <input
                            rows="1"
                            cols="30"
                            type="text"
                            autoComplete="off"
                            name="email"
                            id="email"
                            placeholder="Email"
                            value={staffedit.email}
                            disabled
                            className="form-control "
                          />

                        </div>
                      </div>
                    </div>

                    <div className="col-md-12 mt-3">
                      <div className="row">
                        <div className="col-md-4">
                          <label
                            htmlFor="gender"
                            className="col-form-label leftone"
                          >
                            Gender
                          </label>
                        </div>
                        <div className="col-md-8">
                          <input
                            type="text"
                            autoComplete="off"
                            name="gender"
                            id="gender"
                            placeholder="Gender"
                            value={staffedit.gender}
                            disabled
                            className="form-control "
                          />

                        </div>
                      </div>
                    </div>




                    <div className="col-md-12 mt-3">
                      <div className="row">
                        <div className="col-md-4">
                          <label
                            htmlFor="phoneno "
                            className="col-form-label leftone"
                          >
                            Mobile Number
                          </label>
                        </div>
                        <div className="col-md-8">
                          <input
                            type="number"
                            autoComplete="off"
                            name="phoneno"
                            id="phoneno"
                            placeholder="Mobile Number"
                            disabled
                            className="form-control "
                          />


                        </div>
                      </div>
                    </div>

                    <div className="col-md-12 mt-3">
                      <div className="row">
                        <div className="col-md-4">
                          <label
                            htmlFor="maritalstatus"
                            className="col-form-label leftone"
                          >
                            Marital Status
                          </label>
                        </div>
                        <div className="col-md-8">
                          <input
                            type="text"
                            autoComplete="off"
                            name="maritalstatus"
                            id="maritalstatus"
                            placeholder="maritalstatus"
                            value={staffedit.maritalstatus}
                            disabled
                            className="form-control "
                          />

                        </div>
                      </div>
                    </div>


                    <div className="col-md-12 mt-3">
                      <div className="row">
                        <div className="col-md-4">
                          <label
                            htmlFor="dob"
                            className="col-form-label"
                          >
                            Date of Birth
                          </label>
                        </div>
                        <div className="col-md-8">
                          <input
                            type="date"
                            autoComplete="off"
                            name="dob"
                            id="dob"
                            placeholder="DOB"
                            value={staffedit.dob}
                            disabled
                            className="form-control "
                          />

                        </div>
                      </div>
                    </div>


                    {/* <div className="col-md-12 mt-3">
                      <div className="row">
                        <div className="col-md-4">
                          <label
                            htmlFor="city"
                            className="col-form-label"
                          >
                            City
                          </label>
                        </div>
                        <div className="col-md-8">
                          <input
                            type="text"
                            autoComplete="off"
                            name="city"
                            id="city"
                            placeholder="City"
                            value={staffedit.city}
                            disabled
                            className="form-control "
                          ></input>
                        </div>
                      </div>
                    </div> */}


                    {isAuthorized ? (   <div className="col-md-12 mt-3">
                      <div className="row">
                        <div className="col-md-4">
                          <label
                            htmlFor="designation"
                            className="col-form-label"
                          >
                            Designation
                          </label>
                        </div>
                        <div className="col-md-8">
                          <input
                            type="text"
                            autoComplete="off"
                            name="designation"
                            id="designation"
                            placeholder="Designation"
                            value={staffedit.designation}
                            disabled
                            className="form-control "
                          />
                    
                        </div>
                      </div>
                    </div>
 ) : null}

                  </div>





                  <div className="col-md-6">
                    <div className="col-md-12 mt-3">
                      <div className="row">
                        <div className="col-md-4">
                          <label
                            htmlFor="lname"
                            className="col-form-label leftone"
                          >
                            Last Name
                          </label>
                        </div>
                        <div className="col-md-8">
                          <input
                            type="text"
                            autoComplete="off"
                            name="lname"
                            id="lname"
                            placeholder="Last Name"
                            value={staffedit.lname}
                            disabled
                            className="form-control "
                          />

                        </div>
                      </div>
                    </div>

{/* 
                    <div className="col-md-12 mt-3">
                      <div className="row">
                        <div className="col-md-4">
                          <label
                            htmlFor="passward"
                            className="col-form-label leftone"
                          >
                            Password
                          </label>
                        </div>
                        <div className="col-md-6">
                          <input
                            // type={type}
                            autoComplete="off"
                            name="password"
                            id="password"
                            placeholder="Password"
                            value={staffedit.password}

                            className="form-control "
                          />



                        </div>

                      </div>
                    </div>


                    <div className="col-md-12 mt-3">
                      <div className="row">
                        <div className="col-md-4">
                          <label
                            htmlFor="cpassword"
                            className="col-form-label"
                          >
                            Confirm Password
                          </label>
                        </div>
                        <div className="col-md-8">
                          <input
                            // type={type}
                            autoComplete="off"
                            name="cpassword"
                            id="cpassword"
                            placeholder="Confirm Password"
                            value={staffedit.cpassword}
                            disabled
                            className="form-control "
                          />

                        </div>

                      </div>
                    </div> */}

                    <div className="col-md-12 mt-3">
                      <div className="row">
                        <div className="col-md-4">
                          <label
                            htmlFor="role"
                            className="col-form-label"
                          >
                            Role
                          </label>
                        </div>
                        <div className="col-md-8">
                          <select
                            type="text"
                            autoComplete="off"
                            name="role1"
                            id="role"
                            placeholder="Department"
                            value={staffedit.role1}
                            disabled
                            className="form-control "
                          >
                            <option>Select</option>
                            <option>Admin</option>
                            <option>Doctor</option>
                            <option>Anesthetist</option>
                            <option>OT Technician</option>
                            <option>OT Assistant</option>
                            <option>Nurse</option>
                            <option>Compouder</option>
                            <option>Accountant</option>
                            <option>Pharmacist</option>
                            <option>Pathologist</option>
                            <option>Radiologist</option>
                            <option>Receptionist</option>
                      {/* 
                      <option>Select</option>
                         {data.map((code) => (
                        <option value={code.hospitalcode} key={code.id}>
                          {code.hospitalcode}
                        </option>
                      ))} */}
                          </select>

                        </div>
                      </div>
                    </div>

                    {isAuthorized ? (    <div className="col-md-12 mt-3">
                      <div className="row">
                     <div className="col-md-4">
                          <label
                            htmlFor="hospitalcode"
                            className="col-form-label"
                          >
                            Hospital Code
                          </label>
                        </div>  
                         <div className="col-md-8">
                          <input
                            type="text"
                            autoComplete="off"
                            name="hospitalcode
"
                            id="hospitalcode
"
                            placeholder="Enter Hospital Code"
                            value={staffedit.hospitalcode}
                            disabled
                            className="form-control "
                          />  
                        </div>
                      </div>
                    </div>  ) : null}

                    <div className="col-md-12 mt-3">
                      <div className="row">
                        <div className="col-md-4">
                          <label
                            htmlFor="bloodgroup"
                            className="col-form-label "
                          >
                            Blood Group
                          </label>
                        </div>
                        <div className="col-md-8">
                          <input
                            type="text"
                            autoComplete="off"
                            name="bloodgroup"
                            id="bloodgroup"
                            placeholder="Blood Group"
                            value={staffedit.bloodgroup}
                            disabled
                            className="form-control ember-text-field text-left ember-view"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="col-md-12 mt-3">
                      <div className="row">
                        <div className="col-md-4">
                          <label
                            htmlFor="state"
                            className="col-form-label"
                          >
                            State
                          </label>
                        </div>
                        <div className="col-md-8">
                          <input
                            type="text"
                            autoComplete="off"
                            name="state"
                            id="state"
                            placeholder="State"
                            value={staffedit.state}
                            disabled
                            className="form-control "
                          />
                        </div>
                      </div>
                    </div>

                    {/* {!isAuthorized ? (   <div className="col-md-12 mt-3">
                      <div className="row">
                        <div className="col-md-4">
                          <label
                            htmlFor="occupation"
                            className="col-form-label"
                          >
                            Occupation
                          </label>
                        </div>
                        <div className="col-md-8">
                          <input
                            type="text"
                            autoComplete="off"
                            name="occupation"
                            id="occupation"
                            placeholder="Occupation"
                            value={staffedit.occupation}
                            disabled
                            className="form-control "
                          />
                        </div>
                      </div>
                    </div>

                    
 ) : null} */}

 
<div className="col-md-12 mt-3">
                      <div className="row">
                        <div className="col-md-4">
                          <label
                            htmlFor="city"
                            className="col-form-label"
                          >
                            City
                          </label>
                        </div>
                        <div className="col-md-8">
                          <input
                            type="text"
                            autoComplete="off"
                            name="city"
                            id="city"
                            placeholder="City"
                            value={staffedit.city}
                            disabled
                            className="form-control "
                          ></input>
                        </div>
                      </div>
                    </div>


                    <div className="col-md-12 mt-3">
                      <div className="row">
                    {staffedit.designation === "Doctor" && (
          <div className="col-md-12 mt-3">
            <div className="row">
              <div className="col-md-4">
                <label
                  htmlFor="doctorfees"
                  className="col-form-label leftone"
                >
                  Doctor Fee
                </label>
              </div>
              <div className="col-md-8">
                <input
                  type="text"
                  autoComplete="off"
                  name="doctorfees"
                  id="doctorfees"
                  placeholder="Doctor Fee"
                  value={staffedit.doctorfees}
                  disabled
                  className="form-control "
                />
              </div>
            </div>
          </div>
        )}
         </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>





        </form>
      </div>
    </>
  );
};
export default EditStaff;