import React from "react";

import { NavLink } from "react-router-dom";
// import ReactHtmlTableToExcel from "react-html-table-to-excel";
import NewPatientRegistrationPage from "./PatientRegistrationPages/NewPatientRegistrationPage";
import ListViewPatients from "./PatientRegistrationPages/ListViewPatients";
import EditPatient from "./PatientRegistrationPages/EditPatient";
import Header from "../../Component/Header/Header";
import Sidebar from "../../Component/Sidebar";
import PatientDetailEdit from "./PatientRegistrationPages/PatientDetailEdit";
import { UserProvider } from "../../Provider";
//import Sidebar from "../../Component/Sidebar";
// import Signin from "../Login/Signup/Signin";

export const PatientRegistrationComponent = () => {
  return (

    <UserProvider><div className=" ">
    <Header />
  <div className="row" style={{ marginTop: '50px' }}>
    {/* Sidebar */}
    <div className="col-md-2">
      <Sidebar />
    </div>
    
    {/* Main Content */}
    <div className="col-md-10">
  
    <NewPatientRegistrationPage />
    
    </div>
  </div>
</div></UserProvider>
          
  );
};

// export const PatientListViewComponent = () => {
//   return (
//     <div>
//       <div className="reports">
//         <div className="modal-container ">
//           <div className="title">
//             <div className="title1">
//               <NavLink to="/addpatient">
//                 <button className="allbutton">Add Patient </button>
//               </NavLink>
//             </div>
//           </div>

//           <div className="container2">{/* <ListViewPatients /> */}</div>
//         </div>
//       </div>
//     </div>
//   );
// };

export const PatientEditComponent = () => {
  return (
    <UserProvider> <div className=" ">
    <Header />
  <div className="row" style={{ marginTop: '50px' }}>
    {/* Sidebar */}
    <div className="col-md-2">
      <Sidebar />
    </div>
    
    {/* Main Content */}
    <div className="col-md-10">
  
    <EditPatient />
    
    </div>
  </div>
</div></UserProvider>
            
  );
};

export const PatientEditDetailsComponent = () => {
  return (
    <UserProvider><div className=" ">
    <Header />
  <div className="row" style={{ marginTop: '50px' }}>
    {/* Sidebar */}
    <div className="col-md-2">
      <Sidebar />
    </div>
    
    {/* Main Content */}
    <div className="col-md-10">
  
    <PatientDetailEdit />
    
    </div>
  </div>
</div></UserProvider>
           
  );
};



export const Listview = () => {
  return (
    <UserProvider>  <div className=" ">
      <Header />
      <div
        className="row"
        style={{ marginTop: "50px" }}
      >
        {/* Sidebar */}
        <div className="col-md-2">
          <Sidebar />
        </div>

        {/* Main Content */}
        <div className="col-md-10">
      
        <ListViewPatients />
        
        </div>
      </div>
    </div></UserProvider>
  );
};




