import React, { useState, useEffect } from "react";
import { Table, Button, Modal, Form } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import axios from "../../../helper/axios";
import { IoTrashOutline } from "react-icons/io5";
import { Helmet } from "react-helmet";
import { NavLink, useHistory } from "react-router-dom";
import PrintTemplateBillingView from "../../../Component/PrintTemplateBillingView/PrintTemplateBillingView";
import MUIDataTable from "mui-datatables";
import IconButton from '@mui/material/IconButton';
import PrintIcon from '@mui/icons-material/Print';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined'; 
import DeleteIcon from '@mui/icons-material/Delete';
import { DateRangePicker } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { useUser } from "../../../Provider";
import PathologyReportTemplate from "../../../Component/PrintReport/PathologyReport";
import File from "../../../Component/PrintReport/File";
import PrintRadiologyReport from "../../../Component/RadiologyReportTemplat/PrintRadiologyReport";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
const IpdRadiology = () => {
    const { admitnumber } = useParams();
  const {user, userHospitalcode, userrole1, isAuthRole,isAuthorized,gettokenId,token  } = useUser();
  const history = useHistory();
  const [data, setData] = useState([]);
  const [radiologyreportid, setRadiologyreportid] = useState();
  const [billnumber, setBid] = useState();
  const [isPopup, setIsPopup] = useState(false);
  const currentDate = new Date();
  const day = currentDate.getDate();
  const month = currentDate.getMonth() + 1; // Months are zero-indexed, so add 1
  const year = currentDate.getFullYear();
  const [dateRange, setDateRange] = useState({
    startDate: null,
    endDate: null,
    key: 'selection', // Add the 'key' property
  });
  const handleDelete = async (value, tableMeta,radiologyreportid) => {
    const radiologycategoryname = tableMeta.rowData[0]
    // Display a confirmation dialog with patient's name
    const isConfirmed = window.confirm(`Do you want to delete ${radiologycategoryname}?`);

    if (isConfirmed) {
      try {
        await axios.post(`/deleteradiologyreport/${radiologyreportid}`, null, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        loadReport();
        // getprescriptiondetails();
      } catch (error) {
        // Handle any error here.
        alert("Error cancelling transaction:", error);
      }
    } else {
      // User clicked "Cancel" in the confirmation dialog, do nothing.
    }
  };
  useEffect(() => {
    const loadData = async () => {
     // await loadAllDetails();
      //loadReport(); will wait for loadAllDetails(); to got execute
      await loadReport();
    };

    loadData();
  }, [userrole1,userHospitalcode]);
  // Format the date as DD-MM-YYYY
  const formattedDate = `${day}-${month}-${year}`;
 // const token = sessionStorage.getItem("jwtToken");
  let columns;
 
   columns = [
    {
      name: "billnumber",
      label: "Bill Number",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "patientid",
      label: "Patient Id",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
        name: "patientname",
        label: "Patient Name",
        options: {
          filter: true,
          sort: true,
        },
      },
    {
      name: "createdon",
      label: "Report Date",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "radiologycategoryname", // New column for displaying radiologycategoryname
      label: "Test Name",
      options: {
        filter: true,
        sort: true, // You can't sort this column
       // customBodyRender: This function is typically used to customize the rendering of cell content within the table's body.
      //  customBodyRender: (value) => value.join(", "), // Render radiologycategoryname as a comma-separated list
      },
    },
     {
      name: "",
      label: "",
      options: {
          customBodyRender: (value, tableMeta, updateValue) => {
            const newD = data.find((e) => {
              return (
                e.billno == tableMeta.rowData[0]
                && e.patientname == tableMeta.rowData[2]
                && e.patientid == tableMeta.rowData[1]
                && e.createdon == tableMeta.rowData[3]
                && e.radiologycategoryname == tableMeta.rowData[4]
              );
            });
            if (newD) {
          return (
            <>
              <IconButton color="primary" onClick={() => printForm(value, tableMeta, updateValue)}>
            <VisibilityOutlinedIcon/>
          </IconButton>
            <IconButton color="secondary" onClick={() => handleDelete(value, tableMeta,newD.radiologyreportid)}>
              <DeleteIcon />
            </IconButton>
             
            </>
          );
            }
        },
      },
    },
  ];

  
  const loadReport = async () => {
    try {
      if (userHospitalcode !== undefined && userrole1 !== undefined) {
        console.log(userHospitalcode);
        const res = await axios.get(`/getradiologyreport/${admitnumber}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: {
            hospitalcode: userHospitalcode,
            userrole: userrole1,
          },
        });
  
        console.log(res.data);
  
        if (res.status === 200)
         {
          setData(res.data)
          // const sortedList = [...res.data].sort((a, b) => {
          //   const dateA = new Date(a.appointmentdate);
          //   const dateB = new Date(b.appointmentdate);
          //   return dateB - dateA;
          // });
          
          // setData(preprocessData(sortedList));
          // console.log(preprocessData(sortedList));
        } else {
          alert(`Unexpected status code: ${res.status}`);
        }
      } else {
        console.log("userHospitalcode is undefined");
      }
    } catch (error) {
      alert(`No data found`);
    }
  };
  
 
  //handleClose: This function is used to set the isPopup state to false, presumably to close a popup or modal.
  const handleClose = () => {
    setIsPopup(false)
  }

  const printForm = async (value, tableMeta, updateValue) => {
    const newD = data.find((e) => {
      return e.billnumber == tableMeta.rowData[0]
      && e.patientname == tableMeta.rowData[2]
      && e.patientid == tableMeta.rowData[1]
      && e.createdon == tableMeta.rowData[3]
      && e.radiologycategoryname == tableMeta.rowData[4]
    });
    await setBid(tableMeta.rowData[0]);
    await setRadiologyreportid(newD.radiologyreportid)
    await setIsPopup(true);
  };

  const options = {
    selectableRows: false,
    onRowClick: ((props) => {
      console.log(props)
    }),
    downloadOptions: {
      filename: `Report_${formattedDate}`,// Customize the filename here to download
      filterOptions: {
        useDisplayedColumnsOnly: true,
        useDisplayedRowsOnly: true,
      },
    },
  };
  const currentDomain = window.location.hostname;
  return (
    <>
      <div style={{marginLeft: "auto"}}>
      <Helmet>
        <title>Report Records | {currentDomain} | Hospital Management Software | Smart Solutions for Hospital Efficiency</title>
      </Helmet>
      </div>
      <MUIDataTable
        title={"IPD Radiology Lab Report "}
        data={data}
        columns={columns}
        options={options}
        className="custom-mui-datatable"
      />
        {isPopup==true?
          <>

          <Modal show={true}  animation={false} onHide={handleClose} size="lg">
            <Modal.Header closeButton>
              {/* <Modal.Title>Bill Id:- {billnumber}</Modal.Title> */}
            </Modal.Header>
            <Modal.Body>
            {billnumber==undefined?""
              :
              // <PathologyReportTemplate billnumber={billnumber}  handleClose={handleClose}/>}
                 <PrintRadiologyReport billnumber={billnumber} radiologyreportid={radiologyreportid} handleClose={handleClose}/>}
            </Modal.Body>
          </Modal>
        </> 
        :
        ""}
    </>


  );
};

export default IpdRadiology;