import React from "react";

import { NavLink } from "react-router-dom";
// import ReactHtmlTableToExcel from "react-html-table-to-excel";
import Header from "../../Component/Header/Header";
import Sidebar from "../../Component/Sidebar";
import BillingPage from "./BillingPages/BillingPage";
import ListViewBilling from "./BillingPages/ListViewBilling";
import { UserProvider } from "../../Provider";
import CancelBillList from "./BillingPages/CancelBillList";
import EditBilling from "./BillingPages/EditBilling";
export const BillingPageComponent = () => {
  return (
    <UserProvider>   <div className=" ">
      <Header />
      <div
        className="row"
        style={{ marginTop: "48px" }}
      >
        {/* Sidebar */}
        <div className="col-md-2">
          <Sidebar />
        </div>

        {/* Main Content */}
        <div className="col-md-10">
          {/* to access the UserProvider value in BillingPage component */}
       
        <BillingPage />
       
        </div>
      </div>
    </div></UserProvider>
  );
};

export const BillingListViewComponent = () => {
  return (
    <UserProvider>  <div className=" ">
      <Header />
      <div
        className="row"
        style={{ marginTop: "48px" }}
      >
        {/* Sidebar */}
        <div className="col-md-2">
          <Sidebar />
        </div>

        {/* Main Content */}
        <div className="col-md-10">
       
          <ListViewBilling />
       
        </div>
      </div>
    </div></UserProvider>
  );
};
export const CancelBillListComponent = () => {
  return (
    <UserProvider> <div className=" ">
      <Header />
      <div
        className="row"
        style={{ marginTop: "48px" }}
      >
        {/* Sidebar */}
        <div className="col-md-2">
          <Sidebar />
        </div>

        {/* Main Content */}
        <div className="col-md-10">
       
          <CancelBillList/>
       
        </div>
      </div>
    </div></UserProvider>
  );
};
export const EditBillingComponent = () => {
  return (
    <UserProvider><div className=" ">
      <Header />
      <div
        className="row"
        style={{ marginTop: "48px" }}
      >
        {/* Sidebar */}
        <div className="col-md-2">
          <Sidebar />
        </div>

        {/* Main Content */}
        <div className="col-md-10">
       
          <EditBilling/>
       
        </div>
      </div>
    </div> </UserProvider>
  );
};