import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import axios from "../../../helper/axios";
import { useUser } from "../../../Provider";
import { Helmet } from "react-helmet";
import { NavLink} from "react-router-dom";
import MUIDataTable from "mui-datatables";
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';


const ListViewFloor = () => {
  // Destructuring user details and functions from the context
  const { user, userHospitalcode, userrole1, isAuthRole, isAuthorized, gettokenId, token } = useUser();
  console.log(user, userHospitalcode, userrole1, isAuthRole, isAuthorized, gettokenId);
  // State variable to store floor data
  const [data, setData] = useState([]);

  // Get the current date
  const currentDate = new Date();
  const day = currentDate.getDate();
  const month = currentDate.getMonth() + 1; // Months are zero-indexed, so add 1
  const year = currentDate.getFullYear();
  // Format the date as DD-MM-YYYY
  const formattedDate = `${day}-${month}-${year}`;

  // State variable to store user role
  const [userRole, setRole] = useState();

  // useEffect to load floor details on component mount or when dependencies change
  useEffect(() => {
    const loadData = async () => {
      await loadAllBeds();
    };

    loadData();
  }, [userHospitalcode, userrole1, isAuthorized]);

  // Function to fetch all floor details
  const loadAllBeds = async () => {
    try {
      if (userHospitalcode !== undefined && userrole1 !== undefined) {
        console.log("userHospitalcode:", userHospitalcode);
        console.log("userrole1:", userrole1);
        const res = await axios.get("/GetFloors", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: {
            hospitalcode: userHospitalcode,
            userrole: userrole1,
          },
        });
        setData(res.data);
        console.log(res.data);
      }
    } catch (error) {
      // Handle any error here.
      alert("Error loading floor list:", error);
    }
  };

  // Function to delete a floor
  const cancelFloor = async (floorid) => {
    // Display a confirmation dialog
    const isConfirmed = window.confirm("Do you want to delete this floor?");
  
    if (isConfirmed) {
      try {
        console.log("Floor ID:", floorid);

        await axios.post(`/deletefloor/${floorid}`, null, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        // After successfully deleting the floor, refresh the floor list.
        loadAllBeds();
      } catch (error) {
        // Handle any error here.
        alert("Error deleting floor:", error);
      }
    } else {
      // User clicked "Cancel" in the confirmation dialog, do nothing.
    }
  };

  // Define columns for MUI DataTable based on user authorization
  let columns;
  if (isAuthorized) {
    columns = [
      {
        name: "floorname",
        label: "Floor Name",
        options: {
          filter: true,
          sort: true,
        },
      },
      {
        name: "description",
        label: "Description",
        options: {
          filter: true,
          sort: true,
          setCellHeaderProps: () => ({ style: { minWidth: "600px" } }), // Set the minimum width for the column
        },
      },
      {
        name: "hospitalcode",
        label: "Hospital Code",
        options: {
          filter: true,
          sort: true,
          setCellHeaderProps: () => ({ style: { paddingLeft: '20px' } }), // Set the left padding for the column header
          setCellProps: () => ({ style: { paddingLeft: '20px' } }), // Set the left padding for the column cells
          customBodyRender: (value) => (value !== null && value !== undefined) ? value.toString().padStart(5, '0') : ""
        },
      },
      {
        name: "",
        label: "Action",
        options: {
          customBodyRender: (value, tableMeta, updateValue) => {
            const newD = data.find((e) => {
              return (
                e.floorname === tableMeta.rowData[0] &&
                e.description === tableMeta.rowData[1] &&
                e.hospitalcode === tableMeta.rowData[2]
              );
            });
            console.log('newD:', newD);
            return (
              <>
                 <IconButton color="secondary" onClick={() => cancelFloor(newD.floorid)}>
              <DeleteIcon />
            </IconButton>

                <NavLink to={`/editfloor/${newD.floorid}`}>
                  <IconButton color="primary" >
                <EditIcon />
              </IconButton>
                </NavLink>
              </>
            );
          },
        },
      },
    ];

  } else {
    columns = [
      {
        name: "floorname",
        label: "Floor Name",
        options: {
          filter: true,
          sort: true,
        },
      },
      {
        name: "description",
        label: "Description",
        options: {
          filter: true,
          sort: true,
          setCellHeaderProps: () => ({ style: { minWidth: "600px" } }), // Set the minimum width for the column
        },
      },
      {
        name: "",
        label: "Action",
        options: {
          customBodyRender: (value, tableMeta, updateValue) => {
            const newD = data.find((e) => {
              return (
                e.floorname === tableMeta.rowData[0] &&
                e.description === tableMeta.rowData[1]
              );
            });
            console.log('newD:', newD);
            return (
              <>
              <IconButton color="secondary" onClick={() => cancelFloor(newD.floorid)}>
           <DeleteIcon />
         </IconButton>

             <NavLink to={`/editfloor/${newD.floorid}`}>
               <IconButton color="primary" >
             <EditIcon />
           </IconButton>
             </NavLink>
           </>
            );
          },
        },
      },
    ];
  }

  // Options configuration for MUI DataTable
  const options = {
    selectableRows: false,
    onRowClick: ((props) => {
      console.log(props);
    }),
    downloadOptions: {
      filename: `Patient_list_${formattedDate}`,
      filterOptions: {
        useDisplayedColumnsOnly: true,
        useDisplayedRowsOnly: true,
      },
    },
  };

  // Get the current domain for the page title
  const currentDomain = window.location.hostname;

  return (
    <>
      <Helmet>
        <title>Floor List | {currentDomain} | Hospital Management Software | Smart Solutions for Hospital Efficiency</title>
      </Helmet>
      {/* Navigation link to add floor */}
      <NavLink to='/addfloor'>
        <button className="btn btn-success btn-sm mr-2" style={{ margin: "10px" }}>
          <svg xmlns="http://www.w3.org/2000/svg" width="19" height="18" style={{ paddingRight: '4px' }} viewBox="0 0 100 100">
            <line x1="10" y1="50" x2="90" y2="50" stroke="white" stroke-width="10" />
            <line x1="50" y1="10" x2="50" y2="90" stroke="white" stroke-width="10" />
          </svg>
          Add Floor
        </button>
      </NavLink>
      {/* MUI DataTable for displaying floor list */}
      <MUIDataTable
        title={"Bed List"}
        data={data}
        columns={columns}
        options={options}
        className="custom-mui-datatable"
      />
    </>
  );
};

export default ListViewFloor;