import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import axios from "../../../helper/axios";
import { useUser } from "../../../Provider";
import { Helmet } from "react-helmet";
import { NavLink, useHistory } from "react-router-dom";
import MUIDataTable from "mui-datatables";
import { FaEdit } from "react-icons/fa";
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

const ListViewMedicine = () => {
  // Destructuring values from useUser hook
  const { user, userHospitalcode, userrole1, isAuthRole, isAuthorized, gettokenId, token } = useUser();
  console.log(user, userHospitalcode, userrole1, isAuthRole, isAuthorized, gettokenId);
  const history = useHistory();
  const [data, setData] = useState([]);
  const currentDate = new Date();
  const day = currentDate.getDate();
  const month = currentDate.getMonth() + 1; // Months are zero-indexed, so add 1
  const year = currentDate.getFullYear();
  // Format the date as DD-MM-YYYY
  const formattedDate = `${day}-${month}-${year}`;
  // const token = sessionStorage.getItem("jwtToken");
  const [userRole, setRole] = useState();

  // When the page loads or refreshes, this function will be called
  useEffect(() => {
    const loadData = async () => {
      // await loadAllDetails();
      // loadAllAppointment(); will wait for loadAllDetails(); to be executed
      await loadAllMedicine ();
    };

    loadData();
  }, [userHospitalcode, userrole1, isAuthorized]);

  // Load all operations based on user role and hospital code
  const loadAllMedicine = async () => {
    try {
      if (userHospitalcode !== undefined && userrole1 !== undefined) {
        console.log("userHospitalcode:", userHospitalcode); // Add this line
        console.log("userrole1:", userrole1); // Add this line
        const res = await axios.get("/getmedicines", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: {
            hospitalcode: userHospitalcode, // Include the hospital code in the query parameters
            userrole: userrole1, // Include the user role in the query parameters
          },
        });
        setData(res.data);
        console.log(res.data);
      }
    } catch (error) {
      console.log(error);
    }
  };



  const cancelMedicine = async (medicineid) => {
    // Display a confirmation dialog
    const isConfirmed = window.confirm("Do you want to delete this medicine?");
    
    if (isConfirmed) {
      try {
        console.log("medicineid:", medicineid);
        await axios.post(`/deletemedicine/${medicineid}`, null, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        // After successfully deleting the bed, refresh the bed list.
        loadAllMedicine();
      } catch (error) {
        // Handle any error here.
        alert("Error deleting medicine", error);
      }
    } else {
      // User clicked "Cancel" in the confirmation dialog, do nothing.
    }
  };



  let columns;
  // Define columns for MUIDataTable based on user authorization
  if (isAuthorized) {
    columns = [
      {
        name: "medicinename",
        label: "Medicine Name",
        options: {
          filter: true,
          sort: true,
        },
      },
      {
        name: "medicinecode",
        label: "Medicine code",
        options: {
          filter: true,
          sort: true,
        },
      },
      {
        name: "medicinecategory",
        label: "Category",
        options: {
          filter: true,
          sort: true,
        },
      },
      {
        name: "suppliername",
        label: "Supplier",
        options: {
          filter: true,
          sort: true,
        },
      },
      // {
      //   name: "unit",
      //   label: "Unit",
      //   options: {
      //     filter: true,
      //     sort: true,
      //   },
      // },
      {
        name: "hospitalcode",
        label: "Hospital Code",
        options: {
          filter: true,
          sort: true,
          customBodyRender: (value) => (value !== null && value !== undefined) ? value.toString().padStart(5, '0') : ""
        },
      },

      {
        name: "Action",
        label: "",
        options: {
          customBodyRender: (value, tableMeta, updateValue) => {
            const newD = data.find((e) => {
              return (
                e.medicinename === tableMeta.rowData[0] &&
                e.medicinecode === tableMeta.rowData[1] &&
                e.medicinecategory === tableMeta.rowData[2] &&
                e.suppliername === tableMeta.rowData[3] &&
                e.hospitalcode === tableMeta.rowData[4] 
                
              );
            });
            console.log('newD"""""""""""""""""":', newD);
            return (
              <>
               <IconButton color="secondary" onClick={() => cancelMedicine(newD.medicineid)}>
              <DeleteIcon />
            </IconButton>
                <NavLink to={`/editmedicine/${newD.medicineid}`}>
                    <IconButton color="primary" >
                <EditIcon />
              </IconButton>
                </NavLink>
              </>
            );
          },
        },
      },
    
    
    ];
  } else {
    columns = [
      {
        name: "medicinename",
        label: "Medicine Name",
        options: {
          filter: true,
          sort: true,
        },
      },
      {
        name: "medicinecode",
        label: "Medicine code",
        options: {
          filter: true,
          sort: true,
        },
      },
      {
        name: "medicinecategory",
        label: "Category",
        options: {
          filter: true,
          sort: true,
        },
      },


      {
        name: "suppliername",
        label: "Supplier",
        options: {
          filter: true,
          sort: true,
        },
      },

      // {
      //   name: "unit",
      //   label: "Unit",
      //   options: {
      //     filter: true,
      //     sort: true,
      //   },
      // },
      
      {
        name: "Action",
        label: "",
        options: {
          customBodyRender: (value, tableMeta, updateValue) => {
            const newD = data.find((e) => {
              return (
                e.medicinename === tableMeta.rowData[0] &&
                e.medicinecode === tableMeta.rowData[1] &&
                e.medicinecategory === tableMeta.rowData[2] &&
                e.suppliername === tableMeta.rowData[3]
              );
            });
            console.log('newD:', newD);
            return (
              <>
               <IconButton color="secondary" onClick={() => cancelMedicine(newD.medicineid)}>
              <DeleteIcon />
            </IconButton>
                <NavLink to={`/editmedicine/${newD.medicineid}`}>
                    <IconButton color="primary" >
                <EditIcon />
              </IconButton>
                </NavLink>
              </>
            );
          },
        },
      },
    

    ];
  }

  const options = {
    selectableRows: false,
    onRowClick: ((props) => {
      console.log(props);
    }),
    downloadOptions: {
      filename: `Medicine_list_${formattedDate}`, // Customize the filename here to download
      filterOptions: {
        useDisplayedColumnsOnly: true,
        useDisplayedRowsOnly: true,
      },
    },
  };

  return (
    <>
      <Helmet>
        <title>Aarogyasaarthi | Medicine List</title>
      </Helmet>
      <NavLink to='/addmedicine'>
        <button className="btn btn-success btn-sm mr-2" style={{ margin: "10px" }}>
          <svg xmlns="http://www.w3.org/2000/svg" width="19" height="18" style={{ paddingRight: '4px' }} viewBox="0 0 100 100">
            <line x1="10" y1="50" x2="90" y2="50" stroke="white" stroke-width="10" />
            <line x1="50" y1="10" x2="50" y2="90" stroke="white" stroke-width="10" />
          </svg>Add Medicine
        </button>
      </NavLink>
      <MUIDataTable
        title={"Medicine List"}
        data={data}
        columns={columns}
        options={options}
        className="custom-mui-datatable"
      />
    </>
  );
};

export default ListViewMedicine;
