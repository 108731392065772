import React, { useState, useEffect } from 'react';
import { Modal, Backdrop, Fade, Button, TextField } from '@mui/material';
import { useFormik } from "formik";
import axios from "../../../helper/axios";
import { useUser } from "../../../Provider";
import { useParams } from 'react-router-dom/cjs/react-router-dom';
import { ToastContainer, toast } from 'react-toastify'
import {paymentvalidation } from "../../../helper/schemas";

const initialValues = {
    paymentdate:"",
    paymentmode:"",
    paymentnote:"",
    paymentamount:""  
};
const UpdateIpdpayment = ({paymentData,onClose }) => {
    const handlePayment = () => {
        setFormVisibility(!isFormVisible); // Toggle form visibility
    };
    const { admitnumber } = useParams();
    //console.log({ admitnumber })
    const { user, userHospitalcode, userrole1, isAuthRole, isAuthorized, gettokenId,token } = useUser();
   // console.log(user, userHospitalcode, userrole1, isAuthRole, isAuthorized, gettokenId)
    const [isFormVisible, setFormVisibility] = useState(false);
    // Convert the date format from the database

    //const token = sessionStorage.getItem("jwtToken");
    const {
        values,
        errors,
        touched,
        handleBlur,
        handleChange,
        handleReset,
        handleSubmit,
        resetForm,
        setValues,
      } = useFormik({
        initialValues,
       //validationSchema:paymentvalidation ,
        onSubmit: async (values, action) => {
            try {
      
            //  if (userHospitalcode !== undefined) {
                const data = new FormData();
                data.append("document", JSON.stringify(values));
               console.log(JSON.stringify(values))
                const response = await axios.post(`/updatepayment/${admitnumber}/${paymentData}`, data, {
                  headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "multipart/form-data",
                  },
                });
                if (response.status === 200) {
                  
                 toast.success("Data Saved")
                 
                }
              //}
            } catch (err) {
              
            }
            action.resetForm();
          },
      
        });
        const getIpdOpeartiondetails = async () => {
            try {
              if (userHospitalcode !== undefined && userrole1 !== undefined) {
                const res = await axios.get(`/getipdtransaction/${admitnumber}/${paymentData}`, {
                  headers: {
                    Authorization: `Bearer ${token}`,
                  },
                  params: {
                    userHospitalcode:userHospitalcode ,
                    userrole: userrole1,
                  },
                });
        
                if (res.status === 200) {
                  setValues(res.data[0]);
                  console.log(res.data[0])
                } else {
                  alert(`Request failed with status: ${res.status}`);
                }
              }
            } catch (error) {
              console.log(`An error occurred: ${error.message}`);
            }
          };
          useEffect(() => {
            const loadData = async () => {
              await getIpdOpeartiondetails();
            };
        
            loadData();
        
          }, [userHospitalcode, userrole1, isAuthorized]);
          //formatte the date
  

          // const formattedDate = values.paymentdate ? new Date(values.paymentdate).toISOString().slice(0, 16) : '';
  return (
    <Modal
      open={true} // Set to true to open the modal
      onClose={onClose}
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      closeAfterTransition
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={true}>
        <div style={{ backgroundColor: 'white', padding: '20px', borderRadius: '8px', maxWidth: '65%', margin: 'auto', marginTop: '100px' }}>
          <h2>Update Transaction</h2>
          <form onSubmit={handleSubmit} style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                    <div className="patient-new primary-info">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="col-md-12 mt-3">
                                            <div className="row">
                                                <div className="col-md-4">
                                                    <label
                                                        htmlFor="paymentdate"
                                                        className="col-form-label leftone"
                                                    >
                                                        Date
                                                    </label>
                                                </div>
                                                <div className="col-md-8">
                                                    <input
                                                       type="datetime-local"
                                                       autoComplete="off"
                                                        name="paymentdate"
                                                        id="paymentdate"
                                                        placeholder="paymentdate"
                                                        value={values.paymentdate ? new Date(values.paymentdate).toISOString().slice(0, 16) : ''}
                                                          onChange={handleChange}
                                                          className="form-control ember-text-field text-left ember-view"
                                                    />
                                                      {errors.paymentdate && touched.paymentdate ? (
                    <p className="form-erroremployee">{errors.paymentdate}</p>
                  ) : null}
                                                </div>
                                            </div>
                                        </div>

                                        
                 <div className="col-md-12 mt-3">
                  <div className="row">
                    <div className="col-md-4">
                      <label
                        htmlFor="paymentmode"
                        className="col-form-label"
                      >
                        Payment Mode
                      </label>
                    </div>
                    <div className="col-md-8">
                      <select
                        type="text"
                        autoComplete="off"
                        name="paymentmode"
                        id="paymentmode"
                        placeholder="Payment Mode"
                        value={values.paymentmode}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        style={{ width: '73%', height: '32px', border: '1px solid #ced4da ', color: '#495057' }}
                      >
                        <option>Select</option>
                        <option>Cash</option>
                        <option>Cheque</option>
                        <option>Transfer to Bank Account</option>
                        <option>UPI</option>
                        <option>Online</option>
                        <option>Other</option>
                      </select>
                      
                    </div>
                  </div>
                </div>
          </div>
                                    <div className="col-md-6">

                                    <div className="col-md-12 mt-3">
                                            <div className="row">
                                                <div className="col-md-4">
                                                    <label
                                                        htmlFor="paymentamount"
                                                        className="col-form-label leftone"
                                                    >
                                                      Amount($)
                                                    </label>
                                                </div>
                                                <div className="col-md-8">
                                                    <input
                                                        type="number"
                                                        autoComplete="off"
                                                        name="paymentamount"
                                                        id="paymentamount"
                                                        placeholder="paymentamount"
                                                        value={values.paymentamount}
                                                          onChange={handleChange}
                                                          onBlur={handleBlur}

                                                        className="form-control"
                                                    />
                                                    {errors.paymentamount && touched.paymentamount ? (
                    <p className="form-erroremployee">{errors.paymentamount}</p>
                  ) : null}
                                                </div>
                                            </div>
                                        </div>         
                                        <div className="col-md-12 mt-3">
                                            <div className="row">
                                                <div className="col-md-4">
                                                    <label
                                                        htmlFor="paymentnote"
                                                        className="col-form-label "
                                                    >
                                                       Note
                                                    </label>
                                                </div>
                                                <div className="col-md-8">
                                                    <input
                                                        type="text"
                                                        autoComplete="off"
                                                        name="paymentnote"
                                                        id="paymentnote"
                                                        placeholder="paymentnote"
                                                        value={values.paymentnote}
                                                          onChange={handleChange}
                                                          onBlur={handleBlur}

                                                        className="form-control"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-11 mt-6 text-center">
                    <Button  type="submit" variant="contained" color="primary" size="small" style={{ marginRight: '10px' }}>
              Submit
            </Button>
            <Button variant="contained" size="small" onClick={onClose}>
                Cancel
              </Button>
          </div>
                </form>
        </div>
      </Fade>
    </Modal>
  );
};

export default UpdateIpdpayment;
