import React, { useState, useEffect } from 'react'
import axios from '../../helper/axios';
import { useHistory } from 'react-router-dom';
import { useUser } from '../../Provider';
import { Helmet } from 'react-helmet';
export const Mainpage = () => {
  const {user, userHospitalcode, userrole1, isAuthRole,isAuthorized,gettokenId,token  } = useUser();
  console.log(user, userHospitalcode, userrole1, isAuthRole,isAuthorized,gettokenId )
  //const token = sessionStorage.getItem("jwtToken");
  const history = useHistory();
  const[data, setData] = useState({
    totalOPDs: 0,
    totalOPDCollection : 0,
    totalPatientsAdmitted : 0,
    totalCollection:0.00,
    totalIPDBill : 0.00,
    totalIPDCollection : 0.00,
    totalIPDRefund : 0,
    totalDiagnosticCollection:0.00,
    totalCoefficient : 0,
    totalRefund : 0,
    totalNoOfBilling : 0,
    totalOPDProcedure : 0,
    totalOPDProcedureCollection : 0,
    totalBilling:0
  })
  const loadOPD = async () => {
    try {
      if (userHospitalcode !== undefined && userrole1 !== undefined) {
      const res = await axios.get("/GetDashData", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
          params: {
            hospitalcode: userHospitalcode, // Include the hospitalcode in the query parameters
            userrole: userrole1, // Include the userrole in the query parameters
          },
      });
      console.log(res.data);
    setData({
      ...data,
      totalOPDs: res.data.totalOPDcount[0].totalOPDs,
      totalOPDCollection : res.data.totalOPDcollection[0].totalOPDcollection,
      totalPatients : res.data.totalPatients[0].totalPatients,
      presentPatients:res.data.presentPatients[0].presentPatients,
      totalCollection:res.data.totalCollection,
      totalDiagnosticCollection:res.data.totalDiagnosticCollection,
      totalBilling:res.data.totalBilling[0].totalBilling,
      OPDcollectiontotal:res.data.OPDcollectiontotal[0].OPDcollectiontotal,
      totalPatientsAdmitted : 0
    });
    console.log(res.data[0]);
  }else {
    console.log("userHospitalcode is undefined");
  }
    } catch (error) {
      console.error("AxiosError:", error);
    }
  };
  //when page load or refresh happen this function will got called
  useEffect(() => {
    const loadData = async () => {
     // await loadAllDetails();
      //loadAllAppointment(); will wait for loadAllDetails(); to got execute
      await loadOPD();
    };

    loadData();
    // Set up a scheduler to refresh the data every 30 seconds 
  const refreshDataInterval = 30000; // 30 seconds in milliseconds

  const refreshDataTimeout = setInterval(loadData, refreshDataInterval);

  // Clear the timeout when the component unmounts
  return () => {
    clearInterval(refreshDataTimeout);
  };
  }, [userrole1,userHospitalcode]);
//   useEffect(() => {
    
// // Set up a scheduler to refresh the entire page every X milliseconds (e.g., every 30 seconds)
// const refreshPageInterval = 30000; // 30 seconds in milliseconds

// const refreshPage = () => {
//   window.location.reload(); // Reload the entire page
// };
// const pageRefreshTimeout = setTimeout(refreshPage, refreshPageInterval);
// // Clear the timeout when the component unmounts
// return () => {
//   clearTimeout(pageRefreshTimeout);
// };
//   }, []); 

// Get the current domain
const currentDomain = window.location.hostname;
console.log(currentDomain)
  return (
    <>
     <Helmet>
        <title>Data Visualization | {currentDomain} | Hospital Management Software | Smart Solutions for Hospital Efficiency </title>
      </Helmet>
        <div className="grid-tablet">
          <div className="grid-tablets tab-1">
            <span id="num-head">{data.totalOPDs}</span>
            <span id="text-subhead">Today's OPD</span>
          </div>
          {!isAuthorized ? (
        <div className="grid-tablets tab-2">
          <span id="num-head">₹{data.totalOPDCollection}</span>
          <span id="text-subhead">Today's OPD Collection</span>
        </div>
      ) : null}

          {/* <div className="grid-tablets tab-2">
            <span id="num-head">₹{data.totalOPDCollection}</span>
            <span id="text-subhead">Today's OPD Collection</span>
          </div> */}
           {!isAuthorized ? (
          <div className="grid-tablets tab-3">
            <span id="num-head">₹{data.totalDiagnosticCollection}</span>
            <span id="text-subhead">Today's Diagnostic Collection</span>
          </div>
       ) : null}
        {!isAuthorized ? (
          <div className="grid-tablets tab-4">
            <span id="num-head">₹{data.totalCollection}</span>
            <span id="text-subhead">Today's Total Collection</span>
          </div>
      ) : null}
          <div className="grid-tablets tab-5">
            <span id="num-head">{data.totalPatients}</span>
            <span id="text-subhead">Today's Patient </span>
          </div>

          <div className="grid-tablets tab-1">
            <span id="num-head">{data.presentPatients}</span>
            <span id="text-subhead">Total Patient</span>
          </div>
          {!isAuthorized ? (
          <div className="grid-tablets tab-2">
            <span id="num-head">₹{parseFloat(data.totalBilling).toFixed(2)}</span>
            <span id="text-subhead">Total Diagnostic Collection</span>
          </div>
       ) : null}
        {!isAuthorized ? (
          <div className="grid-tablets tab-3">
            <span id="num-head">₹{data.OPDcollectiontotal}</span>                              {/* today Admitted People */}
            <span id="text-subhead">Total OPD Collection</span>
          </div>
          ) : null}
           {!isAuthorized ? (
          <div className="grid-tablets tab-4">
            <span id="num-head">₹{parseFloat(0.00).toFixed(2)}</span>
            <span id="text-subhead">Total IPD Collection</span>
          </div>
         ) : null}
         {!isAuthorized ? (
          <div className="grid-tablets tab-5">
            <span id="num-head">₹ 0.00</span>
            <span id="text-subhead">Total Refund</span>
          </div>
        ) : null}
         {!isAuthorized ? (
          <div className="grid-tablets tab-1">
            <span id="num-head">0</span>
            <span id="text-subhead">Total No Billing</span>
          </div>
        ) : null}
          <div className="grid-tablets tab-4">
            <span id="num-head">0</span>
            <span id="text-subhead">Total OPD Procedure</span>
          </div>
          {!isAuthorized ? (
          <div className="grid-tablets tab-5">
            <span id="num-head">0</span>
            <span id="text-subhead">Total OPD Procedure Collection</span>
          </div>
          ) : null}
        </div>
    </>
  )
}