
import React, { useState, useEffect } from "react";
import { Table, Button, Modal, Form } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import axios from "../../../helper/axios";
import { IoTrashOutline } from "react-icons/io5";
import { Helmet } from "react-helmet";
import { NavLink, useHistory } from "react-router-dom";
import PrintTemplateBillingView from "../../../Component/PrintTemplateBillingView/PrintTemplateBillingView";
import MUIDataTable from "mui-datatables";
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined'; 
import { DateRangePicker } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { useUser } from "../../../Provider";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import File from "../../../Component/PrintReport/File";
const LabInvestigationTable = () => {
  const { admitnumber } = useParams();
  const {user, userHospitalcode, userrole1, isAuthRole,isAuthorized,gettokenId,token  } = useUser();
  const history = useHistory();
  const [data, setData] = useState([]);
  const [patientId, setPid] = useState();
  const [billnumber, setBid] = useState();
  const [isPopup, setIsPopup] = useState(false);
  const currentDate = new Date();
  const day = currentDate.getDate();
  const month = currentDate.getMonth() + 1; // Months are zero-indexed, so add 1
  const year = currentDate.getFullYear();
  const [dateRange, setDateRange] = useState({
    startDate: null,
    endDate: null,
    key: 'selection', // Add the 'key' property
  });
  const formatDate = (date) => {
    if (!date) return ''; // Handle the case when date is null or undefined

    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-indexed, so add 1
    const day = date.getDate().toString().padStart(2, '0');

    return `${year}-${month}-${day}`;
  };
  const [userRole, setRole] = useState();
  // const [gettokenId, setTokenId] = useState();
  // const [userHospitalcode, setUserHospitalcode] = useState();
  // const [userrole1, setUserrole1] = useState();
  // const [isAuthorized, setIsAuthorized] = useState(false);
    // Function to get login user's details
  
  useEffect(() => {
    const loadData = async () => {
     // await loadAllDetails();
      //loadAllPatients(); will wait for loadAllDetails(); to got execute
      await loadAllPatients();
    };

    loadData();
  }, [userrole1,userHospitalcode]);
  // Format the date as DD-MM-YYYY
  const formattedDate = `${day}-${month}-${year}`;
 // const token = sessionStorage.getItem("jwtToken");
  let columns;
  if (isAuthorized) {
   columns = [
    {
      name: "billnumber",
      label: "Bill Number",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "patientid",
      label: "Patient Id",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "patientname",
      label: "Patient Name",
      options: {
        filter: true,
        sort: true,
      },
    },
    // {
    //   name: "subtotal",
    //   label: "Bill Amount",
    //   options: {
    //     filter: true,
    //     sort: true,
    //   },
    // },
    {
      name: "createdon",
      label: "Billing Date",
      options: {
        filter: true,
        sort: true,
      },
    },
   {
      name: "testcategory", // New column for displaying testcategory
      label: "Test Name",
      options: {
        filter: true,
        sort: true, // You can't sort this column
       // customBodyRender: This function is typically used to customize the rendering of cell content within the table's body.
        customBodyRender: (value) => value.join(", "), // Render testcategory as a comma-separated list
      },
    },
    {
      name: "",
      label: "",
      options: {
          customBodyRender: (value, tableMeta, updateValue) => {
            const newD = data.find((e) => {
              return (
                e.patientid == tableMeta.rowData[1]
                && e.patientname == tableMeta.rowData[2]
                && e.billnumber == tableMeta.rowData[0]
              //  && e.subtotal == tableMeta.rowData[3]
                && e.createdon == tableMeta.rowData[3]
              );
            });
            if (newD) {
          return (
            <>
              <IconButton color="primary" onClick={() => printForm(value, tableMeta, updateValue)}>
            <VisibilityOutlinedIcon/>
          </IconButton>
            <IconButton color="secondary" onClick={() => cancelBilling(newD.billnumber)}>
              <DeleteIcon />
            </IconButton>
              {/* <button
                className="btn btn-danger btn-sm"
                onClick={() => cancelBilling(newD.billnumber)}
              >
                Cancel
              </button> */}
            </>
          );
            }
        },
      },
    },
  ];
}
else{
  columns = [
    {
      name: "billnumber",
      label: "Bill Number",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "patientid",
      label: "Patient Id",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "patientname",
      label: "Patient Name",
      options: {
        filter: true,
        sort: true,
      },
    },
    // {
    //   name: "billnumber",
    //   label: "Bill Number",
    //   options: {
    //     filter: true,
    //     sort: true,
    //   },
    // },
    // {
    //   name: "subtotal",
    //   label: "Bill Amount",
    //   options: {
    //     filter: true,
    //     sort: true,
    //   },
    // },
    {
      name: "createdon",
      label: "Billing Date",
      options: {
        filter: true,
        sort: true,
      },
    },
   {
      name: "testcategory", // New column for displaying testcategory
      label: "Test Name",
      options: {
        filter: true,
        sort: true, // You can't sort this column
       // customBodyRender: This function is typically used to customize the rendering of cell content within the table's body.
        customBodyRender: (value) => value.join(", "), // Render testcategory as a comma-separated list
      },
    },
    {
      name: "",
      label: "",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          const newD = data.find((e) => {
            return (
              e.patientid == tableMeta.rowData[1]
              && e.patientname == tableMeta.rowData[2]
              && e.billnumber == tableMeta.rowData[0]
            //  && e.subtotal == tableMeta.rowData[3]
              && e.createdon == tableMeta.rowData[3]
            );
          });
          return (
            <>
             <IconButton color="primary" onClick={() => printForm(value, tableMeta, updateValue)}>
            <VisibilityOutlinedIcon/>
          </IconButton>
             <IconButton color="secondary" onClick={() => cancelBilling(newD.billnumber)}>
              <DeleteIcon />
            </IconButton>
              {/* <button
                className="btn btn-danger btn-sm"
                onClick={() => cancelBilling(newD.billnumber)}
              >
                Cancel
              </button> */}
            </>
          );
        },
      },
    },
  ];
}
  // preprocessData: This function takes originalData as input, which seems to be an array of objects with properties like billnumber,
  // chargecode, etc. It processes this data to group rows with the same billnumber together and collect their chargecode values into an array. 
  // The result is an array of objects with unique billnumber values and their associated chargecodes.
  const preprocessData = (originalData) => {
    const processedData = {};
  
    originalData.forEach((row) => {
      const billnumber = row.billnumber;
  
      if (!processedData[billnumber]) {
        processedData[billnumber] = {
          billnumber: billnumber,
          patientid: row.patientid,
          patientname: row.patientname,
          createdon: row.createdon,
          testcategory: new Set([row.categoryname]), // Use a Set to store unique categorynames
          hospitalcode: row.hospitalcode,
        };
      } else {
        processedData[billnumber].testcategory.add(row.categoryname); // Add categoryname to the Set
      }
    });
  
    // Convert the Set values to an array
    Object.values(processedData).forEach((data) => {
      data.testcategory = Array.from(data.testcategory);
    });
  
    return Object.values(processedData);
  };

  
  const cancelBilling = async (billnumber) => {
    // Display a confirmation dialog
    const isConfirmed = window.confirm("Do you want to cancel this bill?");
  
    if (isConfirmed) {
      try {
        await axios.post(`/cancelbill/${billnumber}`, null, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        
        //alert("Bill has been canceled.");
        
        // After successfully canceling the bill, refresh the bill list.
        loadAllPatients();
      } catch (error) {
        // Handle any error here.
       alert("Error cancelling bill:", error);
      }
    } else {
      // User clicked "Cancel" in the confirmation dialog, do nothing.
    }
  };
  // loadAllPatients: This is an asynchronous function that makes a GET request to a backend API endpoint ("/GetPatientsBilling") to retrieve patient billing data. 
  // It sorts the data based on the appointmentdate property in descending order and then processes the sorted data using the preprocessData function.
  //  The processed data is stored in the data state variable.

  const loadAllPatients = async () => {
    try {
     if (userHospitalcode !== undefined && userrole1 !== undefined) {
        console.log(userHospitalcode);
      const res = await axios.get(`/ipdreportdetails/${admitnumber}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          hospitalcode: userHospitalcode, // Include the hospitalcode in the query parameters
          userrole: userrole1, // Include the userrole in the query parameters
        },
      });
  console.log(res.data)
      if (res.status === 200) {
        const sortedList = [...res.data].sort((a, b) => {
          const dateA = new Date(a.appointmentdate);
          const dateB = new Date(b.appointmentdate);
          return dateB - dateA;
        });
        setData(preprocessData(sortedList));
        console.log(preprocessData(sortedList))
      } else {
        // Handle other status codes if needed
        alert(`Lab Investigation Data not found`);
      }
    }else {
      console.log("userHospitalcode is undefined");
    }
    } catch (error) {
      // Handle the error here
     alert(` Lab Investigation Data not found`);
  
      // You can display an error message to the user or take other actions as needed.
    }
  };
 
  //handleClose: This function is used to set the isPopup state to false, presumably to close a popup or modal.
  const handleClose = () => {
    setIsPopup(false)
  }

  const printForm = async (value, tableMeta, updateValue) => {
    const newD = data.find((e) => {
      return e.patientid == tableMeta.rowData[1]
        && e.patientname == tableMeta.rowData[2]
        && e.billnumber == tableMeta.rowData[0]
        //&& e.subtotal == tableMeta.rowData[3]
        && e.createdon == tableMeta.rowData[3]
    });

    await setBid(newD.billnumber);
    await setPid(tableMeta.rowData[1]);
    await setIsPopup(true);
  };

  const options = {
    selectableRows: false,
    onRowClick: ((props) => {
      console.log(props)
    }),
    downloadOptions: {
      filename: `Billing_${formattedDate}`,// Customize the filename here to download
      filterOptions: {
        useDisplayedColumnsOnly: true,
        useDisplayedRowsOnly: true,
      },
    },
  };
  const currentDomain = window.location.hostname;
  return (
    <>
      <div style={{marginLeft: "auto"}}>
      <Helmet>
        <title>lab Report Records | {currentDomain} | Hospital Management Software | Smart Solutions for Hospital Efficiency</title>
      </Helmet>
      </div>
      <MUIDataTable
        title={"IPD Pathology Lab Report"}
        data={data}
        columns={columns}
        options={options}
        className="custom-mui-datatable"
      />


        {isPopup==true?
          <>

          <Modal show={true}  animation={false} onHide={handleClose} size="lg">
            <Modal.Header closeButton>
              {/* <Modal.Title>Bill Id:- {billnumber}</Modal.Title> */}
            </Modal.Header>
            <Modal.Body>
            {billnumber==undefined?""
              :
              <File billnumber={billnumber}  handleClose={handleClose}/>}
              {/* <PrintTemplateBillingView bId={billnumber} patientId={patientId} handleClose={handleClose}/> */}
            </Modal.Body>
          </Modal>
        </> 
        :
        ""}
    </>


  );
};

export default LabInvestigationTable;