import React from "react";
import { useFormik } from "formik";
import { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useHistory } from "react-router-dom";
import axios from "../../../helper/axios";
import { Table, Button, Modal, Form } from "react-bootstrap";
import { patientregistration } from "../../../helper/schemas";
import { ToastContainer, toast } from 'react-toastify';
import * as Yup from "yup";  // Import Yup for validation
import { useUser } from "../../../Provider";
import HospitalCodeSearch from "../../../Component/HospitalCodeSearch";

const ValidationSchema = Yup.object().shape({
  radiologyunit: Yup.string().required('Enter the radiologyunit'),
  hospitalcode: Yup.string().required("Please select the hospitalcode"),
 });
 const ValidationSchema1 = Yup.object().shape({
   radiologyunit: Yup.string().required('Enter the radiologyunit'),
 });
const initialValues = {
    radiologyunit: "",
    hospitalcode: "",
  };
const AddRadiologyUnit = () => {
    const { user, userHospitalcode, userrole1, isAuthRole, isAuthorized, gettokenId, token } = useUser();
    const history = useHistory();
    const [message, setMessage] = useState("");
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);
    //get current date
    let Showdate = new Date();
    let ShowTodaydate =
      Showdate.getFullYear() +
      "-" +
      (Showdate.getMonth() + 1) +
      "-" +
      Showdate.getDate();
    const [currentDate, setCurrentDate] = useState(ShowTodaydate);
  
      const getValidationSchema = () => {
        if (userHospitalcode === 1) {
          return ValidationSchema;
  
        } else  {
          return ValidationSchema1;
        }
      }
  
    const {
      values,
      errors,
      touched,
      handleBlur,
      handleChange,
      handleSubmit,
      resetForm,
      setValues
    } = useFormik({
      initialValues,
      validationSchema: getValidationSchema, // Apply validation schema
      onSubmit: async (values, action) => {
        try {
          const data = new FormData();
          data.append("document", JSON.stringify(values));
          data.append("currentDate", JSON.stringify(currentDate));
          data.append("gettokenId", JSON.stringify(gettokenId));
          data.append("userHospitalcode", JSON.stringify(userHospitalcode));
          console.log(JSON.stringify(values));
          //   console.log(JSON.stringify(hospitalcode));
  
          const response = await axios.post("/addradiologyunit", data, {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "multipart/form-data",
            },
          });
          console.log(
            "🚀 ~ file: Registration.jsx ~ line 11 ~ Registration ~ values",
            values
          );
          if (response.status === 200) {
            return history.push("/listrediologyunit");
          }
        } catch (error) {
          console.log(error)
          toast("Something Went Wrong")
        }
      },
    });
    const handleReset = () => {
      // Reset the form using Formik's resetForm function
      resetForm();
    };
    const [data, setData] = useState([]);
    const [userRole, setRole] = useState();
    const [searchValues, setSearchValues] = useState("");
  //Hospital code search
  const onSearchItems = (searchTerm) => {
    console.log("------", searchTerm)
    setSearchValues(searchTerm);
    if (searchTerm && searchTerm.hospitalcode) {
      setValues({
        ...values,
        hospitalcode: searchTerm.hospitalcode,
        // Update other fields as needed
      });
    }
  };
  
    //when page load or refresh happen this function will got called
    useEffect(() => {
      const loadData = async () => {
        await loadHospitalcode();
      };
      loadData();
    }, [userHospitalcode, userrole1, isAuthorized]);
    //  get all hospital 
    const loadHospitalcode = async () => {
      //when userHospitalcode and userrole1 is not undefined then execute /gethospitalcode
      if (userHospitalcode !== undefined && userrole1 !== undefined) {
        const res = await axios.get("/gethospitalcode", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: {
            hospitalcode: userHospitalcode, // Include the hospitalcode in the query parameters
            userrole: userrole1, // Include the userrole in the query parameters
          },
        });
        setData(res.data);
        console.log(res.data);
      };
    }
    const currentDomain = window.location.hostname;
    return (
      <div className="p-3 pt-0 appointment">
        <Helmet>
          <title>Aarogyasaarthi  | Add Radiology |{currentDomain}</title>
        </Helmet>
        <form onSubmit={handleSubmit}>
          {success && <div>Add Radiology Unit</div>}
          {error && <div>Error in add bed type please try again</div>}
          <div class="fill header"><h3 class="float-left"> Add Radiology Unit</h3> </div>
          <div className="patient-new primary-info">
            <div className="row">
              <div className="col-md-12">
                <div className="row">
                  <div className="col-md-6">
  
                    <div className="col-md-12 mt-3">
                      <div className="row">
  
                        <div className="col-md-4">
                          <label
                            htmlFor="radiologyunit"
                            className="col-form-label leftone"
                          >
                            Radiology Unit
                          </label>
                        </div>
                        <div className="col-md-8">
                          <input
                            type="text"
                            autoComplete="off"
                            name="radiologyunit"
                            id="radiologyunit"
                            placeholder="Radiology Unit"
                            value={values.radiologyunit}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            style={{ width: '73%', height: '32px', border: '1px solid #ced4da', color: '#495057' }}
                          />
                          {errors.radiologyunit && touched.radiologyunit ? (
                            <p className="form-erroremployee">{errors.radiologyunit}</p>
                          ) : null}
                        </div></div></div>
  
                  </div>
                  <div className="col-md-6">
                  {isAuthorized ? (<div className="col-md-12 mt-3">
              <div className="row">
  
                <div className="col-md-4">
                  <label
                    htmlFor="hospitalcode"
                    className="col-form-label leftone"
                  >
                    Hospital Code
                  </label>
                </div>
                <div className="col-md-8">
                  {/* Conditionally render the dropdown and input field */}
                  {isAuthorized ? (
                      
                  <HospitalCodeSearch  onSearchItem={onSearchItems} />
                       
                    // <select
                    //   type="text"
                    //   autoComplete="off"
                    //   name="hospitalcode"
                    //   id="hospitalcode"
                    //   placeholder="Hospital Code"
                    //   value={values.hospitalcode}
                    //   onChange={handleChange}
                    //   onBlur={handleBlur}
                    //   style={{ width: '73%', height: '32px', border: '1px solid #ced4da', color: '#495057' }}
                    // >
                    //   <option>Select</option>
                    //   {data.map((code) => (
                    //     <option value={code.hospitalcode} key={code.id}>
                    //       {code.hospitalcode}
                    //     </option>
                    //   ))}
                    // </select>
                  ) : (
                    <input
                      type="text"
                      name="hospitalcode"
                      id="hospitalcode"
                      value={userHospitalcode}
                      disabled={true} // Set the input as disabled
                      className="form-control "
                    />
                  )}
                  {isAuthorized && errors.hospitalcode && touched.hospitalcode ? (
                    <p className="form-erroremployee">{errors.hospitalcode}</p>
                  ) : null}
                </div></div></div>) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6  mt-3">
            <Button
              type="submit"
              variant="success"
              className="mx-3"
            >
              Save
            </Button>
            <Button
              variant="primary"
              className="mx-3"
              onClick={handleReset}
            >
              Reset
            </Button>
          </div>
        </form>
      </div>
    );
  };

export default AddRadiologyUnit