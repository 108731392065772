import React from "react";
import { NavLink } from "react-router-dom";
import Header from "../../Component/Header/Header";
import Sidebar from "../../Component/Sidebar";
import { UserProvider } from "../../Provider";
import ListViewRadiologyCategory from "./RadiologyPages/ListViewRadiologyCategory";
import AddRadiologyCategory from "./RadiologyPages/AddRadiologyCategory";
import AddRadiologyUnit from "./RadiologyPages/AddRadiologyUnit";
import ListviewRadiologyUnit from "./RadiologyPages/ListviewRadiologyUnit";
import AddRadiologyParameter from "./RadiologyPages/AddRadiologyParameter";
import ListviewRadiologyParameter from "./RadiologyPages/ListviewRadiologyParameter";
import AddRadiologyReport from "./RadiologyPages/AddRadiologyReport";
import PrintRadiologyReport from "../../Component/RadiologyReportTemplat/PrintRadiologyReport";
import ListviewRadiologyReport from "./RadiologyPages/ListviewRadiologyReport";
import EditRadiology from "./RadiologyPages/EditRadiology";
import EditRadiologyCategory from "./RadiologyPages/EditRadiologyCategory";
import UpdateRadiologyParameters from "./RadiologyPages/UpdateRadiologyParameters";



export const RadiologyChategory = () => {
  return (

    <UserProvider>  <div className=" ">
    <Header />
  <div className="row" style={{ marginTop: '50px' }}>
    {/* Sidebar */}
    <div className="col-md-2">
      <Sidebar />
    </div>
    
    {/* Main Content */}
    <div className="col-md-10">
   <AddRadiologyCategory/>
    </div>
  </div>
</div></UserProvider>
          
  );
};

export const ListViewRadiologyChategories = () => {
  return (
    <UserProvider> <div className=" ">
      <Header />
      <div
        className="row"
        style={{ marginTop: "50px" }}
      >
        {/* Sidebar */}
        <div className="col-md-2">
          <Sidebar />
        </div>

        {/* Main Content */}
        <div className="col-md-10">
        <ListViewRadiologyCategory/>
        </div>
      </div>
    </div></UserProvider>
  );
};

export const AddRadiologyUnits = () => {
  return (

    <UserProvider>  <div className=" ">
    <Header />
  <div className="row" style={{ marginTop: '50px' }}>
    {/* Sidebar */}
    <div className="col-md-2">
      <Sidebar />
    </div>
    
    {/* Main Content */}
    <div className="col-md-10">
    <AddRadiologyUnit/>
    </div>
  </div>
</div></UserProvider>
          
  );
};
export const ListViewAddRadiologyUnits = () => {
  return (
    <UserProvider> <div className=" ">
      <Header />
      <div
        className="row"
        style={{ marginTop: "50px" }}
      >
        {/* Sidebar */}
        <div className="col-md-2">
          <Sidebar />
        </div>

        {/* Main Content */}
        <div className="col-md-10">
        <ListviewRadiologyUnit/>
        </div>
      </div>
    </div> </UserProvider>
  );
};

export const AddRadiologyComponent = () => {
  return (

    <UserProvider>  <div className=" ">
    <Header />
  <div className="row" style={{ marginTop: '50px' }}>
    {/* Sidebar */}
    <div className="col-md-2">
      <Sidebar />
    </div>
    
    {/* Main Content */}
    <div className="col-md-10">
    <AddRadiologyParameter/>
    </div>
  </div>
</div></UserProvider>
          
  );
};

export const RadiologyReportComponent = () => {
  return (

    <UserProvider>  <div className=" ">
    <Header />
  <div className="row" style={{ marginTop: '50px' }}>
    {/* Sidebar */}
    <div className="col-md-2">
      <Sidebar />
    </div>
    
    {/* Main Content */}
    <div className="col-md-10">
    <AddRadiologyReport/>
    </div>
  </div>
</div></UserProvider>
          
  );
};
export const ListRadiologyReportComponent = () => {
  return (

    <UserProvider>  <div className=" ">
    <Header />
  <div className="row" style={{ marginTop: '50px' }}>
    {/* Sidebar */}
    <div className="col-md-2">
      <Sidebar />
    </div>
    
    {/* Main Content */}
    <div className="col-md-10">
      <ListviewRadiologyReport/>
    </div>
  </div>
</div></UserProvider>
          
  );
};

export const ListViewRadiologyComponent = () => {
  return (
    <UserProvider> <div className=" ">
      <Header />
      <div
        className="row"
        style={{ marginTop: "50px" }}
      >
        {/* Sidebar */}
        <div className="col-md-2">
          <Sidebar />
        </div>

        {/* Main Content */}
        <div className="col-md-10">
        <ListviewRadiologyParameter/>
        </div>
      </div>
    </div></UserProvider>
  );
}

export const EditRadiologyReportComponent =()=>{
  return (
    <UserProvider> <div className=" ">
      <Header />
      <div
        className="row"
        style={{ marginTop: "50px" }}
      >
        {/* Sidebar */}
        <div className="col-md-2">
          <Sidebar />
        </div>

        {/* Main Content */}
        <div className="col-md-10">
        <EditRadiology/>
        </div>
      </div>
    </div></UserProvider>
  );
}

export const EditRadiologyCategoryComponent =()=>{
  return (
    <UserProvider> <div className=" ">
      <Header />
      <div
        className="row"
        style={{ marginTop: "50px" }}
      >
        {/* Sidebar */}
        <div className="col-md-2">
          <Sidebar />
        </div>

        {/* Main Content */}
        <div className="col-md-10">
        <EditRadiologyCategory/>
        </div>
      </div>
    </div></UserProvider>
  );
}

export const EditRadiologyParameter =()=>{
  return (
    <UserProvider> <div className=" ">
      <Header />
      <div
        className="row"
        style={{ marginTop: "50px" }}
      >
        {/* Sidebar */}
        <div className="col-md-2">
          <Sidebar />
        </div>

        {/* Main Content */}
        <div className="col-md-10">
        <UpdateRadiologyParameters/>
        </div>
      </div>
    </div></UserProvider>
  );
}