import React, { useState, useEffect } from 'react';
import { Modal, Backdrop, Fade, Button, TextField, Autocomplete, InputAdornment, IconButton } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useFormik } from "formik";
import { Operationvalidation } from "../../../helper/schemas";
import axios from "../../../helper/axios";
import { useUser } from "../../../Provider";
import { useParams } from 'react-router-dom/cjs/react-router-dom';
import { ToastContainer, toast } from 'react-toastify'
const initialValues = {
  operationcategory: "",
  operationtime: "",
  assistant1: "",
  anesthetist: "",
  ottechnician: "",
  remark: "",
  operationcode: "",
  consultantdoctor: "",
  assistant2: "",
  anesthesiatype: "",
  otassistant: "",
  result: ""
};
const UpdateIpdOperationPopUp = ({ operationData, onClose }) => {
  const { user, userHospitalcode, userrole1, isAuthRole, isAuthorized, gettokenId, token } = useUser();
  const [isFormVisible, setFormVisibility] = useState(false);
  const { admitnumber } = useParams();
  console.log({ admitnumber })
  const [data, setData] = useState([]);
  const [doctor, setDoctor] = useState([]);
  const [anesthetist, setAnesthetist] = useState([]);
  const [oTTechnician, setOTTechnician] = useState([]);
  const [oTAssistant, setOTAssistant] = useState([]);
  const [category1, setCategory] = useState([]);
  const [openAutocompleted, setOpenAutocompleted] = useState(false);
  const [openAutocomplete, setOpenAutocomplete] = useState(false);
  const [Autocompleted, setAutocompleted] = useState(false);
  const [openAutocompletes, setOpenAutocompletes] = useState(false);
  const [open, setOpen] = useState(false);
  const [opens, setOpens] = useState(false);
  const handleopens = () => {
    setOpens(!opens);
  };
  const handleopen = () => {
    setOpen(!open);
  };
  const handleExpandClicks = () => {
    setOpenAutocompletes(!openAutocompletes);
  };
  const handleExpandClick = () => {
    setOpenAutocomplete(!openAutocomplete);
  };
  const handleClicked = () => {
    setAutocompleted(!Autocompleted);
  };
  const handleExpandClicked = () => {
    setOpenAutocompleted(!openAutocompleted);
  };
  const fetchCateoryNames = async () => {
    try {
      const response = await axios.get("/getcategory", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          hospitalcode: data.hospitalcode, // Use the selected hospital code
        },
      });

      if (response.status === 200) {
        setCategory(response.data);
      } else {
        console.error(`Failed to fetch available floor numbers with status: ${response.status}`);
      }
    } catch (error) {
      console.error('Error fetching available floor numbers:', error.message);
    }
  };
  const loadHospitalcode = async () => {
    //when userHospitalcode and userrole1 is not undefined then execute /gethospitalcode
    if (userHospitalcode !== undefined && userrole1 !== undefined) {
      const res = await axios.get(`/getIPDhospitalcode/${admitnumber}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          hospitalcode: userHospitalcode, // Include the hospitalcode in the query parameters
          userrole: userrole1, // Include the userrole in the query parameters
        },
      });
      setData(res.data[0]);
      // console.log(res.data[0]);
    };
  }
  const getDoctordetails = async () => {

    try {
      if (userHospitalcode !== undefined && userrole1 !== undefined) {
        const res = await axios.get(`/getdoctorids`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: {
            hospitalcode: data.hospitalcode, // Include the hospitalcode in the query parameters
            userrole: userrole1, // Include the userrole in the query parameters
          },
        });
        console.log(res.data)
        // Check if the response status code is  200
        if (res.status === 200) {
          setDoctor(res.data);
        } else {
          // Handle unexpected response status codes (e.g., 404, 500, etc.) as errors.
          alert(`Request failed with status: ${res.status}`);
          // Optionally, you can set an error state or display an error message to the user.
        }
      }
    }
    catch (error) {
      // Handle network errors or other exceptions.
      console.log(`An error occurred: ${error.message}`);
      // Optionally, you can set an error state or display an error message to the user.
    }
  };
  const getAnesthetistdetails = async () => {

    try {
      if (userHospitalcode !== undefined && userrole1 !== undefined) {
        const res = await axios.get(`/getAnesthetist`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: {
            hospitalcode: data.hospitalcode, // Include the hospitalcode in the query parameters
            userrole: userrole1, // Include the userrole in the query parameters
          },
        });
        console.log(res.data)
        // Check if the response status code is  200
        if (res.status === 200) {
          setAnesthetist(res.data);
        } else {
          // Handle unexpected response status codes (e.g., 404, 500, etc.) as errors.
          alert(`Request failed with status: ${res.status}`);
          // Optionally, you can set an error state or display an error message to the user.
        }
      }
    }
    catch (error) {
      // Handle network errors or other exceptions.
      console.log(`An error occurred: ${error.message}`);
      // Optionally, you can set an error state or display an error message to the user.
    }
  };
  const getOTTechniciandetails = async () => {

    try {
      if (userHospitalcode !== undefined && userrole1 !== undefined) {
        const res = await axios.get(`/getOTTechnician`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: {
            hospitalcode: data.hospitalcode, // Include the hospitalcode in the query parameters
            userrole: userrole1, // Include the userrole in the query parameters
          },
        });
        console.log(res.data)
        // Check if the response status code is  200
        if (res.status === 200) {
          setOTTechnician(res.data);
        } else {
          // Handle unexpected response status codes (e.g., 404, 500, etc.) as errors.
          alert(`Request failed with status: ${res.status}`);
          // Optionally, you can set an error state or display an error message to the user.
        }
      }
    }
    catch (error) {
      // Handle network errors or other exceptions.
      console.log(`An error occurred: ${error.message}`);
      // Optionally, you can set an error state or display an error message to the user.
    }
  };
  const getOTAssistantdetails = async () => {

    try {
      if (userHospitalcode !== undefined && userrole1 !== undefined) {
        const res = await axios.get(`/getOTAssistant`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: {
            hospitalcode: data.hospitalcode, // Include the hospitalcode in the query parameters
            userrole: userrole1, // Include the userrole in the query parameters
          },
        });
        console.log(res.data)
        // Check if the response status code is  200
        if (res.status === 200) {
          setOTAssistant(res.data);
        } else {
          // Handle unexpected response status codes (e.g., 404, 500, etc.) as errors.
          alert(`Request failed with status: ${res.status}`);
          // Optionally, you can set an error state or display an error message to the user.
        }
      }
    }
    catch (error) {
      // Handle network errors or other exceptions.
      console.log(`An error occurred: ${error.message}`);
      // Optionally, you can set an error state or display an error message to the user.
    }
  };
  const [selectedCategory, setSelectedCategory] = useState('');
  const [operationNames, setOperationNames] = useState([]);

  const handleCategoryChange = (event) => {
    const selectedCategory = event.target.value;
    setSelectedCategory(selectedCategory);
    // Fetch operation names based on the selected category
    // You need to implement the API endpoint for fetching operation names based on the category
    // Update the endpoint and parameters accordingly
    fetchOperationNames(selectedCategory);
  };

  const fetchOperationNames = async (selectedCategory) => {
    try {
      const response = await axios.get("/getoperationnames", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          hospitalcode: data.hospitalcode,
          category: selectedCategory,
        },
      });

      if (response.status === 200) {
        setOperationNames(response.data);
      } else {
        console.error(`Failed to fetch operation names with status: ${response.status}`);
      }
    } catch (error) {
      console.error('Error fetching operation names:', error.message);
    }
  };

  const getIpdOpeartiondetails = async () => {
    try {
      if (userHospitalcode !== undefined && userrole1 !== undefined) {
        const res = await axios.get(`/getipdoperations/${admitnumber}/${operationData}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: {
            userHospitalcode: userHospitalcode,
            userrole: userrole1,
          },
        });

        if (res.status === 200) {
          setValues(res.data[0]);
          setSelectedCategory(res.data[0].operationcategory)
          fetchOperationNames(res.data[0].operationcategory);
          console.log(res.data[0])
        } else {
          alert(`Request failed with status: ${res.status}`);
        }
      }
    } catch (error) {
      console.log(`An error occurred: ${error.message}`);
    }
  };
  useEffect(() => {
    const loadData = async () => {
      await getIpdOpeartiondetails();
      await getDoctordetails(data.hospitalcode);
      await getAnesthetistdetails(data.hospitalcode);
      await getOTTechniciandetails(data.hospitalcode);
      await getOTAssistantdetails(data.hospitalcode);
      await loadHospitalcode();
      await fetchCateoryNames(data.hospitalcode);
    };

    loadData();

  }, [userHospitalcode, userrole1, isAuthorized, data.hospitalcode]);


  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleReset,
    handleSubmit,
    resetForm,
    setValues,
  } = useFormik({
    initialValues,
    // validationSchema: Operationvalidation,
    onSubmit: async (values, action) => {
      try {

        if (userHospitalcode !== undefined && userrole1 !== undefined) {
          const data = new FormData();

          data.append("document", JSON.stringify(values));
          console.log(JSON.stringify(values))
          data.append("selectedCategory", JSON.stringify(selectedCategory));
          const response = await axios.post(`/updateoperation/${admitnumber}/${operationData}`, data, {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "multipart/form-data",
            },

          });
          if (response.status === 200) {

            toast.success("Data Saved")

          }
        }
      } catch (err) {

      }
      action.resetForm();
    },

  });

  return (
    <Modal
      open={true} // Set to true to open the modal
      onClose={onClose}
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      closeAfterTransition
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={true}>
        <div style={{ backgroundColor: 'white', padding: '20px', borderRadius: '8px', maxWidth: '81%', margin: 'auto', marginTop: '50px' }}>
          <h2>Update Operation</h2>
          <form onSubmit={handleSubmit} style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
            <div className="patient-new primary-info">
              <div className="row">
                <div className="col-md-12">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="col-md-12 mt-3">
                        <div className="row">
                          <div className="col-md-4">
                            <label
                              htmlFor="operationcategory"
                              className="col-form-label leftone"
                            >
                              Operation Category
                            </label>
                          </div>
                          <div className="col-md-8">
                            <select
                              type="text"
                              autoComplete="off"
                              name="operationcategory"
                              id="operationcategory"
                              placeholder="Operation Category"
                              value={selectedCategory}
                              onChange={handleCategoryChange}
                              // onBlur={handleBlur}
                              style={{ width: '73%', height: '32px', border: '1px solid #ced4da ', color: '#495057' }}
                            >
                              <option>Select</option>
                              {category1.map((code) => (
                                <option value={code.categoryid} key={code.categoryid}>
                                  {code.category}
                                </option>
                              ))}
                            </select>
                            {errors.operationcategory && touched.operationcategory ? (
                              <p className="form-erroremployee">{errors.operationcategory}</p>
                            ) : null}
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12 mt-3">
                        <div className="row">
                          <div className="col-md-4">
                            <label
                              htmlFor="operationtime"
                              className="col-form-label leftone"
                            >
                              Operation Date
                            </label>
                          </div>
                          <div className="col-md-8">
                            <input
                              type="datetime-local"
                              autoComplete="off"
                              name="operationtime"
                              id="operationtime"
                              placeholder="Operation Date"
                              value={values.operationtime ? new Date(values.operationtime).toISOString().slice(0, 16) : ''}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              className="form-control"
                            />
                            {errors.operationtime && touched.operationtime ? (
                              <p className="form-erroremployee">{errors.operationtime}</p>
                            ) : null}
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12 mt-3">
                        <div className="row">
                          <div className="col-md-4">
                            <label
                              htmlFor="assistant1"
                              className="col-form-label "
                            >
                              Assistant Consultant 1
                            </label>
                          </div>
                          <div className="col-md-8">
                            <Autocomplete
                              freeSolo
                              id="free-solo"
                              options={doctor}
                              getOptionLabel={(doctors) => doctors.fname}
                              value={doctor.find((doctors) => doctors.doctorids === values.assistant1) || null}
                              open={openAutocompletes}
                              onOpen={() => setOpenAutocompletes(true)}
                              onClose={() => setOpenAutocompletes(false)}
                              onChange={(event, newValue) => {
                                setValues({
                                  ...values,
                                  assistant1: newValue ? newValue.doctorids : ""
                                });
                              }}
                              onBlur={handleBlur}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  placeholder="Select BedType"
                                  InputProps={{
                                    ...params.InputProps,
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        <IconButton onClick={handleExpandClicks}>
                                          <ExpandMoreIcon />
                                        </IconButton>
                                      </InputAdornment>
                                    ),
                                    //style: { width: '74%', padding: '0px ', border: "none" } // Adjust width here
                                  }}
                                />
                              )}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12 mt-3">
                        <div className="row">
                          <div className="col-md-4">
                            <label
                              htmlFor="anesthetist"
                              className="col-form-label "
                            >
                              Anesthetist
                            </label>
                          </div>
                          <div className="col-md-8">
                            <Autocomplete
                              freeSolo
                              id="free-solo"
                              options={anesthetist}
                              getOptionLabel={(doctors) => doctors.fname}
                              value={anesthetist.find((doctors) => doctors.anesthetistid === values.anesthetist) || null}
                              open={open}
                              onOpen={() => setOpen(true)}
                              onClose={() => setOpen(false)}
                              onChange={(event, newValue) => {
                                setValues({
                                  ...values,
                                  anesthetist: newValue ? newValue.anesthetistid : ""
                                });
                              }}
                              onBlur={handleBlur}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  placeholder="Select BedType"
                                  InputProps={{
                                    ...params.InputProps,
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        <IconButton onClick={handleopen}>
                                          <ExpandMoreIcon />
                                        </IconButton>
                                      </InputAdornment>
                                    ),
                                    //style: { width: '74%', padding: '0px ', border: "none" } // Adjust width here
                                  }}
                                />
                              )}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12 mt-3">
                        <div className="row">
                          <div className="col-md-4">
                            <label
                              htmlFor="ottechnician"
                              className="col-form-label "
                            >
                              OT Technician
                            </label>
                          </div>
                          <div className="col-md-8">
                            <Autocomplete
                              freeSolo
                              id="free-solo"
                              options={oTTechnician}
                              getOptionLabel={(doctors) => doctors.fname}
                              value={oTTechnician.find((doctors) => doctors.ottechnicianid === values.ottechnician) || null}
                              open={opens}
                              onOpen={() => setOpens(true)}
                              onClose={() => setOpens(false)}
                              onChange={(event, newValue) => {
                                setValues({
                                  ...values,
                                  ottechnician: newValue ? newValue.ottechnicianid : ""
                                });
                              }}
                              onBlur={handleBlur}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  placeholder="Select BedType"
                                  InputProps={{
                                    ...params.InputProps,
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        <IconButton onClick={handleopens}>
                                          <ExpandMoreIcon />
                                        </IconButton>
                                      </InputAdornment>
                                    ),
                                    //style: { width: '74%', padding: '0px ', border: "none" } // Adjust width here
                                  }}
                                />
                              )}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12 mt-3">
                        <div className="row">
                          <div className="col-md-4">
                            <label
                              htmlFor="remark"
                              className="col-form-label "
                            >
                              Remark
                            </label>
                          </div>
                          <div className="col-md-8">
                            <input
                              type="text"
                              autoComplete="off"
                              name="remark"
                              id="remark"
                              placeholder="remark"
                              value={values.remark}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              style={{ width: '73%', height: '32px', border: '1px solid #ced4da ', color: '#495057' }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="col-md-12 mt-3">
                        <div className="row">
                          <div className="col-md-4">
                            <label
                              htmlFor="operationcode"
                              className="col-form-label leftone"
                            >
                              Operation Name
                            </label>
                          </div>
                          <div className="col-md-8">
                            <select
                              type="text"
                              autoComplete="off"
                              name="operationcode"
                              id="operationcode"
                              placeholder="Operation Name"
                              value={values.operationcode}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              style={{ width: '73%', height: '32px', border: '1px solid #ced4da ', color: '#495057' }}
                            >
                              <option>Select</option>
                              {operationNames.map((name) => (
                                <option value={name.operationcode} key={name.operationcode}>
                                  {name.operationname}
                                </option>
                              ))}
                            </select>
                            {errors.operationcode && touched.operationcode ? (
                              <p className="form-erroremployee">{errors.operationcode}</p>
                            ) : null}
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12 mt-3">
                        <div className="row">
                          <div className="col-md-4">
                            <label
                              htmlFor="consultantdoctor"
                              className="col-form-label leftone"
                            >
                              Consultant Doctor
                            </label>
                          </div>
                          <div className="col-md-8">
                            <Autocomplete
                              freeSolo
                              id="free-solo"
                              options={doctor}
                              getOptionLabel={(doctors) => doctors.fname}
                              value={doctor.find((doctors) => doctors.doctorids === values.consultantdoctor) || null}
                              open={Autocompleted}
                              onOpen={() => setAutocompleted(true)}
                              onClose={() => setAutocompleted(false)}
                              onChange={(event, newValue) => {
                                setValues({
                                  ...values,
                                  consultantdoctor: newValue ? newValue.doctorids : ""
                                });
                              }}
                              onBlur={handleBlur}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  placeholder="Select BedType"
                                  InputProps={{
                                    ...params.InputProps,
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        <IconButton onClick={handleClicked}>
                                          <ExpandMoreIcon />
                                        </IconButton>
                                      </InputAdornment>
                                    ),
                                    //style: { width: '74%', padding: '0px ', border: "none" } // Adjust width here
                                  }}
                                />
                              )}
                            />
                            {errors.doctorids && touched.doctorids ? (
                              <p className="form-erroremployee">{errors.doctorids}</p>
                            ) : null}
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12 mt-3">
                        <div className="row">
                          <div className="col-md-4">
                            <label
                              htmlFor="assistant2"
                              className="col-form-label "
                            >
                              Assistant Consultant 2
                            </label>
                          </div>
                          <div className="col-md-8">
                            <Autocomplete
                              freeSolo
                              id="free-solo"
                              options={doctor}
                              getOptionLabel={(doctors) => doctors.fname}
                              value={doctor.find((doctors) => doctors.doctorids === values.assistant2) || null}
                              open={openAutocomplete}
                              onOpen={() => setOpenAutocomplete(true)}
                              onClose={() => setOpenAutocomplete(false)}
                              onChange={(event, newValue) => {
                                setValues({
                                  ...values,
                                  assistant2: newValue ? newValue.doctorids : ""
                                });
                              }}
                              onBlur={handleBlur}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  placeholder="Select BedType"
                                  InputProps={{
                                    ...params.InputProps,
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        <IconButton onClick={handleExpandClick}>
                                          <ExpandMoreIcon />
                                        </IconButton>
                                      </InputAdornment>
                                    ),
                                    //style: { width: '74%', padding: '0px ', border: "none" } // Adjust width here
                                  }}
                                />
                              )}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12 mt-3">
                        <div className="row">
                          <div className="col-md-4">
                            <label
                              htmlFor="anesthesiatype"
                              className="col-form-label "
                            >
                              Anesthesia Type
                            </label>
                          </div>
                          <div className="col-md-8">
                            <input
                              type="text"
                              autoComplete="off"
                              name="anesthesiatype"
                              id="anesthesiatype"
                              placeholder="Anesthesia Type"
                              value={values.anesthesiatype}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              style={{ width: '73%', height: '32px', border: '1px solid #ced4da ', color: '#495057' }}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12 mt-3">
                        <div className="row">
                          <div className="col-md-4">
                            <label
                              htmlFor="otassistant"
                              className="col-form-label "
                            >
                              OT Assistant
                            </label>
                          </div>
                          <div className="col-md-8">
                            <Autocomplete
                              freeSolo
                              id="free-solo"
                              options={oTAssistant}
                              getOptionLabel={(bedType) => bedType.fname}
                              value={oTAssistant.find((bedType) => bedType.otassistantid === values.otassistant) || null}
                              open={openAutocompleted}
                              onOpen={() => setOpenAutocompleted(true)}
                              onClose={() => setOpenAutocompleted(false)}
                              onChange={(event, newValue) => {
                                setValues({
                                  ...values,
                                  otassistant: newValue ? newValue.otassistantid : ""
                                });
                              }}
                              onBlur={handleBlur}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  placeholder="Select BedType"
                                  InputProps={{
                                    ...params.InputProps,
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        <IconButton onClick={handleExpandClicked}>
                                          <ExpandMoreIcon />
                                        </IconButton>
                                      </InputAdornment>
                                    ),
                                    //style: { width: '74%', padding: '0px ', border: "none" } // Adjust width here
                                  }}
                                />
                              )}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12 mt-3">
                        <div className="row">
                          <div className="col-md-4">
                            <label
                              htmlFor="result"
                              className="col-form-label "
                            >
                              Result
                            </label>
                          </div>
                          <div className="col-md-8">
                            <input
                              type="text"
                              autoComplete="off"
                              name="result"
                              id="result"
                              placeholder="Result"
                              value={values.result}
                              onChange={handleChange}
                              onBlur={handleBlur}

                              className="form-control"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-11 mt-6 text-center">
              <Button type="submit" variant="contained" color="primary" size="small" style={{ marginRight: '10px' }}>
                Submit
              </Button>
              <Button variant="contained" size="small" onClick={onClose}>
                Cancel
              </Button>
            </div>
          </form>
          <ToastContainer/>
        </div>
      </Fade>
    </Modal>
  );
};

export default UpdateIpdOperationPopUp;
