import React, { useState, useEffect } from 'react';
import { Form, Button } from 'react-bootstrap';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { NavLink, useHistory } from 'react-router-dom/cjs/react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from '../../../helper/axios'; // Assuming axios is used for API calls
import { Helmet } from 'react-helmet';
import Searchbar from '../../../Component/Searchbar';
import { useUser } from "../../../Provider";
import HospitalCodeSearch from '../../../Component/HospitalCodeSearch';
import SearchStaff from '../../../Component/SearchStaff';
import AvailableBedSearch from '../../../Component/AvailableBedSearch';

const getCurrentDate = () => {
  const now = new Date();
  const year = now.getFullYear();
  const month = String(now.getMonth() + 1).padStart(2, '0');
  const day = String(now.getDate()).padStart(2, '0');
  const hours = String(now.getHours()).padStart(2, '0');
  const minutes = String(now.getMinutes()).padStart(2, '0');
  const currentDateTimeString = `${year}-${month}-${day}`;
  return currentDateTimeString;
};

// ... rest of your code

const initialValues = {
  admitdate: getCurrentDate(),
  empid: "",
  PID: "",
  hospitalcode: "",
  bedid: "",
  patientname: "",
  phoneno: "",
  age: "",
  address: "",
  gender: "",
  email: "",
  bloodgroup: "",
  attendername: "",
  attenderphone: "",
  gaurdianname: "",
  // Add more fields as needed
};
const PatientAdmit = () => {
   // Validation schema for patientType "Registered"
  const validationSchema1 = Yup.object({
    patientname: Yup.string().required("Please enter the patient name"),
    bedid: Yup.string().required("Please enter the bed name"),
    empid: Yup.string().required("Please enter the admitted by"),
    admitdate: Yup.string().required("Please enter the admit date"),
    attenderphone: Yup.string()
    .matches(/^[0-9]+$/, "Mobile number should only contain numbers")
    .min(10, "Mobile number must be at least 10 characters")
    .max(10, "Mobile number must be 10 characters")
    .required("Mobile number is required"),
    // attendername: Yup.string().required("Please enter the attender name"),
    attendername: Yup.string().matches(/^[a-zA-Z\s]+$/, "Attender name should only contain letters").required("Please enter the attender name"),
  });
   // Validation schema for patientType "New"
  const validationSchema2 = Yup.object({
    patientname: Yup.string().matches(/^[a-zA-Z\s]+$/, "Patient name should only contain letters").required("Please enter the patient name"),
    gaurdianname : Yup.string().matches(/^[a-zA-Z\s]+$/, "Guardian name should only contain letters"),
    phoneno: Yup.string()
      .matches(/^[0-9]+$/, "Mobile Number should only contain numbers")
      .min(10, "Mobile Number must be at least 10 characters")
      .max(10, "Mobile Number must be 10 characters")
      .required("Mobile Number is required"),
      age: Yup.number()
      .typeError("Age must be a number")
      .positive("Age must be a positive number")
      .integer("Age must be an integer")
      .max(999, "Age must be at most 3 digits")
      .required("Please enter the age"),
    hospitalcode: Yup.string().required("Please enter the hospital code"),
    address: Yup.string().required("Please enter the address"),
    gender: Yup.string().required("Gender is required"),
    bedid: Yup.string().required("Please enter the bed name"),
    empid: Yup.string().required("Please enter the admitted by"),
    admitdate: Yup.string().required("Please enter the admit date"),
    attenderphone: Yup.string()
    .matches(/^[0-9]+$/, "Mobile number should only contain numbers")
    .min(10, "Mobile number must be at least 10 characters")
    .max(10, "Mobile number must be 10 characters")
    .required("Mobile number is required"),
    // attendername: Yup.string().required("Please enter the attender name"),
    attendername: Yup.string().matches(/^[a-zA-Z\s]+$/, "Attender name should only contain letters").required("Please enter the attender name"),
  });
  
  const validationSchema3 = Yup.object({
    patientname: Yup.string().matches(/^[a-zA-Z\s]+$/, "Patient name should only contain letters").required("Please enter the patient name"),
    gaurdianname : Yup.string().matches(/^[a-zA-Z\s]+$/, "Guardian name should only contain letters"),
    phoneno: Yup.string()
      .matches(/^[0-9]+$/, "Mobile Number should only contain numbers")
      .min(10, "Mobile Number must be at least 10 characters")
      .max(10, "Mobile Number must be 10 characters")
      .required("Mobile Number is required"),
      age: Yup.number()
      .typeError("Age must be a number")
      .positive("Age must be a positive number")
      .integer("Age must be an integer")
      .max(999, "Age must be at most 3 digits")
      .required("Please enter the age"),
    address: Yup.string().required("Please enter the address"),
    gender: Yup.string().required("Gender is required"),
    bedid: Yup.string().required("Please enter the bed name"),
    empid: Yup.string().required("Please enter the admitted by"),
    admitdate: Yup.string().required("Please enter the admit date"),
    attenderphone: Yup.string()
    .matches(/^[0-9]+$/, "Mobile number should only contain numbers")
    .min(10, "Mobile number must be at least 10 characters")
    .max(10, "Mobile number must be 10 characters")
    .required("Mobile number is required"),
    // attendername: Yup.string().required("Please enter the attender name"),
    attendername: Yup.string().matches(/^[a-zA-Z\s]+$/, "Attender name should only contain letters").required("Please enter the attender name"),
  });
  
   const {user, userHospitalcode, userrole1, isAuthRole,isAuthorized,gettokenId,token  } = useUser();
  // console.log(user, userHospitalcode, userrole1, isAuthRole,isAuthorized,gettokenId )

  const [patientType, setPatientType] = useState("Registered");
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
 // const [gettokenId, setTokenId] = useState();
  const history = useHistory();
  //const [isAuthorized, setIsAuthorized] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [isPopup, setIsPopup] = useState(false);
  const [currentDateTime, setCurrentDateTime] = useState('');
  const [availableBedNumbers, setAvailableBedNumbers] = useState([]);
  const [availableStaffs, setAvailableStaffs] = useState([]);
  const [searchValues, setSearchValues] = useState("");
  const handlePatientChange = (event) => {
    setPatientType(event.target.value);

    // If the selected patient type is "Registered," reset the form
    if (event.target.value === "Registered" || event.target.value === "New") {
      resetForm();
    }

  };

  const getCurrentDateTime = () => {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0');
    const day = String(now.getDate()).padStart(2, '0');
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    const currentDateTimeString = `${year}-${month}-${day}T${hours}:${minutes}`;
    return currentDateTimeString;
  };

  let Showdate = new Date();
  let ShowTodaydate =
    Showdate.getFullYear() +
    "-" +
    (Showdate.getMonth() + 1) +
    "-" +
    Showdate.getDate();
  const [currentDate, setCurrentDate] = useState(ShowTodaydate);
  const getValidationSchema = () => {
    if (patientType === 'Registered') {
      return validationSchema1;

    } else if (!isAuthorized && patientType === 'New') {
      return validationSchema3;
    }
    else if (isAuthorized && patientType === 'New') {
      return validationSchema2;
    }
    // Add default validation schema if needed
    return Yup.object();
  };
  const {
    values,
    errors,
    touched,
    setValues,
    handleChange,
    handleBlur,
    resetForm,
    handleSubmit,
  } = useFormik({
    initialValues,
    validationSchema: getValidationSchema,
    onSubmit: async (values, { resetForm }) => {
      console.log(values); // Log the values to check if they are captured correctly

      try {
        setIsPopup(false)
        if (userHospitalcode !== undefined) {
          const data = new FormData();
          data.append("document", JSON.stringify(values));

          // // ... append other fields as needed

          data.append('userHospitalcode', JSON.stringify(userHospitalcode));
          data.append('currentDateTime', JSON.stringify(getCurrentDateTime()));
          data.append('gettokenId', JSON.stringify(gettokenId));
          data.append('currentDate', JSON.stringify(currentDate));
          data.append("patientType", JSON.stringify(patientType));
         
          console.log(JSON.stringify(values));

          const response = await axios.post("/patientadmit", data, {
            headers: {
              Authorization: `Beaxrer ${token}`,
              "Content-Type": "multipart/form-data",
            },
          });

          if (response.status === 200) {
            return history.push("/listviewIPD");
            toast.success("Data Saved");
            setError(false);
            resetForm(); // Reset the form on successful submission
          } else {
            console.error('Error in admitting patient');
            setSuccess(false);
            setError(true);
          }
        }
      } catch (error) {
        console.error('Error:', error.message);
        setSuccess(false);
        setError(true);
        toast.error('Something Went Wrong');
      }

    },
  });

  const handleReset = () => {
    // Reset the form using Formik's resetForm function
    resetForm();
  };
//hospitalcode search
  const onSearchItems = (searchTerm) => {
    console.log("------",searchTerm)
    setValues({
      ...values,
      hospitalcode: searchTerm.hospitalcode,
      empid: "",
      // Update other fields as needed
    });
    setSearchValues("");
  };
  //get employee
  const handleSearchChanges = (searchTerms) => {
    console.log("---------------",searchTerms)
    setSearchValues(searchTerms); // Update the searchValue state with the search term
     setValues({
      ...values,
      empid: searchTerms.empid,
      // Update other fields as needed
    });
  };
    //get employee
    const handleSearchBedChanges = (searchTerms) => {
      console.log("---------------",searchTerms)
      //setSearchValues(searchTerms); // Update the searchValue state with the search term
       setValues({
        ...values,
        bedid: searchTerms.bedid,
        // Update other fields as needed
      });
    };
// search patient
  const handleSearchChange = async (searchTerm) => {
    if (searchTerm) {
      console.log(searchTerm);
      setSearchValue(searchTerm); // Update the searchValue state with the search term
      // Assuming searchTerm contains patient information including hospital code
      const selectedHospitalCode = searchTerm.hospitalcode;
  
      // Call the function to fetch available bed numbers based on the selected hospital code
      await getAvailableBedNumbers(selectedHospitalCode);
    } else {
      // Handle the case when searchTerm is null, you can reset the searchValue state
      setSearchValue("");
    }
  };

  useEffect(() => {
    const loadData = async () => {
      // await getBedNumbers();
      const selectedHospitalCode = values.hospitalcode || userHospitalcode;
      await getAvailableBedNumbers(selectedHospitalCode);
     // await getAvailableStaff(selectedHospitalCode);
    };
    loadData();
  }, [userHospitalcode, userrole1, isAuthorized, values.hospitalcode]);

  useEffect(() => {
  //  loadAllDetails();
    setCurrentDateTime(getCurrentDateTime());
  }, []);

  useEffect(() => {

    // Call the fetchPatientDetails function whenever the searchValue changes
    console.log(searchValue, "-----")
    if (searchValue) {
      // fetchPatientDetails();
      setValues({
        ...values,
        patientname: searchValue.patientname, // Make sure field names match
        bloodGroup: searchValue.bloodgroup,   // Make sure field names match
        phoneno: searchValue.phoneno,        // Make sure field names match
        address: searchValue.address,
        PID: searchValue.patientid,
        age: searchValue.age,
        email: searchValue.email,
        bloodgroup: searchValue.bloodgroup,
        gender: searchValue.gender,
        gaurdianname: searchValue.gaurdianname,
        hospitalcode: searchValue.hospitalcode,
        // Update other fields as needed
      });
    }
  }, [searchValue, token]);

  const getAvailableBedNumbers = async (selectedHospitalCode) => {
    try {
      const response = await axios.get(`/getAvailableBedNumbers`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          hospitalcode: selectedHospitalCode,
        },
      });

      if (response.status === 200) {
        setAvailableBedNumbers(response.data);
      } else {
        console.error(`Failed to fetch available bed numbers with status: ${response.status}`);
      }
    } catch (error) {
      console.error('Error fetching available bed numbers:', error.message);
    }
  };


  // const getAvailableStaff = async (selectedHospitalCode) => {
  //   try {
  //     const response = await axios.get(`/getAvailableStaffs`, {
  //       headers: {
  //         Authorization: `Bearer ${token}`,
  //       },
  //       params: {
  //         hospitalcode: selectedHospitalCode,
  //       },
  //     });

  //     if (response.status === 200) {
  //       setAvailableStaffs(response.data);
  //       console.log(response.data)
  //     } else {
  //       console.error(`Failed to fetch available staff with status: ${response.status}`);
  //     }
  //   } catch (error) {
  //     console.error('Error fetching available staff number:', error.message);
  //   }
  // };
  const currentDomain = window.location.hostname;
  return (
    <div className="p-3 pt-0 appointment">
      <Helmet>
        <title> Admit Patient | {currentDomain} | Hospital Management Software | Smart Solutions for Hospital Efficiency</title>
      </Helmet>
      {/* <ToastContainer /> */}


      {/* <div className='row'>
            <div className='col-md-6'>
              <h4>Patient Admit</h4>
            </div>
          </div> */}

      {/* <div className='row'> */}
      {/* <div className='col-md-6'> */}
      <Form onSubmit={handleSubmit}>
        {success && <div>Patient Admit</div>}
        {error && <div>Error in registering patient. please try again</div>}
        <div className="patient-new primary-info">
          <div className="row">
            <div className="col-md-12">
              <div className="row">
                <div className="col-md-6">
                  <div className="col-md-12 mt-3">
                    <div className="row">
                      <div className="col-md-4">
                        <label
                          htmlFor="patientType"
                          className="col-form-label "
                        >
                          Patient Type
                        </label>
                      </div>
                      <div className="col-md-8">
                        <select
                          type="text"
                          autoComplete="off"
                          name="patientType"
                          id="patientType"
                          placeholder="Patient Type"
                          value={patientType}
                          onChange={handlePatientChange}
                          style={{ width: '73%', height: '32px', border: '1px solid #ced4da ', color: '#495057' }}
                        >
                          <option>Registered</option>
                          <option>New</option>

                        </select>
                        {errors.patientType && touched.patientType ? (
                          <p className="form-error">
                            {errors.patientType}
                          </p>
                        ) : null}
                      </div>
                    </div>

                  </div>

                  <div className="col-md-12">
                    <div className="row">
                      {patientType !== "New" && (<div className="col-md-4 mt-3">
                        <label
                          htmlFor="PID"
                          className="col-form-label leftone"
                        >
                          Patient Id
                        </label>

                      </div>)}
                      {patientType !== "New" && (<div className="col-md-8 mt-3">
                        <input
                          type="text"
                          autoComplete="off"
                          name="PID"
                          id="PID"
                          placeholder="Patient Id"
                          value={values.PID}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          disabled={patientType === 'Registered'}
                          className="form-control"
                        />
                        {errors.PID && touched.PID ? (
                          <p className="form-error">{errors.PID}</p>
                        ) : null}
                      </div>)}
                    </div>

                  </div>
{/* 
                  <div className="col-md-12 mt-3">
                    <div className="row">
                      <div className="col-md-4">
                        <label
                          htmlFor="patientname"
                          className="col-form-label leftone"
                        >
                          Patient Name
                        </label>
                      </div>
                      <div className="col-md-8">
                        <input
                          type="text"
                          autoComplete="off"
                          name="patientname"
                          id="patientname"
                          placeholder="Patient Name"
                          // value={values.patientname}
                          value={patientType !== 'Register' ? '' :values.patientname}
                          disabled={patientType === 'Register'}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          // className="form-control ember-text-field text-left ember-view"
                          className="form-control"
                        />
                        {errors.patientname && touched.patientname ? (
                          <p className="form-erroremployee">{errors.patientname}</p>
                        ) : null}
                      </div>
                    </div>
                  </div> */}


                 
                  <div className="col-md-12 mt-3">
                    <div className="row">
                    {patientType !== "Registered" && (<div className="col-md-4">
                        <label
                          htmlFor="age"
                          className="col-form-label leftone"
                        >
                          Age
                        </label>
                      </div>)}
                      {patientType !== "New" && (<div className="col-md-4">
                        <label
                          htmlFor="age"
                          className="col-form-label leftone"
                        >
                           Patient Age
                        </label>
                      </div>)}
                      <div className="col-md-8">
                        <input
                          type="number"
                          autoComplete="off"
                          name="age"
                          id="age"
                          placeholder="Age"
                          // value={patientType !== 'Register' ? '' :values.age}
                          value={values.age}
                          disabled={patientType === 'Registered'}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className="form-control ember-text-field text-left ember-view"
                        ></input>
                        {errors.age && touched.age ? (
                          <p className="form-erroremployee">{errors.age}</p>
                        ) : null}
                      </div>
                    </div>
                  </div>

                  <div className="col-md-12 mt-3">
                    <div className="row">
                      {patientType !== "New" && (<div className="col-md-4">
                        <label
                          htmlFor="gender"
                          className="col-form-label "
                        >
                          Patient Gender
                        </label>
                      </div>)}
                      {patientType !== "Registered" && (<div className="col-md-4">
                        <label
                          htmlFor="gender"
                          className="col-form-label leftone  "
                        >
                          Patient Gender
                        </label>
                      </div>)}
                      {patientType !== "New" && (<div className="col-md-8">
                        <select
                          type="text"
                          autoComplete="off"
                          name="gender"
                          id="gender"
                          placeholder="Gender"
                          value={patientType !== 'Registered' ? '' :values.gender}
                          // value={values.gender}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          disabled={patientType === 'Registered'}
                          className="form-control"
                        >
                          <option>Select</option>
                          <option>Male</option>
                          <option>Female</option>
                          <option>Others</option>
                        </select>
                        {errors.gender && touched.gender ? (
                          <p className="form-erroremployee">{errors.gender}</p>
                        ) : null}
                      </div>)}
                      {patientType !== "Registered" && (<div className="col-md-8">
                        <select
                          type="text"
                          autoComplete="off"
                          name="gender"
                          id="gender"
                          placeholder="Patient gender"
                          value={values.gender}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          disabled={patientType === 'Registered'}
                          style={{ width: '73%', height: '32px', border: '1px solid #ced4da ', color: '#495057' }}
                        >
                          <option>Select</option>
                          <option>Male</option>
                          <option>Female</option>
                          <option>Other</option>
                        </select>
                        {errors.gender && touched.gender ? (
                          <p className="form-error">{errors.gender}</p>
                        ) : null}
                      </div>)}
                    </div>
                  </div>

                  <div className="col-md-12 mt-3">
                    <div className="row">
                      {patientType !== "Registered" && (<div className="col-md-4">
                        <label
                          htmlFor="email"
                          className="col-form-label"
                        >
                          Email
                        </label>
                      </div>)}
                      {patientType !== "New" && (<div className="col-md-4">
                        <label
                          htmlFor="email"
                          className="col-form-label"
                        >
                          Email
                        </label>
                      </div>)}
                      <div className="col-md-8">
                        <input
                          type="email"
                          autoComplete="off"
                          name="email"
                          id="email"
                          placeholder="Email"
                          // value={patientType !== 'Register' ? '' :values.email}
                          value={values.email}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          disabled={patientType === 'Registered'}
                          className="form-control ember-text-field text-left ember-view"
                        ></input>
                        {errors.email && touched.email ? (
                          <p className="form-erroremployee">{errors.email}</p>
                        ) : null}
                      </div>
                    </div>
                  </div>

                  {/* <div className="col-md-12 mt-3">
                    <div className="row">
                      {patientType !== "Register" && (<div className="col-md-4">
                        <label
                          htmlFor="bloodgroup"
                          className="col-form-label "
                        >
                          Blood Group
                        </label>
                      </div>)}
                      {patientType !== "New" && (<div className="col-md-4">
                        <label
                          htmlFor="bloodgroup"
                          className="col-form-label "
                        >
                          Blood Group
                        </label>
                      </div>)}
                      <div className="col-md-8">
                        <input
                          type="text"
                          autoComplete="off"
                          name="bloodgroup"
                          id="bloodgroup"
                          placeholder="Blood Group"
                          value={patientType !== 'Register' ? '' :values.bloodgroup}
                          // value={values.bloodgroup}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          disabled={patientType === 'Register'}
                          className="form-control ember-text-field text-left ember-view"
                        />

                        {errors.bloodgroup && touched.bloodgroup ? (
                          <p className="form-erroremployee">{errors.bloodgroup}</p>
                        ) : null}
                      </div>
                    </div>
                  </div> */}


                  <div className="col-md-12 mt-3">
                    <div className="row">
                      <div className="col-md-4">
                        <label
                          htmlFor="admitdate"
                          className="col-form-label leftone"
                        >
                          Admit Date
                        </label>
                      </div>
                      <div className="col-md-8">
                        <input
                          type="date"
                          autoComplete="off"
                          name="admitdate"
                          id="admitdate"
                          placeholder="Admit Date"
                          value={values.admitdate}
                          min={getCurrentDate()}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className="form-control ember-text-field text-left ember-view"
                        />
                        {errors.admitdate && touched.admitdate ? (
                          <p className="form-erroremployee">{errors.admitdate}</p>
                        ) : null}
                      </div>
                    </div>
                  </div>


                  <div className="col-md-12 mt-3">
                    <div className="row">
                      <div className="col-md-4">
                        <label
                          htmlFor="attendername"
                          className="col-form-label leftone"
                        >
                          Attender Name
                        </label>
                      </div>
                      <div className="col-md-8">
                        <input
                          type="text"
                          autoComplete="off"
                          name="attendername"
                          id="attendername"
                          placeholder="Attender Name"
                          value={values.attendername}

                          onChange={handleChange}
                          onBlur={handleBlur}
                          className="form-control ember-text-field text-left ember-view"
                        />
                        {errors.attendername && touched.attendername ? (
                          <p className="form-erroremployee">{errors.attendername}</p>
                        ) : null}
                      </div>
                    </div>
                  </div>

                  <div className="col-md-12 mt-3">
                    <div className="row">
                      <div className="col-md-4">
                        <label
                          htmlFor="attenderphone"
                          className="col-form-label leftone"
                        >
                          Attender Phone
                        </label>
                      </div>
                      <div className="col-md-8">
                        <input
                          type="text"
                          autoComplete="off"
                          name="attenderphone"
                          id="attenderphone"
                          placeholder="Attender Phone"
                          value={values.attenderphone}

                          onChange={handleChange}
                          onBlur={handleBlur}
                          className="form-control ember-text-field text-left ember-view"
                        />
                        {errors.attenderphone && touched.attenderphone? (
                          <p className="form-erroremployee">{errors.attenderphone}</p>
                        ) : null}
                      </div>
                    </div>
                  </div>

                  <div className="col-md-12 mt-3">
                    <div className="row">
                      <div className="col-md-4">
                        <label
                          htmlFor="empid"
                          className="col-form-label leftone"
                        >
                          Admitted By
                        </label>
                      </div>
                      <div className="col-md-8">
                      <SearchStaff 
                      onSearchChanges={handleSearchChanges}
                      hospitalvalue={values.hospitalcode || userHospitalcode}
                      
                      />
                        {/* <select
                          type="text"
                          autoComplete="off"
                          name="empid"
                          id="empid"
                          placeholder="Admited By"
                          value={values.empid || gettokenId }
                          onChange={handleChange}
                          onBlur={handleBlur}
                          style={{ width: '73%', height: '32px', border: '1px solid #ced4da ', color: '#495057' }}
                        >

                 <option value="">Select Staff</option>
                          {availableStaffs.map((admitted) => (
                            <option key={admitted.empid} value={admitted.empid}>
                              {admitted.fname}
                            </option>
                          ))}
                          </select> */}
                          {errors.empid && touched.empid? (
                          <p className="form-erroremployee">{errors.empid}</p>
                        ) : null}
                      </div></div></div>
                </div>

                <div className="col-md-6">
                  <div className="col-md-12 ">
                    <div className="row">
                      {patientType !== "New" && (<div className="col-md-4 mt-3">
                        <label
                          className="col-form-label "
                        >
                          Search Patient
                        </label>
                      </div>)}
                      {patientType !== "New" && (
                        <div className="col-md-8 mt-3">
                          <Searchbar onSearchChange={handleSearchChange} />
                        </div>)}
                    </div>

                  </div>

                  
                  <div className="col-md-12 mt-3">
                    <div className="row">
                      <div className="col-md-4">
                        <label
                          htmlFor="patientname"
                          className="col-form-label leftone"
                        >
                          Patient Name
                        </label>
                      </div>
                      <div className="col-md-8">
                        <input
                          type="text"
                          autoComplete="off"
                          name="patientname"
                          id="patientname"
                          placeholder="Patient Name"
                          value={values.patientname}
                          // value={patientType !== 'Register' ? '' :values.patientname}
                          disabled={patientType === 'Registered'}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          // className="form-control ember-text-field text-left ember-view"
                          className="form-control"
                        />
                        {errors.patientname && touched.patientname ? (
                          <p className="form-erroremployee">{errors.patientname}</p>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  {/* <div className="col-md-12">
                    <div className="row">
                      {patientType !== "New" && (<div className="col-md-4 mt-3">
                        <label
                          htmlFor="PID"
                          className="col-form-label leftone"
                        >
                          Patient Id
                        </label>

                      </div>)}
                      {patientType !== "New" && (<div className="col-md-8 mt-3">
                        <input
                          type="text"
                          autoComplete="off"
                          name="PID"
                          id="PID"
                          placeholder="Patient Id"
                          value={values.PID}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          disabled={patientType === 'Register'}
                          className="form-control"
                        />
                        {errors.PID && touched.PID ? (
                          <p className="form-error">{errors.PID}</p>
                        ) : null}
                      </div>)}
                    </div>

                  </div> */}

                  <div className="col-md-12 mt-3">
                    <div className="row">
                      {patientType !== "Registered" && (<div className="col-md-4">
                        <label
                          htmlFor="phoneno "
                          className="col-form-label leftone"
                        >
                          Mobile Number
                        </label>
                      </div>)}
                      {patientType !== "New" && (<div className="col-md-4">
                        <label
                          htmlFor="phoneno "
                          className="col-form-label leftone"
                        >
                          Mobile Number
                        </label>
                      </div>)}
                      <div className="col-md-8">
                        <input
                          type="number"
                          autoComplete="off"
                          name="phoneno"
                          id="phoneno"
                          placeholder="Mobile Number"
                          // value={patientType !== 'Register' ? '' :values.phoneno}
                          value={values.phoneno}
                          onChange={handleChange}
                          disabled={patientType === 'Registered'}
                          className="form-control ember-text-field text-left ember-view"
                        />

                        {errors.phoneno && touched.phoneno ? (
                          <p className="form-erroremployee">{errors.phoneno}</p>
                        ) : null}
                      </div></div></div>

                  <div className="col-md-12 mt-3">
                    <div className="row">
                      {patientType !== "Registered" && (<div className="col-md-4">
                        <label
                          htmlFor="address"
                          className="col-form-label leftone  "
                        >
                          Address
                        </label>
                      </div>)}
                      {patientType !== "New" && (<div className="col-md-4">
                        <label
                          htmlFor="address"
                          className="col-form-label"
                        >
                          Address
                        </label>
                      </div>)}
                      <div className="col-md-8">
                        <textarea
                          rows="1"
                          cols="30"
                          type="text"
                          autoComplete="off"
                          name="address"
                          id="address"
                          placeholder="Address"
                          // value={patientType !== 'Register' ? '' :values.address}
                          value={values.address}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          disabled={patientType === 'Registered'}
                          className="form-control"

                        />
                        {errors.address && touched.address ? (
                          <p className="form-erroremployee">{errors.address}</p>
                        ) : null}
                      </div></div></div>


                      <div className="col-md-12 mt-3">
                    <div className="row">
                      {patientType !== "Registered" && (<div className="col-md-4">
                        <label
                          htmlFor="bloodgroup"
                          className="col-form-label "
                        >
                          Blood Group
                        </label>
                      </div>)}
                      {patientType !== "New" && (<div className="col-md-4">
                        <label
                          htmlFor="bloodgroup"
                          className="col-form-label "
                        >
                          Blood Group
                        </label>
                      </div>)}
                      <div className="col-md-8">
                      {patientType !== "New" ? (
    <input
      type="text"
      autoComplete="off"
      name="bloodgroup"
      id="bloodgroup"
      placeholder="Blood Group"
      value={values.bloodgroup}
      onChange={handleChange}
      onBlur={handleBlur}
      disabled={patientType === 'Registered'}
      className="form-control ember-text-field text-left ember-view"
    />
  ) : (
    <select
      type="text"
      autoComplete="off"
      name="bloodgroup"
      id="bloodgroup"
      placeholder="Blood Group"
      value={values.bloodgroup}
      onChange={handleChange}
      onBlur={handleBlur}
      disabled={patientType === 'Registered'}
      style={{ width: '73%', height: '32px', border: '1px solid #ced4da', color: '#495057' }}
    >
      <option>Select</option>
      <option>O+ve</option>
      <option>A+ve</option>
      <option>B+ve</option>
      <option>AB+ve</option>
      <option>O-ve</option>
      <option>A-ve</option>
      <option>B-ve</option>
      <option>AB-ve</option>
    </select>
  )}
  
  {errors.bloodgroup && touched.bloodgroup ? (
    <p className="form-erroremployee">{errors.bloodgroup}</p>
  ) : null}
                      </div>
                    </div>
                  </div>
                  {isAuthorized ? (<div className="col-md-12 mt-3">
                    <div className="row">

                      {patientType !== "New" && (<div className="col-md-4">
                        <label
                          htmlFor="hospitalcode"
                          className="col-form-label leftone"
                        >
                          Hospital Code
                        </label>
                      </div>)}
                      {patientType !== "Registered" && (<div className="col-md-4">
                        <label
                          htmlFor="hospitalcode"
                          className="col-form-label leftone  "
                        >
                          Hospital Code
                        </label>
                      </div>)}
                      {patientType !== "New" && (<div className="col-md-8">
                        {/* Conditionally render the dropdown and input field */}
                        {isAuthorized ? (
                          <input
                            type="text"
                            autoComplete="off"
                            name="hospitalcode"
                            id="hospitalcode"
                            placeholder="Hospital Code"
                            // value={patientType !== 'Registered' ? '' :values.hospitalcode}
                            value={values.hospitalcode}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            onSearchChange={(e) => handleSearchChange(e.target.value)} // Pass the selected hospital code to handleSearchChange
                            disabled={patientType === 'Registered'}
                            className="form-control"
                          />
                           
                        ) : (
                          <input
                            type="text"
                            name="hospitalcode"
                            id="hospitalcode"
                            value={userHospitalcode}
                            disabled={true} // Set the input as disabled
                            className="form-control "
                          />
                        )}
                        {isAuthorized && errors.hospitalcode && touched.hospitalcode ? (
                          <p className="form-erroremployee">{errors.hospitalcode}</p>
                        ) : null}
                      </div>)}
                      {patientType !== "Registered" && (<div className="col-md-8">
                        {isAuthorized ? (
                            <HospitalCodeSearch onSearchItem={onSearchItems} />
                          // <select
                          //   type="text"
                          //   autoComplete="off"
                          //   name="hospitalcode"
                          //   id="hospitalcode"
                          //   placeholder="Hospital Code"
                          //   // value={patientType !== 'Registered' ? '' :values.hospitalcode}
                          //   value={values.hospitalcode}
                          //   onChange={handleChange}
                          //   onBlur={handleBlur}
                          //   onSearchChange={(e) => handleSearchChange(e.target.value)} // Pass the selected hospital code to handleSearchChange
                          //   style={{ width: '73%', height: '32px', border: '1px solid #ced4da', color: '#495057' }}
                          // >
                          //   <option>Select</option>
                          //   {data.map((code) => (
                          //     <option value={code.hospitalcode} key={code.id}>
                          //       {code.hospitalcode}
                          //     </option>
                          //   ))}
                          // </select>
                        ) : (
                          <input
                            type="hidden"
                            name="hospitalcode"
                            id="hospitalcode"
                            value={userHospitalcode}
                            disabled={true} // Set the input as disabled
                            className="form-control "
                          />
                        )}
                        {isAuthorized && errors.hospitalcode && touched.hospitalcode ? (
                          <p className="form-erroremployee">{errors.hospitalcode}</p>
                        ) : null}
                      </div>)}
                    </div></div>) : null}

                    <div className="col-md-12 mt-3">
                    <div className="row">
                      <div className="col-md-4">
                        <label
                          htmlFor="gaurdianname"
                          className="col-form-label "
                        >
                          Patient Guardian

                        </label>
                      </div>
                      <div className="col-md-8">
                        <input
                          type="text"
                          autoComplete="off"
                          name="gaurdianname"
                          id="gaurdianname"
                          placeholder="Patient Guardian"
                          // value={patientType !== 'Register' ? '' :values.gaurdianname}
                          value={values.gaurdianname}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          disabled={patientType === 'Registered'}
                          className="form-control"
                        />
                        {errors.gaurdianname && touched.gaurdianname ? (
                          <p className="col-form-label">{errors.gaurdianname}</p>
                        ) : null}
                      </div>
                    </div>

                  </div>
                  <div className="col-md-12 mt-3">
                    <div className="row">
                      <div className="col-md-4">
                        <label
                          htmlFor="bedid"
                          className="col-form-label leftone"
                        >
                          Bed Name
                        </label>
                      </div>
                      <div className="col-md-8">
                      <AvailableBedSearch
                      onSearchChangeBed={handleSearchBedChanges}
                      hospitalvalue={values.hospitalcode || userHospitalcode}
                      
                      />
                        {/* <select
                          type="text"
                          autoComplete="off"
                          name="bedid"
                          id="bedid"
                          placeholder="Bed Name"
                          value={values.bedid}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          style={{ width: '73%', height: '32px', border: '1px solid #ced4da ', color: '#495057' }}
                        >
                          <option value="">Select Bed Name</option>
                          {availableBedNumbers.map((bed) => (
                            
                            <option key={bed.bedid} value={bed.bedid}>
                              {bed.bedname}/{bed.bedtype}/{bed.groupname}/{bed.floorname }
                            </option>
                          ))}
                        </select> */}
                        
                        {errors.bedid && touched.bedid ? (
                          <p className="form-erroremployee">{errors.bedid}</p>
                        ) : null}
                      </div>
                    </div>
                  </div>

                  {/* Add more form fields as needed */}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='mb-3'>
          <Button type='submit' variant='primary'>
            Submit
          </Button>{' '}
          <Button variant='info' onClick={handleReset}>
            Reset
          </Button>
        </div>
      </Form>
      {/* </div> */}
      {/* </div> */}




    </div>
  );
};

export default PatientAdmit;