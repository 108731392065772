import React from 'react'
import Sidebar from '../../Component/Sidebar'
import { Mainpage } from './Mainpage'
import Header from '../../Component/Header/Header'
import { UserProvider } from '../../Provider';
export  const Dashboard = () => {
  return (
       <div className=" ">
     <UserProvider> <Header /></UserProvider>
    <div className="row" style={{ marginTop: '48px' }}>
      {/* Sidebar */}
      <div className="col-md-2">
      <UserProvider> <Sidebar /> </UserProvider>
      </div>
      {/* Main Content */}
      <div className="col-md-10">
     <UserProvider>
     <Mainpage />
     </UserProvider>
     
      </div>
    </div>
    </div>
    
  )
}
