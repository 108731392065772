import React, { useState, useEffect } from 'react';
import { Form, Button, Modal } from 'react-bootstrap';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import PrintTemplateBirth from "../../../Component/PrintTemplateBirth/PrintTemplateBirth"
import { NavLink, useHistory } from 'react-router-dom/cjs/react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from '../../../helper/axios'; // Assuming axios is used for API calls
import { Helmet } from 'react-helmet';
import Searchbar from '../../../Component/Searchbar';
import { useUser } from "../../../Provider";
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';

const ValidationSchema = Yup.object().shape({
  childname: Yup.string().required('Child Name is required'),
  fathername: Yup.string().required("Father's Name is required"),
  mothername: Yup.string().required("Mother's Name is required"),
  hospitalcode: Yup.string().required("Please select the hospitalcode"),
  gender: Yup.string().required('Gender is required'),
  dob: Yup.date().required('Date of Birth is required'),
  tob: Yup.string().required('Time of Birth is required'),
  religion: Yup.string().required('Religion is required'),
  weight: Yup.string().required('Weight is required'),
  phoneno: Yup.string()
  .matches(/^[0-9]+$/, "Mobile Number should only contain numbers")
  .min(10, "Mobile Number must be at least 10 characters")
  .max(10, "Mobile Number must be 10 characters")
  .required("Mobile Number is required"),
  village: Yup.string().matches(/^[a-zA-Z\s]+$/, "State should only contain letters").required('Village is required'),
  po: Yup.string().matches(/^[a-zA-Z\s]+$/, "State should only contain letters").required('Post Office is required'),
  ps: Yup.string().matches(/^[a-zA-Z\s]+$/, "State should only contain letters").required('Police Station is required'),
  district: Yup.string().matches(/^[a-zA-Z\s]+$/, "State should only contain letters").required('District is required'),
  state: Yup.string().matches(/^[a-zA-Z\s]+$/, "State should only contain letters").required('State is required'),
  pin: Yup.number()
    .test('is-valid-pin', 'Pin Code should only contain numbers', (value) => {
      // Customize the regular expression according to your validation requirements
      const pinPattern = /^[0-9]+$/;
      return pinPattern.test(value);
    })
    .required('Pin Code is required'),
});


const ValidationSchema1 = Yup.object().shape({
  childname: Yup.string().required('Child Name is required'),
  fathername: Yup.string().required("Father's Name is required"),
  mothername: Yup.string().required("Mother's Name is required"),
  // hospitalname: Yup.string().required("Please select the hospitalname"),
  gender: Yup.string().required('Gender is required'),
  dob: Yup.date().required('Date of Birth is required'),
  tob: Yup.string().required('Time of Birth is required'),
  religion: Yup.string().required('Religion is required'),
  weight: Yup.string().required('Weight is required'),
  phoneno: Yup.string()
  .matches(/^[0-9]+$/, "Mobile Number should only contain numbers")
  .min(10, "Mobile Number must be at least 10 characters")
  .max(10, "Mobile Number must be 10 characters")
  .required("Mobile Number is required"),
  village: Yup.string().matches(/^[a-zA-Z\s]+$/, "State should only contain letters").required('Village is required'),
  po: Yup.string().matches(/^[a-zA-Z\s]+$/, "State should only contain letters").required('Post Office is required'),
  ps: Yup.string().matches(/^[a-zA-Z\s]+$/, "State should only contain letters").required('Police Station is required'),
  district: Yup.string().matches(/^[a-zA-Z\s]+$/, "State should only contain letters").required('District is required'),
  state: Yup.string().matches(/^[a-zA-Z\s]+$/, "State should only contain letters").required('State is required'),
  pin: Yup.number()
    .test('is-valid-pin', 'Pin Code should only contain numbers', (value) => {
      // Customize the regular expression according to your validation requirements
      const pinPattern = /^[0-9]+$/;
      return pinPattern.test(value);
    })
    .required('Pin Code is required'),
});

const initialValues = {
    childname: "",
    fathername: "",
    mothername: "",
    hospitalcode: "",
    gender: "",
    dob: "",
    tob: "",
    religion: "",
    weight: "",
    phoneno: "",
    village: "",
    po: "",
    ps: "",
    district: "",
    state: "",
    pin: "",
  
};

const UpdateBith = () => {
  const {user, userHospitalcode, userrole1, isAuthRole,isAuthorized,gettokenId,token  } = useUser();
  // console.log(user, userHospitalcode, userrole1, isAuthRole,isAuthorized,gettokenId )
  const { birthnumber} = useParams();
  const [birthData, setBirthData] = useState([])
  const [patientType, setPatientType] = useState("Register");
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
 // const [gettokenId, setTokenId] = useState();
  const history = useHistory();
 // const [isAuthorized, setIsAuthorized] = useState(false);
  const [searchValue, setSearchValue] = useState("");
 // const [userHospitalcode, setUserHospitalcode] = useState();
 // const [userrole1, setUserrole1] = useState();
  const [isPopup, setIsPopup] = useState(false);
  //const [currentDateTime, setCurrentDateTime] = useState('');
  const [data, setData] = useState([]);
  const [birthid, setBid] = useState();

  let Showdate = new Date();
  let ShowTodaydate =
    Showdate.getFullYear() +
    "-" +
    (Showdate.getMonth() + 1) +
    "-" +
    Showdate.getDate();
  const [currentDate, setCurrentDate] = useState(ShowTodaydate);
  const getValidationSchema = () => {
    if (userHospitalcode === 1) {
      return ValidationSchema;

    } else  {
      return ValidationSchema1;
    }
  }
  const {
    values,
    errors,
    touched,
    setValues,
    handleChange,
    handleBlur,
    resetForm,
    handleSubmit,
  } = useFormik({
    initialValues,
   validationSchema: getValidationSchema,
    onSubmit: async (values, action) => {
      console.log(values); // Log the values to check if they are captured correctly

      try {
        
        if (userHospitalcode !== undefined && userrole1 !== undefined) {
          const data = new FormData();
           // ... append other fields as needed
          data.append("document", JSON.stringify(values));
          data.append('userHospitalcode', JSON.stringify(userHospitalcode));
          data.append('gettokenId', JSON.stringify(gettokenId));
          data.append('currentDate', JSON.stringify(currentDate));
          console.log(JSON.stringify(values));

          const response = await axios.post("/updatebirth", data, {
            headers: {
              Authorization: `Beaxrer ${token}`,
              "Content-Type": "multipart/form-data",
            },
            params: {
             // hospitalcode: userHospitalcode, // Include the hospitalcode in the query parameters
              userrole: userrole1, // Include the userrole in the query parameters
            },
          });
        if (response.status === 200) {
          setSuccess(true);
          setError(false);
          return history.push("/listviewbirth");;
        }
      } 
      } catch (error) {
        console.error('Error:', error.message);
        setSuccess(false);
        setError(true);
        toast.error('Something Went Wrong');
      }
      action.resetForm();
    },
  });
  // const handleSearchChange = async (searchTerm) => {
  //   console.log(searchTerm)
  //   setSearchValue(searchTerm); // Update the searchValue state with the search term
  //   // Assuming searchTerm contains patient information including hospital code
  //   const selectedHospitalCode = searchTerm.hospitalcode;

  //   // Call the function to fetch available bed numbers based on the selected hospital code
  //   // await getAvailableBedNumbers(selectedHospitalCode);
  // };

  useEffect(() => {
    const loadData = async () => {
      await loadHospitalcode();
      await getBirthDetails();
    };
    loadData();
  }, [userHospitalcode, userrole1, isAuthorized, values.hospitalcode]);
  const handleClosed = () => {
    setIsPopup(false)
    //window.location.reload();
  }

  const printForm = (values) => {
    if (values.childname.trim() === "") {
      setIsPopup(false); // Set isPopup to false if patientName is empty
      toast.error("Chaild Name is Mandatory")
    } else {
      setBid(values.birthid)
      setIsPopup(true)
      // window.open(`/AppoinmentForm/${value.patientid}`, "_blank")
    }
  }
  const loadHospitalcode = async () => {
    //when userHospitalcode and userrole1 is not undefined then execute /gethospitalcode
    if (userHospitalcode !== undefined && userrole1 !== undefined) {
      const res = await axios.get("/gethospitalcode", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          hospitalcode: userHospitalcode, // Include the hospitalcode in the query parameters
          userrole: userrole1, // Include the userrole in the query parameters
        },
      });
      setData(res.data);
      console.log(res.data);
    };
  }
  const getBirthDetails  = async () => {
    //when userHospitalcode and userrole1 is not undefined then execute /gethospitalcode
    if (userHospitalcode !== undefined && userrole1 !== undefined) {
      const res = await axios.get(`/GetBirthBybirth/${birthnumber}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          hospitalcode: userHospitalcode, // Include the hospitalcode in the query parameters
          userrole: userrole1, // Include the userrole in the query parameters
        },
      });
      setValues(res.data[0]);
      console.log(res.data);
    };
  }

  const currentDomain = window.location.hostname;

  return (
        <div className="p-3 pt-0 appointment">
           <Helmet>
            <title>Add Birth Record | {currentDomain} | Hospital Management Software | Smart Solutions for Hospital Efficiency</title>
          </Helmet>
          <form onSubmit={handleSubmit}>
            {success && <div>Add Birth Record</div>}
            {error && <div>Error in add birth record please try again</div>}
            <div class="fill header"><h3 class="float-left"> Add Birth Record </h3> </div>
            <div className="patient-new primary-info">
            <div className="row">
            <div className="col-md-12">
              <div className="row">
            <div className="col-md-6">
                
           <div className="col-md-12 mt-3">
                  <div className="row">
      
                    <div className="col-md-4">
                      <label
                        htmlFor="childname"
                        className="col-form-label leftone"
                      >
                       Child Name
                      </label>
                    </div>
                    <div className="col-md-8">
                        <input
                          type="text"
                          autoComplete="off"
                          name="childname"
                          id="childname"
                          placeholder="Child Name"
                          value={values.childname}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          style={{ width: '73%', height: '32px', border: '1px solid #ced4da', color: '#495057' }}
                        />                
                      {errors.childname && touched.childname ? (
                        <p className="form-erroremployee">{errors.childname}</p>
                      ) : null}
                    </div></div></div>
    
    
                    <div className="col-md-12 mt-3">
                  <div className="row">
      
                    <div className="col-md-4">
                      <label
                        htmlFor="fathername"
                        className="col-form-label leftone"
                      >
                       Father's Name
                      </label>
                    </div>
                    <div className="col-md-8">
                        <input
                          type="text"
                          autoComplete="off"
                          name="fathername"
                          id="fathername"
                          placeholder="Father Name"
                          value={values.fathername}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          style={{ width: '73%', height: '32px', border: '1px solid #ced4da', color: '#495057' }}
                        />              
                      {errors.fathername && touched.fathername ? (
                        <p className="form-erroremployee">{errors.fathername}</p>
                      ) : null}
                    </div></div></div>
    
                    <div className="col-md-12 mt-3">
                  <div className="row">
                    <div className="col-md-4">
                      <label
                        htmlFor="gender"
                        className="col-form-label leftone"
                      >
                        Gender
                      </label>
                    </div>
                    <div className="col-md-8">
                      <select
                        type="text"
                        autoComplete="off"
                        name="gender"
                        id="gender"
                        placeholder="Gender"
                        value={values.gender}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        style={{ width: '73%', height: '32px', border: '1px solid #ced4da ', color: '#495057' }}
                      >
                        <option>Select</option>
                        <option>Male</option>
                        <option>Female</option>
                        <option>Others</option>
                      </select>
                      {errors.gender && touched.gender ? (
                        <p className="form-erroremployee">{errors.gender}</p>
                      ) : null}
                    </div>
                  </div>
                </div>


                
                <div className="col-md-12 mt-3">
                  <div className="row">
    <div className="col-md-4">
                      <label
                        htmlFor="phoneno "
                        className="col-form-label leftone"
                      >
                        Mobile Number
                      </label>
                    </div>
                    <div className="col-md-8">
                      <input
                        type="number"
                        autoComplete="off"
                        name="phoneno"
                        id="phoneno"
                        placeholder="Mobile Number"
                        value={values.phoneno}
                        onChange={handleChange}
                        className="form-control ember-text-field text-left ember-view"
                      />
    
                      {errors.phoneno && touched.phoneno ? (
                        <p className="form-erroremployee">{errors.phoneno}</p>
                      ) : null}
                    </div></div></div>
                    
    
                <div className="col-md-12 mt-3">
                  <div className="row">
      
                    <div className="col-md-4">
                      <label
                        htmlFor="village"
                        className="col-form-label leftone"
                      >
                       Village 
                      </label>
                    </div>
                    <div className="col-md-8">
                        <input
                          type="text"
                          autoComplete="off"
                          name="village"
                          id="village"
                          placeholder="Village"
                          value={values.village}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          style={{ width: '73%', height: '32px', border: '1px solid #ced4da', color: '#495057' }}
                        />                
                      {errors.village && touched.village ? (
                        <p className="form-erroremployee">{errors.village}</p>
                      ) : null}
                    </div></div></div>
               
                             
                    <div className="col-md-12 mt-3">
                  <div className="row">
      
                    <div className="col-md-4">
                      <label
                        htmlFor="po"
                        className="col-form-label leftone"
                      >
                       Post Office
                      </label>
                    </div>
                    <div className="col-md-8">
                        <input
                          type="text"
                          autoComplete="off"
                          name="po"
                          id="po"
                          placeholder="Post Office"
                          value={values.po}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          style={{ width: '73%', height: '32px', border: '1px solid #ced4da', color: '#495057' }}
                        />                
                      {errors.po&& touched.po ? (
                        <p className="form-erroremployee">{errors.po}</p>
                      ) : null}
                    </div></div></div>
    
                <div className="col-md-12 mt-3">
                         <div className="row">
                         <div className="col-md-4">
                              <label htmlFor="weight" className="col-form-label leftone">
                                Weight
                              </label>
                            </div>
                     <div className="col-md-8">
                            <input
                              type="number"
                              autoComplete="off"
                              name="weight"
                              id="weight"
                              placeholder="Weight"
                              value={values.weight}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              style={{ width: '73%', height: '32px', border: '1px solid #ced4da ', color: '#495057' }}
                            />
    
                            {errors.weight && touched.weight ? (
                              <p className="form-erroremployee">{errors.weight}</p>
                            ) : null}
                          </div> 
                        </div>
                      </div>


                          
                <div className="col-md-12 mt-3">
                  <div className="row">
                    <div className="col-md-4">
                      <label
                        htmlFor="pin "
                        className="col-form-label leftone"
                      >
                        Pin Code
                      </label>
                    </div>
                    <div className="col-md-8">
                      <input
                        type="number"
                        autoComplete="off"
                        name="pin"
                        id="pin"
                        placeholder="Pin Code"
                        value={values.pin}
                        onChange={handleChange}
                        className="form-control ember-text-field text-left ember-view"
                      />
    
                      {errors.pin && touched.pin ? (
                        <p className="form-erroremployee">{errors.pin}</p>
                      ) : null}
                    </div></div></div>
    
              
              </div>
    
    
              <div className="col-md-6">
    
    
                  {isAuthorized ? (<div className="col-md-12 mt-3">
                  <div className="row">
      
                    <div className="col-md-4">
                      <label
                        htmlFor="hospitalcode"
                        className="col-form-label leftone"
                      >
                        Hospital code
                      </label>
                    </div>
                    <div className="col-md-8">
                    
                    {isAuthorized ? (
                        <input
                          type="text"
                          autoComplete="off"
                          name="hospitalcode"
                          id="hospitalcode"
                          placeholder="Hospital code"
                          value={values.hospitalcode}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          disabled
                        //  onSearchChange={(e) => handleSearchChange(e.target.value)} // Pass the selected hospital code to handleSearchChange
                        className="form-control "
                        />
                          
                      ) : (
                        <input
                          type="text"
                          name="hospitalcode"
                          id="hospitalcode"
                          value={values.hospitalcode}
                          disabled={true} // Set the input as disabled
                          className="form-control "
                        />
                      )}
                      {isAuthorized && errors.hospitalcode && touched.hospitalcode ? (
                        <p className="form-erroremployee">{errors.hospitalcode}</p>
                      ) : null}
                    </div></div></div>) : null}
    
                      <div className="col-md-12 mt-3">
                  <div className="row">
      
                    <div className="col-md-4">
                      <label
                        htmlFor="mothername"
                        className="col-form-label leftone"
                      >
                       Mother's Name
                      </label>
                    </div>
                    <div className="col-md-8">
                        <input
                          type="text"
                          autoComplete="off"
                          name="mothername"
                          id="mothername"
                          placeholder="Mother Name"
                          value={values.mothername}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          style={{ width: '73%', height: '32px', border: '1px solid #ced4da', color: '#495057' }}
                        />              
                      {errors.mothername && touched.mothername ? (
                        <p className="form-erroremployee">{errors.mothername}</p>
                      ) : null}
                    </div></div></div>

           <div className="col-md-12 mt-3">
             <div className="row">
                <div className="col-md-4">
                <label
                    htmlFor="religion"
                    className="col-form-label leftone"
                  >
                    Religion
                  </label>
                </div>
                <div className="col-md-8">
                  <select
                    type="text"
                    autoComplete="off"
                    name="religion"
                    id="religion"
                    placeholder="Religion"
                    value={values.religion}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    style={{ width: '73%', height: '32px', border: '1px solid #ced4da ', color: '#495057' }}
                  >
                    <option>Select</option>
                    <option>Hinduism</option>
                    <option>Islam</option>
                    <option>Christianity</option>
                  </select>
                {errors.religion && touched.religion ? (
                    <p className="form-erroremployee">{errors.religion}</p>
                  ) : null}
                </div>
              </div>
            </div>


    
                    <div className="col-md-12 mt-3">
                        <div className="row">
                          <div className="col-md-4">
                            <label
                              htmlFor="dob"
                              className="col-form-label leftone"
                            >
                              Date of Birth
                            </label>
    
                          </div>
                          <div className="col-md-8">
                            <input
                              type="date"
                              autoComplete="off"
                              name="dob"
                              id="dob"
                              placeholder="Date of Birth"
                              value={values.dob}
                              
                             onChange={handleChange}
                             onBlur={handleBlur}
                              // onChange={(e) => setCurrentDateTime(e.target.value)}
                              className="form-control"
                              
                            />
    
                            {errors.dob&& touched.dob ? (
                              <p className="form-erroremployee">
                                {errors.dob}
                              </p>
                            ) : null}
                          </div>
                        </div>
    
                      </div>
    
    
                      <div className="col-md-12 mt-3">
                        <div className="row">
                          <div className="col-md-4">
                            <label
                              htmlFor="tob"
                              className="col-form-label leftone"
                            >
                              Time of Birth
                            </label>
    
                          </div>
                          <div className="col-md-8">
                            <input
                              type="time"
                              autoComplete="off"
                              name="tob"
                              id="tob"
                              placeholder="Time of Birth"
                              value={values.tob}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              // onChange={(e) => setCurrentDateTime(e.target.value)}
                              className="form-control"
                            />
    
                            {errors.tob && touched.tob ? (
                              <p className="form-erroremployee">
                                {errors.tob}
                              </p>
                            ) : null}
                          </div>
                        </div>
    
                      </div>


                      <div className="col-md-12 mt-3">
                  <div className="row">
      
                    <div className="col-md-4">
                      <label
                        htmlFor="ps"
                        className="col-form-label leftone"
                      >
                       Police Station
                      </label>
                    </div>
                    <div className="col-md-8">
                        <input
                          type="text"
                          autoComplete="off"
                          name="ps"
                          id="ps"
                          placeholder="Police Station"
                          value={values.ps}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          style={{ width: '73%', height: '32px', border: '1px solid #ced4da', color: '#495057' }}
                        />                
                      {errors.ps && touched.ps ? (
                        <p className="form-erroremployee">{errors.ps}</p>
                      ) : null}
                    </div></div></div>
    
                    <div className="col-md-12 mt-3">
                  <div className="row">
      
                    <div className="col-md-4">
                      <label
                        htmlFor="district"
                        className="col-form-label leftone"
                      >
                       District
                      </label>
                    </div>
                    <div className="col-md-8">
                        <input
                          type="text"
                          autoComplete="off"
                          name="district"
                          id="district"
                          placeholder="District"
                          value={values.district}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          style={{ width: '73%', height: '32px', border: '1px solid #ced4da', color: '#495057' }}
                        />                
                      {errors.district && touched.district ? (
                        <p className="form-erroremployee">{errors.district}</p>
                      ) : null}
                    </div></div></div>
               

                    <div className="col-md-12 mt-3">
                  <div className="row">
      
                    <div className="col-md-4">
                      <label
                        htmlFor="state"
                        className="col-form-label leftone"
                      >
                       State
                      </label>
                    </div>
                    <div className="col-md-8">
                        <input
                          type="text"
                          autoComplete="off"
                          name="state"
                          id="state"
                          placeholder="State"
                          value={values.state}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          style={{ width: '73%', height: '32px', border: '1px solid #ced4da', color: '#495057' }}
                        />                
                      {errors.state && touched.state ? (
                        <p className="form-erroremployee">{errors.state}</p>
                      ) : null}
                    </div></div></div>
               
                </div>
              </div>
              </div>
            </div>
           
            <div className="col-md-6  mt-3">
              <Button
                type="submit"
                variant="success"
                className="mx-3"
              >
               Update
              </Button>
              {/* <Button type="submit" variant="primary" className="mx-3" onClick={() => printForm(values, "printandsave")}>
              Save And Print
            </Button> */}
            </div>
            
             </div>
          </form>
        </div>
      );
};

export default UpdateBith;
