import React from "react";
import { useFormik } from "formik";
import { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useHistory } from "react-router-dom";
import axios from "../../../helper/axios";
import { Table, Button, Modal, Form } from "react-bootstrap";
import { patientregistration } from "../../../helper/schemas";
import { ToastContainer, toast } from 'react-toastify';
import * as Yup from "yup";  // Import Yup for validation
import { useUser } from "../../../Provider";
import HospitalCodeSearch from "../../../Component/HospitalCodeSearch";
import SearchFloor from "../../../Component/SearchFloor";

// Define Yup validation schema for authorized and unauthorized users
const ValidationSchema = Yup.object().shape({
  groupname: Yup.string().required('Bed name is required'),
  hospitalcode: Yup.string().required("Please select the hospitalcode"),
  description: Yup.string().required("Please enter the description"),
  floorid: Yup.string().required("Please select the floor name"),
});

const ValidationSchema1 = Yup.object().shape({
  groupname: Yup.string().required('Bed name is required'),
  // hospitalcode: Yup.string().required("Please select the hospitalcode"),
  description: Yup.string().required("Please enter the description"),
  floorid: Yup.string().required("Please select the floor name"),
});

// Initial form values
const initialValues = {
  groupname: "",
  floorid: "",
  description: "",
  hospitalcode: "",
};

const AddBedGroup = () => {
  // Extract user information using custom hook
  const { user, userHospitalcode, userrole1, isAuthRole, isAuthorized, gettokenId, token } = useUser();
  const history = useHistory();
  const [message, setMessage] = useState("");
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [data, setData] = useState([]);
  const [userRole, setRole] = useState();
  const [floorNames, setFloorNames] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  let Showdate = new Date();
  let ShowTodaydate = Showdate.getFullYear() + "-" + (Showdate.getMonth() + 1) + "-" + Showdate.getDate();
  const [currentDate, setCurrentDate] = useState(ShowTodaydate);

  // Function to determine which Yup validation schema to use based on userHospitalcode
  const getValidationSchema = () => {
    if (userHospitalcode === 1) {
      return ValidationSchema;
    } else {
      return ValidationSchema1;
    }
  };

  // Formik hook to manage form state and handle form submission
  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    resetForm,
    setValues
  } = useFormik({
    initialValues,
    validationSchema: getValidationSchema(), // Apply validation schema
    onSubmit: async (values, action) => {
      try {
        const data = new FormData();
        data.append("document", JSON.stringify(values));
        data.append("userHospitalcode", JSON.stringify(userHospitalcode));

        const response = await axios.post("/addbedgroup", data, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        });

        if (response.status === 200) {
          return history.push("/listviewbedgroup");
        }
      } catch (error) {
        console.log(error);
        toast("Something Went Wrong");
      }
    },
  });
 //Hospitalcode search
 const onSearchItems = (searchTerm) => {
  console.log("------",searchTerm)
  setValues({
    ...values,
    hospitalcode: searchTerm.hospitalcode,
    // Update other fields as needed
  });
};
  // floor onsearch
  const handleFloorChanges = (searchTerms) => {
    console.log("---------------", searchTerms)
    setValues({
      ...values,
      floorid: searchTerms.floorid,
    })
  };
  // Function to reset the form
  const handleReset = () => {
    resetForm();
  };

  // Function to handle changes in the search term
  // const handleSearchChange = async (searchTerm) => {
  //   setSearchValue(searchTerm);
  //   const selectedHospitalCode = searchTerm.hospitalcode || userHospitalcode;
  //   //await fetchFloorNames(selectedHospitalCode);
  // };

  // Fetch floor names based on the selected hospital code
  useEffect(() => {
    const loadData = async () => {
    };
    loadData();
  }, [userHospitalcode, userrole1, isAuthorized, values.hospitalcode]);

  // Load hospital code information
  useEffect(() => {
    const loadData = async () => {
      await loadHospitalcode();
    };
    loadData();
  }, [userHospitalcode, userrole1, isAuthorized]);

  // Function to load hospital code information
  const loadHospitalcode = async () => {
    if (userHospitalcode !== undefined && userrole1 !== undefined) {
      const res = await axios.get("/gethospitalcode", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          hospitalcode: userHospitalcode,
          userrole: userrole1,
        },
      });
      setData(res.data);
    };
  }

  // Function to fetch floor names
  // const fetchFloorNames = async (selectedHospitalCode) => {
  //   try {
  //     const response = await axios.get("/getfloornames", {
  //       headers: {
  //         Authorization: `Bearer ${token}`,
  //       },
  //       params: {
  //         hospitalcode: selectedHospitalCode,
  //       },
  //     });

  //     if (response.status === 200) {
  //       setFloorNames(response.data);
  //     } else {
  //       console.error(`Failed to fetch available floor numbers with status: ${response.status}`);
  //     }
  //   } catch (error) {
  //     console.error('Error fetching available floor numbers:', error.message);
  //   }
  // };

  const currentDomain = window.location.hostname;

  return (
    <div className="p-3 pt-0 appointment">
      <Helmet>
        <title>Add Bed Group | {currentDomain} | Hospital Management Software | Smart Solutions for Hospital Efficiency</title>
      </Helmet>
      <form onSubmit={handleSubmit}>
        {success && <div>Add Bed Group</div>}
        {error && <div>Error in add bed group. please try again</div>}
        <div class="fill header"><h3 class="float-left"> Add Bed Group</h3> </div>
        <div className="patient-new primary-info">
          <div className="row">
            <div className="col-md-12">
              <div className="row">
                <div className="col-md-6">
                  <div className="col-md-12 mt-3">
                    <div className="row">
                      <div className="col-md-4">
                        <label
                          htmlFor="groupname"
                          className="col-form-label leftone"
                        >
                          Bed Group Name
                        </label>
                      </div>
                      <div className="col-md-8">
                        <input
                          type="text"
                          autoComplete="off"
                          name="groupname"
                          id="groupname"
                          placeholder="Group Name"
                          value={values.groupname}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          style={{ width: '73%', height: '32px', border: '1px solid #ced4da', color: '#495057' }}
                        />
                        {errors.groupname && touched.groupname ? (
                          <p className="form-erroremployee">{errors.groupname}</p>
                        ) : null}
                      </div>
                    </div>
                  </div>

                  <div className="col-md-12 mt-3">
                    <div className="row">
                      <div className="col-md-4">
                        <label
                          htmlFor="floorid"
                          className="col-form-label leftone"
                        >
                          Floor Name
                        </label>
                      </div>
                      <div className="col-md-8">
                      <SearchFloor
                           onFloorChange={handleFloorChanges}
                            hospitalvalue={values.hospitalcode || userHospitalcode}
                          />
                        {/* <select
                          type="text"
                          autoComplete="off"
                          name="floorid"
                          id="floorid"
                          placeholder="Floor Name"
                          value={values.floorid}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          style={{ width: '73%', height: '32px', border: '1px solid #ced4da', color: '#495057' }}
                        >
                          <option>Select</option>
                          {floorNames.map((code) => (
                            <option value={code.floorid} key={code.floorid}>
                              {code.floorname}
                            </option>
                          ))}
                        </select> */}
                        {errors.floorid && touched.floorid ? (
                          <p className="form-erroremployee">{errors.floorid}</p>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="col-md-12 mt-3">
                    <div className="row">
                      <div className="col-md-4">
                        <lab
                          htmlFor="description"
                          className="col-form-label leftone"
                        >
                          Description
                        </lab>
                      </div>
                      <div className="col-md-8">
                        <input
                          type="text"
                          autoComplete="off"
                          name="description"
                          id="description"
                          placeholder="Description"
                          value={values.description}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          style={{ width: '73%', height: '32px', border: '1px solid #ced4da ', color: '#495057' }}
                        />
                        {errors.description && touched.description ? (
                          <p className="form-erroremployee">{errors.description}</p>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  {isAuthorized ? (<div className="col-md-12 mt-3">
                    <div className="row">
                      <div className="col-md-4">
                        <label
                          htmlFor="hospitalcode"
                          className="col-form-label leftone"
                        >
                          Hospital Code
                        </label>
                      </div>
                      <div className="col-md-8">
                        {isAuthorized ? (
                        <HospitalCodeSearch onSearchItem={onSearchItems} />
                          // <select
                          //   type="text"
                          //   autoComplete="off"
                          //   name="hospitalcode"
                          //   id="hospitalcode"
                          //   placeholder="Hospital Code"
                          //   value={values.hospitalcode}
                          //   onChange={handleChange}
                          //   onBlur={handleBlur}
                          //   onSearchChange={(e) => handleSearchChange(e.target.value)}
                          //   style={{ width: '73%', height: '32px', border: '1px solid #ced4da', color: '#495057' }}
                          // >
                          //   <option>Select</option>
                          //   {data.map((code) => (
                          //     <option value={code.hospitalcode} key={code.id}>
                          //       {code.hospitalcode}
                          //     </option>
                          //   ))}
                          // </select>
                        ) : (
                          <input
                            type="text"
                            name="hospitalcode"
                            id="hospitalcode"
                            value={userHospitalcode}
                            disabled={true}
                            className="form-control "
                          />
                        )}
                        {isAuthorized && errors.hospitalcode && touched.hospitalcode ? (
                          <p className="form-erroremployee">{errors.hospitalcode}</p>
                        ) : null}
                      </div>
                    </div>
                  </div>) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-6  mt-3">
          <Button
            type="submit"
            variant="success"
            className="mx-3"
          >
            Save
          </Button>
          <Button
            variant="primary"
            className="mx-3"
            onClick={handleReset}
          >
            Reset
          </Button>
        </div>
      </form>
    </div>
  );
};

export default AddBedGroup;