import React, { useRef, useEffect, useState } from "react";
import { useReactToPrint } from "react-to-print";
import image from "./PrintTemplateImageLogo.png";
import { useParams, useHistory } from "react-router-dom";
import "./PrintTemplateBillingViewCss.css";
import { Grid } from "@mui/material";
import axios from "../../helper/axios";
import moment from "moment/moment";
import API from "../../helper/axios";
const PrintTemplateBillingViewPharmacy = (props) => {
  const history = useHistory();
  const params = useParams();
  const today = new Date();
  const token = localStorage.getItem("jwtToken");
  const [patientData, setPatientData] = useState({});
  const [billItems, setBillItems] = useState([
    {
      medicinebillingid: 0,
      medicinecode: 0,
    },
  ]);
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: patientData.medicinebillingdetailsid,
    onAfterPrint: props.handleClose,
  });

  const getPatientDetails = async () => {
    try {
      console.log(props, "---");

      const res = await axios.get(`/getbillingpharmacy/${props.medicinebillingid}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (res.status === 200) {
        // If the request is successful (status code 200), set the patient data.
        setPatientData(res.data[0]);
      } else {
        // Handle unexpected response status codes, e.g., 404 or 500.
        alert(`Unexpected status code: ${res.status}`);
      }
    } catch (error) {
      // Handle network errors or other exceptions.
      alert(`An error occurred: ${error.message}`);
      // Optionally, you can set an error state or display an error message to the user.
    }
  };

  const getPatientBillDetails = async () => {
    try {
      const res = await axios.get(
        `/patients/${props.patientId}/billingpharmacy/${props.medicinebillingid}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (res.status === 200) {
        // If the request is successful (status code 200), set the bill items data.
        setBillItems(res.data);
      } else {
        // Handle unexpected response status codes, e.g., 404 or 500.
        alert(`Unexpected status code: ${res.status}`);
      }
    } catch (error) {
      // Handle network errors or other exceptions.
      alert(`An error occurred: ${error.message}`);
      // Optionally, you can set an error state or display an error message to the user.
    }
  };

  useEffect(() => {
    getPatientDetails();
    getPatientBillDetails();
    return () => { };
  }, []);
  const [data, setData] = useState([]);
  const [userHospitalcode, setUserHospitalcode] = useState();
  const [userrole1, setUserrole1] = useState();
  const [isAuthorized, setIsAuthorized] = useState(false);
  // Function to get login user's details
  const loadAllDetails = async () => {
    try {
      const response = await axios.post('/loginuser', null, {
        headers: { Authorization: `Bearer ${token}` },
      });

      // Debugging: Log the entire response
      console.log("Response:", response);

      const UserRoles = response.data.person[0][0].role1;
      const hospitalcode = response.data.person[0][0].hospitalcode;

      // Debugging: Log the values of userHospitalcode and userrole1
      console.log("userHospitalcode:", hospitalcode);
      console.log("userrole1:", UserRoles);

      setUserHospitalcode(hospitalcode);
      setUserrole1(UserRoles);
      // Debugging: Log the condition result
      const authorizationResult = hospitalcode === 1 && UserRoles === 'Superadmin';
      console.log("Authorization Result:", authorizationResult);
      setIsAuthorized(authorizationResult);
      console.log("isAuthorized after setIsAuthorized:", isAuthorized);
    } catch (error) {
      console.error("Error:", error);

      if (error.response.status === 403) {
        alert('Token Expired. Please login again.');
        localStorage.removeItem('jwtToken');
        history.push('/');
      } else if (error.response.status === 401) {
        alert('Invalid token. Please login again.');
        localStorage.removeItem('jwtToken');
        history.push('/');
      } else {
        alert('Error: ' + error.response.data.error);
      }
    }
  };
  //when page load or refresh happen this function will got called
  useEffect(() => {
    const loadData = async () => {
      await loadAllDetails();
      //loadHospitalLogo(); will wait for loadAllDetails(); to got execute
      if (patientData.hospitalcode) {
        await loadHospitalLogo();
      }
    };
    loadData();
  }, [userHospitalcode, userrole1, isAuthorized, patientData]);
  //get all hospital code
  const loadHospitalLogo = async () => {
    //when userHospitalcode is not undefined
    if (userHospitalcode !== undefined) {
      console.log(userHospitalcode)
      console.log(patientData.hospitalcode)
      const res = await axios.get(`/gethospitallogo/${patientData.hospitalcode}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          hospitalcode: patientData.hospitalcode, // Include the hospitalcode in the query parameters
          //userrole: userrole1, // Include the userrole in the query parameters
        },
      });
      setData(res.data[0]);
      console.log(res.data);
    };
  }
  return (
    <>
      <button
        className="btn btn-success"
        style={{
          float: "right",
          backgroundColor: "white",
          color: "green",
          marginRight: "20px",
        }}
        onClick={handlePrint}
      >
        Print Bill
      </button>
      <div
        // className="pass-title"
        ref={componentRef}
        style={{ width: "100%", height: window.innerHeight, padding: "40px" }}
      >
        <div style={{ textAlign: "center", marginTop: "-10px" }}>
          {data.attachment2 ? (
            <img
              src={`${API.defaults.baseURL}/uploadimage/attachtwo/${data.attachment2}`}
              style={{ width: '100%', marginBottom: "1px", height: "20%" }}
              alt="Attachment 2"
            />
          ) : (
            <div>
              <img
                src={`${API.defaults.baseURL}/uploadimage/hospitallogo/${data.hospitallogo}`}
                style={{ width: "15%", marginBottom: "5px", float: "left" }}
                alt="Hospital Logo"
              />
              <h4 style={{ textAlign: "center" }}>
                {data.hospitalname ? data.hospitalname.toUpperCase() : "HOSPITAL NAME NOT AVAILABLE"}
              </h4>
              <h6 style={{ textAlign: "center" }}>
                {data.address}
              </h6>
              <h6 style={{ textAlign: "center" }}>
                Mobile: +91-{data.phone}
              </h6>
            </div>
          )}
        </div>

        <div style={{ textAlign: "center", marginTop: "-10x" }}>
          <b>Receipt</b>
        </div>
        {/* <div class="box"></div> */}
        <div style={{ border: "1px solid black" }}>
          <div style={{ padding: "12px" }}>
            <Grid container spacing={2} style={{ fontSize: "12px" }}>
              <Grid item xs={6}>
                <b>Bill Number :- </b>
                {billItems[0].medicinebillingid}
              </Grid>

              <Grid item xs={6}>
                <b>Booking Date and Time :- </b>
                {moment(patientData.createdon).format("DD/MM/YYYY hh:mm A")}
              </Grid>
            </Grid>
            <Grid container spacing={2} style={{ fontSize: "12px" }}>
              <Grid item xs={6}>
                <b>Name :- </b>
                {patientData.patientname}
              </Grid>

              <Grid item xs={6}>
                <b>Address :- </b>
                {patientData.address}
              </Grid>
            </Grid>
            <Grid container spacing={2} style={{ fontSize: "12px" }}>
              <Grid item xs={6}>
                <b>Mobile :- </b>
                {patientData.phoneno}
              </Grid>

              <Grid item xs={6}>
                <b>Email :- </b>NA
              </Grid>
            </Grid>
          </div>
          <div class="box"></div>
          <div>

            <div style={{ padding: "12px" }}>
              <table
                style={{
                  width: "100%",
                }}
              >
                <thead style={{ fontSize: "12px" }}>
                  <tr>
                    <th>SNo.</th>
                    <th>Description</th>
                    <th>Quantity</th>
                    <th>Saleprice</th>
                    <th>Tax</th>
                    <th>Rate</th>
                  </tr>
                </thead>
                <tbody style={{ fontSize: "12px" }}>
                  {billItems.map((item, index) => {
                    if (item.medicinecode.length != 0) {
                      return (
                        <tr key={index} style={{ borderTop: "1px solid black" }}>
                          <td>{index + 1}</td>
                          <td>{item.medicinename}</td>
                          <td>{item.quantity}</td>
                          <td>{parseFloat(item.saleprice).toFixed(2)}</td>
                          <td>{parseFloat(item.taxs).toFixed(2)}</td>
                          <td>{parseFloat(item.amount).toFixed(2)}</td>
                        </tr>
                      );
                    }
                  })}
                </tbody>
              </table>
            </div>
            {/* <div class="box"></div> */}
            <hr style={{ marginTop: "0px" }} />
            {/* <div className="vl"></div> */}
            <div>
              <div
                style={{
                  float: "right",
                  fontSize: "12px",
                  //   marginRight: "-1px",
                  //   marginRight: "75px",
                  // paddingRight: "75px",
                  marginTop: "-17px",
                  clear: "both",
                }}
              >
                <table
                  style={{
                    width: "100%",
                    borderLeft: "1px solid black",
                    clear: "both",
                  }}
                >
                  <tr>
                    <th
                      style={{ borderRight: "1px solid black", padding: "2.5px", paddingRight: "75px" }}
                    >
                      Total Amount
                    </th>
                    <td style={{ padding: "2.5px", paddingRight: "75px" }}>
                      {parseFloat(billItems[0].total).toFixed(2)}
                    </td>
                  </tr>
                  <tr>
                    <th
                      style={{ borderRight: "1px solid black", padding: "2.5px" }}
                    >
                      Extra Amount
                    </th>
                    <td style={{ padding: "2.5px" }}>
                      {parseFloat(billItems[0].extracharge).toFixed(2)}</td>
                  </tr>
                  <tr>
                    <th
                      style={{ borderRight: "1px solid black", padding: "2.5px" }}
                    >
                      Sub Total
                    </th>
                    <td style={{ padding: "2.5px" }}>
                      {parseFloat(billItems[0].finalamount).toFixed(2)}
                    </td>
                  </tr>
                  {/* <tr> */}
                  <tr style={{ borderBottom: "1px solid black" }}>
                    <th
                      style={{ borderRight: "1px solid black", padding: "2.5px" }}
                    >
                      Paid
                    </th>
                    <td style={{ padding: "2.5px" }}>
                      {parseFloat(billItems[0].finalamount).toFixed(2)}
                    </td>
                  </tr>
                  {/* <hr style={{width: "250%", border: "1px solid black", marginTop: "0px", marginBottom: "0px"}}/> */}
                  {/* <hr style={{width: "10rem", border: "1px solid black", marginTop: "0px", marginBottom: "0px"}}/> */}
                  <tr>
                    <th
                      style={{ borderRight: "1px solid black", padding: "2.5px" }}
                    >
                      Balance
                    </th>
                    <td style={{ padding: "2.5px" }}>0.00</td>
                  </tr>
                </table>
              </div>
            </div>
            <div style={{ marginTop: "4.75rem", fontSize: "12px", padding: "12px" }}>
              <b>Authorized Signature</b>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PrintTemplateBillingViewPharmacy;
