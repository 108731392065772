import React, { useState, useEffect } from 'react';
import { Typography, Button, TextField, Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import axios from "../../../helper/axios";
import { useFormik } from "formik";
import {Nursenotevalidation} from "../../../helper/schemas";
import { useUser } from "../../../Provider";
import { useParams } from 'react-router-dom/cjs/react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SearchNurse from '../../../Component/SearchNurse';
const initialValues = {
      date:new Date().toISOString().slice(0, 16),
      nurseid:"",
      note:"",
      comment:""  
};
const NurseNoteForm = ({ onNoteAdded }) => {
    const { admitnumber } = useParams();
    console.log({ admitnumber })
    const { user, userHospitalcode, userrole1, isAuthRole, isAuthorized, gettokenId,token,userdesignation } = useUser();
    console.log(user, userHospitalcode, userrole1, isAuthRole, isAuthorized, gettokenId)
    const [isFormVisible, setFormVisibility] = useState(false);
    const [nurse, setNurse] = useState([]);
    const [data, setData] = useState([]);
    //const token = sessionStorage.getItem("jwtToken");
    const [currentDateTime, setCurrentDateTime] = useState('');
    const handleAddNote = () => {
        setFormVisibility(!isFormVisible); // Toggle form visibility
    };
    const {
        values,
        errors,
        touched,
        handleBlur,
        handleChange,
        handleReset,
        handleSubmit,
        resetForm,
        setValues,
      } = useFormik({
        initialValues,
        validationSchema: Nursenotevalidation,
        onSubmit: async (values, action) => {
            try {
      
            //  if (userHospitalcode !== undefined) {
                const data = new FormData();
                data.append("document", JSON.stringify(values));
                data.append("currentDate", JSON.stringify(currentDateTime));
                const response = await axios.post(`/nursenote/${admitnumber}`, data, {
                  headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "multipart/form-data",
                  },
                  params: {
                    userDesignation: userdesignation,
                  },
                });
                if (response.status === 200) {
                    onNoteAdded();
                 toast.success("Data Saved")
                }
            } catch (err) {
                toast.error("Only Nurse can enter the note")   
            }
            action.resetForm();
          },
      
        });
        // const getNurseDetails = async () => {

        //     try {
        //         if (userHospitalcode !== undefined && userrole1 !== undefined) {
        //             const res = await axios.get(`/getnurse`, {
        //                 headers: {
        //                     Authorization: `Bearer ${token}`,
        //                 },
        //                 params: {
        //                     hospitalcode: data.hospitalcode, // Include the hospitalcode in the query parameters
        //                     userrole: userrole1, // Include the userrole in the query parameters
        //                 },
        //             });
        //             console.log(res.data)
        //             // Check if the response status code is  200
        //             if (res.status === 200) {
        //                 setNurse(res.data);
        //             } else {
        //                 // Handle unexpected response status codes (e.g., 404, 500, etc.) as errors.
        //                 alert(`Request failed with status: ${res.status}`);
        //                 // Optionally, you can set an error state or display an error message to the user.
        //             }
        //         }
        //     }
        //     catch (error) {
        //         // Handle network errors or other exceptions.
        //        console.log(`An error occurred: ${error.message}`);
        //         // Optionally, you can set an error state or display an error message to the user.
        //     }
        // };
        const loadHospitalcode = async () => {
            //when userHospitalcode and userrole1 is not undefined then execute /gethospitalcode
            if (userHospitalcode !== undefined && userrole1 !== undefined) {
              const res = await axios.get(`/getIPDhospitalcode/${admitnumber}`, {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
                params: {
                  hospitalcode: userHospitalcode, // Include the hospitalcode in the query parameters
                  userrole: userrole1, // Include the userrole in the query parameters
                },
              });
              setData(res.data[0]);
              console.log(res.data);
            };
          }
          const handleSearchChanges = (searchTerms) => {
            console.log("---------------",searchTerms)
            setNurse(searchTerms); // Update the searchValue state with the search term
            setValues({
                ...values,
                nurseid:searchTerms.nurseid
             })
          };
        useEffect(() => {
            const loadData = async () => {
               // await getNurseDetails(data.hospitalcode);
               await loadHospitalcode();
            };
         
            loadData();
             // Function to get the current date and time
     const getCurrentDateTime = () => {
        const now = new Date();
        const year = now.getFullYear();
        const month = String(now.getMonth() + 1).padStart(2, '0');
        const day = String(now.getDate()).padStart(2, '0');
        const hours = String(now.getHours()).padStart(2, '0');
        const minutes = String(now.getMinutes()).padStart(2, '0');
        const currentDateTimeString = `${year}-${month}-${day}T${hours}:${minutes}`;
        return currentDateTimeString;
      };
  
      // Set the current date and time when the component mounts
      setCurrentDateTime(getCurrentDateTime());
        }, [userHospitalcode, userrole1, isAuthorized,data.hospitalcode]);
        
    return (
        <div>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography variant="h6" style={{ padding: '20px' }}>
                    Nurse Note
                </Typography>
                <Button variant="contained" color="primary" onClick={handleAddNote}>
                    {isFormVisible ? 'Close Form' : 'Add Nurse Note'}
                </Button>
            </div>
            {isFormVisible && (
                <form onSubmit={handleSubmit} style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                    <div className="patient-new primary-info">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="col-md-12 mt-3">
                                            <div className="row">
                                                <div className="col-md-4">
                                                    <label
                                                        htmlFor="date"
                                                        className="col-form-label leftone"
                                                    >
                                                        Date
                                                    </label>
                                                </div>
                                                <div className="col-md-8">
                                                    <input
                                                        type="datetime-local"
                                                        autoComplete="off"
                                                        name="date"
                                                        id="date"
                                                        placeholder="date"
                                                        value={currentDateTime}
                                                          onChange={handleChange}
                                                          onBlur={handleBlur}

                                                        className="form-control"
                                                    />
                                                    {errors.date && touched.date ? (
                    <p className="form-erroremployee">{errors.date}</p>
                  ) : null}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-12 mt-3">
                                            <div className="row">
                                                <div className="col-md-4">
                                                    <label
                                                        htmlFor="note"
                                                        className="col-form-label leftone "
                                                    >
                                                        Note
                                                    </label>
                                                </div>
                                                <div className="col-md-8">
                                                    <textarea
                                                        // style={{ width: "210px", height: "50px" }}
                                                        rows="1"
                                                        cols="30"
                                                        type="text"
                                                        autoComplete="off"
                                                        name="note"
                                                        id="note"
                                                        placeholder="note"
                                                        value={values.note}
                                                          onChange={handleChange}
                                                          onBlur={handleBlur}

                                                        className="form-control"
                                                    /> {errors.note && touched.note ? (
                                                        <p className="form-erroremployee">{errors.note}</p>
                                                      ) : null}
                                                </div>
                                            </div>
                                        </div>
                                       </div>
                                    <div className="col-md-6">
                                        <div className="col-md-12 mt-3">
                                            <div className="row">
                                                <div className="col-md-4">
                                                    <label
                                                        htmlFor="nurseid"
                                                        className="col-form-label leftone "
                                                    >
                                                        Nurse
                                                    </label>
                                                </div>
                                                <div className="col-md-8">
                                                <SearchNurse
                          onSearchChanges={handleSearchChanges}
                          hospitalvalue={data.hospitalcode}
                        />
                                                    {/* <select
                                                        type="text"
                                                        autoComplete="off"
                                                        name="nurseid"
                                                        id="nurseid"
                                                        placeholder="Nurse"
                                                        value={values.nurseid}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        style={{ width: '73%', height: '32px', border: '1px solid #ced4da ', color: '#495057' }}
                                                    >
                                                        <option>
                                                            Select
                                                        </option>
                                                        {nurse.map((nurse) => (
                                                            <option
                                                                value={nurse.nurseid}
                                                                key={nurse.fname}
                                                            >
                                                                {nurse.fname}
                                                            </option>
                                                        ))}
                                                    </select> */}
                                                    {errors.nurseid && touched.nurseid ? (
                                                        <p className="form-erroremployee">{errors.nurseid}</p>
                                                    ) : null}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-12 mt-3">
                                            <div className="row">
                                                <div className="col-md-4">
                                                    <label
                                                        htmlFor="comment"
                                                        className="col-form-label leftone "
                                                    >
                                                        Comment
                                                    </label>
                                                </div>
                                                <div className="col-md-8">
                                                    <textarea
                                                        // style={{ width: "210px", height: "50px" }}
                                                        rows="1"
                                                        cols="30"
                                                        type="text"
                                                        autoComplete="off"
                                                        name="comment"
                                                        id="comment"
                                                        placeholder="comment"
                                                        value={values.comment}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}

                                                        className="form-control"
                                                    />
                                                    {errors.comment && touched.comment ? (
                                                        <p className="form-erroremployee">{errors.comment}</p>
                                                    ) : null}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-11 mt-6 text-center">
                        <Button type="submit" variant="contained" color="primary" size="small" style={{ width: 'auto' }}>
                            Submit
                        </Button>
                    </div>
                    <ToastContainer/>
                </form>
            )}
        </div>
    );
};

export default NurseNoteForm;



