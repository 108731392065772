
import React from "react";
import { useFormik } from "formik";
import { useState, useEffect } from "react";


import { Helmet } from "react-helmet";
// import { BsFillEyeFill } from "react-icons/bs";
import { useHistory } from "react-router-dom";
import axios from "../../../helper/axios";
import { Table, Button, Modal, Form } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
const initialValues = {
 chargestype: "",
};
const AddCharge = () => {
  const history = useHistory();
  const token = localStorage.getItem("jwtToken");
  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleReset,
    handleSubmit,
  } = useFormik({
    initialValues,
    // validationSchema: empsignUpSchema,
    //creating new patient
    onSubmit: async (values, action) => {
      // const data = new FormData();
      // data.append("typee", JSON.stringify(typee));
      // console.log(typee);
      console.log(values);
      const response = await axios.post("/ChargesType", values, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
      
      if (response.status === 200) {
        return history.push("/allcharges");
      }
    },
  });
  useEffect(() => {
    // if(!Cookies.get('jwtToken')){
    //   return history.push("/");
    // }
  }, []);
  const currentDomain = window.location.hostname;
    return (
      <div className="pt-3 appointment  p-5">
         <Helmet>
        <title>Add New Charges | {currentDomain} | Hospital Management Software | Smart Solutions for Hospital Efficiency</title>
      </Helmet>
      <h4>Add Charges</h4>
      <Form onSubmit={handleSubmit}>
        
         <div className="row  ml-4">
     <div className="col-md-12 mt-3">
     <div className="row">
      <div className="col-md-2">
      <label
              htmlFor="Charge Name"
              className="input-labelemployee "
            >
              Charge Name
            </label>
      </div>
      <div className="col-md-4">
      <input
                type="text"
                autoComplete="off"
                name="patientName"
                id="patientName"
                placeholder="Charge Name"
                value={values.patientName}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {errors.patientName && touched.patientName ? (
                <p className="form-erroremployee">{errors.patientName}</p>
              ) : null}
              {errors.OPDType && touched.OPDType ? (
                <p className="form-erroremployee">
                  {errors.OPDType}
                </p>
              ) : null}
      </div>
      {values.OPDType !== "New" && ( <div className="col-md-2">
      <label
              className="input-labelemployee "
            >
             Charge Category 
            </label>
      </div>)}
      {values.OPDType !== "New" && (
      <div className="col-md-4">
     <select
                type="text"
                autoComplete="off"
                name="doctorName"
                id="doctorName"
                placeholder="Doctor Name"
                value={values.doctorName}
                onChange={handleChange}
                onBlur={handleBlur}
              >
                <option>Select</option>
                <option></option>
                <option></option>
              </select>
              {errors.doctorName && touched.doctorName ? (
                <p className="form-erroremployee">
                  {errors.doctorName}
                </p>
              ) : null}
      </div>)}
     </div>
    
  </div>
  <div className="col-md-12 mt-3">
     <div className="row">
      <div className="col-md-2">
      <label
              htmlFor="patientName"
              className="input-labelemployee leftone"
            >
             Tax(%)
            </label>
      </div>
      <div className="col-md-4">
      <input
  type="text"
  autoComplete="off"
  name="Tax"
  id="Tax"
  placeholder="Tax"
  value={values.Tax || "18"}
  onChange={handleChange}
  onBlur={handleBlur}
/>
{errors.Tax && touched.Tax ? (
  <p className="form-erroremployee">{errors.Tax}</p>
) : null}
      </div>
      <div className="col-md-2">
      <label
              htmlFor="standardcharge"
              className="input-labelemployee leftone"
            >
             Standard Charge
            </label>
      </div>
      <div className="col-md-4">
      <input
                type="text"
                autoComplete="off"
                name="standardcharge"
                id="standardcharge"
                placeholder="standardcharge"
                value={values.standardcharge}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {errors.standardcharge && touched.standardcharge ? (
                <p className="form-erroremployee">{errors.standardcharge}</p>
              ) : null}
      </div>
     </div>
    
  </div>
  <div className="col-md-11 mt-5 d-flex justify-content-end">
  <Button type="submit" variant="primary" className="">
    Save
  </Button>
  <Button type="submit" variant="danger" className="mx-5" >
    Reset
  </Button>
</div>
</div>
   
      </Form>
      </div>
    );
};

export default AddCharge;




