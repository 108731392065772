
// import React, { useState } from 'react';
import React, { useState, useEffect } from "react";
import { Table, Button, Modal, Form } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

import axios from "../../../helper/axios";

import { Helmet } from "react-helmet";
import { NavLink, useHistory } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';

const AllChargesComponent = () => {
  const history = useHistory();
  const [data, setData] = useState([]);
  const loadAllChargesType = async () => {
    try {
      const token = sessionStorage.getItem("jwtToken");
      const res = await axios.get("/", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setData(res.data);
      console.log(res.data);
    } catch (error) {
      // Handle the error, e.g., display an error message or log it
      console.error("Axios error:", error);
    }
  };
  

  useEffect(() => {
    // if (!sessionStorage.getItem("jwtToken")) {
    //   return history.push("/");
    // }
    loadAllChargesType();
  }, []);
  const currentDomain = window.location.hostname;
  return (
    <section className="content p-4">
       <Helmet>
        <title>ALL Charges Records | {currentDomain} | Hospital Management Software | Smart Solutions for Hospital Efficiency</title>
      </Helmet>
      <div className="col-md-12">
        <div className="box box-primary">
          <div className="box-header with-border">
            <div className="d-flex justify-content-between align-items-center">
              <h5 className="box-title titlefix">Charges Details List</h5>
            <NavLink to= "/createcharges"> <button
                className="btn btn-primary btn-sm pathology addtest">
                Add Charges
              </button></NavLink> 
            </div>
          </div>
          <div className="box-body mt-3">
            <div className="table-responsive">
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Charge Type</th>
                    <th>Tax(%)</th>
                    <th>Standard Charge</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                    {/* Add table rows here when you have data */}
                    {data.map((value, key) => (
                      <tr key={key}>
                       <td>{key + 1}</td>
                       
                        <td>{value.chargetype}</td>
                       
                       
                       
                      </tr>
                    ))}
                  </tbody>
              </Table>
            </div>
          </div>
        </div>
      </div>
        
    </section>
  );
};

export default AllChargesComponent;




