import React from "react";
import { useFormik } from "formik";
import { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useHistory } from "react-router-dom";
import axios from "../../../helper/axios";
import { Table, Button, Modal, Form } from "react-bootstrap";
import { ParametervalidationSchema } from "../../../helper/schemas";
import { ParametervalidationSchema2 } from "../../../helper/schemas";
import { ToastContainer, toast } from 'react-toastify';
import * as Yup from "yup";  // Import Yup for validation
import { useUser } from "../../../Provider";
import HospitalCodeSearch from "../../../Component/HospitalCodeSearch";
import SearchUnit from '../../../Component/SearchUnit';
import SearchPathologyCategory from '../../../Component/SearchPathologyCategory';



const initialValues = {
  chargecode: "",
  parametername: "",
  referencerange: "",
  hospitalcode: "",
  unitid: "",
  description: "",
};

const AddPathology = () => {
  const { user, userHospitalcode, userrole1, isAuthRole, isAuthorized, gettokenId, token } = useUser();
  const history = useHistory();
  const [message, setMessage] = useState("");
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [category, setCategory] = useState([]);
  const [unit1, setUnit] = useState([]);
  // initialised isAuthorized value as false
  // const [isAuthorized, setIsAuthorized] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  //get jwttoken from sessionStorage
  // const token = sessionStorage.getItem("jwtToken");
  //get current date
  let Showdate = new Date();
  let ShowTodaydate =
    Showdate.getFullYear() +
    "-" +
    (Showdate.getMonth() + 1) +
    "-" +
    Showdate.getDate();
  const [currentDate, setCurrentDate] = useState(ShowTodaydate);

  const getValidationSchema = () => {
    if (userHospitalcode === 1) {
      return ParametervalidationSchema;

    } else {
      return ParametervalidationSchema2;
    }
  }

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    resetForm,
    setValues
  } = useFormik({
    initialValues,
    validationSchema: getValidationSchema, // Apply validation schema
    onSubmit: async (values, action) => {
      try {
        const data = new FormData();
        data.append("document", JSON.stringify(values));
        data.append("currentDate", JSON.stringify(currentDate));
        data.append("gettokenId", JSON.stringify(gettokenId));
        data.append("userHospitalcode", JSON.stringify(userHospitalcode));
        console.log(JSON.stringify(values));
        //   console.log(JSON.stringify(hospitalcode));

        const response = await axios.post("/addpathology", data, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        });
        console.log(
          "🚀 ~ file: Registration.jsx ~ line 11 ~ Registration ~ values",
          values
        );
        console.log(
          "🚀 ~ file: Registration.jsx ~ line 11 ~ Registration ~ values",
          response.status
        );
        if (response.status === 200) {
          return history.push("/listviewpathology");
        }
      } catch (error) {
        console.log(error)
        toast("Something Went Wrong")
      }
    },
  });


  // const handleSearchChange = async (searchTerm) => {
  //   setSearchValue(searchTerm);
  //   const selectedHospitalCode = searchTerm.hospitalcode || userHospitalcode;
  //   await fetchUnitName(selectedHospitalCode); // Fetch floor names based on the selected hospital code
  // };
  const handleReset = () => {
    // Reset the form using Formik's resetForm function
    resetForm();
  };
  const [data, setData] = useState([]);
  const [userRole, setRole] = useState();

  const [searchValues, setSearchValues] = useState("");
  //Hospital code search
  const onSearchItems = (searchTerm) => {
    console.log("------", searchTerm)
    setSearchValues(searchTerm);
    if (searchTerm && searchTerm.hospitalcode) {
      setValues({
        ...values,
        hospitalcode: searchTerm.hospitalcode,
        // Update other fields as needed
      });
    }
  };

  //get Unit
  const handleSearchChanges = (searchTerms) => {
    console.log("---------------",searchTerms)
    setSearchValues(searchTerms); // Update the searchValue state with the search term
    if (searchTerms) {
     setValues({
      ...values,
      unitid: searchTerms.unitid,
      // Update other fields as needed
    });
  }
  };


  //get category
  const handleSearchCategoryChanges = (searchTerms) => {
    console.log("---------------",searchTerms)
    setSearchValues(searchTerms); // Update the searchValue state with the search term
    if (searchTerms) {
     setValues({
      ...values,
      chargecode: searchTerms.chargecode,
      categoryid: searchTerms.categoryid,
      // Update other fields as needed
    });
  }
  };

  //when page load or refresh happen this function will got called
  useEffect(() => {
    const loadData = async () => {
      // await loadAllDetails();
      // loadHospitalcode(); will wait for loadAllDetails(); to got execute
      await loadHospitalcode();
      const selectedHospitalCode = values.hospitalcode || userHospitalcode;
      // await fetchUnitName(selectedHospitalCode);
      await fetchcategoryName(selectedHospitalCode);
    };
    loadData();
  }, [userHospitalcode, userrole1, isAuthorized, values.hospitalcode]);

  //  get all hospital 
  const loadHospitalcode = async () => {
    //when userHospitalcode and userrole1 is not undefined then execute /gethospitalcode
    if (userHospitalcode !== undefined && userrole1 !== undefined) {
      const res = await axios.get("/gethospitalcode", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          hospitalcode: userHospitalcode, // Include the hospitalcode in the query parameters
          userrole: userrole1, // Include the userrole in the query parameters
        },
      });
      setData(res.data);
      console.log(res.data);
    };
  }
  const fetchcategoryName = async (selectedHospitalCode) => {
    try {
      const response = await axios.get("/pathologycategory", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          hospitalcode: selectedHospitalCode, // Use the selected hospital code
        },
      });

      if (response.status === 200) {
        setCategory(response.data);
      } else {
        console.error(`Failed to fetch available category name with status: ${response.status}`);
      }
    } catch (error) {
      console.error('Error fetching available category name:', error.message);
    }
  };

  // const fetchUnitName = async (selectedHospitalCode) => {
  //   try {
  //     const response = await axios.get("/getunit", {
  //       headers: {
  //         Authorization: `Bearer ${token}`,
  //       },
  //       params: {
  //         hospitalcode: selectedHospitalCode, // Use the selected hospital code
  //       },
  //     });

  //     if (response.status === 200) {
  //       setUnit(response.data);
  //     } else {
  //       console.error(`Failed to fetch available floor numbers with status: ${response.status}`);
  //     }
  //   } catch (error) {
  //     console.error('Error fetching available floor numbers:', error.message);
  //   }
  // };

  const currentDomain = window.location.hostname;

  return (
    <div className="p-3 pt-0 appointment">
      <Helmet>
        <title>Aarogyasaarthi | {currentDomain} | Add Pathology Parameter</title>
      </Helmet>
      <form onSubmit={handleSubmit}>
        {success && <div>Add Pathology Parameter</div>}
        {error && <div>Error in add bed type please try again</div>}
        <div class="fill header"><h3 class="float-left"> Add Pathology Parameter</h3> </div>
        <div className="patient-new primary-info">
          <div className="row">
            <div className="col-md-12">
              <div className="row">
                <div className="col-md-6">
                  <div className="col-md-12 mt-3">
                    <div className="row">

                      <div className="col-md-4">
                        <label
                          htmlFor="chargecode"
                          className="col-form-label leftone"
                        >
                          Pathology Category
                        </label>
                      </div>
                      <div className="col-md-8">
                      <SearchPathologyCategory
                      onPathologyCategoryChange={handleSearchCategoryChanges}
                      hospitalvalue={values.hospitalcode || userHospitalcode}
                      
                      />
                        {/* <select
                          type="text"
                          autoComplete="off"
                          name="chargecode"
                          id="chargecode"
                          placeholder="category name"
                          value={values.chargecode}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          style={{ width: '73%', height: '32px', border: '1px solid #ced4da', color: '#495057' }}
                        >
                          <option>Select</option>
                          {category.map((code) => (
                            <option value={code.chargecode} key={code.chargecode}>
                              {code.categoryname}
                            </option>
                          ))}
                        </select> */}
                        {errors.chargecode && touched.chargecode ? (
                          <p className="form-erroremployee">{errors.chargecode}</p>
                        ) : null}
                      </div></div></div>

                  <div className="col-md-12 mt-3">
                    <div className="row">

                      <div className="col-md-4">
                        <label
                          htmlFor="parametername"
                          className="col-form-label leftone"
                        >
                          Parameter Name
                        </label>
                      </div>
                      <div className="col-md-8">
                        <input
                          type="text"
                          autoComplete="off"
                          name="parametername"
                          id="parametername"
                          placeholder="Parameter Name"
                          value={values.parametername}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          style={{ width: '73%', height: '32px', border: '1px solid #ced4da', color: '#495057' }}
                        />
                        {errors.parametername && touched.parametername ? (
                          <p className="form-erroremployee">{errors.parametername}</p>
                        ) : null}
                      </div></div></div>

                  <div className="col-md-12 mt-3">
                    <div className="row">

                      <div className="col-md-4">
                        <label
                          htmlFor="description"
                          className="col-form-label leftone"
                        >
                          Description
                        </label>
                      </div>
                      <div className="col-md-8">
                        <textarea
                          type="text"
                          autoComplete="off"
                          name="description"
                          id="description"
                          placeholder="Description"
                          value={values.description}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          style={{ width: '73%', height: '32px', border: '1px solid #ced4da', color: '#495057' }}
                        />



                        {errors.description && touched.description ? (
                          <p className="form-erroremployee">{errors.description}</p>
                        ) : null}
                      </div></div></div>



                </div>
                <div className="col-md-6">
                {isAuthorized ? (<div className="col-md-12 mt-3">
              <div className="row">
  
                <div className="col-md-4">
                  <label
                    htmlFor="hospitalcode"
                    className="col-form-label leftone"
                  >
                    Hospital Code
                  </label>
                </div>
                <div className="col-md-8">
                  {/* Conditionally render the dropdown and input field */}
                  {isAuthorized ? (
                      
                  <HospitalCodeSearch  onSearchItem={onSearchItems} />
                       
                    // <select
                    //   type="text"
                    //   autoComplete="off"
                    //   name="hospitalcode"
                    //   id="hospitalcode"
                    //   placeholder="Hospital Code"
                    //   value={values.hospitalcode}
                    //   onChange={handleChange}
                    //   onBlur={handleBlur}
                    //   style={{ width: '73%', height: '32px', border: '1px solid #ced4da', color: '#495057' }}
                    // >
                    //   <option>Select</option>
                    //   {data.map((code) => (
                    //     <option value={code.hospitalcode} key={code.id}>
                    //       {code.hospitalcode}
                    //     </option>
                    //   ))}
                    // </select>
                  ) : (
                    <input
                      type="text"
                      name="hospitalcode"
                      id="hospitalcode"
                      value={userHospitalcode}
                      disabled={true} // Set the input as disabled
                      className="form-control "
                    />
                  )}
                  {isAuthorized && errors.hospitalcode && touched.hospitalcode ? (
                    <p className="form-erroremployee">{errors.hospitalcode}</p>
                  ) : null}
                </div></div></div>) : null}
                  <div className="col-md-12 mt-3">
                    <div className="row">

                      <div className="col-md-4">
                        <label
                          htmlFor="referencerange"
                          className="col-form-label leftone"
                        >
                          Reference Range
                        </label>
                      </div>
                      <div className="col-md-8">
                        <input
                          type="text"
                          autoComplete="off"
                          name="referencerange"
                          id="referencerange"
                          placeholder="Reference Range"
                          value={values.referencerange}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          style={{ width: '73%', height: '32px', border: '1px solid #ced4da', color: '#495057' }}
                        />
                        {errors.referencerange && touched.referencerange ? (
                          <p className="form-erroremployee">{errors.referencerange}</p>
                        ) : null}
                      </div></div></div>


                  <div className="col-md-12 mt-3">
                    <div className="row">
                      <div className="col-md-4">
                        <label
                          htmlFor="unitid"
                          className="col-form-label leftone"
                        >
                          Unit
                        </label>
                      </div>
                      <div className="col-md-8">
                      <SearchUnit
                      onUnitChange={handleSearchChanges}
                      hospitalvalue={values.hospitalcode || userHospitalcode}
                      
                      />
                        {/* <select
                          type="text"
                          autoComplete="off"
                          name="unitid"
                          id="unitid"
                          placeholder="Unit"
                          value={values.unitid}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          style={{ width: '73%', height: '32px', border: '1px solid #ced4da ', color: '#495057' }}
                        >
                          <option>
                            Select
                          </option>


                          {unit1.map((code) => (
                            <option value={code.unitid} key={code.unitid}>
                              {code.unitname}
                            </option>
                          ))}
                        </select> */}
                        {errors.unitid && touched.unitid ? (
                          <p className="form-erroremployee">{errors.unitid}</p>
                        ) : null}
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-6  mt-3">
          <Button
            type="submit"
            variant="success"
            className="mx-3"
          >
            Save
          </Button>
          <Button
            variant="primary"
            className="mx-3"
            onClick={handleReset}
          >
            Reset
          </Button>
        </div>
        <ToastContainer />
      </form>
    </div>
  );
};

export default AddPathology;
