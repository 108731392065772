import React, { useState, useEffect } from 'react';
import { Modal, Backdrop, Fade, Button,TextField, Autocomplete, InputAdornment, IconButton } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useFormik } from "formik";
import axios from "../../../helper/axios";
import { useUser } from "../../../Provider";
import { useParams } from 'react-router-dom/cjs/react-router-dom';
import { ToastContainer, toast } from 'react-toastify'
const initialValues = {
    date:new Date().toISOString().slice(0, 16),
    nurseid:"",
    note:"",
    comment:""  
};
const PopupNurseNote = ({noteId,onClose }) => {
    console.log(noteId)
    const { admitnumber } = useParams();
    const { user, userHospitalcode, userrole1, isAuthRole, isAuthorized, gettokenId,token } = useUser();
    const [nurse, setNurse] = useState([]);
    const [nursenote, setNursenote] = useState([]);
    const [nursecomment, setNursecomment] = useState([]);
    const [data, setData] = useState([]);
    //const token = sessionStorage.getItem("jwtToken");
    const [currentDateTime, setCurrentDateTime] = useState('');
    const [firstNurseCommentId, setFirstNurseCommentId] = useState({comment:""});
    const [nursecommentid, setNursecommentid] = useState({nursecommentid:""});
    const [openAutocompleted, setOpenAutocompleted] = useState(false);
  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleReset,
    handleSubmit,
    resetForm,
    setValues,
  } = useFormik({
    initialValues,
    //validationSchema: Nursenotevalidation,
    onSubmit: async (values, action) => {
        try {
  
        //  if (userHospitalcode !== undefined) {
            const data = new FormData();
            data.append("document", JSON.stringify(values));
            data.append("currentDate", JSON.stringify(currentDateTime));
            data.append("nursecommentid", JSON.stringify(nursecommentid));
           data.append("firstNurseCommentId", JSON.stringify(firstNurseCommentId));
            console.log(JSON.stringify(firstNurseCommentId));
            console.log(JSON.stringify(values))
            console.log(JSON.stringify(nursecommentid))
            const response = await axios.post(`/nursenoteupdate/${admitnumber}`, data, {
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "multipart/form-data",
              },
            });
            if (response.status === 200) {
              
             toast.success("Data Saved")
             
            }
          //}
        } catch (err) {
          toast.error("Unauthorised access")
        }
        action.resetForm();
      },
  
    });
    const handleCommentChange = (event) => {
      const { value } = event.target;
      // Set the value to the state
    setFirstNurseCommentId(value);
      // Use handleChange to update Formik values
      handleChange(event);
    };
    const handleExpandClicked = () => {
      setOpenAutocompleted(!openAutocompleted);
  };
    const loadHospitalcode = async () => {
        //when userHospitalcode and userrole1 is not undefined then execute /gethospitalcode
        if (userHospitalcode !== undefined && userrole1 !== undefined) {
          const res = await axios.get(`/getIPDhospitalcode/${admitnumber}`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
            params: {
              hospitalcode: userHospitalcode, // Include the hospitalcode in the query parameters
              userrole: userrole1, // Include the userrole in the query parameters
            },
          });
          setData(res.data[0]);
          console.log(res.data);
        };
      }
    const getNursenotedetails = async () => {
        try {
          if (userHospitalcode !== undefined && userrole1 !== undefined) {
            const res = await axios.get(`/getnursenotes/${admitnumber}/${noteId}`, {
              headers: {
                Authorization: `Bearer ${token}`,
              },
              params: {
                userHospitalcode:userHospitalcode ,
                userrole: userrole1,
              },
            });
    
            if (res.status === 200) {
              setValues(res.data[0]);
              console.log(res.data[0])
            } else {
              alert(`Request failed with status: ${res.status}`);
            }
          }
        } catch (error) {
          console.log(`An error occurred: ${error.message}`);
        }
      };
      const getNurseDetails = async () => {

        try {
            if (userHospitalcode !== undefined && userrole1 !== undefined) {
                const res = await axios.get(`/getnurse`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                    params: {
                        hospitalcode: data.hospitalcode, // Include the hospitalcode in the query parameters
                        userrole: userrole1, // Include the userrole in the query parameters
                    },
                });
                console.log(res.data)
                // Check if the response status code is  200
                if (res.status === 200) {
                    setNurse(res.data);
                  
                } else {
                    // Handle unexpected response status codes (e.g., 404, 500, etc.) as errors.
                    alert(`Request failed with status: ${res.status}`);
                    // Optionally, you can set an error state or display an error message to the user.
                }
            }
        }
        catch (error) {
            // Handle network errors or other exceptions.
           console.log(`An error occurred: ${error.message}`);
            // Optionally, you can set an error state or display an error message to the user.
        }
    };
    const getNurseComment = async () => {

      try {
          if (userHospitalcode !== undefined && userrole1 !== undefined) {
              const resp = await axios.get(`/getnursenotecomment/${noteId}`, {
                  headers: {
                      Authorization: `Bearer ${token}`,
                  },
                  params: {
                      hospitalcode: data.hospitalcode, // Include the hospitalcode in the query parameters
                      userrole: userrole1, // Include the userrole in the query parameters
                  },
              });
              console.log(resp.data)
              // Check if the response status code is  200
              if (resp.status === 200) {
                setFirstNurseCommentId({
                  ...resp.data[0],
                  nursecomments: resp.data[0].comment, // Set initial value for nursecomments
                  nursecommentid:resp.data[0].nursecommentid
                });
                setNursecommentid({
                  ...resp.data[0],
                  nursecomments: resp.data[0].comment, // Set initial value for nursecomments
                  nursecommentid:resp.data[0].nursecommentid
                });
                console.log(resp.data)
              } else {
                  // Handle unexpected response status codes (e.g., 404, 500, etc.) as errors.
                  alert(`Request failed with status: ${resp.status}`);
                  // Optionally, you can set an error state or display an error message to the user.
              }
          }
      }
      catch (error) {
          // Handle network errors or other exceptions.
         console.log(`An error occurred: ${error.message}`);
          // Optionally, you can set an error state or display an error message to the user.
      }
  };
    
      useEffect(() => {
        const loadData = async () => {
            await getNursenotedetails();
            await getNurseDetails(data.hospitalcode);
            await getNurseComment();
           await loadHospitalcode();
        };
      
        loadData();

      // Function to get the current date and time
       const getCurrentDateTime = () => {
        const now = new Date();
        const year = now.getFullYear();
        const month = String(now.getMonth() + 1).padStart(2, '0');
        const day = String(now.getDate()).padStart(2, '0');
        const hours = String(now.getHours()).padStart(2, '0');
        const minutes = String(now.getMinutes()).padStart(2, '0');
        const currentDateTimeString = `${year}-${month}-${day}T${hours}:${minutes}`;
        return currentDateTimeString;
      };
  
      // Set the current date and time when the component mounts
      setCurrentDateTime(getCurrentDateTime());
    }, [userHospitalcode, userrole1, isAuthorized,data.hospitalcode]);

   // const nursecommentid = nursecomment.length > 0 ? nursecomment[0].nursecommentid : ""
    
  return (
    <Modal
    open={true} // Set to true to open the modal
    onClose={onClose}
    aria-labelledby="transition-modal-title"
    aria-describedby="transition-modal-description"
    closeAfterTransition
    BackdropProps={{
      style: {
        backgroundColor: 'rgba(0, 0, 0, 0.5)', // Adjust the opacity as needed
      },
    }}
    >
      <Fade in={true}>
        <div style={{ backgroundColor: 'white', padding: '20px', borderRadius: '8px', maxWidth: '700px', margin: 'auto', marginTop: '100px' }}>
          <h2>Update Note</h2>
          <form onSubmit={handleSubmit} style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                    <div className="patient-new primary-info">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="col-md-12 mt-3">
                                            <div className="row">
                                                <div className="col-md-4">
                                                    <label
                                                        htmlFor="date"
                                                        className="col-form-label leftone"
                                                    >
                                                        Date
                                                    </label>
                                                </div>
                                                <div className="col-md-8">
                                                    <input
                                                        type="datetime-local"
                                                        autoComplete="off"
                                                        name="date"
                                                        id="date"
                                                        placeholder="date"
                                                        value={currentDateTime}
                                                          onChange={handleChange}
                                                          onBlur={handleBlur}
                                                        className="form-control"
                                                    />
                                                    {errors.date && touched.date ? (
                    <p className="form-erroremployee">{errors.date}</p>
                  ) : null}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-12 mt-3">
                                            <div className="row">
                                                <div className="col-md-4">
                                                    <label
                                                        htmlFor="note"
                                                        className="col-form-label leftone "
                                                    >
                                                        Note
                                                    </label>
                                                </div>
                                                <div className="col-md-8">
                                                    <textarea
                                                        // style={{ width: "210px", height: "50px" }}
                                                        rows="1"
                                                        cols="30"
                                                        type="text"
                                                        autoComplete="off"
                                                        name="note"
                                                        id="note"
                                                        placeholder="note"
                                                        value={values.note}
                                                          onChange={handleChange}
                                                          onBlur={handleBlur}

                                                        className="form-control"
                                                    /> {errors.note && touched.note ? (
                                                        <p className="form-erroremployee">{errors.note}</p>
                                                      ) : null}
                                                </div>
                                            </div>
                                        </div>
                                       </div>
                                    <div className="col-md-6">
                                        <div className="col-md-12 mt-3">
                                            <div className="row">
                                                <div className="col-md-4">
                                                    <label
                                                        htmlFor="nurseid"
                                                        className="col-form-label leftone "
                                                    >
                                                        Nurse
                                                    </label>
                                                </div>
                                                <div className="col-md-8">
                                                    {/* <select
                                                        type="text"
                                                        autoComplete="off"
                                                        name="nurseid"
                                                        id="nurseid"
                                                        placeholder="Nurse"
                                                        value={values.nurseid}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        style={{ width: '73%', height: '32px', border: '1px solid #ced4da ', color: '#495057' }}
                                                    >
                                                        <option>
                                                            Select
                                                        </option>
                                                        {nurse.map((nurse) => (
                                                            <option
                                                                value={nurse.nurseid}
                                                                key={nurse.fname}
                                                            >
                                                                {nurse.fname}
                                                            </option>
                                                        ))}
                                                    </select> */}
                                                        <Autocomplete
                          freeSolo
                          id="free-solo"
                          options={nurse}
                          getOptionLabel={(bedType) => bedType.fname}
                          value={nurse.find((bedType) => bedType.nurseid === values.nurseid) || null}
                          open={openAutocompleted}
                          onOpen={() => setOpenAutocompleted(true)}
                          onClose={() => setOpenAutocompleted(false)}
                          onChange={(event, newValue) => {
                            setValues({
                              ...values,
                              nurseid: newValue ? newValue.nurseid : ""
                            });
                          }}
                          onBlur={handleBlur}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              placeholder="Select nurse"
                              InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <IconButton onClick={handleExpandClicked}>
                                      <ExpandMoreIcon />
                                    </IconButton>
                                  </InputAdornment>
                                ),
                                //style: { width: '74%', padding: '0px ', border: "none" } // Adjust width here
                              }}
                            />
                          )}
                        />
                                                    {errors.nurseid && touched.nurseid ? (
                                                        <p className="form-erroremployee">{errors.nurseid}</p>
                                                    ) : null}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-12 mt-3">
                                            <div className="row">
                                                <div className="col-md-4">
                                                    <label
                                                        htmlFor="comment"
                                                        className="col-form-label leftone "
                                                    >
                                                        Comment
                                                    </label>
                                                </div>
                                                <div className="col-md-8">
                                                    <textarea
                                                        // style={{ width: "210px", height: "50px" }}
                                                        rows="1"
                                                        cols="30"
                                                        type="text"
                                                        autoComplete="off"
                                                        name="comment"
                                                        id="comment"
                                                        placeholder="comment"
                                                        value={firstNurseCommentId.comment}
                                                        onChange={handleCommentChange}
                                                        onBlur={handleBlur}

                                                        className="form-control"
                                                    />
                                                    {errors.comment && touched.comment ? (
                                                        <p className="form-erroremployee">{errors.comment}</p>
                                                    ) : null}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-11 mt-6 text-center">
              <Button type="submit" variant="contained" color="primary" size="small" style={{ marginRight: '10px' }}>
                Update
              </Button>
              <Button variant="contained" size="small" onClick={onClose}>
                Cancel
              </Button>
            </div>
            <ToastContainer/>
                </form>
        </div>
      </Fade>
    </Modal>
  );
};

export default PopupNurseNote;
