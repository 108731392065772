import React from "react";
import { useFormik } from "formik";
import { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useHistory, useParams } from "react-router-dom";
import axios from "../../../helper/axios";
import { Table, Button, Modal, Form } from "react-bootstrap";
import { patientregistration } from "../../../helper/schemas";
import { ToastContainer, toast } from 'react-toastify';
import * as Yup from "yup";  // Import Yup for validation
import { useUser } from "../../../Provider";
import File from "../../../Component/PrintReport/File";

const initialValues = {
  result: ""
};


const EditPathology = () => {
  const history = useHistory();
  const { billingno } = useParams();
  const [success, setSuccess] = useState(false);
  // Use the 'useUser' hook to get user-related information
  const { user, userHospitalcode, userrole1, isAuthRole, isAuthorized, gettokenId, token } = useUser();
  console.log(user, userHospitalcode, userrole1, isAuthRole, isAuthorized, gettokenId);
  const [category1, setCategory] = useState([]);
  const [isPopup, setIsPopup] = useState(false);
  // const [data, setData] = useState([]);
  const [billnumber, setBid] = useState();
  const [error, setError] = useState(false);
  const [searchValueName, setSearchValueName] = useState("")
  const {
    values,
    errors,
    touched,
    setValues,
    handleChange,
    handleBlur,
    resetForm,
    handleSubmit,
  } = useFormik({
    initialValues,
    // validationSchema,
    // validationSchema: getValidationSchema, // Apply validation schema
    onSubmit: async (values, { resetForm }) => {

      try {
        if (userHospitalcode !== undefined) {
          const data = new FormData();
          // Append form data to the FormData object
          data.append("document", JSON.stringify(searchValueName));
          data.append("documentvalue", JSON.stringify(values));
          data.append("currentDate", JSON.stringify(currentDate));
          data.append("gettokenId", JSON.stringify(gettokenId));
          data.append("test", JSON.stringify(test));
          data.append("userHospitalcode", JSON.stringify(userHospitalcode));

          console.log(JSON.stringify(searchValueName))

          console.log(JSON.stringify(test))

          // Make a POST request to save Prescription data
          const response = await axios.post(`/updatepathologyreport/${billingno}`, data, {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "multipart/form-data",
            },
            params: {
              hospitalcode: userHospitalcode, // Include the hospitalcode in the query parameters
              userrole: userrole1, // Include the userrole in the query parameters
            },
          },);

          if (response.status === 200) {
            return history.push("/pathologyreport");
          } else {
            console.error('Error in admitting patient');
            setSuccess(false);
            setError(true);
          }
        }
      } catch (error) {
        console.error('Error:', error.message);
        setSuccess(false);
        setError(true);
        toast.error('Something Went Wrong');
      }
    }
  });


  const getCurrentDateTime = () => {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0');
    const day = String(now.getDate()).padStart(2, '0');
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    const currentDateTimeString = `${year}-${month}-${day}T${hours}:${minutes}`;
    return currentDateTimeString;


  };

  let Showdate = new Date();
  let ShowTodaydate =
    Showdate.getFullYear() +
    "-" +
    (Showdate.getMonth() + 1) +
    "-" +
    Showdate.getDate();
  const [currentDate, setCurrentDate] = useState(ShowTodaydate);
  const [test, setTest] = useState([{ testcategory: '', testparameter: '', result: '', unit: '', referencerange: '', method: '',chargecode:"" }]);
  const [data, setData] = useState([]);


  //  get all hospital 
  const loadHospitalcode = async () => {
    //when userHospitalcode and userrole1 is not undefined then execute /gethospitalcode
    if (userHospitalcode !== undefined && userrole1 !== undefined) {
      const res = await axios.get("/gethospitalcode", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          hospitalcode: userHospitalcode, // Include the hospitalcode in the query parameters
          userrole: userrole1, // Include the userrole in the query parameters
        },
      });
      setData(res.data);
      console.log(res.data);
    };
  }

  // Function to handle changes in test-related fields
  const handleChanges = (e, index, field) => {
    const { value } = e.target;
    setTest((prevTests) => {
      return prevTests.map((test, i) => {
        if (i === index) {
          return { ...test, [field]: value };
        }
        return test;
      });
    });
  }


  const handleClosed = () => {
    setIsPopup(false)
    //window.location.reload();
  }

  const printForm = (values) => {

    setBid(searchValueName.billnumber)
    setIsPopup(true)
    // window.open(`/AppoinmentForm/${value.patientid}`, "_blank")
  }



  const loadPathologyReport = async () => {
    try {
      if (userHospitalcode !== undefined && userrole1 !== undefined) {
        const response = await axios.get(`/getpathologyreports/${billingno}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: {
            hospitalcode: userHospitalcode,
            userrole: userrole1,
          },
        });

        const pathologyreportdata = response.data;
        console.log(" sndbsjkdnksd", pathologyreportdata)
        setValues(pathologyreportdata[0]);
        setTest(pathologyreportdata)
      }
    } catch (error) {
      console.error('Error loading bed type details:', error.message);
      alert('Something Went Wrong');
    }
  };

  //when page load or refresh happen this function will got called
  useEffect(() => {
    const loadData = async () => {

      await loadPathologyReport();
    };
    loadData();
  }, [userHospitalcode, userrole1, isAuthorized]);

  const currentDomain = window.location.hostname;
  return (
    <div>
      <form onSubmit={handleSubmit}>
        <div className="patient-new primary-info">
          <div className="row  ml-4">
            <div className="col-md-12 ">

              <div className="row">
                <div className="col-md-2">
                  <label className="col-form-label ">Billing No</label>
                </div>
                <div className="col-md-4">
                  <input
                    type="text"
                    autoComplete="off"
                    name="billingno"
                    id="billingno"
                    placeholder="Billing No"
                    value={values.billingno}
                    onChange={handleChange}
                    disabled
                    className="form-control"
                  />
                  {errors.billingno && touched.billingno ? (
                    <p className="form-erroremployee">{errors.billingno}</p>
                  ) : null}
                </div>
                {isAuthorized ? (<div className="col-md-2">
                  <label
                    htmlFor="hospitalcode"
                    className="col-form-label"
                  >
                    Hospital Code
                  </label>
                </div>) : (
                  null
                )}
                <div className="col-md-4">

                  {isAuthorized ? (
                    <input
                      type="text"
                      autoComplete="off"
                      name="hospitalcode"
                      id="hospitalcode"
                      placeholder="Hospital Code"
                      value={values.hospitalcode}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      disabled
                      className="form-control"
                    />

                  ) : (
                    <input
                      type="hidden"
                      name="hospitalcode"
                      id="hospitalcode"
                      value={values.hospitalcode}
                      disabled={true} // Set the input as disabled
                      className="form-control "
                    />
                  )}
                  {errors.hospitalcode && touched.hospitalcode ? (
                    <p className="form-erroremployee">{errors.hospitalcode}</p>
                  ) : null}
                </div>
              </div>


              <div className="row">
                <div className="col-md-12">

                  <h5>Patient Information</h5>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="row">
                        <div className="col-md-4">
                          <label htmlFor="name" className="col-form-label leftone">Name</label>
                        </div>
                        <div className="col-md-8">
                          <input
                            type="text"
                            autoComplete="off"
                            name="patientname"
                            id="patientname"
                            placeholder="Name"
                            value={values.patientname}
                            onChange={handleChange}
                            disabled
                            className="form-control"
                          />
                          {/* {errors.name && touched.name ? (
                  <p className="form-erroremployee">{errors.name}</p>
                ) : null} */}
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-4">
                          <label htmlFor="age" className="col-form-label leftone">Age</label>
                        </div>
                        <div className="col-md-8">
                          <input
                            type="number"
                            autoComplete="off"
                            name="age"
                            id="age"
                            placeholder="Age"
                            value={values.age}
                            onChange={handleChange}
                            disabled
                            className="form-control"
                          />
                          {errors.age && touched.age ? (
                            <p className="form-erroremployee">{errors.age}</p>
                          ) : null}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="row">
                        <div className="col-md-4">
                          <label htmlFor="sex" className="col-form-label ">Sex</label>
                        </div>
                        <div className="col-md-8">
                          <input
                            type="text"
                            autoComplete="off"
                            name="sex"
                            id="sex"
                            placeholder="Sex"
                            value={values.gender}
                            onChange={handleChange}
                            disabled
                            className="form-control"
                          />
                          {errors.sex && touched.sex ? (
                            <p className="form-erroremployee">{errors.sex}</p>
                          ) : null}
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-4">
                          <label htmlFor="medicalHistory" className="col-form-label ">Medical History</label>
                        </div>
                        <div className="col-md-8">
                          <input
                            type="text"
                            autoComplete="off"
                            name="medicalHistory"
                            id="medicalHistory"
                            placeholder="Medical History"
                            value={values.medicalHistory}
                            onChange={handleChange}
                            className="form-control"
                          />
                          {errors.medicalHistory && touched.medicalHistory ? (
                            <p className="form-erroremployee">{errors.medicalHistory}</p>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
        <div>
          <table>
            <thead>
              <tr style={{ paddingRight: '10px' }}>
                <th>Test Category</th>
                <th>Pathology Parameter</th>
                <th>Result</th>
                <th>Unit</th>
                <th>Reference Range</th>
                <th>Method</th>
                {/* <th>Delete</th> */}
              </tr>
            </thead>
            <tbody>
              {test.map((test, index) => (
                <tr key={index}>
                  <td style={{ paddingRight: '10px' }}>
                    <input
                      type="text"
                      autoComplete="off"
                      name="testcategory"
                      id="testcategory"
                      placeholder="Test Category"
                      value={test.categoryname}
                      disabled
                      onBlur={handleBlur}
                      style={{ width: '100%', height: '32px', border: '1px solid #ced4da ', color: '#495057', background: '#ced4da' }}
                    />
                    {/* <option>Select</option>
                        {category1.map((code) => (
                          <option value={code.chargecode} key={code.chargecode}>
                            {code.chargename}
                          </option>
                        ))}
                      </select> */}
                  </td>
                  <td>
                    <input
                      type="text"
                      autoComplete="off"
                      name="testparameter"
                      id="testparameter"
                      placeholder="test Name"
                      value={test.testparameter}
                      disabled
                      // onChange={(e) => handleChanges(e, index, "testparameter")}
                      style={{ width: '100%', height: '32px', border: '1px solid #ced4da ', color: '#495057', background: '#ced4da' }}
                    />
                  </td>

                  <td>
                    <input
                      type="text"
                      autoComplete="off"
                      name="result"
                      id="result"
                      placeholder="result"
                      value={test.result}
                      onChange={(e) => handleChanges(e, index, "result")}

                      style={{ width: '100%', height: '32px', border: '1px solid #ced4da ', color: '#495057' }}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      autoComplete="off"
                      name="unit"
                      id="unit"
                      placeholder="unit"
                      value={test.unit}
                      // onChange={(e) => handleChanges(e, index, "unit")}
                      disabled
                      style={{ width: '100%', height: '32px', border: '1px solid #ced4da ', color: '#495057', background: '#ced4da' }}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      autoComplete="off"
                      name="referencerange"
                      id="referencerange"
                      placeholder="referencerange"
                      value={test.referencerange}
                      disabled
                      //  onChange={(e) => handleChanges(e, index, "referencerange")}
                      style={{ width: '100%', height: '32px', border: '1px solid #ced4da ', color: '#495057', background: '#ced4da' }}
                    />
                  </td>
                  <td>
                    <textarea
                      // style={{ width: "210px", height: "50px" }}
                      rows="1"
                      cols="30"
                      type="text"
                      autoComplete="off"
                      name="method"
                      id="method"
                      placeholder="method"
                      value={test.method}
                      // onChange={(e) => handleChanges(e, index, "method")}
                      disabled
                      style={{ width: '100%', height: '32px', border: '1px solid #ced4da ', color: '#495057', background: '#ced4da' }}
                    />
                  </td>
                  {/* <td>
                    <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        style={{ width: 'auto' }}
                        onClick={(e) => handleDeleteRow(index)}
                      >
                        Delete
                      </Button>
                    </td> */}
                </tr>
              ))}
            </tbody>
          </table>
          {/* <Button variant="contained" color="primary" size="small" style={{ width: 'auto' }}
             onClick={() => setTest([...test, { testcategory: '', testparameter: '', result: '', unit: '', referencerange: '', method: '' }])}>
              Add Test
            </Button> */}
        </div>
        <div className="row">
          <div className="col-md-1 ">
            <button className="btn btn-primary" type="submit">Save</button>
          </div>
          <div className="col-md-2 ">
            <button className="btn btn-primary" type="submit" onClick={() => printForm(values, "printandsave")}>Save Print</button>
          </div>
        </div>
        {isPopup == true ?
          <>

            <Modal show={true} animation={false} onHide={handleClosed} size="lg">
              <Modal.Header closeButton>
                <Modal.Title>{billnumber}</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                {billnumber == undefined ? ""
                  :
                  // <PrintTemplateAppointment pId={billnumber} handleSubmit={handleClosed} apppoinentDate={appointmentDate}/>}
                  <File billnumber={billnumber} handleSubmit={handleClosed} />}
              </Modal.Body>
            </Modal>
          </>
          :
          ""}
        <ToastContainer />
      </form>
    </div>
  )
};

export default EditPathology;

