import React, { useState, useEffect } from 'react';
import axios from "../../../helper/axios";
import { useFormik } from "formik";
import Searchbar from '../../../Component/Searchbar';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Button, Modal } from 'react-bootstrap';
import PrintTemplateAppointment from "../../../Component/PrintTemplateAppointment/PrintTemplateAppointment"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import * as Yup from 'yup';
import { Helmet } from 'react-helmet';
import { useUser } from "../../../Provider";
import Doctorsearch from '../../../Component/Doctorsearch';
import HospitalCodeSearch from '../../../Component/HospitalCodeSearch';
//All the Initial values for inputs
const initialValues = {
  patientName: "",
  bloodGroup: "",
  mobileNo: "",
  doctorrecordid: "",
  doctorFee: "",
  appointmentDate: "",
  paymentMode: "",
  address: "",
  // OPDType:"Visit",
  PID: "",
  gaurdianname: "",
  age: "",
  gender: "",
  hospitalcode: "",
  height:"",
  weight:"",
  bp:"",
  temperature:"",
  pulse:""
}

const NewAppoinment = () => {
  //Got all the details from of logged in user usin useContext hhok
  const { user, userHospitalcode, userrole1, isAuthRole, isAuthorized, gettokenId,token } = useUser();
  //other useState
  const [OPDType, setOPDType] = useState("Visit");
  const [opdrecordnumber, setPid] = useState();
  const [isPopup, setIsPopup] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [searchValues, setSearchValues] = useState("");
  const [data, setData] = useState([]);
  const [getrowdata, setGetrowdata] = useState({ doctorfees: " " });
  //Onchange Function
  const handleOpdChange = (event) => {
    setOPDType(event.target.value);
       // If the selected patient type is "New," reset the form
       if (event.target.value === "Visit" || event.target.value === "New") {
        resetForm();
      }
  };
  // Validation schema for OPDType "Visit"
  const visitValidationSchema = Yup.object().shape({
    patientName: Yup.string()
    .matches(/^[a-zA-Z\s]+$/, "Patient name should only contain letters")
    .required("Please enter the patient name"),    doctorrecordid: Yup.string().required("Please select the doctor"),
    height: Yup.string().matches(/^[0-9]+(\.[0-9]+)?$/, "Height should only contain numbers with an optional decimal point"),
     pulse: Yup.string().matches(/^[0-9. ]+$/, "Pulse should only contain numbers and dots"),
    bp: Yup.string().matches(/^\d{2,3}\/\d{2,3}$/, "BP should be in the format '120/80'"),
    weight: Yup.string().matches(/^[0-9]+(\.[0-9]+)?$/, "Weight should only contain numbers with an optional decimal point"),
    temperature: Yup.string().matches(/^[0-9]+(\.[0-9]+)?$/, "Temperature should only contain numbers with an optional decimal point"),
    
  });

  // Validation schema for OPDType "New"
  const newValidationSchema = Yup.object().shape({
    patientName: Yup.string()
    .matches(/^[a-zA-Z\s]+$/, "Patient name should only contain letters")
    .required("Please enter the patient name"),    address: Yup.string().required("Please enter the address"),
    mobileNo: Yup.string()
    .matches(/^[0-9]+$/, "Mobile number should only contain numbers")
    .min(10, "Mobile number must be at least 10 characters")
    .max(10, "Mobile number must be 10 characters")
    .required("Mobile number is required"),
    bloodGroup: Yup.string()
          .matches(/^[a-zA-Z+\- ]+$/, "Blood group should only contain letters, +, -, or spaces"),
    age: Yup.number()
    .typeError("Age must be a number")
    .positive("Age must be a positive number")
    .integer("Age must be an integer")
    .max(999, "Age must be at most 3 digits")
    .required("Please enter the age"),
    gender: Yup.string().required("Please select the gender"),
    hospitalcode: Yup.string().required("Please select the hospital code"),
    doctorrecordid: Yup.string().required("Please select the doctor"),
    gaurdianname: Yup.string().matches(/^[a-zA-Z\s]+$/, "Guardian name should only contain letters"),
    height: Yup.string().matches(/^[0-9]+(\.[0-9]+)?$/, "Height should only contain numbers with an optional decimal point"),
    pulse: Yup.string().matches(/^[0-9. ]+$/, "Pulse should only contain numbers and dots"),
    bp: Yup.string().matches(/^\d{2,3}\/\d{2,3}$/, "BP should be in the format '120/80'"),
    weight: Yup.string().matches(/^[0-9]+(\.[0-9]+)?$/, "Weight should only contain numbers with an optional decimal point"),
    temperature: Yup.string().matches(/^[0-9]+(\.[0-9]+)?$/, "Temperature should only contain numbers with an optional decimal point"),
  });
  // Validation schema for OPDType "New"
  const newValidationSchemas = Yup.object().shape({
  patientName: Yup.string()
  .matches(/^[a-zA-Z\s]+$/, "Patient name should only contain letters")
  .required("Please enter the patient name"),    address: Yup.string().required("Please enter the address"),
    mobileNo: Yup.string().min(10).max(10).required("Please enter the mobile no"),
    age: Yup.number()
    .typeError("Age must be a number")
    .positive("Age must be a positive number")
    .integer("Age must be an integer")
    .max(999, "Age must be at most 3 digits")
    .required("Please enter the age"),
    gender: Yup.string().required("Please enter the gender"),
    doctorrecordid: Yup.string().required("Please select the doctor"),
  });
//Validtion
  const getValidationSchema = () => {
    if (OPDType === 'Visit') {
      return visitValidationSchema;

    } else if (!isAuthorized && OPDType === 'New') {
      return newValidationSchemas;
    }
    else if (isAuthorized && OPDType === 'New') {
      return newValidationSchema;
    }
    // Add default validation schema if needed
    return Yup.object();
  };

  let Showdate = new Date();
  let ShowTodaydate =
    Showdate.getFullYear() +
    "-" +
    (Showdate.getMonth() + 1) +
    "-" +
    Showdate.getDate();
  const [currentDate, setCurrentDate] = useState(ShowTodaydate);

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleReset,
    handleSubmit,
    resetForm,
    setValues,
  } = useFormik({
    initialValues,
    validationSchema: getValidationSchema,
    onSubmit: async (values, action) => {
      try {

        setIsPopup(false)
        if (userHospitalcode !== undefined) {
          const data = new FormData();
          data.append("document", JSON.stringify(values));
          data.append("getrowdata", JSON.stringify(getrowdata));
          data.append("OPDType", JSON.stringify(OPDType));
          data.append("currentDateTime", JSON.stringify(currentDateTime));
          data.append("gettokenId", JSON.stringify(gettokenId));
          data.append("currentDate", JSON.stringify(currentDate));
          data.append("userHospitalcode", JSON.stringify(userHospitalcode));
          console.log(JSON.stringify(values))
          console.log(JSON.stringify(getrowdata))
          const response = await axios.post("/newappointment", data, {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "multipart/form-data",
            },
          });
          if (response.status === 200) {
            // console.log(response.data[0])
            const opdrecordnumber = response.data[0];
            setPid(opdrecordnumber);
            toast.success("Data Saved")
            // setSuccess(true);
            if (isPopup) {
              setIsPopup(true)
            }
            setError(false)
            //return history.push("/appointment");
          }
        }
      } catch (err) {
        setSuccess(false);
        setError(true);
        toast.error("Something Went Wrong")
      }
      action.resetForm();
    },

  });
  //patient onSearch function
  const handleSearchChange = (searchTerm) => {
    console.log(searchTerm)
    setSearchValue(searchTerm); // Update the searchValue state with the search term
  };
  //Doctor onSearch Function
  const handleSearchChanges = (searchTerms) => {
    console.log("---------------",searchTerms)
    setValues({
      ...values,
      doctorrecordid: searchTerms.doctorrecordid,
      // Update other fields as needed
    }); // Update the searchValue state with the search term
  };
  //Hospital onSearch Function
  const onSearchItems = (searchTerm) => {
    console.log("------",searchTerm)
    //setSearchValues(searchTerm);
    setValues({
      ...values,
      hospitalcode: searchTerm.hospitalcode,
      // Update other fields as needed
    });
  };
  const [currentDateTime, setCurrentDateTime] = useState('');
  useEffect(() => {
    // Function to get the current date and time
    const getCurrentDateTime = () => {
      const now = new Date();
      const year = now.getFullYear();
      const month = String(now.getMonth() + 1).padStart(2, '0');
      const day = String(now.getDate()).padStart(2, '0');
      const hours = String(now.getHours()).padStart(2, '0');
      const minutes = String(now.getMinutes()).padStart(2, '0');
      const currentDateTimeString = `${year}-${month}-${day}T${hours}:${minutes}`;
      return currentDateTimeString;
    };

    // Set the current date and time when the component mounts
    setCurrentDateTime(getCurrentDateTime());
  }, []);
  const handleClosed = () => {
    setIsPopup(false)
    //window.location.reload();
  }

  const printForm = (values) => {
    if (values.patientName.trim() === "") {
      setIsPopup(false); // Set isPopup to false if patientName is empty
      toast.error("Patient Name is Mandatory")
    } else {
      setPid(values.opdrecordnumber)
      setIsPopup(true)
      // window.open(`/AppoinmentForm/${value.patientid}`, "_blank")
    }
  }
  useEffect(() => {

    // Call the fetchPatientDetails function whenever the searchValue changes
    console.log(searchValue, "-----")
    if (searchValue) {
      // fetchPatientDetails();
      setValues({
        ...values,
        patientName: searchValue.patientname, // Make sure field names match
        bloodGroup: searchValue.bloodgroup,   // Make sure field names match
        mobileNo: searchValue.phoneno,        // Make sure field names match
        address: searchValue.address,
        PID: searchValue.patientid,
        age: searchValue.age,
        gender: searchValue.gender,
        gaurdianname: searchValue.gaurdianname,
        hospitalcode: searchValue.hospitalcode,
        // Update other fields as needed
      });
    }
  }, [searchValue, token]);
  //when page load or refresh happen this function will got called
  useEffect(() => {
    const loadData = async () => {
      //await loadAllDetails();
      //loadHospitalcode(); will wait for loadAllDetails(); to got execute
      await loadHospitalcode();
    };
    loadData();
  }, [userHospitalcode, userrole1, isAuthorized]);
  //get all hospital code
  const loadHospitalcode = async () => {
    //when userHospitalcode and userrole1 is not undefined then execute /gethospitalcode
    if (userHospitalcode !== undefined && userrole1 !== undefined) {
      const res = await axios.get("/gethospitalcode", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          hospitalcode: userHospitalcode, // Include the hospitalcode in the query parameters
          userrole: userrole1, // Include the userrole in the query parameters
        },
      });
      setData(res.data);
      console.log(res.data);
    };
  }
  //When doctor change price change

const handleEdit = async (selectedDoctor) => {
  console.log("gfwehFFGWEFGIWRFERHERFIUGHEUGHROGGBJRGOIBGRJBJGF", selectedDoctor);
  if (selectedDoctor === null) {
      setGetrowdata({ doctorfees: " " });
      return; // Return early if selectedDoctor is null
  }
else{  
   const { doctorrecordid } = selectedDoctor; // Assuming 'doctorrecordid' is the property that uniquely identifies a doctor

try {
    const res = await axios.get(`/getdoctor/${doctorrecordid}`, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
    setGetrowdata(res.data[0]);
    setValues((values) => ({
        ...values,
        doctorrecordid: doctorrecordid, // Assuming 'doctorrecordid' is the property that holds the doctor's unique identifier
    }));
} catch (error) {
    console.error("Error fetching doctor details:", error);
    // Handle error, maybe display a message to the user
}}

};


  const currentDomain = window.location.hostname;
  return (
    <div className="p-3 pt-0 appointment" >
      <Helmet>
        <title>Appointment Scheduling System | {currentDomain} | Hospital Management Software | Smart Solutions for Hospital Efficiency</title>
      </Helmet>
      <form onSubmit={handleSubmit}>
        {success && (
          <div >
            Appointment confirm.
          </div>
        )}
        {error && (
          <div>
            Error in booking Appointment Please try again later.
          </div>
        )}
        <div class="fill header"><h3 class="float-left"> Appointment Form  </h3> </div>
        <div className="patient-new primary-info">

          <div className="row">
            <div className="col-md-12">
              <div className="row">
                <div className="col-md-6">

                  <div className="col-md-12 mt-3">
                    <div className="row">
                      <div className="col-md-4">
                        <label
                          htmlFor="OPDType"
                          className="col-form-label "
                        >
                          OPD Type
                        </label>
                      </div>
                      <div className="col-md-8">
                        <select
                          type="text"
                          autoComplete="off"
                          name="OPDType"
                          id="OPDType"
                          placeholder="OPD Type"
                          value={OPDType}
                          onChange={handleOpdChange}
                          style={{ width: '73%', height: '32px', border: '1px solid #ced4da ', color: '#495057' }}
                        >
                          <option>Visit</option>
                          <option>New</option>

                        </select>
                        {errors.OPDType && touched.OPDType ? (
                          <p className="form-error">
                            {errors.OPDType}
                          </p>
                        ) : null}
                      </div>
                    </div>

                  </div>
                  <div className="col-md-12">
                    <div className="row">
                      {OPDType !== "New" && (<div className="col-md-4 mt-3">
                        <label
                          htmlFor="PID"
                          className="col-form-label leftone"
                        >
                          Patient Id
                        </label>

                      </div>)}
                      {OPDType !== "New" && (<div className="col-md-8 mt-3">
                        <input
                          type="text"
                          autoComplete="off"
                          name="PID"
                          id="PID"
                          placeholder="Patient Id"
                          value={values.PID}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          disabled={OPDType === 'Visit'}
                          className="form-control"
                        />
                        {errors.PID && touched.PID ? (
                          <p className="form-error">{errors.PID}</p>
                        ) : null}
                      </div>)}
                    </div>

                  </div>
                  <div className="col-md-12 mt-3">
                    <div className="row">
                      {OPDType !== "Visit" && (<div className="col-md-4">
                        <label
                          htmlFor="age"
                          className="col-form-label leftone "
                        >
                          Patient Age
                        </label>
                      </div>)}
                      {OPDType !== "New" && (<div className="col-md-4">
                        <label
                          htmlFor="age"
                          className="col-form-label "
                        >
                          Patient Age
                        </label>
                      </div>)}
                      <div className="col-md-8">
                        <input
                          type="number"
                          autoComplete="off"
                          name="age"
                          id="age"
                          placeholder="Patient age"
                          // value={OPDType !== 'Visit' ? '' :values.age}
                          value={values.age}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          disabled={OPDType === 'Visit'}
                          className="form-control"
                        />
                        {errors.age && touched.age ? (
                          <p className="form-error">{errors.age}</p>
                        ) : null}
                      </div>
                    </div>

                  </div>
                  <div className="col-md-12 mt-3">
                    <div className="row">
                      {OPDType !== "New" && (<div className="col-md-4">
                        <label
                          htmlFor="gender"
                          className="col-form-label "
                        >
                          Patient Gender
                        </label>
                      </div>)}
                      {OPDType !== "Visit" && (<div className="col-md-4">
                        <label
                          htmlFor="gender"
                          className="col-form-label leftone  "
                        >
                          Patient Gender
                        </label>
                      </div>)}
                      {OPDType !== "New" && (<div className="col-md-8">
                        <input
                          type="text"
                          autoComplete="off"
                          name="gender"
                          id="gender"
                          placeholder="Patient gender"
                          // value={OPDType !== 'Visit' ? '' :values.gender}
                          value={values.gender}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          disabled={OPDType === 'Visit'}
                          className="form-control"
                        />
                        {/* <option>Select</option>
                <option>Male</option>
                <option>Female</option>
                <option>Other</option> */}
                        {/* </select> */}
                        {errors.gender && touched.gender ? (
                          <p className="form-error">{errors.gender}</p>
                        ) : null}
                      </div>)}
                      {OPDType !== "Visit" && (<div className="col-md-8">
                        <select
                          type="text"
                          autoComplete="off"
                          name="gender"
                          id="gender"
                          placeholder="Patient gender"
                          // value={OPDType !== 'Visit' ? '' :values.gender}
                          value={values.gender}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          disabled={OPDType === 'Visit'}
                          style={{ width: '73%', height: '32px', border: '1px solid #ced4da ', color: '#495057' }}
                        >
                          <option>Select</option>
                          <option>Male</option>
                          <option>Female</option>
                          <option>Other</option>
                        </select>
                        {errors.gender && touched.gender ? (
                          <p className="form-error">{errors.gender}</p>
                        ) : null}
                      </div>)}
                    </div>

                  </div>
                  {isAuthorized ? (<div className="col-md-12 mt-3">
                    <div className="row">
                      {OPDType !== "New" && (<div className="col-md-4">
                        <label
                          htmlFor="hospitalcode"
                          className="col-form-label "
                        >
                          Hospital Code
                        </label>
                      </div>)}
                      {OPDType !== "Visit" && (<div className="col-md-4">
                        <label
                          htmlFor="hospitalcode"
                          className="col-form-label leftone  "
                        >
                          Hospital Code
                        </label>
                      </div>)}
                      {OPDType !== "New" && (<div className="col-md-8">
                        {isAuthorized ? (<input
                          type="text"
                          autoComplete="off"
                          name="hospitalcode"
                          id="hospitalcode"
                          placeholder="Hospitalcode"
                          // value={OPDType !== 'Visit' ? '' :values.hospitalcode}
                          value={values.hospitalcode}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          disabled={OPDType === 'Visit'}
                          className="form-control"
                        />) : (
                          <input
                            type="hidden"
                            name="hospitalcode"
                            id="hospitalcode"
                            value={values.hospitalcode}
                            disabled={true} // Set the input as disabled
                            className="form-control "
                          />
                        )}
                        {errors.hospitalcode && touched.hospitalcode ? (
                          <p className="form-error">{errors.hospitalcode}</p>
                        ) : null}
                      </div>)}
                      {OPDType !== "Visit" && (<div className="col-md-8">
                        {isAuthorized ? (
                             <HospitalCodeSearch onSearchItem={onSearchItems} />
                          // <select
                          //   type="text"
                          //   autoComplete="off"
                          //   name="hospitalcode"
                          //   id="hospitalcode"
                          //   placeholder="Hospital Code"
                          //   // value={OPDType !== 'Visit' ? '' :values.hospitalcode}
                          //   value={values.hospitalcode}
                          //   onChange={handleChange}
                          //   onBlur={handleBlur}
                          //   style={{ width: '73%', height: '32px', border: '1px solid #ced4da', color: '#495057' }}
                          // >
                          //   <option>Select</option>
                          //   {data.map((code) => (
                          //     <option value={code.hospitalcode} key={code.id}>
                          //       {code.hospitalcode}
                          //     </option>
                          //   ))}
                          // </select>
                        ) : (
                          <input
                            type="hidden"
                            name="hospitalcode"
                            id="hospitalcode"
                            value={userHospitalcode}
                            disabled={true} // Set the input as disabled
                            className="form-control "
                          />
                        )}
                        {isAuthorized && errors.hospitalcode && touched.hospitalcode ? (
                          <p className="form-erroremployee">{errors.hospitalcode}</p>
                        ) : null}
                      </div>)}
                    </div>

                  </div>) : null}
                  <div className="col-md-12 mt-3">
                    <div className="row">
                      <div className="col-md-4">
                        <label
                          htmlFor="appointmentDate "
                          className="col-form-label "
                        >
                          Appointment Date
                        </label>

                      </div>
                      <div className="col-md-8">
                        <input
                          type="datetime-local"
                          autoComplete="off"
                          name="appointmentDate"
                          id="appointmentDate"
                          placeholder="Appointment Date"
                          value={currentDateTime}
                          onChange={(e) => setCurrentDateTime(e.target.value)}
                          className="form-control"
                        />

                        {errors.appointmentDate && touched.appointmentDate ? (
                          <p className="col-form-label">
                            {errors.appointmentDate}
                          </p>
                        ) : null}
                      </div>
                    </div>

                  </div>
                  <div className="col-md-12 mt-3">
                    <div className="row">
                      <div className="col-md-4">
                        <label
                          htmlFor="doctorName"
                          className="col-form-label leftone "
                        >
                          Doctor
                        </label>
                      </div>
                      <div className="col-md-8">
                        <Doctorsearch
                          onSearchChanges={handleSearchChanges}
                          hospitalvalue={values.hospitalcode || userHospitalcode}
                          handleEdit={handleEdit}
                        />

                        {/* <select
                          type="text"
                          autoComplete="off"
                          name="doctorrecordid"
                          id="doctorrecordid"
                          placeholder="Doctor Name"
                          value={values.doctorrecordid}
                          onChange={(e) => { handleEdit(e) }}
                          onBlur={handleBlur}
                          style={{ width: '73%', height: '32px', border: '1px solid #ced4da ', color: '#495057' }}

                        >
                          <option>Select</option>
                          {doctor.map((doctor) => (
                            <option
                              value={doctor.doctorrecordid}
                              key={doctor.id}
                            >
                              {doctor.fname}
                            </option>
                          ))}


                        </select> */}
                        {errors.doctorrecordid && touched.doctorrecordid ? (
                          <p className="form-error">
                            {errors.doctorrecordid}
                          </p>
                        ) : null}
                      </div>
                    </div>

                  </div>
                  <div className="col-md-12 mt-3">
                    <div className="row">
                      <div className="col-md-4">
                        <label
                          htmlFor="paymentMode"
                          className="col-form-label "
                        >
                          Payment Mode

                        </label>
                      </div>
                      <div className="col-md-8">
                      <select
                                        type="text"
                                        autoComplete="off"
                                        name="paymentmode"
                                        id="paymentmode"
                                        placeholder="Payment Mode"
                                        value={values.paymentmode}
                                         onChange={handleChange}
                onBlur={handleBlur}
                                        style={{ width: '73%', height: '32px', border: '1px solid #ced4da ', color: '#495057' }}
                                    >
                                        <option>Cash</option>
                                        <option>Cheque</option>
                                        <option>Upi</option>
                                        <option>Online</option>
                                        <option>Other</option>
                                    </select>
                        {errors.paymentMode && touched.paymentMode ? (
                          <p className="col-form-label">{errors.paymentMode}</p>
                        ) : null}
                      </div>
                    </div>

                  </div>
                     <div className="col-md-12 mt-3">
                     {userHospitalcode !== 2 ? (    <div className="row">
                     
                        <div className="col-md-4">
                          <label htmlFor="weight" className="col-form-label">
                            Weight
                          </label>
                        </div>
                 <div className="col-md-8">
                        <input
                          type="text"
                          autoComplete="off"
                          name="weight"
                          id="weight"
                          placeholder="Weight"
                          value={values.weight}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          style={{ width: '73%', height: '32px', border: '1px solid #ced4da ', color: '#495057' }}
                        />

                        {errors.weight && touched.weight ? (
                          <p className="form-error">{errors.weight}</p>
                        ) : null}
                      </div> 
                    </div>) : (
                      null
                    )}

                  </div>
                     <div className="col-md-12 mt-3">
                     {userHospitalcode !== 2 ? (   <div className="row">
                      <div className="col-md-4">
                        <label
                          htmlFor="pulse"
                          className="col-form-label"
                        >
                          Pulse
                        </label>
                      </div>
                      <div className="col-md-8">
                        <input
                          type="text"
                          autoComplete="off"
                          name="pulse"
                          id="pulse"
                          placeholder="Pulse"
                          value={values.pulse}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          style={{ width: '73%', height: '32px', border: '1px solid #ced4da ', color: '#495057' }}
                        />

                        {errors.pulse && touched.pulse ? (
                          <p className="form-error">{errors.pulse}</p>
                        ) : null}
                      </div>
                    </div> ) : (
                        null
                      )}


                  </div>
                  
                </div>
                <div className="col-md-6">
                  <div className="col-md-12 ">
                    <div className="row">
                      {OPDType !== "New" && (<div className="col-md-4 mt-3">
                        <label
                          className="col-form-label "
                        >
                          Search Patient
                        </label>
                      </div>)}
                      {OPDType !== "New" && (
                        <div className="col-md-8 mt-3">
                          <Searchbar onSearchChange={handleSearchChange} />
                         
                        </div>)}
                    </div>

                  </div>
                  <div className="col-md-12 mt-3">
                    <div className="row">
                      <div className="col-md-4">
                        <label
                          htmlFor="patientName"
                          className="col-form-label leftone"
                        >
                          Patient Name
                        </label>
                      </div>
                      <div className="col-md-8">
                        <input
                          type="text"
                          autoComplete="off"
                          name="patientName"
                          id="patientName"
                          placeholder="Patient Name"
                          // value={OPDType !== 'Visit' ? '' :values.patientName}
                          value={values.patientName}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          disabled={OPDType === 'Visit'}
                          className="form-control"
                        />
                        {errors.patientName && touched.patientName ? (
                          <p className="form-error">{errors.patientName}</p>
                        ) : null}
                      </div>
                    </div>

                  </div>
                  <div className="col-md-12 mt-3">
                    <div className="row">
                      {OPDType !== "Visit" && (<div className="col-md-4">
                        <label
                          htmlFor="mobileNo"
                          className="col-form-label leftone "
                        >
                          Mobile No
                        </label>
                      </div>)}
                      {OPDType !== "New" && (<div className="col-md-4">
                        <label
                          htmlFor="mobileNo"
                          className="col-form-label"
                        >
                          Mobile No
                        </label>
                      </div>)}
                      <div className="col-md-8">
                        <input
                          type="number"
                          autoComplete="off"
                          name="mobileNo"
                          id="mobileNo"
                          placeholder="Mobile No"
                          // value={OPDType !== 'Visit' ? '' :values.mobileNo}
                          value={values.mobileNo}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          disabled={OPDType === 'Visit'}
                          className="form-control"
                        />
                        {errors.mobileNo && touched.mobileNo ? (
                          <p className="form-error">{errors.mobileNo}</p>
                        ) : null}
                      </div>
                    </div>

                  </div>
                  <div className="col-md-12 mt-3">
                    <div className="row">
                      {OPDType !== "Visit" && (<div className="col-md-4">
                        <label
                          htmlFor="gaurdianname"
                          className="col-form-label leftone  "
                        >
                          Address
                        </label>
                      </div>)}
                      {OPDType !== "New" && (<div className="col-md-4">
                        <label
                          htmlFor="gaurdianname"
                          className="col-form-label"
                        >
                          Address
                        </label>
                      </div>)}
                      <div className="col-md-8">
                        <textarea
                          // style={{ width: "210px", height: "50px" }}
                          rows="1"
                          cols="30"
                          type="text"
                          autoComplete="off"
                          name="address"
                          id="address"
                          placeholder="Address"
                          // value={OPDType !== 'Visit' ? '' :values.address}
                          value={values.address}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          disabled={OPDType === 'Visit'}
                          style={{ width: '73%', height: '32px', border: '1px solid #ced4da ', color: '#495057' }}
                        />
                        {errors.address && touched.address ? (
                          <p className="form-error">{errors.address}</p>
                        ) : null}
                      </div>
                    </div>

                  </div>
                  <div className="col-md-12 mt-3">
                    <div className="row">
                      <div className="col-md-4">
                        <label
                          htmlFor="bloodGroup"
                          className="col-form-label"
                        >
                          Blood Group
                        </label>
                      </div>
                      <div className="col-md-8">
                        <input
                          type="text"
                          autoComplete="off"
                          name="bloodGroup"
                          id="bloodGroup"
                          placeholder="Blood Group"
                          // value={OPDType !== 'Visit' ? '' :values.bloodGroup}
                          value={values.bloodGroup}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          disabled={OPDType === 'Visit'}
                          className="form-control"
                        />

                        {errors.bloodGroup && touched.bloodGroup ? (
                          <p className="col-form-label">
                            {errors.bloodGroup}
                          </p>
                        ) : null}
                      </div>
                    </div>

                  </div>
                  <div className="col-md-12 mt-3">
                    <div className="row">
                      <div className="col-md-4">
                        <label
                          htmlFor="doctorFee"
                          className="col-form-label"
                        >
                          Doctor Fees
                        </label>
                      </div>
                      <div className="col-md-8">
                        <input
                          type="text"
                          autoComplete="off"
                          name="doctorFee"
                          id="doctorFee"
                          placeholder="Doctor Fee"
                          value={getrowdata.doctorfees}
                          // onChange={handleChange}
                          // onBlur={handleBlur}
                          disabled
                          className="form-control"
                        />
                        {errors.doctorFee && touched.doctorFee ? (
                          <p className="col-form-label">{errors.doctorFee}</p>
                        ) : null}
                      </div>
                    </div>

                  </div>
                  <div className="col-md-12 mt-3">
                    <div className="row">
                      <div className="col-md-4">
                        <label
                          htmlFor="gaurdianname"
                          className="col-form-label "
                        >
                          Patient Guardian

                        </label>
                      </div>
                      <div className="col-md-8">
                        <input
                          type="text"
                          autoComplete="off"
                          name="gaurdianname"
                          id="gaurdianname"
                          placeholder="Guardian Name"
                          // value={OPDType !== 'Visit' ? '' :values.gaurdianname}
                          value={values.gaurdianname}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          disabled={OPDType === 'Visit'}
                          className="form-control"
                        />
                        {errors.gaurdianname && touched.gaurdianname ? (
                          <p className="col-form-label">{errors.gaurdianname}</p>
                        ) : null}
                      </div>
                    </div>

                  </div>
                  <div className="col-md-12 mt-3">
                  {userHospitalcode !== 2 ? (   <div className="row">
                      <div className="col-md-4">
                        <label
                          htmlFor="height"
                          className="col-form-label "
                        >
                          Height

                        </label>
                      </div>
                      <div className="col-md-8">
                        <input
                          type="text"
                          autoComplete="off"
                          name="height"
                          id="height"
                          placeholder="Height"
                          value={values.height}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          // disabled={OPDType === 'Visit'}
                          className="form-control"
                        />
                        {errors.height && touched.height ? (
                          <p className="form-error">{errors.height}</p>
                        ) : null}
                      </div>
                    </div>) : (
  null
)}

                  </div>
                  <div className="col-md-12 mt-3">
                  {userHospitalcode !== 2 ? (     <div className="row">
                      <div className="col-md-4">
                        <label
                          htmlFor="bp"
                          className="col-form-label "
                        >
                          BP

                        </label>
                      </div>
                      <div className="col-md-8">
                        <input
                          type="text"
                          autoComplete="off"
                          name="bp"
                          id="bp"
                          placeholder="BP"
                          value={values.bp}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          //disabled={OPDType === 'Visit'}
                          className="form-control"
                        />
                        {errors.bp && touched.bp ? (
                          <p className="form-error">{errors.bp}</p>
                        ) : null}
                      </div>
                    </div>) : (
  null
)}

                  </div>
                  <div className="col-md-12 mt-3">
                  {userHospitalcode !== 2 ? (   <div className="row">
                      <div className="col-md-4">
                        <label
                          htmlFor="temperature"
                          className="col-form-label"
                        >
                          Temperature
                        </label>
                      </div>
                      <div className="col-md-8">
                        <input
                          type="text"
                          autoComplete="off"
                          name="temperature"
                          id="temperature"
                          placeholder="Temperature"
                          value={values.temperature}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          style={{ width: '73%', height: '32px', border: '1px solid #ced4da ', color: '#495057' }}
                        />

                        {errors.temperature && touched.temperature ? (
                          <p className="form-error">{errors.temperature}</p>
                        ) : null}
                      </div>
                    </div> ) : (
                        null
                      )}

                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <input
              type="hidden"
              autoComplete="off"
              name="PID"
              id="PID"
              value={values.PID}
            /> */}
          <input
            type="hidden"
            autoComplete="off"
            name="insertby"
            id="insertby"
            value={gettokenId}
          />
          <input
            type="hidden"
            autoComplete="off"
            name="insertdate"
            id="insertdate"
            value={currentDate}
          />


          <div className="col-md-6  mt-3">

            <Button type="submit" variant="success" className="mx-3">
              Save
            </Button>
            <Button type="submit" variant="primary" className="mx-3" onClick={() => printForm(values, "printandsave")}>
              Save And Print
            </Button>
          </div>
          {isPopup == true ?
            <>

              <Modal show={true} animation={false} onHide={handleClosed} size="lg">
                <Modal.Header closeButton>
                  <Modal.Title>{opdrecordnumber}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  {opdrecordnumber == undefined ? ""
                    :
                    // <PrintTemplateAppointment pId={opdrecordnumber} handleSubmit={handleClosed} apppoinentDate={appointmentDate}/>}
                    <PrintTemplateAppointment pId={opdrecordnumber} handleSubmit={handleClosed} />}
                </Modal.Body>
              </Modal>
            </>
            :
            ""}
        </div>
      </form>
      <ToastContainer />
    </div>
  );
};

export default NewAppoinment;