import React, { useState, useEffect } from "react";
import { Table, Button, Modal, Form } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import axios from "../../../helper/axios";
import { IoTrashOutline } from "react-icons/io5";
import { Helmet } from "react-helmet";
import { NavLink, useHistory } from "react-router-dom";
import MUIDataTable from "mui-datatables";
import IconButton from '@mui/material/IconButton';
import PrintIcon from '@mui/icons-material/Print';
import DeleteIcon from '@mui/icons-material/Delete';
import { DateRangePicker } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { useUser } from "../../../Provider";
import PrintTemplatePharmacyPurchase from "../../../Component/PrintTemplatePharmacyPurchase/PrintTemplatePharmacyPurchase";

const PharmacyPurchaseList = () => {
  const {user, userHospitalcode, userrole1, isAuthRole,isAuthorized,gettokenId,token  } = useUser();
  const history = useHistory();
  const [data, setData] = useState([]);
  const [supplierid, setSid] = useState();
  const [medicinepurchasedetailsid, setBid] = useState();
  const [purchaseid, setPid] = useState();
  const [isPopup, setIsPopup] = useState(false);
  const currentDate = new Date();
  const day = currentDate.getDate();
  const month = currentDate.getMonth() + 1; // Months are zero-indexed, so add 1
  const year = currentDate.getFullYear();
 
  useEffect(() => {
    const loadData = async () => {
     // await loadAllDetails();
      //loadAllPatients(); will wait for loadAllDetails(); to got execute
      await loadAllPatients();
    };

    loadData();
  }, [userrole1,userHospitalcode]);
  // Format the date as DD-MM-YYYY
  const formattedDate = `${day}-${month}-${year}`;
 // const token = sessionStorage.getItem("jwtToken");
  let columns;
  if (isAuthorized) {
   columns = [
    {
      name: "purchaseid",
      label: "Purchase Number",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta) => {
          // Assuming the patient id is also available in your data
          const purchaseid= tableMeta.rowData[0]; // Adjust this index as needed

          return (
            <NavLink to={`/editpurchase/${purchaseid}`} className="addlink">{value}</NavLink>
          );
        }
      },
    },
   
    {
      name: "suppliername",
      label: "Supplier Name",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "expirydate",
      label: "Expiry Date",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "medicinecode", // New column for displaying medicinecode
      label: "Medicine",
      options: {
        filter: true,
        sort: true, // You can't sort this column
       // customBodyRender: This function is typically used to customize the rendering of cell content within the table's body.
        customBodyRender: (value) => value.join(", "), // Render medicinecode as a comma-separated list
      },
    },
    {
      name: "hospitalcode",
      label: "Hospital Code",
      options: {
       filter: true,
       sort: true,
       customBodyRender: (value) => (value !== null && value !== undefined) ? value.toString().padStart(5, '0') : ""
      }
     },
    {
      name: "",
      label: "",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          const newD = data.find((e) => {
            return (
              e.purchaseid == tableMeta.rowData[0]
             && e.suppliername== tableMeta.rowData[1]
             && e.expirydate == tableMeta.rowData[2]
               && e.medicinecode == tableMeta.rowData[3]
            );
          });
        
          // if (newD) {
          return (
            <>
             <IconButton color="primary" onClick={() => printForm(value, tableMeta, updateValue)}>
            <PrintIcon  />
          </IconButton>
             <IconButton color="secondary" onClick={() => cancelBilling(newD.purchaseid)}>
              <DeleteIcon />
            </IconButton>
            </>
          );
        // }
        },
      },
    },
  ];
}
else{
  columns = [
    {
      name: "purchaseid",
      label: "Purchase Number",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta) => {
          // Assuming the patient id is also available in your data
          const purchaseid= tableMeta.rowData[0]; // Adjust this index as needed

          return (
            <NavLink to={`/editpurchase/${purchaseid}`} className="addlink">{value}</NavLink>
          );
        }
      },
    },

    {
      name: "suppliername",
      label: "Supplier Name",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "expirydate",
      label: "Expiry Date",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "medicinecode", // New column for displaying medicinecode
      label: "Medicine",
      options: {
        filter: true,
        sort: true, // You can't sort this column
       // customBodyRender: This function is typically used to customize the rendering of cell content within the table's body.
        customBodyRender: (value) => value.join(", "), // Render medicinecode as a comma-separated list
      },
    },
    {
      name: "",
      label: "",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          const newD = data.find((e) => {
            return (
              e.purchaseid == tableMeta.rowData[0]
             && e.suppliername== tableMeta.rowData[1]
             && e.expirydate == tableMeta.rowData[2]
               && e.medicinecode == tableMeta.rowData[3]
            );
          });
          return (
            <>
             <IconButton color="primary" onClick={() => printForm(value, tableMeta, updateValue)}>
            <PrintIcon  />
          </IconButton>
             <IconButton color="secondary" onClick={() => cancelBilling(newD.purchaseid)}>
              <DeleteIcon />
            </IconButton>
            </>
          );
        },
      },
    },
  ];
}
  // preprocessData: This function takes originalData as input, which seems to be an array of objects with properties like medicinepurchasedetailsid,
  // chargecode, etc. It processes this data to group rows with the same medicinepurchasedetailsid together and collect their chargecode values into an array. 
  // The result is an array of objects with unique medicinepurchasedetailsid values and their associated chargecodes.
  const preprocessData = (originalData) => {
    const processedData = [];
    const uniqueBillIds = new Set();

    originalData.forEach((row) => {
      const medicinepurchasedetailsid = row.medicinepurchasedetailsid;

      if (!uniqueBillIds.has(medicinepurchasedetailsid)) {
        uniqueBillIds.add(medicinepurchasedetailsid);
        processedData.push({
          ...row,
          medicinecode: [row.medicinename], // Initialize an array with the first chargecode
        });
      } else {
        // Find the existing row with the same medicinepurchasedetailsid and add the chargecode
        const existingRow = processedData.find((item) => item.medicinepurchasedetailsid === medicinepurchasedetailsid);
        existingRow.medicinecode.push(row.medicinename);
      }
    });

    return processedData;
  };
  const cancelBilling = async (purchaseid) => {
    // Display a confirmation dialog
    const isConfirmed = window.confirm("Do you want to cancel this bill?");
  
    if (isConfirmed) {
      try {
        await axios.post(`/cancelpharmacypurchase/${purchaseid}`, null, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        
        //alert("Bill has been canceled.");
        
        // After successfully canceling the bill, refresh the bill list.
        loadAllPatients();
      } catch (error) {
        // Handle any error here.
       alert("Error cancelling bill:", error);
      }
    } else {
      // User clicked "Cancel" in the confirmation dialog, do nothing.
    }
  };
  // loadAllPatients: This is an asynchronous function that makes a GET request to a backend API endpoint ("/GetPatientsBilling") to retrieve patient billing data. 
  // It sorts the data based on the appointmentdate property in descending order and then processes the sorted data using the preprocessData function.
  //  The processed data is stored in the data state variable.

  const loadAllPatients = async () => {
    try {
      if (userHospitalcode !== undefined && userrole1 !== undefined) {
       
        const res = await axios.get("/getpurchasedetails", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: {
            hospitalcode: userHospitalcode,
            userrole: userrole1,
          },
        });
       
        if (res.status === 200) {
          const sortedList = [...res.data].sort((a, b) => {
            const dateA = new Date(a.appointmentdate);
            const dateB = new Date(b.appointmentdate);
            return dateB - dateA;
          });
          setData(preprocessData(sortedList));
          // console.log(preprocessData(sortedList))
        } else {
          alert(`Unexpected status code: ${res.status}`);
        }
      } else {
        // console.log("userHospitalcode is undefined");
      }
    } catch (error) {
      alert(`An error occurred: ${error.message}`);
    }
  };
  //handleClose: This function is used to set the isPopup state to false, presumably to close a popup or modal.
  const handleClose = () => {
    setIsPopup(false)
  }

  const printForm = async (value, tableMeta, updateValue) => {
    const newD = data.find((e) => {
      return  e.purchaseid == tableMeta.rowData[0]
      && e.suppliername== tableMeta.rowData[1]
      && e.expirydate == tableMeta.rowData[2]
        && e.medicinecode == tableMeta.rowData[3]
    });
  console.log("--------------",newD.purchaseid)
  console.log("--------------egrfuewhfouaweh",newD.medicinepurchasedetailsid)
    if (newD) {
      await setBid(newD.medicinepurchasedetailsid);
      await setPid(newD.purchaseid);
      await setSid(newD.supplierid );
      await setIsPopup(true);
    } else {
      console.error("Data not found for selected row:", tableMeta.rowData);
    }
  };
  

  const options = {
    selectableRows: false,
    onRowClick: ((props) => {
      console.log(props)
    }),
    downloadOptions: {
      filename: `Billing_${formattedDate}`,// Customize the filename here to download
      filterOptions: {
        useDisplayedColumnsOnly: true,
        useDisplayedRowsOnly: true,
      },
    },
  };
  const currentDomain = window.location.hostname;
  return (
    <>
      <div style={{marginLeft: "auto"}}>
      <Helmet>
        <title>Billing Records | {currentDomain} | Hospital Management Software | Smart Solutions for Hospital Efficiency</title>
      </Helmet>
      <NavLink to='/newpharmacypurchase'>
        <button className="btn btn-success btn-sm mr-2" style={{margin: "10px"}}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="19"
            height="18"
            style={{ paddingRight: "4px" }}
            viewBox="0 0 100 100"
          >
            <line
              x1="10"
              y1="50"
              x2="90"
              y2="50"
              stroke="white"
              stroke-width="10"
            />
            <line
              x1="50"
              y1="10"
              x2="50"
              y2="90"
              stroke="white"
              stroke-width="10"
            />
          </svg>
          New Purchase
        </button>
      </NavLink>
      </div>
      <MUIDataTable
        title={"Purchase List"}
        data={data}
        columns={columns}
        options={options}
        className="custom-mui-datatable"
      />


        {isPopup==true?
          <>

          <Modal show={true}  animation={false} onHide={handleClose} size="lg">
            <Modal.Header closeButton>
              <Modal.Title>Bill Id:- {medicinepurchasedetailsid}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            {medicinepurchasedetailsid==undefined?""
              :
              <PrintTemplatePharmacyPurchase medicinepurchasedetailsid={medicinepurchasedetailsid} supplierid={supplierid} purchaseid={purchaseid} handleClose={handleClose}/>}
              {/* <PrintTemplateBillingView bId={medicinepurchasedetailsid} patientId={patientId} handleClose={handleClose}/> */}
            </Modal.Body>
          </Modal>
        </> 
        :
        ""}
    </>


  );
};

export default PharmacyPurchaseList;