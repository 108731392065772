import React from "react";
import { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useHistory } from "react-router-dom";
import axios from "../../helper/axios";
import { Table, Button, Modal, Form, NavLink } from "react-bootstrap";
import { useUser } from "../../Provider";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import * as Yup from "yup";
import API from "../../helper/axios";
import { useFormik } from 'formik';
const validationSchema = Yup.object().shape({
  hospitalname: Yup.string().required("Hospital Name is required"),
  registrationno: Yup.number()
    .integer("Registration number must be an integer")
    .positive("Registration number must be a positive number")
    .required("Please enter the registration number"),
  phone: Yup.string().min(10).max(10).required("Please enter the phone number "),
  address: Yup.string().required("Please enter the  address"),
  email: Yup.string().required("Please enter the email"),
  noofuser: Yup.number()
  .transform((value, originalValue) => (originalValue === "" ? null : value))
  .positive("Number of users must be a positive number").nullable(),
  amountpaid: Yup.number()
  .transform((value, originalValue) => (originalValue === "" ? null : value))
  .positive("Amountpaid must be a positive number")
  .nullable(),
  licenserate: Yup.number()
  .positive("Licenserate must be a positive number").nullable(),
  hospitashortcode: Yup.string().required("Hospital Name is required"),
  birthcertificate:  Yup.mixed().nullable()
  // .test(
  //   'fileSize',
  //   'File size should be less than 3MB',
  //   (value) => !value || (value && value.size <= 3000000)
  // )
  ,
  deathcertificate:  Yup.mixed().nullable()
  // .test(
  //   'fileSize',
  //   'File size should be less than 3MB',
  //   (value) => !value || (value && value.size <= 3000000)
  // )
  ,
  hospitallogo: Yup.mixed().nullable()
  // .test(
  //   'fileSize',
  //   'File size should be less than 3MB',
  //   (value) =>!value || (value && value.size <= 3000000)
  // )
  ,
  //hospitalfavicon: Yup.string().nullable(),
  attachone:Yup.mixed().nullable()
  // .test(
  //   'fileSize',
  //   'File size should be less than 3MB',
  //   (value) => !value || (value && value.size <= 3000000)
  // )
  ,
  attachtwo: Yup.mixed().nullable()
  // .test(
  //   'fileSize',
  //   'File size should be less than 3MB',
  //   (value) => !value || (value && value.size <= 3000000)
  // )
  ,
  //attachthree: Yup.string().nullable(),
  workhour: Yup.number()
  .positive("Working hour must be a positive number").nullable(),
 time:Yup.string().nullable(),
 licenseduration:Yup.string().nullable(),
 licenseexpirydate: Yup.string().nullable(),
 remarks: Yup.string().nullable(),
});

const initialValues = {
  hospitalname:"",
  hospitashortcode: "",
  registrationno: "",
  phone: "",
  address: "",
  email: "",
  currency: "",
  currencysymbol: "",
  creditlimit: "",
  time: "",
  workhour: "",
  pathologyheader: "",
  radiologyheader: "",
  billingheader: "",
  prescriptionheader: "",
  birthcertificate: "",
  deathcertificate: "",
  licenseduration: "",
  licenserate: "",
  amountpaid: "",
  licenseexpirydate: "",
  noofuser: "",
  remarks: "",
  hospitallogo: "",
  hospitalfavicon: "",
  attachone: "",
  attachtwo: "",
  attachthree: "",
};

//This is the main component for HospitalRegistration form
 const HospitalDetail = () => {
  const { hospitalcode } = useParams();
  const {user, userHospitalcode, userrole1, isAuthRole,isAuthorized,gettokenId ,token } = useUser();
  // console.log(user, userHospitalcode, userrole1, isAuthRole,isAuthorized,gettokenId )
  // const[hospital,setHospital]=useState( {
  //   hospitalname:"",
  //   hospitashortcode: "",
  //   registrationno: "",
  //   phone: "",
  //   address: "",
  //   email: "",
  //   currency: "",
  //   currencysymbol: "",
  //   creditlimit: "",
  //   time: "",
  //   workhour: "",
  //   pathologyheader: "",
  //   radiologyheader: "",
  //   billingheader: "",
  //   prescriptionheader: "",
  //   birthcertificate: "",
  //   deathcertificate: "",
  //   licenseduration: "",
  //   licenserate: "",
  //   amountpaid: "",
  //   licenseexpirydate: "",
  //   noofuser: "",
  //   remarks: "",
  //   hospitallogo: "",
  //   hospitalfavicon: "",
  //   attachone: "",
  //   attachtwo: "",
  //   attachthree: "",
  // })
  const history = useHistory();
  const [data, setData] = useState({});
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
 // const token = sessionStorage.getItem("jwtToken");
  //get the current date
  let Showdate = new Date();
  let ShowTodaydate =
    Showdate.getFullYear() +
    "-" +
    (Showdate.getMonth() + 1) +
    "-" +
    Showdate.getDate();
  const [currentDate, setCurrentDate] = useState(ShowTodaydate);

  const {
    values,
    errors,
    touched,
    setValues,
    handleChange,
    handleBlur,
    resetForm,
    handleSubmit,
} = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values, { resetForm }) => {
        console.log(values); // Log the values to check if they are captured correctly

        try {
          // Validate the form using Yup schema
        
      
          // If validation succeeds, proceed with form submission
          const data = new FormData();
          data.append("document", JSON.stringify(values));
          data.append("birthcertificate", file);
          data.append("deathcertificate", deathcertificatefile);
          data.append("hospitallogofile", hospitallogofile);
          data.append("hospitalfaviconfile", hospitalfaviconfile);
          data.append("attachonefile", attachonefile);
          data.append("attachtwofile", attachtwofile);
          data.append("attachthreefile", attachthreefile);
          data.append("currentDate", JSON.stringify(currentDate));
          data.append("gettokenId", JSON.stringify(gettokenId));
          console.log( attachtwofile);
          console.log(attachthreefile);
      
          // Submit your data to the server
          const response = await axios.post(`/hospitaldetailupdate/${hospitalcode}`, data, {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "multipart/form-data",
            },
          });
      
          if (response.status === 200) {
            return history.push("/hospital-list");
          } else {
            console.error("Server returned a non-200 status code:", response.status);
          }
        } catch (err) {
          // Handle validation errors or submission errors
          console.error("Error occurred while submitting:", err);
        }
    },
});
const [file, setFile] = useState(null);
const [deathcertificatefile, setDeathcertificateFile] = useState(null);
const [hospitallogofile, setHospitallogoFile] = useState(null);
const [hospitalfaviconfile, setHospitalfaviconFile] = useState(null);
const [attachonefile, setAttachoneFile] = useState(null);
const [attachtwofile, setAttachtwoFile] = useState(null);
const [attachthreefile, setAttachthreeFile] = useState(null);

const loadHospitalLogo = async () => {
  //when userHospitalcode is not undefined

    const res = await axios.get(`/gethospitalattachment/${hospitalcode}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    setData(res.data[0]);
    console.log(res.data[0]);
  };
const handleFileChangeBirthCertificate = (event) => {
  const selectedFile = event.target.files[0];
  if (selectedFile) {
    // Set the file in the state
    setFile(selectedFile);

    // Set the file name as the value of the "birthcertificate" field
    handleChange({
      target: {
        name: "birthcertificate",
        value: selectedFile,
        size: selectedFile.size,
      },
    });
  }
};
 // Add a new function to handle file change and extract the file name:
 const handleFileChangeDeathCertificate = (event) => {
  const selectedFiles = event.target.files[0];
  if (selectedFiles) {
    // Set the file in the state
    setDeathcertificateFile(selectedFiles);
  handleChange({
    target: {
      name: "deathcertificate",
      value: selectedFiles,
      size: selectedFiles.size,
    },
  });
}
 };
 // Add a new function to handle file change and extract the file name:
 const handleFileChangeHospitallogo = (event) => {
  const hospitallogFiles = event.target.files[0];
  if (hospitallogFiles) {
    // Set the file in the state
    setHospitallogoFile(hospitallogFiles);

    // Get the size of the image
    const fileSizeInBytes = hospitallogFiles.size;
    handleChange({
      target: {
        name: "hospitallogo",
        value: hospitallogFiles,
        size: fileSizeInBytes,
      },
    });
  }
};
 // Add a new function to handle file change and extract the file name:
 const handleFileChangeAttachone = (event) => {
  const attachoneFiles = event.target.files[0];
  if (attachoneFiles) {
    // Set the file in the state
    setAttachoneFile(attachoneFiles);
  handleChange({
    target: {
      name: "attachone",
      value: attachoneFiles,
      size: attachoneFiles.size,
    },
  });
}
};
 // Add a new function to handle file change and extract the file name:
const handleFileChangeAttachtwo = (event) => {
  const attachtwoFiles = event.target.files[0];
  if (attachtwoFiles) {
    // Set the file in the state
    setAttachtwoFile(attachtwoFiles);
  handleChange({
    target: {
      name: "attachtwo",
      value: attachtwoFiles,
      size: attachtwoFiles.size,
    },
  });
}
 };
 // Add a new function to handle file change and extract the file name:
// const handleFileChangeAttachthree = (event) => {
//   const attachthreeFiles = event.target.files[0];
//   if (attachthreeFiles) {
//     // Set the file in the state
//     setAttachthreeFile(attachthreeFiles);
//   handleChange({
//     target: {
//       name: "attachthree",
//       value: attachthreeFiles,
//     },
//   });
// }
// };
//  // Add a new function to handle file change and extract the file name:
//  const handleFileChangeHospitalfavicon = (event) => {
//   const hospitalfaviconFiles = event.target.files[0];
//   if (hospitalfaviconFiles) {
//     // Set the file in the state
//     setHospitalfaviconFile(hospitalfaviconFiles);
//   handleChange({
//     target: {
//       name: "hospitalfavicon",
//       value:hospitalfaviconFiles,
//     },
//   });
// }
 //};
 const [fileName, setFileName] = useState('');
 const [filehospitalfavicon, setFilehospitalfavicon] = useState('');
 const [fileattachment1, setFileattachment1] = useState('');
 const [fileattachment2, setFileattachment2] = useState('');
 const [fileattachment3, setFileattachment3] = useState('');
 const [filebirthcertificate, setFilebirthcertificate] = useState('');
 const [filedeathcertificate, setFiledeathcertificate] = useState('');
 const loadHospital = async () => {
  if (userHospitalcode !== undefined && userrole1 !== undefined) {
    try {
      const res = await axios.get(`/gethospitaldetail/${hospitalcode}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          hospitalcode: userHospitalcode,
          userrole: userrole1,
        },
      });

      const newProduct = await res.data;
     // console.log(newProduct);

      // Set hospital details
      setValues(newProduct);

      // Check if 'hospitallogo' field exists and has a value
      if (newProduct.hospitallogo) {
        // Extracting the file name from the string value
        const fileName = newProduct.hospitallogo;
        // Set the extracted file name to the state variable 'hospitallogofile'
        setFileName(fileName);
      }
       // Check if 'hospitalfavicon' field exists and has a value
       if (newProduct.hospitalfavicon) {
        // Extracting the file name from the string value
        const filehospitalfavicon = newProduct.hospitalfavicon;
        // Set the extracted file name to the state variable 'hospitalfaviconfile'
        setFilehospitalfavicon(filehospitalfavicon);
      }
      // Check if 'attachment1' field exists and has a value
      if (newProduct.attachment1) {
        // Extracting the file name from the string value
        const fileattachment1 = newProduct.attachment1;
        // Set the extracted file name to the state variable 'attachment1file'
        setFileattachment1(fileattachment1);
      }
      // Check if 'attachment2' field exists and has a value
      if (newProduct.attachment2) {
        // Extracting the file name from the string value
        const fileattachment2 = newProduct.attachment2;
        // Set the extracted file name to the state variable 'attachment1file'
        setFileattachment2(fileattachment2);
      }
      // Check if 'attachment3' field exists and has a value
      if (newProduct.attachment3) {
        // Extracting the file name from the string value
        const fileattachment3= newProduct.attachment3;
        // Set the extracted file name to the state variable 'attachment1file'
        setFileattachment3(fileattachment3);
      }
       // Check if 'birthcertificate' field exists and has a value
       if (newProduct.birthcertificate) {
        // Extracting the file name from the string value
        const filebirthcertificate= newProduct.birthcertificate;
        // Set the extracted file name to the state variable 'attachment1file'
        setFilebirthcertificate(filebirthcertificate);
      }
        // Check if 'deathcertificate' field exists and has a value
        if (newProduct.deathcertificate) {
          // Extracting the file name from the string value
          const filedeathcertificate= newProduct.deathcertificate;
          // Set the extracted file name to the state variable 'attachment1file'
          setFiledeathcertificate(filedeathcertificate);
        }
    } catch (error) {
      console.error("Error fetching hospital details:", error);
      // Handle errors as needed
    }
  }
};
useEffect(()=>{
const loadData = async () => {
  //loadHospitalcode(); will wait for loadAllDetails(); to got execute
  await loadHospital();
  await loadHospitalLogo();
};
loadData();
}, [userHospitalcode, userrole1, isAuthorized]);
const currentDomain = window.location.hostname;

//this is the return function where we will do the UI changes
  return (
    <div className="p-3 pt-0 appointment">
       <Helmet>
        <title>Hospital Details | {currentDomain} | Hospital Management Software | Smart Solutions for Hospital Efficiency</title>
      </Helmet>
      <form onSubmit={handleSubmit}>
        {success && <div>Hospital registered</div>}
        {error && <div>Error in registering hospital. please try again</div>}
       
        <div class="fill header"><h3 class="float-left">Hospital Registration </h3> </div>
        <div className="hospital-new primary-info">
        <div className="row  ml-4">
          <div className="col-md-12 ">
            <div className="row">
              <div className="col-md-2">
                <label
                  htmlFor="hospitalname"
                  className="col-form-label leftone"
                >
                  Hospital Name
                </label>
              </div>
              <div className="col-md-4">
                <input
                  type="text"
                  autoComplete="off"
                  name="hospitalname"
                  id="hospitalname"
                  placeholder="Hospital Name"
                  value={values.hospitalname}
                 onChange={handleChange}
                onBlur={handleBlur}
                  className="form-control"
                />
                 {errors.hospitalname && (
        <p style={{ color: "red" }}>{errors.hospitalname}</p>
      )}
                
              </div>
              <div className="col-md-2">
                <label
                  htmlFor="registrationno "
                  className="col-form-label leftone"
                >
                  Registration Number
                </label>
              </div>
              <div className="col-md-4">
                <input
                  type="number"
                  autoComplete="off"
                  name="registrationno"
                  id="registrationno"
                  placeholder="Registration Number"
                  value={values.registrationno}
                 onChange={handleChange}
                onBlur={handleBlur}
                  className="form-control"
                />

{errors.registrationno && (
        <p style={{ color: "red" }}>{errors.registrationno}</p>
      )}
              </div>
            </div>
          </div>

          <div className="col-md-12 mt-3">
            <div className="row">
              <div className="col-md-2">
                <label
                  htmlFor="phone"
                  className="col-form-label leftone"
                >
                  Phone no
                </label>
              </div>
              <div className="col-md-4">
                <input
                  type="number"
                  autoComplete="off"
                  name="phone"
                  id="phone"
                  placeholder="Phone no"
                  value={values.phone}
                 onChange={handleChange}
                onBlur={handleBlur}
                  className="form-control"
                ></input>
                {errors.phone && (
        <p style={{ color: "red" }}>{errors.phone}</p>
      )}
              </div>
              <div className="col-md-2">
                <label
                  htmlFor="address"
                  className="col-form-label leftone"
                >
                  Address
                </label>
              </div>
              <div className="col-md-4">
                <input
                  type="text"
                  autoComplete="off"
                  name="address"
                  id="address"
                  placeholder="Address"
                  value={values.address}
                 onChange={handleChange}
                onBlur={handleBlur}
                  className="form-control"
                ></input>
                {errors.address && (
        <p style={{ color: "red" }}>{errors.address}</p>
      )}
              </div>
            </div>
          </div>

          <div className="col-md-12 mt-3">
            <div className="row">
              <div className="col-md-2">
                <label
                  htmlFor="email"
                  className="col-form-label leftone"
                >
                  Email
                </label>
              </div>
              
              <div className="col-md-4">
                <input
                  type="email"
                  autoComplete="off"
                  name="email"
                  id="email"
                  placeholder="Email"
                  value={values.email}
                 onChange={handleChange}
                onBlur={handleBlur}
                  className="form-control"
                ></input>
                 {errors.email  ? (
                 <p style={{ color: "red" }}>{errors.email}</p>
                ) : null}
              </div>
              <div className="col-md-2">
                <label
                  htmlFor="hospitashortcode"
                  className="col-form-label leftone"
                >
                  hospitashortcode Short Name
                </label>
              </div>
              <div className="col-md-4">
                <input
                  type="text"
                  autoComplete="off"
                  name="hospitashortcode"
                  id="hospitashortcode"
                  placeholder="hospitashortcode Short Name"
                  value={values.hospitashortcode}
                 onChange={handleChange}
                onBlur={handleBlur}
                  className="form-control"
                />
              {errors.hospitashortcode && (
        <p style={{ color: "red" }}>{errors.hospitashortcode}</p>
      )}
              </div>
              {/* <div className="col-md-2">
                <label
                  htmlFor="currency"
                  className="col-form-label "
                >
                  Currency
                </label>
              </div> */}
              {/* <div className="col-md-4">
                <input
                  type="text"
                  autoComplete="off"
                  name="currency"
                  id="currency"
                  placeholder="Currency"
                  value={hospital.currency}
                 
                />
                {errors.gaurdianname && touched.gaurdianname ? (
                  <p className="form-erroremployee">{errors.gaurdianname}</p>
                ) : null}
              </div> */}
            </div>
          </div>
         

          <div className="col-md-12 mt-3">
            <div className="row">
              <div className="col-md-2">
                <label
                  htmlFor="time"
                  className="col-form-label "
                >
                  Time Format
                </label>
              </div>
              <div className="col-md-4">
                <input
                  type="text"
                  autoComplete="off"
                  name="time"
                  id="time"
                  placeholder="Time Format"
                  value={values.time}
                 onChange={handleChange}
                onBlur={handleBlur}
                  className="form-control"
                />

               
              </div>

              <div className="col-md-2">
                <label
                  htmlFor="workhour"
                  className="col-form-label"
                >
                  Working Hour
                </label>
              </div>
              <div className="col-md-4">
                <input
                  type="number"
                  autoComplete="off"
                  name="workhour"
                  id="workhour"
                  placeholder="Work Hour"
                  value={values.workhour}
                 onChange={handleChange}
                onBlur={handleBlur}
                  className="form-control"
                />
              </div>
            </div>
          </div>
          <div className="col-md-12 mt-3">
            <div className="row">
              <div className="col-md-2">
                <label
                  htmlFor="birthcertificate"
                  className="col-form-label"
                >
                  Birth Certificate
                </label>
              </div>
              <div className="col-md-4">
                <input
                  type="file"
                  autoComplete="off"
                  name="birthcertificate"
                  id="birthcertificate"
                  placeholder="Birth Certificate"
                  accept="image/png, image/gif, image/jpeg,image/jpg"
                  onChange={handleFileChangeBirthCertificate}
                  className="form-control"
                ></input>
                 {errors.birthcertificate && (
        <p style={{ color: "red" }}>{errors.birthcertificate}</p>
      )}
         <a href={`${API.defaults.baseURL}/uploadimage/birthcertificate/${data.birthcertificate}`} target="_blank" rel="noopener noreferrer">
                <span>{filebirthcertificate}</span>
                </a>
              </div>

              <div className="col-md-2">
                <label
                  htmlFor="deathcertificate"
                  className="col-form-label"
                >
                  Death Certificate
                </label>
              </div>
              <div className="col-md-4">
                <input
                  type="file"
                  autoComplete="off"
                  name="deathcertificate"
                  id="deathcertificate"
                  placeholder=" Death Certificate"
                  accept="image/png, image/gif, image/jpeg,image/jpg"
                  onChange={handleFileChangeDeathCertificate}
                  className="form-control"
                />
                 {errors.deathcertificate && (
        <p style={{ color: "red" }}>{errors.deathcertificate}</p>
      )}
                 <a href={`${API.defaults.baseURL}/uploadimage/deathcertificate/${data.deathcertificate}`} target="_blank" rel="noopener noreferrer">
                <span>{filedeathcertificate}</span>
                </a>
              </div>
            </div>
          </div>

          <div className="col-md-12 mt-3">
            <div className="row">
              <div className="col-md-2">
                <label
                  htmlFor="licenseduration"
                  className="col-form-label"
                >
                  License duration
                </label>
              </div>
              <div className="col-md-4">
                <input
                  type="text"
                  autoComplete="off"
                  name="licenseduration"
                  id="licenseduration"
                  placeholder="License duration"
                  value={values.licenseduration}
                 onChange={handleChange}
                onBlur={handleBlur}
                  className="form-control"
                ></input>
              </div>

              <div className="col-md-2">
                <label
                  htmlFor="licenserate"
                  className="col-form-label"
                >
                  License Rate
                </label>
              </div>
              <div className="col-md-4">
                <input
                  type="number"
                  autoComplete="off"
                  name="licenserate"
                  id="licenserate"
                  placeholder=" License Rate"
                  value={values.licenserate}
                 onChange={handleChange}
                onBlur={handleBlur}
                  className="form-control"
                ></input>
                {errors.licenserate  ? (
                  <p style={{ color: "red" }}>{errors.licenserate}</p>
                 ) : null}
              </div>
            </div>
          </div>

          <div className="col-md-12 mt-3">
            <div className="row">
              <div className="col-md-2">
                <label
                  htmlFor="amountpaid"
                  className="col-form-label"
                >
                  Amount paid
                </label>
              </div>
              <div className="col-md-4">
                <input
                  type="number"
                  autoComplete="off"
                  name="amountpaid"
                  id="amountpaid"
                  placeholder=" "
                  value={values.amountpaid}
                 onChange={handleChange}
                onBlur={handleBlur}
                  className="form-control"
                >
                </input>
                {errors.amountpaid  ? (
                  <p style={{ color: "red" }}>{errors.amountpaid}</p>
                 ) : null}
              </div>

              <div className="col-md-2">
                <label
                  htmlFor="licenseexpirydate"
                  className="col-form-label"
                >
                  License expiry date
                </label>
              </div>
              <div className="col-md-4">
                <input
                  type="date"
                  autoComplete="off"
                  name="licenseexpirydate"
                  id="licenseexpirydate"
                  placeholder="License expiry date "
                  value={values.licenseexpirydate}
                 onChange={handleChange}
                onBlur={handleBlur}
                  className="form-control"
                ></input>
              </div>
            </div>
          </div>

          <div className="col-md-12 mt-3">
            <div className="row">
              <div className="col-md-2">
                <label
                  htmlFor="noofuser"
                  className="col-form-label"
                >
                  Number Of Users
                </label>
              </div>
              <div className="col-md-4">
                <input
                  type="number"
                  autoComplete="off"
                  name="noofuser"
                  id="noofuser"
                  placeholder=" "
                  value={values.noofuser}
                 onChange={handleChange}
                onBlur={handleBlur}
                  className="form-control"
                ></input> {errors.noofuser  ? (
                  <p style={{ color: "red" }}>{errors.noofuser}</p>
                 ) : null}

              </div>
              <div className="col-md-2">
                <label
                  htmlFor="remarks"
                  className="col-form-label"
                >
                  Remarks
                </label>
              </div>
              <div className="col-md-4">
                <textarea
                  rows={2}
                  column={2}
                  type="text"
                  autoComplete="off"
                  name="remarks"
                  id="remarks"
                  placeholder=" Remarks"
                  value={values.remarks}
                 onChange={handleChange}
                onBlur={handleBlur}
                  style={{ width: '90%', height: '32px', border: '1px solid #ced4da ', color: '#495057' }}
                ></textarea>
              </div>
             
            </div>
          </div>

          <div className="col-md-12 mt-3">
            <div className="row">
              <div className="col-md-2">
                <label
                  htmlFor="hospitallogo"
                  className="col-form-label"
                >
                  Hospital logo
                </label>
               </div>
              <div className="col-md-4">
                <input
                  type="file"
                  autoComplete="off"
                  name="hospitallogo"
                  id="hospitallogo"
                  placeholder="Hospital Logo "
                  accept="image/png, image/gif, image/jpeg,image/jpg"
                  //value={hospital.hospitallogo}
                  onChange={handleFileChangeHospitallogo }
                  className="form-control"
                ></input>
                {errors.hospitallogo && (
        <p style={{ color: "red" }}>{errors.hospitallogo}</p>
      )}
                  <a href={`${API.defaults.baseURL}/uploadimage/hospitallogo/${data.hospitallogo}`} target="_blank" rel="noopener noreferrer">
                  <span>{fileName}</span>
                  </a>
              </div> 
              <div className="col-md-2">
                <label
                  htmlFor="attachtwo"
                  className="col-form-label"
                >
                  Billing Header 
                </label>
              </div>
              <div className="col-md-4">
                <input
                  type="file"
                  autoComplete="off"
                  name="attachtwo"
                  id="attachtwo"
                  placeholder=" "
                  accept="image/png, image/gif, image/jpeg,image/jpg"
                  //value={hospital.attachtwo}
                  onChange={handleFileChangeAttachtwo}
                  className="form-control"
                ></input>
                {errors.attachtwo && (
        <p style={{ color: "red" }}>{errors.attachtwo}</p>
      )}
               <a href={`${API.defaults.baseURL}/uploadimage/attachtwo/${data.attachment2}`} target="_blank" rel="noopener noreferrer">
                <span>{fileattachment2}</span>
                </a>
              </div>
              {/* <div className="col-md-2">
                <label
                  htmlFor="hospitalfavicon"
                  className="col-form-label"
                >
                  Hospital Favicon
                </label>
              </div> */}
              {/* <div className="col-md-4">
                <input
                  type="file"
                  autoComplete="off"
                  name="hospitalfavicon"
                  id="hospitalfavicon"
                  placeholder="Hospital favicon"
                  // value={hospital.hospitalfavicon}
                  onChange={handleFileChangeHospitalfavicon}
                  className="form-control"
                ></input>
                <a href={`${API.defaults.baseURL}/uploadimage/hospitalfavicon/${data.hospitalfavicon}`} target="_blank" rel="noopener noreferrer">
                <span>{filehospitalfavicon}</span>
                </a>
              </div> */}
            </div>
          </div>

          <div className="col-md-12 mt-3">
            <div className="row">
              <div className="col-md-2">
                <label
                  htmlFor="attachtone"
                  className="col-form-label"
                >
                   Perscription header
                </label>
              </div>
              <div className="col-md-4">
                <input
                  type="file"
                  autoComplete="off"
                  name="attachone"
                  id="attachone"
                  placeholder=" "
                  accept="image/png, image/gif, image/jpeg,image/jpg"
                  //value={hospital.attachone}
                  onChange={handleFileChangeAttachone}
                  className="form-control"
                ></input>
                 {errors.attachone && (
        <p style={{ color: "red" }}>{errors.attachone}</p>
      )}
                <a href={`${API.defaults.baseURL}/uploadimage/attachone/${data.attachment1}`} target="_blank" rel="noopener noreferrer">
                <span>{fileattachment1}</span>
                </a>
              </div>

              {/* <div className="col-md-2">
                <label
                  htmlFor="attachtwo"
                  className="col-form-label"
                >
                  Billing Header 
                </label>
              </div>
              <div className="col-md-4">
                <input
                  type="file"
                  autoComplete="off"
                  name="attachtwo"
                  id="attachtwo"
                  placeholder=" "
                  //value={hospital.attachtwo}
                  onChange={handleFileChangeAttachtwo}
                  className="form-control"
                ></input>
               <a href={`${API.defaults.baseURL}/uploadimage/attachtwo/${data.attachment2}`} target="_blank" rel="noopener noreferrer">
                <span>{fileattachment2}</span>
                </a>
              </div> */}
            </div>
          </div>

          {/* <div className="col-md-12 mt-3">
            <div className="row">
              <div className="col-md-2">
                <label
                  htmlFor="attachthree"
                  className="col-form-label"
                >
                  Attachment three
                </label>
              </div>
              <div className="col-md-4">
              <input
                  type="file"
                  autoComplete="off"
                  name="attachthree"
                  id="attachthree"
                  placeholder=" "
                  //value={hospital.attachthree}
                  onChange={handleFileChangeAttachthree}
                  className="form-control"
                ></input>
               <a href={`${API.defaults.baseURL}/uploadimage/attachthree/${data.attachment3}`} target="_blank" rel="noopener noreferrer">
                <span>{fileattachment3}</span>
                </a>
              </div>
            </div>
          </div> */}
        </div>
        </div>
        <div className="col-md-6  mt-3">
          <Button
            type="submit"
            variant="success"
            className="mx-3"
          >
           Update
          </Button>
          <Button
            variant="primary"
            className="mx-3"
            // onClick={handleReset}
          >
            Cancel
          </Button>
        </div>
      </form>
    </div>
  );
};
export default HospitalDetail;