import React, { useState, useEffect } from "react";
import axios from "../../../helper/axios";
import Autocomplete from "@mui/material/Autocomplete";
import { useFormik } from "formik";
import TextField from "@mui/material/TextField";
import { NavLink, useHistory, useParams } from "react-router-dom/cjs/react-router-dom";
import Searchbar from "../../../Component/Searchbar";
import { newappointmentvalidation } from "../../../helper/schemas";
import "bootstrap/dist/css/bootstrap.min.css";
import { Table, Button, Modal, Form } from "react-bootstrap";
import Searchesforchanges from "../../../Component/Searchesforcharges";
import PrintTemplateBilling from "../../../Component/PrintTemplateBilling/PrintTemplateBilling"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useUser } from "../../../Provider";
import { Helmet } from "react-helmet";
const initialValues = {
  patientname: "",
  bloodGroup: "",
  phoneno: "",
  doctorName: "",
  doctorFee: "",
  appointmentDate: "",
  paymentMode: "",
  address: "",
  OPDType: "visit",
  gaurdianname: "",
  patientid: "",
  hospitalcode: ""
};
const EditBilling = () => {
  const { billnumber } = useParams();
  const { user, userHospitalcode, userrole1, isAuthRole, isAuthorized, gettokenId,token } = useUser();
  const history = useHistory();
  let Showdate = new Date();
  let ShowTodaydate =
    Showdate.getFullYear() +
    "-" +
    (Showdate.getMonth() + 1) +
    "-" +
    Showdate.getDate();
  const [currentDate, setCurrentDate] = useState(ShowTodaydate);
  const itemTemp = {
    chargecode: "",
    chargename: "",
    chargeamount: "",
    tax: 0,
    chragequantity: 1,
  };
  const [billid, setBId] = useState();
  const [isPopup, setIsPopup] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [searchValueName, setSearchValueName] = useState("");
  const [addchargesname, setAddchargesname] = useState([itemTemp]); //addchargesname
  const [itemList, setItemList] = useState([]); // State to hold charge types
  const [allItemList, setAllItemList] = useState([]); // State to hold charge types
  //const token = sessionStorage.getItem("jwtToken");
  const [total, setTotal] = useState("");
  const [extraCharge, setExtraCharge] = useState(0);
  const [discountBy, setDiscountBy] = useState("");
  const [allowPopup, setAllowPopup] = useState(false);
  const [discountPercentage, setDiscountPercentage] = useState("");
  const [finalAmount, setFinalAmount] = useState("");
  const handleEdit = (e) => {
    // Use the spread operator to merge the existing state with the new value.
    setDiscountBy({ ...discountBy, [e.target.name]: e.target.value, });
  };
  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setValues,
  } = useFormik({
    initialValues,
    // validationSchema: newappointmentvalidation,
    onSubmit: async (values, action) => {
      try {
        if (gettokenId !== undefined) {
          const data = new FormData();
          data.append("document", JSON.stringify(values));
          data.append("addchargesname", JSON.stringify(addchargesname));
          data.append("extraCharge", JSON.stringify(extraCharge));
          data.append("discountBy", JSON.stringify(discountBy));
          data.append("discountPercentage", JSON.stringify(discountPercentage));
          data.append("finalAmount", JSON.stringify(finalAmount));
          data.append("currentDate", JSON.stringify(currentDate));
          data.append("total", JSON.stringify(total));
          data.append("gettokenId", JSON.stringify(gettokenId));
          data.append("userHospitalcode", JSON.stringify(userHospitalcode));
          console.log(JSON.stringify(userHospitalcode))
          console.log(JSON.stringify(values))
          console.log(JSON.stringify(addchargesname))
          const response = await axios.post(`/updatebilling/${billnumber}`, data, {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "multipart/form-data",
            },
          });
          if (response.status === 200) {
            // setSearchValue("")
            setSuccess(true);
            //if save and print then show thw bill
            if (allowPopup) {
              setIsPopup(true)
            }
            //if no Popup screen then empty the field
            else {
              setExtraCharge("");
              setDiscountBy("");
              setDiscountPercentage("");
              setFinalAmount("");
              setTotal("");
              setAddchargesname([itemTemp])
            }
            toast.success("Data Saved")
            setError(false);
          }

        }
      } catch (err) {
        setSuccess(false);
        toast.error("Something Went Wrong")
        setError(true);
      }
      action.resetForm();
    }
  },
  );
  const addItemrow = () => {
    setAddchargesname([...addchargesname, itemTemp]);
  };
  const onChange = (e, index) => {
    const { name, value } = e.target;
    const updateUser = [...addchargesname];
    const [fieldName, fieldIndex] = name.split('_');
    const itemIndex = parseInt(fieldIndex, 10);
    updateUser[itemIndex] = {
      ...updateUser[itemIndex],
      [fieldName]: value,
    };
    console.log(updateUser)
    setAddchargesname(updateUser);
    // Recalculate the final amount whenever the tax changes
    calculateFinalAmount(updateUser);
  };
  const handleSearchChangeType = async (value, index) => {
    console.log(index);
    const itemChargeData = allItemList.find((e) => e.chargename == value);
    let newArr = [...addchargesname];
    newArr[index] = {
      chargecode: itemChargeData.chargecode,
      chargename: itemChargeData.chargename,
      chargeamount: parseFloat(itemChargeData.chargeamount),
      tax: parseFloat(itemChargeData.tax),
      chragequantity: parseFloat(itemChargeData.chragequantity),
    };
    console.log(newArr)
    setAddchargesname(newArr);
    let totalSum = 0;
    newArr.forEach((e) => {
      const chargeamount = parseFloat(e.chargeamount);
      const tax = parseFloat(e.tax);
      const chragequantity = parseFloat(e.chragequantity) || 1; // Use chragequantity from the item or default to 1
      const taxAmount = (chargeamount * (tax / 100)); // chargeamount as tax
      totalSum += (chargeamount + taxAmount) * chragequantity;
      console.log(totalSum)
    });
    setTotal(totalSum);
  };
  const handleSearch = (searchTerm) => {
    console.log(searchTerm);
    setSearchValueName(searchTerm); // Update the searchValue state with the search term
  };
  const fetchBillingDetails = async () => {
    try {
      const response = await axios.get(`/getbillings/${billnumber}`);
      // Update state based on the fetched data
      setValues(response.data[0]);
      setTotal(response.data[0].total)
      setDiscountPercentage(response.data[0].discountPercentage)
      setExtraCharge(response.data[0].extracharge)
      setBId(response.data[0].billid)
     // setDiscountBy(response.data[0].discountby)
    } catch (error) {
      console.error("Error fetching billing details:", error.message);
      // Handle error, redirect or show a message to the user
    }
  };
  const fetchchargeDetails = async () => {
    try {
      const response = await axios.get(`/getbillingdetails/${billnumber}`);
      // Update state based on the fetched data
      setAddchargesname(response.data);
    } catch (error) {
      console.error("Error fetching billing details:", error.message);
      // Handle error, redirect or show a message to the user
    }
  };
  useEffect(() => {

    fetchchargeDetails();
    fetchBillingDetails();
  }, [billnumber]);
  useEffect(() => {
    if (searchValueName) {
      // fetchPatientDetails();
      setValues({
        ...values,
        patientname: searchValueName.patientname, // Make sure field names match
        bloodGroup: searchValueName.bloodgroup, // Make sure field names match
        phoneno: searchValueName.phoneno, // Make sure field names match
        address: searchValueName.address,
        patientid: searchValueName.patientid,
        age: searchValueName.age,
        gender: searchValueName.gender,
        gaurdianname: searchValueName.gaurdianname,
        hospitalcode: searchValueName.hospitalcode,
        // Update other fields as needed
      });
    }
  }, [searchValueName, token]);
  const loadAllCharges = async (data) => {
    if (userHospitalcode !== undefined && userrole1 !== undefined) {
      const res = await axios.get("/AllChargeTypes", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          hospitalcode: userHospitalcode, // Include the hospitalcode in the query parameters
          userrole: userrole1, // Include the userrole in the query parameters
        },
      });
      setAllItemList(res.data);
      res.data.forEach((element) => {
        setItemList((oldArr) => [...oldArr, element.chargename]);
      });
    }
  };
  //get all hospital code
  const loadHospitalcode = async () => {
    //when userHospitalcode and userrole1 is not undefined then execute /gethospitalcode
    if (userHospitalcode !== undefined && userrole1 !== undefined) {
      const res = await axios.get("/gethospitalcode", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          hospitalcode: userHospitalcode, // Include the hospitalcode in the query parameters
          userrole: userrole1, // Include the userrole in the query parameters
        },
      });
      console.log(res.data);
    };
  }
  useEffect(() => {
    const loadData = async () => {
      //loadHospitalcode(); will wait for loadAllDetails(); to got execute
      await loadHospitalcode();
    };
    loadData();
    loadAllCharges();
  }, [userHospitalcode, userrole1, isAuthorized]);

  useEffect(() => {
    if (addchargesname.length > 0) {
      calculateFinalAmount(addchargesname);
    }
  }, [total, extraCharge, discountBy, discountPercentage]);
  const calculateFinalAmount = (updatedInventory) => {
    const totalValue = updatedInventory.reduce((acc, item) => {
      const chargeamount = parseFloat(item.chargeamount) || 0;
      const tax = parseFloat(item.tax) || 0;
      const chragequantity = parseFloat(item.chragequantity) || 1;
      const taxAmount = (chargeamount * (tax / 100));
      return acc + (chargeamount + taxAmount) * chragequantity;
    }, 0);
    setTotal(totalValue)
    const extraChargeValue = parseFloat(extraCharge) || 0;
    const discountPercentageValue = parseFloat(discountPercentage) || 0;
    const discountAmount = (totalValue + extraChargeValue) * (discountPercentageValue / 100);
    const subtotal = totalValue + extraChargeValue - discountAmount;
    const newFinalAmount = Math.floor(subtotal);
    setFinalAmount(newFinalAmount);
  };

  const handleClosedPopUp = () => {
    setIsPopup(false);
    setTotal("");
    setExtraCharge("");
    setDiscountBy("");
    setDiscountPercentage("");
    setFinalAmount("");
    setAddchargesname([itemTemp])
  }

  const deleteChargeItem = (index) => {

    let tempAmount = addchargesname[index]

    setAddchargesname(oldData => {
      return oldData.filter((_, i) => i !== index)
    })

    setTotal(total - tempAmount.chargeamount)
  }
  const printForm = (values) => {
    if (values.patientname.trim() === "") {
      setIsPopup(false); // Set isPopup to false if patientName is empty
      // You can also display an error message here if needed
      toast.error("Patient Name is Mandatory")
    } else {
      setAllowPopup(true)
      setIsPopup(true)
      // window.open(`/AppoinmentForm/${value.patientid}`, "_blank")
    }
  }
  const currentDomain = window.location.hostname;
  return (
    <div className="p-3 pt-0 ">
      <Helmet>
        <title>Modify Billing Details | {currentDomain} | Hospital Management Software | Smart Solutions for Hospital Efficiency</title>
      </Helmet>
      <form onSubmit={handleSubmit}>
        {/* {success && <div>Appointment confirm.</div>}
        {error && (
          <div>Error in booking Appointment Please try again later.</div>
        )} */}
        <div class="fill header"><h3 class="float-left"> Billing Form  </h3> </div>
        <div className="patient-new primary-info">
          <div className="row  ml-4">
            <div className="col-md-12 ">
              <div className="row">
                {values.OPDType !== "New" && (
                  <div className="col-md-2">
                    <label className="col-form-label ">Search Patient</label>
                  </div>
                )}
                {values.OPDType !== "New" && (
                  <div className="col-md-4">
                    <Searchbar onSearchChange={handleSearch} />
                  </div>
                )}
                {isAuthorized ? (<div className="col-md-2">
                  <label
                    htmlFor="hospitalcode"
                    className="col-form-label"
                  >
                    Hospital Code
                  </label>
                </div>) : (
                  null
                )}
                <div className="col-md-4">

                  {isAuthorized ? (<input
                    type="text"
                    autoComplete="off"
                    name="hospitalcode"
                    id="hospitalcode"
                    placeholder="Hospitalcode"
                    value={values.hospitalcode}
                    disabled
                    className="form-control"
                  />) : (
                    <input
                      type="hidden"
                      name="hospitalcode"
                      id="hospitalcode"
                      value={values.hospitalcode}
                      disabled={true} // Set the input as disabled
                      className="form-control "
                    />
                  )}
                </div>
              </div>
            </div>

            <div className="col-md-12 mt-3">
              <div className="row">
                <div className="col-md-2">
                  <label
                    htmlFor="patientName"
                    className="col-form-label"
                  >
                    Patient Name
                  </label>
                </div>
                <div className="col-md-4">
                  <input
                    type="text"
                    autoComplete="off"
                    name="patientname"
                    id="patientname"
                    placeholder="Patient Name"
                    value={values.patientname}
                    disabled
                    className="form-control"
                  />
                  {errors.patientname && touched.patientname ? (
                    <p className="form-erroremployee">{errors.patientname}</p>
                  ) : null}
                </div>
                <div className="col-md-2">
                  <label
                    htmlFor="gaurdianname"
                    className="col-form-label"
                  >
                    Guardian Name
                  </label>
                </div>
                <div className="col-md-4">
                  <input
                    type="text"
                    autoComplete="off"
                    name="gaurdianname"
                    id="gaurdianname"
                    placeholder="Guardian Name"
                    value={values.gaurdianname}
                    disabled
                    className="form-control"
                  />

                  {errors.gaurdianname && touched.gaurdianname ? (
                    <p className="form-erroremployee">{errors.gaurdianname}</p>
                  ) : null}

                </div>
              </div>
            </div>

            <div className="col-md-12 mt-3">
              <div className="row">
                <div className="col-md-2">
                  <label
                    htmlFor="patientid"
                    className="col-form-label"
                  >
                    Patient ID
                  </label>
                </div>
                <div className="col-md-4">
                  <input
                    type="text"
                    autoComplete="off"
                    name="patientid"
                    id="patientid"
                    placeholder="Patient ID"
                    value={values.patientid}
                    disabled
                    className="form-control"
                  />

                  {errors.bloodGroup && touched.bloodGroup ? (
                    <p className="form-erroremployee">{errors.bloodGroup}</p>
                  ) : null}

                </div>
                <div className="col-md-2">

                  <label
                    htmlFor="phoneno"
                    className="col-form-label"
                  >
                    Mobile No
                  </label>
                </div>
                <div className="col-md-4">
                  <input
                    type="text"
                    autoComplete="off"
                    name="phoneno"
                    id="phoneno"
                    placeholder="Mobile No"
                    value={values.phoneno}
                    disabled
                    className="form-control"
                  />
                  {errors.phoneno && touched.phoneno ? (
                    <p className="form-erroremployee">{errors.phoneno}</p>
                  ) : null}
                </div>

              </div>
            </div>

          </div>
        </div>
        <div className="row pt-2 mt-3">
          <div className="col-md-8">
            <Table id="contacttable" bordered hover>
              <thead>
                <tr>
                  <th style={{ fontSize: "14px" }}>Sl No</th>

                  <th style={{ fontSize: "14px" }}>Charge Name</th>
                  <th style={{ fontSize: "14px" }}> Quanitity</th>
                  <th style={{ fontSize: "14px" }}> Charge Cost</th>
                  <th style={{ fontSize: "14px" }}>Tax</th>
                  <th style={{ fontSize: "14px" }}>Delete</th>
                </tr>
              </thead>
              <tbody>
                {/* dynamic table */}

                {addchargesname.map((user, index) => {
                  return (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td className="remove">
                        <Autocomplete
                          freeSolo
                          id="free-solo"
                          options={itemList}
                          sx={{ width: 300 }}
                          value={user.chargename}
                          // onInputChange={(e) => handleSearchChangeType(e, index)}
                          onChange={(event, value) =>
                            handleSearchChangeType(value, index)
                          }
                          // onClick={console.log()}
                          renderInput={(params) => (
                            <TextField
                              style={{
                                border: "none"
                              }}
                              variant="outlined"
                              {...params}

                              
                            />
                          )}
                        />
                      </td>
                      <td>
                        <input
                          className="inputtable mt-2"
                          style={{ width: "80%" }}
                          name={`chragequantity_${index}`} // Use a unique name for each tax input
                          onChange={(e) => onChange(e, index)}
                          value={user.chragequantity}
                          placeholder="Enter Quantity"
                        />
                      </td>
                      <td>
                        <input
                          className="inputtable mt-2"
                          style={{ width: "100%", border: "none" }}
                          name="Cost"
                          value={user.chargeamount}
                          readOnly
                        />
                      </td>

                      <td>
                        <input
                        type="number"
                          className="inputtable mt-2"
                          style={{ width: "100%" }}
                          name={`tax_${index}`} // Use a unique name for each tax input
                          onChange={(e) => onChange(e, index)}
                          value={user.tax}
                          placeholder="Enter tax value"
                        />
                      </td>

                      <th>
                        {/* <IoTrashOutline onClick={() => deleteRow(index)} /> */}
                        <button className="btn btn-danger btn-sm" onClick={() => deleteChargeItem(index)}>Delete</button>
                      </th>
                      <input
                        type="hidden"
                        className="inputtable mt-3"
                        name="Quantity"
                        onChange={(e) => onChange(e, index)}
                        value={user.Quantity}
                      />
                    </tr>
                  );
                })}
              </tbody>
            </Table>
            {/* TO ADD A NEW A ITEM */}
            <div className="link">
              <NavLink
                to="#"
                className="addlink"
                onClick={addItemrow}
              ><button className="btn btn-success btn-sm mr-2">
                  Add New Line</button>
              </NavLink>
            </div>
          </div>

          <div className="col-md-4">
            <div className="row ml-4">
              <div className="col-md-12 mt-3">
                <div className="row">
                  <div className="col-md-1"></div>
                  <div className="col-md-5">
                    <label className="col-form-label">Total</label>
                  </div>
                  <div className="col-md-6">
                    <input
                      style={{ width: "100%" }}
                      type="number"
                      autoComplete="off"
                      name="total"
                      id="total"
                      placeholder="Total"
                      className="form-control"
                      value={total}
                      onChange={(e) =>
                        setTotal(parseFloat(e.target.value) || 0)
                      }
                    />
                  </div>
                </div>
              </div>

              <div className="col-md-12 mt-3">
                <div className="row">
                  <div className="col-md-1"></div>
                  <div className="col-md-5">
                    <label className="col-form-label">Extra Charge</label>
                  </div>
                  <div className="col-md-6">
                    <input
                      style={{ width: "100%" }}
                      type="number"
                      autoComplete="off"
                      name="extraCharge"
                      id="extraCharge"
                      className="form-control"
                      placeholder="Extra Charge"
                      value={extraCharge}
                      onChange={(e) =>
                        setExtraCharge(parseFloat(e.target.value) || 0)
                      }
                    />
                  </div>
                </div>
              </div>

              <div className="col-md-12 mt-3">
                <div className="row">
                  <div className="col-md-1"></div>
                  <div className="col-md-5">
                    <label className="col-form-label">Discount By</label>
                  </div>
                  <div className="col-md-6">
                    <input
                      style={{ width: "100%" }}
                      type="text"
                      autoComplete="off"
                      name="discountBy"
                      id="discountBy"
                      className="form-control"
                      placeholder="Discount By"
                      value={discountBy.discountBy || ""} // Access the value with the 'discountBy' key
                      onChange={(e) => handleEdit(e)}
                    />
                  </div>
                </div>
              </div>

              <div className="col-md-12 mt-3">
                <div className="row">
                  <div className="col-md-1"></div>
                  <div className="col-md-5">
                    <label className="col-form-label">Discount %</label>
                  </div>
                  <div className="col-md-6">
                    <input
                      style={{ width: "100%" }}
                      type="number"
                      autoComplete="off"
                      name="discountPercentage"
                      id="discountPercentage"
                      className="form-control"
                      placeholder="Discount Percentage"
                      value={discountPercentage}
                      onChange={(e) =>
                        setDiscountPercentage(parseFloat(e.target.value) || 0)
                      }
                    />
                  </div>
                </div>
              </div>

              <div className="col-md-12 mt-3">
                <div className="row">
                  <div className="col-md-1"></div>
                  <div className="col-md-5">
                    <label className="col-form-label">Final Amount</label>
                  </div>
                  <div className="col-md-6">
                    <input
                      style={{ width: "100%" }}
                      type="number"
                      autoComplete="off"
                      name="finalAmount"
                      id="finalAmount"
                      className="form-control"
                      placeholder="Final Amount"
                      value={finalAmount}
                      readOnly // Assuming this is a calculated field
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <input
          type="hidden"
          autoComplete="off"
          name="insertdate"
          id="insertdate"
          value={currentDate}
        />
        <div className="col-md-6  mt-3">
          <Button
            type="submit"
            variant="primary"
            className="mx-3"
          >
            Save
          </Button>
          <Button type="submit" variant="primary" className="mx-3" onClick={() => printForm(values)}>
            Save And Print
          </Button>
        </div>
        {isPopup == true ?
          <>

            <Modal show={true} animation={false} onHide={handleClosedPopUp} size="lg">
              <Modal.Header closeButton>
                {/* <Modal.Title>{billid}</Modal.Title> */}
              </Modal.Header>
              <Modal.Body>
                {billid == undefined ? ""
                  :
                  <PrintTemplateBilling BId={billid} totalCost={total} finalAmount={finalAmount} extraCharge={extraCharge} chargeList={addchargesname} handleSubmit={handleClosedPopUp} />}
              </Modal.Body>
            </Modal>
          </>
          :
          ""}
        <ToastContainer />
      </form>
    </div>
  );
};

export default EditBilling;