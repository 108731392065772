import React, { useState, useEffect } from 'react';
import MUIDataTable from "mui-datatables";
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import PrintIcon from '@mui/icons-material/Print';
import axios from "../../../helper/axios";
import { useUser } from "../../../Provider";
import { useParams } from 'react-router-dom/cjs/react-router-dom';
import PrintTransaction from '../../../Component/PrintTransaction/PrintTransaction';
import { Modal } from 'react-bootstrap';
import UpdateIpdOperationPopUp from './UpdateIpdOperationPopUp';
import UpdateIpdpayment from './UpdateIpdpayment';
import PrintPrescription from '../../../Component/PrintPrescription/PrintPrescription';
import UpdateIpdPrescription from './UpdateIpdPrescription';
import PrescriptionForm from './PrescriptionForm';

const  PrescriptionTable = ({hideForm}) => {
  const { admitnumber } = useParams();
  const { userHospitalcode, userrole1, isAuthRole, isAuthorized, gettokenId ,token} = useUser();
 // const token = sessionStorage.getItem("jwtToken");
  const [PrescriptionData, setPrescriptionData] = useState([]);
  // const [totalAmount, setTotalAmount] = useState(0);
  const [ipdPrescription, setIpdPrescription] = useState();
  const [isPopup, setIsPopup] = useState(false);
  const [isEditFormVisible, setEditFormVisibility] = useState(false);
  const [editprescription, setPrescription] = useState(null);
 ;
  const getprescriptiondetails = async () => {
    try {
      if (userHospitalcode !== undefined && userrole1 !== undefined) {
        const res = await axios.get(`/getprescription/${admitnumber}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        console.log(res.data)
        if (res.status === 200) {
          setPrescriptionData(res.data);
        } else {
          alert(`Request failed with status: ${res.status}`);
        }
      }
    } catch (error) {
      console.log(`An error occurred: ${error.message}`);
    }
  };

  useEffect(() => {
    const loadData = async () => {
      await getprescriptiondetails()
    };

    loadData();
  }, [userHospitalcode, userrole1, isAuthorized]);
  const handlePrescriptionAdd= () => {
    // Function to fetch the updated NurseNote data
    getprescriptiondetails();
  };
  const handleClose = () => {
    setIsPopup(false)
  } 
  const handlePrint = async (value, tableMeta) => {
    console.log(PrescriptionData);
    console.log(tableMeta.rowData[0])
    if (tableMeta.rowData && tableMeta.rowData[1]) {
        const newD =PrescriptionData.find((e) =>
         e.prescriptionnumber === tableMeta.rowData[1]);

        if (newD) {
            console.log(newD);
            await setIpdPrescription(tableMeta.rowData[1]);
            await setIsPopup(true);
        } else {
            console.error('Data not found for the specified prescription number.');
        }
    } else {
        console.error('Invalid tableMeta.rowData.');
    }
};

  const columns = [
    { name: "date", label: "Date" },
    { name: "prescriptionnumber", label: "Prescription Number" },
    { name: "fname", label: "Prescribe By" },
    { name: "findingcategory", label: "Finding Category" },
    { name: "findingdescription", label: "Finding Description" },
    {
      name: "actions",
      label: "Actions",
      options: {
        customBodyRender: (value, tableMeta) => (
          <div>
            <IconButton color="primary" onClick={() => handlePrint(value, tableMeta)}>
              <PrintIcon />
            </IconButton>
            <IconButton color="primary" onClick={() => handleEdit(value, tableMeta)}>
              <EditIcon />
            </IconButton>
            <IconButton color="secondary" onClick={() => handleDelete(value, tableMeta)}>
              <DeleteIcon />
            </IconButton>
          </div>
        ),
      },
    },
  ];

  const options = {
    filter: true,
    selectableRows: "none",
    viewColumns: false,
    print: false,
    download: false,
  };

  const handleEdit = (value, tableMeta, updateValue) => {
    const prescriptionnumber=tableMeta.rowData[1]
    console.log(prescriptionnumber)
    setPrescription(prescriptionnumber);
    setEditFormVisibility(true);
  };
  const handleDelete = async (value, tableMeta, updateValue) => {
    const prescriptionnumber=tableMeta.rowData[1]
  console.log(prescriptionnumber)
    // Display a confirmation dialog with patient's name
    const isConfirmed = window.confirm(`Do you want to delete ${prescriptionnumber}?`);
  
    if (isConfirmed) {
      try {
        await axios.post(`/cancelprescription/${admitnumber}/${prescriptionnumber}`, null, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
  
        getprescriptiondetails();
      } catch (error) {
        // Handle any error here.
        alert("Error cancelling transaction:", error);
      }
    } else {
      // User clicked "Cancel" in the confirmation dialog, do nothing.
    }
  };

  return (
    <div>
       {!hideForm && <PrescriptionForm onPrescriptionAdd={handlePrescriptionAdd} /> }
      <MUIDataTable
        title="Prescription Details"
        data={PrescriptionData}
        columns={columns}
        options={options}
      />
       {isPopup==true?
          <>

          <Modal show={true}  animation={false} onHide={handleClose} size="lg">
            <Modal.Header closeButton>
             
            </Modal.Header>
            <Modal.Body>
            {ipdPrescription==undefined?""
              :
              <PrintPrescription ipdPrescription={ipdPrescription}/>}
            </Modal.Body>
          </Modal>
        </> 
        :
        ""}
    
       {/* ... your existing code ... */}
    {isEditFormVisible && editprescription && (
      <UpdateIpdPrescription
      PrescriptionData={editprescription}
      onClose={() => setEditFormVisibility(false)}
       />
    )}
    </div>
  );
};

export default  PrescriptionTable;
