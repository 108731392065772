//in this patient details will be displayed and can edit the details of patients

import axios from "../../../helper/axios";
import React, { useEffect, useState } from "react";
import { NavLink, useHistory, useParams } from "react-router-dom";
import { GrEdit } from "react-icons/gr";
// import Cookies from "js-cookie";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Table, Button, Modal, Form } from 'react-bootstrap';
import { useUser } from "../../../Provider";
import { ToastContainer, toast } from 'react-toastify';
import { Helmet } from "react-helmet";
import { useFormik } from 'formik';
import * as Yup from "yup";
const validationSchema = Yup.object().shape({
  patientname: Yup.string().required("Patient name is required"),
  gender : Yup.string().required("Patient gender is required"),
  // phoneno: Yup.string().min(10).max(10).required("Please enter the phone number "),
  phoneno: Yup.string()
  .matches(/^[0-9]+$/, "Please enter the mobile number")
  .min(10, "Mobile number must be at least 10 characters")
  .max(10, "Mobile number must be 10 characters")
  .required("Mobile number is required"),
  address: Yup.string().required("Please enter the  address"),
  age: Yup.number()
  .typeError("Please enter a valid age")
  .positive("Age must be a positive number")
  .integer("Age must be an integer")
  .required("Please enter the age"),
  email: Yup.string().email("Email must be a valid").nullable(),
  bloodgroup: Yup.string()
  .matches(/^[a-zA-Z+\- ]+$/, "Blood group should only contain letters, +, -, or spaces").nullable(),
  occupation: Yup.string().nullable()
  .matches(/^[a-zA-Z\s]+$/, "Occupation should only contain letters"),
  gaurdianname: Yup.string().matches(/^[a-zA-Z\s]+$/, "Gaurdian name should only contain letters").nullable(),
  city: Yup.string().matches(/^[a-zA-Z\s]+$/, "City should only contain letters").nullable(),
  state: Yup.string().matches(/^[a-zA-Z\s]+$/, "State should only contain letters").nullable(),
  
});
const initialValues = {
  patientname: "",
    gaurdianname: "",
    phoneno: "",
    address: "",
    bloodgroup: "",
    email: "",
    gender: "",
    dob: "",
    age: "",
    state: "",
    city: "",
    occupation: "",
    hospitalcode:""
}
const PatientDeatilEdit = () => {
  const history = useHistory();
  const {user, userHospitalcode, userrole1, isAuthRole,isAuthorized,gettokenId,token  } = useUser();
  console.log(user, userHospitalcode, userrole1, isAuthRole,isAuthorized,gettokenId )
  const { patientid } = useParams();
  //   console.log("id " + Id);

  // const [gettokenId, setTokenId] = useState();
  const [gettokenRole, setTokenRole] = useState();
  const [data, setData] = useState([]);
  //const token = sessionStorage.getItem("jwtToken");
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  //when page load or refresh happen this function will got called
  useEffect(() => {
    const loadData = async () => {
      //loadHospitalcode(); will wait for loadAllDetails(); to got execute
      await loadHospitalcode();
      await loadAllPatient();
    };
    loadData();
  }, [userHospitalcode, userrole1, isAuthorized]);
  //get all hospital code
  const loadHospitalcode = async () => {
    //when userHospitalcode and userrole1 is not undefined then execute /gethospitalcode
    try{
    if (userHospitalcode !== undefined && userrole1 !== undefined) {
      const res = await axios.get("/gethospitalcode", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          hospitalcode: userHospitalcode, // Include the hospitalcode in the query parameters
          userrole: userrole1, // Include the userrole in the query parameters
        },
      });
      setData(res.data);
      console.log(res.data);
    };
  }catch(error){
    toast.error("Something Went Wrong")
  }
  }
 
  //get patient details(one person)
  const loadAllPatient = async () => {
    try {
      if (userHospitalcode !== undefined && userrole1 !== undefined) {
        const res = await axios.get(`/GetPatient/${patientid}`, {
          headers: {
            Authorization: `Bearer ${token}`,
            Role: userrole1,
          },
          params: {
            hospitalcode: userHospitalcode, // Include the hospitalcode in the query parameters
            userrole: userrole1, // Include the userrole in the query parameters
          },
        }
        );
        const newProduct = await res.data;
        setValues(newProduct);
      }
    } catch(error){
      if (error.response.status === 403) {
        alert('Unauthorised');
      } else if (error.response.status === 401) {
        alert('Error: ' + error.response.data.error);
      } else {
        alert('Error: ' + error.response.data.error);
      }
    }
  };
  
 //get current date
 let Showdate = new Date();
 let ShowTodaydate =
   Showdate.getFullYear() +
   "-" +
   (Showdate.getMonth() + 1) +
   "-" +
   Showdate.getDate();
 const [currentDate, setCurrentDate] = useState(ShowTodaydate);

 const {
  values,
  errors,
  touched,
  setValues,
  handleChange,
  handleBlur,
  resetForm,
  handleSubmit,
} = useFormik({
  initialValues,
  validationSchema,
  onSubmit: async (values, { resetForm }) => {
     // console.log(values); // Log the values to check if they are captured correctly

     try {
      // Validate the form using Yup schema
     if (userHospitalcode !== undefined && userrole1 !== undefined) {
     const data = new FormData();
     data.append("document", JSON.stringify(values));
     data.append("currentDate", JSON.stringify(currentDate));
     data.append("gettokenId", JSON.stringify(gettokenId));
     const response = await axios.post(`/PatientUpdate/${patientid}`,data,
       {
         headers: {
           Authorization: `Bearer ${token}`,
           Role: userrole1,
           "Content-Type": "multipart/form-data",
         },
         params: {
           hospitalcode: userHospitalcode, // Include the hospitalcode in the query parameters
           userrole: userrole1, // Include the userrole in the query parameters
         },
       }
     );
     if (response.status === 200) {
       // setSuccess(true);
       // setError(false);
       return history.push("/listviewpatients");
     } else {
       console.error("Server returned a non-200 status code:", response.status);
     }
   } }catch (err) {
  
    alert("Error occurred while submitting:", err);
   }
  },
});
const currentDomain = window.location.hostname;

  return (
    <>
     <Helmet>
        <title>Update Patient Details | {currentDomain} | Hospital Management Software | Smart Solutions for Hospital Efficiency</title>
      </Helmet>
      <div className="appointment p-2">
        <form onSubmit={handleSubmit}>
          {/* <h5> Patient Registration Form</h5> */}
          <div class="fill header"><h3 class="float-left"> Update Patient  </h3> </div>
          <div className="patient-new primary-info">
          <div className="row  ml-4">
            <div className="col-md-12 mt-3">
              <div className="row">
                <div className="col-md-2">
                  <label
                    htmlFor="patientname"
                    className="input-labelemployee leftone"
                  >
                    Patient Name
                  </label>
                </div>
                <div className="col-md-4">
                  <input
                    type="text"
                    autoComplete="off"
                    name="patientname"
                    id="patientname"
                    placeholder="Patient Name"
                    value={values.patientname}
                   onChange={handleChange}
                onBlur={handleBlur}
                    className="form-control ember-text-field text-left ember-view"
                  />
       {errors.patientname && touched.patientname ? (
                    <p className="form-erroremployee">{errors.patientname}</p>
                  ) : null}
                </div>
                <div className="col-md-2">
                  <label
                    htmlFor="patientid"
                    className="input-labelemployee "
                  >
                    Patient Id
                  </label>
                </div>
                <div className="col-md-4">
                  <input
                    type="text"
                    autoComplete="off"
                    name="patientid"
                    id="patientid"
                    placeholder="Gaurdian Name"
                    value={values.patientid}
                    disabled
                    className="form-control "
                  />
                </div>
              </div>
            </div>

            {/* <div className="col-md-12 mt-3">
              <div className="row">
                <div className="col-md-2">
                  <label
                    htmlFor="bloodgroup"
                    className="input-labelemployee "
                  >
                    Blood Group
                  </label>
                </div>
                <div className="col-md-4">
                  <input
                    type="text"
                    autoComplete="off"
                    name="bloodgroup"
                    id="bloodgroup"
                    placeholder="Blood Group"
                    value={values.bloodgroup}
                   onChange={handleChange}
                onBlur={handleBlur}
                    className="form-control ember-text-field text-left ember-view"
                  />
                </div>
                <div className="col-md-2">
                  <label
                    htmlFor="gaurdianname"
                    className="input-labelemployee "
                  >
                    Gaurdian Name
                  </label>
                </div>
                <div className="col-md-4">
                  <input
                    type="text"
                    autoComplete="off"
                    name="gaurdianname"
                    id="gaurdianname"
                    placeholder="Gaurdian Name"
                    value={values.gaurdianname}
                   onChange={handleChange}
                onBlur={handleBlur}
                    className="form-control ember-text-field text-left ember-view"
                  />
                </div>
              </div>
            </div> */}

            <div className="col-md-12 mt-3">
              <div className="row">
             
                <div className="col-md-2">
                  <label
                    htmlFor="age"
                    className="input-labelemployee leftone"
                  >
                    Age
                  </label>
                </div>
                <div className="col-md-4">
                  <input
                    type="number"
                    autoComplete="off"
                    name="age"
                    id="age"
                    placeholder="Age"
                    value={values.age}
                   onChange={handleChange}
                onBlur={handleBlur}
                    className="form-control ember-text-field text-left ember-view"
                  ></input>
                     {errors.age && touched.age ? (
                    <p className="form-erroremployee">{errors.age}</p>
                  ) : null}
                </div>
               <div className="col-md-2">
                  <label
                    htmlFor="phoneno "
                    className="input-labelemployee leftone"
                  >
                    Mobile Number
                  </label>
                </div>
                <div className="col-md-4">
                  <input
                    type="number"
                    autoComplete="off"
                    name="phoneno"
                    id="phoneno"
                    placeholder="Mobile Number"
                    value={values.phoneno}
                   onChange={handleChange}
                onBlur={handleBlur}
                    className="form-control ember-text-field text-left ember-view"
                  />
                  {errors.phoneno && touched.phoneno ? (
                    <p className="form-erroremployee">{errors.phoneno}</p>
                  ) : null}
                </div>
              </div>
            </div>
            <div className="col-md-12 mt-3">
              <div className="row">
                <div className="col-md-2">
                  <label
                    htmlFor="gender"
                    className="input-labelemployee leftone"
                  >
                    Gender
                  </label>
                </div>
                <div className="col-md-4">
                  <select
                    type="text"
                    autoComplete="off"
                    name="gender"
                    id="gender"
                    placeholder="Gender"
                    value={values.gender}
                   onChange={handleChange}
                onBlur={handleBlur}
                    className="form-control ember-text-field text-left ember-view"
                  >
                    <option>Select</option>
                    <option>Male</option>
                    <option>Female</option>
                    <option>Others</option>
                  </select>
                  {errors.gender && touched.gender ? (
                    <p className="form-erroremployee">{errors.gender}</p>
                  ) : null}
                </div>
                <div className="col-md-2">
                  <label
                    htmlFor="address"
                    className="input-labelemployee leftone"
                  >
                    Address
                  </label>
                </div>
                <div className="col-md-4">
                  <textarea
                    rows="1"
                    cols="30"
                    type="text"
                    autoComplete="off"
                    name="address"
                    id="address"
                    placeholder="Address"
                    value={values.address}
                   onChange={handleChange}
                onBlur={handleBlur}
                    className="form-control ember-text-field text-left ember-view"
                  />
               {errors.address && touched.address ? (
                    <p className="form-erroremployee">{errors.address}</p>
                  ) : null}
                </div>
              </div>
            </div>

            <div className="col-md-12 mt-3">
              <div className="row">
                   <div className="col-md-2">
                  <label
                    htmlFor="email"
                    className="input-labelemployee"
                  >
                    Email
                  </label>
                </div>
                <div className="col-md-4">
                  <input
                    type="email"
                    autoComplete="off"
                    name="email"
                    id="email"
                    placeholder="Email"
                    value={values.email}
                   onChange={handleChange}
                onBlur={handleBlur}
                    className="form-control ember-text-field text-left ember-view"
                  ></input>
                  {errors.email && touched.email ? (
                    <p className="form-erroremployee">{errors.email}</p>
                  ) : null}
                </div>
                <div className="col-md-2">
                  <label
                    htmlFor="dob"
                    className="input-labelemployee"
                  >
                    Date of Birth
                  </label>
                </div>
                <div className="col-md-4">
                  <input
                    type="date"
                    autoComplete="off"
                    name="dob"
                    id="dob"
                    placeholder="DOB"
                    value={values.dob}
                   onChange={handleChange}
                onBlur={handleBlur}
                    className="form-control ember-text-field text-left ember-view"
                  />
                  {errors.dob && touched.dob ? (
                    <p className="form-erroremployee">{errors.dob}</p>
                  ) : null}
                </div>
              </div>
            </div>
            <div className="col-md-12 mt-3">
              <div className="row">
                <div className="col-md-2">
                  <label
                    htmlFor="bloodgroup"
                    className="input-labelemployee "
                  >
                    Blood Group
                  </label>
                </div>
                <div className="col-md-4">
                  <input
                    type="text"
                    autoComplete="off"
                    name="bloodgroup"
                    id="bloodgroup"
                    placeholder="Blood Group"
                    value={values.bloodgroup}
                   onChange={handleChange}
                onBlur={handleBlur}
                    className="form-control ember-text-field text-left ember-view"
                  />
                  {errors.bloodgroup && touched.bloodgroup ? (
                    <p className="form-erroremployee">{errors.bloodgroup}</p>
                  ) : null}
                </div>
                <div className="col-md-2">
                  <label
                    htmlFor="gaurdianname"
                    className="input-labelemployee "
                  >
                    Gaurdian Name
                  </label>
                </div>
                <div className="col-md-4">
                  <input
                    type="text"
                    autoComplete="off"
                    name="gaurdianname"
                    id="gaurdianname"
                    placeholder="Gaurdian Name"
                    value={values.gaurdianname}
                   onChange={handleChange}
                onBlur={handleBlur}
                    className="form-control ember-text-field text-left ember-view"
                  />
                  {errors.gaurdianname && touched.gaurdianname ? (
                    <p className="form-erroremployee">{errors.gaurdianname}</p>
                  ) : null}
                </div>
              </div>
            </div>
            <div className="col-md-12 mt-3">
              <div className="row">
                <div className="col-md-2">
                  <label
                    htmlFor="city"
                    className="input-labelemployee"
                  >
                    City
                  </label>
                </div>
                <div className="col-md-4">
                  <input
                    type="text"
                    autoComplete="off"
                    name="city"
                    id="city"
                    placeholder="City"
                    value={values.city}
                   onChange={handleChange}
                onBlur={handleBlur}
                    className="form-control ember-text-field text-left ember-view"
                  />
                         {errors.city && touched.city ? (
                    <p className="form-erroremployee">{errors.city}</p>
                  ) : null}
                </div>
                <div className="col-md-2">
                  <label
                    htmlFor="state"
                    className="input-labelemployee"
                  >
                    State
                  </label>
                </div>
                <div className="col-md-4">
                  <input
                    type="text"
                    autoComplete="off"
                    name="state"
                    id="state"
                    placeholder="State"
                    value={values.state}
                   onChange={handleChange}
                onBlur={handleBlur}
                    className="form-control ember-text-field text-left ember-view"
                  />
                  {errors.state && touched.state ? (
                    <p className="form-erroremployee">{errors.state}</p>
                  ) : null}
                </div>
              </div>
            </div>

            <div className="col-md-12 mt-3">
              <div className="row">
              </div>
            </div>

            {isAuthorized ? (   <div className="col-md-2">
                  <label
                    htmlFor="hospitalcode"
                    className="input-labelemployee "
                  >
                    Hospital Code
                  </label>
                </div>) : null}
                {isAuthorized ? (<div className="col-md-4">
                 
                    {isAuthorized ? (
                       <input
                       type="text"
                       name="hospitalcode"
                       id="hospitalcode"
                       value={values.hospitalcode}
                       disabled={true} // Set the input as disabled
                       className="form-control "
                     />
                
                  ) : (
                    <input
                      type="text"
                      name="hospitalcode"
                      id="hospitalcode"
                      value={userHospitalcode}
                      disabled={true} // Set the input as disabled
                      className="form-control "
                    />
                  )}
                </div>) : null}
                <div className="col-md-2">
                  <label
                    htmlFor="occupation"
                    className="input-labelemployee"
                  >
                    Occupation
                  </label>
                </div>
                <div className="col-md-4">
                  <input
                    type="text"
                    autoComplete="off"
                    name="occupation"
                    id="occupation"
                    placeholder="Occupation"
                    value={values.occupation}
                   onChange={handleChange}
                onBlur={handleBlur}
                    className="form-control ember-text-field text-left ember-view"
                  />
                  {errors.occupation && touched.occupation ? (
                    <p className="form-erroremployee">{errors.occupation}</p>
                  ) : null}
                </div>
          </div>
          </div> 
          
          <div className="modal-buttons">
          <Button type="submit" variant="success" className="mx-3">
          Update
        </Button>
            {/* <button
              className="input-button"
              type="reset"
            >
              Cancel
            </button> */}
          </div>
          
        </form>
      </div>
    </>
  );
};
export default PatientDeatilEdit;
