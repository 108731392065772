// Importing necessary libraries and components
import React from "react";
import { NavLink } from "react-router-dom";
// import ReactHtmlTableToExcel from "react-html-table-to-excel";
import Header from "../../Component/Header/Header";
import Sidebar from "../../Component/Sidebar";
import { UserProvider } from "../../Provider";
import AddBirth from "./BirthPages/AddBirth";
import ListViewBirth from "./BirthPages/ListViewBirth"
import UpdateBith from "./BirthPages/UpdateBith";

// Function for rendering the component related to adding a birth record
export const AddBirthComponent = () => {
  return (
    <UserProvider>
      <div className=" ">
        {/* Header component */}
        <Header />

        {/* Layout: Sidebar and Main Content */}
        <div className="row" style={{ marginTop: "50px" }}>
          {/* Sidebar component */}
          <div className="col-md-2">
            <Sidebar />
          </div>

          {/* Main Content */}
          <div className="col-md-10">
            {/* AddBirth component for adding a birth record */}
            <AddBirth />
          </div>
        </div>
      </div>
    </UserProvider>
  );
};

// Function for rendering the component related to viewing a list of birth records
export const ListViewBirthComponent = () => {
  return (
    <UserProvider>
      <div className=" ">
        {/* Header component */}
        <Header />

        {/* Layout: Sidebar and Main Content */}
        <div className="row" style={{ marginTop: "50px" }}>
          {/* Sidebar component */}
          <div className="col-md-2">
            <Sidebar />
          </div>

          {/* Main Content */}
          <div className="col-md-10">
            {/* ListViewBirth component for viewing a list of birth records */}
            <ListViewBirth />
          </div>
        </div>
      </div>
    </UserProvider>
  );
};
export const UpdateBirthComponent = () => {
  return (
    <UserProvider>
      <div className=" ">
        {/* Header component */}
        <Header />

        {/* Layout: Sidebar and Main Content */}
        <div className="row" style={{ marginTop: "50px" }}>
          {/* Sidebar component */}
          <div className="col-md-2">
            <Sidebar />
          </div>

          {/* Main Content */}
          <div className="col-md-10">
            {/* ListViewBirth component for viewing a list of birth records */}
            <UpdateBith/>
          </div>
        </div>
      </div>
    </UserProvider>
  );
};