import React from "react";
import { useFormik } from "formik";
import { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useHistory } from "react-router-dom";
import axios from "../../../helper/axios";
import { Table, Button, Modal, Form } from "react-bootstrap";
import { ToastContainer, toast } from 'react-toastify';
import * as Yup from "yup";  // Import Yup for validation
import { useUser } from "../../../Provider";
import HospitalCodeSearch from "../../../Component/HospitalCodeSearch";
import SearchRadiologyUnit from "../../../Component/SearchRadiologyUnit";
import SearchRadiologyCategory from "../../../Component/SearchRadiologyCategory";

const ValidationSchema = Yup.object().shape({
  radiologycategoryid: Yup.string().required('Enter the Radiology category'),
  hospitalcode: Yup.string().required("Please select the hospitalcode"),
  radiologyparametername:Yup.string().required('Enter the parameter name'),
  radiologyreferencerange:Yup.string().required('Enter the reference range'),
  radiologyunitid:Yup.string().required('Enter the unit'),
  radiologydescription:Yup.string().required('Enter the radiology description'),
 });
 const ValidationSchema1 = Yup.object().shape({
  radiologycategoryid: Yup.string().required('Enter the Radiology category'),
  radiologyparametername:Yup.string().required('Enter the parameter name'),
  radiologyreferencerange:Yup.string().required('Enter the reference range'),
  radiologyunitid:Yup.string().required('Enter the unit'),
  radiologydescription:Yup.string().required('Enter the radiology description'),
 });

const initialValues = {
  radiologycategoryid: "",
  radiologyparametername: "",
  radiologyreferencerange: "",
  hospitalcode: "",
  radiologyunitid: "",
  radiologydescription: "",
};

const AddRadiologyParameter = () => {
  const { user, userHospitalcode, userrole1, isAuthRole, isAuthorized, gettokenId, token } = useUser();
  const history = useHistory();
  const [category, setCategory] = useState([]);
  const [message, setMessage] = useState("");
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [unit1, setUnit] = useState([]);
  // initialised isAuthorized value as false
  // const [isAuthorized, setIsAuthorized] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  //get jwttoken from sessionStorage
  // const token = sessionStorage.getItem("jwtToken");
  //get current date
  let Showdate = new Date();
  let ShowTodaydate =
    Showdate.getFullYear() +
    "-" +
    (Showdate.getMonth() + 1) +
    "-" +
    Showdate.getDate();
  const [currentDate, setCurrentDate] = useState(ShowTodaydate);

    const getValidationSchema = () => {
      if (userHospitalcode === 1) {
        return ValidationSchema;

      } else  {
        return ValidationSchema1;
      }
    }

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    resetForm,
    setValues
  } = useFormik({
    initialValues,
    validationSchema: getValidationSchema, // Apply validation schema
    onSubmit: async (values, action) => {
      try {
        const data = new FormData();
        data.append("document", JSON.stringify(values));
        data.append("currentDate", JSON.stringify(currentDate));
        data.append("gettokenId", JSON.stringify(gettokenId));
        data.append("userHospitalcode", JSON.stringify(userHospitalcode));
        console.log(JSON.stringify(values));
        //   console.log(JSON.stringify(hospitalcode));

        const response = await axios.post("/addradiologyparameter", data, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        });
        console.log(
          "🚀 ~ file: Registration.jsx ~ line 11 ~ Registration ~ values",
          values
        );
        if (response.status === 200) {
          return history.push("/listviewradiology");
        }
      } catch (error) {
        console.log(error)
        toast("Something Went Wrong")
      }
    },
  });


  const handleSearchChange = async (searchTerm) => {
    setSearchValue(searchTerm);
    const selectedHospitalCode = searchTerm.hospitalcode || userHospitalcode;
    await fetchUnitName(selectedHospitalCode); // Fetch floor names based on the selected hospital code
  };
  const handleReset = () => {
    // Reset the form using Formik's resetForm function
    resetForm();
  };
  const [data, setData] = useState([]);
  const [userRole, setRole] = useState();
  // const [gettokenId, setTokenId] = useState();
  // const [userHospitalcode, setUserHospitalcode] = useState();
  // const [userrole1, setUserrole1] = useState();
  // Function to get login user's details

  const [searchValues, setSearchValues] = useState("");
  //Hospital code search
  const onSearchItems = (searchTerm) => {
    console.log("------", searchTerm)
    setSearchValues(searchTerm);
    if (searchTerm && searchTerm.hospitalcode) {
      setValues({
        ...values,
        hospitalcode: searchTerm.hospitalcode,
        // Update other fields as needed
      });
    }
  };

    //get Unit
    const handleSearchChanges = (searchTerms) => {
      console.log("---------------",searchTerms)
      setSearchValues(searchTerms); // Update the searchValue state with the search term
      if (searchTerms) {
       setValues({
        ...values,
        radiologyunitid: searchTerms.radiologyunitid,
        // Update other fields as needed
      });
    }
    };


      //get category
  const handleSearchCategoryChanges = (searchTerms) => {
    console.log("---------------",searchTerms)
    setSearchValues(searchTerms); // Update the searchValue state with the search term
    if (searchTerms) {
     setValues({
      ...values,
      radiologycategoryid: searchTerms.chargecode,
      // Update other fields as needed
    });
  }
  };

  const fetchcategoryName = async (selectedHospitalCode) => {
    try {
      const response = await axios.get("/getradiologycategory", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          hospitalcode: selectedHospitalCode, // Use the selected hospital code
        },
      });

      if (response.status === 200) {
        setCategory(response.data);
      } else {
        console.error(`Failed to fetch available floor numbers with status: ${response.status}`);
      }
    } catch (error) {
      console.error('Error fetching available floor numbers:', error.message);
    }
  };


  //when page load or refresh happen this function will got called
  useEffect(() => {
    const loadData = async () => {
      // await loadAllDetails();
      // loadHospitalcode(); will wait for loadAllDetails(); to got execute
      await loadHospitalcode();
      const selectedHospitalCode = values.hospitalcode || userHospitalcode;
      await fetchUnitName(selectedHospitalCode);
      await fetchcategoryName(selectedHospitalCode);
    };
    loadData();
  }, [userHospitalcode, userrole1, isAuthorized, values.hospitalcode]);

  //  get all hospital 
  const loadHospitalcode = async () => {
    //when userHospitalcode and userrole1 is not undefined then execute /gethospitalcode
    if (userHospitalcode !== undefined && userrole1 !== undefined) {
      const res = await axios.get("/gethospitalcode", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          hospitalcode: userHospitalcode, // Include the hospitalcode in the query parameters
          userrole: userrole1, // Include the userrole in the query parameters
        },
      });
      setData(res.data);
      console.log(res.data);
    };
  }


  const fetchUnitName = async (selectedHospitalCode) => {
    try {
      const response = await axios.get("/unitinradiology", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          hospitalcode: selectedHospitalCode, // Use the selected hospital code
        },
      });

      if (response.status === 200) {
        setUnit(response.data);
      } else {
        console.error(`Failed to fetch available floor numbers with status: ${response.status}`);
      }
    } catch (error) {
      console.error('Error fetching available floor numbers:', error.message);
    }
  };

  const currentDomain = window.location.hostname;

  return (
    <div className="p-3 pt-0 appointment">
      <Helmet>
        <title>Aarogyasaarthi | {currentDomain} | Add Radiology Parameter</title>
      </Helmet>
      <form onSubmit={handleSubmit}>
        {success && <div>Add Radiology Parameter</div>}
        {error && <div>Error</div>}
        <div class="fill header"><h3 class="float-left"> Add Radiology Parameter</h3> </div>
        <div className="patient-new primary-info">
          <div className="row">
            <div className="col-md-12">
              <div className="row">
                <div className="col-md-6">
                  <div className="col-md-12 mt-3">
                    <div className="row">

                      <div className="col-md-4">
                        <label
                          htmlFor="radiologycategoryid"
                          className="col-form-label leftone"
                        >
                          Radiology Category
                        </label>
                      </div>
                      <div className="col-md-8">
                      <SearchRadiologyCategory
                      onRadiologyCategoryChange={handleSearchCategoryChanges}
                      hospitalvalue={values.hospitalcode || userHospitalcode}
                      
                      />
                        {/* <select
                          type="text"
                          autoComplete="off"
                          name="radiologycategoryid"
                          id="radiologycategoryid"
                          placeholder="category name"
                          value={values.radiologycategoryid}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          style={{ width: '73%', height: '32px', border: '1px solid #ced4da', color: '#495057' }}
                        >
                          <option>Select</option>
                          {category.map((code) => (
                            <option value={code.radiologycategoryid} key={code.radiologycategoryid}>
                              {code.radiologycategoryname}
                            </option>
                          ))}
                        </select> */}
                        {errors.radiologycategoryid && touched.radiologycategoryid ? (
                          <p className="form-erroremployee">{errors.radiologycategoryid}</p>
                        ) : null}
                      </div></div></div>
                  <div className="col-md-12 mt-3">
                    <div className="row">

                      <div className="col-md-4">
                        <label
                          htmlFor="radiologyparametername"
                          className="col-form-label leftone"
                        >
                          Parameter Name
                        </label>
                      </div>
                      <div className="col-md-8">
                        <input
                          type="text"
                          autoComplete="off"
                          name="radiologyparametername"
                          id="radiologyparametername"
                          placeholder="Parameter Name"
                          value={values.radiologyparametername}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          style={{ width: '73%', height: '32px', border: '1px solid #ced4da', color: '#495057' }}
                        />
                        {errors.radiologyparametername && touched.radiologyparametername ? (
                          <p className="form-erroremployee">{errors.radiologyparametername}</p>
                        ) : null}
                      </div></div></div>
                  <div className="col-md-12 mt-3">
                    <div className="row">

                      <div className="col-md-4">
                        <label
                          htmlFor="radiologyreferencerange"
                          className="col-form-label leftone"
                        >
                          Reference Range
                        </label>
                      </div>
                      <div className="col-md-8">
                        <input
                          type="text"
                          autoComplete="off"
                          name="radiologyreferencerange"
                          id="radiologyreferencerange"
                          placeholder="Reference Range"
                          value={values.radiologyreferencerange}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          style={{ width: '73%', height: '32px', border: '1px solid #ced4da', color: '#495057' }}
                        />
                        {errors.radiologyreferencerange && touched.radiologyreferencerange ? (
                          <p className="form-erroremployee">{errors.radiologyreferencerange}</p>
                        ) : null}
                      </div></div></div>
                </div>
                <div className="col-md-6">
                {isAuthorized ? (<div className="col-md-12 mt-3">
              <div className="row">
  
                <div className="col-md-4">
                  <label
                    htmlFor="hospitalcode"
                    className="col-form-label leftone"
                  >
                    Hospital Code
                  </label>
                </div>
                <div className="col-md-8">
                  {/* Conditionally render the dropdown and input field */}
                  {isAuthorized ? (
                      
                  <HospitalCodeSearch  onSearchItem={onSearchItems} />
                       
                    // <select
                    //   type="text"
                    //   autoComplete="off"
                    //   name="hospitalcode"
                    //   id="hospitalcode"
                    //   placeholder="Hospital Code"
                    //   value={values.hospitalcode}
                    //   onChange={handleChange}
                    //   onBlur={handleBlur}
                    //   style={{ width: '73%', height: '32px', border: '1px solid #ced4da', color: '#495057' }}
                    // >
                    //   <option>Select</option>
                    //   {data.map((code) => (
                    //     <option value={code.hospitalcode} key={code.id}>
                    //       {code.hospitalcode}
                    //     </option>
                    //   ))}
                    // </select>
                  ) : (
                    <input
                      type="text"
                      name="hospitalcode"
                      id="hospitalcode"
                      value={userHospitalcode}
                      disabled={true} // Set the input as disabled
                      className="form-control "
                    />
                  )}
                  {isAuthorized && errors.hospitalcode && touched.hospitalcode ? (
                    <p className="form-erroremployee">{errors.hospitalcode}</p>
                  ) : null}
                </div></div></div>) : null}


                  <div className="col-md-12 mt-3">
                    <div className="row">
                      <div className="col-md-4">
                        <label
                          htmlFor="radiologyunitid"
                          className="col-form-label leftone"
                        >
                          Unit
                        </label>
                      </div>
                      <div className="col-md-8">
                      <SearchRadiologyUnit
                      onRadiologyUnitChange={handleSearchChanges}
                      hospitalvalue={values.hospitalcode || userHospitalcode}
                      
                      />
                        {/* <select
                          type="text"
                          autoComplete="off"
                          name="radiologyunitid"
                          id="radiologyunitid"
                          placeholder="Unit"
                          value={values.radiologyunitid}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          style={{ width: '73%', height: '32px', border: '1px solid #ced4da ', color: '#495057' }}
                        >
                          <option>
                            Select
                          </option>


                          {unit1.map((code) => (
                            <option value={code.radiologyunitid} key={code.radiologyunitid}>
                              {code.radiologyunitname}
                            </option>
                          ))}
                        </select> */}
                        {errors.radiologyunitid && touched.radiologyunitid ? (
                          <p className="form-erroremployee">{errors.radiologyunitid}</p>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12 mt-3">
                    <div className="row">

                      <div className="col-md-4">
                        <label
                          htmlFor="radiologydescription"
                          className="col-form-label leftone"
                        >
                          Radiology Description
                        </label>
                      </div>
                      <div className="col-md-8">
                        <input
                          type="text"
                          autoComplete="off"
                          name="radiologydescription"
                          id="radiologydescription"
                          placeholder="radiologydescription"
                          value={values.radiologydescription}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          style={{ width: '73%', height: '32px', border: '1px solid #ced4da', color: '#495057' }}
                        />



                        {errors.radiologydescription && touched.radiologydescription ? (
                          <p className="form-erroremployee">{errors.radiologydescription}</p>
                        ) : null}
                      </div></div></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-6  mt-3">
          <Button
            type="submit"
            variant="success"
            className="mx-3"
          >
            Save
          </Button>
          <Button
            variant="primary"
            className="mx-3"
            onClick={handleReset}
          >
            Reset
          </Button>
        </div>
        <ToastContainer/>
      </form>
    </div>
  );
};

export default AddRadiologyParameter;
