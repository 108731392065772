import React, { useState, useEffect } from "react";
import axios from '../helper/axios';
import Autocomplete from "@mui/material/Autocomplete";
import { TextField, InputAdornment, IconButton } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useUser } from "../Provider";


const SearchMedicineName = ({ onMedicineChange, hospitalvalue, selectedCategory }) => {
    const { userrole1, token } = useUser();
    const [data, setMedicineNames] = useState([]);
    const [selectedMedicine, setSelectedMedicine] = useState('');
    const [openAutocomplete, setOpenAutocomplete] = useState(false);

    useEffect(() => {
        fetchMedicineNames();
        // Clear selected medicine when category changes
        setSelectedMedicine('');
    }, [hospitalvalue, selectedCategory, userrole1]);

    const fetchMedicineNames = async () => {
        try {
            const response = await axios.get("/medicinenames", {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                params: {
                    hospitalcode: hospitalvalue,
                    category: selectedCategory,
                },
            });

            if (response.status === 200) {
                setMedicineNames(response.data);
            } else {
                console.error(`Failed to fetch medicine names with status: ${response.status}`);
            }
        } catch (error) {
            console.error('Error fetching medicine names:', error.message);
        }
    };

    const handleMedicineChange = (event, value) => {
        if (value) {
            setSelectedMedicine(value);
            onMedicineChange(value);
        } else {
            // Handle the case when no value is selected
            setSelectedMedicine('');
        }
    };

    const getOptionLabel = (option) => {
        // Handle the case when option is null or undefined
        return option?.medicinename || '';
    };
    const handleExpandClick = () => {
        setOpenAutocomplete(!openAutocomplete);
    };
    return (
        <div className="searchbar">
            <Autocomplete
                freeSolo
                id="free-solo"
                options={data}
                getOptionLabel={getOptionLabel}
                open={openAutocomplete}
                onOpen={() => setOpenAutocomplete(true)}
                onClose={() => setOpenAutocomplete(false)}
                value={selectedMedicine} // Set the value of the selected medicine
                onChange={handleMedicineChange}
                renderInput={(params) =>
                    <TextField
                        {...params}
                        placeholder="Select Medicine"
                        InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton onClick={handleExpandClick}>
                                        <ExpandMoreIcon />
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                }
            />
        </div>
    );
};

export default SearchMedicineName;
