import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import axios from "./helper/axios";

const PrivateRoute = ({ props,component: Component, onUpdateAuthorizationStatus,onUpdateAuthorization, 
  onUpdateAuthorization1,onUpdateAuthorization2,onUpdateAuthorization3, ...rest}) => {
  const history = useHistory();
  const token = localStorage.getItem("jwtToken");
  const [userHospitalcode, setUserHospitalcode] = useState(); // Define these state variables
  const [userrole1, setUserrole1] = useState();
  const [isAuthorized, setIsAuthorized] = useState(false);
  const [isAuthRole, setIsAuthRole] = useState(false);
  const [isAuthRole1, setIsAuthRole1] = useState(false);
  const [isAuthRole2, setIsAuthRole2] = useState(false);
  const [isAuthRole3, setIsAuthRole3] = useState(false);
  //const [isAuthRole4, setIsAuthRole4] = useState(false);
  // const [isAuthRole5, setIsAuthRole5] = useState(false);
  // const [isAuthRole6, setIsAuthRole6] = useState(false);
  // const [isAuthRole7, setIsAuthRole7] = useState(false);
  const [userdesignation, setDesignation] = useState();
  // Fetch user details and set userHospitalcode and userrole1 here
  const loadAllDetails = async () => {
    try {
      const response = await axios.post('/loginuser', null, {
        headers: { Authorization: `Bearer ${token}` },
      });
  
      // Debugging: Log the entire response
      console.log("Response:", response);
  
      const UserRoles = response.data.person[0][0].role1;
      const hospitalcode = response.data.person[0][0].hospitalcode;
      const employeeid = response.data.person[0][0].empid;
      const designation = response.data.person[0][0].designation;
     
      // Debugging: Log the values of userHospitalcode and userrole1
      console.log("userHospitalcode:", hospitalcode);
      console.log("userrole1:", UserRoles);
      const authorizationResults = UserRoles === 'Admin';
      setIsAuthRole(authorizationResults);

      const authorizationResults1 = designation === 'Pathologist';
      setIsAuthRole1(authorizationResults1);

      const authorizationResults2 = designation === 'Radiologist';
      setIsAuthRole2(authorizationResults2);

      const authorizationResults3 = designation === 'Nurse';
      setIsAuthRole3(authorizationResults3);

      // const authorizationResults4 = designation === 'Manager';
      // setIsAuthRole4(authorizationResults4);

      // const authorizationResults5 = designation === 'Doctor';
      // setIsAuthRole5(authorizationResults5);

      // const authorizationResults6 = designation === 'Pharmacist';
      // setIsAuthRole6(authorizationResults6);

      // const authorizationResults7 = designation === 'Receptionist';
      // setIsAuthRole7(authorizationResults7);

      setUserHospitalcode(hospitalcode);
      setUserrole1(UserRoles);
      setDesignation(designation);

      // Debugging: Log the condition result
      const authorizationResult = hospitalcode === 1 && UserRoles === 'Superadmin' ;
      console.log("Authorization Result:", authorizationResult);
      setIsAuthorized(authorizationResult);
      console.log("isAuthorized after setIsAuthorized:", isAuthorized);
    } catch (error) {
      console.error("Error:", error);
  
      if (error.response.status === 403) {
        alert('Token Expired. Please login again.');
        localStorage.removeItem('jwtToken');
        history.push('/');
      } else if (error.response.status === 401) {
        alert('Invalid token. Please login again.');
        localStorage.removeItem('jwtToken');
        history.push('/');
      } else {
        alert('Error: ' + error.response.data.error);
      }
    }
  };

  useEffect(() => {
    loadAllDetails();
    const token = localStorage.getItem("jwtToken"); // Get the token from localStorage
    if (!token) {
      // Redirect to the login page if the token is not found
      history.push("/");
    }
    console.log("isAuthorized inside useEffect:", isAuthorized);
    onUpdateAuthorizationStatus(isAuthorized);
    onUpdateAuthorization(isAuthRole);
    onUpdateAuthorization1(isAuthRole1);
    onUpdateAuthorization2(isAuthRole2);
    onUpdateAuthorization3(isAuthRole3);
    // onUpdateAuthorization4(isAuthRole4);
    // onUpdateAuthorization5(isAuthRole5);
    // onUpdateAuthorization6(isAuthRole6);
    // onUpdateAuthorization7(isAuthRole7);
    console.log("|||||||||||||||||||||",userdesignation);
 
  }, [history,userHospitalcode,userrole1, isAuthorized,isAuthRole,onUpdateAuthorization1,onUpdateAuthorization2,onUpdateAuthorizationStatus,onUpdateAuthorization]);




  
  return (
    <>
      <Component />
    </>
  );
};

export default PrivateRoute;

