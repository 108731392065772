import React, { useState, useEffect } from 'react';
import {  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper,TextareaAutosize } from '@mui/material';
import axios from "../../../helper/axios";
import { useUser } from "../../../Provider";
import { useParams } from 'react-router-dom/cjs/react-router-dom';
import ConsultantRegisterForm from './ConsultantRegisterForm';
  // ConsultantRegisterTable component
  const ConsultantRegisterTable = ({hideForm}) => {
    const { admitnumber } = useParams();
    console.log({ admitnumber })
    const { user, userHospitalcode, userrole1, isAuthRole, isAuthorized, gettokenId,token } = useUser();
    //const token = sessionStorage.getItem("jwtToken");
    const[consultantRegisterData,setConsultantRegisterData]=useState([]) ;
    const getBedDetails = async () => {
  
      try {
          if (userHospitalcode !== undefined && userrole1 !== undefined) {
              const res = await axios.get(`/getipdconsultant/${admitnumber}`, {
                  headers: {
                      Authorization: `Bearer ${token}`,
                  },
              
              });
              console.log(res.data)
              // Check if the response status code is  200
              if (res.status === 200) {
                setConsultantRegisterData(res.data);
              } else {
                  // Handle unexpected response status codes (e.g., 404, 500, etc.) as errors.
                  alert(`Request failed with status: ${res.status}`);
                  // Optionally, you can set an error state or display an error message to the user.
              }
          }
      }
      catch (error) {
          // Handle network errors or other exceptions.
         console.log(`An error occurred: ${error.message}`);
          // Optionally, you can set an error state or display an error message to the user.
      }
  };
  useEffect(() => {
    const loadData = async () => {
        await getBedDetails()
    };
  
    loadData();
  }, [userHospitalcode, userrole1, isAuthorized]);
  const handleonConsultantAdd = () => {
    // Function to fetch the updated NurseNote data
    getBedDetails();
  };
    return (
      <>
      {!hideForm && <ConsultantRegisterForm onConsultantAdd={handleonConsultantAdd} /> }
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell style={{ fontWeight: 'bold' }}>Applied Date</TableCell>
              <TableCell style={{ fontWeight: 'bold' }}>Consultant Doctor</TableCell>
              <TableCell style={{ fontWeight: 'bold',width: '30%' }}>Instruction</TableCell>
              <TableCell style={{ fontWeight: 'bold' }}>Instruction Date</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {consultantRegisterData.map((row, index) => (
              <TableRow key={index}>
                <TableCell>{row.applieddate}</TableCell>
                <TableCell>{row.fname}</TableCell>
                <TableCell>{row.instruction}</TableCell>
                <TableCell>{row.instructiondate}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      </>
    );
  };
  export default ConsultantRegisterTable;