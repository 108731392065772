import { useState, useEffect } from "react";
import axios from '../helper/axios';
import Autocomplete from "@mui/material/Autocomplete";
import {
    TextField,
    InputAdornment,
    IconButton,
} from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useUser } from "../Provider";

const SearchOTTechnician = ({ onSearchChanges, hospitalvalue }) => {
    const { user, userHospitalcode, userrole1, isAuthRole, isAuthorized, gettokenId, token } = useUser();
    const [value, setValue] = useState("");
    const [data, setOTTechnician] = useState([]);
    const [openAutocomplete, setOpenAutocomplete] = useState(false);

    useEffect(() => {
        const loadData = async () => {
            await getOTTechniciandetails();
        };

        loadData();
    }, [userrole1, hospitalvalue]);

    const getOTTechniciandetails = async () => {

        try {
          if (userHospitalcode !== undefined && userrole1 !== undefined) {
            const res = await axios.get(`/getOTTechnician`, {
              headers: {
                Authorization: `Bearer ${token}`,
              },
              params: {
                hospitalcode: hospitalvalue, // Include the hospitalcode in the query parameters
                userrole: userrole1, // Include the userrole in the query parameters
              },
            });
            console.log(res.data)
            // Check if the response status code is  200
            if (res.status === 200) {
              setOTTechnician(res.data);
            } else {
              // Handle unexpected response status codes (e.g., 404, 500, etc.) as errors.
              alert(`Request failed with status: ${res.status}`);
              // Optionally, you can set an error state or display an error message to the user.
            }
          }
        }
        catch (error) {
          // Handle network errors or other exceptions.
          console.log(`An error occurred: ${error.message}`);
          // Optionally, you can set an error state or display an error message to the user.
        }
      };

    const onSearch = (searchTerms, item) => {
        onSearchChanges(item);
    };

    const handleExpandClick = () => {
      setOpenAutocomplete(!openAutocomplete);
  };
    return (
        <>
            <div className="searchbar">
                <Autocomplete
                    freeSolo
                    id="free-solo"
                    options={data}
                    getOptionLabel={(option) => `${option.fname}`}
                    open={openAutocomplete}
                    onOpen={() => setOpenAutocomplete(true)}
                    onClose={() => setOpenAutocomplete(false)}
                    onChange={onSearch}
                    renderInput={(params) =>
                        <TextField
                            {...params}
                            placeholder="Select OT Technician"
                            InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton onClick={handleExpandClick}>
                                            <ExpandMoreIcon />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    }
                    renderOption={(props, option) => (
                        <li {...props}>
                            {option.fname}
                        </li>
                    )}
                />

            </div>
        </>
    );
}

export default SearchOTTechnician;