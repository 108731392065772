import React, { useRef, useEffect, useState } from 'react'
import { useReactToPrint } from 'react-to-print'
import image from "./PrintTemplateImage.png"
import { useParams,useHistory } from 'react-router-dom'
import "./PrintCSSTemplateBirth.css"
import { Grid } from '@mui/material';
import axios from '../../helper/axios';
import moment from 'moment'
import API from "../../helper/axios";
import background from './background.jpg'; // Import the local image

const PrintTemplateBirthview = (props) => {
    const history = useHistory();
    const params = useParams()
    const today = new Date()
    const token = localStorage.getItem("jwtToken");
    const [birthData, setBirthData] = useState([{
        dob:"",
        nationality: "INDIAN".toUpperCase(),
    }])
    console.log(birthData)
    const componentRef = useRef();
    const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    // documentTitle: birthData.birthid,
    onAfterPrint:  props.handleClosed ,
    });
//get the Patient details according to patientid
    const getBirthDetails = async() => {
      console.log(props.bId);
        const res = await axios.get(`/GetBirthBybirth/${props.bId}`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          console.log(res.data[0])
          
    const formattedData = {
      ...res.data[0],
      // Convert specific fields to uppercase, e.g., childname, fathername, etc.
      childname: res.data[0].childname.toUpperCase(),
      fathername: res.data[0].fathername.toUpperCase(),
      mothername: res.data[0].mothername.toUpperCase(),
      village: res.data[0].village.toUpperCase(),
      po: res.data[0].po.toUpperCase(),
      ps: res.data[0].ps.toUpperCase(),
      district: res.data[0].district.toUpperCase(),
      state: res.data[0].state.toUpperCase(),
      religion: res.data[0].religion.toUpperCase(),
      gender: res.data[0].gender.toUpperCase(),
     // hospitalname: res.data[0].hospitalname.toUpperCase(),
      

      // Add more fields as needed
    };
          setBirthData(formattedData)
    } 
    useEffect(() => {
        getBirthDetails()
        return () => {}
    },[])
    const [data, setData] = useState([]);
    const [userHospitalcode, setUserHospitalcode] = useState();
    const [userrole1, setUserrole1] = useState();
    const [isAuthorized, setIsAuthorized] = useState(false);
    // Function to get login user's details
    const loadAllDetails = async () => {
      try {
        const response = await axios.post('/loginuser', null, {
          headers: { Authorization: `Bearer ${token}` },
        });
    
        // Debugging: Log the entire response
        console.log("Response:", response);
    
        const UserRoles = response.data.person[0][0].role1;
        const hospitalcode = response.data.person[0][0].hospitalcode;
    
        // Debugging: Log the values of userHospitalcode and userrole1
        console.log("userHospitalcode:", hospitalcode);
        console.log("userrole1:", UserRoles);
    
        setUserHospitalcode(hospitalcode);
        setUserrole1(UserRoles);
        // Debugging: Log the condition result
        const authorizationResult = hospitalcode === 1 && UserRoles === 'Superadmin';
        console.log("Authorization Result:", authorizationResult);
        setIsAuthorized(authorizationResult);
        console.log("isAuthorized after setIsAuthorized:", isAuthorized);
      } catch (error) {
        console.error("Error:", error);
    
        if (error.response.status === 403) {
          alert('Token Expired. Please login again.');
          localStorage.removeItem('jwtToken');
          history.push('/');
        } else if (error.response.status === 401) {
          alert('Invalid token. Please login again.');
          localStorage.removeItem('jwtToken');
          history.push('/');
        } else {
          alert('Error: ' + error.response.data.error);
        }
      }
    };
    //when page load or refresh happen this function will got called
    useEffect(() => {
      const loadData = async () => {
        await loadAllDetails();
        // loadHospitalLogo(); will wait for loadAllDetails(); to got execute
        await loadHospitalLogo();
      };
      loadData();
    }, [userHospitalcode, userrole1, isAuthorized]);

    console.log("akjsbnckjsdbckjdbcsdbckjdchospitalname", birthData.hospitalname)
     //get all hospital code
    //  const loadHospitalLogo = async () => {
    //     // Check if userHospitalcode and birthData.hospitalname are not undefined
    //     if (userHospitalcode !== undefined && birthData.hospitalname !== undefined) {
    //       try {
    //         const res = await axios.get(`/gethospitallogo/${birthData.hospitalcode}`, {
    //           headers: {
    //             Authorization: `Bearer ${token}`,
    //           },
    //           params: {
    //             hospital: birthData.hospitalname,
    //           },
    //         });
      
    //         setData(res.data[0]);
    //         console.log(res.data);
    //       } catch (error) {
    //         console.error("Error fetching hospital logo:", error);
    //         // Handle errors, such as displaying a default logo
    //       }
    //     }
    //   };
    const loadHospitalLogo = async () => {
      //when userHospitalcode is not undefined
      if (userHospitalcode !== undefined ) {
        console.log(userHospitalcode)
        const res = await axios.get(`/gethospitallogo/${birthData.hospitalcode}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: {
            hospitalcode: birthData.hospitalcode, // Include the hospitalcode in the query parameters
            //userrole: userrole1, // Include the userrole in the query parameters
          },
        });
        setData(res.data[0]);
        console.log(res.data);
      };
    }
    return(
        <>
        <button className="btn btn-success" style={{marginLeft: "68%", backgroundColor: "white", color: "green"}} onClick={handlePrint}>Print Certificate</button>
            <div
                // className="pass-title"
                ref={componentRef}
                style={{
                  width: '100%',
                  height: '100%',
                  padding: '40px',
                  backgroundImage: `url(${background})`, // Use the imported image
                  backgroundSize: 'cover',
                }}
            >

      {/* <div
        // className="pass-title"
        ref={componentRef}
        style={{ width: "100%", height: window.innerHeight, padding: "40px" }}
      > */}
                <img src={`${API.defaults.baseURL}/uploadimage/birthcertificate/${data.birthcertificate
                    }`} style={{width: '100%', marginBottom: "10px", height:"120px"}}/>
                {/* <lable><span style={{fontWeight: "bolder", fontSize: "10px", width: "100%", marginBottom: "1px"}}>* OPD Timining : 9AM-3PM.For Emergency 24*7</span>
                  &nbsp;&nbsp;&nbsp;<span style={{fontWeight: "bold", fontSize: "10px"}}>* हर रिववार ओपीडी बंद रहता है , लेिकन िनक खुला रहेगा।</span>&nbsp;&nbsp;&nbsp;<span style={{fontSize: "10px"}}>  :9708162912, 06155295038, 06155231824</span></lable> */}
                {/* <div style={{textAlign: "center", marginTop: "1px"}}>
                    <b>Child Details</b>
                </div> */}
                {/* <div class="box"></div> */}


                <Grid container spacing={3} className="container" style={{paddingTop: "20px"}}>
                     <Grid item xs={12} className="field">
                       <span className="label">NAME OF CHILD:</span>
                       <span className="value">{birthData.childname}</span>
                       </Grid>
               </Grid>     

               <Grid container spacing={3} className="container">
                     <Grid item xs={12} className="field">
                       <span className="label">FATHER's NAME :</span>
                       <span className="value">{birthData.fathername}</span>
                       </Grid>
               </Grid>     

              

           
               <Grid container spacing={3} className="container">
                     <Grid item xs={12} className="field">
                       <span className="label">Mother's NAME :</span>
                       <span className="value">{birthData.mothername}</span>
                       </Grid>
               </Grid>  


            {/* <Grid container spacing={4} style={{fontSize: "15px", marginBottom: "15px" }}>
              
                <Grid item sm={8}>
                    <b>ADDRESS :- </b> 
                    <Grid container spacing={3} style={{fontSize: "12px"}}>
                    <Grid item xs={4}>
                        
                    </Grid>
                  

                    <Grid item xs={4}>
                        <b>Vill. :-    </b>{birthData.village}
                    </Grid>
                 
                    <Grid item xs={4}>
                    <b>P.O :-    </b>{birthData.gaurdianname}
                    </Grid>
                    
                </Grid>
                
                </Grid>
            </Grid> */}


          <Grid container spacing={3} className="container">
                     <Grid item xs={3} className="field">
                       <span className="label">ADDRESS :</span>

                       </Grid>
                       <Grid item xs={5} className="label1">
                       <span className="label">Vill. :</span>
                       <span className="value">{birthData.village}</span>
                       </Grid>

                       <Grid item xs={4} className="label1">
                       <span className="label">PO. :</span>
                       <span className="value">{birthData.po}</span>
                       </Grid>

                       <Grid item xs={3} className="field">
                       

                       </Grid>

                       <Grid item xs={5} className="label1">
                       <span className="label">PS. :</span>
                       <span className="value">{birthData.ps}</span>
                       </Grid>

                       <Grid item xs={4} className="label1">
                       <span className="label">DIST. :</span>
                       <span className="value">{birthData.district}</span>
                       </Grid>

                       <Grid item xs={3} className="field">
                       

                       </Grid>

                       <Grid item xs={5} className="label1">
                       <span className="label">STATE :</span>
                       <span className="value">{birthData.state}</span>
                       </Grid>

                       <Grid item xs={4} className="label1">
                       <span className="label">PIN CODE :</span>
                       <span className="value">{birthData.pin}</span>
                       </Grid>
                       
               </Grid>  

             <Grid container spacing={3} className="container">
                     <Grid item xs={12} className="field">
                       <span className="label">NATIONALITY :</span>
                       <span className="value">{birthData.nationality || "INDIAN"}</span>
                       </Grid>
               </Grid>  

          

            <Grid container spacing={3} className="container">
                     <Grid item xs={12} className="field">
                       <span className="label">RELIGION :</span>
                       <span className="value">{birthData.religion}</span>
                       </Grid>
               </Grid> 
            

               <Grid container spacing={3} className="container">
                     <Grid item xs={12} className="field">
                       <span className="label">SEX :</span>
                       <span className="value">{birthData.gender}</span>
                       </Grid>
               </Grid>

            <Grid container spacing={3} className="container">
                     <Grid item xs={12} className="field">
                       <span className="label">BIRTH PLACE :</span>
                       <span className="value">{birthData.hospitalname}</span>
                       </Grid>
               </Grid> 

               <Grid container spacing={3} className="container">
                     <Grid item xs={6} className="field">
                       <span className="label">DATE OF BIRTH :</span>
                       <span className="value">{birthData.dob}</span>
                       </Grid>

                       <Grid item xs={6} className="field">
                       <span className="label">TIME :</span>
                       <span className="value">{birthData.tob}</span>
                       </Grid>

               </Grid> 
            
               <Grid container spacing={3} className="container">
                     <Grid item xs={12} className="field">
                       <span className="label">WEIGHT :</span>
                       <span className="value">{birthData.weight}Kg</span>
                       </Grid>
               </Grid>
            


           
                 

             <div style={{padding: "40px 8px 40px 15px", }}>
                <div style={{float: "left", padding: "0px"}}>
                <Grid item>
                        <b>DATE : </b> {today. getDate()+"/"+today.getMonth()+"/"+today.getFullYear()+" " }
                    </Grid>
                
                </div>
                <div style={{float: "right", paddingRight: "30px"}}>
                  <b>Signature</b> 
                </div>
                </div>
                <div style={{}}>
                <div style={{textAlign: "center", fontSize: "20px", padding: "80px", fontFamily: "AmstelvarAlpha"}}><strong>SAVE AND RESPECT FEMALE CHILD</strong></div>
                </div>
            </div>
           
            {/* <button className="btn btn-success" style={{marginLeft: "40%"}} onClick={handlePrint}>Print pass</button> */}
        </>
    )
}

export default PrintTemplateBirthview