//in this patient details will be displayed along with edit button

import axios from "../../../helper/axios";
import React, { useEffect, useState } from "react";
import { NavLink, useHistory, useParams } from "react-router-dom";
import { GrEdit } from "react-icons/gr";
import "bootstrap/dist/css/bootstrap.min.css";
import { Table, Button, Modal, Form } from "react-bootstrap";
import { useUser } from "../../../Provider";
import { Helmet } from "react-helmet";

const EditPatient = () => {
  const history = useHistory();
  const {user, userHospitalcode, userrole1, isAuthRole,isAuthorized,gettokenId,token } = useUser();
  console.log(user, userHospitalcode, userrole1, isAuthRole,isAuthorized,gettokenId )
  const { patientid } = useParams();
  console.log("id " + patientid);
  const [patientedit, setPatientedit] = useState({
    patientname: "",
    gaurdianname: "",
    phoneno: "",
    address: "",
    bloodgroup: "",
    email: "",
    gender: "",
    dob: "",
    age: "",
    state: "",
    city: "",
    occupation: "",
    hospitalcode:""
  });
  //const token = sessionStorage.getItem("jwtToken");
  useEffect(() => {
    const loadData = async () => {
      //loadHospitalcode(); will wait for loadAllDetails(); to got execute
      await loadAllPatient();
    };
    loadData();
  }, [userHospitalcode, userrole1, isAuthorized]);

  const loadAllPatient = async () => {
    try{
    if (userHospitalcode !== undefined && userrole1 !== undefined ) {
    const res = await axios.get(`/GetPatient/${patientid}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        hospitalcode: userHospitalcode, // Include the hospitalcode in the query parameters
        userrole: userrole1, // Include the userrole in the query parameters
      },
    });
    const newProduct = await res.data;
    setPatientedit(newProduct);
    console.log(newProduct
      )
  }
}catch(error){
  if (error.response.status === 403) {
    alert('Unauthorised');
  } else if (error.response.status === 401) {
    alert('Error: ' + error.response.data.error);
  } else {
    alert('Error: ' + error.response.data.error);
  }
}
};
const currentDomain = window.location.hostname;
  return (
    <>
     <Helmet>
        <title>Patient Details | {currentDomain} | Hospital Management Software | Smart Solutions for Hospital Efficiency</title>
      </Helmet>
      <div  className="appointment p-2">
        <div className="editbutt">
          <button className="input-butt">
            <NavLink to={`/listviewpatients/${patientid}/edit`}>
              <GrEdit />
            </NavLink>
          </button>
          <br></br>
        </div>
        <form>
          {/* <h5> Patient Registration Form</h5> */}
          <div class="fill header"><h3 class="float-left"> Patient Details  </h3> </div>
        <div className="patient-new primary-info">
          <div className="row  ml-4">
            <div className="col-md-12 mt-3">
              <div className="row">
                <div className="col-md-2">
                  <label
                    htmlFor="patientname"
                    className="col-form-label "
                  >
                    Patient Name
                  </label>
                </div>
                <div className="col-md-4">
                  <input
                    type="text"
                    autoComplete="off"
                    name="patientname"
                    id="patientname"
                    placeholder="Patient Name"
                    value={patientedit.patientname}
                    disabled
                    className="form-control "
                  />
                </div>
                <div className="col-md-2">
                  <label
                    htmlFor="patientid"
                    className="col-form-label "
                  >
                    Patient Id
                  </label>
                </div>
                <div className="col-md-4">
                  <input
                    type="text"
                    autoComplete="off"
                    name="patientid"
                    id="patientid"
                    placeholder="Guardian Name"
                    value={patientedit.patientid}
                    disabled
                    className="form-control "
                  />
                </div>
              </div>
            </div>

            <div className="col-md-12 mt-3">
              <div className="row">
                <div className="col-md-2">
                  <label
                    htmlFor="bloodgroup"
                    className="col-form-label "
                  >
                    Blood Group
                  </label>
                </div>
                <div className="col-md-4">
                  <input
                    type="text"
                    autoComplete="off"
                    name="bloodgroup"
                    id="bloodgroup"
                    placeholder="Blood Group"
                    value={patientedit.bloodgroup}
                    disabled
                    className="form-control "
                  />
                </div>
                <div className="col-md-2">
                  <label
                    htmlFor="gaurdianname"
                    className="col-form-label "
                  >
                    Guardian Name
                  </label>
                </div>
                <div className="col-md-4">
                  <input
                    type="text"
                    autoComplete="off"
                    name="gaurdianname"
                    id="gaurdianname"
                    placeholder="Guardian Name"
                    value={patientedit.gaurdianname}
                    disabled
                    className="form-control "
                  />
                </div>
              </div>
            </div>

            <div className="col-md-12 mt-3">
              <div className="row">
                <div className="col-md-2">
                  <label
                    htmlFor="email"
                    className="col-form-label"
                  >
                    Email
                  </label>
                </div>
                <div className="col-md-4">
                  <input
                    type="email"
                    autoComplete="off"
                    name="email"
                    id="email"
                    placeholder="Email"
                    value={patientedit.email}
                    disabled
                    className="form-control "
                  ></input>
                </div>
                <div className="col-md-2">
                  <label
                    htmlFor="phoneno "
                    className="col-form-label "
                  >
                    Mobile Number
                  </label>
                </div>
                <div className="col-md-4">
                  <input
                    type="number"
                    autoComplete="off"
                    name="phoneno"
                    id="phoneno"
                    placeholder="Mobile Number"
                    value={patientedit.phoneno}
                    disabled
                    className="form-control "
                  />
                </div>
              </div>
            </div>
            <div className="col-md-12 mt-3">
              <div className="row">
                <div className="col-md-2">
                  <label
                    htmlFor="gender"
                    className="col-form-label"
                  >
                    Gender
                  </label>
                </div>
                <div className="col-md-4">
                  <input
                    type="text"
                    autoComplete="off"
                    name="gender"
                    id="gender"
                    placeholder="Gender"
                    value={patientedit.gender}
                    disabled
                    className="form-control "
                  />
                   
                </div>
                <div className="col-md-2">
                  <label
                    htmlFor="dob"
                    className="col-form-label"
                  >
                    Date of Birth
                  </label>
                </div>
                <div className="col-md-4">
                  <input
                    type="date"
                    autoComplete="off"
                    name="dob"
                    id="dob"
                    placeholder="DOB"
                    value={patientedit.dob}
                    disabled
                    className="form-control "
                  />
                </div>
              </div>
            </div>

            <div className="col-md-12 mt-3">
              <div className="row">
                <div className="col-md-2">
                  <label
                    htmlFor="age"
                    className="col-form-label"
                  >
                    Age
                  </label>
                </div>
                <div className="col-md-4">
                  <input
                    type="text"
                    autoComplete="off"
                    name="age"
                    id="age"
                    placeholder="Age"
                    value={patientedit.age}
                    disabled
                    className="form-control "
                  ></input>
                </div>
                <div className="col-md-2">
                  <label
                    htmlFor="address"
                    className="col-form-label "
                  >
                    Address
                  </label>
                </div>
                <div className="col-md-4">
                  <textarea
                    rows="1"
                    cols="30"
                    type="text"
                    autoComplete="off"
                    name="address"
                    id="address"
                    placeholder="Address"
                    value={patientedit.address}
                    disabled
                    className="form-control "
                  />
                </div>
              </div>
            </div>

            <div className="col-md-12 mt-3">
              <div className="row">
                <div className="col-md-2">
                  <label
                    htmlFor="city"
                    className="col-form-label"
                  >
                    City
                  </label>
                </div>
                <div className="col-md-4">
                  <input
                    type="text"
                    autoComplete="off"
                    name="city"
                    id="city"
                    placeholder="City"
                    value={patientedit.city}
                    disabled
                    className="form-control "
                  ></input>
                </div>
                <div className="col-md-2">
                  <label
                    htmlFor="state"
                    className="col-form-label"
                  >
                    State
                  </label>
                </div>
                <div className="col-md-4">
                  <input
                    type="text"
                    autoComplete="off"
                    name="state"
                    id="state"
                    placeholder="State"
                    value={patientedit.state}
                    disabled
                    className="form-control "
                  />
                </div>
              </div>
            </div>

              <div className="col-md-12 mt-3">
            <div className="row">
            {isAuthorized ? (   <div className="col-md-2">
                  <label
                    htmlFor="occupation"
                    className="col-form-label"
                  >
                    Hospitalcode
                  </label>
                </div> ) : null}
                {isAuthorized ? (  <div className="col-md-4">
                  <input
                    type="text"
                    autoComplete="off"
                    name="hospitalcode"
                    id="hospitalcode"
                    placeholder="Hospital Code"
                    value={patientedit.hospitalcode}
                   disabled
                   className="form-control "
                  />
                </div>) : null}
                <div className="col-md-2">
                  <label
                    htmlFor="occupation"
                    className="col-form-label"
                  >
                    Occupation
                  </label>
                </div>
                <div className="col-md-4">
                  <input
                    type="text"
                    autoComplete="off"
                    name="occupation"
                    id="occupation"
                    placeholder="Occupation"
                    value={patientedit.occupation}
                    disabled
                    className="form-control "
                  />
                </div>
            </div>
          </div>
          </div>
          
          </div>
          
        </form>
      </div>
    </>
  );
};
export default EditPatient;

