import React, { useState } from 'react';
import Sidebar from '../../Component/Sidebar';
import 'bootstrap/dist/css/bootstrap.min.css';

import { NavLink } from 'react-router-dom';


import Header from '../../Component/Header/Header';
import AllChargesComponent from './AllChargesPages/AllChargesList';
import AddCharge from './AllChargesPages/CreateCharges';

export const ChargesDetailsList = () => {
  
  return (
    <div className=" ">
      <Header />
    <div className="row" style={{ marginTop: '48px' }}>
      {/* Sidebar */}
      <div className="col-md-2">
        <Sidebar />
      </div>
      
      {/* Main Content */}
      <div className="col-md-10">
      <AllChargesComponent/>
      </div>
    </div>
  </div>
  
  );
};

export const AddNewCharge = () => {
  
    return (
      <div className=" ">
        <Header />
      <div className="row" style={{ marginTop: '48px' }}>
        {/* Sidebar */}
        <div className="col-md-2">
          <Sidebar />
        </div>
        
        {/* Main Content */}
        <div className="col-md-10">
        <AddCharge/>
        </div>
      </div>
    </div>
    
    );
  };

