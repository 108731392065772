import React, { useState, useEffect } from "react";
import { Table, Button, Modal, Form } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { IoTrashOutline } from "react-icons/io5";
import axios from "../../../helper/axios";
import { useUser } from "../../../Provider";
import { Helmet } from "react-helmet";
import { NavLink, useHistory } from "react-router-dom";
import MUIDataTable from "mui-datatables";
import { FaEdit } from "react-icons/fa";
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
const ListviewRadiologyUnit = () => {
  // Destructuring values from useUser hook
  const { user, userHospitalcode, userrole1, isAuthRole, isAuthorized, gettokenId, token } = useUser();
  console.log(user, userHospitalcode, userrole1, isAuthRole, isAuthorized, gettokenId);
  const history = useHistory();
  const [data, setData] = useState([]);
  const currentDate = new Date();
  const day = currentDate.getDate();
  const month = currentDate.getMonth() + 1; // Months are zero-indexed, so add 1
  const year = currentDate.getFullYear();
  // Format the date as DD-MM-YYYY
  const formattedDate = `${day}-${month}-${year}`;
  // const token = sessionStorage.getItem("jwtToken");
  const [userRole, setRole] = useState();
  const handleDelete = async (value, tableMeta,radiologyunitid) => {
    const radiologyunitname = tableMeta.rowData[0]
    // Display a confirmation dialog with patient's name
    const isConfirmed = window.confirm(`Do you want to delete ${radiologyunitname}?`);

    if (isConfirmed) {
      try {
        await axios.post(`/deleteradiologyunit/${radiologyunitid}`, null, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        loadAllCategory();
        // getprescriptiondetails();
      } catch (error) {
        // Handle any error here.
        alert("Error cancelling transaction:", error);
      }
    } else {
      // User clicked "Cancel" in the confirmation dialog, do nothing.
    }
  };
  // When the page loads or refreshes, this function will be called
  useEffect(() => {
    const loadData = async () => {
      // await loadAllDetails();
      // loadAllAppointment(); will wait for loadAllDetails(); to be executed
      await loadAllCategory();
    };

    loadData();
  }, [userHospitalcode, userrole1, isAuthorized]);

  // Load all categories based on user role and hospital code
  const loadAllCategory = async () => {
    try {
      if (userHospitalcode !== undefined && userrole1 !== undefined) {
        console.log("userHospitalcode:", userHospitalcode); // Add this line
        console.log("userrole1:", userrole1); // Add this line
        const res = await axios.get("/getradiologyunit", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: {
            hospitalcode: userHospitalcode, // Include the hospital code in the query parameters
            userrole: userrole1, // Include the user role in the query parameters
          },
        });
        setData(res.data);
        console.log(res.data);
      }
    } catch (error) {
      // Handle any error here.
      //alert("Error deleting hospital:", error);
    }
  };

  // Define columns for MUIDataTable based on user authorization
  let columns;
  if (isAuthorized) {
    columns = [
      {
        name: "radiologyunitname",
        label: "Radiologyunit",
        options: {
          filter: true,
          sort: true,
        },
      },
      {
        name: "hospitalcode",
        label: "Hospital Code",
        options: {
          filter: true,
          sort: true,
          customBodyRender: (value) => (value !== null && value !== undefined) ? value.toString().padStart(5, '0') : ""
        },
      },
      {
        name: "actions",
        label: "Actions",
        options: {
          customBodyRender: (value, tableMeta) => {
            const newD = data.find((e) => {
              return (
                e.radiologyunitname === tableMeta.rowData[0] &&
                e.hospitalcode === tableMeta.rowData[1]
              );
            });;
            console.log('newD:', newD.radiologyunitid);
            return (
            <div>
              {/* <IconButton color="primary" onClick={() => handleEdit(value, tableMeta)}>
                <EditIcon />
              </IconButton> */}
              <IconButton color="secondary" onClick={() => handleDelete(value, tableMeta,newD.radiologyunitid)}>
                <DeleteIcon />
              </IconButton>
            </div>
            )
            },
        },
      },
    ];
  } else {
    columns = [
      {
        name: "radiologyunitname",
        label: "Radiologyunit",
        options: {
          filter: true,
          sort: true,
        },
      },
      {
        name: "actions",
        label: "Actions",
        options: {
          customBodyRender: (value, tableMeta) => {
            const newD = data.find((e) => {
              return (
                e.radiologyunitname === tableMeta.rowData[0] 
              );
            });;
            console.log('newD:', newD.radiologyunitid);
            return (
            <div>
              {/* <IconButton color="primary" onClick={() => handleEdit(value, tableMeta)}>
                <EditIcon />
              </IconButton> */}
              <IconButton color="secondary" onClick={() => handleDelete(value, tableMeta,newD.radiologyunitid)}>
                <DeleteIcon />
              </IconButton>
            </div>
            )
            },
        },
      },
    ];
  }

  const options = {
    selectableRows: false,
    onRowClick: ((props) => {
      console.log(props);
    }),
    downloadOptions: {
      filename: `Radiology_Unit_list_${formattedDate}`, // Customize the filename here to download
      filterOptions: {
        useDisplayedColumnsOnly: true,
        useDisplayedRowsOnly: true,
      },
    },
  };

  const currentDomain = window.location.hostname;

  return (
    <>
      <Helmet>
        <title>Aarogyasaarthi | {currentDomain} radiologyunit List</title>
      </Helmet>
      <NavLink to='/addradiologyunit'>
        <button className="btn btn-success btn-sm mr-2" style={{ margin: "10px" }}>
          <svg xmlns="http://www.w3.org/2000/svg" width="19" height="18" style={{ paddingRight: '4px' }} viewBox="0 0 100 100">
            <line x1="10" y1="50" x2="90" y2="50" stroke="white" stroke-width="10" />
            <line x1="50" y1="10" x2="50" y2="90" stroke="white" stroke-width="10" />
          </svg>Add Radiologyunit
        </button>
      </NavLink>
      <MUIDataTable
        title={" Radiologyunit List"}
        data={data}
        columns={columns}
        options={options}
        className="custom-mui-datatable"
      />
    </>
  );
};

export default ListviewRadiologyUnit;