
import React, { useEffect, useState } from 'react';
// import { Grid, Divider, Typography } from '@mui/material';
import {  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper,TextareaAutosize, IconButton } from '@mui/material';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { useUser } from '../../../Provider';
import MUIDataTable from "mui-datatables";
import axios from "../../../helper/axios";
//import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import PrintIcon from '@mui/icons-material/Print';
import { Modal } from 'react-bootstrap';
import UpdateIpdOperationPopUp from './UpdateIpdOperationPopUp';
import PrintMedication from '../../../Component/PrintMedication/PrintMedication';
import UpdateIpdMedication from './UpdateIpdMedication';
import MedicationeForm from './MedicationForm';

  // OperationTable component
  const MedicationTable = ({hideForm}) => {
    const { admitnumber } = useParams();
    const { userHospitalcode, userrole1,token } = useUser();
    const [medicationData, setMedicationData] = useState([]);
    const [medi, setMedi] = useState();
    const [isPopup, setIsPopup] = useState(false);
    const [isEditFormVisible, setEditFormVisibility] = useState(false);
    const [editMedicationData, setEditMedicationData] = useState(null);

    const getMedication = async () => {
      try {
        if (userHospitalcode !== undefined && userrole1 !== undefined) {
          const res = await axios.get(`/getipdmedication/${admitnumber}`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
            params: {
              userHospitalcode,
              userrole: userrole1,
            },
          });
  
          if (res.status === 200) {
            setMedicationData(res.data);
           // console.log(res.data)
          } else {
            alert(`Request failed with status: ${res.status}`);
          }
        }
      } catch (error) {
        console.log(`An error occurred: ${error.message}`);
      }
    };
  
    useEffect(() => {
      getMedication();
    }, [userHospitalcode, userrole1]);
    const handlemedication = () => {
      // Function to fetch the updated NurseNote data
      getMedication();
    };
    const columns = [
      { name: "medicationname", label: "Reference No" },
      { name: "medicinecategory", label: "Medicine Category" },
      { name: "medicinename", label: "Medicine Name" },
      { name: "date", label: "Medication Date",},
      { name: "time", label: "Medication Time",},
      { name: "dosage", label: "Dose" },
      { name: "remarks", label: "Remark" },
      {
        name: "actions",
        label: "Actions",
        options: {
          customBodyRender: (value, tableMeta) => (
            
            <div>
              <IconButton color="primary" onClick={() => handlePrint(value, tableMeta)}>
                <PrintIcon />
              </IconButton>
              <IconButton color="primary" onClick={() => handleEdit(value,tableMeta)}>
                <EditIcon />
              </IconButton>
              <IconButton color="secondary" onClick={() => handleDelete(value,tableMeta)}>
                <DeleteIcon />
              </IconButton>
            </div>
          ),
        },
      },
    ];
  
    const options = {
      filter: true,
      selectableRows: "none",
      viewColumns: false,
      print: false,
      download: false,
    };
     //handleClose: This function is used to set the isPopup state to false, presumably to close a popup or modal.
  const handleClose = () => {
    setIsPopup(false)
  }  
  const handlePrint = async (value, tableMeta, updateValue) => {
    // console.log(medicationData);
    // console.log(tableMeta.rowData[0])
    if (tableMeta.rowData && tableMeta.rowData[0]) {
        const newD = medicationData.find((e) =>
         e.medicationname === tableMeta.rowData[0]);

        if (newD) {
            console.log(newD);
            await setMedi(tableMeta.rowData[0]);
            await setIsPopup(true);
        } else {
            console.error('Data not found for the specified operation number.');
        }
    } else {
        console.error('Invalid tableMeta.rowData.');
    }
};

const handleEdit = (value, tableMeta, updateValue) => {
  console.log(medicationData)
  const medicationname=tableMeta.rowData[0]
  console.log(medicationname)
  setEditMedicationData(medicationname);
  setEditFormVisibility(true);
};

    const handleDelete = async (value, tableMeta, updateValue) => {
      console.log(medicationData);
      const medicationname=tableMeta.rowData[0]
    console.log(medicationname)
      // Display a confirmation dialog with patient's name
      const isConfirmed = window.confirm(`Do you want to delete ${medicationname}?`);
    
      if (isConfirmed) {
        try {
          await axios.post(`/cancelmedicationname/${admitnumber}/${medicationname}`, null, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
    
          getMedication();
        } catch (error) {
          // Handle any error here.
         
        }
      } else {
        // User clicked "Cancel" in the confirmation dialog, do nothing.
      }
    };
    
    return (
      <>
     {!hideForm && <MedicationeForm onMedication={handlemedication}/>}
      <div style={{marginLeft: "auto"}}>
      {/* <Helmet>
        <title>Billing Records | {currentDomain} | Hospital Management Software | Smart Solutions for Hospital Efficiency</title>
      </Helmet> */}
      </div>
      <div>
        <MUIDataTable
          title="Medication Details"
          data={medicationData}
          columns={columns}
          options={options}
        />
      </div>
        {isPopup==true?
          <>

          <Modal show={true}  animation={false} onHide={handleClose} size="lg">
            <Modal.Header closeButton>
             
            </Modal.Header>
            <Modal.Body>
            {medi==undefined?""
              :
              <PrintMedication medi={medi}/>}
            </Modal.Body>
          </Modal>
        </> 
        :
        ""}
        {/* ... your existing code ... */}
    {isEditFormVisible && editMedicationData && (
      <UpdateIpdMedication
      medicationData={editMedicationData}
      onClose={() => setEditFormVisibility(false)}
       />
    )}
    </>
    );
  };  
  export default MedicationTable;
 