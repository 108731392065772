import React from "react";
import { useFormik } from "formik";
import { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useHistory, useParams } from "react-router-dom";
import axios from "../../../helper/axios";
import { Table, Button, Modal, Form } from "react-bootstrap";
import { patientregistration } from "../../../helper/schemas";
import { ToastContainer, toast } from 'react-toastify';
import * as Yup from "yup";  // Import Yup for validation
import { useUser } from "../../../Provider";
import PrintRadiologyReport from '../../../Component/RadiologyReportTemplat/PrintRadiologyReport';

const initialValues = {
  hospitalcode:"",
  patientid:"",
  patientname: '',
  age: '',
  gender: '',
  medicalhistory: '',
  radiologycategoryid: '',
    bodyPart: '',
    reason: '',
    impression:'',
    recommendations:'',
    signature:'',
    date:'',
    summary:'',
    radiologycategoryname:"",
 };


const EditRadiology = () => {

  const { billno, radiologyreportid} = useParams();

  console.log("?????????????????", billno, radiologyreportid)
  const history = useHistory();
  const [success, setSuccess] = useState(false);
  // Use the 'useUser' hook to get user-related information
  const { user, userHospitalcode, userrole1, isAuthRole, isAuthorized, gettokenId, token } = useUser();
  console.log(user, userHospitalcode, userrole1, isAuthRole, isAuthorized, gettokenId);
  const[searchValueName,setSearchValueName]=useState("")
  // const [category1, setCategory] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [isPopup, setIsPopup] = useState(false);
  const [billnumber, setBid] = useState();
  const [error, setError] = useState(false);
  console.log("billnumber",billnumber)

  const [findings, setFindings] = useState([{ description: '', location: '' }]);
  const handleAddFinding = () => {
    setFindings([...findings, { description: '', location: '' }]);
  };
  const handleSearch = (searchTerm) => {
    console.log(searchTerm);
    setSearchValueName(searchTerm); // Update the searchValue state with the search term
  };
  const {
    values,
    errors,
    touched,
    setValues,
    handleChange,
    handleBlur,
    resetForm,
    handleSubmit,
  } = useFormik({
    initialValues,
    // validationSchema,
    // validationSchema: getValidationSchema, // Apply validation schema
    onSubmit: async (values, { resetForm }) => {

      try {
        if (userHospitalcode !== undefined) {
          const data = new FormData();
          // Append form data to the FormData object
          data.append("documentvalue", JSON.stringify(values));
        data.append("findings", JSON.stringify(findings));
        data.append("document", JSON.stringify(searchValueName));
        data.append("currentDate", JSON.stringify(currentDate));
        data.append("gettokenId", JSON.stringify(gettokenId));
        data.append("userHospitalcode", JSON.stringify(userHospitalcode));
        console.log(JSON.stringify(searchValueName))
        console.log(JSON.stringify(values))
        console.log(JSON.stringify(findings))

          console.log(JSON.stringify(searchValueName))

          console.log(JSON.stringify(test))

          // Make a POST request to save Prescription data
          const response = await axios.post(`/updateradiologyreport/${billno}/${radiologyreportid}`, data, {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "multipart/form-data",
            },
            params: {
              hospitalcode: userHospitalcode, // Include the hospitalcode in the query parameters
              userrole: userrole1, // Include the userrole in the query parameters
            },
          },);

          if (response.status === 200) {
            return history.push("/pathologyreport");
          } else {
            console.error('Error in admitting patient');
            setSuccess(false);
            setError(true);
          }
        }
      } catch (error) {
        console.error('Error:', error.message);
        setSuccess(false);
        setError(true);
        toast.error('Something Went Wrong');
      }
    }
  });


  const getCurrentDateTime = () => {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0');
    const day = String(now.getDate()).padStart(2, '0');
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    const currentDateTimeString = `${year}-${month}-${day}T${hours}:${minutes}`;
    return currentDateTimeString;


  };

  let Showdate = new Date();
  let ShowTodaydate =
    Showdate.getFullYear() +
    "-" +
    (Showdate.getMonth() + 1) +
    "-" +
    Showdate.getDate();
  const [currentDate, setCurrentDate] = useState(ShowTodaydate);
  const [test, setTest] = useState([{ testcategory: '', testparameter: '', result: '', unit: '', referencerange: '', method: '',chargecode:"" }]);
  const [data, setData] = useState([]);


  

  //  get all hospital 
  const loadHospitalcode = async () => {
    //when userHospitalcode and userrole1 is not undefined then execute /gethospitalcode
    if (userHospitalcode !== undefined && userrole1 !== undefined) {
      const res = await axios.get("/gethospitalcode", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          hospitalcode: userHospitalcode, // Include the hospitalcode in the query parameters
          userrole: userrole1, // Include the userrole in the query parameters
        },
      });
      setData(res.data);
      console.log(res.data);
    };
  }

  // Function to handle changes in test-related fields
  const handleChanges = (e, index, field) => {
    const { value } = e.target;
    setTest((prevTests) => {
      return prevTests.map((test, i) => {
        if (i === index) {
          return { ...test, [field]: value };
        }
        return test;
      });
    });
  }


  const handleClosed = () => {
    setIsPopup(false)
    //window.location.reload();
  }

  const printForm = (values) => {

    setBid(searchValueName.billnumber)
    setIsPopup(true)
    // window.open(`/AppoinmentForm/${value.patientid}`, "_blank")
  }


  //when page load or refresh happen this function will got called
  useEffect(() => {
    const loadData = async () => {

      await getRadiologyReport();
      await getRadiologyReportFinding();
      
     

    };
    loadData();
  }, [userHospitalcode, userrole1, isAuthorized]);

  // useEffect(() => {
  //   if (searchValueName) {
  //     // fetchPatientDetails();
  //     setValues({
  //       ...values,
  //       hospitalcode:searchValueName.hospitalcode,
  //       patientid:searchValueName.patientid,
  //       name: searchValueName.patientname,
  //       age: searchValueName.age,
  //       gender: searchValueName.gender,
  //       // Update other fields as needed
  //     });
  //   }
  //   const loadData = async () => {
  //     const selectedHospitalCode = values.hospitalcode;
  //     await fetchMedicineCateoryNames(selectedHospitalCode);
  //   }
  //   loadData();
  // }, [ searchValueName, token,userHospitalcode, userrole1, isAuthorized, values.hospitalcode]);


    const getRadiologyReport = async () => {
        try {
            const res = await axios.get(`/getradiologyreport/${billno}/${radiologyreportid}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                params: {
                  hospitalcode: userHospitalcode,
                  userrole: userrole1,
                },
            });
            console.log(res.data)
            if (res.status === 200) {
                setValues(res.data[0]);
               // setCategory(res.data)
            } else {
                alert(`Unexpected status code: ${res.status}`);
            }
        } catch (error) {
           // alert(`Data not found`);
        }
    };
    const getRadiologyReportFinding = async () => {
      try {
          const res = await axios.get(`/getradiologyreportfindings/${billno}/${radiologyreportid}`, {
              headers: {
                  Authorization: `Bearer ${token}`,
              },
              params: {
                hospitalcode: userHospitalcode,
                userrole: userrole1,
              },
          });
          console.log(res.data)
          if (res.status === 200) {
            setFindings(res.data);
          } else {
              alert(`Unexpected status code: ${res.status}`);
          }
      } catch (error) {
          alert(`Data not found`);
      }
  };


  const currentDomain = window.location.hostname;
  return (

    <form onSubmit={handleSubmit}>
      <div className="patient-new primary-info">
      <div className="row  ml-4">
            <div className="col-md-12 ">

            <div className="row">
                <div className="col-md-2">
                  <label className="col-form-label ">Billing No</label>
                </div>
<div className="col-md-4">
                  <input
                    type="text"
                    autoComplete="off"
                    name="billnumber"
                    id="billnumber"
                    placeholder="Billing No"
                    value={values.billnumber}
                    onChange={handleChange}
                    disabled
                    className="form-control"
                  />
                  {errors.billnumber && touched.billnumber ? (
                    <p className="form-erroremployee">{errors.billnumber}</p>
                  ) : null}
                </div>

                {isAuthorized ? (<div className="col-md-2">
                  <label
                    htmlFor="hospitalcode"
                    className="col-form-label"
                  >
                    Hospital Code
                  </label>
                </div>) : (
                  null
                )}
                <div className="col-md-4">

                  {isAuthorized ? (
                    <input
                      type="text"
                      autoComplete="off"
                      name="hospitalcode"
                      id="hospitalcode"
                      placeholder="Hospital Code"
                      value={values.hospitalcode}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      disabled
                      className="form-control"
                    />

                  ) : (
                    <input
                      type="hidden"
                      name="hospitalcode"
                      id="hospitalcode"
                      value={values.hospitalcode}
                      disabled={true} // Set the input as disabled
                      className="form-control "
                    />
                  )}
                  {errors.hospitalcode && touched.hospitalcode ? (
                    <p className="form-erroremployee">{errors.hospitalcode}</p>
                  ) : null}
                </div>
              </div>
              </div>
              </div>


        <div className="row">
          <div className="col-md-12">

            <h5>Patient Information</h5>
            <div className="row">
              <div className="col-md-6">
                <div className="row">
                  <div className="col-md-4">
                    <label htmlFor="name" className="col-form-label leftone">Name</label>
                  </div>
                  <div className="col-md-8">
                    <input
                      type="text"
                      autoComplete="off"
                      name="patientname"
                      id="patientname"
                      placeholder="Name"
                      value={values.patientname}
                      onChange={handleChange}
                      disabled
                      className="form-control"
                    />
                    {errors.patientname && touched.patientname ? (
                      <p className="form-erroremployee">{errors.patientname}</p>
                    ) : null}
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-4">
                    <label htmlFor="age" className="col-form-label leftone">Age</label>
                  </div>
                  <div className="col-md-8">
                    <input
                      type="number"
                      autoComplete="off"
                      name="age"
                      id="age"
                      placeholder="Age"
                      value={values.age}
                      onChange={handleChange}
                      disabled
                      className="form-control"
                    />
                    {errors.age && touched.age ? (
                      <p className="form-erroremployee">{errors.age}</p>
                    ) : null}
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="row">
                  <div className="col-md-4">
                    <label htmlFor="gender" className="col-form-label leftone">Sex</label>
                  </div>
                  <div className="col-md-8">
                    <input
                      type="text"
                      autoComplete="off"
                      name="gender"
                      id="gender"
                      placeholder="gender"
                      value={values.gender}
                      onChange={handleChange}
                      disabled
                      className="form-control"
                    />
                    {errors.gender && touched.gender ? (
                      <p className="form-erroremployee">{errors.gender}</p>
                    ) : null}
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-4">
                    <label htmlFor="medicalhistory" className="col-form-label leftone">Medical History</label>
                  </div>
                  <div className="col-md-8">
                    <input
                      type="text"
                      autoComplete="off"
                      name="medicalhistory"
                      id="medicalhistory"
                      placeholder="Medical History"
                      value={values.medicalhistory}
                      onChange={handleChange}
                      className="form-control"
                    />
                    {errors.medicalhistory && touched.medicalhistory ? (
                      <p className="form-erroremployee">{errors.medicalhistory}</p>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <h5>Imaging Study Information</h5>
            <div className="row">
              <div className="col-md-6">
                <div className="row">
                  <div className="col-md-4">
                    <label htmlFor="radiologycategoryid" className="col-form-label leftone">Type of Imaging Study</label>
                  </div>
                  <div className="col-md-8">
                    <input
                      type="text"
                      autoComplete="off"
                      name="radiologycategoryid"
                      id="radiologycategoryid"
                      placeholder="Type of Imaging Study"
                      value={values.radiologycategoryname}
                      onChange={handleChange}
                      style={{ width: '73%', height: '32px', border: '1px solid #ced4da ', color: '#495057' }}
                    />
                      {/* <option>Select</option>
                      {category1.map((category1) => (
                        <option
                          value={category1.radiologycategoryid}
                          key={category1.id}
                        >
                          {category1.radiologycategoryname}
                        </option>
                      ))}
                    </select> */}

                    {errors.radiologycategoryid && touched.radiologycategoryid ? (
                      <p className="form-erroremployee">{errors.radiologycategoryid}</p>
                    ) : null}
                  </div>
                </div>
                <div className="row md-6">
                  <div className="row">
                    <div className="col-md-4">
                      <label htmlFor="reason" className="col-form-label leftone">Reason for Study</label>
                    </div>
                    <div className="col-md-8">
                      <input
                        type="text"
                        autoComplete="off"
                        name="reason"
                        id="reason"
                        placeholder="Reason for Study"
                        value={values.reason}
                        onChange={handleChange}
                        className="form-control"
                      />
                      {errors.reason && touched.reason ? (
                        <p className="form-erroremployee">{errors.reason}</p>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="row">
                  <div className="col-md-4">
                    <label htmlFor="bodyPart" className="col-form-label leftone">Body Part/Area Imaged</label>
                  </div>
                  <div className="col-md-8">
                    <input
                      type="text"
                      autoComplete="off"
                      name="bodyPart"
                      id="bodyPart"
                      placeholder="Body Part/Area Imaged"
                      value={values.bodyPart}
                      onChange={handleChange}
                      className="form-control"
                    />
                    {errors.bodyPart && touched.bodyPart ? (
                      <p className="form-erroremployee">{errors.bodyPart}</p>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>


        <div className="row">
          <div className="col-md-12 mt-3">
            <h5>Summary of Findings</h5>
            <textarea
              rows="3"
              cols="30"
              autoComplete="off"
              name="summary"
              id="summary"
              placeholder="Summary of Findings"
              value={values.summary}
              onChange={handleChange}
              className="form-control"
              style={{ width: '95%' }}
            ></textarea>
            {errors.summary && touched.summary ? (
              <p className="form-erroremployee">{errors.summary}</p>
            ) : null}
          </div>
        </div>


        <div className="row">
          <div className="col-md-12 mt-3">
            <h5>Detailed Description of Findings</h5>
            <div className="row">
              <div className="col-md-12">
                {findings.map((finding, index) => (
                  <div key={index} className="row">
                    <div className="col-md-6">
                      <label htmlFor={`findingDescription${index}`} className="col-form-label leftone">Finding {index + 1} Description</label>
                      <input
                        type="text"
                        autoComplete="off"
                        name={`findingDescription${index}`}
                        id={`findingDescription${index}`}
                        placeholder={`Finding ${index + 1} Description`}
                        value={finding.description}
                        onChange={(e) => {
                          const newFindings = [...findings];
                          newFindings[index].description = e.target.value;
                          setFindings(newFindings);
                        }}
                        className="form-control"
                      />
                    </div>
                    <div className="col-md-6">
                      <label htmlFor={`findingLocation${index}`} className="col-form-label leftone">Finding {index + 1} Location</label>
                      <input
                        type="text"
                        autoComplete="off"
                        name={`findingLocation${index}`}
                        id={`findingLocation${index}`}
                        placeholder={`Finding ${index + 1} Location`}
                        value={finding.location}
                        onChange={(e) => {
                          const newFindings = [...findings];
                          newFindings[index].location = e.target.value;
                          setFindings(newFindings);
                        }}
                        className="form-control"
                      />
                    </div>
                  </div>
                ))}
                <button className="btn btn-outline-primary mt-3" onClick={handleAddFinding} type="button">Add Finding</button>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12 mt-3">
            <h5>Impression</h5>
            <textarea
              rows="3"
              cols="30"
              autoComplete="off"
              name="impression"
              id="impression"
              placeholder="Impression"
              value={values.impression}
              onChange={handleChange}
              className="form-control"
              style={{ width: '95%' }}
            ></textarea>
            {errors.impression && touched.impression ? (
              <p className="form-erroremployee">{errors.impression}</p>
            ) : null}
          </div>
        </div>

        <div className="row">
          <div className="col-md-12 mt-3">
            <h5>Recommendations</h5>
            <textarea
              rows="3"
              cols="30"
              autoComplete="off"
              name="recommendations"
              id="recommendations"
              placeholder="Recommendations"
              value={values.recommendations}
              onChange={handleChange}
              className="form-control"
              style={{ width: '95%' }}
            ></textarea>
            {errors.recommendations && touched.recommendations ? (
              <p className="form-erroremployee">{errors.recommendations}</p>
            ) : null}
          </div>
        </div>
        <br />

        <div className="row">
          <div className="col-md-12">
            <div className="row">
              <div className="col-md-6">
                <div className="row">
                  <div className="col-md-4">
                    <label htmlFor="signature" className="col-form-label leftone">Signature</label>
                  </div>
                  <div className="col-md-8">
                    <input
                      type="text"
                      autoComplete="off"
                      name="signature"
                      id="signature"
                      placeholder="Signature"
                      value={values.signature}
                      onChange={handleChange}
                      className="form-control"
                    /> {errors.signature && touched.signature ? (
                      <p className="form-erroremployee">{errors.signature}</p>
                    ) : null}
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="row">
                  <div className="col-md-4">
                    <label htmlFor="signature" className="col-form-label leftone">Date</label>
                  </div>
                  <div className="col-md-8">
                    <input
                      type="date"
                      autoComplete="off"
                      name="date"
                      id="date"
                      placeholder="Date"
                      value={values.date}
                      onChange={handleChange}
                      className="form-control"
                    /> {errors.date && touched.date ? (
                      <p className="form-erroremployee">{errors.date}</p>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="row">
        <div className="col-md-12 mt-3">
          <button className="btn btn-primary" type="submit">Submit</button>
        </div>
        <div className="col-md-12 mt-3">
        <button className="btn btn-primary" type="submit" onClick={() => printForm(values, "printandsave")}>Save Print</button>
        </div>
      </div> */}
      <div className="row">
    <div className="col-md-1 ">
        <button className="btn btn-primary btn-block" type="submit">Submit</button>
    </div>
    <div className="col-md-2 ">
        <button className="btn btn-primary btn-block" type="submit" onClick={() => printForm(values, "printandsave")}>Save Print</button>
    </div>
</div>
<br/>
      {isPopup == true ?
            <>

              <Modal show={true} animation={false} onHide={handleClosed} size="lg">
                <Modal.Header closeButton>
                  <Modal.Title>{billnumber}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  {radiologyreportid == undefined ? ""
                    :
                    // <PrintTemplateAppointment pId={billnumber} handleSubmit={handleClosed} apppoinentDate={appointmentDate}/>}
                    <PrintRadiologyReport billnumber={billnumber}  radiologyreportid={radiologyreportid} handleSubmit={handleClosed} />}
                </Modal.Body>
              </Modal>
            </>
            :
            ""}
          <ToastContainer />
    </form>

  );
};

export default EditRadiology;

