import { useState, useEffect } from "react";
import axios from '../helper/axios';
import Autocomplete from "@mui/material/Autocomplete";
import {  toast } from 'react-toastify';
import {  useHistory } from 'react-router-dom';
import {
  TextField,
  InputAdornment,
  IconButton,
} from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useUser } from "../Provider";
const PatientByHospital = ({ onSearchChange }) => {
  const history = useHistory();
  const {user, userHospitalcode, userrole1, isAuthRole,isAuthorized,gettokenId,token  } = useUser();
 // const token =localStorage.getItem("jwtToken");
  const [value, setValue] = useState("");
  const [data, setData] = useState([]);
  const [userRole, setRole] = useState();
  const [openAutocomplete, setOpenAutocomplete] = useState(false);
//when page load or refresh happen this function will got called
useEffect(() => {
  const loadData = async () => {
   // await loadAllDetails();
    //loadAllAppointment(); will wait for loadAllDetails(); to got execute
    await loadAllPatients();
  };

  loadData();
}, [userrole1,userHospitalcode]);


  const loadAllPatients = async () => {
    try {
    
      if (userHospitalcode !== undefined && userrole1 !== undefined  ) {
      const res = await axios.get(`/GetPatients`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          hospitalcode: userHospitalcode, // Include the hospitalcode in the query parameters
          userrole: userrole1, // Include the userrole in the query parameters
        },
      });
      
      setData(res.data);
      console.log(res.data);
    } }catch (error) {
      // Handle client-side errors here
      if (error.request) {
        // The request was made, but no response was received
        toast.error("No response received from the server");
      } else {
        // Something else went wrong
        toast.error("Error:", error.message);
      }
    }
  };
  
  useEffect(() => {
    // if (!sessionStorage.getItem("jwtToken")) {
    //   return history.push("/");
    // }
    loadAllPatients();
  }, []);

  const onSearch = (searchTerm, item) => {
    console.log(item +"------------")
    console.log(value)
    // setValue("")
    onSearchChange(item);
    // your API call or other logic can go here
    console.log("search ", searchTerm);
  };
  const handleExpandClick = () => {
    setOpenAutocomplete(!openAutocomplete);
};
  return (
    <>
      <div className="searchbar" >
   
          <Autocomplete
             freeSolo
             id="free-solo"
            options={data}
            getOptionLabel={(option) => `${option.patientname}`}
            open={openAutocomplete}
            onOpen={() => setOpenAutocomplete(true)}
            onClose={() => setOpenAutocomplete(false)}
            onChange={onSearch}
            // value=""
            renderInput={(params) => 
            <TextField
             {...params} 
             placeholder="Select Patient"
             InputProps={{
                 ...params.InputProps,
                 endAdornment: (
                     <InputAdornment position="end">
                         <IconButton onClick={handleExpandClick}>
                             <ExpandMoreIcon />
                         </IconButton>
                     </InputAdornment>
                 ),
             }}
           //  onChange={(event) => setValue(event.target.value)} 
              />
            }
            renderOption={(props, option) => (
              <li {...props}>
                {option.patientname}-{option.phoneno}
              </li>
            )}
          />
        </div>
 
    </>
  );
}
export default PatientByHospital;