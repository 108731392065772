import React from "react";
import { useFormik } from "formik";
import { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useHistory } from "react-router-dom";
import axios from "../../../helper/axios";
import { Table, Button, Modal, Form } from "react-bootstrap";
import { patientregistration } from "../../../helper/schemas";
import { ToastContainer, toast } from 'react-toastify';
import * as Yup from "yup";  // Import Yup for validation
import { useUser } from "../../../Provider";
import {AddMedicineValidation, AddMedicineValidation1} from "../../../helper/schemas/index"
import HospitalCodeSearch from "../../../Component/HospitalCodeSearch";
import SearchMedicineCategory from "../../../Component/SearchMedicineCategory";
import SearchSupplier from "../../../Component/SearchSupplier";
import SearchMedicineUnit from "../../../Component/SearchMedicineUnit";

const initialValues = {
    medicinename: "",
    hospitalcode: "",
    medicinecategoryid: "",
    medicinemanufacturerid: "",
    medicineunitid: "",
    // sellingprice: "",
    // buyingprice: "", 
  
};

const AddMedicine = () => {
  const { user, userHospitalcode, userrole1, isAuthRole, isAuthorized, gettokenId,token } = useUser();
  const history = useHistory();
  const [message, setMessage] = useState("");
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [category1, setCategory] = useState([]);
  const [manufacturer1, setManufacturer] = useState([]);
  const [unit1, setUnit] = useState([]);
  // initialised isAuthorized value as false
  // const [isAuthorized, setIsAuthorized] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  //get jwttoken from sessionStorage
  // const token = sessionStorage.getItem("jwtToken");
  //get current date
  let Showdate = new Date();
  let ShowTodaydate =
    Showdate.getFullYear() +
    "-" +
    (Showdate.getMonth() + 1) +
    "-" +
    Showdate.getDate();
  const [currentDate, setCurrentDate] = useState(ShowTodaydate);

  const getValidationSchema = () => {
    if (userHospitalcode === 1) {
      return AddMedicineValidation;

    } else  {
      return AddMedicineValidation1;
    }
  }

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    resetForm,
    setValues
  } = useFormik({
    initialValues,
    validationSchema: getValidationSchema, // Apply validation schema
    onSubmit: async (values, action) => {
      try{
      const data = new FormData();
      data.append("document", JSON.stringify(values));
      data.append("currentDate", JSON.stringify(currentDate));
      data.append("gettokenId", JSON.stringify(gettokenId));
      data.append("userHospitalcode", JSON.stringify(userHospitalcode));
      console.log(JSON.stringify(values));
    //   console.log(JSON.stringify(hospitalcode));

      const response = await axios.post("/addmedicine", data, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      });
      console.log(
        "🚀 ~ file: Registration.jsx ~ line 11 ~ Registration ~ values",
        values
      );
      if (response.status === 200) {
        return history.push("/listmedicine");
      }
    } catch (error) {
      console.log(error)
      toast("Something Went Wrong")
    }
    },
  });
  const handleReset = () => {
    // Reset the form using Formik's resetForm function
    resetForm();
  };
  const [data, setData] = useState([])
  const [searchValues, setSearchValues] = useState("");
  //Hospital code search
  const onSearchItems = (searchTerm) => {
    console.log("------", searchTerm)
    setSearchValues(searchTerm);
    if (searchTerm && searchTerm.hospitalcode) {
      setValues({
        ...values,
        hospitalcode: searchTerm.hospitalcode,
        // Update other fields as needed
      });
    }
  };
    //on Category change
    const handleSearchChanges = (searchTerms) => {
      console.log("---------------",searchTerms)
      setValues({
        ...values,
        medicinecategoryid:searchTerms.medicinecategoryid,
     })
    };
    //
    const handleSupplierChanges = (searchTerms) => {
      console.log("---------------",searchTerms)
      setValues({
        ...values,
        medicinemanufacturerid:searchTerms.medicinemanufacturerid,
     })
    };
    //
   const handleUnitChanges = (searchTerms) => {
    console.log("---------------",searchTerms)
    setValues({
      ...values,
      medicineunitid:searchTerms.medicineunitid,
   })
  };
  //when page load or refresh happen this function will got called
  useEffect(() => {
    const loadData = async () => {
    };
    loadData();
  }, [userHospitalcode, userrole1, isAuthorized, values.hospitalcode]);

  const currentDomain = window.location.hostname;

  return (
    <div className="p-3 pt-0 appointment">
       <Helmet>
        <title>Aarogyasaarthi | {currentDomain} | Add Medicine</title>
      </Helmet>
      <form onSubmit={handleSubmit}>
        {success && <div>Add Medicine</div>}
        {error && <div>Error in add bed type please try again</div>}
        <div class="fill header"><h3 class="float-left"> Add Medicine</h3> </div>
        <div className="patient-new primary-info">
        <div className="row">
        <div className="col-md-12">
          <div className="row">
        <div className="col-md-6">
            
       <div className="col-md-12 mt-3">
              <div className="row">
  
                <div className="col-md-4">
                  <label
                    htmlFor="medicinename"
                    className="col-form-label leftone"
                  >
                   Medicine Name
                  </label>
                </div>
                <div className="col-md-8">
                    <input
                      type="text"
                      autoComplete="off"
                      name="medicinename"
                      id="medicinename"
                      placeholder="Medicine Name"
                      value={values.medicinename}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      style={{ width: '73%', height: '32px', border: '1px solid #ced4da', color: '#495057' }}
                    />                
                  {errors.medicinename && touched.medicinename ? (
                    <p className="form-erroremployee">{errors.medicinename}</p>
                  ) : null}
                </div></div></div>
                {isAuthorized ? (<div className="col-md-12 mt-3">
              <div className="row">
  
                <div className="col-md-4">
                  <label
                    htmlFor="hospitalcode"
                    className="col-form-label leftone"
                  >
                    Hospital Code
                  </label>
                </div>
                <div className="col-md-8">
                  {/* Conditionally render the dropdown and input field */}
                  {isAuthorized ? (
                      
                 <HospitalCodeSearch onSearchItem={onSearchItems} />
                  ) : (
                    <input
                      type="text"
                      name="hospitalcode"
                      id="hospitalcode"
                      value={userHospitalcode}
                      disabled={true} // Set the input as disabled
                      className="form-control "
                    />
                  )}
                  {isAuthorized && errors.hospitalcode && touched.hospitalcode ? (
                    <p className="form-erroremployee">{errors.hospitalcode}</p>
                  ) : null}
                </div></div></div>) : null}
                {/* <div className="col-md-12 mt-3">
              <div className="row">
                <div className="col-md-4">
                  <label
                    htmlFor="medicineunitid"
                    className="col-form-label leftone"
                  >
                    Unit
                  </label>
                </div>
                <div className="col-md-8">
                  < SearchMedicineUnit onUnitChange={handleUnitChanges}
                          hospitalvalue={values.hospitalcode || userHospitalcode}/>
                  {errors.medicineunitid && touched.medicineunitid ? (
                    <p className="form-erroremployee">{errors.medicineunitid}</p>
                  ) : null}
                </div>
              </div>
            </div> */}

            
            <div className="col-md-12 mt-3">
              <div className="row">
                </div>
                </div>
               
          </div>
          <div className="col-md-6">
        
                <div className="col-md-12 mt-3">
              <div className="row">
                <div className="col-md-4">
                  <label
                    htmlFor="medicinecategoryid"
                    className="col-form-label leftone"
                  >
                    Category
                  </label>
                </div>
                <div className="col-md-8">
                  <SearchMedicineCategory  onCategoryChange={handleSearchChanges}
                          hospitalvalue={values.hospitalcode || userHospitalcode} />
                  {errors.medicinecategoryid && touched.medicinecategoryid ? (
                    <p className="form-erroremployee">{errors.medicinecategoryid}</p>
                  ) : null}
                </div>
              </div>
            </div>
                
            <div className="col-md-12 mt-3">
              <div className="row">
                <div className="col-md-4">
                  <label
                    htmlFor="medicinemanufacturerid"
                    className="col-form-label leftone"
                  >
                    Supplier
                  </label>
                </div>
                <div className="col-md-8">
                <SearchSupplier  onSupplierChange={handleSupplierChanges}
                          hospitalvalue={values.hospitalcode || userHospitalcode} />
                 
                  {errors.medicinemanufacturerid && touched.medicinemanufacturerid ? (
                    <p className="form-erroremployee">{errors.medicinemanufacturerid}</p>
                  ) : null}
                </div>
              </div>
            </div>
            <div className="col-md-12 mt-3">
              <div className="row">
                </div></div>
            </div>
          </div>
          </div>
        </div>
        </div>
        <div className="col-md-6  mt-3">
          <Button
            type="submit"
            variant="success"
            className="mx-3"
          >
            Save
          </Button>
          <Button
            variant="primary"
            className="mx-3"
            onClick={handleReset}
          >
            Reset
          </Button>
        </div>
      </form>
    </div>
  );
};

export default AddMedicine;
