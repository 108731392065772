import React, { useRef, useEffect, useState } from "react";
import { useReactToPrint } from "react-to-print";
import image from "./PrintTemplateImageLogo.png";
import { useParams, useHistory } from "react-router-dom";
import "./PrintMedicationCss.css";
import { Grid } from "@mui/material";
import axios from "../../helper/axios";
import moment from "moment/moment";
import API from "../../helper/axios";
import { useUser } from "../../Provider";
const PrintMedication = (props) => {
  const { user, userHospitalcode, userrole1, isAuthRole, isAuthorized, gettokenId, token } = useUser();
  const { admitnumber } = useParams();
  console.log(admitnumber)
  const history = useHistory();
  const params = useParams();
  const today = new Date();
  //const token = localStorage.getItem("jwtToken");
  const [MedicationDta, setMedicationData] = useState({});
  console.log(props.medi);
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    //documentTitle: MedicationDta.medi,
    onAfterPrint: props.handleClose,
  });
  const [data, setData] = useState([]);

  // Function to get login user's details

  //when page load or refresh happen this function will got called
  useEffect(() => {
    const loadData = async () => {

      //loadHospitalLogo(); will wait for loadAllDetails(); to got execute
      if (MedicationDta.hospitalcode) {
        await loadHospitalLogo();
      }
    };
    loadData();
  }, [userHospitalcode, userrole1, isAuthorized, MedicationDta]);
  useEffect(() => {
    getOperationDetails();
  }, []);
  //get the Ipd medication details according to admit number and medicationname
  const getOperationDetails = async () => {
    const res = await axios.get(`/getipdmedication/${admitnumber}/${props.medi}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    console.log(res.data[0])
    setMedicationData(res.data[0])
  }

  //get all hospital code
  const loadHospitalLogo = async () => {
    //when userHospitalcode is not undefined
    if (userHospitalcode !== undefined) {
      console.log(userHospitalcode)
      console.log(MedicationDta.hospitalcode)
      const res = await axios.get(`/gethospitallogo/${MedicationDta.hospitalcode}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          hospitalcode: MedicationDta.hospitalcode, // Include the hospitalcode in the query parameters
          //userrole: userrole1, // Include the userrole in the query parameters
        },
      });
      setData(res.data[0]);
      console.log(res.data);
    };
  }
  const formatOperationTime = (inputDateString) => {
    const inputTime = new Date(inputDateString);

    const formattedTime = inputTime.toLocaleString('en-US', {
      hour: '2-digit',
      minute: '2-digit',
      hour12: true,
      timeZone: 'UTC', // Change this to the server's time zone
    });
    return formattedTime;
  };
  return (
    <>
      <button
        className="btn btn-success"
        style={{ marginLeft: "68%", marginBottom: "1%", backgroundColor: "white", color: "green" }}
        onClick={handlePrint}
      >
        Print Medication Detail
      </button>

      <div  style={{ width: '100%', height: window.innerHeight, padding: "40px" }}  ref={componentRef}>
        {/* image will come dynamically */}
        <img src={`${API.defaults.baseURL}/uploadimage/attachone/${data.attachment1}`}
        style={{ width: '100%', marginBottom: "1px", height: "23%" }} />
        <Grid container spacing={3} className="container" style={{ paddingTop: "20px" }}>
          {/* Header */}
          <Grid item xs={12} className="print-Medication-header">
            <h2>Medication Details</h2>
          </Grid>

          {/* Medication Details */}
          <Grid container spacing={3} className="container">
            <Grid item xs={6} className="field">
              <span className="label">Reference No:</span>
              <span className="value">{MedicationDta.medicationname}</span>
            </Grid>

            <Grid item xs={6} className="field">
              <span className="label">Medicine Name:</span>
              <span className="value">{MedicationDta.medicinename}</span>
            </Grid>

            <Grid item xs={6} className="field">
              <span className="label">Medication Date:</span>
              <span className="value">{MedicationDta.date}</span>
            </Grid>

            <Grid item xs={6} className="field">
              <span className="label">Medication Category:</span>
              <span className="value">{MedicationDta.medicinecategory}</span>
            </Grid>

            <Grid item xs={6} className="field">
              <span className="label">Medication Time:</span>
              <span className="value">{MedicationDta.time}</span>
            </Grid>

            <Grid item xs={6} className="field">
              <span className="label">Dose:</span>
              <span className="value">{MedicationDta.dosage}</span>
            </Grid>
            <Grid item xs={6} className="field">
              <span className="label">Remark:</span>
              <span className="value">{MedicationDta.remarks}</span>
            </Grid>          
          </Grid>
        </Grid>


      </div>
    </>
  );
};

export default PrintMedication;
