import React, { useState, useEffect } from 'react';
import { Typography, Button, TextField, Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import axios from "../../../helper/axios";
import { useFormik } from "formik";
import { consultent } from "../../../helper/schemas";
import { useUser } from "../../../Provider";
import { useParams } from 'react-router-dom/cjs/react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DoctorSearchIds from '../../../Component/DoctorSearchIds';

const initialValues = {
  applieddate: "",
  doctorids: "",
  instructiondate: "",
  instruction: ""
};
const ConsultantRegisterForm = ({onConsultantAdd}) => {
  const handleConsultanRegister = () => {
    setFormVisibility(!isFormVisible); // Toggle form visibility
  };
  const { admitnumber } = useParams();
  console.log({ admitnumber })
  const { user, userHospitalcode, userrole1, isAuthRole, isAuthorized, gettokenId,token } = useUser();
  console.log(user, userHospitalcode, userrole1, isAuthRole, isAuthorized, gettokenId)
  const [isFormVisible, setFormVisibility] = useState(false);
  const [doctor, setDoctor] = useState([]);
  const [data, setData] = useState([]);
 // const token = sessionStorage.getItem("jwtToken");
  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleReset,
    handleSubmit,
    resetForm,
    setValues,
  } = useFormik({
    initialValues,
    validationSchema: consultent,
    onSubmit: async (values, action) => {
      try {

        //  if (userHospitalcode !== undefined) {
        const data = new FormData();
        data.append("document", JSON.stringify(values));
        console.log(JSON.stringify(values))
        const response = await axios.post(`/doctorinstruction/${admitnumber}`, data, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        });
        if (response.status === 200) {
          onConsultantAdd();
          toast.success("Data Saved")

        }
        //}
      } catch (err) {

      }
      action.resetForm();
    },

  });
   //Doctor onSearch Function
 const handleSearchChanges = (searchTerms) => {
  console.log("---------------",searchTerms)
  setDoctor(searchTerms); // Update the searchValue state with the search term
  setValues({
    ...values,
    doctorids:searchTerms.doctorcode,
 })
};
  const loadHospitalcode = async () => {
    //when userHospitalcode and userrole1 is not undefined then execute /gethospitalcode
    if (userHospitalcode !== undefined && userrole1 !== undefined) {
      const res = await axios.get(`/getIPDhospitalcode/${admitnumber}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          hospitalcode: userHospitalcode, // Include the hospitalcode in the query parameters
          userrole: userrole1, // Include the userrole in the query parameters
        },
      });
      setData(res.data[0]);
      console.log(res.data);
    };
  }
  useEffect(() => {
    const loadData = async () => {
      //await getDoctordetails(data.hospitalcode);
      await loadHospitalcode();
    };

    loadData();
  }, [userHospitalcode, userrole1, isAuthorized, data.hospitalcode]);

  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography variant="h6" style={{ padding: '20px' }}>
          Add Consultant Register
        </Typography>
        <Button variant="contained" color="primary" onClick={handleConsultanRegister}>
          {isFormVisible ? 'Close Form' : 'Add Consultant Register'}
        </Button>
      </div>
      {isFormVisible && (
        <form onSubmit={handleSubmit} style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
          <div className="patient-new primary-info">
            <div className="row">
              <div className="col-md-12">
                <div className="row">
                  <div className="col-md-6">
                    <div className="col-md-12 mt-3">
                      <div className="row">
                        <div className="col-md-4">
                          <label
                            htmlFor="applieddate"
                            className="col-form-label leftone"
                          >
                            Applied Date
                          </label>
                        </div>
                        <div className="col-md-8">
                          <input
                            type="datetime-local"
                            autoComplete="applieddate"
                            name="applieddate"
                            id="applieddate"
                            placeholder="Applied Date"
                            value={values.applieddate}
                            onChange={handleChange}
                            onBlur={handleBlur}

                            className="form-control"
                          />
                          {errors.applieddate && touched.applieddate ? (
                            <p className="form-erroremployee">{errors.applieddate}</p>
                          ) : null}
                        </div>
                      </div>
                    </div>

                    <div className="col-md-12 mt-3">
                      <div className="row">
                        <div className="col-md-4">
                          <label
                            htmlFor="doctorids"
                            className="col-form-label leftone"
                          >
                            Consultant Doctor
                          </label>
                        </div>
                        <div className="col-md-8">
                        <DoctorSearchIds
                          onSearchChanges={handleSearchChanges}
                          hospitalvalue={data.hospitalcode}
                         
                        />
                          {/* <select
                            type="text"
                            autoComplete="off"
                            name="doctorids"
                            id="doctorids"
                            placeholder="Consultant Doctor"
                            value={values.doctorids}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            style={{ width: '73%', height: '32px', border: '1px solid #ced4da ', color: '#495057' }}
                          >
                            <option>Select</option>
                            {doctor.map((doctor) => (
                              <option
                                value={doctor.doctorids}
                                key={doctor.fname}
                              >
                                {doctor.fname}
                              </option>
                            ))}
                          </select> */}
                          {errors.doctorids && touched.doctorids ? (
                            <p className="form-erroremployee">{errors.doctorids}</p>
                          ) : null}
                        </div>
                      </div>
                    </div>


                  </div>
                  <div className="col-md-6">

                    <div className="col-md-12 mt-3">
                      <div className="row">
                        <div className="col-md-4">
                          <label
                            htmlFor="instructiondate"
                            className="col-form-label leftone"
                          >
                            Instruction Date
                          </label>
                        </div>
                        <div className="col-md-8">
                          <input
                            type="date"
                            autoComplete="off"
                            name="instructiondate"
                            id="instructiondate"
                            placeholder="Instruction Date"
                            value={values.instructiondate}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            style={{ width: '73%', height: '32px', border: '1px solid #ced4da ', color: '#495057' }}
                          />
                          {errors.instructiondate && touched.instructiondate ? (
                            <p className="form-erroremployee">{errors.instructiondate}</p>
                          ) : null}
                        </div>
                      </div>
                    </div>



                    <div className="col-md-12 mt-3">
                      <div className="row">
                        <div className="col-md-4">
                          <label
                            htmlFor="instruction"
                            className="col-form-label leftone "
                          >
                            Instruction
                          </label>
                        </div>
                        <div className="col-md-8">
                          <textarea
                            type="text"
                            autoComplete="off"
                            name="instruction"
                            id="instruction"
                            placeholder="Instruction"
                            value={values.instruction}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            className="form-control"
                          />
                          {errors.instruction && touched.instruction ? (
                            <p className="form-erroremployee">{errors.instruction}</p>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-11 mt-6 text-center">
            <Button type="submit" variant="contained" color="primary" size="small" style={{ width: 'auto' }}>
              Submit
            </Button>
          </div>
          <ToastContainer/>
        </form>
      )}
    </div>
  );
};

export default ConsultantRegisterForm;



