
// import React, { createContext, useContext, useState, useEffect } from "react";
// import axios from "./helper/axios";
// import { useHistory } from "react-router-dom";
// // Create a context
// const UserContext = createContext();
// //export the useUser function which will return UserContext
// export function useUser() {
//   return useContext(UserContext);}

// export const UserProvider = ({ children }) => {
//   const history = useHistory();
//   const token = localStorage.getItem("jwtToken");
//   const [user, setUser] = useState(null);
//   const [userHospitalcode, setUserHospitalcode] = useState();
//   const [userrole1, setUserrole1] = useState();
//   const [isAuthRole, setIsAuthRole] = useState(false);
//   const [isAuthorized, setIsAuthorized] = useState(false);
//   const [gettokenId, setTokenId] = useState();
//   const loadAllDetails = async () => {
//     try {
//       const response = await axios.post('/loginuser', null, {
//         headers: { Authorization: `Bearer ${token}` },
//       });

//       const UserRoles = response.data.person[0][0].role1;
//       const hospitalcode = response.data.person[0][0].hospitalcode;
//       const employeeid = response.data.person[0][0].empid;
//       setUserHospitalcode(hospitalcode);
//       setUserrole1(UserRoles);
//       setTokenId(employeeid);
//       const authorizationResult = UserRoles === 'Admin';
//       setIsAuthRole(authorizationResult);
//       // Debugging: Log the condition result
//       const authorizationResults = hospitalcode === 1 && UserRoles === 'Superadmin';
//       console.log("Authorization Result:", authorizationResults);
//       setIsAuthorized(authorizationResults);
//     } catch (error) {
//       // Handle errors here
//     }
//   };

//   useEffect(() => {
//     if (!token) {
//       // Redirect to the login page if the token is not found
//       history.push("/");
//     } else {
//       loadAllDetails();
//     }
//   }, [history, token]);

//   // Provide the user context with the user data
//   const userContextValue = { user, userHospitalcode, userrole1, isAuthRole,isAuthorized,gettokenId,token };

//   return (
//     <UserContext.Provider value={userContextValue}>
//       {children}
//     </UserContext.Provider>
//   );
// };
import React, { createContext, useContext, useState, useEffect } from "react";
import axios from "./helper/axios";
import { useHistory } from "react-router-dom";
// Create a context
const UserContext = createContext();
//export the useUser function which will return UserContext
export function useUser() {
  return useContext(UserContext);}

export const UserProvider = ({ children }) => {
  const history = useHistory();
  const token = localStorage.getItem("jwtToken");
  const [user, setUser] = useState(null);
  const [userHospitalcode, setUserHospitalcode] = useState();
  const [userrole1, setUserrole1] = useState();
  const [userdesignation, setDesignation] = useState();
  const [isAuthRole, setIsAuthRole] = useState(false);
  const [isAuthorized, setIsAuthorized] = useState(false);
  const [gettokenId, setTokenId] = useState();
  const loadAllDetails = async () => {
    try {
      const response = await axios.post('/loginuser', null, {
        headers: { Authorization: `Bearer ${token}` },
      });

      const UserRoles = response.data.person[0][0].role1;
      const hospitalcode = response.data.person[0][0].hospitalcode;
      const employeeid = response.data.person[0][0].empid;
      const designation = response.data.person[0][0].designation;
      setUserHospitalcode(hospitalcode);
      setUserrole1(UserRoles);
      setTokenId(employeeid);
      setDesignation(designation);
      const authorizationResult = UserRoles === 'Admin';
      setIsAuthRole(authorizationResult);
      // Debugging: Log the condition result
      const authorizationResults = hospitalcode === 1 && UserRoles === 'Superadmin';
      console.log("Authorization Result:", authorizationResults);
      setIsAuthorized(authorizationResults);
    } catch (error) {
      // Handle errors here
    }
  };

  useEffect(() => {
    if (!token) {
      // Redirect to the login page if the token is not found
      history.push("/");
    } else {
      loadAllDetails();
    }
  }, [history, token]);

  // Provide the user context with the user data
  const userContextValue = { user, userHospitalcode, userrole1, userdesignation, isAuthRole,isAuthorized,gettokenId,token };

  return (
    <UserContext.Provider value={userContextValue}>
      {children}
    </UserContext.Provider>
  );
};
