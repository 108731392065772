import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import axios from "../../../helper/axios";
import { useUser } from "../../../Provider";
import { ToastContainer, toast } from 'react-toastify';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import Billingsearch from '../../../Component/Billingsearch';
import {validationRadiologyReport} from "../../../helper/schemas";
import {validationRadiologyReport2} from "../../../helper/schemas";
import Searchbar from '../../../Component/Searchbar';
import BillingSearchPerPatient from '../../../Component/BillingSearchPerPatient';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Table, Button, Modal, Form } from 'react-bootstrap';
import PrintRadiologyReport from '../../../Component/RadiologyReportTemplat/PrintRadiologyReport';
// Initial values for the form fields
const initialValues = {
  hospitalcode:"",
  patientid:"",
  name: '',
  age: '',
  sex: '',
  medicalHistory: '',
  radiologycategoryid: '',
    bodyPart: '',
    reason: '',
    impression:'',
    recommendations:'',
    signature:'',
    date:'',
    summary:'',
 };
 
const AddRadiologyReport = () => {
  const history = useHistory();
  // Use the 'useUser' hook to get user-related information
  const { user, userHospitalcode, userrole1, isAuthRole, isAuthorized, gettokenId, token } = useUser();
  console.log(user, userHospitalcode, userrole1, isAuthRole, isAuthorized, gettokenId);
  const[searchValueName,setSearchValueName]=useState("")
  const [category1, setCategory] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [isPopup, setIsPopup] = useState(false);
  const [billnumber, setBid] = useState();
  const [radiologyreportid, setRadiology] = useState();
  const [error, setError] = useState(false);
  let Showdate = new Date();
  let ShowTodaydate =
    Showdate.getFullYear() +
    "-" +
    (Showdate.getMonth() + 1) +
    "-" +
    Showdate.getDate();
  const [currentDate, setCurrentDate] = useState(ShowTodaydate);
  const getValidationSchema = () => {
    if (!isAuthorized ) {
      return validationRadiologyReport2;
    }
    else if (isAuthorized) {
      return validationRadiologyReport;
    }
  };
  // Function to fetch test category names based on hospital code
  const fetchMedicineCateoryNames = async (selectedHospitalCode) => {
    try {
      const response = await axios.get("/getradiologycategory", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          hospitalcode: selectedHospitalCode,
        },
      });

      if (response.status === 200) {
        setCategory(response.data);
      } else {
        console.error(`Failed to fetch available floor numbers with status: ${response.status}`);
      }
    } catch (error) {
      console.error('Error fetching available floor numbers:', error.message);
    }
  };
  const [findings, setFindings] = useState([{ description: '', location: '' }]);
  const handleAddFinding = () => {
    setFindings([...findings, { description: '', location: '' }]);
  };
  const handleSearch = (searchTerm) => {
    console.log(searchTerm);
    setSearchValueName(searchTerm); // Update the searchValue state with the search term
  };
  
  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleReset,
    handleSubmit,
    resetForm,
    setValues,
  } = useFormik({
    initialValues,
    validationSchema:getValidationSchema,
    onSubmit: async (values, action) => {

      try {
        setIsPopup(false)
        const data = new FormData();
        // Append form data to the FormData object
        data.append("documentvalue", JSON.stringify(values));
        data.append("findings", JSON.stringify(findings));
        data.append("document", JSON.stringify(searchValueName));
        data.append("currentDate", JSON.stringify(currentDate));
        data.append("gettokenId", JSON.stringify(gettokenId));
        data.append("userHospitalcode", JSON.stringify(userHospitalcode));
        console.log(JSON.stringify(searchValueName))
        console.log(JSON.stringify(values))
        console.log(JSON.stringify(findings))
        // Make a POST request to save Prescription data
        const response = await axios.post(`/addradiologyreport`, data, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        });

        // Display success message if the request is successful
        // if (response.status === 200) {
        //   toast.success("Data Saved");
        //   return history.push("/radiologyreportlist");
        // }
        if (response.status === 200) {
          console.log(response.data[0])
          const radiologyreportid=response.data[0];
          setRadiology(radiologyreportid);
          const billnumber = searchValueName.billnumber;
          setBid(billnumber);
         // toast.success("Data Saved")
          // setSuccess(true);
          if (isPopup) {
            setIsPopup(true)
          }
          setError(false)
          //return history.push("/appointment");
         toast.success("Data Saved");
       //  return history.push("/pathologyreport");
       }
      } catch (err) {
        // Handle errors
      }
      // Reset the form after submission
      action.resetForm();
    },
  });
  useEffect(() => {
    if (searchValueName) {
      setValues({
        ...values,
        hospitalcode:searchValueName.hospitalcode,
        patientid:searchValueName.patientid,
        name: searchValueName.patientname,
        age: searchValueName.age,
        sex: searchValueName.gender,
        // Update other fields as needed
      });
    }

    const loadData = async () => {
      const selectedHospitalCode = values.hospitalcode;
      await fetchMedicineCateoryNames(selectedHospitalCode);
    }
    loadData();
  }, [ searchValueName, token,userHospitalcode, userrole1, isAuthorized, values.hospitalcode]);
  const handleSearchChange = (searchTerm) => {
    console.log(searchTerm)
    setSearchValue(searchTerm); // Update the searchValue state with the search term
  };
  const handleClosed = () => {
    setIsPopup(false)
    //window.location.reload();
  }
  const printForm = (values) => {
      setRadiology(radiologyreportid)
      setBid(searchValueName.billnumber)
      setIsPopup(true)
      // window.open(`/AppoinmentForm/${value.patientid}`, "_blank")
  }
  return (
    <form onSubmit={handleSubmit}>
      <div className="patient-new primary-info">
        <div className="row">
          <div className="col-md-2">
            <label className="col-form-label ">Search Patient</label>
          </div>

          <div className="col-md-4">
            <Searchbar onSearchChange={handleSearchChange} />
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="row">
              <div className="col-md-6">
                <div className="row">
                  <div className="col-md-4">
                    <label htmlFor="name" className="col-form-label leftone">Bill No</label>
                  </div>
                  {/* <div className="col-md-8">
                <Billingsearch onSearchChange={handleSearch} />
              </div> */}
                  <div className="col-md-8">
                    {searchValue.patientid ? (
                      <BillingSearchPerPatient onSearchChange={handleSearch} searchValue={searchValue} />
                    ) : (
                      <Billingsearch onSearchChange={handleSearch} />
                    )}
                  </div>
                </div>
                <div className="row">

                </div>
              </div>
              <div className="col-md-6">
                <div className="row">
                  {isAuthorized ? (
                    <div className="col-md-4">
                      <label htmlFor="age" className="col-form-label leftone">Hospital Code</label>
                    </div>
                  ) : null}

                  <div className="col-md-8">
                    {isAuthorized ? (
                      <input
                        type="text"
                        autoComplete="off"
                        name="hospitalcode"
                        id="hospitalcode"
                        placeholder="Hospital Code"
                        value={values.hospitalcode}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        disabled
                        className="form-control"
                      />
                    ) : (
                      <input
                        type="hidden"
                        name="hospitalcode"
                        id="hospitalcode"
                        value={values.hospitalcode}
                        disabled={true}
                        className="form-control"
                      />
                    )}
                    {errors.hospitalcode && touched.hospitalcode ? (
                      <p className="form-erroremployee">{errors.hospitalcode}</p>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>



        <div className="row">
          <div className="col-md-12">

            <h5>Patient Information</h5>
            <div className="row">
              <div className="col-md-6">
                <div className="row">
                  <div className="col-md-4">
                    <label htmlFor="name" className="col-form-label leftone">Name</label>
                  </div>
                  <div className="col-md-8">
                    <input
                      type="text"
                      autoComplete="off"
                      name="name"
                      id="name"
                      placeholder="Name"
                      value={values.name}
                      onChange={handleChange}
                      disabled
                      className="form-control"
                    />
                    {errors.name && touched.name ? (
                      <p className="form-erroremployee">{errors.name}</p>
                    ) : null}
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-4">
                    <label htmlFor="age" className="col-form-label leftone">Age</label>
                  </div>
                  <div className="col-md-8">
                    <input
                      type="number"
                      autoComplete="off"
                      name="age"
                      id="age"
                      placeholder="Age"
                      value={values.age}
                      onChange={handleChange}
                      disabled
                      className="form-control"
                    />
                    {errors.age && touched.age ? (
                      <p className="form-erroremployee">{errors.age}</p>
                    ) : null}
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="row">
                  <div className="col-md-4">
                    <label htmlFor="sex" className="col-form-label leftone">Sex</label>
                  </div>
                  <div className="col-md-8">
                    <input
                      type="text"
                      autoComplete="off"
                      name="sex"
                      id="sex"
                      placeholder="Sex"
                      value={values.sex}
                      onChange={handleChange}
                      disabled
                      className="form-control"
                    />
                    {errors.sex && touched.sex ? (
                      <p className="form-erroremployee">{errors.sex}</p>
                    ) : null}
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-4">
                    <label htmlFor="medicalHistory" className="col-form-label leftone">Medical History</label>
                  </div>
                  <div className="col-md-8">
                    <input
                      type="text"
                      autoComplete="off"
                      name="medicalHistory"
                      id="medicalHistory"
                      placeholder="Medical History"
                      value={values.medicalHistory}
                      onChange={handleChange}
                      className="form-control"
                    />
                    {errors.medicalHistory && touched.medicalHistory ? (
                      <p className="form-erroremployee">{errors.medicalHistory}</p>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <h5>Imaging Study Information</h5>
            <div className="row">
              <div className="col-md-6">
                <div className="row">
                  <div className="col-md-4">
                    <label htmlFor="radiologycategoryid" className="col-form-label leftone">Type of Imaging Study</label>
                  </div>
                  <div className="col-md-8">
                    <select
                      type="text"
                      autoComplete="off"
                      name="radiologycategoryid"
                      id="radiologycategoryid"
                      placeholder="Type of Imaging Study"
                      value={values.radiologycategoryid}
                      onChange={handleChange}
                      style={{ width: '73%', height: '32px', border: '1px solid #ced4da ', color: '#495057' }}
                    >
                      <option>Select</option>
                      {category1.map((category1) => (
                        <option
                          value={category1.radiologycategoryid}
                          key={category1.id}
                        >
                          {category1.radiologycategoryname}
                        </option>
                      ))}
                    </select>

                    {errors.radiologycategoryid && touched.radiologycategoryid ? (
                      <p className="form-erroremployee">{errors.radiologycategoryid}</p>
                    ) : null}
                  </div>
                </div>
                <div className="row md-6">
                  <div className="row">
                    <div className="col-md-4">
                      <label htmlFor="reason" className="col-form-label leftone">Reason for Study</label>
                    </div>
                    <div className="col-md-8">
                      <input
                        type="text"
                        autoComplete="off"
                        name="reason"
                        id="reason"
                        placeholder="Reason for Study"
                        value={values.reason}
                        onChange={handleChange}
                        className="form-control"
                      />
                      {errors.reason && touched.reason ? (
                        <p className="form-erroremployee">{errors.reason}</p>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="row">
                  <div className="col-md-4">
                    <label htmlFor="bodyPart" className="col-form-label leftone">Body Part/Area Imaged</label>
                  </div>
                  <div className="col-md-8">
                    <input
                      type="text"
                      autoComplete="off"
                      name="bodyPart"
                      id="bodyPart"
                      placeholder="Body Part/Area Imaged"
                      value={values.bodyPart}
                      onChange={handleChange}
                      className="form-control"
                    />
                    {errors.bodyPart && touched.bodyPart ? (
                      <p className="form-erroremployee">{errors.bodyPart}</p>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>


        <div className="row">
          <div className="col-md-12 mt-3">
            <h5>Summary of Findings</h5>
            <textarea
              rows="3"
              cols="30"
              autoComplete="off"
              name="summary"
              id="summary"
              placeholder="Summary of Findings"
              value={values.summary}
              onChange={handleChange}
              className="form-control"
              style={{ width: '95%' }}
            ></textarea>
            {errors.summary && touched.summary ? (
              <p className="form-erroremployee">{errors.summary}</p>
            ) : null}
          </div>
        </div>


        <div className="row">
          <div className="col-md-12 mt-3">
            <h5>Detailed Description of Findings</h5>
            <div className="row">
              <div className="col-md-12">
                {findings.map((finding, index) => (
                  <div key={index} className="row">
                    <div className="col-md-6">
                      <label htmlFor={`findingDescription${index}`} className="col-form-label leftone">Finding {index + 1} Description</label>
                      <input
                        type="text"
                        autoComplete="off"
                        name={`findingDescription${index}`}
                        id={`findingDescription${index}`}
                        placeholder={`Finding ${index + 1} Description`}
                        value={finding.description}
                        onChange={(e) => {
                          const newFindings = [...findings];
                          newFindings[index].description = e.target.value;
                          setFindings(newFindings);
                        }}
                        className="form-control"
                      />
                    </div>
                    <div className="col-md-6">
                      <label htmlFor={`findingLocation${index}`} className="col-form-label leftone">Finding {index + 1} Location</label>
                      <input
                        type="text"
                        autoComplete="off"
                        name={`findingLocation${index}`}
                        id={`findingLocation${index}`}
                        placeholder={`Finding ${index + 1} Location`}
                        value={finding.location}
                        onChange={(e) => {
                          const newFindings = [...findings];
                          newFindings[index].location = e.target.value;
                          setFindings(newFindings);
                        }}
                        className="form-control"
                      />
                    </div>
                  </div>
                ))}
                <button className="btn btn-outline-primary mt-3" onClick={handleAddFinding} type="button">Add Finding</button>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12 mt-3">
            <h5>Impression</h5>
            <textarea
              rows="3"
              cols="30"
              autoComplete="off"
              name="impression"
              id="impression"
              placeholder="Impression"
              value={values.impression}
              onChange={handleChange}
              className="form-control"
              style={{ width: '95%' }}
            ></textarea>
            {errors.impression && touched.impression ? (
              <p className="form-erroremployee">{errors.impression}</p>
            ) : null}
          </div>
        </div>

        <div className="row">
          <div className="col-md-12 mt-3">
            <h5>Recommendations</h5>
            <textarea
              rows="3"
              cols="30"
              autoComplete="off"
              name="recommendations"
              id="recommendations"
              placeholder="Recommendations"
              value={values.recommendations}
              onChange={handleChange}
              className="form-control"
              style={{ width: '95%' }}
            ></textarea>
            {errors.recommendations && touched.recommendations ? (
              <p className="form-erroremployee">{errors.recommendations}</p>
            ) : null}
          </div>
        </div>
        <br />

        <div className="row">
          <div className="col-md-12">
            <div className="row">
              <div className="col-md-6">
                <div className="row">
                  <div className="col-md-4">
                    <label htmlFor="signature" className="col-form-label leftone">Signature</label>
                  </div>
                  <div className="col-md-8">
                    <input
                      type="text"
                      autoComplete="off"
                      name="signature"
                      id="signature"
                      placeholder="Signature"
                      value={values.signature}
                      onChange={handleChange}
                      className="form-control"
                    /> {errors.signature && touched.signature ? (
                      <p className="form-erroremployee">{errors.signature}</p>
                    ) : null}
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="row">
                  <div className="col-md-4">
                    <label htmlFor="signature" className="col-form-label leftone">Date</label>
                  </div>
                  <div className="col-md-8">
                    <input
                      type="date"
                      autoComplete="off"
                      name="date"
                      id="date"
                      placeholder="Date"
                      value={values.date}
                      onChange={handleChange}
                      className="form-control"
                    /> {errors.date && touched.date ? (
                      <p className="form-erroremployee">{errors.date}</p>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="row">
        <div className="col-md-12 mt-3">
          <button className="btn btn-primary" type="submit">Submit</button>
        </div>
        <div className="col-md-12 mt-3">
        <button className="btn btn-primary" type="submit" onClick={() => printForm(values, "printandsave")}>Save Print</button>
        </div>
      </div> */}
      <div className="row">
    <div className="col-md-1 ">
        <button className="btn btn-primary btn-block" type="submit">Submit</button>
    </div>
    <div className="col-md-2 ">
        <button className="btn btn-primary btn-block" type="submit" onClick={() => printForm(values, "printandsave")}>Save Print</button>
    </div>
</div>
<br/>
      {isPopup == true ?
            <>

              <Modal show={true} animation={false} onHide={handleClosed} size="lg">
                <Modal.Header closeButton>
                  <Modal.Title>{billnumber}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  {radiologyreportid == undefined ? ""
                    :
                    // <PrintTemplateAppointment pId={billnumber} handleSubmit={handleClosed} apppoinentDate={appointmentDate}/>}
                    <PrintRadiologyReport billnumber={billnumber}  radiologyreportid={radiologyreportid} handleSubmit={handleClosed} />}
                </Modal.Body>
              </Modal>
            </>
            :
            ""}
          <ToastContainer />
    </form>

  );
};

export default AddRadiologyReport;

