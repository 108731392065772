import React from "react";
import { useFormik } from "formik";
import { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useHistory } from "react-router-dom";
import axios from "../../../helper/axios";
import { Table, Button, Modal, Form } from "react-bootstrap";
import { patientregistration ,patientregistrations} from "../../../helper/schemas";
import { ToastContainer, toast } from 'react-toastify';
import { useUser } from "../../../Provider";
import HospitalCodeSearch from "../../../Component/HospitalCodeSearch";
const initialValues = {
  patientname: "",
  gaurdianname: "",
  phoneno: "",
  address: "",
  bloodgroup: "",
  email: "",
  gender: "",
  dob: "",
  age: "",
  state: "",
  city: "",
  occupation: "",
  hospitalcode: ""
};

const NewPatientRegistrationPage = () => {
  const {user, userHospitalcode, userrole1, isAuthRole,isAuthorized,gettokenId,token  } = useUser();
  const history = useHistory();
  const [message, setMessage] = useState("");
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  // initialised isAuthorized value as false
 // const [isAuthorized, setIsAuthorized] = useState(false);
  //get jwttoken from sessionStorage
 // const token = sessionStorage.getItem("jwtToken");
  //get current date
  let Showdate = new Date();
  let ShowTodaydate =
    Showdate.getFullYear() +
    "-" +
    (Showdate.getMonth() + 1) +
    "-" +
    Showdate.getDate();
  const [currentDate, setCurrentDate] = useState(ShowTodaydate);

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    resetForm,
    setValues
  } = useFormik({
    initialValues,
    //when isAuthorized render patientregistration validation if false render patientregistrations validation
    validationSchema: isAuthorized ? patientregistration : patientregistrations, // Conditionally apply the schema
    // ... (previous code)
    onSubmit: async (values, action) => {
      try{
      const data = new FormData();
      data.append("document", JSON.stringify(values));
      data.append("currentDate", JSON.stringify(currentDate));
      data.append("gettokenId", JSON.stringify(gettokenId));
      data.append("userHospitalcode", JSON.stringify(userHospitalcode));
      console.log(JSON.stringify(userHospitalcode));
      console.log(JSON.stringify(values))
      const response = await axios.post("/PatientRegistration", data, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      });
      console.log(
        "🚀 ~ file: Registration.jsx ~ line 11 ~ Registration ~ values",
        values
      );
      if (response.status === 200) {
        return history.push("/listviewpatients");
      }
    } catch (error) {
      console.log(error)
      toast("Something Went Wrong")
    }
    },
  });
  const handleReset = () => {
    // Reset the form using Formik's resetForm function
    resetForm();
  };
  const [data, setData] = useState([]);
  const [searchValues, setSearchValues] = useState("");
  //Hospital code search
  const onSearchItems = (searchTerm) => {
    console.log("------",searchTerm)
    setSearchValues(searchTerm);
    setValues({
      ...values,
      hospitalcode: searchTerm.hospitalcode,
      // Update other fields as needed
    });
  };
  //when page load or refresh happen this function will got called
  useEffect(() => {
    const loadData = async () => {
      //await loadAllDetails();
      //loadHospitalcode(); will wait for loadAllDetails(); to got execute
     // await loadHospitalcode();
    };
    loadData();
  }, [userHospitalcode, userrole1, isAuthorized]);
   //get all hospital 
  // const loadHospitalcode = async () => {
  //   //when userHospitalcode and userrole1 is not undefined then execute /gethospitalcode
  //   if (userHospitalcode !== undefined && userrole1 !== undefined) {
  //     const res = await axios.get("/gethospitalcode", {
  //       headers: {
  //         Authorization: `Bearer ${token}`,
  //       },
  //       params: {
  //         hospitalcode: userHospitalcode, // Include the hospitalcode in the query parameters
  //         userrole: userrole1, // Include the userrole in the query parameters
  //       },
  //     });
  //     setData(res.data);
  //     console.log(res.data);
  //   };
  // }
  const currentDomain = window.location.hostname;
  return (
    <div className="p-3 pt-0 appointment">
       <Helmet>
        <title>Aarogyasaarthi | {currentDomain} | Hospital Management Software | Smart Solutions for Hospital Efficiency</title>
      </Helmet>
      <form onSubmit={handleSubmit}>
        {success && <div>Patient registered</div>}
        {error && <div>Error in registering patient. please try again</div>}
        <div class="fill header"><h3 class="float-left"> Patient Registration  </h3> </div>
        <div className="patient-new primary-info">
        <div className="row">
        <div className="col-md-12">
          <div className="row">
        <div className="col-md-6">
            <div className="col-md-12 mt-3">
              <div className="row">
                <div className="col-md-4">
                  <label
                    htmlFor="patientname"
                    className="col-form-label leftone"
                  >
                    Patient Name
                  </label>
                </div>
                <div className="col-md-8">
                  <input
                    type="text"
                    autoComplete="off"
                    name="patientname"
                    id="patientname"
                    placeholder="Patient Name"
                    value={values.patientname}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="form-control ember-text-field text-left ember-view"
                  />
                  {errors.patientname && touched.patientname ? (
                    <p className="form-erroremployee">{errors.patientname}</p>
                  ) : null}
                </div>
              </div>
            </div>

            <div className="col-md-12 mt-3">
              <div className="row">
                <div className="col-md-4">
                  <label
                    htmlFor="age"
                    className="col-form-label leftone"
                  >
                    Age
                  </label>
                </div>
                <div className="col-md-8">
                  <input
                    type="number"
                    autoComplete="off"
                    name="age"
                    id="age"
                    placeholder="Age"
                    value={values.age}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="form-control ember-text-field text-left ember-view"
                  ></input>
                  {errors.age && touched.age ? (
                    <p className="form-erroremployee">{errors.age}</p>
                  ) : null}
                </div>
              </div>
            </div>

            <div className="col-md-12 mt-3">
              <div className="row">
                <div className="col-md-4">
                  <label
                    htmlFor="gender"
                    className="col-form-label leftone"
                  >
                    Gender
                  </label>
                </div>
                <div className="col-md-8">
                  <select
                    type="text"
                    autoComplete="off"
                    name="gender"
                    id="gender"
                    placeholder="Gender"
                    value={values.gender}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    style={{ width: '73%', height: '32px', border: '1px solid #ced4da ', color: '#495057' }}
                  >
                    <option>Select</option>
                    <option>Male</option>
                    <option>Female</option>
                    <option>Others</option>
                  </select>
                  {errors.gender && touched.gender ? (
                    <p className="form-erroremployee">{errors.gender}</p>
                  ) : null}
                </div>
              </div>
            </div>
            <div className="col-md-12 mt-3">
              <div className="row">
                <div className="col-md-4">
                  <label
                    htmlFor="email"
                    className="col-form-label"
                  >
                    Email
                  </label>
                </div>
                <div className="col-md-8">
                  <input
                    type="email"
                    autoComplete="off"
                    name="email"
                    id="email"
                    placeholder="Email"
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="form-control ember-text-field text-left ember-view"
                  ></input>
                   {errors.email && touched.email ? (
                    <p className="form-erroremployee">{errors.email}</p>
                  ) : null}
                </div>
              </div>
            </div>

            <div className="col-md-12 mt-3">
              <div className="row">
                <div className="col-md-4">
                  <label
                    htmlFor="bloodgroup"
                    className="col-form-label "
                  >
                    Blood Group
                  </label>
                </div>
                <div className="col-md-8">
                  <select
                    type="text"
                    autoComplete="off"
                    name="bloodgroup"
                    id="bloodgroup"
                    placeholder="Blood Group"
                    value={values.bloodgroup}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    style={{ width: '73%', height: '32px', border: '1px solid #ced4da', color: '#495057' }}
                  > <option>Select</option>
                  <option>O+ve</option>
                  <option>A+ve</option>
                  <option>B+ve</option>
                  <option>AB+ve</option>
                  <option>O-ve</option>
                  <option>A-ve</option>
                  <option>B-ve</option>
                  <option>AB-ve</option>
                </select>

                  {errors.bloodgroup && touched.bloodgroup ? (
                    <p className="form-erroremployee">{errors.bloodgroup}</p>
                  ) : null}
                </div>
              </div>
            </div>

            <div className="col-md-12 mt-3">
              <div className="row">
                <div className="col-md-4">
                  <label
                    htmlFor="city"
                    className="col-form-label"
                  >
                    City
                  </label>
                </div>
                <div className="col-md-8">
                  <input
                    type="text"
                    autoComplete="off"
                    name="city"
                    id="city"
                    placeholder="City"
                    value={values.city}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="form-control ember-text-field text-left ember-view"
                  ></input>
                  {errors.city && touched.city? (
                    <p className="form-erroremployee">{errors.city}</p>
                  ) : null}
                </div>
              </div>
            </div>
            {isAuthorized ? (   <div className="col-md-12 mt-3">
              <div className="row">
            <div className="col-md-4">
              <label
                htmlFor="gaurdianname"
                className="col-form-label "
              >
                Guardian Name
              </label>
            </div>
            <div className="col-md-8">
              <input
                type="text"
                autoComplete="off"
                name="gaurdianname"
                id="gaurdianname"
                placeholder="Guardian Name"
                value={values.gaurdianname}
                onChange={handleChange}
                onBlur={handleBlur}
                className="form-control ember-text-field text-left ember-view"
              />
              {errors.gaurdianname && touched.gaurdianname ? (
                <p className="form-erroremployee">{errors.gaurdianname}</p>
              ) : null}
            </div>
            <div className="col-md-12 mt-3">
              <div className="row">
              </div>
            </div>
          </div>
          </div> ) : null}
          </div>
          <div className="col-md-6">
          <div className="col-md-12 mt-3">
              <div className="row">
<div className="col-md-4">
                  <label
                    htmlFor="phoneno "
                    className="col-form-label leftone"
                  >
                    Mobile Number
                  </label>
                </div>
                <div className="col-md-8">
                  <input
                    type="number"
                    autoComplete="off"
                    name="phoneno"
                    id="phoneno"
                    placeholder="Mobile Number"
                    value={values.phoneno}
                    onChange={handleChange}
                    className="form-control ember-text-field text-left ember-view"
                  />

                  {errors.phoneno && touched.phoneno ? (
                    <p className="form-erroremployee">{errors.phoneno}</p>
                  ) : null}
                </div></div></div>
 <div className="col-md-12 mt-3">
              <div className="row">
   <div className="col-md-4">
                  <label
                    htmlFor="address"
                    className="col-form-label leftone"
                  >
                    Address
                  </label>
                </div>
                <div className="col-md-8">
                  <textarea
                    rows="1"
                    cols="30"
                    type="text"
                    autoComplete="off"
                    name="address"
                    id="address"
                    placeholder="Address"
                    value={values.address}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    style={{ width: '73%', height: '32px', border: '1px solid #ced4da ', color: '#495057' }}
                  />
                  {errors.address && touched.address ? (
                    <p className="form-erroremployee">{errors.address}</p>
                  ) : null}
                </div></div></div>
                {isAuthorized ? (<div className="col-md-12 mt-3">
              <div className="row">
  
                <div className="col-md-4">
                  <label
                    htmlFor="hospitalcode"
                    className="col-form-label leftone"
                  >
                    Hospital Code
                  </label>
                </div>
                <div className="col-md-8">
                  {/* Conditionally render the dropdown and input field */}
                  {isAuthorized ? (
                      
                         <HospitalCodeSearch onSearchItem={onSearchItems} />
                       
                    // <select
                    //   type="text"
                    //   autoComplete="off"
                    //   name="hospitalcode"
                    //   id="hospitalcode"
                    //   placeholder="Hospital Code"
                    //   value={values.hospitalcode}
                    //   onChange={handleChange}
                    //   onBlur={handleBlur}
                    //   style={{ width: '73%', height: '32px', border: '1px solid #ced4da', color: '#495057' }}
                    // >
                    //   <option>Select</option>
                    //   {data.map((code) => (
                    //     <option value={code.hospitalcode} key={code.id}>
                    //       {code.hospitalcode}
                    //     </option>
                    //   ))}
                    // </select>
                  ) : (
                    <input
                      type="text"
                      name="hospitalcode"
                      id="hospitalcode"
                      value={userHospitalcode}
                      disabled={true} // Set the input as disabled
                      className="form-control "
                    />
                  )}
                  {isAuthorized && errors.hospitalcode && touched.hospitalcode ? (
                    <p className="form-erroremployee">{errors.hospitalcode}</p>
                  ) : null}
                </div></div></div>) : null}
              <div className="col-md-12 mt-3">
              <div className="row">
                  <div className="col-md-4">
                  <label
                    htmlFor="dob"
                    className="col-form-label"
                  >
                    Date of Birth
                  </label>
                </div>
                <div className="col-md-8">
                  <input
                    type="date"
                    autoComplete="off"
                    name="dob"
                    id="dob"
                    placeholder="DOB"
                    value={values.dob}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="form-control ember-text-field text-left ember-view"
                  />
                </div></div></div>
 <div className="col-md-12 mt-3">
              <div className="row">

                <div className="col-md-4">
                  <label
                    htmlFor="state"
                    className="col-form-label"
                  >
                    State
                  </label>
                </div>
                <div className="col-md-8">
                  <input
                    type="text"
                    autoComplete="off"
                    name="state"
                    id="state"
                    placeholder="State"
                    value={values.state}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="form-control ember-text-field text-left ember-view"
                  />
                  {errors.state && touched.state? (
                    <p className="form-erroremployee">{errors.state}</p>
                  ) : null}
                </div></div></div>
 <div className="col-md-12 mt-3">
              <div className="row">
 <div className="col-md-4">
                  <label
                    htmlFor="occupation"
                    className="col-form-label"
                  >
                    Occupation
                  </label>
                </div>
                <div className="col-md-8">
                  <input
                    type="text"
                    autoComplete="off"
                    name="occupation"
                    id="occupation"
                    placeholder="Occupation"
                    value={values.occupation}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="form-control ember-text-field text-left ember-view"
                  />
                   {errors.occupation && touched.occupation? (
                    <p className="form-erroremployee">{errors.occupation}</p>
                  ) : null}
                </div></div></div>
                {!isAuthorized ? (   <div className="col-md-12 mt-3">
              <div className="row">
            <div className="col-md-4">
              <label
                htmlFor="gaurdianname"
                className="col-form-label "
              >
                Guardian Name
              </label>
            </div>
            <div className="col-md-8">
              <input
                type="text"
                autoComplete="off"
                name="gaurdianname"
                id="gaurdianname"
                placeholder="Guardian Name"
                value={values.gaurdianname}
                onChange={handleChange}
                onBlur={handleBlur}
                className="form-control ember-text-field text-left ember-view"
              />
              {errors.gaurdianname && touched.gaurdianname ? (
                <p className="form-erroremployee">{errors.gaurdianname}</p>
              ) : null}
            </div>
            <div className="col-md-12 mt-3">
              <div className="row">
              </div>
            </div>
          </div>
          </div> ) : null}
            </div>
          </div>
          </div>
        </div>
        </div>
        <div className="col-md-6  mt-3">
          <Button
            type="submit"
            variant="success"
            className="mx-3"
          >
            Save
          </Button>
          <Button
            variant="primary"
            className="mx-3"
            onClick={handleReset}
          >
            Reset
          </Button>
        </div>
      </form>
    </div>
  );
};

export default NewPatientRegistrationPage;

