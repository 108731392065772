
import React, { useState, useEffect } from "react";
import axios from '../helper/axios';
import Autocomplete from "@mui/material/Autocomplete";
import {
    TextField,
    InputAdornment,
    IconButton,
} from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import { useUser } from "../Provider";

const SearchStaff = ({ onSearchChanges, hospitalvalue }) => {
    const history = useHistory();
    const { userrole1, token } = useUser();
    const [data, setStaff] = useState([]);
    const [selectedStaff, setSelectedStaff] = useState('');
    const [openAutocomplete, setOpenAutocomplete] = useState(false);

    useEffect(() => {
        const loadData = async () => {
            await getStaffDetails();
            setSelectedStaff("");
        };

        loadData();
    }, [hospitalvalue, userrole1]);

    const getStaffDetails = async () => {
        try {
            const res = await axios.get(`/getAvailableStaffs`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                params: {
                    hospitalcode: hospitalvalue,
                    userrole: userrole1,
                },
            });

            if (res.status === 200) {
                setStaff(res.data);
            } else {
                alert(`Request failed with status: ${res.status}`);
            }
        } catch (error) {
            if (error.request) {
                // toast.error("No response received from the server");
            } else {
                toast.error("Error:", error.message);
            }
        }
    };

    const onSearch = (event, value) => {
        setSelectedStaff(value);
        onSearchChanges(value);
    };

    const getOptionLabel = (option) => {
        return option?.fname || '';
    };

    const handleExpandClick = () => {
        setOpenAutocomplete(!openAutocomplete);
    };

    return (
        <div className="searchbar">
            <Autocomplete
                freeSolo
                id="free-solo"
                options={data}
                getOptionLabel={getOptionLabel}
                value={selectedStaff}
                onChange={onSearch}
                open={openAutocomplete}
                onOpen={() => setOpenAutocomplete(true)}
                onClose={() => setOpenAutocomplete(false)}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        placeholder="Select Staff"
                        InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton onClick={handleExpandClick}>
                                        <ExpandMoreIcon />
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                )}
                renderOption={(props, option) => (
                    <li {...props}>
                        {option.fname}
                    </li>
                )}
            />
        </div>
    );
}

export default SearchStaff;

