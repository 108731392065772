import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
// Here we are using react styled component for styling
//we are not using any css file for styling
// Here we have styled the background of sidebar links and for hover clour of same link

const SidebarLink = styled(Link)`
  display: flex;
  color: #FFF;
  justify-content: space-between;
  align-items: center;
  padding:0 0 0 20px; ;
 
  list-style: none;
  height: 35px;
  text-decoration: none;
  font-size: 0.8rem;
  font-weight: 350;
  &:hover {
    background: #f67638;
    cursor: pointer;
  };
  @media (max-width: 480px) {
    /* Adjust the styles for smaller screens */
    height: 45px;
    font-size: 1.2rem;
    padding: 2px;
   
  }
`;
// Here we have styled the title for sidebar
const SidebarLabel = styled.span`
  margin-left: 5px;
  @media (max-width: 480px) {
    /* Adjust the styles for smaller screens */
    margin-left: 3px;
  }
`;
// Here we have styled the background of  sidebar dropdownlinks and for hover clour of same link
const DropdownLink = styled(Link)`
  background: #2d373c;
  height: 30px;
  padding-left: 3rem;
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #f5f5f5;
  font-size: 0.8rem;
  &:hover {
    background: #f67638;
    cursor: pointer;
  };
  @media (max-width: 480px) {
    /* Adjust the styles for smaller screens */
    height: 35px;
    padding-left: 2rem;
    font-size: 1.2rem;
  }
`;

const SubMenu = ({ item }) => {
  const [subnav, setSubnav] = useState(false);

  const showSubnav = () => setSubnav(!subnav);

  return (
    <>
      <SidebarLink to={item.path} onClick={item.subNav && showSubnav}>
        <div>
          {item.icon}
          <SidebarLabel>{item.title}</SidebarLabel>
        </div>
        <div>
          {item.subNav && subnav
            ? item.iconOpened
            : item.subNav
            ? item.iconClosed
            : null}
        </div>
      </SidebarLink>
      {subnav &&
        item.subNav.map((item, index) => {
          return (
            <DropdownLink to={item.path} key={index}>
              {item.icon}
              <SidebarLabel>{item.title}</SidebarLabel>
            </DropdownLink>
          );
        })}
    </>
  );
};

export default SubMenu;
