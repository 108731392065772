
import React, { useState, useEffect } from "react";
import axios from '../helper/axios';
import Autocomplete from "@mui/material/Autocomplete";
import { TextField, InputAdornment, IconButton } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useUser } from "../Provider";
import { useHistory } from 'react-router-dom';

const SearchBedGroup = ({ onBedGroupChange, hospitalvalue }) => {
    const { userrole1, token } = useUser();
    const [data,setBedGroups] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState('');
    const [openAutocomplete, setOpenAutocomplete] = useState(false);
    const history = useHistory();

    useEffect(() => {
        fetchBedGroups();
        setSelectedCategory("")
    }, [hospitalvalue, userrole1]);

    const fetchBedGroups = async () => {
        try {
          const response = await axios.get("/getbedgroup", {
            headers: {
              Authorization: `Bearer ${token}`,
            },
            params: {
              hospitalcode: hospitalvalue,
            },
          });
    
          if (response.status === 200) {
            setBedGroups(response.data);
          } else {
            console.error(`Failed to fetch available bed groups with status: ${response.status}`);
          }
        } catch (error) {
          console.error('Error fetching available bed groups:', error.message);
        }
      };

    const handleCategoryChange = (event, value) => {
        
        if (value) {
            setSelectedCategory(value);
            onBedGroupChange(value);
        } else {
            // Handle the case when no value is selected
            setSelectedCategory('');
        }
    };
    const getOptionLabel = (option) => {
        // Handle the case when option is null or undefined
        return option?.groupname || '';
    };
    const handleExpandClick = () => {
      setOpenAutocomplete(!openAutocomplete);
  };

    return (
        <div className="searchbar">
            <Autocomplete
                freeSolo
                id="free-solo"
                options={data}
                getOptionLabel={getOptionLabel}
                open={openAutocomplete}
                onOpen={() => setOpenAutocomplete(true)}
                onClose={() => setOpenAutocomplete(false)}
                onChange={handleCategoryChange}
                value={selectedCategory}
                renderInput={(params) =>
                    <TextField
                        {...params}
                        placeholder="Select Bed Group"
                        InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton onClick={handleExpandClick}>
                                        <ExpandMoreIcon />
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                }
                renderOption={(props, option) => (
                    <li {...props}>
                      {option.groupname}-{option.floorname}
                    </li>
                  )}
            />
        </div>
    );
};

export default SearchBedGroup;