import React, { useState, useEffect, useRef } from 'react';
// import { Print, Cached, Close } from '@material-ui/icons';
import axios from "../../../helper/axios";
import {  Modal } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { NavLink } from 'react-router-dom';
import PrintTemplate from "../../../Component/PrintTemplate/PrintTemplate"
import MUIDataTable from "mui-datatables";
import { Helmet } from 'react-helmet';
import IconButton from '@mui/material/IconButton';
import PrintIcon from '@mui/icons-material/Print';
import DeleteIcon from '@mui/icons-material/Delete';
import EventIcon from '@mui/icons-material/Event';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { useUser } from "../../../Provider";
const ListofAppointment = () => {
  const { user, userHospitalcode, userrole1, isAuthRole, isAuthorized, gettokenId,token } = useUser();
  const currentDate = new Date();
  const day = currentDate.getDate();
  const month = currentDate.getMonth() + 1; // Months are zero-indexed, so add 1
  const year = currentDate.getFullYear();
  const [dateRange, setDateRange] = useState({
    startDate: null,
    endDate: null,
    key: 'selection', // Add the 'key' property
  });
  const formatDate = (date) => {
    if (!date) return ''; // Handle the case when date is null or undefined

    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-indexed, so add 1
    const day = date.getDate().toString().padStart(2, '0');

    return `${year}-${month}-${day}`;
  };
  // Format the date as DD-MM-YYYY
  const formattedDate = `${day}-${month}-${year}`;
  const [data, setData] = useState([]);
  const [patientid, setPid] = useState();
  const [hospitalcode, setHoc] = useState();
  const [opdnumber, setOpd] = useState();
  const [isPopup, setIsPopup] = useState(false);
  //const token = sessionStorage.getItem("jwtToken");
  const [userRole, setRole] = useState();
  // const [gettokenId, setTokenId] = useState();
  // const [userHospitalcode, setUserHospitalcode] = useState();
  // const [userrole1, setUserrole1] = useState();
  // const [isAuthorized, setIsAuthorized] = useState(false);
  const cancelAppointment = async (opdnumber) => {
    // Display a confirmation dialog
    const isConfirmed = window.confirm(`Do you want to cancel this ${opdnumber} appointment?`);

    if (isConfirmed) {
      try {
        // console.log(opdnumber)
        await axios.post(`/cancelappointment/${opdnumber}`, null, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        // alert("Appointment has been canceled.");

        // After successfully canceling the appointment, refresh the appointment list.
        loadAllAppointment();
      } catch (error) {
        // Handle any error here.
        alert("Error cancelling appointment:", error);
      }
    } else {
      // User clicked "Cancel" in the confirmation dialog, do nothing.
    }
  };
  //when page load or refresh happen this function will got called
  useEffect(() => {
    const loadData = async () => {
      //await loadAllDetails();
      //loadAllAppointment(); will wait for loadAllDetails(); to got execute
      await loadAllAppointment();
    };

    loadData();
  }, [userrole1, userHospitalcode]);
  //get all the appointment list for specific hospital
  const loadAllAppointment = async () => {
    try {
      if (userHospitalcode !== undefined && userrole1 !== undefined) {
        // console.log(userHospitalcode);
        const res = await axios.get('/getappointment', {
          headers: {
            Authorization: `Bearer ${token}`,//Include the token for Authorization
          },
          params: {
            hospitalcode: userHospitalcode, // Include the hospitalcode in the query parameters
            userrole: userrole1, // Include the userrole in the query parameters
          },
        });
        if (res.status === 200) {
          const sortedList = [...res.data].sort((a, b) => {
            // appointmentdate
            const dateA = new Date(a.appointmentdate);
            const dateB = new Date(b.appointmentdate);

            // Compare dateA and dateB to sort in descending order
            return dateB - dateA;
          });

          setData(sortedList);
        } else {
          // Handle unexpected status codes (e.g., 404, 500, etc.)
          alert(`Server responded with status code: ${res.status}`);
          alert(`Response data: ${JSON.stringify(res.data)}`);
        }
      } else {
        console.log("userHospitalcode is undefined");
      }
    } catch (error) {
      // Handle client-side network errors or unexpected errors
      alert(`An error occurred: ${error.message}`);
      // console.error(`An error occurred: ${error.message}`);
    }
  };

  //close the popup
  const handleClose = () => {
    setIsPopup(false)
  }
  //for printing the appointment
  const printForm = async (value) => {
    //console.log(value)
    await setPid(value.patientid)
    await setOpd(value.opdnumber)
    await setHoc(value.hospitalcode)
    await setIsPopup(true)
    // window.open(`/AppoinmentForm/${value.patientid}`, "_blank")

  }
  //to display the options
  const options = {
    selectableRows: false,
    filter: true, // Enable global filtering
    rowsPerPage: 50,
    rowsPerPageOptions: [10, 20, 30, 50],
    onRowClick: ((props) => {
      // console.log(props)
    }),
    //to download the table data
    downloadOptions: {
      filename: `OPD_${formattedDate}`, // Customize the filename here to download
      filterOptions: {
        useDisplayedColumnsOnly: true,
        useDisplayedRowsOnly: true,
      },
    },
  };
 
  let columns;
  if (isAuthorized) {
    //to display the column names
    columns = [
      {
        name: "opdnumber",
        label: "OPD Number",
        options: {
          filter: true,
          sort: true,
          customBodyRender: (value, tableMeta) => {
            // Assuming the patient id is also available in your data
            const opdnumber = tableMeta.rowData[0]; // Adjust this index as needed

            return (
              <NavLink to={`/editappointment/${opdnumber}`} className="addlink">{value}</NavLink>
            );
          }
        }
      },
      {
        name: "patientid",
        label: "Patient Id",
        options: {
          filter: true,
          sort: true,
        }
      },
      {
        name: "opdtype",
        label: "OPD Type",
        options: {
          filter: true,
          sort: true,
        }
      },
      {
        name: "patientname",
        label: "Full Name",
        options: {
          filter: true,
          sort: true
        }
      },
      {
        name: "phoneno",
        label: "Mobile No",
        options: {
          filter: true,
          sort: true,
        }
      },
      {
        name: "appointmentdate",
        label: "Appointment Time",
        options: {
          filter: true,
          sort: true,
        }
      },
      {
        name: "createdon",
        label: "Created On",
        options: {
         filter: true,
         sort: true,
         filterType: 'multiselect',
        }
       },

      {
        name: "fname",
        label: "Doctor",
        options: {
          filter: true,
          sort: true,
        }
      },
      {
        name: "hospitalcode",
        label: "Hospital Code",
        options: {
          filter: true,
          sort: true,
          customBodyRender: (value) => (value !== null && value !== undefined) ? value.toString().padStart(5, '0') : ""
        }
      },
      {
        name: "",
        label: "Action",
        options: {
          customBodyRender: (value, tableMeta, updateValue) => {
            const newD = data.find((e) => {
              return e.opdnumber == tableMeta.rowData[0]
                && e.patientid == tableMeta.rowData[1]
                && e.opdtype == tableMeta.rowData[2]
                && e.patientname == tableMeta.rowData[3]
                && e.phoneno == tableMeta.rowData[4]
                && e.appointmentdate == tableMeta.rowData[5]
                && e.createdon == tableMeta.rowData[6]
                && e.fname == tableMeta.rowData[7]
                && e.hospitalcode == tableMeta.rowData[8]
            })
            return (
              <IconButton color="primary" onClick={() => printForm(newD)}>
              <PrintIcon  />
            </IconButton>
              // <svg width="30" height="30" viewBox="0 0 24 24">
              //  <IoMdPrint onClick={() => printForm(newD)} style={{ cursor: 'pointer' }} />
              //   </svg>
              );
          }
        }
      },
      {
        name: "",
        label: "",
        options: {
          customBodyRender: (value, tableMeta, updateValue) => {
            const newD = data.find((e) => {
              return e.opdnumber == tableMeta.rowData[0]
                && e.patientid == tableMeta.rowData[1]
                && e.opdtype == tableMeta.rowData[2]
                && e.patientname == tableMeta.rowData[3]
                && e.phoneno == tableMeta.rowData[4]
                && e.appointmentdate == tableMeta.rowData[5]
                && e.createdon == tableMeta.rowData[6]
                && e.fname == tableMeta.rowData[7]
                && e.hospitalcode == tableMeta.rowData[8]
            })
            return (
              <NavLink to={`/rescheduleappointment/${newD.opdnumber}`}>
              <IconButton color="warning">
              <EventIcon />
            </IconButton>
            </NavLink>
              // <svg width="30" height="30" viewBox="0 0 24 24">
  

              // <NavLink to={`/rescheduleappointment/${newD.opdnumber}`}>
              //   <FaSyncAlt style={{ cursor: 'pointer' }} />
              // </NavLink>
              // </svg>
            );
          }
        }
      },
      {
        name: "",
        label: "",
        options: {
          customBodyRender: (value, tableMeta, updateValue) => {
            const newD = data.find((e) => {
              return e.opdnumber == tableMeta.rowData[0]
                && e.patientid == tableMeta.rowData[1]
                && e.opdtype == tableMeta.rowData[2]
                && e.patientname == tableMeta.rowData[3]
                && e.phoneno == tableMeta.rowData[4]
                && e.appointmentdate == tableMeta.rowData[5]
                && e.createdon == tableMeta.rowData[6]
                && e.fname == tableMeta.rowData[7]
            })
            return (
              <IconButton color="secondary"  onClick={() => cancelAppointment(newD.opdnumber)}>
              <DeleteIcon />
            </IconButton>
      //         <svg width="30" height="30" viewBox="0 0 24 24">
      //       <MdDelete  onClick={() => cancelAppointment(newD.opdnumber)} style={{ cursor: 'pointer' }} />
      //  </svg>
            )
          },
        },
      },
    ];
  }
  else {
    columns = [
      {
        name: "opdnumber",
        label: "OPD Number",
        options: {
          filter: true,
          sort: true,
          customBodyRender: (value, tableMeta) => {
            // Assuming the patient id is also available in your data
            const opdnumber = tableMeta.rowData[0]; // Adjust this index as needed

            return (
              <NavLink to={`/editappointment/${opdnumber}`} className="addlink">{value}</NavLink>
            );
          }
        }
      },
      {
        name: "patientid",
        label: "Patient Id",
        options: {
          filter: true,
          sort: true,
        }
      },
      {
        name: "opdtype",
        label: "OPD Type",
        options: {
          filter: true,
          sort: true,
        }
      },
      {
        name: "patientname",
        label: "Full Name",
        options: {
          filter: true,
          sort: true
        }
      },
      {
        name: "phoneno",
        label: "Mobile No",
        options: {
          filter: true,
          sort: true,
        }
      },
      {
        name: "appointmentdate",
        label: "Appointment Time",
        options: {
          filter: true,
          sort: true,
        }
      },
      {
        name: "createdon",
        label: "Created On",
        options: {
          filter: true,
          sort: true,
        }
      },
      {
        name: "fname",
        label: "Doctor",
        options: {
          filter: true,
          sort: true,
        }
      },
      {
        name: "",
        label: "Action",
        options: {
          customBodyRender: (value, tableMeta, updateValue) => {
            const newD = data.find((e) => {
              return e.opdnumber == tableMeta.rowData[0]
                && e.patientid == tableMeta.rowData[1]
                && e.opdtype == tableMeta.rowData[2]
                && e.patientname == tableMeta.rowData[3]
                && e.phoneno == tableMeta.rowData[4]
                && e.appointmentdate == tableMeta.rowData[5]
                && e.createdon == tableMeta.rowData[6]
                && e.fname == tableMeta.rowData[7]
            })
            return (
              <IconButton color="primary" onClick={() => printForm(newD)}>
              <PrintIcon  />
            </IconButton>
              );
          }
        }
      },
      {
        name: "",
        label: "",
        options: {
          customBodyRender: (value, tableMeta, updateValue) => {
            const newD = data.find((e) => {
              return e.opdnumber == tableMeta.rowData[0]
                && e.patientid == tableMeta.rowData[1]
                && e.opdtype == tableMeta.rowData[2]
                && e.patientname == tableMeta.rowData[3]
                && e.phoneno == tableMeta.rowData[4]
                && e.appointmentdate == tableMeta.rowData[5]
                && e.createdon == tableMeta.rowData[6]
                && e.fname == tableMeta.rowData[7]
            })
            return (
              <NavLink to={`/rescheduleappointment/${newD.opdnumber}`}>
              <IconButton color="warning">
              <EventIcon />
            </IconButton>
            </NavLink>
              // <svg width="30" height="30" viewBox="0 0 24 24">
  

              // <NavLink to={`/rescheduleappointment/${newD.opdrecordnumber}`}>
              //   <FaSyncAlt style={{ cursor: 'pointer' }} />
              // </NavLink>
              // </svg>
            );
          }
        }
      },

      {
        name: "",
        label: "",
        options: {
          customBodyRender: (value, tableMeta, updateValue) => {
            const newD = data.find((e) => {
              return e.opdnumber == tableMeta.rowData[0]
                && e.patientid == tableMeta.rowData[1]
                && e.opdtype == tableMeta.rowData[2]
                && e.patientname == tableMeta.rowData[3]
                && e.phoneno == tableMeta.rowData[4]
                && e.appointmentdate == tableMeta.rowData[5]
                && e.createdon == tableMeta.rowData[6]
                && e.fname == tableMeta.rowData[7]
            })
            return (
              <IconButton color="secondary"  onClick={() => cancelAppointment(newD.opdnumber)}>
              <DeleteIcon />
            </IconButton>
      //         <svg width="30" height="30" viewBox="0 0 24 24">
      //       <MdDelete  onClick={() => cancelAppointment(newD.opdnumber)} style={{ cursor: 'pointer' }} />
      //  </svg>
            );
          },
        },
      },
    ];
  }
  const currentDomain = window.location.hostname;
  return (
    <>
      <Helmet>
        <title> Appointment Records System | {currentDomain} | Hospital Management Software | Smart Solutions for Hospital Efficiency</title>
      </Helmet>
      <NavLink to='/newappointment'><button className="btn btn-success btn-sm mr-2" style={{ margin: "10px" }}>  <svg xmlns="http://www.w3.org/2000/svg" width="19" height="18" style={{ paddingRight: '4px' }} viewBox="0 0 100 100">
        <line x1="10" y1="50" x2="90" y2="50" stroke="white" stroke-width="10" />
        <line x1="50" y1="10" x2="50" y2="90" stroke="white" stroke-width="10" />
      </svg>New Appointment</button></NavLink>
      <MUIDataTable
        title={"Appointment List"}
        data={data}
        columns={columns}
        options={options}
        className="custom-mui-datatable"
      />

      {isPopup == true ?
        <>

          <Modal show={true} animation={false} onHide={handleClose} size="lg">
            <Modal.Header closeButton>
              <Modal.Title>{patientid}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <PrintTemplate pId={patientid} opd={opdnumber} hoc={hospitalcode} handleClose={handleClose} />
            </Modal.Body>
          </Modal>
        </>
        :
        ""}

    </>

  )
}

export default ListofAppointment