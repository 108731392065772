import React, { useState, useEffect, useRef } from 'react';
// import { Print, Cached, Close } from '@material-ui/icons';
import axios from "../../../helper/axios";
import { Modal } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { NavLink } from 'react-router-dom';
import { IconButton } from '@mui/material';
import MUIDataTable from "mui-datatables";
import PrintIcon from '@mui/icons-material/Print';
import { useUser } from "../../../Provider";
import { Helmet } from 'react-helmet';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import PrintTemplateBirthview from '../../../Component/PrintTemplateBirthview/PrintTemplateBirthview';
const ListViewBirth = () => {
  const {user, userHospitalcode, userrole1, isAuthRole,isAuthorized,gettokenId,token } = useUser();
  console.log(user, userHospitalcode, userrole1, isAuthRole,isAuthorized,gettokenId )
  const currentDate = new Date();
  const day = currentDate.getDate();
  const month = currentDate.getMonth() + 1; // Months are zero-indexed, so add 1
  const year = currentDate.getFullYear();
  const [dateRange, setDateRange] = useState({
    startDate: null,
    endDate: null,
    key: 'selection', // Add the 'key' property
  });
  const formatDate = (date) => {
    if (!date) return ''; // Handle the case when date is null or undefined

    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-indexed, so add 1
    const day = date.getDate().toString().padStart(2, '0');

    return `${year}-${month}-${day}`;
  };
  // Format the date as DD-MM-YYYY
  const formattedDate = `${day}-${month}-${year}`;
  const [data, setData] = useState([]);
  const [birthnumber, setbid] = useState();
  const [hospitalcode, setHoc] = useState();
  const [opdnumber, setOpd] = useState();
  const [isPopup, setIsPopup] = useState(false);
  //const token = sessionStorage.getItem("jwtToken");
  const [userRole, setRole] = useState();
  
  //when page load or refresh happen this function will got called
  useEffect(() => {
    const loadData = async () => {
      // await loadAllDetails();
      //loadAllAppointment(); will wait for loadAllDetails(); to got execute
      await loadAllBirths();
    };

    loadData();
  }, [userrole1, userHospitalcode]);
  //get all the birth details list for specific hospital
  const loadAllBirths = async () => {
        try{
        if (userHospitalcode !== undefined && userrole1 !== undefined) {
            console.log("userHospitalcode:", userHospitalcode); // Add this line
            console.log("userrole1:", userrole1); // Add this line
        const res = await axios.get("/GetBirths", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
              params: {
                hospitalcode: userHospitalcode, // Include the hospitalcode in the query parameters
                userrole: userrole1, // Include the userrole in the query parameters
              },
        });
        setData(res.data);
        console.log(res.data);
        if (res.data.length > 0) {
          // Assuming birthnumber is present in the first record
          const firstBirthId = res.data[0].birthnumber;
          setbid(firstBirthId); // Set birthnumber to the state
        }
      };}catch (error) {
        // Handle any error here.
       alert("Error deleteing hospital:", error);
      }
    } 
  //close the popup
  const handleClose = () => {
    setIsPopup(false)
  }
  //for printing the appointment
  const printForm = (value) => {
    if (value && value.hasOwnProperty('birthnumber')) {
      setbid(value.birthnumber);
      setIsPopup(true);
    } else {
      console.error('Invalid value or birthnumber is undefined:', value);
    }
  };
  //to display the options
  const options = {
    selectableRows: false,
    filter: true, // Enable global filtering
    rowsPerPage: 50,
    rowsPerPageOptions: [10, 20, 30, 50],
    onRowClick: ((props) => {
      // console.log(props)
    }),
    //to download the table data
    downloadOptions: {
      filename: `OPD_${formattedDate}`, // Customize the filename here to download
      filterOptions: {
        useDisplayedColumnsOnly: true,
        useDisplayedRowsOnly: true,
      },
    },
  };
 
  let columns;
  if (isAuthorized) {
   columns = [
    {
     name: "birthnumber",
     label: "Birthnumber",
     options: {
      filter: true,
      sort: true,
      customBodyRender: (value, tableMeta) => {
        // Assuming the patient id is also available in your data
        const birthnumber = tableMeta.rowData[0]; // Adjust this index as needed

        return (
          <NavLink to={`/updatebirthrecord/${birthnumber}`} className="addlink">{value}</NavLink>
        );
      }
     }
    },
    {
     name: "childname",
     label: "Child Name",
     options: {
      filter: true,
      sort: true,
     }
    },
    {
     name: "gender",
     label: "Gender",
     options: {
      filter: true,
      sort: true
     }
    },
    {
     name: "dob",
     label: "Date of Birth",
     options: {
      filter: true,
      sort: true,
     }
    },
    {
     name: "mothername",
     label: "Mother Name",
     options: {
      filter: true,
      sort: true,
     }
    },
    {
      name: "fathername",
      label: "Father Name",
      options: {
       filter: true,
       sort: true,
      }
     },

     {
      name: "hospitalcode",
      label: "Hospital Code",
      options: {
       filter: true,
       sort: true,
        customBodyRender: (value) => (value !== null && value !== undefined) ? value.toString().padStart(5, '0') : ""
      }
     },
     {
      name: "",
      label: "Action",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          const newD = data.find((e) => {
            return (
              e.childname === tableMeta.rowData[1] &&
              e.gender === tableMeta.rowData[2] && 
              e.dob === tableMeta.rowData[3] && 
              e.fathername === tableMeta.rowData[5] && 
              e.mothername === tableMeta.rowData[4] && 
              e.hospitalcode === tableMeta.rowData[6] && 
              e.birthnumber === tableMeta.rowData[0]
          )
          });
          console.log('newD:', newD);
          return (
            <IconButton color="primary" onClick={() => printForm(newD)}>
              <PrintIcon/>
            </IconButton>
            );
          
        },
      },
    },

  ]  
  }
  else{
    columns = [
      {
        name: "birthnumber",
        label: "Birthnumber",
        options: {
         filter: true,
         sort: true,
         customBodyRender: (value, tableMeta) => {
          // Assuming the patient id is also available in your data
          const birthnumber = tableMeta.rowData[0]; // Adjust this index as needed

          return (
            <NavLink to={`/updatebirthrecord/${birthnumber}`} className="addlink">{value}</NavLink>
          );
        }
        }
       },
      {
       name: "childname",
       label: "Child Name",
       options: {
        filter: true,
        sort: true,
  
       }
      },
      {
       name: "gender",
       label: "Gender",
       options: {
        filter: true,
        sort: true
       }
      },
      {
       name: "dob",
       label: "Date of Birth",
       options: {
        filter: true,
        sort: true,
       }
      },
      {
       name: "mothername",
       label: "Mother Name",
       options: {
        filter: true,
        sort: true,
       }
      },
   
      {
        name: "fathername",
        label: "Father Name",
        options: {
         filter: true,
         sort: true,
        }
       },
       {
        name: "",
        label: "Action",
        options: {
          customBodyRender: (value, tableMeta, updateValue) => {
            const newD = data.find((e) => {
              return (
                e.childname === tableMeta.rowData[1] &&
                e.gender === tableMeta.rowData[2] && 
                e.dob === tableMeta.rowData[3] && 
                e.fathername === tableMeta.rowData[5] && 
                e.mothername === tableMeta.rowData[4] && 
                e.birthnumber === tableMeta.rowData[0]
            )
            });
            console.log('newD:', newD);
            return (
              <IconButton color="primary" onClick={() => printForm(newD)}>
              <PrintIcon/>
            </IconButton>
              );
            
          },
        },
      },
  
    ]  
  }
  const currentDomain = window.location.hostname;
  return (
    <>
      <Helmet>
        <title> Birth Record  | {currentDomain} | Hospital Management Software | Smart Solutions for Hospital Efficiency</title>
      </Helmet>
      <NavLink to='/addbirth'><button className="btn btn-success btn-sm mr-2" style={{ margin: "10px" }}>  <svg xmlns="http://www.w3.org/2000/svg" width="19" height="18" style={{ paddingRight: '4px' }} viewBox="0 0 100 100">
        <line x1="10" y1="50" x2="90" y2="50" stroke="white" stroke-width="10" />
        <line x1="50" y1="10" x2="50" y2="90" stroke="white" stroke-width="10" />
      </svg>Add Birth Record</button></NavLink>
      <MUIDataTable
        title={"Birth Record"}
        data={data}
        columns={columns}
        options={options}
        className="custom-mui-datatable"
      />

      {isPopup == true ?
        <>

          <Modal show={true} animation={false} onHide={handleClose} size="lg">
            <Modal.Header closeButton>
              <Modal.Title>{birthnumber}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <PrintTemplateBirthview bId={birthnumber} handleClose={handleClose} />
            </Modal.Body>
          </Modal>
        </>
        :
        ""}

    </>

  )
}

export default ListViewBirth