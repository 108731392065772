import React, { useState, useEffect } from 'react';
import { Modal, Backdrop, Fade, Button, TextField, Autocomplete, InputAdornment, IconButton } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import axios from "../../../helper/axios";
import { useFormik } from "formik";
import { useUser } from "../../../Provider";
import { ToastContainer, toast } from 'react-toastify';
import { useHistory } from "react-router-dom";
import Searchbar from '../../../Component/Searchbar';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';


const initialValues = {
  // bedname: "",
  // bedtypeid: "",
  medicinemanufacturerid: "",
  hospitalcode: "",
  total: "",
  discountPercentage: "",
};

const EditPurchase = () => {
  const { purchaseid } = useParams();
  
    // Use the 'useUser' hook to get user-related information
    const { user, userHospitalcode, userrole1, isAuthRole, isAuthorized, gettokenId, token } = useUser();
    const history = useHistory();
    let Showdate = new Date();
    let ShowTodaydate =
      Showdate.getFullYear() +
      "-" +
      (Showdate.getMonth() + 1) +
      "-" +
      Showdate.getDate();
      const [selectedCategory, setSelectedCategory] = useState("");
    const [currentDate, setCurrentDate] = useState(ShowTodaydate);
    const [category1, setCategory] = useState([]);
    const [data, setData] = useState([]);
    const [supplier, setSupplier] = useState([]);
    const [medicines, setMedicines] = useState([{ medicinecategoryid: '', medicinecode: '', batchno: '',
     expirydate: '',mrp:"",packingqty:"", quantity: '',purchaseprice:"", saleprice: '',tax:"",amount:"" }]);
    const [medicineNames, setMedicineNames] = useState({});
    const [total, setTotal] = useState(0); // Initialize total to 0
      const [extraCharge, setExtraCharge] = useState(0);
      const [discountBy, setDiscountBy] = useState("");
      const [discountPercentage, setDiscountPercentage] = useState(0);
      const [finalAmount, setFinalAmount] = useState(0);
    // Function to handle changes in medicine-related fields


      // Formik hook for form handling
  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setValues,
    resetForm,
  } = useFormik({
    initialValues,
    // validationSchema: getValidationSchema(), // Apply validation schema
    onSubmit: async (values, action) => {
      try {
        // Create form data for submission
        const data = new FormData();
        data.append("document", JSON.stringify(values));
        // data.append("currentDate", JSON.stringify(currentDate));
        data.append("gettokenId", JSON.stringify(gettokenId));
        data.append("userHospitalcode", JSON.stringify(userHospitalcode));
        data.append('hospitalcode', values.hospitalcode); 
        data.append("medicines", JSON.stringify(medicines));
        data.append("finalAmount", JSON.stringify(finalAmount));
        data.append("currentDate", JSON.stringify(currentDate));
        data.append("extraCharge", JSON.stringify(extraCharge));
        data.append("discountBy", JSON.stringify(discountBy));
        data.append("discountP", JSON.stringify(discountBy));
        data.append("total", JSON.stringify(total));
        data.append("discountPercentage", JSON.stringify(discountPercentage));
        
        // data.append("supplier", JSON.stringify(medicinemanufacturerid));
        data.append("medicineNames", JSON.stringify(medicineNames));
      
    
console.log(JSON.stringify(medicines))
        // Make API call to add bed
        const response = await axios.post(`/updatepurchase/${purchaseid }`, data, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        });

        if (response.status === 200) {
          return history.push("/pharmacypurchase");
        }
      } catch (error) {
        // console.log(error)
        toast("Something Went Wrong")
      }
    },
  });
  const getPurchaseDetails = async () => {
    try {
      const res = await axios.get(`/getpharmacypurchases/${purchaseid}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
  
      if (res.status === 200) {
        // If the request is successful (status code 200), set the patient data.
        setValues(res.data[0]);
      } else {
        // Handle unexpected response status codes, e.g., 404 or 500.
        alert(`Unexpected status code: ${res.status}`);
      }
    } catch (error) {
      // Handle network errors or other exceptions.
      alert(`An error occurred: ${error.message}`);
      // Optionally, you can set an error state or display an error message to the user.
    }
  };
  const getPharmacyBillDetails = async () => {
    try {
      const res = await axios.get(
        `/pharmacypurchase/${purchaseid}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
  
      if (res.status === 200) {
        // If the request is successful (status code 200), set the bill items data.
        console.log(res.data);
        // Set the medicines data
        setMedicines(res.data);
        for (let i = 0; i < res.data.length; i++) {
          const medicineItem = res.data[i];
          if (medicineItem.medicinecategoryid) {
            await fetchMedicineNames(medicineItem.medicinecategoryid, i);
          }
        }
      } else {
        // Handle unexpected response status codes, e.g., 404 or 500.
        alert(`Unexpected status code: ${res.status}`);
      }
    } catch (error) {
      // Handle network errors or other exceptions.
      alert(`An error occurred: ${error.message}`);
      // Optionally, you can set an error state or display an error message to the user.
    }
  };
  // Function to load hospital codes
  const loadHospitalcode = async () => {
    if (userHospitalcode !== undefined && userrole1 !== undefined) {
      const res = await axios.get("/gethospitalcode", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          
        },
      });
      setData(res.data);
     // console.log("kjdfbndkjfv",res.data)
    };
  }
    
    const handleChanges = (e, index, field, newValue) => {
      let value;
      if (newValue && newValue.medicinecode) {
        value = newValue.medicinecode;
      } else {
        value = e.target.value;
      }
      setMedicines((prevMedicines) => {
        const updatedMedicines = prevMedicines.map((medicine, i) => {
          if (i === index) {
            return { ...medicine, [field]: value };
          }
          return medicine;
        });

          // Calculate the amount for the medicine at the given index
          const updatedMedicine = updatedMedicines[index];
          const quantity = parseFloat(updatedMedicine.quantity) || 0;
          const purchaseprice = parseFloat(updatedMedicine.purchaseprice) || 0;
          const tax = parseFloat(updatedMedicine.tax) || 0;
          const amount = (quantity * purchaseprice) + ((quantity * purchaseprice * tax) / 100); // Calculate amount with tax
          updatedMedicine.amount = amount.toFixed(2); // Round to 2 decimal places
          return updatedMedicines;
      });
  };
    // // Function to handle category change for a specific row
   
    const handleCategoryChangeForRow = async (newValue, index, key) => {
      console.log("newValue", newValue)
      // Extract medicinecategoryid from the newValue object
      const selectedCategoryId = newValue ? newValue.medicinecategoryid : null;
      console.log("selectedCategoryId:", selectedCategoryId); // Check if selectedCategoryId is correctly extracted
      setSelectedCategory(selectedCategoryId);
      setMedicines((prevMedicines) => {
        const updatedMedicines = [...prevMedicines];
        if (updatedMedicines[index]) {
          updatedMedicines[index] = {
            ...updatedMedicines[index],
            [key]: selectedCategoryId, // Update the specified key with the selected category id
            medicinecode: "", // Assuming you want to reset medicinecode when category changes
            batchno:"", expirydate: '',mrp:"",packingqty:"", quantity: '',purchaseprice:"", saleprice: '',tax:"",amount:""
          };
        }
        return updatedMedicines;
      });
      await fetchMedicineNames(selectedCategoryId, index);
    };
    // Function to handle deletion of a specific row
    const handleDeleteRow = (index) => {
      setMedicines((prevMedicines) => {
        const updatedMedicines = prevMedicines.filter((_medicine, i) => i !== index);
        return updatedMedicines;
      });
    
      // Fetch medicine names based on the remaining medicine categories
      setMedicines((updatedMedicines) => {
        updatedMedicines.forEach(async (medicine, i) => {
          await fetchMedicineNames(medicine.medicinecategoryid, i);
        });
        return updatedMedicines;
      });
    };
    // Function to fetch medicine category names based on hospital code
    const fetchMedicineCateoryNames = async (selectedHospitalCode) => {
        try {
            const response = await axios.get("/medicinecategory", {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                params: {
                    hospitalcode: selectedHospitalCode,
                },
            });

            if (response.status === 200) {
                setCategory(response.data);
            } else {
                console.error(`Failed to fetch available floor numbers with status: ${response.status}`);
            }
        } catch (error) {
            console.error('Error fetching available floor numbers:', error.message);
        }
    };
    // Function to fetch medicine names based on selected category
    const fetchMedicineNames = async ( selectedCategory, index) => {
        try {
            const response = await axios.get("/medicinenames", {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                params: {
                    hospitalcode:values.hospitalcode ,
                    category: selectedCategory,
                },
            });
console.log(response.data)
            if (response.status === 200) {
                setMedicineNames((prevMedicineNames) => {
                    const updatedMedicineNames = { ...prevMedicineNames };
                    updatedMedicineNames[index] = response.data;
                    return updatedMedicineNames;
                });
            } else {
                console.error(`Failed to fetch medicine names with status: ${response.status}`);
            }
        } catch (error) {
            console.error('Error fetching medicine names:', error.message);
        }
    };

  // Function to load all medicine manufacturers based on selected hospital code
const loadAllMedicineManufacturer = async () => {
  try {
    const res = await axios.get("/getmedicinemanufacturer", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        hospitalcode: values.hospitalcode ,
      },
    });

    if (res.status === 200) {
      setSupplier(res.data);
    } else {
      console.error(`Failed to fetch supplier names with status: ${res.status}`);
    }
  } catch (error) {
    console.error('Error fetching supplier names:', error.message);
  }
};

useEffect(() => {
  const loadData = async (index) => {
    await loadHospitalcode();
    await getPurchaseDetails();
    await getPharmacyBillDetails();
    const selectedHospitalCode = values.hospitalcode ;
   await fetchMedicineCateoryNames(selectedHospitalCode);
   await fetchMedicineNames(selectedHospitalCode)
    // Assuming you want to fetch medicine names for each medicine category
    loadAllMedicineManufacturer(selectedHospitalCode);
  
  };
  loadData();
}, [userHospitalcode, userrole1, isAuthorized, values.hospitalcode]);

useEffect(() => {
  const totalAmount = medicines.reduce((acc, curr) => acc + parseFloat(curr.amount || 0), 0);
  setTotal(totalAmount);
}, [medicines]);

useEffect(() => {
  const calculateFinalAmount = () => {
      let calculatedFinalAmount = total + parseFloat(extraCharge) - (total * parseFloat(discountPercentage) / 100);
      setFinalAmount(calculatedFinalAmount.toFixed(2));
  };
  calculateFinalAmount();
}, [total, extraCharge, discountPercentage]);
    return (
        <div>
      <form onSubmit={handleSubmit} >
      <div className="patient-new primary-info">
        <div className="row  ml-4">
          <div className="col-md-12 ">
          <div className="row">
                      
          <div className="col-md-6">
                <div className="row">
                {isAuthorized ? (
                   
                      <div className="row">
                        <div className="col-md-4">
                          <label htmlFor="hospitalcode" className="col-form-label leftone">
                            Hospital Code
                          </label>
                        </div>
                        <div className="col-md-8">
                          {isAuthorized ? (
                            <input
                              type="text"
                              autoComplete="off"
                              name="hospitalcode"
                              id="hospitalcode"
                              placeholder="Hospital Code"
                              value={values.hospitalcode}
                             // onChange={handleChange}
                              //onBlur={handleBlur}
                             // onSearchChange={(e) => handleSearchChange(e.target.value)}
                              style={{ width: '73%', height: '32px', border: '1px solid #ced4da', color: '#495057' }}
                            />
                            
                          ) : (
                            <input
                              type="text"
                              name="hospitalcode"
                              id="hospitalcode"
                              value={userHospitalcode}
                              disabled={true}
                              className="form-control "
                            />
                          )}
                          {isAuthorized && errors.hospitalcode && touched.hospitalcode ? (
                            <p className="form-erroremployee">{errors.hospitalcode}</p>
                          ) : null}
                          </div>
                          
                        </div>
                      
                    
                  ) : null}
                  </div>
                  </div>
       <div className="col-md-6">
    <div className="row">
      <div className="col-md-4">
        <label className="col-form-label">Select Supplier</label>
      </div>
      <div className="col-md-4">
        <select
          type="text"
          autoComplete="off"
          name="medicinemanufacturerid"
          id="medicinemanufacturerid"
          placeholder="Select medicinemanufacturerid"
          value={values.medicinemanufacturerid}
          onChange={handleChange}
          style={{ width: '100%', height: '32px', border: '1px solid #ced4da', color: '#495057' }}
        >
          <option>Select</option>
          {/* Map over the supplier state to render the options */}
          {supplier.map((supplierItem) => (
            <option key={supplierItem.medicinemanufacturerid} value={supplierItem.medicinemanufacturerid}>
              {supplierItem.suppliername}
            </option>
          ))}
        </select>
      </div>
    </div>
  </div>
 
                  </div>
                 
          
          </div>
           
            </div>
            </div>
        <div>
            <table>
                <thead>
                    <tr style={{ paddingRight: '10px' }}>
                        <th>Medicine Category</th>
                        <th>Medicine</th>
                        <th>Batch No </th>
                        <th>Expiry Date</th>
                        <th>MRP ($)</th>
                        <th>Sale Price ($)</th>
                        <th>Packing Qty</th>
                        <th>Quantity</th>
                        <th>Purchase Price ($) </th>
                        <th>Tax</th>
                        <th>Amount</th>
                        <th>Delete</th>
                    </tr>
                </thead>
                <tbody>
                    {medicines.map((medicine, index) => (
                        <tr key={index}>
                            <td style={{ paddingRight: '10px' }}>
                                <Autocomplete
                          freeSolo
                          id="free-solo"
                          options={category1}
                          getOptionLabel={(option) => option.medicinecategory || ""}
                          value={category1.find((option) => option.medicinecategoryid === medicine.medicinecategoryid) || ""}
                          onChange={(event, newValue) => handleCategoryChangeForRow(newValue, index, "medicinecategoryid")}
                          // open={open}
                          // onOpen={() => setOpen(true)}
                          // onClose={() => setOpen(false)}
                          renderInput={(params) =>
                            <TextField
                              {...params}
                              placeholder="Select Category"
                              InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <ExpandMoreIcon />
                                    {/* <IconButton onClick={handleopen}>
              <ExpandMoreIcon />
            </IconButton> */}
                                  </InputAdornment>
                                ),
                              }}
                            />
                          }
                        />
                            </td>
                            <td>
                              
                                <Autocomplete
                          freeSolo
                          id={`free-solo-${index}`}
                          options={medicineNames[index] || []} // Use medicineNames[index] as options
                          getOptionLabel={(option) => option.medicinename || ""}
                          value={medicineNames[index]?.find((option) => option.medicinecode === medicine.medicinecode) || ""}
                          onChange={(event, newValue) => handleChanges(event, index, "medicinecode", newValue)}
                         
                          renderInput={(params) =>
                            <TextField
                              {...params}
                              placeholder="Select Medicine"
                              InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                  <InputAdornment position="end">
                                 
                                      <ExpandMoreIcon />
                                  
                                  </InputAdornment>
                                ),
                              }}
                            />
                          }
                        />

                            </td>
                            <td>
                                <input
                                    type="text"
                                    autoComplete="off"
                                    name="batchno"
                                    id="batchno"
                                    placeholder="batchno"
                                    value={medicine.batchno}
                                    onChange={(e) => handleChanges(e, index, "batchno")}
                                    style={{ width: '100%', height: '32px', border: '1px solid #ced4da ', color: '#495057' }}
                                   />
                                  
                            </td>
                            <td>
                                <input
                                    type="month"
                                    autoComplete="off"
                                    name="expirydate"
                                    id="expirydate"
                                    placeholder="MM/YYYY"
                                    value={medicine.expirydate}
                                    onChange={(e) => handleChanges(e, index, "expirydate")}

                                    style={{ width: '100%', height: '32px', border: '1px solid #ced4da ', color: '#495057' }}
                               />
                                    {/* <option>Select</option>
                                    
                                </select> */}
                            </td>
                            <td>
                                <input
                                    type="text"
                                    autoComplete="off"
                                    name="mrp"
                                    id="mrp"
                                    placeholder="mrp"
                                    value={medicine.mrp}
                                    onChange={(e) => handleChanges(e, index, "mrp")}

                                    style={{ width: '100%', height: '32px', border: '1px solid #ced4da ', color: '#495057' }}
                                />
                            </td>
                            <td>
                                <input
                                    type="text"
                                    autoComplete="off"
                                    name="saleprice"
                                    id="saleprice"
                                    placeholder="saleprice"
                                    value={medicine.saleprice}
                                    onChange={(e) => handleChanges(e, index, "saleprice")}

                                    style={{ width: '100%', height: '32px', border: '1px solid #ced4da ', color: '#495057' }}
                                />
                            </td>
                            <td>
                                <input
                                    type="text"
                                    autoComplete="off"
                                    name="packingqty"
                                    id="packingqty"
                                    placeholder="packingqty"
                                    value={medicine.packingqty}
                                    onChange={(e) => handleChanges(e, index, "packingqty")}
                                    style={{ width: '100%', height: '32px', border: '1px solid #ced4da ', color: '#495057' }}
                                />
                            </td>
                            <td>
                                <input
                                    type="text"
                                    autoComplete="off"
                                    name="quantity"
                                    id="quantity"
                                    placeholder="quantity"
                                    value={medicine.quantity}
                                    onChange={(e) => handleChanges(e, index, "quantity")}
                                    style={{ width: '100%', height: '32px', border: '1px solid #ced4da ', color: '#495057' }}
                                />
                            </td>
                            <td>
                                <input
                                    type="text"
                                    autoComplete="off"
                                    name="purchaseprice"
                                    id="purchaseprice"
                                    placeholder="purchaseprice"
                                    value={medicine.purchaseprice}
                                    onChange={(e) => handleChanges(e, index, "purchaseprice")}

                                    style={{ width: '100%', height: '32px', border: '1px solid #ced4da ', color: '#495057' }}
                                />
                            </td>
                            <td>
                                <input
                                    type="text"
                                    autoComplete="off"
                                    name="tax"
                                    id="tax"
                                    placeholder="tax"
                                    value={medicine.tax}
                                    onChange={(e) => handleChanges(e, index, "tax")}

                                    style={{ width: '100%', height: '32px', border: '1px solid #ced4da ', color: '#495057' }}
                                />
                            </td>
                            <td>
                                <input
                                    type="text"
                                    autoComplete="off"
                                    name="amount"
                                    id="amount"
                                    placeholder="amount"
                                    value={medicine.amount}
                                    onChange={(e) => handleChanges(e, index, "amount")}

                                    style={{ width: '100%', height: '32px', border: '1px solid #ced4da ', color: '#495057' }}
                                />
                            </td>
                            <td>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    size="small"
                                    style={{ width: 'auto' }}
                                    onClick={(e) => handleDeleteRow(index)}
                                >
                                    Delete
                                </Button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <Button variant="contained" color="primary" size="small" style={{ width: 'auto' }}
                onClick={() => setMedicines([...medicines, { medicinecategoryid: '', medicinename: '', batchno: '',
                expirydate: '',mrp:"",packingqty:"", quantity: '',purchaseprice:"", saleprice: '',tax:"",amount:"" }])}>
                Add Medicine
            </Button>
            <div className="col-md-4" style={{marginLeft: "64%"}}>
            <div className="row ml-4">
              <div className="col-md-12 mt-3">
                <div className="row">
                <div className="col-md-1"></div>
                  <div className="col-md-5">
                    <label className="col-form-label">Total</label>
                  </div>
                  <div className="col-md-6">
                    <input
                      style={{ width: "100%" }}
                      type="number"
                      autoComplete="off"
                      name="total"
                      id="total"
                      placeholder="Total"
                      className="form-control"
                      value={total}
                      onChange={(e) =>
                        setTotal(parseFloat(e.target.value) || 0)
                      }
                    />
                  </div>
                </div>
              </div>

              <div className="col-md-12 mt-3" >
                <div className="row">
                <div className="col-md-1"></div>
                  <div className="col-md-5">
                    <label className="col-form-label">Extra Charge</label>
                  </div>
                  <div className="col-md-6">
                    <input
                      style={{ width: "100%" }}
                      type="number"
                      autoComplete="off"
                      name="extraCharge"
                      id="extraCharge"
                      className="form-control"
                      placeholder="Extra Charge"
                      value={extraCharge}
                      onChange={(e) =>
                        setExtraCharge(parseFloat(e.target.value) || 0)
                      }
                    />
                  </div>
                </div>
              </div>

              <div className="col-md-12 mt-3">
                <div className="row">
                <div className="col-md-1"></div>
                  <div className="col-md-5">
                    <label className="col-form-label">Discount By</label>
                  </div>
                  <div className="col-md-6">
                    <input
                      style={{ width: "100%" }}
                      type="text"
                      autoComplete="off"
                      name="discountBy"
                      id="discountBy"
                      className="form-control"
                      placeholder="Discount By"
                      value={discountBy || ""} // Access the value with the 'discountBy' key
                    //   onChange={(e) => handleEdit(e)}
                    />
                  </div>
                </div>
              </div>

              <div className="col-md-12 mt-3">
                <div className="row">
                <div className="col-md-1"></div>
                  <div className="col-md-5">
                    <label className="col-form-label">Discount %</label>
                  </div>
                  <div className="col-md-6">
                    <input
                      style={{ width: "100%" }}
                      type="number"
                      autoComplete="off"
                      name="discountPercentage"
                      id="discountPercentage"
                      className="form-control"
                      placeholder="Discount Percentage"
                      value={discountPercentage}
                      onChange={(e) =>
                        setDiscountPercentage(parseFloat(e.target.value) || 0)
                      }
                    />
                  </div>
                </div>
              </div>

              <div className="col-md-12 mt-3">
                <div className="row">
                <div className="col-md-1"></div>
                  <div className="col-md-5">
                    <label className="col-form-label">Final Amount</label>
                  </div>
                  <div className="col-md-6">
                    <input
                      style={{ width: "100%" }}
                      type="number"
                      autoComplete="off"
                      name="finalAmount"
                      id="finalAmount"
                      className="form-control"
                      placeholder="Final Amount"
                      value={finalAmount}
                      readOnly // Assuming this is a calculated field
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-11 mt-6 text-center">
            <Button type="submit" variant="contained" color="primary" size="small" style={{ width: 'auto' }}>
              Submit
            </Button>
          </div>
        </div>
        </form>
    </div>
    )
}

export default EditPurchase