import React, { useState, useEffect } from "react";
import axios from "../../../helper/axios";
import Autocomplete from "@mui/material/Autocomplete";
import { useFormik } from "formik";
import TextField from "@mui/material/TextField";
import { NavLink, useHistory, useParams } from "react-router-dom/cjs/react-router-dom";
import { newappointmentvalidation } from "../../../helper/schemas";
import "bootstrap/dist/css/bootstrap.min.css";
import { Table, Modal, Form } from "react-bootstrap";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useUser } from "../../../Provider";
import { Typography, Button, } from "@mui/material";
const initialValues = {

};
const Chargesform = ({ onChargesAdded }) => {
  const { admitnumber } = useParams();
  const [Ipddata, setIpdData] = useState();
  const [isFormVisible, setFormVisibility] = useState(false);
  const { user, userHospitalcode, userrole1, isAuthRole, isAuthorized, gettokenId, token } = useUser();
  // console.log(token)
  const history = useHistory();
  let Showdate = new Date();
  let ShowTodaydate =
    Showdate.getFullYear() +
    "-" +
    (Showdate.getMonth() + 1) +
    "-" +
    Showdate.getDate();
  const [currentDate, setCurrentDate] = useState(ShowTodaydate);
  const itemTemp = {
    chargecode: "",
    chargename: "",
    chargeamount: "",
    tax: 0,
    chragequantity: 1,
  };
  const handlePayment = () => {
    setFormVisibility(!isFormVisible); // Toggle form visibility
  };
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [autocompleteValue, setAutocompleteValue] = useState('');
  const [addchargesname, setAddchargesname] = useState([itemTemp]); //addchargesname
  const [itemList, setItemList] = useState([]); // State to hold charge types
  const [allItemList, setAllItemList] = useState([]); // State to hold charge types
  const [total, setTotal] = useState("");
  const [extraCharge, setExtraCharge] = useState(0);
  const [discountBy, setDiscountBy] = useState("");
  const [discountPercentage, setDiscountPercentage] = useState("");
  const [finalAmount, setFinalAmount] = useState("");
  const handleEdit = (e) => {
    // Use the spread operator to merge the existing state with the new value.
    setDiscountBy({ ...discountBy, [e.target.name]: e.target.value, });
  };
  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setValues,
    resetForm,
  } = useFormik({
    initialValues,
    // validationSchema: newappointmentvalidation,
    onSubmit: async (values, action) => {
      if (!autocompleteValue) { // Check if Autocomplete value is empty
        toast.error("Charge Name field can't be empty!"); // Show error message
        return; // Exit function
      }
      try {
        if (gettokenId !== undefined) {
          const data = new FormData();
          //   data.append("document", JSON.stringify(values));
          data.append("charge", JSON.stringify(addchargesname));
          data.append("rowSums", JSON.stringify(rowSums));
          data.append("extraCharge", JSON.stringify(extraCharge));
          data.append("discountBy", JSON.stringify(discountBy));
          data.append("discountPercentage", JSON.stringify(discountPercentage));
          data.append("finalAmount", JSON.stringify(finalAmount));
          data.append("currentDate", JSON.stringify(currentDate));
          data.append("total", JSON.stringify(total));
          data.append("gettokenId", JSON.stringify(gettokenId));
          data.append("userHospitalcode", JSON.stringify(userHospitalcode));
          data.append("Ipddata", JSON.stringify(Ipddata));

          //   console.log(JSON.stringify(userHospitalcode))
          //    console.log(JSON.stringify(gettokenId))
          console.log(JSON.stringify(addchargesname))
          const response = await axios.post(`/ipdcharges/${admitnumber}`, data, {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "multipart/form-data",
            },
          });
          if (response.status === 200) {
            onChargesAdded();
            toast.success("Data Saved")
            setExtraCharge("");
            setDiscountBy("");
            setDiscountPercentage("");
            setFinalAmount("");
            setTotal("");
            setAddchargesname([itemTemp])
          }

        }
      } catch (err) {
        setSuccess(false);
        toast.error("Something Went Wrong")
        setError(true);
      }
      action.resetForm();
    }
  },
  );
  const addItemrow = () => {
    setAddchargesname([...addchargesname, itemTemp]);
  };
  const onChange = (e, index) => {
    const { name, value } = e.target;
    const updateUser = [...addchargesname];
    const [fieldName, fieldIndex] = name.split('_');
    const itemIndex = parseInt(fieldIndex, 10);
    updateUser[itemIndex] = {
      ...updateUser[itemIndex],
      [fieldName]: value,
    };
    // console.log(updateUser)
    setAddchargesname(updateUser);

    // Recalculate the final amount whenever the tax changes
    calculateFinalAmount(updateUser);
  };
  const handleSearchChangeType = async (value, index) => {
    setAutocompleteValue(value); // Update the Autocomplete value
    // console.log(index);
    const itemChargeData = allItemList.find((e) => e.chargename == value);
    let newArr = [...addchargesname];
    newArr[index] = {
      chargecode: itemChargeData.chargecode,
      chargename: itemChargeData.chargename,
      chargeamount: parseFloat(itemChargeData.chargeamount),
      tax: parseFloat(itemChargeData.tax),
      chragequantity: parseFloat(itemChargeData.chragequantity),
    };
    // console.log(newArr)
    setAddchargesname(newArr);
    let totalSum = 0;
    newArr.forEach((e) => {
      const chargeamount = parseFloat(e.chargeamount);
      const tax = parseFloat(e.tax);
      const chragequantity = parseFloat(e.chragequantity) || 1; // Use chragequantity from the item or default to 1
      const taxAmount = (chargeamount * (tax / 100)); // chargeamount as tax
      totalSum += (chargeamount + taxAmount) * chragequantity;
      //console.log(totalSum)
    });
    setTotal(totalSum);
  };
  const GetAdmitPatientId = async () => {
    try {
      if (userHospitalcode !== undefined && userrole1 !== undefined) {
        const res = await axios.get(`/getIPDhospitalcode/${admitnumber}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: {
            hospitalcode: userHospitalcode,
            userrole: userrole1,
          },
        });
        console.log(res.data[0])
        if (res.status === 200) {
          setIpdData(res.data[0]);
        } else {
          alert(`Unexpected status code: ${res.status}`);
        }
      } else {
        console.log("userHospitalcode is undefined");
      }
    } catch (error) {
      alert(`An error occurred: ${error.message}`);
    }
  };
  const loadAllCharges = async (data) => {
    if (userHospitalcode !== undefined && userrole1 !== undefined) {
      const res = await axios.get("/AllChargeTypes", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          hospitalcode: userHospitalcode, // Include the hospitalcode in the query parameters
          userrole: userrole1, // Include the userrole in the query parameters
        },
      });
      setAllItemList(res.data);
      res.data.forEach((element) => {
        setItemList((oldArr) => [...oldArr, element.chargename]);
      });
    }
  };
  //get all hospital code
  const loadHospitalcode = async () => {
    //when userHospitalcode and userrole1 is not undefined then execute /gethospitalcode
    if (userHospitalcode !== undefined && userrole1 !== undefined) {
      const res = await axios.get("/gethospitalcode", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          hospitalcode: userHospitalcode, // Include the hospitalcode in the query parameters
          userrole: userrole1, // Include the userrole in the query parameters
        },
      });
      //console.log(res.data);
    };
  }
  useEffect(() => {
    const loadData = async () => {
      await loadHospitalcode();
    };
    loadData();
    GetAdmitPatientId();
    loadAllCharges();
  }, [userHospitalcode, userrole1, isAuthorized]);

  useEffect(() => {
    if (addchargesname.length > 0) {
      calculateFinalAmount(addchargesname);
    }
  }, [total, extraCharge, discountBy, discountPercentage]);
  const calculateRowSum = (item) => {
    const chargeamount = parseFloat(item.chargeamount) || 0;
    const tax = parseFloat(item.tax) || 0;
    const chragequantity = parseFloat(item.chragequantity) || 1;
    const taxAmount = (chargeamount * (tax / 100));
    return (chargeamount + taxAmount) * chragequantity;
  };
  const rowSums = addchargesname.map((item) => calculateRowSum(item));
  const calculateFinalAmount = (updatedInventory) => {
    const totalValue = updatedInventory.reduce((acc, item) => {
      const chargeamount = parseFloat(item.chargeamount) || 0;
      const tax = parseFloat(item.tax) || 0;
      const chragequantity = parseFloat(item.chragequantity) || 1;
      const taxAmount = (chargeamount * (tax / 100));
      return acc + (chargeamount + taxAmount) * chragequantity;
    }, 0);
    setTotal(totalValue)
    const extraChargeValue = parseFloat(extraCharge) || 0;
    const discountPercentageValue = parseFloat(discountPercentage) || 0;
    const discountAmount = (totalValue + extraChargeValue) * (discountPercentageValue / 100);
    const subtotal = totalValue + extraChargeValue - discountAmount;
    const newFinalAmount = Math.floor(subtotal);
    setFinalAmount(newFinalAmount);
  };

  const deleteChargeItem = (index) => {

    let tempAmount = addchargesname[index]

    setAddchargesname(oldData => {
      return oldData.filter((_, i) => i !== index)
    })
    setTotal(total - tempAmount.chargeamount)
  }

  return (
    <div >
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography variant="h6" style={{ padding: '20px' }}>
          Add Charges
        </Typography>
        <Button variant="contained" color="primary" onClick={handlePayment}>
          {isFormVisible ? 'Close Form' : 'Add charges'}
        </Button>
      </div>
      {isFormVisible && (<form onSubmit={handleSubmit}>


        <div className="row pt-2 mt-3">
          <div className="col-md-8">
            <Table id="contacttable" bordered hover>
              <thead>
                <tr>
                  <th style={{ fontSize: "14px" }}>Sl No</th>

                  <th style={{ fontSize: "14px" }}>Charge Name</th>
                  <th style={{ fontSize: "14px" }}> Quanitity</th>
                  <th style={{ fontSize: "14px" }}> Charge Cost</th>
                  <th style={{ fontSize: "14px" }}>Tax</th>
                  <th style={{ fontSize: "14px" }}>Sum</th>
                  <th style={{ fontSize: "14px" }}>Delete</th>
                </tr>
              </thead>
              <tbody>
                {/* dynamic table */}

                {addchargesname.map((user, index) => {
                  const rowSum = calculateRowSum(user);
                  return (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td className="remove">
                        <Autocomplete
                          freeSolo
                          id="free-solo"
                          options={itemList}
                          sx={{ width: 300 }}
                          onChange={(event, value) =>
                            handleSearchChangeType(value, index)
                          }
                          // onClick={console.log()}
                          renderInput={(params) => (
                            <TextField
                              style={{
                                border: "none"
                              }}
                              variant="outlined"
                              {...params}

                              o
                            />
                          )}
                        />
                      </td>
                      <td>
                        <input
                          className="inputtable mt-2"
                          style={{ width: "80%" }}
                          name={`chragequantity_${index}`} // Use a unique name for each tax input
                          onChange={(e) => onChange(e, index)}
                          value={user.chragequantity}
                          placeholder="Enter Quantity"
                        />
                      </td>
                      <td>
                        <input
                          className="inputtable mt-2"
                          style={{ width: "100%", border: "none" }}
                          name="Cost"
                          value={user.chargeamount}
                          readOnly
                        />
                      </td>

                      <td>
                        <input
                          className="inputtable mt-2"
                          style={{ width: "80%" }}
                          name={`tax_${index}`} // Use a unique name for each tax input
                          onChange={(e) => onChange(e, index)}
                          value={user.tax}
                          placeholder="Enter tax value"
                        />
                      </td>
                      <td>{rowSum.toFixed(2)}</td>
                      <th>
                        {/* <IoTrashOutline onClick={() => deleteRow(index)} /> */}
                        <button className="btn btn-danger btn-sm" onClick={() => deleteChargeItem(index)}>Delete</button>
                      </th>
                      <input
                        type="hidden"
                        className="inputtable mt-3"
                        name="Quantity"
                        onChange={(e) => onChange(e, index)}
                        value={user.Quantity}
                      />
                    </tr>
                  );
                })}
              </tbody>
            </Table>
            {/* TO ADD A NEW A ITEM */}
            <div className="link">
              <NavLink
                to="#"
                className="addlink"
                onClick={addItemrow}
              ><button className="btn btn-success btn-sm mr-2">
                  Add New Line</button>
              </NavLink>
            </div>
          </div>

          <div className="col-md-4">
            <div className="row ml-4">
              <div className="col-md-12 mt-3">
                <div className="row">
                  <div className="col-md-1"></div>
                  <div className="col-md-5">
                    <label className="col-form-label">Total</label>
                  </div>
                  <div className="col-md-6">
                    <input
                      style={{ width: "100%" }}
                      type="number"
                      autoComplete="off"
                      name="total"
                      id="total"
                      placeholder="Total"
                      className="form-control"
                      value={total}
                      onChange={(e) =>
                        setTotal(parseFloat(e.target.value) || 0)
                      }
                    />
                  </div>
                </div>
              </div>

              <div className="col-md-12 mt-3">
                <div className="row">
                  <div className="col-md-1"></div>
                  <div className="col-md-5">
                    <label className="col-form-label">Extra Charge</label>
                  </div>
                  <div className="col-md-6">
                    <input
                      style={{ width: "100%" }}
                      type="number"
                      autoComplete="off"
                      name="extraCharge"
                      id="extraCharge"
                      className="form-control"
                      placeholder="Extra Charge"
                      value={extraCharge}
                      onChange={(e) =>
                        setExtraCharge(parseFloat(e.target.value) || 0)
                      }
                    />
                  </div>
                </div>
              </div>

              <div className="col-md-12 mt-3">
                <div className="row">
                  <div className="col-md-1"></div>
                  <div className="col-md-5">
                    <label className="col-form-label">Discount By</label>
                  </div>
                  <div className="col-md-6">
                    <input
                      style={{ width: "100%" }}
                      type="text"
                      autoComplete="off"
                      name="discountBy"
                      id="discountBy"
                      className="form-control"
                      placeholder="Discount By"
                      value={discountBy.discountBy || ""} // Access the value with the 'discountBy' key
                      onChange={(e) => handleEdit(e)}
                    />
                  </div>
                </div>
              </div>

              <div className="col-md-12 mt-3">
                <div className="row">
                  <div className="col-md-1"></div>
                  <div className="col-md-5">
                    <label className="col-form-label">Discount %</label>
                  </div>
                  <div className="col-md-6">
                    <input
                      style={{ width: "100%" }}
                      type="number"
                      autoComplete="off"
                      name="discountPercentage"
                      id="discountPercentage"
                      className="form-control"
                      placeholder="Discount Percentage"
                      value={discountPercentage}
                      onChange={(e) =>
                        setDiscountPercentage(parseFloat(e.target.value) || 0)
                      }
                    />
                  </div>
                </div>
              </div>

              <div className="col-md-12 mt-3">
                <div className="row">
                  <div className="col-md-1"></div>
                  <div className="col-md-5">
                    <label className="col-form-label">Final Amount</label>
                  </div>
                  <div className="col-md-6">
                    <input
                      style={{ width: "100%" }}
                      type="number"
                      autoComplete="off"
                      name="finalAmount"
                      id="finalAmount"
                      className="form-control"
                      placeholder="Final Amount"
                      value={finalAmount}
                      readOnly // Assuming this is a calculated field
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <input
          type="hidden"
          autoComplete="off"
          name="insertdate"
          id="insertdate"
          value={currentDate}
        />
        <div className="col-md-6  mt-3">
          <Button
            type="submit"
            variant="contained" color="primary"
          >
            Save
          </Button>
          {/* <Button type="submit" variant="primary" className="mx-3" onClick={() =>printForm(values)}>
        Save And Print
        </Button> */}
        </div>
        <ToastContainer />
      </form>)}
    </div>
  );
};

export default Chargesform