import React, { useState } from 'react';
import Sidebar from '../../Component/Sidebar';
import 'bootstrap/dist/css/bootstrap.min.css';

import { NavLink } from 'react-router-dom';
import ChargesTypeComponent from './ChargesPages/Chargestype';
import NewCharge from './ChargesPages/Createcharge';
import Header from '../../Component/Header/Header';
import { UserProvider } from "../../Provider";
import EditCharge from './ChargesPages/Editcharge';
import ChargeDetails from './ChargesPages/ChargeDetails';
export const ChargesComponent = () => {
  return (
    <UserProvider>  <div className=" ">
      <Header />
    <div className="row" style={{ marginTop: '48px' }}>
      {/* Sidebar */}
      <div className="col-md-2">
        <Sidebar />
      </div>
      
      {/* Main Content */}
      <div className="col-md-10">
  
      <ChargesTypeComponent/>
     
      </div>
    </div>
  </div>
  </UserProvider>
  );
};
export const NewChargesComponent = () => {
  return (
    <UserProvider> <div className="">
      <Header />
      <div className="row" style={{ marginTop: '48px' }}>
      {/* Sidebar */}
      <div className="col-md-2">
        <Sidebar />
      </div>
      
      {/* Main Content */}
      <div className="col-md-10">
     
      <NewCharge/>
     
      </div>
    </div>
  </div> </UserProvider>
  
  );
};
export const EditChargesComponent = () => {
  return (
    <UserProvider> <div className="">
      <Header />
      <div className="row" style={{ marginTop: '48px' }}>
      {/* Sidebar */}
      <div className="col-md-2">
        <Sidebar />
      </div>
      
      {/* Main Content */}
      <div className="col-md-10">
     
     <EditCharge/>
     
      </div>
    </div>
  </div> </UserProvider>
  
  );
};

export const DetailChargesComponent = () => {
  return (
    <UserProvider> <div className="">
      <Header />
      <div className="row" style={{ marginTop: '48px' }}>
      {/* Sidebar */}
      <div className="col-md-2">
        <Sidebar />
      </div>
      
      {/* Main Content */}
      <div className="col-md-10">
     
     <ChargeDetails/>
     
      </div>
    </div>
  </div> </UserProvider>
  
  );
};