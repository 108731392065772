import React, { useState, useEffect } from 'react';
import { Modal, Backdrop, Fade, Button, TextField } from '@mui/material';
import { useFormik } from "formik";
import axios from "../../../helper/axios";
import { useUser } from "../../../Provider";
import { useParams } from 'react-router-dom/cjs/react-router-dom';
import { ToastContainer, toast } from 'react-toastify'
const initialValues = {
    comment:""  
};

const PopupNurseComment = ({noteId,onClose }) => {
    console.log(noteId)
    const { admitnumber } = useParams();
    const { user, userHospitalcode, userrole1, isAuthRole, isAuthorized, gettokenId,token } = useUser();
    const [data, setData] = useState([]);
   // const token = sessionStorage.getItem("jwtToken");
    const [currentDateTime, setCurrentDateTime] = useState('');

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleReset,
    handleSubmit,
    resetForm,
    setValues,
  } = useFormik({
    initialValues,
    //validationSchema: Nursenotevalidation,
    onSubmit: async (values, action) => {
        try {
  
        //  if (userHospitalcode !== undefined) {
            const data = new FormData();
            data.append("document", JSON.stringify(values));
           console.log(JSON.stringify(values))
            const response = await axios.post(`/addnursecomment/${admitnumber}/${noteId}`, data, {
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "multipart/form-data",
              },
            });
            if (response.status === 200) {
              
             toast.success("Data Saved")
             
            }
          //}
        } catch (err) {
          toast.error("Unauthorised access")
        }
        action.resetForm();
      },
  
    });
    const loadHospitalcode = async () => {
        //when userHospitalcode and userrole1 is not undefined then execute /gethospitalcode
        if (userHospitalcode !== undefined && userrole1 !== undefined) {
          const res = await axios.get(`/getIPDhospitalcode/${admitnumber}`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
            params: {
              hospitalcode: userHospitalcode, // Include the hospitalcode in the query parameters
              userrole: userrole1, // Include the userrole in the query parameters
            },
          });
          setData(res.data[0]);
          console.log(res.data);
        };
      }
      useEffect(() => {
        const loadData = async () => {
           await loadHospitalcode();
        };
     
        loadData();
                     // Function to get the current date and time
     const getCurrentDateTime = () => {
        const now = new Date();
        const year = now.getFullYear();
        const month = String(now.getMonth() + 1).padStart(2, '0');
        const day = String(now.getDate()).padStart(2, '0');
        const hours = String(now.getHours()).padStart(2, '0');
        const minutes = String(now.getMinutes()).padStart(2, '0');
        const currentDateTimeString = `${year}-${month}-${day}T${hours}:${minutes}`;
        return currentDateTimeString;
      };
  
      // Set the current date and time when the component mounts
      setCurrentDateTime(getCurrentDateTime());
    }, [userHospitalcode, userrole1, isAuthorized,data.hospitalcode]);
  
  return (
    <Modal
      open={true} // Set to true to open the modal
      onClose={onClose}
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      closeAfterTransition
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={true}>
        <div style={{ backgroundColor: 'white', padding: '10px', borderRadius: '8px', maxWidth: '500px', margin: 'auto', marginTop: '150px' }}>
          <h2>Add Note</h2>
          <form onSubmit={handleSubmit} style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                    <div className="patient-new primary-info">
                        <div className="row">         
                                            <div className="row">
                                                <div className="col-md-4">
                                                    <label
                                                        htmlFor="comment"
                                                        className="col-form-label leftone "
                                                    >
                                                        Comment
                                                    </label>
                                                </div>
                                                <div className="col-md-8">
                                                    <textarea
                                                        // style={{ width: "210px", height: "50px" }}
                                                        rows="1"
                                                        cols="30"
                                                        type="text"
                                                        autoComplete="off"
                                                        name="comment"
                                                        id="comment"
                                                        placeholder="comment"
                                                        value={values.comment}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}

                                                        className="form-control"
                                                    />
                                                    {errors.comment && touched.comment ? (
                                                        <p className="form-erroremployee">{errors.comment}</p>
                                                    ) : null}       
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12 mt-6 text-center">
              <Button type="submit" variant="contained" color="primary" size="small" style={{ marginRight: '10px' }}>
                Add Comment
              </Button>
              <Button variant="contained" size="small" onClick={onClose}>
                Cancel
              </Button>
            </div>
            <ToastContainer/>
                </form>
        </div>
      </Fade>
    </Modal>
  );
};

export default PopupNurseComment