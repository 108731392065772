import React, { useRef, useEffect, useState } from "react";
import { useReactToPrint } from "react-to-print";
import image from "./PrintTemplateImageLogo.png";
import { useParams, useHistory } from "react-router-dom";
import "./PrintOperationCss.css";
import { Grid } from "@mui/material";
import axios from "../../helper/axios";
import moment from "moment/moment";
import API from "../../helper/axios";
import { useUser } from "../../Provider";
const PrintOperation = (props) => {
  const { user, userHospitalcode, userrole1, isAuthRole, isAuthorized, gettokenId, token } = useUser();
  const { admitnumber } = useParams();
  console.log(admitnumber)
  const history = useHistory();
  const params = useParams();
  const today = new Date();
  //const token = localStorage.getItem("jwtToken");
  const [OperationData, setOperationData] = useState({});
  console.log(props.opd);
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    //documentTitle: OperationData.opd,
    onAfterPrint: props.handleClose,
  });
  const [data, setData] = useState([]);

  // Function to get login user's details

  //when page load or refresh happen this function will got called
  useEffect(() => {
    const loadData = async () => {

      //loadHospitalLogo(); will wait for loadAllDetails(); to got execute
      if (OperationData.hospitalcode) {
        await loadHospitalLogo();
      }
    };
    loadData();
  }, [userHospitalcode, userrole1, isAuthorized, OperationData]);
  useEffect(() => {
    getOperationDetails();
  }, []);
  //get the Patient details according to patientid
  const getOperationDetails = async () => {
    const res = await axios.get(`/getipdoperations/${admitnumber}/${props.opd}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    console.log(res.data[0])
    setOperationData(res.data[0])
  }

  //get all hospital code
  const loadHospitalLogo = async () => {
    //when userHospitalcode is not undefined
    if (userHospitalcode !== undefined) {
      console.log(userHospitalcode)
      console.log(OperationData.hospitalcode)
      const res = await axios.get(`/gethospitallogo/${OperationData.hospitalcode}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          hospitalcode: OperationData.hospitalcode, // Include the hospitalcode in the query parameters
          //userrole: userrole1, // Include the userrole in the query parameters
        },
      });
      setData(res.data[0]);
      console.log(res.data);
    };
  }
  const formatOperationTime = (inputDateString) => {
    const inputDate = new Date(inputDateString);

    // Format the date as MM/DD/YYYY hh:mm am/pm in the user's local time zone
    const formattedDate = inputDate.toLocaleString('en-US', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      hour12: true,
      timeZone: 'UTC', // Change this to the server's time zone
    });

    return formattedDate;
  };
  return (
    <>
      <button
        className="btn btn-success"
        style={{ marginLeft: "68%", marginBottom: "1%", backgroundColor: "white", color: "green" }}
        onClick={handlePrint}
      >
        Print Operation Detail
      </button>

      <div  style={{ width: '100%', height: window.innerHeight, padding: "40px" }}  ref={componentRef}>
        {/* image will come dynamically */}
        <img src={`${API.defaults.baseURL}/uploadimage/attachone/${data.attachment1}`}
        style={{ width: '100%', marginBottom: "1px", height: "23%" }} />
        <Grid container spacing={3} className="container" style={{ paddingTop: "20px" }}>
          {/* Header */}
          <Grid item xs={12} className="print-operation-header">
            <h2>Operation Details</h2>
          </Grid>

          {/* Operation Details */}
          <Grid container spacing={3} className="container">
            <Grid item xs={6} className="field">
              <span className="label">Reference No:</span>
              <span className="value">{OperationData.operationnumber}</span>
            </Grid>

            <Grid item xs={6} className="field">
              <span className="label">Operation Name:</span>
              <span className="value">{OperationData.operationname}</span>
            </Grid>

            <Grid item xs={6} className="field">
              <span className="label">Operation Date:</span>
              <span className="value">{formatOperationTime(OperationData.operationtime)}</span>
            </Grid>

            <Grid item xs={6} className="field">
              <span className="label">Operation Category:</span>
              <span className="value">{OperationData.category}</span>
            </Grid>

            <Grid item xs={6} className="field">
              <span className="label">Consultant Doctor:</span>
              <span className="value">{OperationData.consultant_name}</span>
            </Grid>

            <Grid item xs={6} className="field">
              <span className="label">Assistant Consultant 1:</span>
              <span className="value">{OperationData.assistant1_name}</span>
            </Grid>

            <Grid item xs={6} className="field">
              <span className="label">Anesthetist:</span>
              <span className="value">{OperationData.anesthetist_name}</span>
            </Grid>

            <Grid item xs={6} className="field">
              <span className="label">Assistant Consultant 2:</span>
              <span className="value">{OperationData.assistant2_name}</span>
            </Grid>

            <Grid item xs={6} className="field">
              <span className="label">Anesthesia Type:</span>
              <span className="value">{OperationData.anesthesiatype}</span>
            </Grid>

            <Grid item xs={6} className="field">
              <span className="label">OT Technician:</span>
              <span className="value">{OperationData.ottechnician_name}</span>
            </Grid>

            <Grid item xs={6} className="field">
              <span className="label">OT Assistant:</span>
              <span className="value">{OperationData.otassistant_name}</span>
            </Grid>

            <Grid item xs={6} className="field">
              <span className="label">Remark:</span>
              <span className="value">{OperationData.remark}</span>
            </Grid>

            <Grid item xs={6} className="field">
              <span className="label">Result:</span>
              <span className="value">{OperationData.result}</span>
            </Grid>
          </Grid>
        </Grid>


      </div>
    </>
  );
};

export default PrintOperation;
