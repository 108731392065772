import React, { useRef } from 'react';
import { Grid, Button } from '@mui/material';
import { useReactToPrint } from 'react-to-print';

const PrintComponent = ({ children, commonContent ,heading, heading2 }) => {
    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
       // onAfterPrint: props.handleClose,
      });
    // const printAction = () => {
    //     const iframe = document.createElement('iframe');
    //     document.body.appendChild(iframe);
    //     const content = componentRef.current.innerHTML;
    //     const iframeContent = iframe.contentDocument;
        
    //     iframeContent.open();
    //     iframeContent.write(content);
    //     iframeContent.close();
        
    //     iframe.contentWindow.print();
    //     document.body.removeChild(iframe);
    // };

    return (
        <Grid container>
            <Grid item xs={12}>
                <Button className={"print-preview-button"} onClick={handlePrint}>Print Report</Button>
                
            </Grid>
            <Grid item xs={12} ref={componentRef}>
                {/* Display commonContent in the header */}
                <table className="print-component">
                    <thead>
                        {/* Table headers */}
                        <tr>
                            {/* Display commonContent in the table header */}
                            <td>{commonContent}</td>
                        </tr>
                        <tr>
                            <td>{heading}</td>
                        </tr>
                        <tr>
                            <td>{heading2}</td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>{children}</td>
                        </tr>
                    </tbody>
                    <tfoot className="table-footer">
                        <tr>
                            <td>{"Page footer"}</td>
                        </tr>
                    </tfoot>
                </table>
            </Grid>
        </Grid>
    );
};

export default PrintComponent;
