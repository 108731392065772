import React from "react";
import { useFormik } from "formik";
import { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useHistory, useParams } from "react-router-dom";
import axios from "../../../helper/axios";
import { Table, Button, Modal, Form } from "react-bootstrap";
import { patientregistration } from "../../../helper/schemas";
import { ToastContainer, toast } from 'react-toastify';
import * as Yup from "yup";  // Import Yup for validation
import { useUser } from "../../../Provider";
import HospitalCodeSearch from "../../../Component/HospitalCodeSearch";


// Define Yup validation schemas
const ValidationSchema = Yup.object().shape({
  floorname: Yup.string().required('Floor name is required'),
  hospitalcode: Yup.string().required("Please select the hospitalcode"),
  description: Yup.string().required("Please enter the description"),
});

const ValidationSchema1 = Yup.object().shape({
  floorname: Yup.string().required('Floor name is required'),
  description: Yup.string().required("Please enter the description"),
});

// Define initial form values
const initialValues = {
  floorname: "",
  description: "",
  hospitalcode: "",
  radiologycategoryname: "",
  chargetype: "",
  
};

// EditFloor component
const EditRadiologyCategory = () => {
  // Extract user details and functions from the context
  const { user, userHospitalcode, userrole1, isAuthRole, isAuthorized, gettokenId, token } = useUser();

  // Initialize React Router's history hook
  const history = useHistory();

  // State variables for handling success, error, and current date
  const [message, setMessage] = useState("");
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  // Extract floorid from URL params
  const { radiologycategoryid } = useParams();

  // Function to dynamically select the validation schema based on userHospitalcode
  const getValidationSchema = () => {
    return userHospitalcode === 1 ? ValidationSchema : ValidationSchema1;
  };

  // Formik hooks for form handling
  const {
    values,
    errors,
    touched,
    setValues,
    handleChange,
    handleBlur,
    resetForm,
    handleSubmit,
  } = useFormik({
    initialValues,
    // validationSchema: getValidationSchema(), // Apply validation schema
    onSubmit: async (values, { resetForm }) => {
      try {
        if (userHospitalcode !== undefined) {
          const data = new FormData();

          // Append all form fields to the FormData object
          data.append('document', JSON.stringify(values));
          data.append('radiologycategoryid', radiologycategoryid); // Assuming bedtypeid is now a number
          data.append('userHospitalcode', JSON.stringify(userHospitalcode));
          data.append('currentDateTime', JSON.stringify(getCurrentDateTime()));
          data.append('gettokenId', JSON.stringify(gettokenId));
          data.append('currentDate', JSON.stringify(currentDate));

          console.log(JSON.stringify(values));

          const response = await axios.post(`/UpdateRadiologyCategory/'${radiologycategoryid}'`, data, {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "multipart/form-data",
            },
            params: {
              hospitalcode: userHospitalcode,
              userrole: userrole1,
            },
          });

          if (response.status === 200) {
            return history.push("/listrediologycatagory");
          } else {
            console.error('Error in admitting patient');
            setSuccess(false);
            setError(true);
          }
        }
      } catch (error) {
        console.error('Error:', error.message);
        setSuccess(false);
        setError(true);
        toast.error('Something Went Wrong');
      }
    },
  });

  // Function to get the current date and time
  const getCurrentDateTime = () => {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0');
    const day = String(now.getDate()).padStart(2, '0');
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    const currentDateTimeString = `${year}-${month}-${day}T${hours}:${minutes}`;
    return currentDateTimeString;
  };

 
  let Showdate = new Date();
    let ShowTodaydate =
        Showdate.getFullYear() +
        "-" +
        (Showdate.getMonth() + 1) +
        "-" +
        Showdate.getDate();
    const [currentDate, setCurrentDate] = useState(ShowTodaydate);

  // State variable to store hospital code data
  const [data, setData] = useState([]);
  const handleReset = () => {
    // Reset the form using Formik's resetForm function
    resetForm();
  };
  const [searchValues, setSearchValues] = useState("");
  //Hospital code search
  const onSearchItems = (searchTerm) => {
    console.log("------", searchTerm)
    setSearchValues(searchTerm);
    if (searchTerm && searchTerm.hospitalcode) {
      setValues({
        ...values,
        hospitalcode: searchTerm.hospitalcode,
        // Update other fields as needed
      });
    }
  };
  // useEffect to load user details, hospital code, and floor details on component mount or when dependencies change
  useEffect(() => {
    const loadData = async () => {
      await loadHospitalcode();
      await loadRadiologyCategory();
    };
    loadData();
  }, [userHospitalcode, userrole1, isAuthorized]);

  // Function to fetch hospital codes
  const loadHospitalcode = async () => {
    if (userHospitalcode !== undefined && userrole1 !== undefined) {
      const res = await axios.get("/gethospitalcode", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          hospitalcode: userHospitalcode,
          userrole: userrole1,
        },
      });
      setData(res.data);
      console.log(res.data);
    };
  }

  // Function to fetch floor details
  const loadRadiologyCategory = async () => {
    try {
      if (userHospitalcode !== undefined && userrole1 !== undefined) {
        const response = await axios.get(`/getcategorybyradiologycategoryid/${radiologycategoryid}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: {
            hospitalcode: userHospitalcode,
            userrole: userrole1,
          },
        });

        const categoryData = response.data;
        console.log("Category Data:", categoryData)
        setValues(categoryData);
      }
    } catch (error) {
      console.error('Error loading floor details:', error.message);
      alert('Something Went Wrong');
    }
  };
  const currentDomain = window.location.hostname;
  // JSX structure for the form
  return (
    <div className="p-3 pt-0 appointment">
       <Helmet>
        <title>Aarogyasaarthi | {currentDomain} | Update Radiology Category</title>
      </Helmet>
      <form onSubmit={handleSubmit}>
        
        <div class="fill header"><h3 class="float-left"> Update Radiology Category</h3> </div>
        <div className="patient-new primary-info">
          <div className="row">
            <div className="col-md-12">
              <div className="row">
                <div className="col-md-6">
                  <div className="col-md-12 mt-3">
                    <div className="row">
                      <div className="col-md-4">
                        <label
                          htmlFor="chargetype"
                          className="col-form-label leftone"
                        >
                          Charges Type
                        </label>
                      </div>
                      <div className="col-md-8">
                        <input
                          type="text"
                          autoComplete="off"
                          name="chargetype"
                          id="chargetype"
                          placeholder="Charge Type"
                          value={values.chargetype}
                          onChange={handleChange}
                          // onBlur={handleBlur}
                          className="form-control ember-text-field text-left ember-view"
                        />
                        {errors.chargetype && touched.chargetype ? (
                          <p className="form-erroremployee">{errors.chargetype}</p>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12 mt-3">
                    <div className="row">

                      <div className="col-md-4">
                        <label
                          htmlFor="radiologycategoryname"
                          className="col-form-label leftone"
                        >
                          Radiology Category
                        </label>
                      </div>
                      <div className="col-md-8">
                      
                        <input
                          type="text"
                          autoComplete="off"
                          name="radiologycategoryname"
                          id="radiologycategoryname"
                          placeholder="Radiology Category"
                          value={values.radiologycategoryname}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          style={{ width: '73%', height: '32px', border: '1px solid #ced4da', color: '#495057' }}
                        />
                        {errors.radiologycategoryname && touched.radiologycategoryname ? (
                          <p className="form-erroremployee">{errors.radiologycategoryname}</p>
                        ) : null}
                      </div></div></div>
                {isAuthorized ? (   <div className="col-md-12 mt-3">
                    <div className="row">
                      <div className="col-md-4">
                        <label
                          htmlFor="tax"
                          className="col-form-label "
                        >
                          Charges Tax
                        </label>
                      </div>
                      <div className="col-md-8">
                        <input
                          type="text"
                          autoComplete="off"
                          name="tax"
                          id="tax"
                          placeholder="Charge tax"
                          value={values.tax}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className="form-control ember-text-field text-left ember-view"
                        />
                        {errors.tax && touched.tax ? (
                          <p className="form-erroremployee">{errors.tax}</p>
                        ) : null}
                      </div>
                    </div>
                </div>  ) : null}
                </div>
                <div className="col-md-6">
                {isAuthorized ? (<div className="col-md-12 mt-3">
                    <div className="row">

                      <div className="col-md-4">
                        <label
                          htmlFor="hospitalcode"
                          className="col-form-label leftone"
                        >
                          Hospital Code
                        </label>
                      </div>
                      <div className="col-md-8">
                        {/* Conditionally render the dropdown and input field */}
                        {isAuthorized ? (
                          <input
                            type="text"
                            autoComplete="off"
                            name="hospitalcode"
                            id="hospitalcode"
                            placeholder="Hospital Code"
                            value={values.hospitalcode}
                            // onChange={handleChange}
                            // onBlur={handleBlur}
                            disabled={true} // Set the input as disabled
                            // style={{ width: '73%', height: '32px', border: '1px solid #ced4da', color: '#495057' }}
                            className="form-control "
                          >
                           
                          </input>
                        ) : (
                          <input
                            type="text"
                            name="hospitalcode"
                            id="hospitalcode"
                            value={userHospitalcode}
                            disabled={true} // Set the input as disabled
                            className="form-control "
                          />
                        )}
                        {isAuthorized && errors.hospitalcode && touched.hospitalcode ? (
                          <p className="form-erroremployee">{errors.hospitalcode}</p>
                        ) : null}
                      </div></div></div>) : null}

                <div className="col-md-12 mt-3">
                    <div className="row">
                      <div className="col-md-4">
                        <label
                          htmlFor="chargeamount"
                          className="col-form-label leftone"
                        >
                          Charge Amount
                        </label>
                      </div>
                      <div className="col-md-8">
                        <input
                          type="number"
                          autoComplete="off"
                          name="chargeamount"
                          id="chargeamount"
                          placeholder="Charge Amount"
                          value={values.chargeamount}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className="form-control ember-text-field text-left ember-view"
                        />
                        {errors.chargeamount && touched.chargeamount ? (
                          <p className="form-erroremployee">{errors.chargeamount}</p>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  {!isAuthorized ? (   <div className="col-md-12 mt-3">
                    <div className="row">
                      <div className="col-md-4">
                        <label
                          htmlFor="tax"
                          className="col-form-label "
                        >
                          Charges Tax
                        </label>
                      </div>
                      <div className="col-md-8">
                        <input
                          type="number"
                          autoComplete="off"
                          name="tax"
                          id="tax"
                          placeholder="Charge Tax"
                          value={values.tax}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className="form-control ember-text-field text-left ember-view"
                        />
                        {errors.tax && touched.tax ? (
                          <p className="form-erroremployee">{errors.tax}</p>
                        ) : null}
                      </div>
                    </div>
                </div>  ) : null}
              </div>
                </div>
                </div>
            </div>
          </div>
      
        <div className="col-md-6  mt-3">
          <Button
            type="submit"
            variant="success"
            className="mx-3"
          >
            Save
          </Button>
          <Button
            variant="primary"
            className="mx-3"
            onClick={handleReset}
          >
            Reset
          </Button>
        </div>
      </form>
    </div>
  );
};

export default EditRadiologyCategory;