import React from 'react'
import { HospitalRegistration } from './HospitalRegister/HospitalRegistration'
import Header from '../Component/Header/Header'
import Sidebar from '../Component/Sidebar'
// import { HospitalList } from './HospitalList/HospitalList'
import ListViewHospitalReg from './HospitalRegister/ListViewHospitalReg'
import HospitalDetail from './HospitalRegister/HospitalDetail'
import { UserProvider } from '../Provider'

export const HospitalDetails = () => {
  return (
    <UserProvider><div className=" ">
    <Header />
  <div className="row" style={{ marginTop: '48px' }}>
    {/* Sidebar */}
    <div className="col-md-2">
      <Sidebar/>
    </div>
    
    {/* Main Content */}
    <div className="col-md-10">
   
    <HospitalRegistration/>
    
    </div>
  </div>
</div></UserProvider>
  )
}
export const Hospitalists = () => {
    return (
      <UserProvider>  <div className=" ">
      <Header />
    <div className="row" style={{ marginTop: '48px' }}>
      {/* Sidebar */}
      <div className="col-md-2">
        <Sidebar/>
      </div>
      
      {/* Main Content */}
      <div className="col-md-10">
     
      <ListViewHospitalReg/>
      
      </div>
    </div>
  </div></UserProvider>
    )
  }
  export const Hospitalistdetail = () => {
    return (
      <UserProvider> <div className=" ">
      <Header />
    <div className="row" style={{ marginTop: '48px' }}>
      {/* Sidebar */}
      <div className="col-md-2">
        <Sidebar/>
      </div>
      
      {/* Main Content */}
      <div className="col-md-10">
     
      <HospitalDetail/>
      
      </div>
    </div>
  </div></UserProvider>
    )
  }