import React, { useState, useEffect } from "react";
import { Table, Button, Modal, Form } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { IoTrashOutline } from "react-icons/io5";
import axios from "../../../helper/axios";
import { useUser } from "../../../Provider";
import { Helmet } from "react-helmet";
import { NavLink, useHistory } from "react-router-dom";
import MUIDataTable from "mui-datatables";
import { FaEdit } from "react-icons/fa";
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

const ListViewOperation = () => {
  // Destructuring values from useUser hook
  const { user, userHospitalcode, userrole1, isAuthRole, isAuthorized, gettokenId, token } = useUser();
  console.log(user, userHospitalcode, userrole1, isAuthRole, isAuthorized, gettokenId);
  const history = useHistory();
  const [data, setData] = useState([]);
  const currentDate = new Date();
  const day = currentDate.getDate();
  const month = currentDate.getMonth() + 1; // Months are zero-indexed, so add 1
  const year = currentDate.getFullYear();
  // Format the date as DD-MM-YYYY
  const formattedDate = `${day}-${month}-${year}`;
  // const token = sessionStorage.getItem("jwtToken");
  const [userRole, setRole] = useState();

  // When the page loads or refreshes, this function will be called
  useEffect(() => {
    const loadData = async () => {
      // await loadAllDetails();
      // loadAllAppointment(); will wait for loadAllDetails(); to be executed
      await loadAllOperation();
    };

    loadData();
  }, [userHospitalcode, userrole1, isAuthorized]);

  // Load all operations based on user role and hospital code
  const loadAllOperation = async () => {
    try {
      if (userHospitalcode !== undefined && userrole1 !== undefined) {
        console.log("userHospitalcode:", userHospitalcode); // Add this line
        console.log("userrole1:", userrole1); // Add this line
        const res = await axios.get("/getoperations", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: {
            hospitalcode: userHospitalcode, // Include the hospital code in the query parameters
            userrole: userrole1, // Include the user role in the query parameters
          },
        });
        setData(res.data);
        console.log(res.data);
      }
    } catch (error) {
      // Handle any error here.
      alert("Error deleting hospital:", error);
    }
  };


    // Function to delete a bed
    const cancelOperation = async (operationsetupid) => {
      // Display a confirmation dialog
      const isConfirmed = window.confirm("Do you want to delete this operation ?");
      
      if (isConfirmed) {
        try {
          console.log("operationsetupid:", operationsetupid);
          await axios.post(`/deleteoperation/${operationsetupid}`, null, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          // After successfully deleting the bed, refresh the operation list.
          loadAllOperation();
        } catch (error) {
          // Handle any error here.
          alert("Error deleting operation:", error);
        }
      } else {
        // User clicked "Cancel" in the confirmation dialog, do nothing.
      }
    };
    
  let columns;
  // Define columns for MUIDataTable based on user authorization
  if (isAuthorized) {
    columns = [
      {
        name: "operationname",
        label: "Operation Name",
        options: {
          filter: true,
          sort: true,
        },
      },
      {
        name: "operationcode",
        label: "Operation code",
        options: {
          filter: true,
          sort: true,
        },
      },
      {
        name: "category",
        label: "Category",
        options: {
          filter: true,
          sort: true,
        },
      },
      {
        name: "hospitalcode",
        label: "Hospital Code",
        options: {
          filter: true,
          sort: true,
          customBodyRender: (value) => (value !== null && value !== undefined) ? value.toString().padStart(5, '0') : ""
        },
      },
      {
        name: "",
        label: "Action",
        options: {
          customBodyRender: (value, tableMeta, updateValue) => {
            const newD = data.find((e) => {
              return (
                e.operationname === tableMeta.rowData[0] &&
                e.operationcode==tableMeta.rowData[1] &&
                e.category === tableMeta.rowData[2] &&
                e.hospitalcode === tableMeta.rowData[3]
              );
            });
            console.log('newD:', newD);
            return (
              <>
                 <IconButton color="secondary" onClick={() => cancelOperation(newD.operationsetupid)}>
              <DeleteIcon />
            </IconButton>

            <NavLink to={`/editoperation/${newD.operationsetupid}`}>
                  {/* <button className="btn btn-primary btn-sm ml-2" style={{marginLeft: '30px'}}>
                    <FaEdit /> Edit
                  </button> */}
                    <IconButton color="primary" >
                <EditIcon />
              </IconButton>
                </NavLink>
              </>
            );
          },
        },
      },
    ];
  } else {
    columns = [
      {
        name: "operationname",
        label: "Operation Name",
        options: {
          filter: true,
          sort: true,
        },
      },
      {
        name: "operationcode",
        label: "Operation code",
        options: {
          filter: true,
          sort: true,
        },
      },
      {
        name: "category",
        label: "Category",
        options: {
          filter: true,
          sort: true,
        },
      },
      {
        name: "",
        label: "Action",
        options: {
          customBodyRender: (value, tableMeta, updateValue) => {
            const newD = data.find((e) => {
              return (
                e.operationname === tableMeta.rowData[0] &&
                e.operationcode==tableMeta.rowData[1] &&
                e.category === tableMeta.rowData[2] 
                  // e.hospitalcode === tableMeta.rowData[3] 
              );
            });
            console.log('newD:', newD);
            return (
              <>
                 <IconButton color="secondary" onClick={() => cancelOperation(newD.operationsetupid)}>
              <DeleteIcon />
            </IconButton>

            <NavLink to={`/editoperation/${newD.operationsetupid}`}>
                  {/* <button className="btn btn-primary btn-sm ml-2" style={{marginLeft: '30px'}}>
                    <FaEdit /> Edit
                  </button> */}
                    <IconButton color="primary" >
                <EditIcon />
              </IconButton>
                </NavLink>

              </>
            );
          },
        },
      },
    ];
  }

  const options = {
    selectableRows: false,
    onRowClick: ((props) => {
      console.log(props);
    }),
    downloadOptions: {
      filename: `Operation_list_${formattedDate}`, // Customize the filename here to download
      filterOptions: {
        useDisplayedColumnsOnly: true,
        useDisplayedRowsOnly: true,
      },
    },
  };

  return (
    <>
      <Helmet>
        <title>Aarogyasaarthi | Operation List</title>
      </Helmet>
      <NavLink to='/addoperation'>
        <button className="btn btn-success btn-sm mr-2" style={{ margin: "10px" }}>
          <svg xmlns="http://www.w3.org/2000/svg" width="19" height="18" style={{ paddingRight: '4px' }} viewBox="0 0 100 100">
            <line x1="10" y1="50" x2="90" y2="50" stroke="white" stroke-width="10" />
            <line x1="50" y1="10" x2="50" y2="90" stroke="white" stroke-width="10" />
          </svg>Add Operation
        </button>
      </NavLink>
      <MUIDataTable
        title={"Operation List"}
        data={data}
        columns={columns}
        options={options}
        className="custom-mui-datatable"
      />
    </>
  );
};

export default ListViewOperation;
