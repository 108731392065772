import React, { useState, useEffect } from 'react';
import { Typography, Button, TextField, Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import axios from "../../../helper/axios";
import { useFormik } from "formik";
import { Medicationvalidation } from "../../../helper/schemas";
import { useUser } from "../../../Provider";
import { useParams } from 'react-router-dom/cjs/react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SearchMedicineCategory from '../../../Component/SearchMedicineCategory';
import SearchMedicineName from '../../../Component/SearchMedicineName';

const getCurrentDate = () => {
  const now = new Date();
  const year = now.getFullYear();
  const month = String(now.getMonth() + 1).padStart(2, '0');
  const day = String(now.getDate()).padStart(2, '0');
  const hours = String(now.getHours()).padStart(2, '0');
  const minutes = String(now.getMinutes()).padStart(2, '0');
  const currentDateTimeString = `${year}-${month}-${day}`;
  return currentDateTimeString;
};
const initialValues = {
  date: getCurrentDate(),
  time: "",
  medicinecategory: "",
  medicinename: "",
  dosage: "",
  remarks: "",
};

const MedicationeForm = ({onMedication}) => {
  const { admitnumber } = useParams();
  console.log({ admitnumber })
  const { user, userHospitalcode, userrole1, isAuthRole, isAuthorized, gettokenId, token } = useUser();
  console.log(user, userHospitalcode, userrole1, isAuthRole, isAuthorized, gettokenId)
  const [isFormVisible, setFormVisibility] = useState(false);
  const [category1, setCategory] = useState([]);
  const [data, setData] = useState([]);
  const [medicineNames, setMedicineNames] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  let Showdate = new Date();
  let ShowTodaydate =
    Showdate.getFullYear() +
    "-" +
    (Showdate.getMonth() + 1) +
    "-" +
    Showdate.getDate();
  const [currentDate, setCurrentDate] = useState(ShowTodaydate);
  const handleAddMedication = () => {
    setFormVisibility(!isFormVisible); // Toggle form visibility
  };


  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleReset,
    handleSubmit,
    resetForm,
    setValues,
  } = useFormik({
    initialValues,
    validationSchema: Medicationvalidation,
    onSubmit: async (values, action) => {
      try {

        //  if (userHospitalcode !== undefined) {
        const data = new FormData();
        data.append("document", JSON.stringify(values));
        data.append("selectedCategory", JSON.stringify(selectedCategory));
        data.append("currentDate", JSON.stringify(currentDate));
      data.append("gettokenId", JSON.stringify(gettokenId));
      console.log(JSON.stringify(values))
        const response = await axios.post(`/medication/${admitnumber}`, data, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        });
        if (response.status === 200) {
          onMedication();
          toast.success("Data Saved")

        }
        //}
      } catch (err) {

      }
      action.resetForm();
    },

  });
 
  const loadHospitalcode = async () => {
    //when userHospitalcode and userrole1 is not undefined then execute /gethospitalcode
    if (userHospitalcode !== undefined && userrole1 !== undefined) {
      const res = await axios.get(`/getIPDhospitalcode/${admitnumber}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          hospitalcode: userHospitalcode, // Include the hospitalcode in the query parameters
          userrole: userrole1, // Include the userrole in the query parameters
        },
      });
      setData(res.data[0]);
      console.log(res.data);
    };
  }
  //on Category change
  const handleSearchChanges = (searchTerms) => {
    console.log("---------------",searchTerms)
    setCategory(searchTerms); // Update the searchValue state with the search term
    const selectedCategory = searchTerms.medicinecategoryid;
    setSelectedCategory(selectedCategory);
    setValues({
      ...values,
      medicinecategory:searchTerms.medicinecategoryid,
   })
  };
  const handleMedicineChange = (searchTerms) => {
    console.log("---------------",searchTerms)
    setMedicineNames(searchTerms); // Update the searchValue state with the search term
        setValues({
        ...values,
        medicinename:searchTerms.medicinecode,
     })
  };
  
  useEffect(() => {
    const loadData = async () => {
      await loadHospitalcode();
      const selectedHospitalCode = data.hospitalcode || userHospitalcode;
    };
    loadData();
  }, [userHospitalcode, userrole1, isAuthorized, data.hospitalcode]);
  
  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography variant="h6" style={{ padding: '20px' }}>
          Add Medication Dose
        </Typography>
        <Button variant="contained" color="primary" onClick={handleAddMedication}>
          {isFormVisible ? 'Close Form' : 'Add Medication Dose'}
        </Button>
      </div>
      {isFormVisible && (
        <form onSubmit={handleSubmit} style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
          <div className="patient-new primary-info">
            <div className="row">
              <div className="col-md-12">
                <div className="row">
                  <div className="col-md-6">
                    <div className="col-md-12 mt-3">
                      <div className="row">
                        <div className="col-md-4">
                          <label
                            htmlFor="date"
                            className="col-form-label leftone"
                          >
                            Date
                          </label>
                        </div>
                        <div className="col-md-8">
                          <input
                            type="date"
                            autoComplete="off"
                            name="date"
                            id="date"
                            placeholder="Date"
                            value={values.date}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            min={getCurrentDate()}
                            className="form-control"
                          />
                          {errors.date && touched.date ? (
                            <p className="form-erroremployee">{errors.date}</p>
                          ) : null}
                        </div>
                      </div>
                    </div>


                    <div className="col-md-12 mt-3">
                      <div className="row">
                        <div className="col-md-4">
                          <label
                            htmlFor="medicinecategory"
                            className="col-form-label leftone"
                          >
                            Medicine Category
                          </label>
                        </div>
                        <div className="col-md-8">
                       < SearchMedicineCategory onCategoryChange={handleSearchChanges}
                          hospitalvalue={data.hospitalcode }/>
                         
                          {/* {errors.medicinecategory && touched.medicinecategory ? (
                            <p className="form-erroremployee">{errors.medicinecategory}</p>
                          ) : null} */}
                        </div>
                      </div>
                    </div>


                    <div className="col-md-12 mt-3">
                      <div className="row">
                        <div className="col-md-4">
                          <label
                            htmlFor="dosage"
                            className="col-form-label leftone"
                          >
                            Dosage
                          </label>
                        </div>
                        <div className="col-md-8">
                          <input
                            type="text"
                            autoComplete="off"
                            name="dosage"
                            id="dosage"
                            placeholder="Dosage"
                            value={values.dosage}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            style={{ width: '73%', height: '32px', border: '1px solid #ced4da ', color: '#495057' }}
                          />
                          {errors.dosage && touched.dosage ? (
                            <p className="form-erroremployee">{errors.dosage}</p>
                          ) : null}

                        </div>
                      </div>
                    </div>


                  </div>
                  <div className="col-md-6">

                    <div className="col-md-12 mt-3">
                      <div className="row">
                        <div className="col-md-4">
                          <label
                            htmlFor="time"
                            className="col-form-label leftone"
                          >
                            Time
                          </label>
                        </div>
                        <div className="col-md-8">
                          <input
                            type="time"
                            autoComplete="off"
                            name="time"
                            id="time"
                            placeholder="Time"
                            value={values.time}
                            onChange={handleChange}
                            onBlur={handleBlur}

                            className="form-control"
                          />
                          {errors.time && touched.time ? (
                            <p className="form-erroremployee">{errors.time}</p>
                          ) : null}
                        </div>
                      </div>
                    </div>

                    <div className="col-md-12 mt-3">
                      <div className="row">
                        <div className="col-md-4">
                          <label
                            htmlFor="medicinename"
                            className="col-form-label leftone"
                          >
                            Medicine Name
                          </label>
                        </div>
                        <div className="col-md-8">
                          <SearchMedicineName onMedicineChange= {handleMedicineChange} selectedCategory={selectedCategory} 
                          hospitalvalue={data.hospitalcode} />
                         
                          {errors.medicinename && touched.medicinename ? (
                            <p className="form-erroremployee">{errors.medicinename}</p>
                          ) : null}

                        </div>
                      </div>
                    </div>


                    <div className="col-md-12 mt-3">
                      <div className="row">
                        <div className="col-md-4">
                          <label
                            htmlFor="remarks"
                            className="col-form-label "
                          >
                            Remarks
                          </label>
                        </div>
                        <div className="col-md-8">
                          <textarea
                            // style={{ width: "210px", height: "50px" }}
                            rows="1"
                            cols="30"
                            type="text"
                            autoComplete="off"
                            name="remarks"
                            id="remarks"
                            placeholder="Remarks"
                            value={values.remarks}
                            onChange={handleChange}
                            onBlur={handleBlur}

                            className="form-control"
                          />
                          {errors.remarks && touched.remarks ? (
                            <p className="form-erroremployee">{errors.remarks}</p>
                          ) : null}

                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-11 mt-6 text-center">
            <Button type="submit" variant="contained" color="primary" size="small" style={{ width: 'auto' }}>
              Submit
            </Button>
          </div>
          <ToastContainer />
        </form>
      )}
    </div>
  );
};

export default MedicationeForm;



