import React, { useState, useEffect } from 'react';
import axios from "../../../helper/axios";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Table, Button, Modal, Form } from 'react-bootstrap';
import { NavLink, useParams, useHistory } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { useUser } from "../../../Provider";
import { useFormik } from 'formik';
const initialValues = {
    patientname: "",
        bloodgroup: "",
        mobileno: "",
        age: "",
        doctorname: "",
        appointmentdate: "",
        paymentmode: "",
        address: "",
        doctorid:"",
        doctorrecordid: "",
        doctorfees: "",
        height: "",
        //weight: "",
        //bp: "",
        //pulse: "",
        //temperature: ""
}
const RescheduleAppointment = () => {
    const { user, userHospitalcode, userrole1, isAuthRole, isAuthorized, gettokenId,token } = useUser();
    console.log(user, userHospitalcode, userrole1, isAuthRole, isAuthorized, gettokenId)
    const history = useHistory()
    //const token = sessionStorage.getItem("jwtToken");
    const { opdnumber } = useParams();
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);
    // const [currentDateTime, setCurrentDateTime] = useState('');
    const [doctor, setDoctor] = useState([]);
    let Showdate = new Date();
  let ShowTodaydate =
    Showdate.getFullYear() +
    "-" +
    (Showdate.getMonth() + 1) +
    "-" +
    Showdate.getDate();
  const [currentDate, setCurrentDate] = useState(ShowTodaydate);
    const {
        values,
        errors,
        touched,
        setValues,
        handleChange,
        handleBlur,
        resetForm,
        handleSubmit,
      } = useFormik({
        initialValues,
        // validationSchema,
        onSubmit: async (values, { resetForm }) => {
           // console.log(values); // Log the values to check if they are captured correctly
      
           try {
            if (userHospitalcode !== undefined && userrole1 !== undefined) {
                const data = new FormData();
                data.append("document", JSON.stringify(values));
                data.append("gettokenId", JSON.stringify(gettokenId));
                data.append("currentDate", JSON.stringify(currentDate));
                console.log(JSON.stringify(values));
                
                const respon = await axios.post(`/reschedule/${opdnumber}`, data, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    }, params: {
                        hospitalcode: userHospitalcode, // Include the hospitalcode in the query parameters
                        userrole: userrole1, // Include the userrole in the query parameters
                    },
                },);

                if (respon.status === 200) {
                    setSuccess(true);
                    setError(false);
                    return history.push("/appointment");
                }
            }
        } catch (err) {
            setSuccess(false);
            setError(true);
            alert("Unauthorised")
        }
        },
      });
    const getDoctorDetails = async () => {

        try {
            if (userHospitalcode !== undefined && userrole1 !== undefined) {
                const res = await axios.get(`/getdoctordetails`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                    params: {
                        hospitalcode: values.hospitalcode || userHospitalcode, // Include the hospitalcode in the query parameters
                        userrole: userrole1, // Include the userrole in the query parameters
                    },
                });
                console.log(res.data)
                // Check if the response status code is  200
                if (res.status === 200) {
                    setDoctor(res.data);
                } else {
                    // Handle unexpected response status codes (e.g., 404, 500, etc.) as errors.
                    alert(`Request failed with status: ${res.status}`);
                    // Optionally, you can set an error state or display an error message to the user.
                }
            }
        }
        catch (error) {
            // Handle network errors or other exceptions.
           console.log(`An error occurred: ${error.message}`);
            // Optionally, you can set an error state or display an error message to the user.
        }
    };
    useEffect(() => {
        const loadData = async () => {
            //getDoctorDetails(); will wait for loadAllDetails(); to got execute
           // await getDoctorDetails(values.hospitalcode);
        };
        getDoctorDetails(values.hospitalcode);
        loadData();
    }, [userHospitalcode, userrole1, isAuthorized,values.hospitalcode]);
   
    const loadAllAppointmentDetails = async () => {
        try {
            if (userHospitalcode !== undefined && userrole1 !== undefined) {
                const response = await axios.get(`/getappointments/${opdnumber}`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                    params: {
                        hospitalcode: userHospitalcode, // Include the hospitalcode in the query parameters
                        userrole: userrole1, // Include the userrole in the query parameters
                    },
                });
                const appoinmentdata = response.data;
                console.log(appoinmentdata)
                setValues(appoinmentdata);
               
            }
        } catch (error) {
            // console.log(error.response.data[0]);
            alert("Something Went Wrong")
        }
    };
    useEffect(() => {
        const loadData = async () => {
            //loadAllAppointmentDetails(); will wait for loadAllDetails(); to got execute
            await loadAllAppointmentDetails();

        };
        loadData();
    }, [userHospitalcode, userrole1, isAuthorized,]);
    const handleDoctorChange = async (e) => {
        const doctorrecordid = e.target.value;
    
        // Fetch doctor details based on the selected doctorid
        try {
            const response = await axios.get(`/getdoctor/${doctorrecordid}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                params: {
                    hospitalcode: values.hospitalcode || userHospitalcode,
                    userrole: userrole1,
                },
            });
            console.log(response.data)
            console.log(response.data[0].doctorfees)
    
            // Check if the response status code is 200
            if (response.status === 200) {
                const selectedDoctor = response.data[0];
    
                // Update doctorfees in the form values
                setValues((prevValues) => ({
                    ...prevValues,
                    doctorid: selectedDoctor.doctorrecordid,
                    doctorfees: selectedDoctor.doctorfees,
                }));
            } else {
                // Handle unexpected response status codes as errors.
                alert(`Request failed with status: ${response.status}`);
            }
        } catch (error) {
            console.log(`An error occurred: ${error.message}`);
            // Optionally, you can set an error state or display an error message to the user.
        }
    };
    
    // const handleDoctorChange = async (e) => {
    //     const doctorrecordid = e.target.value;
    
    //     // Fetch doctor details based on the selected doctorid
    //     try {
    //         const response = await axios.get(`/getdoctor/${doctorrecordid}`, {
    //             headers: {
    //                 Authorization: `Bearer ${token}`,
    //             },
    //             params: {
    //                 hospitalcode: values.hospitalcode || userHospitalcode,
    //                 userrole: userrole1,
    //             },
    //         });
    //         console.log(response.data)
    //        console.log(response.data[0].doctorfees)
    //         // Check if the response status code is 200
    //         if (response.status === 200) {
    //             const selectedDoctor = response.data[0];
                
    //             // Update doctorfees in the form values
    //             setValues((prevValues) => ({
    //                 ...prevValues,
    //                 doctorid: selectedDoctor.doctorrecordid,
    //                 doctorfees: selectedDoctor.doctorfees,
    //             }));
    //         } else {
    //             // Handle unexpected response status codes as errors.
    //             alert(`Request failed with status: ${response.status}`);
    //         }
    //     } catch (error) {
    //         console.log(`An error occurred: ${error.message}`);
    //         // Optionally, you can set an error state or display an error message to the user.
    //     }
    // };
    
   
    return (
        <div className="p-3 pt-0 appointment" >
            <Helmet>
                <title> Reschedule Appointment Details | aarogyasaarthi.com | Hospital Management Software | Smart Solutions for Hospital Efficiency</title>
            </Helmet>
            <form onSubmit={handleSubmit}>
                {success && (
                    <div >
                        Appointment confirm.
                    </div>
                )}
                {error && (
                    <div>
                        Error in booking Appointment Please try again later.
                    </div>
                )}
                <div class="fill header"><h3 class="float-left"> Reschedule Appointment  </h3> </div>
                <div className="patient-new primary-info">
                    <div className="row  ml-4">
                        <div className="col-md-12 mt-3">
                            <div className="row">
                                <div className="col-md-2">
                                    <label
                                        htmlFor="patientName"
                                        className="input-labelemployee leftone"
                                    >
                                        Patient Id
                                    </label>
                                </div>
                                <div className="col-md-4">
                                    <input
                                        type="text"
                                        autoComplete="off"
                                        name="patientName"
                                        id="patientName"
                                        placeholder="Patient Name"
                                        disabled
                                        className="form-control "
                                        value={values.patientid}
                                    />

                                </div>
                                <div className="col-md-2">
                                    <label
                                        htmlFor="mobileno"
                                        className="input-labelemployee leftone"
                                    >
                                        Mobile No
                                    </label>
                                </div>
                                <div className="col-md-4">
                                    <input
                                        type="text"
                                        autoComplete="off"
                                        name="mobileno"
                                        id="mobileno"
                                        placeholder="Mobile No"
                                        disabled
                                        className="form-control "
                                        value={values.phoneno}
                                    />

                                </div>
                            </div>

                        </div>
                        <div className="col-md-12 mt-3">
                            <div className="row">
                                <div className="col-md-2">
                                    <label
                                        htmlFor="patientName"
                                        className="input-labelemployee leftone"
                                    >
                                        Patient Name
                                    </label>
                                </div>
                                <div className="col-md-4">
                                    <input
                                        type="text"
                                        autoComplete="off"
                                        name="patientName"
                                        id="patientName"
                                        placeholder="Patient Name"
                                        disabled
                                        className="form-control "
                                        value={values.patientname}
                                    />

                                </div>
                                <div className="col-md-2">
                                    <label
                                        htmlFor="age"
                                        className="input-labelemployee leftone"
                                    >
                                        Age
                                    </label>
                                </div>
                                <div className="col-md-4">
                                    <input
                                        type="text"
                                        autoComplete="off"
                                        name="age"
                                        id="age"
                                        placeholder="age"
                                        disabled
                                        className="form-control "
                                        value={values.age}
                                    />

                                </div>
                            </div>

                        </div>

                        <div className="col-md-12 mt-3">
                            <div className="row">
                                <div className="col-md-2">
                                    <label
                                        htmlFor="appointmentDate "
                                        className="input-labelemployee leftone"
                                    >
                                        Appointment Date
                                    </label>
                                </div>
                                <div className="col-md-4">
                                    <input
                                        type="datetime-local"
                                        autoComplete="off"
                                        name="appointmentdate"
                                        id="appointmentdate"
                                        placeholder="Appointment Date"
                                        value={values.appointmentdate}
                                        //onChange={(e) => setCurrentDateTime(e.target.value)}
                                        onChange={handleChange}
                                        className="form-control ember-text-field text-left ember-view"
                                    />
                                </div>
                                <div className="col-md-2">
                                    <label
                                        htmlFor="bloodGroup"
                                        className="input-labelemployee"
                                    >
                                        Blood Group
                                    </label>
                                </div>
                                <div className="col-md-4">
                                    <input
                                        type="text"
                                        autoComplete="off"
                                        name="bloodGroup"
                                        id="bloodGroup"
                                        placeholder="Blood Group"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.bloodgroup}
                                        disabled
                                        className="form-control "
                                    />

                                </div>

                            </div>

                        </div>
                        <div className="col-md-12 mt-3">
                            <div className="row">
                                <div className="col-md-2">
                                    <label
                                        htmlFor="gender "
                                        className="input-labelemployee leftone"
                                    >
                                        Gender
                                    </label>
                                </div>
                                <div className="col-md-4">
                                    <input
                                        type="text"
                                        autoComplete="off"
                                        name="gender"
                                        id="gender"
                                        placeholder="Gender"
                                        value={values.gender}
                                        //onChange={(e) => setCurrentDateTime(e.target.value)}
                                        disabled
                                        className="form-control "
                                    />
                                </div>

                                <div className="col-md-2">
                                    <label
                                        htmlFor="address"
                                        className="input-labelemployee leftone"
                                    >
                                        Address
                                    </label>
                                </div>
                                <div className="col-md-4">
                                    <textarea
                                        // style={{ width: "210px", height: "50px" }}
                                        rows="1"
                                        cols="30"
                                        type="text"
                                        autoComplete="off"
                                        name="address"
                                        id="address"
                                        placeholder="Address"
                                        disabled
                                        className="form-control "
                                        value={values.address}
                                    />
                                </div>
                            </div>

                        </div>
                        <div className="col-md-12 mt-3">
                            <div className="row">
                                <div className="col-md-2">
                                    <label
                                        htmlFor="doctorName"
                                        className="input-labelemployee"
                                    >
                                        Doctor
                                    </label>
                                </div>
                                <div className="col-md-4">
                                    <select
                                        type="text"
                                        autoComplete="off"
                                        name="doctorid"
                                        id="doctorid"
                                        placeholder="Doctor Name"
                                        value={values.doctorid}
                                        onChange={(e) => {
                                            handleDoctorChange(e); // Call the custom function when doctorid changes
                                        }}
                                        onBlur={handleBlur}
                                        style={{ width: '73%', height: '32px', border: '1px solid #ced4da ', color: '#495057' }}

                                    >
                                        {doctor.map((doctor) => (
                                            <option
                                                value={doctor.doctorid}
                                                key={doctor.id}
                                            >
                                                {doctor.fname}
                                            </option>
                                        ))}


                                    </select>
                                    {/* {errors.doctorName && touched.doctorName ? (
                <p className="col-form-label">
                  {errors.doctorName}
                </p>
              ) : null} */}
                                </div>
                                <div className="col-md-2">
                                    <label
                                        htmlFor="doctorfees"
                                        className="input-labelemployee"
                                    >
                                        Doctor Fees
                                    </label>
                                </div>
                                <div className="col-md-4">
                                    <input
                                        type="text"
                                        autoComplete="off"
                                        name="doctorfees"
                                        id="doctorfees"
                                        placeholder="Doctor Fee"
                                        className="form-control "
                                        value={values.doctorfees}
                                        readOnly
                                    />
                                </div>
                            </div>

                        </div>
                        {/* <div className="col-md-12 mt-3">
                            <div className="row">
                                <div className="col-md-2">
                                    <label
                                        htmlFor="paymentMode"
                                        className="input-labelemployee"
                                    >
                                        Payment Mode
                                    </label>
                                </div>
                               <div className="col-md-4">
                                    <select
                                        type="text"
                                        autoComplete="off"
                                        name="paymentmode"
                                        id="paymentmode"
                                        placeholder="Payment Mode"
                                        value={values.paymentmode}
                                         onChange={handleChange}
                onBlur={handleBlur}
                                        style={{ width: '73%', height: '32px', border: '1px solid #ced4da ', color: '#495057' }}
                                    >
                                        <option>Cash</option>
                                        <option>Cheque</option>
                                        <option>Upi</option>
                                        <option>Online</option>
                                        <option>Other</option>
                                    </select>
                                </div>
                                {userHospitalcode !== 2 ? (<div className="col-md-2">
                                    <label
                                        htmlFor="height"
                                        className="input-labelemployee"
                                    >
                                        Height
                                    </label>
                                </div>) : (
                                    null
                                )}
                                {userHospitalcode !== 2 ? ( <div className="col-md-4">
                                    <input
                                        type="text"
                                        autoComplete="off"
                                        name="height"
                                        id="height"
                                        placeholder="Height"
                                         onChange={handleChange}
                                         onBlur={handleBlur}
                                        value={values.height}
                                        disabled
                                        className="form-control "
                                    />

                                </div>) : (
                                    null
                                )}
                            </div>

                        </div> */}
                        {/* <div className="col-md-12 mt-3">
                            {userHospitalcode !== 2 ? (<div className="row">
                                <div className="col-md-2">
                                    <label
                                        htmlFor="weight"
                                        className="input-labelemployee "
                                    >
                                        Weight
                                    </label>
                                </div>
                                <div className="col-md-4">
                                    <input
                                        type="text"
                                        autoComplete="off"
                                        name="weight"
                                        id="weight"
                                        placeholder="Weight"
                                         onChange={handleChange}
                                         onBlur={handleBlur}
                                        className="form-control "
                                        value={values.weight}
                                    />

                                </div>
                                <div className="col-md-2">
                                    <label
                                        htmlFor="bp"
                                        className="input-labelemployee "
                                    >
                                        BP
                                    </label>
                                </div>
                                <div className="col-md-4">
                                    <input
                                        type="text"
                                        autoComplete="off"
                                        name="bp"
                                        id="bp"
                                        placeholder="bp"
                                         onChange={handleChange}
                onBlur={handleBlur}
                                        className="form-control "
                                        value={values.bp}
                                    />

                                </div>
                            </div>) : (
                                null
                            )}

                        </div> */}
                        {/* <div className="col-md-12 mt-3">
                            {userHospitalcode !== 2 ? (<div className="row">
                                <div className="col-md-2">
                                    <label
                                        htmlFor="pulse"
                                        className="input-labelemployee "
                                    >
                                        Pulse
                                    </label>
                                </div>
                                <div className="col-md-4">
                                    <input
                                        type="text"
                                        autoComplete="off"
                                        name="pulse"
                                        id="pulse"
                                        placeholder="Pulse"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        className="form-control "
                                        value={values.pulse}
                                    />

                                </div>
                                <div className="col-md-2">
                                    <label
                                        htmlFor="temperature"
                                        className="input-labelemployee "
                                    >
                                        Temperature
                                    </label>
                                </div>
                                <div className="col-md-4">
                                    <input
                                        type="text"
                                        autoComplete="off"
                                        name="temperature"
                                        id="temperature"
                                        placeholder="Temperature"
                                        onChange={handleChange}
                onBlur={handleBlur}
                                        className="form-control "
                                        value={values.temperature}
                                    />

                                </div>
                            </div>) : (
                                null
                            )}


                        </div> */}
                    </div>
                </div>


                <div className="col-md-6  mt-3">

                    <Button type="submit" variant="success" className="mx-3">
                        Update
                    </Button>
                    {/* <Button type="submit" variant="primary" className="mx-3">
                        Save And Print
                    </Button> */}
                </div>

            </form>
        </div>
    );
};

export default RescheduleAppointment;