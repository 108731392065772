import React from "react";
import { useFormik } from "formik";
import { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useHistory, useParams } from "react-router-dom";
import axios from "../../../helper/axios";
import { Table, Button, Modal, Form } from "react-bootstrap";
import { patientregistration } from "../../../helper/schemas";
import { ToastContainer, toast } from 'react-toastify';
import * as Yup from "yup";  // Import Yup for validation
import { useUser } from "../../../Provider";

// Define Yup validation schemas
const ValidationSchema = Yup.object().shape({
  bedtype: Yup.string().required('Bed type is required'),
  hospitalcode: Yup.string().required("Please select the hospitalcode"),
});

const ValidationSchema1 = Yup.object().shape({
  bedtype: Yup.string().required('Bed type is required'),
});

// Define initial form values
const initialValues = {
  bedtype: "",
  hospitalcode: "",
};

const EditBedType = () => {
  // Extract user details and functions from the context
  const { user, userHospitalcode, userrole1, isAuthRole, isAuthorized, gettokenId, token } = useUser();
  
  // Initialize React Router's history hook
  const history = useHistory();

  // State variables for handling success, error, and current date
  const [message, setMessage] = useState("");
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [currentDate, setCurrentDate] = useState(new Date());

  // Extract bedtypeid from URL params
  const { bedtypeid } = useParams();

  // Function to dynamically select the validation schema based on userHospitalcode
  const getValidationSchema = () => {
    return userHospitalcode === 1 ? ValidationSchema : ValidationSchema1;
  };

  // Function to get the current date and time
  const getCurrentDateTime = () => {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0');
    const day = String(now.getDate()).padStart(2, '0');
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    const currentDateTimeString = `${year}-${month}-${day}T${hours}:${minutes}`;
    return currentDateTimeString;
  };

  // Formik hooks for form handling
  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    setValues,
    handleSubmit,
  } = useFormik({
    initialValues,
    validationSchema: getValidationSchema(), // Apply validation schema
    onSubmit: async (values, { resetForm }) => {
      try {
        // Check if userHospitalcode is defined
        if (userHospitalcode !== undefined) {
          const data = new FormData();

          // Append form fields to the FormData object
          data.append('document', JSON.stringify(values));
          data.append('bedtypeid', bedtypeid);
          data.append('userHospitalcode', JSON.stringify(userHospitalcode));
          data.append('currentDateTime', JSON.stringify(getCurrentDateTime()));
          data.append('gettokenId', JSON.stringify(gettokenId));
          data.append('currentDate', JSON.stringify(currentDate));

          const response = await axios.post(`/BedTypeUpdate/'${bedtypeid}'`, data, {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "multipart/form-data",
            },
            params: {
              hospitalcode: userHospitalcode,
              userrole: userrole1,
            },
          });

          if (response.status === 200) {
            // Redirect to the bed type list view upon successful update
            return history.push("/listviewbedtype");
          } else {
            console.error('Error in updating bed type');
            setSuccess(false);
            setError(true);
          }
        }
      } catch (error) {
        console.error('Error:', error.message);
        setSuccess(false);
        setError(true);
        toast.error('Something Went Wrong');
      }
    },
  });

  // State variable to store hospital code data
  const [data, setData] = useState([]);

  // useEffect to load user details, hospital code, and bed type details on component mount or when dependencies change
  useEffect(() => {
    const loadData = async () => {
      await loadHospitalcode();
      await loadBedType();
    };
    loadData();
  }, [userHospitalcode, userrole1, isAuthorized]);

  // Function to fetch hospital codes
  const loadHospitalcode = async () => {
    if (userHospitalcode !== undefined && userrole1 !== undefined) {
      const res = await axios.get("/gethospitalcode", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          hospitalcode: userHospitalcode,
          userrole: userrole1,
        },
      });
      setData(res.data);
      console.log(res.data);
    };
  }

  // Function to fetch bed type details
  const loadBedType = async () => {
    try {
      if (userHospitalcode !== undefined && userrole1 !== undefined) {
        const response = await axios.get(`/GetBedTypesbedtypeid/${bedtypeid}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: {
            hospitalcode: userHospitalcode,
            userrole: userrole1,
          },
        });

        const bedtypedata = response.data;
        console.log("Bed Type Data:", bedtypedata)
        setValues(bedtypedata);
      }
    } catch (error) {
      console.error('Error loading bed type details:', error.message);
      alert('Something Went Wrong');
    }
  };

  // JSX structure for the form
  return (
    <div className="p-3 pt-0 appointment">
      <Helmet>
        <title>Update Bed Type | {window.location.hostname} | Hospital Management Software | Smart Solutions for Hospital Efficiency</title>
      </Helmet>
      <form onSubmit={handleSubmit}>
        <div className="fill header">
          <h3 className="float-left">Bed Type Edit</h3>
        </div>
        <div className="patient-new primary-info">
          <div className="row">
            <div className="col-md-12">
              <div className="row">
                <div className="col-md-6">
                  <div className="col-md-12 mt-3">
                    <div className="row">
                      <div className="col-md-4">
                        <label htmlFor="bedtype" className="col-form-label leftone">
                          Bed Type Name
                        </label>
                      </div>
                      <div className="col-md-8">
                        <input
                          type="text"
                          autoComplete="off"
                          name="bedtype"
                          id="bedtype"
                          placeholder="Bed Type"
                          value={values.bedtype}
                          onChange={handleChange}
                          className="form-control ember-text-field text-left ember-view"
                        />
                        {errors.bedtype && touched.bedtype ? (
                          <p className="form-erroremployee">{errors.bedtype}</p>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">



                {isAuthorized ? (<div className="col-md-12 mt-3">
                    <div className="row">

                      <div className="col-md-4">
                        <label
                          htmlFor="hospitalcode"
                          className="col-form-label leftone"
                        >
                          Hospital Code
                        </label>
                      </div>
                      <div className="col-md-8">
                        {/* Conditionally render the dropdown and input field */}
                        {isAuthorized ? (
                          <input
                            type="text"
                            autoComplete="off"
                            name="hospitalcode"
                            id="hospitalcode"
                            placeholder="Hospital Code"
                            value={values.hospitalcode}
                            // onChange={handleChange}
                            // onBlur={handleBlur}
                            disabled={true} // Set the input as disabled
                            // style={{ width: '73%', height: '32px', border: '1px solid #ced4da', color: '#495057' }}
                            className="form-control "
                          >
                           
                          </input>
                        ) : (
                          <input
                            type="text"
                            name="hospitalcode"
                            id="hospitalcode"
                            value={userHospitalcode}
                            disabled={true} // Set the input as disabled
                            className="form-control "
                          />
                        )}
                        {isAuthorized && errors.hospitalcode && touched.hospitalcode ? (
                          <p className="form-erroremployee">{errors.hospitalcode}</p>
                        ) : null}
                      </div></div></div>) : null}


                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="modal-buttons">
          <Button type="submit" variant="success" className="mx-3">
            Update
          </Button>
        </div>
      </form>
    </div>
  );
};

export default EditBedType;
