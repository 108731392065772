import React from "react";
import { NavLink } from "react-router-dom";
import Header from "../../Component/Header/Header";
import Sidebar from "../../Component/Sidebar";
import ListViewFloor from "./BedPages/ListViewFloor";
import AddFloor from "./BedPages/AddFloor";
import AddBedGroup from "./BedPages/AddBedGroup";
import ListViewBedGroup from "./BedPages/ListViewBedGroup";
import AddBedType from "./BedPages/AddBedType";
import ListViewBedType from "./BedPages/ListViewBedType";
import AddBed from "./BedPages/AddBed";
import ListViewBed from "../Bed/BedPages/ListViewBed";
import { UserProvider } from "../../Provider";
import EditBedType from "./BedPages/EditBedType";
import EditBed from "./BedPages/EditBed";
import EditFloor from "./BedPages/EditFloor";
import EditBedGroup from "./BedPages/EditBedGroup";

// Component for adding a new floor
export const AddFloorComponent = () => {
  return (
    <UserProvider>
      <div className=" ">
        <Header />
        <div className="row" style={{ marginTop: "50px" }}>
          {/* Sidebar */}
          <div className="col-md-2">
            <Sidebar />
          </div>
          {/* Main Content */}
          <div className="col-md-10">
            <AddFloor />
          </div>
        </div>
      </div>
    </UserProvider>
  );
};

// Component for viewing the list of floors
export const ListViewFloorComponent = () => {
  return (
    <UserProvider>
      <div className=" ">
        <Header />
        <div className="row" style={{ marginTop: "50px" }}>
          {/* Sidebar */}
          <div className="col-md-2">
            <Sidebar />
          </div>
          {/* Main Content */}
          <div className="col-md-10">
            <ListViewFloor />
          </div>
        </div>
      </div>
    </UserProvider>
  );
};

// Component for adding a new bed group
export const AddBedGroupComponent = () => {
  return (
    <UserProvider>
      <div className=" ">
        <Header />
        <div className="row" style={{ marginTop: "50px" }}>
          {/* Sidebar */}
          <div className="col-md-2">
            <Sidebar />
          </div>
          {/* Main Content */}
          <div className="col-md-10">
            <AddBedGroup />
          </div>
        </div>
      </div>
    </UserProvider>
  );
};

// Component for viewing the list of bed groups
export const ListViewBedGroupComponent = () => {
  return (
    <UserProvider>
      <div className=" ">
        <Header />
        <div className="row" style={{ marginTop: "50px" }}>
          {/* Sidebar */}
          <div className="col-md-2">
            <Sidebar />
          </div>
          {/* Main Content */}
          <div className="col-md-10">
            <ListViewBedGroup />
          </div>
        </div>
      </div>
    </UserProvider>
  );
};

// Component for adding a new bed type
export const AddBedTypeComponent = () => {
  return (
    <UserProvider>
      <div className=" ">
        <Header />
        <div className="row" style={{ marginTop: "50px" }}>
          {/* Sidebar */}
          <div className="col-md-2">
            <Sidebar />
          </div>
          {/* Main Content */}
          <div className="col-md-10">
            <AddBedType />
          </div>
        </div>
      </div>
    </UserProvider>
  );
};

// Component for viewing the list of bed types
export const ListViewBedTypeComponent = () => {
  return (
    <UserProvider>
      <div className=" ">
        <Header />
        <div className="row" style={{ marginTop: "50px" }}>
          {/* Sidebar */}
          <div className="col-md-2">
            <Sidebar />
          </div>
          {/* Main Content */}
          <div className="col-md-10">
            <ListViewBedType />
          </div>
        </div>
      </div>
    </UserProvider>
  );
};

// Component for adding a new bed
export const AddBedComponent = () => {
  return (
    <UserProvider>
      <div className=" ">
        <Header />
        <div className="row" style={{ marginTop: "50px" }}>
          {/* Sidebar */}
          <div className="col-md-2">
            <Sidebar />
          </div>
          {/* Main Content */}
          <div className="col-md-10">
            <AddBed />
          </div>
        </div>
      </div>
    </UserProvider>
  );
};

// Component for viewing the list of beds
export const ListViewBedComponent = () => {
  return (
    <UserProvider>
      <div className=" ">
        <Header />
        <div className="row" style={{ marginTop: "50px" }}>
          {/* Sidebar */}
          <div className="col-md-2">
            <Sidebar />
          </div>
          {/* Main Content */}
          <div className="col-md-10">
            <ListViewBed />
          </div>
        </div>
      </div>
    </UserProvider>
  );
};

// Component for editing bed type details
export const EditBedTypeComponent = () => {
  return (
    <UserProvider>
      <div className=" ">
        <Header />
        <div className="row" style={{ marginTop: "50px" }}>
          {/* Sidebar */}
          <div className="col-md-2">
            <Sidebar />
          </div>
          {/* Main Content */}
          <div className="col-md-10">
            <EditBedType />
          </div>
        </div>
      </div>
    </UserProvider>
  );
};

// Component for editing bed details
export const EditBedComponent = () => {
  return (
    <UserProvider>
      <div className=" ">
        <Header />
        <div className="row" style={{ marginTop: "50px" }}>
          {/* Sidebar */}
          <div className="col-md-2">
            <Sidebar />
          </div>
          {/* Main Content */}
          <div className="col-md-10">
            <EditBed />
          </div>
        </div>
      </div>
    </UserProvider>
  );
};

// Component for editing floor details
export const EditFloorComponent = () => {
  return (
    <UserProvider>
      <div className=" ">
        <Header />
        <div className="row" style={{ marginTop: "50px" }}>
          {/* Sidebar */}
          <div className="col-md-2">
            <Sidebar />
          </div>
          {/* Main Content */}
          <div className="col-md-10">
            <EditFloor />
          </div>
        </div>
      </div>
    </UserProvider>
  );
};

// Component for editing bed group details
export const EditBedGroupComponent = () => {
  return (
    <UserProvider>
      <div className=" ">
        <Header />
        <div className="row" style={{ marginTop: "50px" }}>
          {/* Sidebar */}
          <div className="col-md-2">
            <Sidebar />
          </div>
          {/* Main Content */}
          <div className="col-md-10">
            <EditBedGroup />
          </div>
        </div>
      </div>
    </UserProvider>
  );
};