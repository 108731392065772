import React, { useRef, useEffect, useState } from "react";
import { useReactToPrint } from "react-to-print";
import image from "./PrintTemplateImageLogo.png";
import { useParams, useHistory } from "react-router-dom";
import "./PrintTransactionCss.css";
import { Grid } from "@mui/material";
import axios from "../../helper/axios";
import moment from "moment/moment";
import API from "../../helper/axios";
import { useUser } from "../../Provider";
const PrintTransaction = (props) => {
  const { user, userHospitalcode, userrole1, isAuthRole, isAuthorized, gettokenId, token } = useUser();
  const { admitnumber } = useParams();
  console.log(admitnumber)
  const history = useHistory();
  const params = useParams();
  const today = new Date();
  //const token = localStorage.getItem("jwtToken");
  const [TransactionData, setOperationData] = useState({});
  console.log(props.ipdtransaction);
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    //documentTitle: TransactionData.opd,
    onAfterPrint: props.handleClose,
  });
  const [data, setData] = useState([]);

  // Function to get login user's details

  //when page load or refresh happen this function will got called
  useEffect(() => {
    const loadData = async () => {

      //loadHospitalLogo(); will wait for loadAllDetails(); to got execute
      if (TransactionData.hospitalcode) {
        await loadHospitalLogo();
      }
    };
    loadData();
  }, [userHospitalcode, userrole1, isAuthorized, TransactionData]);
  useEffect(() => {
    getTransactionDetails();
  }, []);
  //get the Patient details according to patientid
  const getTransactionDetails = async () => {
    const res = await axios.get(`/getipdtransaction/${admitnumber}/${props.ipdtransaction}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    console.log(res.data[0])
    setOperationData(res.data[0])
  }

  //get all hospital code
  const loadHospitalLogo = async () => {
    //when userHospitalcode is not undefined
    if (userHospitalcode !== undefined) {
      console.log(userHospitalcode)
      console.log(TransactionData.hospitalcode)
      const res = await axios.get(`/gethospitallogo/${TransactionData.hospitalcode}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          hospitalcode: TransactionData.hospitalcode, // Include the hospitalcode in the query parameters
          //userrole: userrole1, // Include the userrole in the query parameters
        },
      });
      setData(res.data[0]);
      console.log(res.data);
    };
  }
  const formatOperationTime = (inputDateString) => {
    const inputDate = new Date(inputDateString);

    // Format the date as MM/DD/YYYY hh:mm am/pm in the user's local time zone
    const formattedDate = inputDate.toLocaleString('en-US', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      hour12: true,
      timeZone: 'UTC', // Change this to the server's time zone
    });

    return formattedDate;
  };
  return (
    <>
      <button
        className="btn btn-success"
        style={{ marginLeft: "68%", marginBottom: "1%", backgroundColor: "white", color: "green" }}
        onClick={handlePrint}
      >
        Print Transaction Detail
      </button>

      <div style={{ width: '100%', height: window.innerHeight, padding: "40px" }} ref={componentRef}>
        {/* image will come dynamically */}
        <img src={`${API.defaults.baseURL}/uploadimage/attachone/${data.attachment1}`}
          style={{ width: '100%', marginBottom: "1px", height: "23%" }} />
        <Grid container spacing={3} className="container" style={{ paddingTop: "10px" }}>
          {/* Patient Details */}
          <Grid item xs={6}>
            <b>Patient :- </b>
            {TransactionData.patientname}
          </Grid>
          {/* Transaction Details */}
          <Grid item xs={6}>
            <b>Transaction ID :-</b>
           {TransactionData.transactionid}
          </Grid>

          <Grid item xs={6} >
            <span className="label">IPD No :-</span>
            <span className="value">{TransactionData.admitnumber}</span>
          </Grid>

          <Grid item xs={6} >
            <span className="label">Date :-</span>
            <span className="value">{formatOperationTime(TransactionData.paymentdate)}</span>
          </Grid>
          {/* Horizontal Line */}
          <Grid item xs={12}>
            <hr style={{ border: "1px solid #000" }} />
          </Grid>
          <Grid item xs={8} className="field">
            <span className="label">Description:</span>
          </Grid>

          <Grid item xs={4} className="field">
            <span className="label">Amount (₹)</span>
          </Grid>
          {/* Horizontal Line */}
          <Grid item xs={12}>
            <hr style={{ border: "1px solid #000" }} />
          </Grid>

          <Grid item xs={8} className="field">
            <b>Payment Received</b>
          </Grid>
          <Grid item xs={4} className="field">
            <span className="label">{TransactionData.paymentamount}</span>
          </Grid>
          {/* <Grid item xs={8} className="field">
            <p>By: Transfer to Bank Account</p>
          </Grid> */}

          {/* Horizontal Line */}
          <Grid item xs={12}>
            <hr style={{ border: "1px solid #000" }} />
          </Grid>
          <Grid item xs={8} className="field">

          </Grid>

          <Grid item xs={4} className="field">
            <span className="label">Total: {TransactionData.paymentamount}</span>

          </Grid>
        </Grid>



      </div>
    </>
  );
};

export default PrintTransaction;
