import React, { useRef, useEffect, useState } from "react";
import { useReactToPrint } from "react-to-print";
// import image from "./PrintTemplateImage.png"
import { useParams, useHistory } from "react-router-dom";
import "./PrintTemplateBillingCss.css";
import { Grid } from "@mui/material";
import axios from "../../helper/axios";
import moment from "moment/moment";
import image from "../PrintTemplateBillingView/PrintTemplateImageLogo.png";
import API from "../../helper/axios";

const PrintTemplateBilling = (props) => {
  const history = useHistory();
  const params = useParams();
  const today = new Date();
  const token = localStorage.getItem("jwtToken");
  const [patientData, setPatientData] = useState({});
  const [billId, setBillId] = useState(props.BId);
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: patientData.billid,
    onAfterPrint: props.handleClosedPopUp,
  });
  console.log(props.BId);
  const getPatientDetails = async () => {
    const res = await axios.get(`/getbilling/${props.BId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    console.log(res.data[0]);
    setPatientData(res.data[0]);
  };

  useEffect(() => {
    // setBillId(props.BId)
    getPatientDetails();
    return () => {};
  }, []);
  const [data, setData] = useState([]);
  const [userHospitalcode, setUserHospitalcode] = useState();
  const [userrole1, setUserrole1] = useState();
  const [isAuthorized, setIsAuthorized] = useState(false);
  // Function to get login user's details
  const loadAllDetails = async () => {
    try {
      const response = await axios.post("/loginuser", null, {
        headers: { Authorization: `Bearer ${token}` },
      });

      // Debugging: Log the entire response
      console.log("Response:", response);

      const UserRoles = response.data.person[0][0].role1;
      const hospitalcode = response.data.person[0][0].hospitalcode;

      // Debugging: Log the values of userHospitalcode and userrole1
      console.log("userHospitalcode:", hospitalcode);
      console.log("userrole1:", UserRoles);

      setUserHospitalcode(hospitalcode);
      setUserrole1(UserRoles);
      // Debugging: Log the condition result
      const authorizationResult =
        hospitalcode === 1 && UserRoles === "Superadmin";
      console.log("Authorization Result:", authorizationResult);
      setIsAuthorized(authorizationResult);
      console.log("isAuthorized after setIsAuthorized:", isAuthorized);
    } catch (error) {
      console.error("Error:", error);

      if (error.response.status === 403) {
        alert("Token Expired. Please login again.");
        localStorage.removeItem("jwtToken");
        history.push("/");
      } else if (error.response.status === 401) {
        alert("Invalid token. Please login again.");
        localStorage.removeItem("jwtToken");
        history.push("/");
      } else {
        alert("Error: " + error.response.data.error);
      }
    }
  };
  //when page load or refresh happen this function will got called
  useEffect(() => {
    const loadData = async () => {
      await loadAllDetails();
      //loadHospitalLogo(); will wait for loadAllDetails(); to got execute
      await loadHospitalLogo();
    };
    loadData();
  }, [userHospitalcode, userrole1, isAuthorized]);
  //get all hospital code
  const loadHospitalLogo = async () => {
    //when userHospitalcode is not undefined
    if (userHospitalcode !== undefined) {
      console.log(userHospitalcode);
      const res = await axios.get(
        `/gethospitallogo/${patientData.hospitalcodee}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: {
            hospitalcode: patientData.hospitalcode, // Include the hospitalcode in the query parameters
            //userrole: userrole1, // Include the userrole in the query parameters
          },
        }
      );
      setData(res.data[0]);
      console.log(res.data);
    }
  };
  return (
    <>
      <button
        className="btn btn-success"
        style={{
          float: "right",
          backgroundColor: "white",
          color: "green",
          marginRight: "20px",
        }}
        onClick={handlePrint}
      >
        Print Bill
      </button>

      <div
        // className="pass-title"
        ref={componentRef}
        style={{ width: "100%", height: window.innerHeight, padding: "40px" }}
      >
        <div style={{ textAlign: "center", marginTop: "-10px" }}>
          {data.attachment2 ? (
            <img
              src={`${API.defaults.baseURL}/uploadimage/attachtwo/${data.attachment2}`}
              style={{ width: "100%", marginBottom: "1px", height: "20%" }}
              alt="Attachment 2"
            />
          ) : (
            <div>
              <img
                src={`${API.defaults.baseURL}/uploadimage/hospitallogo/${data.hospitallogo}`}
                style={{ width: "15%", marginBottom: "5px", float: "left" }}
                alt="Hospital Logo"
              />
              <h4 style={{ textAlign: "center" }}>
                {data.hospitalname
                  ? data.hospitalname.toUpperCase()
                  : "HOSPITAL NAME NOT AVAILABLE"}
              </h4>
              <h6 style={{ textAlign: "center" }}>{data.address}</h6>
              <h6 style={{ textAlign: "center" }}>Mobile: +91-{data.phone}</h6>
            </div>
          )}
        </div>
        <div style={{ textAlign: "center", marginTop: "-10x" }}>
          <b>Receipt</b>
        </div>
        {/* <div class="box"></div> */}
        <div style={{ maxWidth: "85%", marginLeft: "50px" }}>
          <div style={{ border: "1px solid black" }}>
            <div style={{ padding: "12px 0px 12px 30px" }}>
              <Grid container spacing={2} style={{ fontSize: "12px" }}>
                <Grid item xs={5}>
                  <b>Bill Number :- </b>
                  {patientData.billnumber}
                </Grid>

                <Grid item xs={6}>
                  <b>Booking Date and Time :- </b>
                  {moment().format("DD/MM/YYYY hh:mm A")}
                </Grid>
              </Grid>
              <Grid container spacing={2} style={{ fontSize: "12px" }}>
                <Grid item xs={5}>
                  <b>Name :- </b>
                  {patientData.patientname}
                </Grid>

                <Grid item xs={6}>
                  <b>Email :- </b>
                  {patientData.email}
                </Grid>
              </Grid>
              <Grid container spacing={2} style={{ fontSize: "12px" }}>
                <Grid item xs={5}>
                  <b>Mobile :- </b>
                  {patientData.phoneno}
                </Grid>

                <Grid item xs={6}>
                  <b>Address :- </b>
                  {patientData.address}
                </Grid>
              </Grid>
            </div>
            <div class="box"></div>
            <div>
              <div style={{ padding: "12px" }}>
                <table
                  style={{
                    width: "90%",
                    marginLeft: "20px",
                  }}
                >
                  <thead style={{ fontSize: "13px" }}>
                    <tr>
                      <th>SNo.</th>
                      <th>Description</th>
                      <th>Quantity</th>
                      <th>Rate</th>
                    </tr>
                  </thead>
                  <tbody style={{ fontSize: "12px" }}>
                    {props.chargeList.map((item, index) => {
                      if (item.chargename.length != 0) {
                        return (
                          <tr
                            key={index}
                            style={{ borderTop: "1px solid gray" }}
                          >
                            <td>{index + 1}</td>
                            <td style={{ width: "40%", overflow: "hidden", textOverflow: "ellipsis" }}>
                {item.chargename}
              </td>
                            <td>{item.chragequantity}</td>
                            <td>{parseFloat(item.chargeamount).toFixed(2)}</td>
                          </tr>
                        );
                      }
                    })}
                  </tbody>
                </table>
              </div>
              {/* <div class="box"></div> */}
              <hr style={{ marginTop: "0px" }} />
              {/* <div className="vl"></div> */}
              <div>
                <div
                  style={{
                    float: "right",
                    fontSize: "12px",
                    //   marginRight: "-1px",
                    //   marginRight: "75px",
                    // paddingRight: "75px",
                    marginTop: "-17px",
                    clear: "both",
                  }}
                >
                  <table
                    style={{
                      width: "100%",
                      marginRight: "85px",
                      borderLeft: "1px solid black",
                      clear: "both",
                    }}
                  >
                    <tr>
                      <th
                        style={{
                          borderRight: "1px solid black",
                          padding: "2.5px",
                          paddingRight: "30px",
                        }}
                      >
                        Total Amount
                      </th>
                      <td style={{ padding: "2.5px", paddingRight: "70px" }}>
                        {parseFloat(props.totalCost).toFixed(2)}
                      </td>
                    </tr>
                    <tr>
                      <th
                        style={{
                          borderRight: "1px solid black",
                          padding: "2.5px",
                        }}
                      >
                        Extra Amount
                      </th>
                      <td style={{ padding: "2.5px" }}>
                        {parseFloat(props.extraCharge).toFixed(2)}
                      </td>
                    </tr>
                    <tr>
                      <th
                        style={{
                          borderRight: "1px solid black",
                          padding: "2.5px",
                        }}
                      >
                        Sub Total
                      </th>
                      <td style={{ padding: "2.5px" }}>
                        {parseFloat(props.finalAmount).toFixed(2)}
                      </td>
                    </tr>
                    {/* <tr> */}
                    <tr style={{ borderBottom: "1px solid black" }}>
                      <th
                        style={{
                          borderRight: "1px solid black",
                          padding: "2.5px",
                        }}
                      >
                        Paid
                      </th>
                      <td style={{ padding: "2.5px" }}>
                        {parseFloat(props.finalAmount).toFixed(2)}
                      </td>
                    </tr>
                    {/* <hr style={{width: "250%", border: "1px solid black", marginTop: "0px", marginBottom: "0px"}}/> */}
                    {/* <hr style={{width: "10rem", border: "1px solid black", marginTop: "0px", marginBottom: "0px"}}/> */}
                    <tr>
                      <th
                        style={{
                          borderRight: "1px solid black",
                          padding: "2.5px",
                        }}
                      >
                        Balance
                      </th>
                      <td style={{ padding: "2.5px" }}>0.00</td>
                    </tr>
                  </table>
                </div>
              </div>
              <div
                style={{
                  marginTop: "4.75rem",
                  fontSize: "12px",
                  padding: "12px",
                }}
              >
                <b>Authorized Signature</b>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PrintTemplateBilling;
