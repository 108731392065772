import React from "react";
import { NavLink } from "react-router-dom";
import Header from "../../Component/Header/Header";
import Sidebar from "../../Component/Sidebar";
import { UserProvider } from "../../Provider";
import ListViewIPD from "./IPDBooking/ViewListIPD";
import PatientAdmit from "./IPDBooking/PatientAdmit";
import EditIpdPatientAdmit from "./IPDBooking/EditIpdPatientAdmit";
import DischargedView from "./IPDBooking/DischargedView";
import Overview from "./IPDBooking/Overview";
//this component is used to admit patient in Ipd
export const PatientAdmitComponent = () => {
  return (

    <UserProvider>  <div className=" ">
    <Header />
  <div className="row" style={{ marginTop: '50px' }}>
    {/* Sidebar */}
    <div className="col-md-2">
      <Sidebar />
    </div>
    
    {/* Main Content */}
    <div className="col-md-10">
    
    <PatientAdmit />
    
    </div>
  </div>
</div> </UserProvider>
          
  );
};
export const ListViewIPDComponent = () => {
  return (
    <UserProvider> <div className=" ">
      <Header />
      <div
        className="row"
        style={{ marginTop: "50px" }}
      >
        {/* Sidebar */}
        <div className="col-md-2">
          <Sidebar />
        </div>

        {/* Main Content */}
        <div className="col-md-10">
        
        <ListViewIPD />
        
        </div>
      </div>
    </div> </UserProvider>
  );
};
export const EditIPD = () => {
  return (
    <UserProvider> <div className=" ">
      <Header />
      <div
        className="row"
        style={{ marginTop: "50px" }}
      >
        {/* Sidebar */}
        <div className="col-md-2">
          <Sidebar />
        </div>

        {/* Main Content */}
        <div className="col-md-10">
        
        <EditIpdPatientAdmit />
        
        </div>
      </div>
    </div> </UserProvider>
  );
};


export const DischargedViewComponent = () => {
  return (
    <UserProvider> <div className=" ">
      <Header />
      <div
        className="row"
        style={{ marginTop: "50px" }}
      >
        {/* Sidebar */}
        <div className="col-md-2">
          <Sidebar />
        </div>

        {/* Main Content */}
        <div className="col-md-10">
        
        <DischargedView />
        
        </div>
      </div>
    </div> </UserProvider>
  );
};
export const OverViewComponent = () => {
  return (
    <UserProvider> <div className=" ">
      <Header />
      <div
        className="row"
        style={{ marginTop: "50px" }}
      >
        {/* Sidebar */}
        <div className="col-md-2">
          <Sidebar />
        </div>

        {/* Main Content */}
        <div className="col-md-10">
        
        <Overview/>
        
        </div>
      </div>
    </div>  </UserProvider>
  );
};