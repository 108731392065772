import React, { useRef, useEffect, useState } from "react";
import { useReactToPrint } from "react-to-print";
import image from "./PrintTemplateImageLogo.png";
import { useParams, useHistory } from "react-router-dom";
import "./PrintPrescriptionCss.css";
import { Grid } from "@mui/material";
import axios from "../../helper/axios";
import moment from "moment/moment";
import API from "../../helper/axios";
import { useUser } from "../../Provider";
const PrintPrescription = (props) => {
  const { user, userHospitalcode, userrole1, isAuthRole, isAuthorized, gettokenId, token } = useUser();
  const { admitnumber } = useParams();
  console.log(admitnumber)
  const history = useHistory();
  const params = useParams();
  const today = new Date();
  //const token = localStorage.getItem("jwtToken");
  const [PrescriptionData, setPrescriptionData] = useState({});
  const [billItems, setBillItems] = useState([]);
  console.log(props.ipdPrescription);
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    //documentTitle: PrescriptionData.opd,
    onAfterPrint: props.handleClose,
  });
  const [data, setData] = useState([]);

  // Function to get login user's details

  //when page load or refresh happen this function will got called
      useEffect(() => {
        const loadData = async () => {
    
          //loadHospitalLogo(); will wait for loadAllDetails(); to got execute
          if (PrescriptionData.hospitalcode) {
            await loadHospitalLogo();
          }
        };
        loadData();
      }, [userHospitalcode, userrole1, isAuthorized, PrescriptionData]);
    
 
  useEffect(() => {
    getTransactionDetails();
    getPatientBillDetails();
  }, []);
  const getPatientBillDetails = async () => {
    try {
      const res = await axios.get(
        `/prescriptionmedicinebynumber/${admitnumber}/${props.ipdPrescription}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (res.status === 200) {
        // If the request is successful (status code 200), set the bill items data.
        setBillItems(res.data);
      } else {
        // Handle unexpected response status codes, e.g., 404 or 500.
        alert(`Unexpected status code: ${res.status}`);
      }
    } catch (error) {
      // Handle network errors or other exceptions.
      alert(`An error occurred: ${error.message}`);
      // Optionally, you can set an error state or display an error message to the user.
    }
  };
  const loadHospitalLogo = async () => {
    //when userHospitalcode is not undefined
    if (userHospitalcode !== undefined ) {
      console.log(userHospitalcode)
      const res = await axios.get(`/gethospitallogo/${PrescriptionData.hospitalcode}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          hospitalcode: PrescriptionData.hospitalcode, // Include the hospitalcode in the query parameters
          //userrole: userrole1, // Include the userrole in the query parameters
        },
      });
      setData(res.data[0]);
      console.log(res.data);
    };
  }
  //get the Patient details according to patientid
  const getTransactionDetails = async () => {
    const res = await axios.get(`/getprescriptionbynumber/${admitnumber}/${props.ipdPrescription}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    console.log(res.data[0])
    setPrescriptionData(res.data[0])
  }


  return (
    <>
      <button
        className="btn btn-success"
        style={{ marginLeft: "68%", backgroundColor: "white", color: "green" }}
        onClick={handlePrint}
      >
        Print Prescription Detail
      </button>

      <div style={{ width: '100%', height: '100%', padding: "40px" }}ref={componentRef}>
        {/* image will come dynamically */}
        <div   style={{ textAlign: "center", marginTop: "-30px" }}>
        <img src={`${API.defaults.baseURL}/uploadimage/attachone/${data.attachment1}`}
          style={{ width: '100%', marginBottom: "1px", height: "23%" }} />
        </div>
        <div>
          <div>
            {/* <Grid container spacing={3} className="container" style={{ paddingTop: "20px" }}> */}

            {/* Medication Details */}
            <Grid container spacing={3} >
              <Grid item xs={12} >
                <span className="label">Header Note:</span>
                <div dangerouslySetInnerHTML={{ __html: PrescriptionData.headernote }} />
              </Grid >
              <Grid item xs={6} >
                <span className="label">Date:</span>
                {PrescriptionData.date}
              </Grid>

              <Grid item xs={6} >
                <span className="label">Prescribe By:</span>
                {PrescriptionData.fname}
              </Grid>
              <Grid item xs={6} >
                <span className="label">IPD No:</span>
                {PrescriptionData.admitnumber}
              </Grid>
              <Grid item xs={6} >
                <span className="label">Prescription No:</span>
                {PrescriptionData.prescriptionnumber}
              </Grid>

              <Grid item xs={6} >
                <span className="label">Finding Category:</span>
                {PrescriptionData.findingcategory}
              </Grid>

              <Grid item xs={12} >
                <span className="label">Finding Description:</span>
                {PrescriptionData.findingdescription}
              </Grid>

              <Grid item xs={12} >
                <span className="label">Pathology:</span>
                {PrescriptionData.pathology}
              </Grid>
              <Grid item xs={12}>
                <span className="label">Radiology:</span>
                {PrescriptionData.radiology}
              </Grid>
            </Grid>
            {/* </Grid> */}
            <table
              style={{
                width: "100%",
              }}
            >
              <thead style={{ fontSize: "12px" }}>
                <tr>
                  <th>SNo.</th>
                  <th>Medicine Chategory</th>
                  <th>Medicine Name</th>
                  <th>Dosage</th>
                  <th>Dose Interval</th>
                  <th>Dose Duration</th>
                  <th>Instruction</th>
                </tr>
              </thead>
              <tbody style={{ fontSize: "12px" }}>
                {billItems.map((item, index) => {
                  if (item.medicinename.length != 0) {
                    return (
                      <tr key={index} style={{ borderTop: "1px solid black" }}>
                        <td>{index + 1}</td>
                        <td>{item.medicinecategory}</td>
                        <td>{item.medicinename}</td>
                        <td>{item.dosage}</td>
                        <td>{item.doseinterval}</td>
                        <td>{item.doseduration}</td>
                        <td>{item.instruction}</td>
                      </tr>
                    );
                  }
                })}
              </tbody>
            </table>
          </div>
          {/* <div class="box"></div> */}
          <hr style={{ marginTop: "0px" }} />
          {/* <div className="vl"></div> */}
          <div>
          <Grid item xs={12} >
              <span className="label">Footer Note:</span>
              <div dangerouslySetInnerHTML={{ __html: PrescriptionData.footernote }} />
            </Grid >
          </div>
        </div>
      </div>
    </>
  );
};

export default PrintPrescription;
