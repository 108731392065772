import { useState, useEffect } from "react";
import axios from '../helper/axios';
import Autocomplete from "@mui/material/Autocomplete";
import {
    TextField,
    InputAdornment,
    IconButton,
} from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import { useUser } from "../Provider";

const DoctorSearchIds = ({ onSearchChanges, hospitalvalue }) => {
    const history = useHistory();
    const { user, userHospitalcode, userrole1, isAuthRole, isAuthorized, gettokenId, token } = useUser();
    const [value, setValue] = useState("");
    const [data, setDoctor] = useState([]);
   // const [selectedStaff, setSelectedStaff] = useState('');
    const [openAutocomplete, setOpenAutocomplete] = useState(false);

    useEffect(() => {
        const loadData = async () => {
            await getDoctorDetails();
           // setSelectedStaff("");
        };

        loadData();
    }, [userrole1, hospitalvalue]);

    const getDoctorDetails = async () => {
        try {
            if (hospitalvalue !== undefined && userrole1 !== undefined) {
                const res = await axios.get(`/getdoctor`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                    params: {
                        hospitalcode: hospitalvalue,
                        userrole: userrole1,
                    },
                });

                if (res.status === 200) {
                    setDoctor(res.data);
                } else {
                    alert(`Request failed with status: ${res.status}`);
                }
            }
        } catch (error) {
            if (error.request) {
                // toast.error("No response received from the server");
            } else {
                toast.error("Error:", error.message);
            }
        }
    };

    const onSearch = (searchTerms, item) => {
      //  setSelectedStaff(item);
        onSearchChanges(item);
    };

    const getOptionLabel = (option) => {
        return option?.fname || '';
    };

    const handleExpandClick = () => {
        setOpenAutocomplete(!openAutocomplete);
    };
    return (
        <>
            <div className="searchbar">
                
                <Autocomplete
                    freeSolo
                    id="free-solo"
                    options={data}
                    getOptionLabel={getOptionLabel}
                    open={openAutocomplete}
                    onOpen={() => setOpenAutocomplete(true)}
                    onClose={() => setOpenAutocomplete(false)}
                    onChange={onSearch}
                    renderInput={(params) =>
                        <TextField
                            {...params}
                            placeholder="Select Doctor"
                            InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton onClick={handleExpandClick}>
                                            <ExpandMoreIcon />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    }
                    renderOption={(props, option) => (
                        <li {...props}>
                            {option.fname}
                        </li>
                    )}
                />

            </div>
        </>
    );
}

export default DoctorSearchIds;