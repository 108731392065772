import React, { useState } from "react";
import { useUser } from "../../../Provider";
import { Tabs, Tab, Box, Typography} from "@mui/material";
import DischargedView from "./DischargedView";
import ListViewIPD from "./ViewListIPD";
import AddBed from "../../Bed/BedPages/AddBed";
import AppsIcon from '@mui/icons-material/Apps';
import NoteAltIcon from '@mui/icons-material/NoteAlt';
import MedicationIcon from '@mui/icons-material/Medication';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import BiotechIcon from '@mui/icons-material/Biotech';
import ContentCutIcon from '@mui/icons-material/ContentCut';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import PaidIcon from '@mui/icons-material/Paid';
import HotelIcon from '@mui/icons-material/Hotel';
import EditIpdPatientAdmit from "./EditIpdPatientAdmit";
import EditIcon from '@mui/icons-material/Edit';
import OverViewIPD from "./OverViewIPD";
import MedicationTable from "./MedicationTable";
import PrescriptionTable from "./PrescriptionTable";
import ConsultantRegisterTable from "./ConsultantRegisterTable";
import LabInvestigationTable from "./LabInvestigationTable";
import OperationTable from "./OperationTable";
import PaymentTable from "./PaymentTable";
import ChargesTable from "./ChargesTable ";
import BedTable from "./BedTable";
import NurseNote from "./NurseNote";
import NurseNoteForm from "./NurseNoteForm";
import MedicationeForm from "./MedicationForm";
import PaymentForm from "./PaymentForm";
import OperationForm from "./OperationForm";
import ConsultantRegisterForm from "./ConsultantRegisterForm";
import Chargesform from "./Chargesform";
import PrescriptionForm from "./PrescriptionForm";
import IpdRadiology from "./IpdRadiology";



const Overview = () => {
  const { user, userHospitalcode, userrole1, isAuthRole, isAuthorized, gettokenId } = useUser();
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box>
     <Tabs value={value} onChange={handleChange} variant="scrollable" scrollButtons="auto">
     <Tab
          label={
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <AppsIcon sx={{ marginRight: 1 }} />
              <Typography style={{ textTransform: 'none' }}>Overview</Typography>
            </Box>
          }
        />
         <Tab
          label={
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <EditIcon sx={{ marginRight: 1 }} />
              <Typography style={{ textTransform: 'none' }}>Update IPD</Typography>
            </Box>
          }
        />
           <Tab
          label={
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <NoteAltIcon sx={{ marginRight: 1 }} />
              <Typography style={{ textTransform: 'none' }}>Nurse Note</Typography>
            </Box>
          }
        />
          <Tab
          label={
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <MedicationIcon sx={{ marginRight: 1 }} />
              <Typography style={{ textTransform: 'none' }}>Medication</Typography>
            </Box>
          }
        />
       <Tab
          label={
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <NoteAddIcon sx={{ marginRight: 1 }} />
              <Typography style={{ textTransform: 'none' }}>Prescription</Typography>
            </Box>
          }
        />
         <Tab
          label={
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <AppsIcon sx={{ marginRight: 1 }} />
              <Typography style={{ textTransform: 'none' }}>Consultant Register</Typography>
            </Box>
          }
        />
       <Tab
          label={
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <BiotechIcon sx={{ marginRight: 1 }} />
              <Typography style={{ textTransform: 'none' }}>Lab Investigation</Typography>
            </Box>
          }
        />
      <Tab
          label={
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <ContentCutIcon sx={{ marginRight: 1 }} />
              <Typography style={{ textTransform: 'none' }}>Operations</Typography>
            </Box>
          }
        />
       <Tab
          label={
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <AttachMoneyIcon sx={{ marginRight: 1 }} />
              <Typography style={{ textTransform: 'none' }}>Charges</Typography>
            </Box>
          }
        />
        <Tab
          label={
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <PaidIcon sx={{ marginRight: 1 }} />
              <Typography style={{ textTransform: 'none' }}>Payment</Typography>
            </Box>
          }
        />
       <Tab
          label={
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <HotelIcon sx={{ marginRight: 1 }} />
              <Typography style={{ textTransform: 'none' }}>Bed History</Typography>
            </Box>
          }
        />  
      </Tabs>
      <TabPanel value={value} index={0}>
        <OverViewIPD/>
      </TabPanel>

      <TabPanel value={value} index={1}>
        <EditIpdPatientAdmit  />
      </TabPanel>
      <TabPanel value={value} index={2}>
      {/* <NurseNoteForm/> */}
      <NurseNote  />
      </TabPanel>
      <TabPanel value={value} index={3}>
      {/* <MedicationeForm/> */}
      <MedicationTable/>
      </TabPanel>
      <TabPanel value={value} index={4}>
      {/* <PrescriptionForm/> */}
      <PrescriptionTable/>
      </TabPanel>
      <TabPanel value={value} index={5}>
      {/* <ConsultantRegisterForm/> */}
      <ConsultantRegisterTable/>
      </TabPanel>
      <TabPanel value={value} index={6}>
      <LabInvestigationTable/>
      <IpdRadiology/>
      </TabPanel>
      <TabPanel value={value} index={7}>
      {/* <OperationForm/> */}
      <OperationTable/>
      </TabPanel>
      <TabPanel value={value} index={8}>
      {/* <Chargesform/> */}
      <ChargesTable/>
      </TabPanel>
      <TabPanel value={value} index={9}>
      {/* <PaymentForm/> */}
      <PaymentTable/>
      </TabPanel>
      <TabPanel value={value} index={10}>
      <BedTable/>
      </TabPanel>
    </Box>
  );
};

const TabPanel = ({ children, value, index }) => {
  return (
    <div role="tabpanel" hidden={value !== index}>
      {value === index && <Box>{children}</Box>}
    </div>
  );
};

export default Overview;
