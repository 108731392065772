import React, { useEffect, useState } from 'react';
// import { Grid, Divider, Typography } from '@mui/material';
import { Grid, Divider, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper,TextareaAutosize } from '@mui/material';
import MedicationTable from './MedicationTable';
import PrescriptionTable from './PrescriptionTable';
import ConsultantRegisterTable from './ConsultantRegisterTable';
import LabInvestigationTable from './LabInvestigationTable';
import OperationTable from './OperationTable';
import PaymentTable from './PaymentTable';
import ChargesTable from './ChargesTable ';
import BedTable from './BedTable';
import NurseNote from './NurseNote';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { useUser } from '../../../Provider';
import axios from '../../../helper/axios'; // Assuming axios is used for API calls
import IpdRadiology from './IpdRadiology';
   
const OverViewIPD = () => { 
    const { admitnumber } = useParams();
const { user, userHospitalcode, userrole1, isAuthRole, isAuthorized, gettokenId,token } = useUser();
console.log(userHospitalcode)
const [admit, setAdmit] = useState({
    admitdate: "",
    empid: "",
    PID: "",
    hospitalcode: "",
    bedid: "",
    patientname: "",
    phoneno: "",
    age: "",
    address: "",
    gender: "",
    email: "",
    bloodgroup: "",
    // Add more fields as needed
});
    const loadAllIPDDetails = async () => {
        try {
            if (userHospitalcode !== undefined && userrole1 !== undefined) {
                const response = await axios.get(`/getipd/${admitnumber}`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                    params: {
                        hospitalcode: userHospitalcode,
                        userrole: userrole1,
                    },
                });
    
                const addmiteddata = response.data;
                console.log(addmiteddata)
                setAdmit(addmiteddata);
           

                // const ipddata = response.data;
                // console.log('Before setValues:', values);
                // setValues((prevValues) => ({
                //     ...prevValues,
                //     ...ipddata,
                // }));
                // console.log('After setValues:', values);
            }
        } catch (error) {
            console.error('Error loading IPD details:', error.message);
            alert('Something Went Wrong');
        }
    };
    useEffect(() => {
        const loadData = async () => {
           
            await loadAllIPDDetails();
          
        };
        loadData();
    }, [userHospitalcode, userrole1, isAuthorized, admit.hospitalcode]);
    return (
        <div>
            <Grid item xs={12}>
                <Divider style={{ backgroundColor: '#000000', height: '100%' }} />
            </Grid>
            <Grid container spacing={2}>
                {/* Left Section */}
                <Grid item xs={4}>
                    <Typography variant="h8" style={{ padding: '13px', }}>
                    Patient Name: {admit.patientname}
                    </Typography>

                    {/* Nested Grid for additional content */}
                    <Grid container spacing={2} style={{ padding: '10px' }}>
                        <Grid item xs={12}>
                            <Divider style={{ backgroundColor: '#000000', width: '100%' }} />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography>Gender: {admit.gender}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography>Age: {admit.age}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography>Phone: {admit.phoneno}</Typography>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} style={{ padding: '10px' }}>
                        <Grid item xs={12}>
                            <Divider style={{ backgroundColor: '#000000', width: '100%' }} />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography>Case ID: </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography>IPD No: { admitnumber } </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography>Admission Date: {admit.admitdate}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography>Bed: {admit.bedname}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Divider style={{ backgroundColor: '#000000', width: '100%' }} />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} style={{ padding: '10px' }}>
                        <Grid item xs={12}>
                            <Typography>Known Allergies: </Typography>
                        </Grid>

                        <Grid item xs={12}>
                            <Divider style={{ backgroundColor: '#000000', width: '100%' }} />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} style={{ padding: '10px' }}>
                        <Grid item xs={12}>
                            <Typography>Finding: </Typography>
                        </Grid>

                        <Grid item xs={12}>
                            <Divider style={{ backgroundColor: '#000000', width: '100%' }} />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} style={{ padding: '10px' }}>
                        <Grid item xs={12}>
                            <Typography> Symptoms: </Typography>
                        </Grid>

                        <Grid item xs={12}>
                            <Divider style={{ backgroundColor: '#000000', width: '100%' }} />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} style={{ padding: '10px' }}>
                        <Grid item xs={12}>
                            <Typography>CONSULTANT DOCTOR</Typography>
                        </Grid>

                        <Grid item xs={12}>
                            <Divider style={{ backgroundColor: '#000000', width: '100%' }} />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} style={{ padding: '10px' }}>
                        <Grid item xs={12}>
                            <Typography>NURSE NOTES</Typography>
                            <NurseNote time= "10.30am" note="wefersg" hideForm={true} />
                        </Grid>

                        <Grid item xs={12}>
                            <Divider style={{ backgroundColor: '#000000', width: '100%' }} />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} style={{ padding: '10px' }}>
                        <Grid item xs={12}>
                            <Typography>TIMELINE</Typography>
                        </Grid>
                    </Grid>
                </Grid>

                {/* Vertical Line */}
                <Grid item xs={0}>
                    <Divider orientation="vertical" style={{ backgroundColor: '#000000', height: '100%' }} />
                </Grid>

                {/* Right Section */}
                <Grid item xs={7}>

                    {/* Nested Grid for additional content */}
                    <Grid container spacing={2} style={{ padding: '0px' }}>
                        <Grid item xs={6}>
                            <Typography>IPD PAYMENT/BILLING</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography>PHARMACY PAYMENT/BILLING</Typography>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} style={{ padding: '10px' }}>
                        <Grid item xs={12}>
                            <Divider style={{ backgroundColor: '#000000', width: '100%' }} />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography>MEDICATION </Typography>
                            <MedicationTable hideForm={true}/>
                        </Grid>

                        <Grid item xs={12}>
                            <Divider style={{ backgroundColor: '#000000', width: '100%' }} />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} style={{ padding: '10px' }}>
                        <Grid item xs={12}>
                            <Typography>PRESCRIPTION </Typography>
                            <PrescriptionTable hideForm={true}/>
                        </Grid>

                        <Grid item xs={12}>
                            <Divider style={{ backgroundColor: '#000000', width: '100%' }} />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} style={{ padding: '10px' }}>
                        <Grid item xs={12}>
                            <Typography>CONSULTANT REGISTER </Typography>
                            <ConsultantRegisterTable hideForm={true}/>
                        </Grid>

                        <Grid item xs={12}>
                            <Divider style={{ backgroundColor: '#000000', width: '100%' }} />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} style={{ padding: '10px' }}>
                        <Grid item xs={12}>
                            <Typography> LAB INVESTIGATION </Typography>
                            <LabInvestigationTable/>
                            <IpdRadiology/>
                        </Grid>

                        <Grid item xs={12}>
                            <Divider style={{ backgroundColor: '#000000', width: '100%' }} />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} style={{ padding: '10px' }}>
                        <Grid item xs={12}>
                            <Typography>OPERATION</Typography>
                            <OperationTable hideForm={true}/>
                        </Grid>

                        <Grid item xs={12}>
                            <Divider style={{ backgroundColor: '#000000', width: '100%' }} />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} style={{ padding: '10px' }}>
                        <Grid item xs={12}>
                            <Typography>CHARGES</Typography>
                            <ChargesTable hideForm={true}/>
                        </Grid>

                        <Grid item xs={12}>
                            <Divider style={{ backgroundColor: '#000000', width: '100%' }} />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} style={{ padding: '10px' }}>
                        <Grid item xs={12}>
                            <Typography>PAYMENT</Typography>
                            <PaymentTable hideForm={true}/>
                        </Grid>

                        <Grid item xs={12}>
                            <Divider style={{ backgroundColor: '#000000', width: '100%' }} />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} style={{ padding: '10px' }}>
                        <Grid item xs={12}>
                            <Typography>BED HISTORY</Typography>
                            <BedTable/>
                        </Grid>
                    </Grid>
                </Grid>

            </Grid>
        </div>
    );
};

export default OverViewIPD;
