import React from "react";
import AppointmentReport from "./AppointmentReport";
import Header from "../../Component/Header/Header";
import Sidebar from "../../Component/Sidebar";
import { UserProvider } from "../../Provider";
import BillingReport from "./BillingReport";
export const ReportAppointment= () => {
    return (
  
      < UserProvider>  <div className=" ">
      <Header/>
    <div className="row" style={{ marginTop: '50px' }}>
      {/* Sidebar */}
      <div className="col-md-2">
        <Sidebar/>
      </div>
      
      {/* Main Content */}
      <div className="col-md-10">
      < UserProvider>
      <AppointmentReport/>
      </UserProvider>
        
      </div>
    </div>
  </div></UserProvider>
            
    );
  };
  export const ReportBilling= () => {
    return (
  
      < UserProvider>  <div className=" ">
      <Header/>
    <div className="row" style={{ marginTop: '50px' }}>
      {/* Sidebar */}
      <div className="col-md-2">
        <Sidebar/>
      </div>
      
      {/* Main Content */}
      <div className="col-md-10">
       
        <BillingReport/>
       
      </div>
    </div>
  </div></UserProvider>
            
    );
  };