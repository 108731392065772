import React, { useState, useEffect } from 'react';
import { Form, Button } from 'react-bootstrap';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { NavLink, useHistory, useParams } from 'react-router-dom/cjs/react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from '../../../helper/axios'; // Assuming axios is used for API calls
import { Helmet } from 'react-helmet';
import { useUser } from "../../../Provider";
import { TextField, Autocomplete, InputAdornment, IconButton } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
const validationSchema = Yup.object({
    admitdate: Yup.date().required('values Date is required'),

});
const initialValues = {
    admitdate: "",
    empid: "",
    PID: "",
    hospitalcode: "",
    bedid: "",
    patientname: "",
    phoneno: "",
    age: "",
    address: "",
    gender: "",
    email: "",
    bloodgroup: "",
};
const EditIpdPatientAdmit = () => {
    const { admitnumber } = useParams();
    const { user, userHospitalcode, userrole1, isAuthRole, isAuthorized, gettokenId, token } = useUser();
    //const token = sessionStorage.getItem("jwtToken");
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);
    const history = useHistory();
    const [currentDateTime, setCurrentDateTime] = useState('');
    const [data, setData] = useState([]);
    const [availableBedNumbers, setAvailableBedNumbers] = useState([]);
    const [availableStaffs, setAvailableStaffs] = useState([]);
    const [openAutocompleted, setOpenAutocompleted] = useState(false);

    const getCurrentDateTime = () => {
        const now = new Date();
        const year = now.getFullYear();
        const month = String(now.getMonth() + 1).padStart(2, '0');
        const day = String(now.getDate()).padStart(2, '0');
        const hours = String(now.getHours()).padStart(2, '0');
        const minutes = String(now.getMinutes()).padStart(2, '0');
        const currentDateTimeString = `${year}-${month}-${day}T${hours}:${minutes}`;
        return currentDateTimeString;
    };

    let Showdate = new Date();
    let ShowTodaydate =
        Showdate.getFullYear() +
        "-" +
        (Showdate.getMonth() + 1) +
        "-" +
        Showdate.getDate();
    const [currentDate, setCurrentDate] = useState(ShowTodaydate);
    const {
        values,
        errors,
        touched,
        setValues,
        handleChange,
        handleBlur,
        resetForm,
        handleSubmit,

    } = useFormik({
        initialValues,
        //validationSchema: getValidationSchema,
        onSubmit: async (values, { resetForm }) => {
            console.log(values); // Log the values to check if they are captured correctly

            try {
                if (userHospitalcode !== undefined) {
                    const data = new FormData();

                    // Append all form fields to the FormData object
                    data.append('document', JSON.stringify(values));
                    // ... append other fields as needed

                    data.append('userHospitalcode', userHospitalcode);
                    data.append('currentDateTime', JSON.stringify(getCurrentDateTime()));
                    data.append('gettokenId', gettokenId);
                    data.append('currentDate', JSON.stringify(currentDate));

                    console.log(JSON.stringify(values));

                    const response = await axios.post(`/editipd/'${admitnumber}'`, data, {
                        headers: {
                            Authorization: `Beaxrer ${token}`,
                            "Content-Type": "multipart/form-data",
                        },
                        params: {
                            hospitalcode: values.hospitalcode, // Include the hospitalcode in the query parameters
                            userrole: userrole1, // Include the userrole in the query parameters
                        },
                    },);

                    if (response.status === 200) {
                        return history.push("/listviewIPD");
                    } else {
                        console.error('Error in admitting patient');
                        setSuccess(false);
                        setError(true);
                    }
                }
            } catch (error) {
                console.error('Error:', error.message);
                setSuccess(false);
                setError(true);
                toast.error('Something Went Wrong');
            }

        },
    });

    const handleExpandClicked = () => {
        setOpenAutocompleted(!openAutocompleted);
    };
    const loadAllIPDDetails = async () => {
        try {
            if (userHospitalcode !== undefined && userrole1 !== undefined) {
                const response = await axios.get(`/getipd/${admitnumber}`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                    params: {
                        hospitalcode: userHospitalcode,
                        userrole: userrole1,
                    },
                });

                const addmiteddata = response.data;
                console.log(addmiteddata)
                setValues(addmiteddata);
            }
        } catch (error) {
            console.error('Error loading IPD details:', error.message);
            alert('Something Went Wrong');
        }
    };
    useEffect(() => {
        const loadData = async () => {
            await loadHospitalcode();
            await loadAllIPDDetails();
            await getAvailableBedNumbers(values.hospitalcode);
            await getAvailableStaff(values.hospitalcode);
        };
        loadData();
    }, [userHospitalcode, userrole1, isAuthorized, values.hospitalcode]);

    const getAvailableBedNumbers = async () => {
        try {
            if (userHospitalcode !== undefined && userrole1 !== undefined) {
                const response = await axios.get(`/getipdbed/${admitnumber}`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                    params: {
                        hospitalcode: values.hospitalcode,
                        userrole: userrole1,
                    },
                });

                if (response.status === 200) {
                    const patientBed = response.data.patientBed || [];
                    const availableBeds = response.data.availableBeds || [];
                    setAvailableBedNumbers([...patientBed, ...availableBeds]);
                    console.log(patientBed)
                    console.log(availableBeds)
                } else {
                    console.error(`Failed to fetch available bed numbers with status: ${response.status}`);
                }
            }
        } catch (error) {
            console.error('Error fetching available bed numbers:', error.message);
        }
    };

    console.log('Available Bed Numbers:', availableBedNumbers);

    const getAvailableStaff = async () => {
        try {
            const response = await axios.get(`/getAvailableStaffs`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                params: {
                    hospitalcode: values.hospitalcode,
                },
            });

            if (response.status === 200) {
                setAvailableStaffs(response.data);
            } else {
                console.error(`Failed to fetch available staff with status: ${response.status}`);
            }
        } catch (error) {
            console.error('Error fetching available staff number:', error.message);
        }
    };

    useEffect(() => {
        setCurrentDateTime(getCurrentDateTime());
    }, []);
    const loadHospitalcode = async () => {
        //when userHospitalcode and userrole1 is not undefined then execute /gethospitalcode
        if (userHospitalcode !== undefined && userrole1 !== undefined) {
            const res = await axios.get("/gethospitalcode", {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                params: {
                    hospitalcode: userHospitalcode, // Include the hospitalcode in the query parameters
                    userrole: userrole1, // Include the userrole in the query parameters
                },
            });
            setData(res.data);
            console.log(res.data);
        };
    }
    const currentDomain = window.location.hostname;

    return (
        <div className="p-3 pt-0 appointment">
            <Helmet>
                <title> Update Ipd | {currentDomain} | Hospital Management Software | Smart Solutions for Hospital Efficiency</title>
            </Helmet>
            <Form onSubmit={handleSubmit}>
                {success && <div>Patient values</div>}
                {error && <div>Error in registering patient. please try again</div>}
                <div className="patient-new primary-info">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="row">
                                <div className="col-md-6">

                                    <div className="col-md-12 mt-3">
                                        <div className="row">
                                            <div className="col-md-4">
                                                <label
                                                    htmlFor="patientname"
                                                    className="col-form-label leftone"
                                                >
                                                    Patient Name
                                                </label>
                                            </div>
                                            <div className="col-md-8">
                                                <input
                                                    type="text"
                                                    autoComplete="off"
                                                    name="patientname"
                                                    id="patientname"
                                                    placeholder="Patient Name"
                                                    value={values.patientname}
                                                    // onChange={handleChange}
                                                    // onBlur={handleBlur}
                                                    className="form-control ember-text-field text-left ember-view"
                                                />
                                                {/* {errors.patientname && touched.patientname ? (
                                                    <p className="form-erroremployee">{errors.patientname}</p>
                                                ) : null} */}
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md-12 mt-3">
                                        <div className="row">
                                            <div className="col-md-4">
                                                <label
                                                    htmlFor="age"
                                                    className="col-form-label leftone"
                                                >
                                                    Age
                                                </label>
                                            </div>
                                            <div className="col-md-8">
                                                <input
                                                    type="number"
                                                    autoComplete="off"
                                                    name="age"
                                                    id="age"
                                                    placeholder="Age"
                                                    value={values.age}
                                                    disabled
                                                    // onChange={handleChange}
                                                    // onBlur={handleBlur}
                                                    className="form-control ember-text-field text-left ember-view"
                                                ></input>
                                                {/* {errors.age && touched.age ? (
                                                    <p className="form-erroremployee">{errors.age}</p>
                                                ) : null} */}
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md-12 mt-3">
                                        <div className="row">
                                            <div className="col-md-4">
                                                <label
                                                    htmlFor="gender"
                                                    className="col-form-label leftone"
                                                >
                                                    Gender
                                                </label>
                                            </div>
                                            <div className="col-md-8">
                                                <select
                                                    type="text"
                                                    autoComplete="off"
                                                    name="gender"
                                                    id="gender"
                                                    placeholder="Gender"
                                                    value={values.gender}
                                                    // onChange={handleChange}
                                                    // onBlur={handleBlur}
                                                    disabled
                                                    className="form-control"
                                                >
                                                    <option>Select</option>
                                                    <option>Male</option>
                                                    <option>Female</option>
                                                    <option>Others</option>
                                                </select>
                                                {/* {errors.gender && touched.gender ? (
                                                    <p className="form-erroremployee">{errors.gender}</p>
                                                ) : null} */}
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md-12 mt-3">
                                        <div className="row">
                                            <div className="col-md-4">
                                                <label
                                                    htmlFor="email"
                                                    className="col-form-label"
                                                >
                                                    Email
                                                </label>
                                            </div>
                                            <div className="col-md-8">
                                                <input
                                                    type="email"
                                                    autoComplete="off"
                                                    name="email"
                                                    id="email"
                                                    placeholder="Email"
                                                    value={values.email}
                                                    // onChange={handleChange}
                                                    // onBlur={handleBlur}
                                                    disabled
                                                    className="form-control ember-text-field text-left ember-view"
                                                ></input>
                                                {/* {errors.email && touched.email ? (
                                                    <p className="form-erroremployee">{errors.email}</p>
                                                ) : null} */}
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md-12 mt-3">
                                        <div className="row">
                                            <div className="col-md-4">
                                                <label
                                                    htmlFor="bloodgroup"
                                                    className="col-form-label "
                                                >
                                                    Blood Group
                                                </label>
                                            </div>
                                            <div className="col-md-8">
                                                <input
                                                    type="text"
                                                    autoComplete="off"
                                                    name="bloodgroup"
                                                    id="bloodgroup"
                                                    placeholder="Blood Group"
                                                    value={values.bloodgroup}
                                                    // onChange={handleChange}
                                                    // onBlur={handleBlur}
                                                    disabled
                                                    className="form-control ember-text-field text-left ember-view"
                                                />
                                                {/* 
                                                {errors.bloodgroup && touched.bloodgroup ? (
                                                    <p className="form-erroremployee">{errors.bloodgroup}</p>
                                                ) : null} */}
                                            </div>
                                        </div>
                                    </div>


                                    <div className="col-md-12 mt-3">
                                        <div className="row">
                                            <div className="col-md-4">
                                                <label
                                                    htmlFor="admitdate"
                                                    className="col-form-label leftone"
                                                >
                                                    values Date
                                                </label>
                                            </div>
                                            <div className="col-md-8">
                                                <input
                                                    type="date"
                                                    autoComplete="off"
                                                    name="admitdate"
                                                    id="admitdate"
                                                    placeholder="values Date"
                                                    value={values.admitdate}
                                                    onChange={handleChange}
                                                    // onChange={handleChange}
                                                    // onBlur={handleBlur}
                                                    className="form-control ember-text-field text-left ember-view"
                                                />
                                                {/* {errors.patientname && touched.patientname ? (
                    <p className="form-erroremployee">{errors.patientname}</p>
                  ) : null} */}
                                            </div>
                                        </div>
                                    </div>


                                    <div className="col-md-12 mt-3">
                                        <div className="row">
                                            <div className="col-md-4">
                                                <label
                                                    htmlFor="attendername"
                                                    className="col-form-label leftone"
                                                >
                                                    Attender Name
                                                </label>
                                            </div>
                                            <div className="col-md-8">
                                                <input
                                                    type="text"
                                                    autoComplete="off"
                                                    name="attendername"
                                                    id="attendername"
                                                    placeholder="Attender Name"
                                                    value={values.attendername}

                                                    onChange={handleChange}
                                                    //   onBlur={handleBlur}
                                                    className="form-control ember-text-field text-left ember-view"
                                                />
                                                {/* {errors.attendername && touched.attendername ? (
                          <p className="form-erroremployee">{errors.attendername}</p>
                        ) : null} */}
                                            </div>
                                        </div>
                                    </div>


                                </div>

                                <div className="col-md-6">
                                    <div className="col-md-12">
                                        <div className="row">
                                            <div className="col-md-4 mt-3">
                                                <label
                                                    htmlFor="patientid"
                                                    className="col-form-label leftone"
                                                >
                                                    Patient Id
                                                </label>

                                            </div>
                                            <div className="col-md-8 mt-3">
                                                <input
                                                    type="text"
                                                    autoComplete="off"
                                                    name="patientid"
                                                    id="patientid"
                                                    placeholder="Patient Id"
                                                    value={values.patientid}
                                                    // onChange={handleChange}
                                                    disabled

                                                    className="form-control ember-text-field text-left ember-view"
                                                />
                                                {/* {errors.PID && touched.PID ? (
                                                    <p className="form-error">{errors.PID}</p>
                                                ) : null} */}
                                            </div>
                                        </div>

                                    </div>

                                    <div className="col-md-12 mt-3">
                                        <div className="row">
                                            <div className="col-md-4">
                                                <label
                                                    htmlFor="phoneno "
                                                    className="col-form-label leftone"
                                                >
                                                    Mobile Number
                                                </label>
                                            </div>
                                            <div className="col-md-8">
                                                <input
                                                    type="number"
                                                    autoComplete="off"
                                                    name="phoneno"
                                                    id="phoneno"
                                                    placeholder="Mobile Number"
                                                    value={values.phoneno}
                                                    // onChange={handleChange}
                                                    disabled
                                                    className="form-control ember-text-field text-left ember-view"
                                                />

                                                {/* {errors.phoneno && touched.phoneno ? (
                                                    <p className="form-erroremployee">{errors.phoneno}</p>
                                                ) : null} */}
                                            </div></div></div>

                                    <div className="col-md-12 mt-3">
                                        <div className="row">
                                            <div className="col-md-4">
                                                <label
                                                    htmlFor="address"
                                                    className="col-form-label leftone"
                                                >
                                                    Address
                                                </label>
                                            </div>
                                            <div className="col-md-8">
                                                <textarea
                                                    rows="1"
                                                    cols="30"
                                                    type="text"
                                                    autoComplete="off"
                                                    name="address"
                                                    id="address"
                                                    placeholder="Address"
                                                    value={values.address}
                                                    // onChange={handleChange}
                                                    // onBlur={handleBlur}
                                                    disabled
                                                    className="form-control"

                                                />
                                                {/* {errors.address && touched.address ? (
                                                    <p className="form-erroremployee">{errors.address}</p>
                                                ) : null} */}
                                            </div></div></div>


                                    {isAuthorized ? (<div className="col-md-12 mt-3">
                                        <div className="row">

                                            <div className="col-md-4">
                                                <label
                                                    htmlFor="hospitalcode"
                                                    className="col-form-label leftone"
                                                >
                                                    Hospital Code
                                                </label>
                                            </div>
                                            <div className="col-md-8">
                                                {/* Conditionally render the dropdown and input field */}
                                                {isAuthorized ? (
                                                    <input
                                                        type="text"
                                                        autoComplete="off"
                                                        name="hospitalcode"
                                                        id="hospitalcode"
                                                        placeholder="Hospital Code"
                                                        value={values.hospitalcode}
                                                        // onChange={handleChange}
                                                        // onBlur={handleBlur}
                                                        disabled
                                                        className="form-control"
                                                    />

                                                ) : (
                                                    <input
                                                        type="text"
                                                        name="hospitalcode"
                                                        id="hospitalcode"
                                                        value={userHospitalcode}
                                                        disabled={true} // Set the input as disabled
                                                        className="form-control "
                                                    />
                                                )}
                                                {/* {isAuthorized && errors.hospitalcode && touched.hospitalcode ? (
                                                    <p className="form-erroremployee">{errors.hospitalcode}</p>
                                                ) : null} */}
                                            </div></div></div>) : null}

                                    <div className="col-md-12 mt-3">
                                        <div className="row">
                                            <div className="col-md-4">
                                                <label
                                                    htmlFor="empid"
                                                    className="col-form-label"
                                                >
                                                    Admitted By
                                                </label>
                                            </div>
                                            <div className="col-md-8">
                                                <select
                                                    type="text"
                                                    autoComplete="off"
                                                    name="empid"
                                                    id="empid"
                                                    placeholder="Admited By"
                                                    value={values.empid}
                                                    onChange={handleChange}
                                                    // onChange={handleChange}
                                                    // onBlur={handleBlur}
                                                    className="form-control ember-text-field text-left ember-view"
                                                >
                                                    <option value="">Select Staff</option>
                                                    {availableStaffs.map((admitted) => (
                                                        <option key={admitted.empid} value={admitted.empid}>
                                                            {admitted.fname}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div></div></div>

                                    <div className="col-md-12 mt-3">
                                        <div className="row">
                                            <div className="col-md-4">
                                                <label
                                                    htmlFor="bedid"
                                                    className="col-form-label leftone"
                                                >
                                                    Bed Name
                                                </label>
                                            </div>
                                            <div className="col-md-8">
                                                <Autocomplete
                                                    freeSolo
                                                    id="free-solo"
                                                    options={availableBedNumbers}
                                                    getOptionLabel={(bedname) => bedname.bedname}
                                                    value={availableBedNumbers.find((bedname) => bedname.bedid === values.bedid) || null}
                                                    open={openAutocompleted}
                                                    onOpen={() => setOpenAutocompleted(true)}
                                                    onClose={() => setOpenAutocompleted(false)}
                                                    onChange={(event, newValue) => {
                                                        setValues({
                                                            ...values,
                                                            bedid: newValue ? newValue.bedid : ""
                                                        });
                                                    }}
                                                    onBlur={handleBlur}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            placeholder="Select bedname"
                                                            InputProps={{
                                                                ...params.InputProps,
                                                                endAdornment: (
                                                                    <InputAdornment position="end">
                                                                        <IconButton onClick={handleExpandClicked}>
                                                                            <ExpandMoreIcon />
                                                                        </IconButton>
                                                                    </InputAdornment>
                                                                ),
                                                                //style: { width: '74%', padding: '0px ', border: "none" } // Adjust width here
                                                            }}
                                                        />
                                                        
                                                    )}
                                                    renderOption={(props, option) => (
                                                        <li {...props}>
                                                            {option.bedname}/{option.bedtype}/{option.groupname}/{option.floorname}
                                                        </li>
                                                    )}
                                                />
                                                {/* {errors.bedid && touched.bedid ? (
                                                    <p className="form-erroremployee">{errors.bedid}</p>
                                                ) : null} */}

                                            </div>
                                        </div>
                                    </div>



                                    <div className="col-md-12 mt-3">
                                        <div className="row">
                                            <div className="col-md-4">
                                                <label
                                                    htmlFor="attenderphone"
                                                    className="col-form-label leftone"
                                                >
                                                    Attender Phone
                                                </label>
                                            </div>
                                            <div className="col-md-8">
                                                <input
                                                    type="text"
                                                    autoComplete="off"
                                                    name="attenderphone"
                                                    id="attenderphone"
                                                    placeholder="Attender Phone"
                                                    value={values.phoneno}

                                                    onChange={handleChange}
                                                    //   onBlur={handleBlur}
                                                    className="form-control ember-text-field text-left ember-view"
                                                />
                                                {/* {errors.attenderphone && touched.attenderphone? (
                          <p className="form-erroremployee">{errors.attenderphone}</p>
                        ) : null} */}
                                            </div>
                                        </div>
                                    </div>


                                    {/* Add more form fields as needed */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='mb-3'>
                    <Button type='submit' variant='primary'>
                        Update
                    </Button>{' '}
                    {/* <Button variant='info' onClick={handleReset}>
                        Reset
                    </Button> */}
                </div>
            </Form>
            {/* </div> */}
            {/* </div> */}
        </div>
    );
};

export default EditIpdPatientAdmit;