import { useState, useEffect } from "react";
import axios from '../helper/axios';
import Autocomplete from "@mui/material/Autocomplete";
import {
    TextField,
    InputAdornment,
    IconButton,
} from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useUser } from "../Provider";

const HospitalCodeSearch = ({onSearchItem}) => {
    const { userHospitalcode, userrole1, token } = useUser();
    const [data, setData] = useState([]);
    const [open, setOpen] = useState(false); // State to control dropdown visibility
    const [selectedValue, setSelectedValue] = useState(null); // State to store selected value

    useEffect(() => {
        const loadData = async () => {
            await loadAllHospitalcode();
        };
        loadData();
    }, [userrole1,userHospitalcode]);

    const loadAllHospitalcode = async () => {
        if (userHospitalcode !== undefined && userrole1 !== undefined) {
            const res = await axios.get("/gethospitalcode", {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                params: {
                    hospitalcode: userHospitalcode,
                    userrole: userrole1,
                },
            });
            setData(res.data);
        }
    };
    
    // useEffect(() => {
    //     loadAllHospitalcode();
    // }, []);

    const onSearch = (searchTerm, item) => {
        onSearchItem(item);
        setSelectedValue(item);
    };

    const handleExpandClick = () => {
        setOpen(!open);
    };

    return (
        <div className="searchbar">
            <Autocomplete
                freeSolo
                id="free-solo"
                options={data}
                getOptionLabel={(option) => `${option.hospitalcode}`}
                // sx={{ width: 230 }}
                open={open}
                onOpen={() => setOpen(true)}
                onClose={() => setOpen(false)}
                value={selectedValue}
                onChange={(event, newValue) => {
                    onSearch(null, newValue);
                    setSelectedValue(newValue);
                }}
                renderInput={(params) =>
                    <TextField
                        {...params}
                        placeholder="Select Hospital Code"
                        InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton onClick={handleExpandClick}>
                                        <ExpandMoreIcon />
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                }
                renderOption={(props, option) => (
                    <li {...props}>
                        {option.hospitalcode}
                    </li>
                )}
            />
        </div>
    );
};

export default HospitalCodeSearch;
