import React, { useState, useEffect } from "react";
import { Table, Button, Modal, Form } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import axios from "../../../helper/axios";
import { NavLink, useHistory } from "react-router-dom";
import MUIDataTable from "mui-datatables";
import { useUser } from "../../../Provider";
import { Helmet } from "react-helmet";
const ChargesTypeComponent = () => {
  const {user, userHospitalcode, userrole1, isAuthRole,isAuthorized,gettokenId,token  } = useUser();
  console.log(user, userHospitalcode, userrole1, isAuthRole,isAuthorized,gettokenId )
  const history = useHistory();
  const currentDate = new Date();
  const day = currentDate.getDate();
  const month = currentDate.getMonth() + 1; // Months are zero-indexed, so add 1
  const year = currentDate.getFullYear();
  const formattedDate = `${day}-${month}-${year}`;
  const [data, setData] = useState([]);
  //const token = sessionStorage.getItem("jwtToken");

  const loadAllChargesType = async () => {
    try {
      if (userHospitalcode !== undefined && userrole1 !== undefined) {
        const res = await axios.get("/getcharges", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: {
            hospitalcode: userHospitalcode, // Include the hospitalcode in the query parameters
            userrole: userrole1, // Include the userrole in the query parameters
          },
        });
        setData(res.data);
        console.log(res.data);
      }
    } catch (error){
      if (error.response.status === 403) {
        alert('Unauthorised');
      } else if (error.response.status === 401) {
        alert('Error: ' + error.response.data.error);
      } else {
        alert('Error: ' + error.response.data.error);
      }
    }
  }
  const cancelCharge = async (chargecode) => {
    // Display a confirmation dialog
    const isConfirmed = window.confirm("Do you want to cancel this charge?");
  
    if (isConfirmed) {
      try {
        await axios.post(`/cancelcharge/${chargecode}`, null, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        
        //alert("charge has been canceled.");
        loadAllChargesType()
      } catch (error) {
        // Handle any error here.
       alert("Error cancelling charge:", error);
      }
    } else {
      // User clicked "Cancel" in the confirmation dialog, do nothing.
    }
  };
  useEffect(() => {
  const loadData = async () => {
      //loadHospitalcode(); will wait for loadAllDetails(); to got execute
      await loadAllChargesType();
    };
    loadData();
  }, [userHospitalcode, userrole1, isAuthorized]);

  let columns;
  if (isAuthorized) {
   columns = [
    {
      name: "chargecode",
      label: "Charge Code",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "chargename",
      label: "Charge Name",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta) => {
          // Assuming the patient id is also available in your data
          const chargecode = tableMeta.rowData[0]; // Adjust this index as needed
    
          return (
            <NavLink to={`/editcharges/${chargecode}`} className="addlink">{value}</NavLink>
           );
          }
      },
    },
    {
      name: "chargetype",
      label: "Charge Type",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "chargeamount",
      label: "Charge Amount",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "hospitalcode",
      label: "Hospital Code",
      options: {
       filter: true,
       sort: true,
       customBodyRender: (value) => (value !== null && value !== undefined) ? value.toString().padStart(5, '0') : ""
      }
     },
    {
      name: "",
      label: "",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          const newD = data.find((e) => {
            return (
              e.chargecode == tableMeta.rowData[0]
              && e.chargename == tableMeta.rowData[1]
              && e.chargetype == tableMeta.rowData[2]
              && e.chargeamount == tableMeta.rowData[3]
              && e.hospitalcode == tableMeta.rowData[4]
            );
          });
          return (
            <>
              <button
                className="btn btn-danger btn-sm"
                onClick={() => cancelCharge(newD.chargecode)}
              >
                Cancel
              </button>
            </>
          );
        },
      },
    },
  ];
}else{
  columns = [
    {
      name: "chargecode",
      label: "Charge Code",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "chargename",
      label: "Charge Name",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta) => {
          // Assuming the patient id is also available in your data
          const chargecode = tableMeta.rowData[0]; // Adjust this index as needed
    
          return (
            <NavLink to={`/editcharges/${chargecode}`} className="addlink">{value}</NavLink>
           );
          }
      },
    },
    {
      name: "chargetype",
      label: "Charge Type",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "chargeamount",
      label: "Charge Amount",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "",
      label: "",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          const newD = data.find((e) => {
            return (
              e.chargecode == tableMeta.rowData[0]
              && e.chargename == tableMeta.rowData[1]
              && e.chargetype == tableMeta.rowData[2]
              && e.chargeamount == tableMeta.rowData[3]
            );
          });
          return (
            <>
              <button
                className="btn btn-danger btn-sm"
                onClick={() => cancelCharge(newD.chargecode)}
              >
                Cancel
              </button>
            </>
          );
        },
      },
    },
  ];
}
  const options = {
    selectableRows: false,
    onRowClick: ((props) => {
      console.log(props)
    }),
    downloadOptions: {
      filename: `ChargeType_${formattedDate}`,// Customize the filename here to download
      filterOptions: {
        useDisplayedColumnsOnly: true,
        useDisplayedRowsOnly: true,
      },
    },
  };
  const currentDomain = window.location.hostname;
  return (
    <>
     <Helmet>
     <title>Charge List | {currentDomain} | Hospital Management Software | Smart Solutions for Hospital Efficiency</title>
      </Helmet>
    <div style={{marginLeft: "auto"}}>
    <NavLink to="/createcharges">
      <button className="btn btn-success btn-sm mr-2" style={{margin: "10px"}}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="19"
          height="18"
          style={{ paddingRight: "4px" }}
          viewBox="0 0 100 100"
        >
          <line
            x1="10"
            y1="50"
            x2="90"
            y2="50"
            stroke="white"
            stroke-width="10"
          />
          <line
            x1="50"
            y1="10"
            x2="50"
            y2="90"
            stroke="white"
            stroke-width="10"
          />
        </svg>
        Add Charge
      </button>
    </NavLink>
    </div>
    <MUIDataTable
      title={"Charge List"}
      data={data}
      columns={columns}
      options={options}
      className="custom-mui-datatable"
    />
  </>

  );
};

export default ChargesTypeComponent;




