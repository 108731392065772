import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import axios from "../../../helper/axios";
import { useUser } from "../../../Provider";
import { Helmet } from "react-helmet";
import { NavLink} from "react-router-dom";
import MUIDataTable from "mui-datatables";
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';



const ListViewBedGroup = () => {
  const {user, userHospitalcode, userrole1, isAuthRole,isAuthorized,gettokenId,token  } = useUser();
  console.log(user, userHospitalcode, userrole1, isAuthRole,isAuthorized,gettokenId )
  const [data, setData] = useState([]);
  const currentDate = new Date();
  const day = currentDate.getDate();
  const month = currentDate.getMonth() + 1; // Months are zero-indexed, so add 1  
  const year = currentDate.getFullYear();
  // Format the date as DD-MM-YYYY
  const formattedDate = `${day}-${month}-${year}`;
 // const token = sessionStorage.getItem("jwtToken");
  const [userRole, setRole] = useState();
  
//when page load or refresh happen this function will got called
useEffect(() => {
  const loadData = async () => {
    await loadAllBedGroups();
  };

  loadData();
}, [userHospitalcode, userrole1, isAuthorized]);


// Function to fetch all bed group details
  const loadAllBedGroups = async () => {
    try{
    if (userHospitalcode !== undefined && userrole1 !== undefined) {
        console.log("userHospitalcode:", userHospitalcode); // Add this line
        console.log("userrole1:", userrole1); // Add this line
    const res = await axios.get("/GetBedGroups", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
          params: {
            hospitalcode: userHospitalcode, // Include the hospitalcode in the query parameters
            userrole: userrole1, // Include the userrole in the query parameters
          },
    });
    setData(res.data);
    console.log("skjdbsdbjchsdhcjsdhc",res.data);
  };}catch (error) {
    // Handle any error here.
   alert("Error loading list:", error);
  }
} 
  

  // Function to delete a bed group 
  const cancelBedGroup = async (bedgroupid) => {
    // Display a confirmation dialog
    const isConfirmed = window.confirm("Do you want to delete this bed group ?");
  
    if (isConfirmed) {
      try {
        console.log("sdcsadcasdasfdmnvbadfhvadshbafs",bedgroupid);

        await axios.post(`/deletebedgroup/${bedgroupid}`,null,  {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        // After successfully delete the staff, refresh the bedtype list.
        loadAllBedGroups();
      } catch (error) {
        // Handle any error here.
       alert("Error deleting bed group:", error);
      }
    } else {
      // User clicked "Cancel" in the confirmation dialog, do nothing.
    }
  };

  let columns;
  if (isAuthorized) {
   columns = [
    {
     name: "groupname",
     label: "Bed Group Name",
     options: {
      filter: true,
      sort: true,
     }
    },
    {
     name: "floorname",
     label: "Floor Name",
     options: {
      filter: true,
      sort: true,
  
     }
    },
    {
     name: "description",
     label: "Description",
     options: {
      filter: true,
      sort: true
     }
    },
    {
     name: "hospitalcode",
     label: "Hospital Code",
     options: {
      filter: true,
      sort: true,
      customBodyRender: (value) => (value !== null && value !== undefined) ? value.toString().padStart(5, '0') : ""
     }
    },
     {
      name: "",
      label: "Action",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          const newD = data.find((e) => {
            return (
              e.groupname === tableMeta.rowData[0] 
              && e.floorname === tableMeta.rowData[1] 
              &&  e.description === tableMeta.rowData[2] 
             && e.hospitalcode === tableMeta.rowData[3] 
             
            
            ); 
          });
          console.log('newD:', newD);
          return (
            <>
           <IconButton color="secondary" onClick={() =>cancelBedGroup(newD.bedgroupid)} >
              <DeleteIcon />
            </IconButton>

            <NavLink to={`/editbedgroup/${newD.bedgroupid}`}>
            <IconButton color="primary" >
                <EditIcon />
              </IconButton>
            </NavLink>
            </>
          );
          
        },
      },
    },

    
   ];

  }
  else{
    columns = [
        {
            name: "groupname",
            label: "Group Name",
            options: {
             filter: true,
             sort: true,
            }
           },
      {
       name: "floorname",
       label: "Floor Name",
       options: {
        filter: true,
        sort: true,
       }
      },
      {
       name: "description",
       label: "Description",
       options: {
        filter: true,
        sort: true,
       }
      },
      {
        name: "",
        label: "Action",
        options: {
          customBodyRender: (value, tableMeta, updateValue) => {
            const newD = data.find((e) => {
              return (
                e.groupname === tableMeta.rowData[0] 
              && e.floorname === tableMeta.rowData[1] 
              &&  e.description === tableMeta.rowData[2] 
              );
            });
            console.log('newD:', newD);
            return (
              <>
              <IconButton color="secondary" onClick={() =>cancelBedGroup(newD.bedgroupid)} >
                 <DeleteIcon />
               </IconButton>
   
               <NavLink to={`/editbedgroup/${newD.bedgroupid}`}>
               <IconButton color="primary" >
                   <EditIcon />
                 </IconButton>
               </NavLink>
               </>
            );
          },
        },
      },
     ];
  }
   const options = {
    selectableRows: false,
    onRowClick: ((props) => {
      console.log(props)
       }),
    downloadOptions: {
      filename: `Patient_list_${formattedDate}`, // Customize the filename here to download
      filterOptions: {
        useDisplayedColumnsOnly: true,
        useDisplayedRowsOnly: true,
        },
    },
  };
  //  const options = {
  //   selectableRows: false,
  //   onRowClick: ((props) => {
  //     console.log(props)
  //   })
  // };
  const currentDomain = window.location.hostname;

  return (

    <>
     <Helmet>
       
        <title>Bed Group List | {currentDomain} | Hospital Management Software | Smart Solutions for Hospital Efficiency</title>
      </Helmet>
       <NavLink to='/addbedgroup'><button className="btn btn-success btn-sm mr-2" style={{margin: "10px"}}>  <svg xmlns="http://www.w3.org/2000/svg" width="19" height="18" style={{ paddingRight: '4px' }} viewBox="0 0 100 100">
        <line x1="10" y1="50" x2="90" y2="50" stroke="white" stroke-width="10" />
        <line x1="50" y1="10" x2="50" y2="90" stroke="white" stroke-width="10" />
      </svg>Add Bed Group</button></NavLink>
       <MUIDataTable
        title={"Bed List"}
        data={data}
        columns={columns}
        options={options}
        className="custom-mui-datatable"
      />
    </>

   
  );
};

export default ListViewBedGroup;
