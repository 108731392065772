import React from "react";
import { useFormik } from "formik";
import { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useHistory, useParams } from "react-router-dom";
import axios from "../../../helper/axios";
import { Table, Button, Modal, Form } from "react-bootstrap";
import { patientregistration } from "../../../helper/schemas";
import { ToastContainer, toast } from 'react-toastify';
import * as Yup from "yup";  // Import Yup for validation
import { useUser } from "../../../Provider";
import { EditMedicineValidation, EditMedicineValidation1 } from "../../../helper/schemas/index"
import { TextField, Autocomplete, InputAdornment, IconButton } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
const initialValues = {
  medicinename: "",
  hospitalcode: "",
  medicinecategoryid: "",
  medicinemanufacturerid: "",
  medicineunitid: "",
  sellingprice: "",
  buyingprice: "",
};

const EditMedicine = () => {
  const { user, userHospitalcode, userrole1, isAuthRole, isAuthorized, gettokenId, token } = useUser();
  const history = useHistory();
  const [message, setMessage] = useState("");
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [category1, setCategory] = useState([]);
  const [manufacturer1, setManufacturer] = useState([]);
  const [unit1, setUnit] = useState([]);
  // initialised isAuthorized value as false
  const { medicineid } = useParams();
  const getValidationSchema = () => {
    if (userHospitalcode === 1) {
      return EditMedicineValidation;

    } else {
      return EditMedicineValidation1;
    }
  }
  const {
    values,
    errors,
    touched,
    setValues,
    handleChange,
    handleBlur,
    resetForm,
    handleSubmit,
  } = useFormik({
    initialValues,
    // validationSchema,
    validationSchema: getValidationSchema, // Apply validation schema
    onSubmit: async (values, { resetForm }) => {

      try {
        if (userHospitalcode !== undefined) {
          const data = new FormData();

          // Append all form fields to the FormData object
          data.append('document', JSON.stringify(values));
          // ... append other fields as needed
          data.append('medicineid', medicineid); // Assuming bedtypeid is now a number
          data.append('userHospitalcode', JSON.stringify(userHospitalcode));
          data.append('currentDateTime', JSON.stringify(getCurrentDateTime()));
          data.append('gettokenId', JSON.stringify(gettokenId));
          data.append('currentDate', JSON.stringify(currentDate));

          console.log(JSON.stringify(values));

          const response = await axios.post(`/MedicineUpdate/'${medicineid}'`, data, {
            headers: {
              Authorization: `Beaxrer ${token}`,
              "Content-Type": "multipart/form-data",
            },
            params: {
              hospitalcode: userHospitalcode, // Include the hospitalcode in the query parameters
              userrole: userrole1, // Include the userrole in the query parameters
            },
          },);

          if (response.status === 200) {
            return history.push("/listmedicine");
          } else {
            console.error('Error in admitting patient');
            setSuccess(false);
            setError(true);
          }
        }
      } catch (error) {
        console.error('Error:', error.message);
        setSuccess(false);
        setError(true);
        toast.error('Something Went Wrong');
      }
    }
  });


  const getCurrentDateTime = () => {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0');
    const day = String(now.getDate()).padStart(2, '0');
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    const currentDateTimeString = `${year}-${month}-${day}T${hours}:${minutes}`;
    return currentDateTimeString;
  };
  const [openAutocomplete, setOpenAutocomplete] = useState(false);
  const [openAutocompleted, setOpenAutocompleted] = useState(false);
  const handleExpandClick = () => {
    setOpenAutocomplete(!openAutocomplete);
};
const handleExpandClicked = () => {
  setOpenAutocompleted(!openAutocompleted);
};
  let Showdate = new Date();
  let ShowTodaydate =
    Showdate.getFullYear() +
    "-" +
    (Showdate.getMonth() + 1) +
    "-" +
    Showdate.getDate();
  const [currentDate, setCurrentDate] = useState(ShowTodaydate);
  const [data, setData] = useState([]);
  // Load bed details when the page loads or refreshes
  useEffect(() => {
    const loadData = async () => {
      const selectedHospitalCode = values.hospitalcode ;
      fetchCateoryNames(selectedHospitalCode);
      await fetchManufacturerNames(selectedHospitalCode);
      // await fetchUnit(selectedHospitalCode);
    };
    loadData();
  }, [userHospitalcode, userrole1, isAuthorized, values.hospitalcode]);

  const loadMedicine = async () => {
    try {
      if (userHospitalcode !== undefined && userrole1 !== undefined) {
        const response = await axios.get(`/GetMedicine/${medicineid}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: {
            hospitalcode: userHospitalcode,
            userrole: userrole1,
          },
        });

        const medicinedata = response.data;
        console.log(" sndbsjkdnksd", medicinedata)
        setValues(medicinedata);
      }
    } catch (error) {
      console.error('Error loading  details:', error.message);
      alert('Something Went Wrong');
    }
  };

  //when page load or refresh happen this function will got called
  useEffect(() => {
    const loadData = async () => {
      // await loadAllDetails();
      // loadHospitalcode(); will wait for loadAllDetails(); to got execute
      //   await loadHospitalcode();
      await loadMedicine();
    };
    loadData();
  }, [userHospitalcode, userrole1, isAuthorized]);

  const fetchCateoryNames = async (selectedHospitalCode) => {
    try {
      const response = await axios.get("/medicinecategory", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          hospitalcode: selectedHospitalCode, // Use the selected hospital code
        },
      });

      if (response.status === 200) {
        setCategory(response.data);
      } else {
        console.error(`Failed to fetch available category name with status: ${response.status}`);
      }
    } catch (error) {
      console.error('Error fetching available category number:', error.message);
    }
  };

  const fetchManufacturerNames = async (selectedHospitalCode) => {
    try {
      const response = await axios.get("/medicinemanufacturer", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          hospitalcode: selectedHospitalCode, // Use the selected hospital code
        },
      });

      if (response.status === 200) {
        setManufacturer(response.data);
        console.log(response.data)
      } else {
        console.error(`Failed to fetch available manufacturer name with status: ${response.status}`);
      }
    } catch (error) {
      console.error('Error fetching available manufacturer name:', error.message);
    }
  };
  const currentDomain = window.location.hostname;
  return (
    <div className="p-3 pt-0 appointment">
      <Helmet>

        <title>Update Medicine Details | {currentDomain} | Hospital Management Software | Smart Solutions for Hospital Efficiency</title>
      </Helmet>
      <form onSubmit={handleSubmit}>
        <div class="fill header"><h3 class="float-left"> Medicine Edit</h3> </div>
        <div className="patient-new primary-info">
          <div className="row">
            <div className="col-md-12">
              <div className="row">
                <div className="col-md-6">

                  <div className="col-md-12 mt-3">
                    <div className="row">

                      <div className="col-md-4">
                        <label
                          htmlFor="medicinename"
                          className="col-form-label leftone"
                        >
                          Medicine Name
                        </label>
                      </div>
                      <div className="col-md-8">
                        <input
                          type="text"
                          autoComplete="off"
                          name="medicinename"
                          id="medicinename"
                          placeholder="Medicine Name"
                          value={values.medicinename}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          style={{ width: '73%', height: '32px', border: '1px solid #ced4da', color: '#495057' }}
                        />
                        {errors.medicinename && touched.medicinename ? (
                          <p className="form-erroremployee">{errors.medicinename}</p>
                        ) : null}
                      </div></div></div>
                      <div className="col-md-12 mt-3">
                    <div className="row">
                      <div className="col-md-4">
                        <label
                          htmlFor="medicinemanufacturerid"
                          className="col-form-label leftone"
                        >
                          Manufacturer
                        </label>
                      </div>
                      <div className="col-md-8">
                        {/* <select
                          type="text"
                          autoComplete="off"
                          name="medicinemanufacturerid"
                          id="medicinemanufacturerid"
                          placeholder="Manufacturer"
                          value={values.medicinemanufacturerid}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          style={{ width: '73%', height: '32px', border: '1px solid #ced4da ', color: '#495057' }}
                        >
                          <option>
                            Select
                          </option>
                          {manufacturer1.map((code) => (
                            <option value={code.medicinemanufacturerid} key={code.medicinemanufacturerid}>
                              {code.suppliername}
                            </option>
                          ))}
                        </select> */}
                         <Autocomplete
                          freeSolo
                          id="free-solo"
                          options={manufacturer1}
                          getOptionLabel={(supplier) => supplier.suppliername}
                          value={manufacturer1.find((supplier) => supplier.medicinemanufacturerid === values.medicinemanufacturerid) || null}
                          open={openAutocompleted}
                          onOpen={() => setOpenAutocompleted(true)}
                          onClose={() => setOpenAutocompleted(false)}
                          onChange={(event, newValue) => {
                            setValues({
                              ...values,
                              medicinemanufacturerid: newValue ? newValue.medicinemanufacturerid : ""
                            });
                          }}
                          onBlur={handleBlur}
                          renderInput={(params) => (
                            <TextField
                              {...params} 
                              placeholder="Select BedGroup"
                              InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <IconButton onClick={handleExpandClicked}>
                                      <ExpandMoreIcon />
                                    </IconButton>
                                  </InputAdornment>
                                ),
                                //style: { width: '74%', padding: '0px ', border: "none" } // Adjust width here
                              }}
                            />
                          )}
                        />
                        {errors.medicinemanufacturerid && touched.medicinemanufacturerid ? (
                          <p className="form-erroremployee">{errors.medicinemanufacturerid}</p>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="col-md-12 mt-3">
                    <div className="row">
                      <div className="col-md-4">
                        <label
                          htmlFor="medicinecategoryid"
                          className="col-form-label leftone"
                        >
                          Category
                        </label>
                      </div>
                      <div className="col-md-8">
                        {/* <select
                          type="text"
                          autoComplete="off"
                          name="medicinecategoryid"
                          id="medicinecategoryid"
                          placeholder="Category"
                          value={values.medicinecategoryid}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          style={{ width: '73%', height: '32px', border: '1px solid #ced4da ', color: '#495057' }}
                        >
                          <option>
                            Select
                          </option>


                          {category1.map((code) => (
                            <option value={code.medicinecategoryid} key={code.medicinecategoryid}>
                              {code.medicinecategory}
                            </option>
                          ))}
                        </select> */}

                        <Autocomplete
                          freeSolo
                          id="free-solo"
                          options={category1}
                          getOptionLabel={(category) => category.medicinecategory}
                          value={category1.find((category) => category.medicinecategoryid === values.medicinecategoryid) || null}
                          open={openAutocomplete}
                          onOpen={() => setOpenAutocomplete(true)}
                          onClose={() => setOpenAutocomplete(false)}
                          onChange={(event, newValue) => {
                            setValues({
                              ...values,
                              medicinecategoryid: newValue ? newValue.medicinecategoryid : ""
                            });
                          }}
                          onBlur={handleBlur}
                          renderInput={(params) => (
                            <TextField
                              {...params} 
                              placeholder="Select BedGroup"
                              InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <IconButton onClick={handleExpandClick}>
                                      <ExpandMoreIcon />
                                    </IconButton>
                                  </InputAdornment>
                                ),
                                //style: { width: '74%', padding: '0px ', border: "none" } // Adjust width here
                              }}
                            />
                          )}
                        />
                        {errors.medicinecategoryid && touched.medicinecategoryid ? (
                          <p className="form-erroremployee">{errors.medicinecategoryid}</p>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  {isAuthorized ? (<div className="col-md-12 mt-3">
                    <div className="row">

                      <div className="col-md-4">
                        <label
                          htmlFor="hospitalcode"
                          className="col-form-label leftone"
                        >
                          Hospital Code
                        </label>
                      </div>
                      <div className="col-md-8">
                        {/* Conditionally render the dropdown and input field */}
                        {isAuthorized ? (
                          <input
                            type="text"
                            autoComplete="off"
                            name="hospitalcode"
                            id="hospitalcode"
                            placeholder="Hospital Code"
                            value={values.hospitalcode}
                            // onChange={handleChange}
                            // onBlur={handleBlur}
                            disabled={true} // Set the input as disabled
                            // style={{ width: '73%', height: '32px', border: '1px solid #ced4da', color: '#495057' }}
                            className="form-control "
                          >

                          </input>
                        ) : (
                          <input
                            type="text"
                            name="hospitalcode"
                            id="hospitalcode"
                            value={userHospitalcode}
                            disabled={true} // Set the input as disabled
                            className="form-control "
                          />
                        )}
                        {isAuthorized && errors.hospitalcode && touched.hospitalcode ? (
                          <p className="form-erroremployee">{errors.hospitalcode}</p>
                        ) : null}
                      </div></div></div>) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="modal-buttons">
          <Button type="submit" variant="success" className="mx-3">
            Update
          </Button>
        </div>
      </form>
    </div>
  );
};

export default EditMedicine;

