//in this staff details will be displayed and can edit the details of staff

import axios from "../../../helper/axios";
import React, { useEffect, useState } from "react";
import { NavLink, useHistory, useParams } from "react-router-dom";
import { GrEdit } from "react-icons/gr";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Table, Button, Modal, Form } from 'react-bootstrap';
import { useUser } from "../../../Provider";
import { Helmet } from "react-helmet";
import { useFormik } from 'formik';
import * as Yup from "yup";
import { SHA256 } from "crypto-js";

const initialValues = {
  fname: "",
  lname: "",
  email: "",
  password: "",
  dob: "",
  gender: "",
  maritalstatus: "",
  role: "",
  phoneno: "",
  bloodgroup: "",
  state: "",
  hospitalcode: "",
  city: "",
  cpassword: "",
  designation: "",
  doctorfees: "",
  };
// hashing password
const hashPassword = (password) => {
  return SHA256(password).toString();
};
const StaffDetailEdit = () => {
  const validationSchema = Yup.object().shape({
    fname: Yup.string().required("Please enter the first name"),
    lname: Yup.string().required("Please enter the last name"),
    email: Yup.string().email().required("Please enter the email"),
    password: Yup.string().nullable(),
    gender: Yup.string().required("Please enter the gender"),
    phoneno: Yup.string()
    .matches(/^[0-9]+$/, "Please enter mobile number")
    .min(10, "Mobile Number must be at least 10 characters")
    .max(10, "Mobile Number must be at most 10 characters")
    .required("Mobile Number is required"),
    designation: Yup.string().required("Please select the designation "),
    city: Yup.string().nullable().matches(/^[a-zA-Z\s]+$/, "City should only contain letters"),
    state: Yup.string().nullable().matches(/^[a-zA-Z\s]+$/, "State should only contain letters"),
    bloodgroup: Yup.string().nullable()
    .matches(/^[a-zA-Z+\-]+$/, "Blood Group should only contain letters, +, or -"),
  });

  const validationSchemas = Yup.object().shape({
    fname: Yup.string().required("Please enter the first name"),
    lname: Yup.string().required("Please enter the last name"),
    email: Yup.string().email().required("Please enter the email"),
    password: Yup.string().nullable(),
    gender: Yup.string().required("Please enter the gender"),
    phoneno: Yup.string()
    .matches(/^[0-9]+$/, "Please enter mobile number")
    .min(10, "Mobile Number must be at least 10 characters")
    .max(10, "Mobile Number must be at most 10 characters")
    .required("Mobile Number is required"),
    designation: Yup.string().required("Please select the designation "),
    city: Yup.string().nullable().matches(/^[a-zA-Z\s]+$/, "City should only contain letters"),
    state: Yup.string().nullable().matches(/^[a-zA-Z\s]+$/, "State should only contain letters"),
    bloodgroup: Yup.string().nullable()
    .matches(/^[a-zA-Z+\-]+$/, "Blood Group should only contain letters, +, or -"),
    doctorfees: Yup.string()
    .matches(/^[0-9.]+$/, "Doctor fees contain only number")
    .required("Please enter doctor fees")
  });
  const getValidationSchema = () => {
    if (values.designation === 'Doctor') {
      return validationSchemas;

    } else {
      return validationSchema;
    }
  };
 const { user, userHospitalcode, userrole1, isAuthRole, isAuthorized, gettokenId,token } = useUser();
//  console.log(user, userHospitalcode, userrole1, isAuthRole, isAuthorized, gettokenId)
  const history = useHistory();
  const { empid } = useParams();
  const [type, setType] = useState("");
  const [icon, setIcon] = useState("show");
  let Showdate = new Date();
  let ShowTodaydate =
    Showdate.getFullYear() +
    "-" +
    (Showdate.getMonth() + 1) +
    "-" +
    Showdate.getDate();
  const [currentDate, setCurrentDate] = useState(ShowTodaydate);
  const handleToggle = (e) => {
    
    const gettype = e.target.value;
    console.log(gettype);
    if (gettype === "password") {
      setType("text");
      setIcon("Hide");
    } else {
      setType("password");
      setIcon("Show");
    }
  };

  const [data, setData] = useState([]);
  
  const [datas, setDatas] = useState("");
  //const token = sessionStorage.getItem("jwtToken");
//when page load or refresh happen this function will got called
useEffect(() => {
  const loadData = async () => {
    //loadHospitalcode(); will wait for loadAllDetails(); to got execute
    await loadHospitalcode();
    await getempdetails();
  };
  loadData();
  
}, [userHospitalcode, userrole1, isAuthorized]);

 //get all hospital code
 const loadHospitalcode = async () => {
  //when userHospitalcode and userrole1 is not undefined then execute /gethospitalcode
  try{
  if (userHospitalcode !== undefined && userrole1 !== undefined) {
    const res = await axios.get("/gethospitalcode", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        hospitalcode: userHospitalcode, // Include the hospitalcode in the query parameters
        userrole: userrole1, // Include the userrole in the query parameters
      },
    });
    setData(res.data);
    console.log(res.data);
  }
} catch(error){
    // Handle the error here, e.g., set an error state or display an error message.
  console.error("Error loading hospital codes:", error);
}
 };

 //const [errors, setErrors] = useState({});
  // const handleEdit = (e) => {

  //   const { name, value } = e.target;

  //   setStaffedit({ ...staffedit, [e.target.name]: e.target.value });
  // };

  //get staff details(one person)
  const getempdetails = async () => {
    try{
    if (userHospitalcode !== undefined && userrole1 !== undefined ) {
    const res = await axios.get(`/GetStaff/${empid}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        hospitalcode: userHospitalcode, // Include the hospitalcode in the query parameters
        userrole: userrole1, // Include the userrole in the query parameters
      },
    });
    const newProduct = await res.data;
    setValues(newProduct);
    console.log(newProduct.password)
    setDatas(newProduct.password)
  }
}catch(error){
   // Handle the error here, e.g., set an error state or display an error message.
  //console.error("Error getting employee details:", error);
}
  };
const [passwordChanged, setPasswordChanged] = useState(false);

  const {
    values,
    errors,
    touched,
    setValues,
    handleChange,
    handleBlur,
    resetForm,
    handleSubmit,
} = useFormik({
    initialValues,
    validationSchema: getValidationSchema,
    onSubmit: async (values, { resetForm }) => {
       // console.log(values); // Log the values to check if they are captured correctly

        try {
         // console.log(staffedit);
          const data = new FormData();
          if (datas !== values.password) {
            const hashedPassword = hashPassword(values.password);
            values.password = hashedPassword;
        }
          data.append("document", JSON.stringify(values));
          data.append("currentDate", JSON.stringify(currentDate));
          data.append("gettokenId", JSON.stringify(gettokenId));
          //console.log(JSON.stringify(values))
           // hashing password.......................
          const response = await axios.post( `/StaffUpdate/${empid}`, data, {
              headers: {
                Authorization: `Bearer ${token}`,
                // Role: UserLogedinRole,
                "Content-Type": "multipart/form-data",
              },
            }
          );
          if (response.status === 200) {
            setSuccess(true);
            setError(false);
            return history.push("/listviewstaff");
          }
        } catch (err) {
          setSuccess(false);
          setError(true);
          // alert("Unauthorised")
          alert("Error occurred while submitting:", err);
        }
    },
});
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const currentDomain = window.location.hostname;
  return (
    <>
     <Helmet>
        <title>Update Staff Details | {currentDomain} | Hospital Management Software | Smart Solutions for Hospital Efficiency</title>
      </Helmet>
<div className="p-3 pt-0 appointment">
    <form onSubmit={handleSubmit}>
      <div class="fill header"><h3 class="float-left"> Staff Edit  </h3> </div>
      <div className="patient-new primary-info">
      <div className="row">
        <div className="col-md-12">
          <div className="row">
            <div className="col-md-6">
            <div className="col-md-12 mt-3">
              <div className="row">
              <div className="col-md-4">
              <label
                htmlFor="fname"
                className="col-form-label leftone"
              >
                First Name
              </label>
            </div>
            <div className="col-md-8">
              <input
                type="text"
                autoComplete="off"
                name="fname"
                id="fname"
                placeholder="First Name"
                value={values.fname}
                onChange={handleChange}
                onBlur={handleBlur}
                className="form-control ember-text-field text-left ember-view"
              />
               {errors.fname && (
        <p style={{ color: "red" }}>{errors.fname}</p>
      )}
            </div>
            </div>
            </div>

        <div className="col-md-12 mt-3">
          <div className="row">
            <div className="col-md-4">
              <label
                htmlFor="email"
                className="col-form-label leftone"
              >
                Email
              </label>
            </div>
            <div className="col-md-8">
              <input
                rows="1"
                cols="30"
                type="text"
                autoComplete="off"
                name="email"
                id="email"
                placeholder="Email"
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
               
                className="form-control ember-text-field text-left ember-view"
              />
             {errors.email && (
        <p style={{ color: "red" }}>{errors.email}</p>
      )}
            </div>
            </div>
            </div>

        <div className="col-md-12 mt-3">
              <div className="row">
                <div className="col-md-4">
                <label
                  htmlFor="gender"
                  className="col-form-label leftone"
                >
                  Gender
                </label>
              </div>
              <div className="col-md-8">
                <select
                  type="text"
                  autoComplete="off"
                  name="gender"
                  id="gender"
                  placeholder="Gender"
                  value={values.gender}
                  onChange={handleChange}
                onBlur={handleBlur}

                  style={{ width: '73%', height: '32px', border: '1px solid #ced4da ', color: '#495057' }}
                >
                  <option>Select</option>
                  <option>Male</option>
                  <option>Female</option>
                  <option>Others</option>
                </select>
                {errors.gender && (
        <p style={{ color: "red" }}>{errors.gender}</p>
      )}
              </div>
              </div>
              </div>


         

            <div className="col-md-12 mt-3">
              <div className="row">
                <div className="col-md-4">
                <label
                  htmlFor="phoneno "
                  className="col-form-label leftone"
                >
                Mobile Number
                </label>
              </div>
              <div className="col-md-8">
                <input
                  type="number"
                  autoComplete="off"
                  name="phoneno"
                  id="phoneno"
                  placeholder="Mobile Number"
                  value={values.phoneno}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className="form-control ember-text-field text-left ember-view"
                />
 {errors.phoneno && (
        <p style={{ color: "red" }}>{errors.phoneno}</p>
      )}
               
              </div>
          </div>
          </div> 

          <div className="col-md-12 mt-3">
              <div className="row">
                <div className="col-md-4">
              <label
                htmlFor="maritalstatus"
                className="col-form-label leftone"
              >
               Marital Status
              </label>
            </div>
            <div className="col-md-8">
              <select
                type="text"
                autoComplete="off"
                name="maritalstatus"
                id="maritalstatus"
                placeholder="maritalstatus"
                value={values.maritalstatus}
                onChange={handleChange}
                onBlur={handleBlur}

                style={{ width: '73%', height: '32px', border: '1px solid #ced4da ', color: '#495057' }}
              >
                <option>Select</option>
                <option>Married</option>
                <option>Single</option>
                <option>Divorced</option>
                <option>Widower</option>
              </select>
              {/* {errors.maritalstatus && (
        <p style={{ color: "red" }}>{errors.maritalstatus}</p>
      )} */}
            </div>
            </div>
            </div>


             <div className="col-md-12 mt-3">
              <div className="row">
             <div className="col-md-4">
              <label
                htmlFor="dob"
                className="col-form-label"
              >
                Date of Birth
              </label>
            </div>
            <div className="col-md-8">
              <input
                type="date"
                autoComplete="off"
                name="dob"
                id="dob"
                placeholder="DOB"
                value={values.dob}
                onChange={handleChange}
                onBlur={handleBlur}
                className="form-control ember-text-field text-left ember-view"
              />

            </div>
            </div>
            </div>

            
        <div className="col-md-12 mt-3">
              <div className="row">
  <div className="col-md-4">
              <label
                htmlFor="city"
                className="col-form-label"
              >
                City
              </label>
            </div>
            <div className="col-md-8">
              <input
                type="text"
                autoComplete="off"
                name="city"
                id="city"
                placeholder="City"
                value={values.city}
                onChange={handleChange}
                onBlur={handleBlur}
                className="form-control ember-text-field text-left ember-view"
              ></input>
                 {errors.city&& (
        <p style={{ color: "red" }}>{errors.city}</p>
      )}
            </div>
            </div>
            </div>
            
            
            {/* <div className="col-md-12 mt-3">
              <div className="row">
                 <div className="col-md-4">
                 <label
                    htmlFor="designation"
                    className="col-form-label"
                  >
                Designation
              </label>
            </div>
            <div className="col-md-8">
              <input
                type="text"
                autoComplete="off"
                name="designation"
                id="designation"
                placeholder="Designation"
                value={staffedit.designation}
                onChange={(e) => {
                  handleEdit(e);
                  }}
                className="form-control ember-text-field text-left ember-view"
              />
            </div>
          </div>
        </div> */}

     <div className="col-md-12 mt-3">
              <div className="row">
                 {/* <div className="col-md-4">
                 <label
                    htmlFor="designation"
                    className="col-form-label"
                  >
               Designation
              </label>
            </div>
            <div className="col-md-8">
              <input
                type="text"
                autoComplete="off"
                name="designation"
                id="designation"
                placeholder="Designation"
                value={values.designation}
                onChange={handleChange}
                onBlur={handleBlur}
                className="form-control ember-text-field text-left ember-view"
              />
              
            </div> */}

        <div className="col-md-12 mt-3">
              <div className="row">
                <div className="col-md-4">
                <label
                  htmlFor="gender"
                  className="col-form-label leftone"
                >
                  Designation
                </label>
              </div>
              <div className="col-md-8">
                <select
                  type="text"
                  autoComplete="off"
                  name="designation"
                  id="designation"
                  placeholder="Designation"
                  value={values.designation}
                  onChange={handleChange}
                  onBlur={handleBlur}

                  style={{ width: '73%', height: '32px', border: '1px solid #ced4da ', color: '#495057' }}
                >
                  <option>Select</option>
                <option>Admin</option>
                <option>Manager </option>
                <option>Doctor</option>
                <option>Anesthetist</option>
                <option>OT Technician</option>
                <option>OT Assistant</option>
                <option>Nurse</option>
                <option>Compouder</option>
                <option>Accountant</option>
                <option>Pharmacist</option>
                <option>Pathologist</option>
                <option>Radiologist</option>
                <option>Receptionist</option>
                </select>
                {errors.designation && (
        <p style={{ color: "red" }}>{errors.designation}</p>
      )}
              </div>
              </div>
              </div>
          </div>
        </div>


          </div>

        
         


<div className="col-md-6">    
<div className="col-md-12 mt-3">
              <div className="row">
                <div className="col-md-4">
              <label
                htmlFor="lname"
                className="col-form-label leftone"
              >
                Last Name
              </label>
            </div>
            <div className="col-md-8">
              <input
                type="text"
                autoComplete="off"
                name="lname"
                id="lname"
                placeholder="Last Name"
                value={values.lname}
                onChange={handleChange}
                onBlur={handleBlur}
                className="form-control ember-text-field text-left ember-view"
              />
               {errors.lname && (
        <p style={{ color: "red" }}>{errors.lname}</p>
      )}
            </div>
          </div>
        </div>


        <div className="col-md-12 mt-3">
              <div className="row">
                <div className="col-md-4">
              <label
                htmlFor="passward"
                className="col-form-label"
              >
                Password
              </label>
            </div>
            <div className="col-md-6">
                <input
                  type={type}
                  autoComplete="off"
                  name="password"
                  id="password"
                  placeholder="Password"
                  value={values.password}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className="form-control ember-text-field text-left ember-view"
                />
                 {errors.password && (
        <p style={{ color: "red" }}>{errors.password}</p>
      )}
        </div>

              <div className="col-md-2">
              <button
                className="show-button btn btn-primary"
                type="button"
                value={type}
                onClick={handleToggle}
              >
                Show
              </button>
                </div>
              
                </div>
              </div>


              <div className="col-md-12 mt-3">
              <div className="row">
                <div className="col-md-4">
              <label
                htmlFor="cpassword"
                className="col-form-label"
              >
                Confirm Password
              </label>
              </div>
              <div className="col-md-8">
              <input
                  // type={type}
                  autoComplete="off"
                  name="cpassword"
                  id="cpassword"
                  placeholder="Confirm Password"
                  value={values.cpassword}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className="form-control ember-text-field text-left ember-view"
                />
               
              </div>

            </div>
          </div>

            <div className="col-md-12 mt-3">
              <div className="row">
              <div className="col-md-4">
              <label
                htmlFor="role"
                className="col-form-label"
              >
                Role
              </label>
            </div>
            <div className="col-md-8">
                <select
                  type="text"
                  autoComplete="off"
                  name="role1"
                  id="role"
                  placeholder="Department"
                  value={values.role1}
                  onChange={handleChange}
                onBlur={handleBlur}
                    style={{ width: '73%', height: '32px', border: '1px solid #ced4da ', color: '#495057' }}
                >
                  <option>Select</option>
                  <option>Supperadmin </option>
                  <option>Admin </option>
                  <option>Employee</option>
                </select>
                {/* {errors.role && (
        <p style={{ color: "red" }}>{errors.role}</p>
      )} */}
              </div>
          </div>
        </div>
{isAuthorized ? ( 
        <div className="col-md-12 mt-3">
              <div className="row">
                 <div className="col-md-4">
              <label
                htmlFor="hospitalcode"
                className="col-form-label"
              >
                Hospital Code
              </label>
            </div>
            
            <div className="col-md-8">

            {isAuthorized ? (
                  //   <select              
                  //   type="text"
                  //   autoComplete="off"
                  //   name="hospitalcode"
                  //   id="hospitalcode"
                  //   placeholder="Enter Hospital Code"
                  //   value={staffedit.hospitalcode}
                  //   onChange={(e) => {
                  //     handleEdit(e);
                  //     }}
                  //   style={{ width: '73%', height: '32px', border: '1px solid #ced4da ', color: '#495057' }}
                  // >
                  //     <option>Select</option>
                  //     {data.map((code) => (
                  //       <option value={code.hospitalcode} key={code.id}>
                  //         {code.hospitalcode}
                  //       </option>
                  //     ))}
                  //   </select>
                  <input
                  type="text"
                  autoComplete="off"
                  name="hospitalcode
"
                  id="hospitalcode
"
                  placeholder="Enter Hospital Code"
                  value={values.hospitalcode}
                  disabled
                  className="form-control "
                />  
                  ) : (
                    <input
                      type="text"
                      name="hospitalcode"
                      id="hospitalcode"
                      value={userHospitalcode}
                      disabled={true} // Set the input as disabled
                      className="form-control "
                    />
                  )}
            </div> 

          </div>
          </div>) : null}

        <div className="col-md-12 mt-3">
          <div className="row">
          <div className="col-md-4">
              <label
                htmlFor="bloodgroup"
                className="col-form-label "
              >
                Blood Group
              </label>
            </div>
            <div className="col-md-8">
              <input
                type="text"
                autoComplete="off"
                name="bloodgroup"
                id="bloodgroup"
                placeholder="Blood Group"
                value={values.bloodgroup}
                onChange={handleChange}
                onBlur={handleBlur}
                className="form-control ember-text-field text-left ember-view"
              />
   {errors.bloodgroup && (
        <p style={{ color: "red" }}>{errors.bloodgroup}</p>
      )}
            
            </div>
            </div>
            </div>
          
            <div className="col-md-12 mt-3">
              <div className="row">
              <div className="col-md-4">
              <label
                htmlFor="state"
                className="col-form-label"
              >
                State
              </label>
            </div>
            <div className="col-md-8">
              <input
                type="text"
                autoComplete="off"
                name="state"
                id="state"
                placeholder="State"
                value={values.state}
                onChange={handleChange}
                onBlur={handleBlur}
                className="form-control ember-text-field text-left ember-view"
              />
                 {errors.state && (
        <p style={{ color: "red" }}>{errors.state}</p>
      )}
            </div>


            {values.designation === "Doctor" && (
          <div className="col-md-12 mt-3">
            <div className="row">
              <div className="col-md-4">
                <label
                  htmlFor="doctorfees"
                  className="col-form-label leftone"
                >
                  Doctor Fee
                </label>
              </div>
              <div className="col-md-8">
                <input
                  type="text"
                  autoComplete="off"
                  name="doctorfees"
                  id="doctorfees"
                  placeholder="Doctor Fees"
                  value={values.doctorfees}
                  onChange={handleChange}
                onBlur={handleBlur}
                 
                  className="form-control ember-text-field text-left ember-view"
                />
                   {errors.doctorfees && (
        <p style={{ color: "red" }}>{errors.doctorfees}</p>
      )}
              </div>
            </div>
          </div>
        )}
          </div>
        </div>
      </div>
      </div>
          </div>
          </div>
          </div>

          <div className="modal-buttons">
          <Button type="submit" variant="success" className="mx-3">
          Update
        </Button>
            {/* <button
              className="input-button"
              type="reset"
            >
              Cancel
            </button> */}
          </div>
        </form>
      </div>
    </>
  );
};
export default StaffDetailEdit;