import React, { useState, useEffect } from 'react';
import { Modal, Backdrop, Fade, Button, TextField, Autocomplete, InputAdornment, IconButton } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useFormik } from "formik";
import axios from "../../../helper/axios";
import { useUser } from "../../../Provider";
import { useParams } from 'react-router-dom/cjs/react-router-dom';
import { ToastContainer, toast } from 'react-toastify'
import { paymentvalidation } from "../../../helper/schemas";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Import the styles
const initialValues = {
  date: "",
  doctorids: "",
  findingcategory: "",
  findingdescription: "",
  pathology: "",
  radiology: ""
};
const UpdateIpdPrescription = ({ PrescriptionData, onClose }) => {
  // Retrieve 'admitnumber' from the URL parameters
  const { admitnumber } = useParams();
  console.log({ admitnumber });

  // Use the 'useUser' hook to get user-related information
  const { user, userHospitalcode, userrole1, isAuthRole, isAuthorized, gettokenId, token } = useUser();
  console.log(user, userHospitalcode, userrole1, isAuthRole, isAuthorized, gettokenId);

  // State variables to manage form visibility, category data, and other data
  const [isFormVisible, setFormVisibility] = useState(false);
  const [category1, setCategory] = useState([]);
  const [data, setData] = useState([]);
  const [doctor, setDoctor] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");

  // Get today's date to initialize the form field
  let Showdate = new Date();
  let ShowTodaydate =
    Showdate.getFullYear() +
    "-" +
    (Showdate.getMonth() + 1) +
    "-" +
    Showdate.getDate();
  const [currentDate, setCurrentDate] = useState(ShowTodaydate);

  // Function to toggle form visibility
  const handleAddMedication = () => {
    setFormVisibility(!isFormVisible);
  };

  // Formik hook for form state management and validation
  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleReset,
    handleSubmit,
    resetForm,
    setValues,
  } = useFormik({
    initialValues,
    onSubmit: async (values, action) => {
      try {
        const data = new FormData();
        // Append form data to the FormData object
        data.append("document", JSON.stringify(values));
        data.append("selectedCategory", JSON.stringify(selectedCategory));
        data.append("currentDate", JSON.stringify(currentDate));
        data.append("gettokenId", JSON.stringify(gettokenId));
        data.append("medicines", JSON.stringify(medicines));
        data.append("headernote", JSON.stringify(headernote));
        data.append("footernote", JSON.stringify(footernote));
        // console.log(JSON.stringify(values))
        console.log(JSON.stringify(medicines))
        //console.log(JSON.stringify(headernote))
        // console.log(JSON.stringify(footernote))

        // Make a POST request to save Prescription data
        const response = await axios.post(`/updateprescription/${admitnumber}/${PrescriptionData}`, data, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        });

        // Display success message if the request is successful
        if (response.status === 200) {
          toast.success("Data Saved");
        }
      } catch (err) {
        // Handle errors
      }
      // Reset the form after submission
      action.resetForm();
    },
  });

  // State variables for managing medicine-related data
  const [medicines, setMedicines] = useState([{ medicinecategoryid: '', medicinecode: '', dosage: '', doseinterval: '', doseduration: '', instruction: '' }]);
  const [medicineNames, setMedicineNames] = useState({});
  const [open, setOpen] = useState(false);
  const handleopen = () => {
    setOpen(!open);
  };
  const [Open, setOpens] = useState(false);
  const handleOpen = () => {
    setOpens(!Open);
  };
  // Function to handle category change for a specific row
  const handleCategoryChangeForRow = async (newValue, index, key) => {
    console.log("newValue", newValue)
    // Extract medicinecategoryid from the newValue object
    const selectedCategoryId = newValue ? newValue.medicinecategoryid : null;
    console.log("selectedCategoryId:", selectedCategoryId); // Check if selectedCategoryId is correctly extracted
    setSelectedCategory(selectedCategoryId);
    setMedicines((prevMedicines) => {
      const updatedMedicines = [...prevMedicines];
      if (updatedMedicines[index]) {
        updatedMedicines[index] = {
          ...updatedMedicines[index],
          [key]: selectedCategoryId, // Update the specified key with the selected category id
          medicinecode: "", // Assuming you want to reset medicinecode when category changes
        };
      }
      return updatedMedicines;
    });
    await fetchMedicineNames(selectedCategoryId, index);
  };


  // Function to fetch medicine names based on selected category
  const fetchMedicineNames = async (selectedCategory, index) => {
    try {
      const response = await axios.get("/medicinenames", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          hospitalcode: data.hospitalcode,
          category: selectedCategory,
        },
      });

      if (response.status === 200) {
        setMedicineNames((prevMedicineNames) => {
          const updatedMedicineNames = { ...prevMedicineNames };
          updatedMedicineNames[index] = response.data;
          return updatedMedicineNames;
        });
      } else {
        console.error(`Failed to fetch medicine names with status: ${response.status}`);
      }
    } catch (error) {
      console.error('Error fetching medicine names:', error.message);
    }
  };

  // // Function to handle changes in medicine-related fields
  const handleChanges = (e, index, field, newValue) => {
    let value;
    if (newValue && newValue.medicinecode) {
      value = newValue.medicinecode;
    } else {
      value = e.target.value;
    }
    setMedicines((prevMedicines) => {
      return prevMedicines.map((medicine, i) => {
        if (i === index) {
          return { ...medicine, [field]: value };
        }
        return medicine;
      });
    });
  };

  // Function to handle deletion of a specific row
  const handleDeleteRow = (index) => {
    setMedicines((prevMedicines) => {
      const updatedMedicines = prevMedicines.filter((_medicine, i) => i !== index);
      return updatedMedicines;
    });

    // Fetch medicine names based on the remaining medicine categories
    setMedicines((updatedMedicines) => {
      updatedMedicines.forEach(async (medicine, i) => {
        await fetchMedicineNames(medicine.medicinecategoryid, i);
      });
      return updatedMedicines;
    });
  };

  // Function to load hospital code based on user information
  const loadHospitalcode = async () => {
    if (userHospitalcode !== undefined && userrole1 !== undefined) {
      const res = await axios.get(`/getIPDhospitalcode/${admitnumber}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          hospitalcode: userHospitalcode,
          userrole: userrole1,
        },
      });
      setData(res.data[0]);
      console.log(res.data);
    };
  };

  // Function to fetch medicine category names based on hospital code
  const fetchMedicineCateoryNames = async (selectedHospitalCode) => {
    try {
      const response = await axios.get("/medicinecategory", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          hospitalcode: selectedHospitalCode,
        },
      });

      if (response.status === 200) {
        setCategory(response.data);
      } else {
        console.error(`Failed to fetch available floor numbers with status: ${response.status}`);
      }
    } catch (error) {
      console.error('Error fetching available floor numbers:', error.message);
    }
  };
  //get the Patient details according to patientid
  const getTransactionDetails = async () => {
    const res = await axios.get(`/getprescriptionbynumber/${admitnumber}/${PrescriptionData}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    console.log(res.data[0])
    setValues(res.data[0])
    setHeadernote(res.data[0].headernote)
    setFooternote(res.data[0].footernote)
  }
  const getPatientBillDetails = async () => {
    try {
      const res = await axios.get(
        `/prescriptionmedicinebynumber/${admitnumber}/${PrescriptionData}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (res.status === 200) {
        // If the request is successful (status code 200), set the bill items data.
        setMedicines(res.data);
        // Fetch medicine names based on the medicine category for each item
        for (let i = 0; i < res.data.length; i++) {
          const medicineItem = res.data[i];
          if (medicineItem.medicinecategoryid) {
            await fetchMedicineNames(medicineItem.medicinecategoryid, i);
          }
        }
      } else {
        // Handle unexpected response status codes, e.g., 404 or 500.
        alert(`Unexpected status code: ${res.status}`);
      }
    } catch (error) {
      // Handle network errors or other exceptions.
      alert(`An error occurred: ${error.message}`);
      // Optionally, you can set an error state or display an error message to the user.
    }
  };
  // Function to get doctor details
  const getDoctordetails = async () => {

    try {
      if (userHospitalcode !== undefined && userrole1 !== undefined) {
        const res = await axios.get(`/getdoctorids`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: {
            hospitalcode: data.hospitalcode, // Include the hospitalcode in the query parameters
            userrole: userrole1, // Include the userrole in the query parameters
          },
        });
        console.log(res.data)
        // Check if the response status code is  200
        if (res.status === 200) {
          setDoctor(res.data);
        } else {
          // Handle unexpected response status codes (e.g., 404, 500, etc.) as errors.
          alert(`Request failed with status: ${res.status}`);
          // Optionally, you can set an error state or display an error message to the user.
        }
      }
    }
    catch (error) {
      // Handle network errors or other exceptions.
      console.log(`An error occurred: ${error.message}`);
      // Optionally, you can set an error state or display an error message to the user.
    }
  };

  useEffect(() => {
    const loadData = async () => {
      await loadHospitalcode();
      await getDoctordetails(data.hospitalcode);
      const selectedHospitalCode = data.hospitalcode || userHospitalcode;
      await fetchMedicineCateoryNames(selectedHospitalCode);
      await getTransactionDetails();
      await getPatientBillDetails();
      // Fetch the initial list of medicine names based on the initial category value
      await fetchMedicineNames();

    };
    loadData();
  }, [userHospitalcode, userrole1, isAuthorized, data.hospitalcode]);
  // State variable for the text editor content
  const [headernote, setHeadernote] = useState('');
  const [footernote, setFooternote] = useState('');
  const [Autocompleted, setAutocompleted] = useState(false);
  const handleClicked = () => {
    setAutocompleted(!Autocompleted);
  };
  // Handler for text editor content change
  const handleEditorChange = (content) => {
    setHeadernote(content);
  };
  const handleFooterEditorChange = (content) => {
    setFooternote(content)
  };
  return (
    <Modal
      open={true} // Set to true to open the modal
      onClose={onClose}
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      closeAfterTransition
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={true}>
        <div style={{
          backgroundColor: 'white',
          padding: '20px',
          borderRadius: '8px',
          maxWidth: '99%',
          margin: 'auto',
          marginTop: '2%',
          maxHeight: '90vh',  // Set a maximum height for the modal content
          overflowY: 'auto',  // Add a vertical scrollbar when content exceeds the height
        }}>
          <h2>Update Prescription</h2>
          <form onSubmit={handleSubmit} style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
            <div className="patient-new primary-info">
              Header Note
              {/* ReactQuill component */}
              <ReactQuill
                theme="snow"
                value={headernote}
                onChange={handleEditorChange}
              />
              <div className="row">
                <div className="col-md-12">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="col-md-12 mt-3">
                        <div className="row">
                          <div className="col-md-4">
                            <label
                              htmlFor="date"
                              className="col-form-label leftone"
                            >
                              Date
                            </label>
                          </div>
                          <div className="col-md-8">
                            <input
                              type="date"
                              autoComplete="off"
                              name="date"
                              id="date"
                              placeholder="Date"
                              value={values.date}
                              onChange={handleChange}
                              onBlur={handleBlur}

                              className="form-control"
                            />
                            {errors.date && touched.date ? (
                              <p className="form-erroremployee">{errors.date}</p>
                            ) : null}
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12 mt-3">
                        <div className="row">
                          <div className="col-md-4">
                            <label
                              htmlFor="findingcategory"
                              className="col-form-label leftone"
                            >

                              Finding Category
                            </label>
                          </div>
                          <div className="col-md-8">
                            <input
                              type="text"
                              autoComplete="off"
                              name="findingcategory"
                              id="findingcategory"
                              placeholder="findingcategory"
                              value={values.findingcategory}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              style={{ width: '73%', height: '32px', border: '1px solid #ced4da ', color: '#495057' }}
                            />
                            {errors.findingcategory && touched.findingcategory ? (
                              <p className="form-erroremployee">{errors.findingcategory}</p>
                            ) : null}

                          </div>
                        </div>
                      </div>
                      <div className="col-md-12 mt-3">
                        <div className="row">
                          <div className="col-md-4">
                            <label
                              htmlFor="pathology"
                              className="col-form-label "
                            >

                              Pathology Test
                            </label>
                          </div>
                          <div className="col-md-8">
                            <input
                              type="text"
                              autoComplete="off"
                              name="pathology"
                              id="pathology"
                              placeholder="Pathology"
                              value={values.pathology}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              style={{ width: '73%', height: '32px', border: '1px solid #ced4da ', color: '#495057' }}
                            />
                            {errors.pathology && touched.pathology ? (
                              <p className="form-erroremployee">{errors.pathology}</p>
                            ) : null}

                          </div>
                        </div>
                      </div>

                    </div>
                    <div className="col-md-6">

                      <div className="col-md-12 mt-3">
                        <div className="row">
                          <div className="col-md-4">
                            <label
                              htmlFor="doctorids"
                              className="col-form-label leftone"
                            >
                              Prescribe By
                            </label>
                          </div>
                          <div className="col-md-8">

                            <Autocomplete
                              freeSolo
                              id="free-solo"
                              options={doctor}
                              getOptionLabel={(doctors) => doctors.fname}
                              value={doctor.find((doctors) => doctors.doctorids === values.doctorids) || null}
                              open={Autocompleted}
                              onOpen={() => setAutocompleted(true)}
                              onClose={() => setAutocompleted(false)}
                              onChange={(event, newValue) => {
                                setValues({
                                  ...values,
                                  doctorids: newValue ? newValue.doctorids : ""
                                });
                              }}
                              onBlur={handleBlur}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  placeholder="Select BedType"
                                  InputProps={{
                                    ...params.InputProps,
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        <IconButton onClick={handleClicked}>
                                          <ExpandMoreIcon />
                                        </IconButton>
                                      </InputAdornment>
                                    ),
                                    //style: { width: '74%', padding: '0px ', border: "none" } // Adjust width here
                                  }}
                                />
                              )}
                            />
                            {errors.doctorids && touched.doctorids ? (
                              <p className="form-erroremployee">{errors.doctorids}</p>
                            ) : null}
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12 mt-3">
                        <div className="row">
                          <div className="col-md-4">
                            <label
                              htmlFor="findingdescription"
                              className="col-form-label leftone"
                            >
                              Finding Description
                            </label>
                          </div>
                          <div className="col-md-8">
                            <textarea
                              // style={{ width: "210px", height: "50px" }}
                              rows="1"
                              cols="30"
                              type="text"
                              autoComplete="off"
                              name="findingdescription"
                              id="findingdescription"
                              placeholder="findingdescription"
                              value={values.findingdescription}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              style={{ width: '73%', height: '32px', border: '1px solid #ced4da ', color: '#495057' }}
                            />
                            {errors.findingdescription && touched.findingdescription ? (
                              <p className="form-erroremployee">{errors.findingdescription}</p>
                            ) : null}

                          </div>
                        </div>
                      </div>
                      <div className="col-md-12 mt-3">
                        <div className="row">
                          <div className="col-md-4">
                            <label
                              htmlFor="radiology"
                              className="col-form-label"
                            >
                              Radiology Test
                            </label>
                          </div>
                          <div className="col-md-8">
                            <input
                              type="text"
                              autoComplete="off"
                              name="radiology"
                              id="radiology"
                              placeholder="Radiology"
                              value={values.radiology}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              style={{ width: '73%', height: '32px', border: '1px solid #ced4da ', color: '#495057' }}
                            />
                            {errors.radiology && touched.radiology ? (
                              <p className="form-erroremployee">{errors.radiology}</p>
                            ) : null}

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <table>
                <thead>
                  <tr style={{ paddingRight: '10px' }}>
                    <th>Medicine Category</th>
                    <th>Medicine Name</th>
                    <th>Dosage</th>
                    <th>Dose Interval</th>
                    <th>Dose Duration</th>
                    <th>Instruction</th>
                    <th>Delete</th>
                  </tr>
                </thead>
                <tbody>
                  {medicines.map((medicine, index) => (
                    <tr key={index}>
                      <td style={{ paddingRight: '10px' }}>

                        <Autocomplete
                          freeSolo
                          id="free-solo"
                          options={category1}
                          getOptionLabel={(option) => option.medicinecategory || ""}
                          value={category1.find((option) => option.medicinecategoryid === medicine.medicinecategoryid) || ""}
                          onChange={(event, newValue) => handleCategoryChangeForRow(newValue, index, "medicinecategoryid")}
                          // open={open}
                          // onOpen={() => setOpen(true)}
                          // onClose={() => setOpen(false)}
                          renderInput={(params) =>
                            <TextField
                              {...params}
                              placeholder="Select Category"
                              InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <ExpandMoreIcon />
                                    {/* <IconButton onClick={handleopen}>
              <ExpandMoreIcon />
            </IconButton> */}
                                  </InputAdornment>
                                ),
                              }}
                            />
                          }
                        />
                      </td>
                      <td>
                        <Autocomplete
                          freeSolo
                          id={`free-solo-${index}`}
                          options={medicineNames[index] || []} // Use medicineNames[index] as options
                          getOptionLabel={(option) => option.medicinename || ""}
                          value={medicineNames[index]?.find((option) => option.medicinecode === medicine.medicinecode) || ""}
                          onChange={(event, newValue) => handleChanges(event, index, "medicinecode", newValue)}
                          // open={open}
                          // onOpen={() => setOpen(true)}
                          // onClose={() => setOpen(false)}
                          renderInput={(params) =>
                            <TextField
                              {...params}
                              placeholder="Select Medicine"
                              InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                  <InputAdornment position="end">
                                    {/* <IconButton onClick={handleOpen}> */}
                                      <ExpandMoreIcon />
                                    {/* </IconButton> */}
                                  </InputAdornment>
                                ),
                              }}
                            />
                          }
                        />


                      </td>
                      <td>
                        <input
                          type="text"
                          autoComplete="off"
                          name="dosage"
                          id="dosage"
                          placeholder="dosage"
                          value={medicine.dosage}
                          onChange={(e) => handleChanges(e, index, "dosage")}


                          style={{ width: '100%', height: '32px', border: '1px solid #ced4da ', color: '#495057' }}
                        />
                      </td>
                      <td>
                        <select
                          type="text"
                          autoComplete="off"
                          name="doseinterval"
                          id="doseinterval"
                          placeholder="doseinterval"
                          value={medicine.doseinterval}
                          onChange={(e) => handleChanges(e, index, "doseinterval")}

                          style={{ width: '100%', height: '32px', border: '1px solid #ced4da ', color: '#495057' }}
                        >
                          <option>Select</option>
                          <option>1 times a day</option>
                          <option>2 times a day</option>
                          <option>3 times a day</option>
                          <option>4 times a day</option>
                          <option>5 times a day</option>
                          <option>6 times a day</option>
                        </select>
                      </td>
                      <td>
                        <input
                          type="text"
                          autoComplete="off"
                          name="doseduration"
                          id="doseduration"
                          placeholder="doseduration"
                          value={medicine.doseduration}
                          onChange={(e) => handleChanges(e, index, "doseduration")}
                          style={{ width: '100%', height: '32px', border: '1px solid #ced4da ', color: '#495057' }}
                        />
                      </td>
                      <td>
                        <textarea
                          // style={{ width: "210px", height: "50px" }}
                          rows="1"
                          cols="30"
                          type="text"
                          autoComplete="off"
                          name="instruction"
                          id="instruction"
                          placeholder="Instruction"
                          value={medicine.instruction}
                          onChange={(e) => handleChanges(e, index, "instruction")}

                          style={{ width: '100%', height: '32px', border: '1px solid #ced4da ', color: '#495057' }}
                        />
                      </td>
                      <td>
                        <Button
                          variant="contained"
                          color="primary"
                          size="small"
                          style={{ width: 'auto' }}
                          onClick={(e) => handleDeleteRow(index)}
                        >
                          Delete
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <Button variant="contained" color="primary" size="small" style={{ width: 'auto' }}
                onClick={() => setMedicines([...medicines, { medicinecategoryid: '', medicinecode: '', dose: '', doseinterval: '', doseduration: '', instruction: '' }])}>
                Add Medicine
              </Button>
            </div>
            Footer Note
            {/* ReactQuill component */}
            <ReactQuill
              theme="snow"
              value={footernote}
              onChange={handleFooterEditorChange}
            />
            <div className="col-md-11 mt-6 text-center">
              <Button type="submit" variant="contained" color="primary" size="small" style={{ width: 'auto' }}>
                Submit
              </Button>
            </div>
          </form>
          <ToastContainer />
        </div>
      </Fade>
    </Modal>
  );
};

export default UpdateIpdPrescription;
