import React, { useEffect, useState } from 'react';
// import { Grid, Divider, Typography } from '@mui/material';
import {  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper,TextareaAutosize, IconButton } from '@mui/material';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { useUser } from '../../../Provider';
import MUIDataTable from "mui-datatables";
import axios from "../../../helper/axios";
//import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import PrintIcon from '@mui/icons-material/Print';
import { Modal } from 'react-bootstrap';
import PrintOperation from '../../../Component/PrintOperation/PrintOperation';
import UpdateIpdOperationPopUp from './UpdateIpdOperationPopUp';
import OperationForm from './OperationForm';

  // OperationTable component
  const OperationTable = ({hideForm}) => {
    const { admitnumber } = useParams();
    const { userHospitalcode, userrole1,token } = useUser();
    const [operationData, setOperationData] = useState([]);
    const [opd, setOpd] = useState();
    const [isPopup, setIsPopup] = useState(false);
    const [isEditFormVisible, setEditFormVisibility] = useState(false);
    const [editOperationData, setEditOperationData] = useState(null);

    const getOperations = async () => {
      try {
        if (userHospitalcode !== undefined && userrole1 !== undefined) {
          const res = await axios.get(`/getipdoperation/${admitnumber}`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
            params: {
              userHospitalcode,
              userrole: userrole1,
            },
          });
  
          if (res.status === 200) {
            setOperationData(res.data);
           // console.log(res.data)
          } else {
            alert(`Request failed with status: ${res.status}`);
          }
        }
      } catch (error) {
        console.log(`An error occurred: ${error.message}`);
      }
    };
  
    useEffect(() => {
      getOperations();
    }, [userHospitalcode, userrole1]);
    const handleOperationAdded = () => {
      // Function to fetch the updated NurseNote data
      getOperations();
    };
    const formatOperationTime = (inputDateString) => {
      const inputDate = new Date(inputDateString);
    
      // Format the date as MM/DD/YYYY hh:mm am/pm in the user's local time zone
      const formattedDate = inputDate.toLocaleString('en-US', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        hour12: true,
        timeZone: 'UTC', // Change this to the server's time zone
      });
    
      return formattedDate;
    };
    const columns = [
      { name: "operationnumber", label: "Reference No" },
      { name: "operationtime", label: "Operation Date", options: {
        customBodyRender: (value) => formatOperationTime(value),
      },},
      { name: "operationname", label: "Operation Name" },
      { name: "category", label: "Operation Category" },
      { name: "ottechnician_name", label: "OT Technician" },
      {
        name: "actions",
        label: "Actions",
        options: {
          customBodyRender: (value, tableMeta) => (
            
            <div>
              <IconButton color="primary" onClick={() => handlePrint(value, tableMeta)}>
                <PrintIcon />
              </IconButton>
              <IconButton color="primary" onClick={() => handleEdit(value,tableMeta)}>
                <EditIcon />
              </IconButton>
              <IconButton color="secondary" onClick={() => handleDelete(value,tableMeta)}>
                <DeleteIcon />
              </IconButton>
            </div>
          ),
        },
      },
    ];
  
    const options = {
      filter: true,
      selectableRows: "none",
      viewColumns: false,
      print: false,
      download: false,
    };
     //handleClose: This function is used to set the isPopup state to false, presumably to close a popup or modal.
  const handleClose = () => {
    setIsPopup(false)
  }  
  const handlePrint = async (value, tableMeta, updateValue) => {
    // console.log(operationData);
    // console.log(tableMeta.rowData[0])
    if (tableMeta.rowData && tableMeta.rowData[0]) {
        const newD = operationData.find((e) =>
         e.operationnumber === tableMeta.rowData[0]);

        if (newD) {
            console.log(newD);
            await setOpd(tableMeta.rowData[0]);
            await setIsPopup(true);
        } else {
            console.error('Data not found for the specified operation number.');
        }
    } else {
        console.error('Invalid tableMeta.rowData.');
    }
};

const handleEdit = (value, tableMeta, updateValue) => {
  console.log(operationData)
  const operationnumber=tableMeta.rowData[0]
  console.log(operationnumber)
  setEditOperationData(operationnumber);
  setEditFormVisibility(true);
};

    const handleDelete = async (value, tableMeta, updateValue) => {
      console.log(operationData);
      const operationnumber=tableMeta.rowData[0]
    console.log(operationnumber)
      // Display a confirmation dialog with patient's name
      const isConfirmed = window.confirm(`Do you want to delete ${operationnumber}?`);
    
      if (isConfirmed) {
        try {
          await axios.post(`/canceloperationnumber/${admitnumber}/${operationnumber}`, null, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
    
          getOperations();
        } catch (error) {
          // Handle any error here.
          alert("Error cancelling admit:", error);
        }
      } else {
        // User clicked "Cancel" in the confirmation dialog, do nothing.
      }
    };
    
    return (
      <>
      {!hideForm && <OperationForm onOperationAdded ={handleOperationAdded} /> }
      <div style={{marginLeft: "auto"}}>
      {/* <Helmet>
        <title>Billing Records | {currentDomain} | Hospital Management Software | Smart Solutions for Hospital Efficiency</title>
      </Helmet> */}
      </div>
      <div>
        <MUIDataTable
          title="Operation Details"
          data={operationData}
          columns={columns}
          options={options}
        />
      </div>
        {isPopup==true?
          <>

          <Modal show={true}  animation={false} onHide={handleClose} size="lg">
            <Modal.Header closeButton>
             
            </Modal.Header>
            <Modal.Body>
            {opd==undefined?""
              :
              <PrintOperation opd={opd}/>}
            </Modal.Body>
          </Modal>
        </> 
        :
        ""}
        {/* ... your existing code ... */}
    {isEditFormVisible && editOperationData && (
      <UpdateIpdOperationPopUp 
      operationData={editOperationData}
      onClose={() => setEditFormVisibility(false)}
       />
    )}
    </>
    );
  };  
  export default OperationTable;
 