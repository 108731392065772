import React, { useState, useEffect } from "react";
import { Table, Button, Modal, Form } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { IoTrashOutline } from "react-icons/io5";
import axios from "../../../helper/axios";
import { useUser } from "../../../Provider";
import { Helmet } from "react-helmet";
import { NavLink, useHistory } from "react-router-dom";
import MUIDataTable from "mui-datatables";
import { FaEdit } from "react-icons/fa";
import { IconButton } from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
const ListViewUnit = () => {
  // Destructuring values from useUser hook
  const { user, userHospitalcode, userrole1, isAuthRole, isAuthorized, gettokenId, token } = useUser();
  // console.log(user, userHospitalcode, userrole1, isAuthRole, isAuthorized, gettokenId);
  const history = useHistory();
  const [data, setData] = useState([]);
  const currentDate = new Date();
  const day = currentDate.getDate();
  const month = currentDate.getMonth() + 1; // Months are zero-indexed, so add 1
  const year = currentDate.getFullYear();
  // Format the date as DD-MM-YYYY
  const formattedDate = `${day}-${month}-${year}`;
  // const token = sessionStorage.getItem("jwtToken");
  const [userRole, setRole] = useState();

  // When the page loads or refreshes, this function will be called
  useEffect(() => {
    const loadData = async () => {
      // await loadAllDetails();
      // loadAllAppointment(); will wait for loadAllDetails(); to be executed
      await loadAllCategory();
    };

    loadData();
  }, [userHospitalcode, userrole1, isAuthorized]);

  // Load all unit based on user role and hospital code
  const loadAllCategory = async () => {
    try {
      if (userHospitalcode !== undefined && userrole1 !== undefined) {
        console.log("userHospitalcode:", userHospitalcode); // Add this line
        console.log("userrole1:", userrole1); // Add this line
        const res = await axios.get("/getunits", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: {
            hospitalcode: userHospitalcode, // Include the hospital code in the query parameters
            userrole: userrole1, // Include the user role in the query parameters
          },
        });
        setData(res.data);
        console.log(res.data);
      }
    } catch (error) {
      // Handle any error here.
      alert("Error deleting hospital:", error);
    }
  };
  const cancelcategory = async (unitid) => {
    // Display a confirmation dialog
    const isConfirmed = window.confirm("Do you want to cancel this unit?");
  
    if (isConfirmed) {
      try {
        await axios.post(`/cancelunit/${unitid}`, null, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        loadAllCategory();
        //alert("Bill has been canceled.");
        
        // After successfully canceling the bill, refresh the bill list.
      } catch (error) {
        // Handle any error here.
        alert("Error Cancelling Unit:", error);
      }
    } else {
      // User clicked "Cancel" in the confirmation dialog, do nothing.
    }
  };
  // Define columns for MUIDataTable based on user authorization
  let columns;
  if (isAuthorized) {
    columns = [
      {
        name: "unitname",
        label: "Unit Name",
        options: {
          filter: true,
          sort: true,
        },
      },
      {
        name: "hospitalcode",
        label: "Hospital Code",
        options: {
          filter: true,
          sort: true,
          customBodyRender: (value) => (value !== null && value !== undefined) ? value.toString().padStart(5, '0') : ""
        },
      },
      {
        name: "",
        label: "",
        options: {
          customBodyRender: (value, tableMeta, updateValue) => {
            const newD = data.find((e) => {
              return (
                e.unitname === tableMeta.rowData[0] &&
                e.hospitalcode === tableMeta.rowData[1]
              );
            });
            return (
              <>
               <IconButton color="secondary" onClick={() => cancelcategory(newD.unitid)}>
                <DeleteIcon/>
              </IconButton>
              <NavLink to={`/editpathologyunit/${newD.unitid}`}>   
              <IconButton  color="primary">
              <EditIcon/>
              </IconButton>
              </NavLink>
              </>
            );
          },
        },
      },
    ];
  } else {
    columns = [
      {
        name: "unitname",
        label: "Unit Name",
        options: {
          filter: true,
          sort: true,
        },
      },
      {
        name: "",
        label: "",
        options: {
          customBodyRender: (value, tableMeta, updateValue) => {
            const newD = data.find((e) => {
              return (
                e.unitname === tableMeta.rowData[0]
              );
            });
            return (
              <>
               <IconButton color="secondary" onClick={() => cancelcategory(newD.unitid)}>
                <DeleteIcon/>
              </IconButton>
              <NavLink to={`/editpathologyunit/${newD.unitid}`}>   
              <IconButton  color="primary">
              <EditIcon/>
              </IconButton>
              </NavLink>
              </>
            );
          },
        },
      },
    ];
  }

  const options = {
    selectableRows: false,
    onRowClick: ((props) => {
      console.log(props);
    }),
    downloadOptions: {
      filename: `Patient_list_${formattedDate}`, // Customize the filename here to download
      filterOptions: {
        useDisplayedColumnsOnly: true,
        useDisplayedRowsOnly: true,
      },
    },
  };

  const currentDomain = window.location.hostname;

  return (
    <>
      <Helmet>
        <title>Aarogyasaarthi | {currentDomain} Unit List</title>
      </Helmet>
      <NavLink to='/addunit'>
        <button className="btn btn-success btn-sm mr-2" style={{ margin: "10px" }}>
          <svg xmlns="http://www.w3.org/2000/svg" width="19" height="18" style={{ paddingRight: '4px' }} viewBox="0 0 100 100">
            <line x1="10" y1="50" x2="90" y2="50" stroke="white" stroke-width="10" />
            <line x1="50" y1="10" x2="50" y2="90" stroke="white" stroke-width="10" />
          </svg>Add Unit
        </button>
      </NavLink>
      <MUIDataTable
        title={"Pathology Unit List"}
        data={data}
        columns={columns}
        options={options}
        className="custom-mui-datatable"
      />
    </>
  );
};

export default ListViewUnit;