// Import necessary modules and components
import React from "react";
import { useFormik } from "formik";
import { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useHistory, useParams } from "react-router-dom";
import axios from "../../../helper/axios";
import { Table, Button, Modal, Form } from "react-bootstrap";
import { patientregistration } from "../../../helper/schemas";
import { ToastContainer, toast } from 'react-toastify';
import * as Yup from "yup";  // Import Yup for validation
import { useUser } from "../../../Provider";
import { TextField, Autocomplete, InputAdornment, IconButton } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
// Define Yup validation schema for authorized and unauthorized users
const ValidationSchema = Yup.object().shape({
  groupname: Yup.string().required('Bed group name is required'),
  hospitalcode: Yup.string().required("Please select the hospitalcode"),
  description: Yup.string().required("Please enter the description"),
  floorid: Yup.string().required("Please select the floor name"),
});

const ValidationSchema1 = Yup.object().shape({
  groupname: Yup.string().required('Bed name is required'),
  // hospitalcode: Yup.string().required("Please select the hospitalcode"),
  description: Yup.string().required("Please enter the description"),
  floorid: Yup.string().required("Please select the floor name"),
});

const initialValues = {
  groupname: "",
  floorid: "",
  description: "",
  hospitalcode: "",
};

const EditBedGroup = () => {
  const { user, userHospitalcode, userrole1, isAuthRole, isAuthorized, gettokenId, token } = useUser();
  const history = useHistory();
  const [message, setMessage] = useState("");
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [openAutocompleted, setOpenAutocompleted] = useState(false);
  const { bedgroupid } = useParams();
  const handleExpandClicked = () => {
    setOpenAutocompleted(!openAutocompleted);
  };
  const getCurrentDateTime = () => {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0');
    const day = String(now.getDate()).padStart(2, '0');
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    const currentDateTimeString = `${year}-${month}-${day}T${hours}:${minutes}`;
    return currentDateTimeString;
  };

  let Showdate = new Date();
  let ShowTodaydate =
    Showdate.getFullYear() +
    "-" +
    (Showdate.getMonth() + 1) +
    "-" +
    Showdate.getDate();
  const [currentDate, setCurrentDate] = useState(ShowTodaydate);

  const getValidationSchema = () => {
    if (userHospitalcode === 1) {
      return ValidationSchema;
    } else {
      return ValidationSchema1;
    }
  };

  const {
    values,
    errors,
    touched,
    setValues,
    handleChange,
    handleBlur,
    resetForm,
    handleSubmit,
  } = useFormik({
    initialValues,
    validationSchema: getValidationSchema, // Apply validation schema
    onSubmit: async (values, { resetForm }) => {
      try {
        if (userHospitalcode !== undefined) {
          const data = new FormData();

          // Append all form fields to the FormData object
          data.append('document', JSON.stringify(values));
          data.append('bedgroupid', bedgroupid);
          data.append('userHospitalcode', JSON.stringify(userHospitalcode));
          data.append('currentDateTime', JSON.stringify(getCurrentDateTime()));
          data.append('gettokenId', JSON.stringify(gettokenId));
          data.append('currentDate', JSON.stringify(currentDate));

          console.log(JSON.stringify(values));

          const response = await axios.post(`/BedGroupUpdate/'${bedgroupid}'`, data, {
            headers: {
              Authorization: `Beaxrer ${token}`,
              "Content-Type": "multipart/form-data",
            },
            params: {
              hospitalcode: userHospitalcode,
              userrole: userrole1,
            },
          });

          if (response.status === 200) {
            return history.push("/listviewbedgroup");
          } else {
            console.error('Error in updating bed group');
            setSuccess(false);
            setError(true);
          }
        }
      } catch (error) {
        console.error('Error:', error.message);
        setSuccess(false);
        setError(true);
        toast.error('Something Went Wrong');
      }
    },
  });

  const handleSearchChange = async (searchTerm) => {
    const selectedHospitalCode = searchTerm.hospitalcode || userHospitalcode;
    fetchFloorNames(selectedHospitalCode);
  };

  const [data, setData] = useState([]);
  const [floorNames, setFloorNames] = useState([]);

  useEffect(() => {
    const loadData = async () => {
      await loadHospitalcode();
      const selectedHospitalCode = values.hospitalcode || userHospitalcode;
      fetchFloorNames(selectedHospitalCode);
    };
    loadData();
  }, [userHospitalcode, userrole1, isAuthorized, values.hospitalcode]);

  const loadHospitalcode = async () => {
    if (userHospitalcode !== undefined && userrole1 !== undefined) {
      const res = await axios.get("/gethospitalcode", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          hospitalcode: userHospitalcode,
          userrole: userrole1,
        },
      });
      setData(res.data);
      console.log(res.data);
    };
  }

  // Function to fetch floor names based on the selected hospital code
  const fetchFloorNames = async (selectedHospitalCode) => {
    try {
      const response = await axios.get("/getfloornames", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          hospitalcode: selectedHospitalCode,
        },
      });

      if (response.status === 200) {
        setFloorNames(response.data);
      } else {
        console.error(`Failed to fetch available floor numbers with status: ${response.status}`);
      }
    } catch (error) {
      console.error('Error fetching available floor numbers:', error.message);
    }
  };  

    // Function to load bed group details
  const loadBedGroup = async () => {
    try {
      if (userHospitalcode !== undefined && userrole1 !== undefined) {
        const response = await axios.get(`/getbedgroupbybedgroupid/${bedgroupid}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: {
            hospitalcode: userHospitalcode,
            userrole: userrole1,
          },
        });

        const bedgroupdata = response.data;
        console.log(" sndbsjkdnksd", bedgroupdata)
        setValues(bedgroupdata);
      }
    } catch (error) {
      console.error('Error loading bed type details:', error.message);
      alert('Something Went Wrong');
    }
  };

  useEffect(() => {
    const loadData = async () => {
      await loadHospitalcode();
      await loadBedGroup();
    };
    loadData();
  }, [userHospitalcode, userrole1, isAuthorized]);

  const currentDomain = window.location.hostname;

  return (
    <div className="p-3 pt-0 appointment">
      <Helmet>
        <title>Aarogyasaarthi | Bed Group Edit</title>
        <title>Bed Group Edit | {currentDomain} | Hospital Management Software | Smart Solutions for Hospital Efficiency</title>
      </Helmet>
      <form onSubmit={handleSubmit}>
        <div class="fill header"><h3 class="float-left"> Bed Group Edit</h3> </div>
        <div className="patient-new primary-info">
          <div className="row">
            <div className="col-md-12">
              <div className="row">
                <div className="col-md-6">
                  <div className="col-md-12 mt-3">
                    <div className="row">
                      <div className="col-md-4">
                        <label
                          htmlFor="groupname"
                          className="col-form-label leftone"
                        >
                          Bed Group Name
                        </label>
                      </div>
                      <div className="col-md-8">
                        <input
                          type="text"
                          autoComplete="off"
                          name="groupname"
                          id="groupname"
                          placeholder="Group Name"
                          value={values.groupname}
                          onChange={handleChange}
                          style={{ width: '73%', height: '32px', border: '1px solid #ced4da', color: '#495057' }}
                        />
                        {errors.groupname && touched.groupname ? (
                          <p className="form-erroremployee">{errors.groupname}</p>
                        ) : null}
                      </div>
                    </div>
                  </div>

                  <div className="col-md-12 mt-3">
                    <div className="row">
                      <div className="col-md-4">
                        <label
                          htmlFor="floorname"
                          className="col-form-label leftone"
                        >
                          Floor Name
                        </label>
                      </div>
                      <div className="col-md-8">
                        <Autocomplete
                          freeSolo
                          id="free-solo"
                          options={floorNames}
                          getOptionLabel={(bedType) => bedType.floorname}
                          value={floorNames.find((bedType) => bedType.floorid === values.floorid) || null}
                          open={openAutocompleted}
                          onOpen={() => setOpenAutocompleted(true)}
                          onClose={() => setOpenAutocompleted(false)}
                          onChange={(event, newValue) => {
                            setValues({
                              ...values,
                              floorid: newValue ? newValue.floorid : ""
                            });
                          }}
                          onBlur={handleBlur}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              placeholder="Select BedType"
                              InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <IconButton onClick={handleExpandClicked}>
                                      <ExpandMoreIcon />
                                    </IconButton>
                                  </InputAdornment>
                                ),
                                //style: { width: '74%', padding: '0px ', border: "none" } // Adjust width here
                              }}
                            />
                          )}
                        />
                        {errors.floorid && touched.floorid ? (
                          <p className="form-erroremployee">{errors.floorid}</p>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="col-md-12 mt-3">
                    <div className="row">
                      <div className="col-md-4">
                        <label
                          htmlFor="description"
                          className="col-form-label leftone"
                        >
                          Description
                        </label>
                      </div>
                      <div className="col-md-8">
                        <input
                          type="text"
                          autoComplete="off"
                          name="description"
                          id="description"
                          placeholder="Description"
                          value={values.description}
                          onChange={handleChange}
                          style={{ width: '73%', height: '32px', border: '1px solid #ced4da ', color: '#495057' }}
                        />
                        {errors.description && touched.description ? (
                          <p className="form-erroremployee">{errors.description}</p>
                        ) : null}
                      </div>
                    </div>
                  </div>

                  {isAuthorized ? (<div className="col-md-12 mt-3">
                    <div className="row">

                      <div className="col-md-4">
                        <label
                          htmlFor="hospitalcode"
                          className="col-form-label leftone"
                        >
                          Hospital Code
                        </label>
                      </div>
                      <div className="col-md-8">
                        {/* Conditionally render the dropdown and input field */}
                        {isAuthorized ? (
                          <input
                            type="text"
                            autoComplete="off"
                            name="hospitalcode"
                            id="hospitalcode"
                            placeholder="Hospital Code"
                            value={values.hospitalcode}
                            // onChange={handleChange}
                            // onBlur={handleBlur}
                            disabled={true} // Set the input as disabled
                            // style={{ width: '73%', height: '32px', border: '1px solid #ced4da', color: '#495057' }}
                            className="form-control "
                          >
                           
                          </input>
                        ) : (
                          <input
                            type="text"
                            name="hospitalcode"
                            id="hospitalcode"
                            value={userHospitalcode}
                            disabled={true} // Set the input as disabled
                            className="form-control "
                          />
                        )}
                        {isAuthorized && errors.hospitalcode && touched.hospitalcode ? (
                          <p className="form-erroremployee">{errors.hospitalcode}</p>
                        ) : null}
                      </div></div></div>) : null}



                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="modal-buttons">
          <Button type="submit" variant="success" className="mx-3">
            Update
          </Button>
        </div>
      </form>
    </div>
  );
};

export default EditBedGroup;