import React, { useState, useEffect } from "react";
import axios from '../helper/axios';
import Autocomplete from "@mui/material/Autocomplete";
import { TextField, InputAdornment, IconButton } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useUser } from "../Provider";

const SearchRadiologyUnit = ({ onRadiologyUnitChange, hospitalvalue }) => {
    const { userrole1, token } = useUser();
    const [data,setUnit] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState('');
    const [openAutocomplete, setOpenAutocomplete] = useState(false);

    useEffect(() => {
        fetchUnit();
        setSelectedCategory("")
    }, [hospitalvalue, userrole1]);

    const fetchUnit = async () => {
        try {
          const response = await axios.get("/unitinradiology", {
            headers: {
              Authorization: `Bearer ${token}`,
            },
            params: {
              hospitalcode:hospitalvalue,
            },
          });
    
          if (response.status === 200) {
            setUnit(response.data);
          } else {
            console.error(`Failed to fetch available unit with status: ${response.status}`);
          }
        } catch (error) {
          console.error('Error fetching available unit:', error.message);
        }
      };

    const handleCategoryChange = (event, value) => {
      if (value) {
        setSelectedCategory(value);
        onRadiologyUnitChange(value);
    } else {
        // Handle the case when no value is selected
        setSelectedCategory('');
    }
    };
    const getOptionLabel = (option) => {
      // Handle the case when option is null or undefined
      return option?.radiologyunitname || '';
  };

  const handleExpandClick = () => {
    setOpenAutocomplete(!openAutocomplete);
};
    return (
        <div className="searchbar">
            <Autocomplete
                freeSolo
                id="free-solo"
                options={data}
                getOptionLabel={getOptionLabel}
                value={selectedCategory}
                onChange={handleCategoryChange}
                open={openAutocomplete}
                onOpen={() => setOpenAutocomplete(true)}
                onClose={() => setOpenAutocomplete(false)}
                renderInput={(params) =>
                    <TextField
                        {...params}
                        placeholder="Select Unit"
                        InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton onClick={handleExpandClick}>
                                        <ExpandMoreIcon />
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                }
            />
        </div>
    );
};

export default SearchRadiologyUnit;
