import React, { useState, useEffect } from "react";
import { NavLink, useHistory } from "react-router-dom";
import MUIDataTable from "mui-datatables";
import axios from "../../helper/axios";
import { Helmet } from "react-helmet";
import { useUser } from "./../../Provider";
const ListViewHospitalReg = () => {
  const { user, userHospitalcode, userrole1, isAuthRole, isAuthorized, gettokenId,token } = useUser();
  const history = useHistory();
 // const token = sessionStorage.getItem("jwtToken");
 // const [userRole, setRole] = useState();
  //const [gettokenId, setTokenId] = useState();
  const [data, setData] = useState([]);
  const cancelHospital = async (hospitalcode) => {
    // Display a confirmation dialog
    const isConfirmed = window.confirm("Do you want to delete this hospital?");

    if (isConfirmed) {
      try {
        console.log(hospitalcode);
        await axios.post(`/deletehospital/${hospitalcode}`, null, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        // After successfully canceling the bill, refresh the bill list.
        loadAllHospital();
      } catch (error) {
        // Handle any error here.
        alert("Error deleteing hospital:", error);
      }
    } else {
      // User clicked "Cancel" in the confirmation dialog, do nothing.
    }
  };
  // const loadAllDetails = async () => {
  //   try {
  //     const response = await axios.post('/loginuser', null, {
  //       headers: { Authorization: `Bearer ${token}` },
  //     });
  //     const UserLogedin = response.data.person[0][0].empid;
  //     const UserRoles = response.data.person[0][0].role;
  //     setTokenId(UserLogedin);
  //     //  console.log(UserRoles)
  //     setRole(UserLogedin)
  //     //console.log("------"+UserLogedinId);

  //   } catch (error) {
  //     if (error.response.status === 403) {
  //       alert('Token Expired. Please login again.');
  //       sessionStorage.removeItem('jwtToken');
  //       //Cookies.remove('jwtToken');
  //       history.push('/');
  //     } else if (error.response.status === 401) {
  //       alert('Invalid token. Please login again.');
  //       sessionStorage.removeItem('jwtToken');
  //       //Cookies.remove('jwtToken');
  //       history.push('/');
  //     } else {
  //       alert('Error: ' + error.response.data.error);
  //     }
  //   }
  // };
  const loadAllHospital = async () => {
    try {
      const res = await axios.get("/gethospitaldetails", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      console.log(res.data)
      setData(res.data)
      if (res.status === 200) {

      } else {
        // Handle other status codes if needed
        alert(`Unexpected status code: ${res.status}`);
      }
    } catch (error) {
      // Handle the error here
      alert(`An error occurred: ${error.message}`);

      // You can display an error message to the user or take other actions as needed.
    }
  };

  useEffect(() => {
   // loadAllDetails();
    loadAllHospital();
  }, []);
  const columns = [
    {
      name: "hospitalcode",
      label: "Hospital Code",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => (value !== null && value !== undefined) ? value.toString().padStart(5, '0') : ""
      },
    },
    {
      name: "hospitalname",
      label: "Hospital Name",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta) => {
          // Assuming the patient id is also available in your data
          const hospitalcode = tableMeta.rowData[0]; // Adjust this index as needed

          return (
            <NavLink to={`/hospital-list/${hospitalcode}/edit`} className="addlink">{value}</NavLink>
          );
        }
      },
    },
    {
      name: "registrationno",
      label: "Registration No",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "phone",
      label: "Phone No",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "email",
      label: "Email",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "address",
      label: "Address",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "",
      label: "",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          const newD = data.find((e) => {
            return (
              e.hospitalcode == tableMeta.rowData[0] &&
              e.hospitalname == tableMeta.rowData[1] &&
              e.registrationno == tableMeta.rowData[2] &&
              e.phone == tableMeta.rowData[3] &&
              e.email == tableMeta.rowData[4] &&
              e.address == tableMeta.rowData[5]

            );
          });

          console.log('newD:', newD);
          return (
            <>
              <button
                className="btn btn-danger btn-sm"
                onClick={() => cancelHospital(newD.hospitalcode)}
              >
                Delete
              </button>
            </>
          );
        },
      },
    },
  ]
  const options = {
    selectableRows: false,
    onRowClick: ((props) => {
      console.log(props)
    })
  }
  const currentDomain = window.location.hostname;

  return (
    <>
      <div style={{ marginLeft: "auto" }}>
        <Helmet>
          <title>Hospital List | {currentDomain} | Hospital Management Software | Smart Solutions for Hospital Efficiency</title>
        </Helmet>
        <NavLink to="/hospital-registration">
          <button className="btn btn-success btn-sm mr-2" style={{ margin: "10px" }}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="19"
              height="18"
              style={{ paddingRight: "4px" }}
              viewBox="0 0 100 100"
            >
              <line
                x1="10"
                y1="50"
                x2="90"
                y2="50"
                stroke="white"
                stroke-width="10"
              />
              <line
                x1="50"
                y1="10"
                x2="50"
                y2="90"
                stroke="white"
                stroke-width="10"
              />
            </svg>
            New Hospital Registration
          </button>
        </NavLink>
      </div>
      <MUIDataTable
        title={"Hospital List"}
        data={data}
        columns={columns}
        options={options}
        className="custom-mui-datatable"
      />

    </>


  );
}

export default ListViewHospitalReg