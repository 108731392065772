import React from "react";
import { useFormik } from "formik";
import { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useHistory,useParams,NavLink } from "react-router-dom";
import axios from "../../../helper/axios";
import { Table, Button, Modal, Form } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useUser } from "../../../Provider";
import { GrEdit } from "react-icons/gr";
import { chargeValidation,chargeValidationHospital} from "../../../helper/schemas";
const initialValues = {
  chargetype:"",
  chargename: "",
  chargeamount: "",
  tax:0,
  hospitalcode:""
};
const ChargeDetails = () => {
  const { chargecode } = useParams();
  const {user, userHospitalcode, userrole1, isAuthRole,isAuthorized,gettokenId,token  } = useUser();
  const history = useHistory();
   //get current date
   let Showdate = new Date();
   let ShowTodaydate =
     Showdate.getFullYear() +
     "-" +
     (Showdate.getMonth() + 1) +
     "-" +
     Showdate.getDate();
   const [currentDate, setCurrentDate] = useState(ShowTodaydate);
  const [data, setData] = useState([]);
  //const token = sessionStorage.getItem("jwtToken");
  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    resetForm,
    setValues,
  } = useFormik({
    initialValues,
    validationSchema:isAuthorized ? chargeValidationHospital : chargeValidation, // Conditionally apply the schema
    onSubmit: async (values, action) => {
      try{
        if (userHospitalcode !== undefined && userrole1 !== undefined ) {
      const data = new FormData();
      data.append("document", JSON.stringify(values));
      data.append("currentDate", JSON.stringify(currentDate));
      data.append("gettokenId", gettokenId); // Add gettokenId to the form data
      console.log(JSON.stringify(values))
      const response = await axios.post(`/updatecharge/${chargecode}`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
        params: {
          hospitalcode: userHospitalcode, // Include the hospitalcode in the query parameters
          userrole: userrole1, // Include the userrole in the query parameters
        },
      });
      if (response.status === 200) {
      return history.push("/allcharges");
      }
    }
    }catch{

    }
    },
  });
  const loadChargebyChargecode = async () => {
    try{
      if (userHospitalcode !== undefined && userrole1 !== undefined ) {
      const res = await axios.get(`/getcharge/${chargecode}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          hospitalcode: userHospitalcode, // Include the hospitalcode in the query parameters
          userrole: userrole1, // Include the userrole in the query parameters
        },
      });
      const newProduct = await res.data;
      setValues(newProduct);
      console.log(newProduct
        )
    }
  }catch(error){
    if (error.response.status === 403) {
      alert('Unauthorised');
    } else if (error.response.status === 401) {
      alert('Error: ' + error.response.data.error);
    } else {
      alert('Error: ' + error.response.data.error);
    }
  }
}

  const loadHospitalcode = async () => {
    //when userHospitalcode and userrole1 is not undefined then execute /gethospitalcode
    if (userHospitalcode !== undefined && userrole1 !== undefined) {
      const res = await axios.get("/gethospitalcode", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          hospitalcode: userHospitalcode, // Include the hospitalcode in the query parameters
          userrole: userrole1, // Include the userrole in the query parameters
        },
      });
      setData(res.data);
      console.log(res.data);
    };
  }
  useEffect(() => {
    const loadData = async () => {
      await loadHospitalcode();
      await loadChargebyChargecode();
    };
    loadData();
  }, [userHospitalcode, userrole1, isAuthorized]);
  const handleReset = () => {
    // Reset the form using Formik's resetForm function
    resetForm();
  };
  const currentDomain = window.location.hostname;
    return (
        <div className="appointment p-2">
             <Helmet>
        <title> Charge Details | {currentDomain} | Hospital Management Software | Smart Solutions for Hospital Efficiency</title>
      </Helmet>
            <div className="editbutt">
                <button className="input-butt">
                    <NavLink to={`/editcharges/${chargecode}/edit`}>
                        <GrEdit />
                    </NavLink>
                </button>
                <br></br>
            </div>
            <form onSubmit={handleSubmit}>
                <div class="fill header"><h3 class="float-left"> Charges Type  </h3> </div>
                <div className="patient-new primary-info">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="col-md-12 mt-3">
                                        <div className="row">
                                            <div className="col-md-4">
                                                <label
                                                    htmlFor="chargetype"
                                                    className="col-form-label leftone"
                                                >
                                                    Charges Type
                                                </label>
                                            </div>
                                            <div className="col-md-8">
                                                <input
                                                    type="text"
                                                    autoComplete="off"
                                                    name="chargetype"
                                                    id="chargetype"
                                                    placeholder="Charge Type"
                                                    value={values.chargetype}
                                                    disabled
                                                    className="form-control "
                                                />
                                                {errors.chargetype && touched.chargetype ? (
                                                    <p className="form-erroremployee">{errors.chargetype}</p>
                                                ) : null}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-12 mt-3">
                                        <div className="row">
                                            <div className="col-md-4">
                                                <label
                                                    htmlFor="chargename"
                                                    className="col-form-label leftone"
                                                >
                                                    Charges Name
                                                </label>
                                            </div>
                                            <div className="col-md-8">
                                                <input
                                                    type="text"
                                                    autoComplete="off"
                                                    name="chargename"
                                                    id="chargename"
                                                    placeholder="Charge Name"
                                                    value={values.chargename}
                                                    disabled
                                                    className="form-control "
                                                />
                                                {errors.chargename && touched.chargename ? (
                                                    <p className="form-erroremployee">{errors.chargename}</p>
                                                ) : null}
                                            </div>
                                        </div>
                                    </div>
                                    {isAuthorized ? (  <div className="col-md-12 mt-3">
                                        <div className="row">
                                            <div className="col-md-4">
                                                <label
                                                    htmlFor="tax"
                                                    className="col-form-label "
                                                >
                                                    Charges Tax
                                                </label>
                                            </div>
                                            <div className="col-md-8">
                                                <input
                                                    type="text"
                                                    autoComplete="off"
                                                    name="tax"
                                                    id="tax"
                                                    placeholder="Charge Name"
                                                    value={values.tax}
                                                    disabled
                                                    className="form-control "
                                                />
                                                {errors.tax && touched.tax ? (
                                                    <p className="form-erroremployee">{errors.tax}</p>
                                                ) : null}
                                            </div>
                                        </div>
                                    </div> ) : null}
                                </div>
                                <div className="col-md-6">
                                    {isAuthorized ? (<div className="col-md-12 mt-3">
                                        <div className="row">

                                            <div className="col-md-4">
                                                <label
                                                    htmlFor="hospitalcode"
                                                    className="col-form-label leftone"
                                                >
                                                    Hospital Code
                                                </label>
                                            </div>
                                            <div className="col-md-8">
                                                {/* Conditionally render the dropdown and input field */}
                                                {isAuthorized ? (
                                                    <input
                                                    type="text"
                                                    name="hospitalcode"
                                                    id="hospitalcode"
                                                    value={values.hospitalcode}
                                                    disabled={true} // Set the input as disabled
                                                    className="form-control "
                                                />
                                                    // <select
                                                    //     type="text"
                                                    //     autoComplete="off"
                                                    //     name="hospitalcode"
                                                    //     id="hospitalcode"
                                                    //     placeholder="Hospital Code"
                                                    //     value={values.hospitalcode}
                                                    //     onChange={handleChange}
                                                    //     onBlur={handleBlur}
                                                    //     style={{ width: '73%', height: '32px', border: '1px solid #ced4da', color: '#495057' }}
                                                    // >
                                                    //     <option>Select</option>
                                                    //     {data.map((code) => (
                                                    //         <option value={code.hospitalcode} key={code.id}>
                                                    //             {code.hospitalcode}
                                                    //         </option>
                                                    //     ))}
                                                    // </select>
                                                ) : (
                                                    <input
                                                        type="text"
                                                        name="hospitalcode"
                                                        id="hospitalcode"
                                                        value={userHospitalcode}
                                                        disabled={true} // Set the input as disabled
                                                        className="form-control "
                                                    />
                                                )}
                                                {isAuthorized && errors.hospitalcode && touched.hospitalcode ? (
                                                    <p className="form-erroremployee">{errors.hospitalcode}</p>
                                                ) : null}
                                            </div></div></div>) : null}
                                            
                                    <div className="col-md-12 mt-3">
                                        <div className="row">
                                            <div className="col-md-4">
                                                <label
                                                    htmlFor="chargeamount"
                                                    className="col-form-label leftone"
                                                >
                                                    Charge Amount
                                                </label>
                                            </div>
                                            <div className="col-md-8">
                                                <input
                                                    type="text"
                                                    autoComplete="off"
                                                    name="chargeamount"
                                                    id="chargeamount"
                                                    placeholder="Charge Amount"
                                                    value={values.chargeamount}
                                                    disabled
                                                    className="form-control "
                                                />
                                                {errors.chargeamount && touched.chargeamount ? (
                                                    <p className="form-erroremployee">{errors.chargeamount}</p>
                                                ) : null}
                                            </div>
                                        </div>
                                    </div>
                                    {!isAuthorized ? (   <div className="col-md-12 mt-3">
                                        <div className="row">
                                            <div className="col-md-4">
                                                <label
                                                    htmlFor="tax"
                                                    className="col-form-label "
                                                >
                                                    Charges Tax
                                                </label>
                                            </div>
                                            <div className="col-md-8">
                                                <input
                                                    type="text"
                                                    autoComplete="off"
                                                    name="tax"
                                                    id="tax"
                                                    placeholder="Charge Name"
                                                    value={values.tax}
                                                    disabled
                                                    className="form-control "
                                                />
                                                {errors.tax && touched.tax ? (
                                                    <p className="form-erroremployee">{errors.tax}</p>
                                                ) : null}
                                            </div>
                                        </div>
                                    </div>) : null}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-6  mt-3">
                    <Button
                        type="submit"
                        variant="success"
                        className="mx-3"
                    >
                        Save
                    </Button>
                    <Button
                        variant="primary"
                        className="mx-3"
                        onClick={handleReset}
                    >
                        Reset
                    </Button>
                </div>
            </form>
        </div>
    );
};

export default ChargeDetails;
