import React from "react";
import { useFormik } from "formik";
import { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useHistory } from "react-router-dom";
import axios from "../../../helper/axios";
import { Table, Button, Modal, Form } from "react-bootstrap";
import { patientregistration } from "../../../helper/schemas";
import { ToastContainer, toast } from 'react-toastify';
import * as Yup from "yup";
import { useUser } from "../../../Provider";
import HospitalCodeSearch from "../../../Component/HospitalCodeSearch";

// Define Yup validation schema for authorized and unauthorized users
const ValidationSchema = Yup.object().shape({
  floorname: Yup.string().required('Floor name is required'),
  hospitalcode: Yup.string().required("Please select the hospitalcode"),
  description: Yup.string().required("Please enter the description"),
});

const ValidationSchema1 = Yup.object().shape({
  floorname: Yup.string().required('Floor name is required'),
  // hospitalcode: Yup.string().required("Please select the hospitalcode"),
  description: Yup.string().required("Please enter the description"),
});

// Initial form values
const initialValues = {
  floorname: "",
  description: "",
  hospitalcode: "",
};

const AddFloor = () => {
  // Extract user information using custom hook
  const { user, userHospitalcode, userrole1, isAuthRole, isAuthorized, gettokenId, token } = useUser();
  const history = useHistory();
  const [message, setMessage] = useState("");
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [data, setData] = useState([]);
  const [userRole, setRole] = useState();
  let Showdate = new Date();
  let ShowTodaydate = Showdate.getFullYear() + "-" + (Showdate.getMonth() + 1) + "-" + Showdate.getDate();
  const [currentDate, setCurrentDate] = useState(ShowTodaydate);

  // Function to determine which Yup validation schema to use based on userHospitalcode
  const getValidationSchema = () => {
    if (userHospitalcode === 1) {
      return ValidationSchema;
    } else {
      return ValidationSchema1;
    }
  };

  // Formik hook to manage form state and handle form submission
  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    resetForm,
    setValues
  } = useFormik({
    initialValues,
    validationSchema: getValidationSchema(), // Apply validation schema
    onSubmit: async (values, action) => {
      try {
        const data = new FormData();
        data.append("document", JSON.stringify(values));
        data.append("currentDate", JSON.stringify(currentDate));
        data.append("gettokenId", JSON.stringify(gettokenId));
        data.append("userHospitalcode", JSON.stringify(userHospitalcode));

        const response = await axios.post("/addfloor", data, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        });

        if (response.status === 200) {
          return history.push("/listviewfloor");
        }
      } catch (error) {
        console.log(error);
        toast("Something Went Wrong");
      }
    },
  });
   //Hospitalcode search
 const onSearchItems = (searchTerm) => {
  console.log("------",searchTerm)
  setValues({
    ...values,
    hospitalcode: searchTerm.hospitalcode,
    // Update other fields as needed
  });
};
  // Function to reset the form
  const handleReset = () => {
    resetForm();
  };
  // Load hospital code information
  useEffect(() => {
    const loadData = async () => {
      await loadHospitalcode();
    };
    loadData();
  }, [userHospitalcode, userrole1, isAuthorized]);

  // Function to load hospital code information
  const loadHospitalcode = async () => {
    if (userHospitalcode !== undefined && userrole1 !== undefined) {
      const res = await axios.get("/gethospitalcode", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          hospitalcode: userHospitalcode,
          userrole: userrole1,
        },
      });
      setData(res.data);
    };
  }

  const currentDomain = window.location.hostname;

  return (
    <div className="p-3 pt-0 appointment">
      <Helmet>
        <title>Add Floor | {currentDomain} | Hospital Management Software | Smart Solutions for Hospital Efficiency</title>
      </Helmet>
      <form onSubmit={handleSubmit}>
        {success && <div>Add Floor</div>}
        {error && <div>Error in add floor please try again</div>}
        <div class="fill header"><h3 class="float-left"> Add Floor </h3> </div>
        <div className="patient-new primary-info">
          <div className="row">
            <div className="col-md-12">
              <div className="row">
                <div className="col-md-6">
                  <div className="col-md-12 mt-3">
                    <div className="row">
                      <div className="col-md-4">
                        <label
                          htmlFor="floorname"
                          className="col-form-label leftone"
                        >
                          Floor Name
                        </label>
                      </div>
                      <div className="col-md-8">
                        <input
                          type="text"
                          autoComplete="off"
                          name="floorname"
                          id="floorname"
                          placeholder="Floor Name"
                          value={values.floorname}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          style={{ width: '73%', height: '32px', border: '1px solid #ced4da', color: '#495057' }}
                        />
                        {errors.floorname && touched.floorname ? (
                          <p className="form-erroremployee">{errors.floorname}</p>
                        ) : null}
                      </div>
                    </div>
                  </div>

                  {isAuthorized ? (<div className="col-md-12 mt-3">
                    <div className="row">
                      <div className="col-md-4">
                        <label
                          htmlFor="hospitalcode"
                          className="col-form-label leftone"
                        >
                          Hospital Code
                        </label>
                      </div>
                      <div className="col-md-8">
                        {isAuthorized ? (
                            <HospitalCodeSearch onSearchItem={onSearchItems} />
                          // <select
                          //   type="text"
                          //   autoComplete="off"
                          //   name="hospitalcode"
                          //   id="hospitalcode"
                          //   placeholder="Hospital Code"
                          //   value={values.hospitalcode}
                          //   onChange={handleChange}
                          //   onBlur={handleBlur}
                          //   style={{ width: '73%', height: '32px', border: '1px solid #ced4da', color: '#495057' }}
                          // >
                          //   <option>Select</option>
                          //   {data.map((code) => (
                          //     <option value={code.hospitalcode} key={code.id}>
                          //       {code.hospitalcode}
                          //     </option>
                          //   ))}
                          // </select>
                        ) : (
                          <input
                            type="text"
                            name="hospitalcode"
                            id="hospitalcode"
                            value={userHospitalcode}
                            disabled={true}
                            className="form-control "
                          />
                        )}
                        {isAuthorized && errors.hospitalcode && touched.hospitalcode ? (
                          <p className="form-erroremployee">{errors.hospitalcode}</p>
                        ) : null}
                      </div>
                    </div>
                  </div>) : null}
                </div>

                <div className="col-md-6">
                  <div className="col-md-12 mt-3">
                    <div className="row">
                      <div className="col-md-4">
                        <label
                          htmlFor="description"
                          className="col-form-label leftone"
                        >
                          Description
                        </label>
                      </div>
                      <div className="col-md-8">
                        <input
                          type="text"
                          autoComplete="off"
                          name="description"
                          id="description"
                          placeholder="Description"
                          value={values.description}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          style={{ width: '73%', height: '32px', border: '1px solid #ced4da', color: '#495057' }}
                        />
                        {errors.description && touched.description ? (
                          <p className="form-erroremployee">{errors.description}</p>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-6  mt-3">
          <Button
            type="submit"
            variant="success"
            className="mx-3"
          >
            Save
          </Button>
          <Button
            variant="primary"
            className="mx-3"
            onClick={handleReset}
          >
            Reset
          </Button>
        </div>
      </form>
    </div>
  );
};

export default AddFloor;