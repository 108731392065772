import React, { useState, useEffect } from "react";
import axios from '../helper/axios';
import Autocomplete from "@mui/material/Autocomplete";
import { TextField, InputAdornment, IconButton } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useUser } from "../Provider";


const SearchMedicineCategory = ({ onCategoryChange, hospitalvalue }) => {
    const { userrole1, token } = useUser();
    const [data, setCategory] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState('');
    const [openAutocomplete, setOpenAutocomplete] = useState(false);
    useEffect(() => {
        fetchCateoryNames();
        setSelectedCategory("")
    }, [hospitalvalue, userrole1]);

    const fetchCateoryNames = async () => {
        try {
            const response = await axios.get("/medicinecategory", {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                params: {
                    hospitalcode: hospitalvalue,
                },
            });

            if (response.status === 200) {
                setCategory(response.data);
            } else {
                console.error(`Failed to fetch available medicine categories with status: ${response.status}`);
            }
        } catch (error) {
            console.error('Error fetching available medicine categories:', error.message);
        }
    };

    const handleCategoryChange = (event, value) => {
        if (value) {
            setSelectedCategory(value);
            onCategoryChange(value);
        }
        else{
        setSelectedCategory("")
    }
    };
    const getOptionLabel = (option) => {
        // Handle the case when option is null or undefined
        return option?.medicinecategory || '';
    };
    const handleExpandClick = () => {
        setOpenAutocomplete(!openAutocomplete);
    };
    return (
        <div className="searchbar">
            <Autocomplete
                freeSolo
                id="free-solo"
                options={data}
                getOptionLabel={getOptionLabel }
                open={openAutocomplete}
                onOpen={() => setOpenAutocomplete(true)}
                onClose={() => setOpenAutocomplete(false)}
                value={selectedCategory}
                onChange={handleCategoryChange}
                renderInput={(params) =>
                    <TextField
                        {...params}
                        placeholder="Select Category"
                        InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton onClick={handleExpandClick}>
                                        <ExpandMoreIcon />
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                }
            />
        </div>
    );
};

export default SearchMedicineCategory;