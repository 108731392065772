import React from "react";
import { useFormik } from "formik";
import { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useHistory } from "react-router-dom";
import axios from "../../helper/axios";
import { Table, Button, Modal, Form } from "react-bootstrap";
import { hospitalregistration } from "../../helper/schemas";
import { useUser } from "./../../Provider";

const initialValues = {
  hospitalname:"",
  hospital: "",
  registrationnumber: "",
  phoneno: "",
  address: "",
  email: "",
  // currency: "",
  // currencysymbol: "",
  //creditlimit: "",
  time: "",
  workhour: "",
  //pathologyheader: "",
  // radiologyheader: "",
  // billingheader: "",
  // prescriptionheader: "",
  birthcertificate: "",
  deathcertificate: "",
  licenseduration: "",
  licenserate: "",
  amountpaid: "",
  licenseexpirydate: "",
  numberofusers: "",
  remarks: "",
  hospitallogo: "",
  hospitalfavicon: "",
  attachone: "",
  attachtwo: "",
  attachthree: "",
};
//This is the main component for HospitalRegistration form
export const HospitalRegistration = () => {
  const { user, userHospitalcode, userrole1, isAuthRole, isAuthorized, gettokenId,token } = useUser();
  const history = useHistory();
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
 // const token = sessionStorage.getItem("jwtToken");
  const [userRole, setRole] = useState();
 // const [gettokenId, setTokenId] = useState();
 // const [userHospitalcode, setUserHospitalcode] = useState();
 // const [userrole1, setUserrole1] = useState();
  //get the current date
  let Showdate = new Date();
  let ShowTodaydate =
    Showdate.getFullYear() +
    "-" +
    (Showdate.getMonth() + 1) +
    "-" +
    Showdate.getDate();
  const [currentDate, setCurrentDate] = useState(ShowTodaydate);
  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    resetForm,
  } = useFormik({
    initialValues,
    validationSchema: hospitalregistration,
  //send data to backend
    onSubmit: async (values, action) => {
      const data = new FormData();
      data.append("document", JSON.stringify(values));
      data.append("birthcertificate", file);
        data.append("deathcertificate", deathcertificatefile);
         data.append("hospitallogofile", hospitallogofile);
        // data.append("hospitalfaviconfile", hospitalfaviconfile);
         data.append("attachonefile", attachonefile);
         data.append("attachtwofile", attachtwofile);
         data.append("gettokenId", JSON.stringify(gettokenId));
         data.append("currentDate", JSON.stringify(currentDate));

      console.log(hospitallogofile)
      console.log(hospitalfaviconfile)
      // data.append("typee", JSON.stringify(typee));
      // data.append("currentDate", JSON.stringify(currentDate));

      console.log(JSON.stringify(values));
      const response = await axios.post("/hospitalregister", data, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      });
      console.log(
        "🚀 ~ file: Registration.jsx ~ line 11 ~ Registration ~ values",
        values
      );
      if (response.status === 200) {
        return history.push("/hospital-list");
      }
    },
  });
  const handleReset = () => {
    // Reset the form using Formik's resetForm function
    resetForm();
  };

const [file, setFile] = useState(null);
const [deathcertificatefile, setDeathcertificateFile] = useState(null);
const [hospitallogofile, setHospitallogoFile] = useState(null);
const [hospitalfaviconfile, setHospitalfaviconFile] = useState(null);
const [attachonefile, setAttachoneFile] = useState(null);
const [attachtwofile, setAttachtwoFile] = useState(null);
const [attachthreefile, setAttachthreeFile] = useState(null);
const handleFileChangeBirthCertificate = (event) => {
  const selectedFile = event.target.files[0];
  if (selectedFile) {
    // Set the file in the state
    setFile(selectedFile);

    // Set the file name as the value of the "birthcertificate" field
    handleChange({
      target: {
        name: "birthcertificate",
        value: selectedFile,
        size: selectedFile.size,
      },
    });
  }
};


 // Add a new function to handle file change and extract the file name:
const handleFileChangeDeathCertificate = (event) => {
  const selectedFiles = event.target.files[0];
  if (selectedFiles) {
    // Set the file in the state
    setDeathcertificateFile(selectedFiles);
  handleChange({
    target: {
      name: "deathcertificate",
      value: selectedFiles,
      size: selectedFiles.size,
    },
  });
}
};
 // Add a new function to handle file change and extract the file name:
 const handleFileChangeHospitallogo = (event) => {
  const hospitallogFiles = event.target.files[0];
  if (hospitallogFiles) {
    // Set the file in the state
    setHospitallogoFile(hospitallogFiles);

    // Get the size of the image
    const fileSizeInBytes = hospitallogFiles.size;
    handleChange({
      target: {
        name: "hospitallogo",
        value: hospitallogFiles,
        size: fileSizeInBytes,
      },
    });
  }
};
 // Add a new function to handle file change and extract the file name:
const handleFileChangeAttachone = (event) => {
  const attachoneFiles = event.target.files[0];
  if (attachoneFiles) {
    // Set the file in the state
    setAttachoneFile(attachoneFiles);
  handleChange({
    target: {
      name: "attachone",
      value: attachoneFiles,
      size: attachoneFiles.size,
    },
  });
}
};
 // Add a new function to handle file change and extract the file name:
const handleFileChangeAttachtwo = (event) => {
  const attachtwoFiles = event.target.files[0];
  if (attachtwoFiles) {
    // Set the file in the state
    setAttachtwoFile(attachtwoFiles);
  handleChange({
    target: {
      name: "attachtwo",
      value: attachtwoFiles,
      size: attachtwoFiles.size,
    },
  });
}
};
 // Add a new function to handle file change and extract the file name:
const handleFileChangeAttachthree = (event) => {
  const attachthreeFiles = event.target.files[0];
  if (attachthreeFiles) {
    // Set the file in the state
    setAttachthreeFile(attachthreeFiles);
  handleChange({
    target: {
      name: "attachthree",
      value: attachthreeFiles,
      size: attachthreeFiles.size,
    },
  });
}
};
 // Add a new function to handle file change and extract the file name:
const handleFileChangeHospitalfavicon = (event) => {
  const hospitalfaviconFiles = event.target.files[0];
  if (hospitalfaviconFiles) {
    // Set the file in the state
    setHospitalfaviconFile(hospitalfaviconFiles);
  handleChange({
    target: {
      name: "hospitalfavicon",
      value:hospitalfaviconFiles,
      size:hospitalfaviconFiles.size,
    },
  });
}
};

  const currentDomain = window.location.hostname;

//this is the return function where we will do the UI changes
  return (
    <div className="p-3 pt-0 appointment">
       <Helmet>
        <title> Hospital Registration | {currentDomain} | Hospital Management Software | Smart Solutions for Hospital Efficiency</title>
      </Helmet>
      <form onSubmit={handleSubmit}>
        {success && <div>Hospital registered</div>}
        {error && <div>Error in registering hospital. please try again</div>}
       
        <div class="fill header"><h3 class="float-left">Hospital Registration </h3> </div>
        <div className="hospital-new primary-info">
        <div className="row  ml-4">
          <div className="col-md-12 ">
            <div className="row">
              <div className="col-md-2">
                <label
                  htmlFor="hospitalname"
                  className="col-form-label leftone"
                >
                  Hospital Name
                </label>
              </div>
              <div className="col-md-4">
                <input
                  type="text"
                  autoComplete="off"
                  name="hospitalname"
                  id="hospitalname"
                  placeholder="Hospital Name"
                  value={values.hospitalname}
                  onChange={handleChange}
                  className="form-control"
                />
                {errors.hospitalname && touched.hospitalname ? (
                  <p className="form-erroremployee">{errors.hospitalname}</p>
                ) : null}
              </div>
              <div className="col-md-2">
                <label
                  htmlFor="registrationnumber "
                  className="col-form-label leftone"
                >
                  Registration Number
                </label>
              </div>
              <div className="col-md-4">
                <input
                  type="number"
                  autoComplete="off"
                  name="registrationnumber"
                  id="registrationnumber"
                  placeholder="Registration Number"
                  value={values.registrationnumber}
                  onChange={handleChange}
                  className="form-control"
                />

                {errors.registrationnumber && touched.registrationnumber ? (
                  <p className="form-erroremployee">{errors.registrationnumber}</p>
                ) : null}
              </div>
            </div>
          </div>

          <div className="col-md-12 mt-3">
            <div className="row">
              <div className="col-md-2">
                <label
                  htmlFor="phoneno"
                  className="col-form-label leftone"
                >
                  Phone no
                </label>
              </div>
              <div className="col-md-4">
                <input
                  type="number"
                  autoComplete="off"
                  name="phoneno"
                  id="phoneno"
                  placeholder="Phone no"
                  value={values.phoneno}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className="form-control"
                ></input>
                  {errors.phoneno && touched.phoneno ? (
                  <p className="form-erroremployee">{errors.phoneno}</p>
                ) : null}
              </div>
              <div className="col-md-2">
                <label
                  htmlFor="address"
                  className="col-form-label leftone"
                >
                  Address
                </label>
              </div>
              <div className="col-md-4">
                <input
                  type="text"
                  autoComplete="off"
                  name="address"
                  id="address"
                  placeholder="Address"
                  value={values.address}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className="form-control"
                ></input>
                {errors.address && touched.address ? (
                  <p className="form-erroremployee">{errors.address}</p>
                ) : null}
              </div>
            </div>
          </div>

          <div className="col-md-12 mt-3">
            <div className="row">
              <div className="col-md-2">
                <label
                  htmlFor="email"
                  className="col-form-label leftone"
                >
                  Email
                </label>
              </div>
              
              <div className="col-md-4">
                <input
                  type="email"
                  autoComplete="off"
                  name="email"
                  id="email"
                  placeholder="Email"
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className="form-control"
                ></input>
                 {errors.email && touched.email ? (
                  <p className="form-erroremployee">{errors.email}</p>
                ) : null}
              </div>
              <div className="col-md-2">
                <label
                  htmlFor="hospital"
                  className="col-form-label leftone"
                >
                  Hospital Short Name
                </label>
              </div>
              <div className="col-md-4">
                <input
                  type="text"
                  autoComplete="off"
                  name="hospital"
                  id="hospital"
                  placeholder="Hospital Short Name"
                  value={values.hospital}
                  onChange={handleChange}
                  className="form-control"
                />
                {errors.hospital && touched.hospital ? (
                  <p className="form-erroremployee">{errors.hospital}</p>
                ) : null}
              </div>
              {/* <div className="col-md-2">
                <label
                  htmlFor="currency"
                  className="col-form-label "
                >
                  Currency
                </label>
              </div> */}
              {/* <div className="col-md-4">
                <input
                  type="text"
                  autoComplete="off"
                  name="currency"
                  id="currency"
                  placeholder="Currency"
                  value={values.currency}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {errors.gaurdianname && touched.gaurdianname ? (
                  <p className="form-erroremployee">{errors.gaurdianname}</p>
                ) : null}
              </div> */}
            </div>
          </div>

          <div className="col-md-12 mt-3">
            <div className="row">
              <div className="col-md-2">
                <label
                  htmlFor="time"
                  className="col-form-label "
                >
                  Time Format
                </label>
              </div>
              <div className="col-md-4">
                <input
                  type="text"
                  autoComplete="off"
                  name="time"
                  id="time"
                  placeholder="Time Format"
                  value={values.time}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className="form-control"
                />

                {errors.bloodgroup && touched.bloodgroup ? (
                  <p className="form-erroremployee">{errors.bloodgroup}</p>
                ) : null}
              </div>

              <div className="col-md-2">
                <label
                  htmlFor="workhour"
                  className="col-form-label"
                >
                  Working Hour
                </label>
              </div>
              <div className="col-md-4">
                <input
                  type="text"
                  autoComplete="off"
                  name="workhour"
                  id="workhour"
                  placeholder="Work Hour"
                  value={values.workhour}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className="form-control"
                />
                 {errors.workhour && touched.workhour ? (
                  <p className="form-erroremployee">{errors.workhour}</p>
                ) : null}
              </div>
            </div>
          </div>

        

          <div className="col-md-12 mt-3">
            <div className="row">
              <div className="col-md-2">
                <label
                  htmlFor="birthcertificate"
                  className="col-form-label"
                >
                  Birth Certificate Header
                </label>
              </div>
              <div className="col-md-4">
                <input
                  type="file"
                  autoComplete="off"
                  name="birthcertificate"
                  id="birthcertificate"
                  placeholder="Birth Certificate"
                  onChange={handleFileChangeBirthCertificate}
                  onBlur={handleBlur}
                  className="form-control"
                ></input>
 {errors.birthcertificate && touched.birthcertificate ? (
                  <p className="form-erroremployee">{errors.birthcertificate}</p>
                ) : null}
              </div>

              <div className="col-md-2">
                <label
                  htmlFor="deathcertificate"
                  className="col-form-label "
                >
                  Death Certificate Header
                </label>
              </div>
              <div className="col-md-4">
                <input
                  type="file"
                  autoComplete="off"
                  name="deathcertificate"
                  id="deathcertificate"
                  placeholder=" Death Certificate"
                  onChange={handleFileChangeDeathCertificate}
                  onBlur={handleBlur}
                  className="form-control"
                />
                {errors.deathcertificate && touched.deathcertificate ? (
                  <p className="form-erroremployee">{errors.deathcertificate}</p>
                ) : null}
              </div>
            </div>
          </div>

          <div className="col-md-12 mt-3">
            <div className="row">
              <div className="col-md-2">
                <label
                  htmlFor="licenseduration"
                  className="col-form-label"
                >
                  License duration
                </label>
              </div>
              <div className="col-md-4">
                <input
                  type="text"
                  autoComplete="off"
                  name="licenseduration"
                  id="licenseduration"
                  placeholder="License duration"
                  value={values.licenseduration}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className="form-control"
                ></input>
                 {errors.licenseduration && touched.licenseduration? (
                  <p className="form-erroremployee">{errors.licenseduration}</p>
                ) : null}
              </div>

              <div className="col-md-2">
                <label
                  htmlFor="licenserate"
                  className="col-form-label"
                >
                  License Rate
                </label>
              </div>
              <div className="col-md-4">
                <input
                  type="number"
                  autoComplete="off"
                  name="licenserate"
                  id="licenserate"
                  placeholder=" License Rate"
                  value={values.licenserate}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className="form-control"
                ></input>
                {errors.licenserate && touched.licenserate? (
                  <p className="form-erroremployee">{errors.licenserate}</p>
                ) : null}
              </div>
            </div>
          </div>

          <div className="col-md-12 mt-3">
            <div className="row">
              <div className="col-md-2">
                <label
                  htmlFor="amountpaid"
                  className="col-form-label"
                >
                  Amount paid
                </label>
              </div>
              <div className="col-md-4">
                <input
                  type="number"
                  autoComplete="off"
                  name="amountpaid"
                  id="amountpaid"
                  placeholder=" "
                  value={values.amountpaid}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className="form-control"
                ></input>
                {errors.amountpaid && touched.amountpaid ? (
                  <p className="form-erroremployee">{errors.amountpaid}</p>
                ) : null}
              </div>

              <div className="col-md-2">
                <label
                  htmlFor="licenseexpirydate"
                  className="col-form-label"
                >
                  License expiry date
                </label>
              </div>
              <div className="col-md-4">
                <input
                  type="date"
                  autoComplete="off"
                  name="licenseexpirydate"
                  id="licenseexpirydate"
                  placeholder="License expiry date "
                  value={values.licenseexpirydate}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className="form-control"
                ></input>
              </div>
            </div>
          </div>

          <div className="col-md-12 mt-3">
            <div className="row">
              <div className="col-md-2">
                <label
                  htmlFor="numberofusers"
                  className="col-form-label"
                >
                  Number Of Users
                </label>
              </div>
              <div className="col-md-4">
                <input
                  type="number"
                  autoComplete="off"
                  name="numberofusers"
                  id="numberofusers"
                  placeholder=" "
                  value={values.numberofusers}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className="form-control"
                ></input>
                 {errors.numberofusers && touched.numberofusers ? (
                  <p className="form-erroremployee">{errors.numberofusers}</p>
                ) : null}
              </div>
              <div className="col-md-2">
                <label
                  htmlFor="remarks"
                  className="col-form-label"
                >
                  Remarks
                </label>
              </div>
              <div className="col-md-4">
                <textarea
                  rows={2}
                  column={2}
                  type="text"
                  autoComplete="off"
                  name="remarks"
                  id="remarks"
                  placeholder=" Remarks"
                  value={values.remarks}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  style={{ width: '90%', height: '32px', border: '1px solid #ced4da ', color: '#495057' }}
                ></textarea>
              </div>
             
            </div>
          </div>

          <div className="col-md-12 mt-3">
            <div className="row">
              <div className="col-md-2">
                <label
                  htmlFor="hospitallogo"
                  className="col-form-label leftone"
                >
                  Hospital logo
                </label>
              </div>
              <div className="col-md-4">
                <input
                  type="file"
                  accept="image/png, image/gif, image/jpeg"
                  autoComplete="off"
                  name="hospitallogo"
                  id="hospitallogo"
                  placeholder="Hospital Logo "
                  // value={values.hospitallogo}
                  onChange={handleFileChangeHospitallogo }
                  onBlur={handleBlur}
                  className="form-control"
                ></input>
                 {errors.hospitallogo && touched.hospitallogo ? (
                  <p className="form-erroremployee">{errors.hospitallogo}</p>
                ) : null}
              </div>

              {/* <div className="col-md-2">
                <label
                  htmlFor="hospitalfavicon"
                  className="col-form-label"
                >
                  Hospital Favicon
                </label>
              </div> */}
              {/* <div className="col-md-4">
                <input
                  type="file"
                  autoComplete="off"
                  accept="image/png, image/gif, image/jpeg"
                  name="hospitalfavicon"
                  id="hospitalfavicon"
                  placeholder="Hospital favicon"
                  // value={values.hospitalfavicon}
                  onChange={handleFileChangeHospitalfavicon}
                  onBlur={handleBlur}
                  className="form-control"
                ></input>
              </div> */}
                 <div className="col-md-2">
                <label
                  htmlFor="attachtwo"
                  className="col-form-label leftone"
                >
                  Billing Header 
                </label>
              </div>
              <div className="col-md-4">
                <input
                  type="file"
                  autoComplete="off"
                  accept="image/png, image/gif, image/jpeg"
                  name="attachtwo"
                  id="attachtwo"
                  placeholder=" "
                  //value={values.attachtwo}
                  onChange={handleFileChangeAttachtwo}
                  onBlur={handleBlur}
                  className="form-control"
                ></input>
                 {errors.attachtwo && touched.attachtwo ? (
                  <p className="form-erroremployee">{errors.attachtwo}</p>
                ) : null}
              </div>
            </div>
          </div>

          <div className="col-md-12 mt-3">
            <div className="row">
              <div className="col-md-2">
                <label
                  htmlFor="attachtone"
                  className="col-form-label leftone"
                >
                   Perscription header 
                </label>
              </div>
              <div className="col-md-4">
                <input
                  type="file"
                  autoComplete="off"
                  accept="image/png, image/gif, image/jpeg"
                  name="attachone"
                  id="attachone"
                  placeholder=" "
                  //value={values.attachone}
                  onChange={handleFileChangeAttachone}
                  onBlur={handleBlur}
                  className="form-control"
                ></input>
                   {errors.attachone && touched.attachone ? (
                  <p className="form-erroremployee">{errors.attachone}</p>
                ) : null}
              </div>
              {/* <div className="col-md-2">
                <label
                  htmlFor="attachtwo"
                  className="col-form-label"
                >
                  Billing Header 
                </label>
              </div>
              <div className="col-md-4">
                <input
                  type="file"
                  autoComplete="off"
                  accept="image/png, image/gif, image/jpeg"
                  name="attachtwo"
                  id="attachtwo"
                  placeholder=" "
                  //value={values.attachtwo}
                  onChange={handleFileChangeAttachtwo}
                  onBlur={handleBlur}
                  className="form-control"
                ></input>
                 {errors.attachtwo && touched.attachtwo ? (
                  <p className="form-erroremployee">{errors.attachtwo}</p>
                ) : null}
              </div> */}
            </div>
          </div>

          {/* <div className="col-md-12 mt-3">
            <div className="row">
              <div className="col-md-2">
                <label
                  htmlFor="attachthree"
                  className="col-form-label"
                >
                  Attachment three
                </label>
              </div>
              <div className="col-md-4">
                <input
                  type="file"
                  autoComplete="off"
                  accept="image/png, image/gif, image/jpeg"
                  name="attachthree"
                  id="attachthree"
                  placeholder=" "
                 // value={values.attachthree}
                  onChange={handleFileChangeAttachthree}
                  onBlur={handleBlur}
                  className="form-control"
                ></input>
              </div>
            </div>
          </div> */}
        </div>
        </div>
        <div className="col-md-6  mt-3">
          <Button
            type="submit"
            variant="success"
            className="mx-3"
          >
            Save
          </Button>
          <Button
            variant="primary"
            className="mx-3"
            onClick={handleReset}
          >
            Reset
          </Button>
        </div>
      </form>
    </div>
  );
};