import React from "react";
import { useFormik } from "formik";
import { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useHistory, useParams } from "react-router-dom";
import axios from "../../../helper/axios";
import { Table, Button, Modal, Form } from "react-bootstrap";
import { patientregistration } from "../../../helper/schemas";
import { ToastContainer, toast } from 'react-toastify';
import * as Yup from "yup";  // Import Yup for validation
import { useUser } from "../../../Provider";
import { TextField, Autocomplete, InputAdornment, IconButton } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
// Define validation schema using Yup
const ValidationSchema = Yup.object().shape({
  bedname: Yup.string().required('Bed name is required'),
  hospitalcode: Yup.string().required("Please select the hospitalcode"),
  bedgroupid: Yup.string().required("Please select the bed group name"),
  bedtypeid: Yup.string().required("Please select the bed type"),

});

// Define an alternative validation schema if userHospitalcode is not 1

const ValidationSchema1 = Yup.object().shape({
  bedname: Yup.string().required('Bed name is required'),
  // hospitalcode: Yup.string().required("Please select the hospitalcode"),
  bedgroupid: Yup.string().required("Please select the bed group name"),
  bedtypeid: Yup.string().required("Please select the bed type"),
});
const initialValues = {
  bedname: "",
  bedtypeid: "",
  bedgroupid: "",
  hospitalcode: "",
};
const EditBed = () => {
  const { user, userHospitalcode, userrole1, isAuthRole, isAuthorized, gettokenId, token } = useUser();
  const history = useHistory();
  const [message, setMessage] = useState("");
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [openAutocomplete, setOpenAutocomplete] = useState(false);
  const [openAutocompleted, setOpenAutocompleted] = useState(false);
  // initialised isAuthorized value as false
  const { bedid } = useParams();
  const handleExpandClick = () => {
    setOpenAutocomplete(!openAutocomplete);
};
const handleExpandClicked = () => {
  setOpenAutocompleted(!openAutocompleted);
};
  const getValidationSchema = () => {
    if (userHospitalcode === 1) {
      return ValidationSchema;

    } else {
      return ValidationSchema1;
    }
  }
  const {
    values,
    errors,
    touched,
    setValues,
    handleChange,
    handleBlur,
    resetForm,
    handleSubmit,
  } = useFormik({
    initialValues,
    // validationSchema,
    validationSchema: getValidationSchema, // Apply validation schema
    onSubmit: async (values, { resetForm }) => {

      try {
        if (userHospitalcode !== undefined) {
          const data = new FormData();

          // Append all form fields to the FormData object
          data.append('document', JSON.stringify(values));
          // ... append other fields as needed
          data.append('bedid', bedid); // Assuming bedtypeid is now a number
          data.append('userHospitalcode', JSON.stringify(userHospitalcode));
          data.append('currentDateTime', JSON.stringify(getCurrentDateTime()));
          data.append('gettokenId', JSON.stringify(gettokenId));
          data.append('currentDate', JSON.stringify(currentDate));

          console.log(JSON.stringify(values));

          const response = await axios.post(`/BedUpdate/'${bedid}'`, data, {
            headers: {
              Authorization: `Beaxrer ${token}`,
              "Content-Type": "multipart/form-data",
            },
            params: {
              hospitalcode: userHospitalcode, // Include the hospitalcode in the query parameters
              userrole: userrole1, // Include the userrole in the query parameters
            },
          },);

          if (response.status === 200) {
            return history.push("/listviewbed");
          } else {
            console.error('Error in admitting patient');
            setSuccess(false);
            setError(true);
          }
        }
      } catch (error) {
        console.error('Error:', error.message);
        setSuccess(false);
        setError(true);
        toast.error('Something Went Wrong');
      }
    }
  });
  const getCurrentDateTime = () => {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0');
    const day = String(now.getDate()).padStart(2, '0');
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    const currentDateTimeString = `${year}-${month}-${day}T${hours}:${minutes}`;
    return currentDateTimeString;
  };
  let Showdate = new Date();
  let ShowTodaydate =
    Showdate.getFullYear() +
    "-" +
    (Showdate.getMonth() + 1) +
    "-" +
    Showdate.getDate();
  const [currentDate, setCurrentDate] = useState(ShowTodaydate);
  const handleSearchChange = async (searchTerm) => {
    // setSearchValue(searchTerm);
    const selectedHospitalCode = searchTerm.hospitalcode || userHospitalcode;
    await fetchBedGroups(selectedHospitalCode); // Fetch floor names based on the selected hospital code
    await fetchBedTypes(selectedHospitalCode); // Fetch floor names based on the selected hospital code
  };
  const [data, setData] = useState([]);
  const [userRole, setRole] = useState();
  // const [gettokenId, setTokenId] = useState();
  // const [userHospitalcode, setUserHospitalcode] = useState();
  // const [userrole1, setUserrole1] = useState();
  const [bedGroups, setBedGroups] = useState([]);
  const [bedTypes, setBedTypes] = useState([]);
  // Function to get login user's details

  // Load bed details when the page loads or refreshes
  useEffect(() => {
    const loadData = async () => {
      // await loadAllDetails();
      // loadHospitalcode(); will wait for loadAllDetails(); to got execute
      await loadHospitalcode();

      const selectedHospitalCode = values.hospitalcode || userHospitalcode;
      fetchBedTypes(selectedHospitalCode);
      fetchBedGroups(selectedHospitalCode);
    };
    loadData();
  }, [userHospitalcode, userrole1, isAuthorized, values.hospitalcode]);


  //  get all hospital 
  const loadHospitalcode = async () => {
    //when userHospitalcode and userrole1 is not undefined then execute /gethospitalcode
    if (userHospitalcode !== undefined && userrole1 !== undefined) {
      const res = await axios.get("/gethospitalcode", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          hospitalcode: userHospitalcode, // Include the hospitalcode in the query parameters
          userrole: userrole1, // Include the userrole in the query parameters
        },
      });
      setData(res.data);
      console.log(res.data);
    };
  }

  // Fetch bed types based on selected hospital code
  const fetchBedTypes = async (selectedHospitalCode) => {
    try {
      const response = await axios.get("/getbedtype", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          hospitalcode: selectedHospitalCode, // Use the selected hospital code
        },
      });

      if (response.status === 200) {
        setBedTypes(response.data);
      } else {
        console.error(`Failed to fetch available floor numbers with status: ${response.status}`);
      }
    } catch (error) {
      console.error('Error fetching available floor numbers:', error.message);
    }
  };
  // Fetch bed groups based on selected hospital code
  const fetchBedGroups = async (selectedHospitalCode) => {
    try {
      const response = await axios.get("/getbedgroup", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          hospitalcode: selectedHospitalCode, // Use the selected hospital code
        },
      });
      if (response.status === 200) {
        setBedGroups(response.data);
      } else {
        console.error(`Failed to fetch available floor numbers with status: ${response.status}`);
      }
    } catch (error) {
      console.error('Error fetching available floor numbers:', error.message);
    }
  };
  const loadBed = async () => {
    try {
      if (userHospitalcode !== undefined && userrole1 !== undefined) {
        const response = await axios.get(`/getbedbybedid/${bedid}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: {
            hospitalcode: userHospitalcode,
            userrole: userrole1,
          },
        });

        const beddata = response.data;
        console.log(" sndbsjkdnksd", beddata)
        setValues(beddata);


        // const ipddata = response.data;
        // console.log('Before setValues:', values);
        // setValues((prevValues) => ({
        //     ...prevValues,
        //     ...ipddata,
        // }));
        // console.log('After setValues:', values);
      }
    } catch (error) {
      console.error('Error loading bed type details:', error.message);
      alert('Something Went Wrong');
    }
  };

  //when page load or refresh happen this function will got called
  useEffect(() => {
    const loadData = async () => {
      await loadBed();
    };
    loadData();
  }, [userHospitalcode, userrole1, isAuthorized]);

  const currentDomain = window.location.hostname;
  return (
    <div className="p-3 pt-0 appointment">
      <Helmet>

        <title>Update Bed Details | {currentDomain} | Hospital Management Software | Smart Solutions for Hospital Efficiency</title>
      </Helmet>
      <form onSubmit={handleSubmit}>
        <div class="fill header"><h3 class="float-left"> Bed Edit</h3> </div>
        <div className="patient-new primary-info">
          <div className="row">
            <div className="col-md-12">
              <div className="row">
                <div className="col-md-6">


                  <div className="col-md-12 mt-3">
                    <div className="row">

                      <div className="col-md-4">
                        <label
                          htmlFor="bedname"
                          className="col-form-label leftone"
                        >
                          Bed Name
                        </label>
                      </div>
                      <div className="col-md-8">
                        <input
                          type="text"
                          autoComplete="off"
                          name="bedname"
                          id="bedname"
                          placeholder="Bed Name"
                          value={values.bedname}
                          onChange={handleChange}
                          //   onChange={handleChange}
                          //   onBlur={handleBlur}
                          className="form-control ember-text-field text-left ember-view"
                        />
                        {errors.bedname && touched.bedname ? (
                          <p className="form-erroremployee">{errors.bedname}</p>
                        ) : null}
                      </div></div></div>

                  <div className="col-md-12 mt-3">
                    <div className="row">

                      <div className="col-md-4">
                        <label
                          htmlFor="groupname"
                          className="col-form-label leftone"
                        >
                          Bed Group Name
                        </label>
                      </div>
                      <div className="col-md-8">
                        <Autocomplete
                          freeSolo
                          id="free-solo"
                          options={bedGroups}
                          getOptionLabel={(bedGroup) => bedGroup.groupname}
                          value={bedGroups.find((bedGroup) => bedGroup.bedgroupid === values.bedgroupid) || null}
                          open={openAutocomplete}
                          onOpen={() => setOpenAutocomplete(true)}
                          onClose={() => setOpenAutocomplete(false)}
                          onChange={(event, newValue) => {
                            setValues({
                              ...values,
                              bedgroupid: newValue ? newValue.bedgroupid : ""
                            });
                          }}
                          onBlur={handleBlur}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              placeholder="Select BedGroup"
                              InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <IconButton onClick={handleExpandClick}>
                                      <ExpandMoreIcon />
                                    </IconButton>
                                  </InputAdornment>
                                ),
                                //style: { width: '74%', padding: '0px ', border: "none" } // Adjust width here
                              }}
                            />
                          )}
                          renderOption={(props, option) => (
                            <li {...props}>
                              {option.groupname}-{option.floorname}
                            </li>
                          )}
                        />

                        {errors.bedgroupid && touched.bedgroupid ? (
                          <p className="form-erroremployee">{errors.bedgroupid}</p>
                        ) : null}
                      </div></div></div>
                </div>
                <div className="col-md-6">
                  {isAuthorized ? (<div className="col-md-12 mt-3">
                    <div className="row">

                      <div className="col-md-4">
                        <label
                          htmlFor="hospitalcode"
                          className="col-form-label leftone"
                        >
                          Hospital Code
                        </label>
                      </div>
                      <div className="col-md-8">
                        {/* Conditionally render the dropdown and input field */}
                        {isAuthorized ? (
                          <input
                            type="text"
                            autoComplete="off"
                            name="hospitalcode"
                            id="hospitalcode"
                            placeholder="Hospital Code"
                            value={values.hospitalcode}
                            // onChange={handleChange}
                            // onBlur={handleBlur}
                            disabled={true} // Set the input as disabled
                            // style={{ width: '73%', height: '32px', border: '1px solid #ced4da', color: '#495057' }}
                            className="form-control "
                          >
                          </input>
                        ) : (
                          <input
                            type="text"
                            name="hospitalcode"
                            id="hospitalcode"
                            value={userHospitalcode}
                            disabled={true} // Set the input as disabled
                            className="form-control "
                          />
                        )}
                        {isAuthorized && errors.hospitalcode && touched.hospitalcode ? (
                          <p className="form-erroremployee">{errors.hospitalcode}</p>
                        ) : null}
                      </div></div></div>) : null}
                  <div className="col-md-12 mt-3">
                    <div className="row">
                      <div className="col-md-4">
                        <label
                          htmlFor="bedtype"
                          className="col-form-label leftone"
                        >
                          Bed Type
                        </label>
                      </div>
                      <div className="col-md-8">
                        <Autocomplete
                          freeSolo
                          id="free-solo"
                          options={bedTypes}
                          getOptionLabel={(bedType) => bedType.bedtype}
                          value={bedTypes.find((bedType) => bedType.bedtypeid === values.bedtypeid) || null}
                          open={openAutocompleted}
                          onOpen={() => setOpenAutocompleted(true)}
                          onClose={() => setOpenAutocompleted(false)}
                          onChange={(event, newValue) => {
                            setValues({
                              ...values,
                              bedtypeid: newValue ? newValue.bedtypeid : ""
                            });
                          }}
                          onBlur={handleBlur}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              placeholder="Select BedType"
                              InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <IconButton onClick={handleExpandClicked}>
                                      <ExpandMoreIcon />
                                    </IconButton>
                                  </InputAdornment>
                                ),
                                //style: { width: '74%', padding: '0px ', border: "none" } // Adjust width here
                              }}
                            />
                          )}
                        />

                        {errors.bedtypeid && touched.bedtypeid ? (
                          <p className="form-erroremployee">{errors.bedtypeid}</p>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="modal-buttons">
          <Button type="submit" variant="success" className="mx-3">
            Update
          </Button>
        </div>
      </form>
    </div>
  );
};
export default EditBed;

