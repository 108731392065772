
import React, { useState, useEffect } from 'react';
import axios from "../../../helper/axios";
import { Card, CardContent, Typography,  } from '@mui/material';
import { useUser } from "../../../Provider";
import { useParams } from 'react-router-dom/cjs/react-router-dom';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import CommentIcon from '@mui/icons-material/Comment';
import PopupNurseNote from './PopupNurseNote'; // Import your pop-up/modal component
import PopupNurseComment from './PopupNurseComment';
import NurseNoteForm from './NurseNoteForm';
const NurseNote = ({hideForm}) => {
  const { admitnumber } = useParams();
  const { userHospitalcode, userrole1 } = useUser();
  const [nursenote, setNursenote] = useState([]);
  const [editNote, setEditNote] = useState(null); // Track the note being edited
  const [addcomment, setAddcomment] = useState(null); 
  const [isPopupOpen, setPopupOpen] = useState(false);
  const [isPopupOpens, setPopupOpens] = useState(false);
  const token = localStorage.getItem("jwtToken");

  const getNursenote = async () => {
    try {
      if (userHospitalcode !== undefined && userrole1 !== undefined) {
        const res = await axios.get(`/getnursenote/${admitnumber}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: {
            userHospitalcode,
            userrole: userrole1,
          },
        });
  
        if (res.status === 200) {
          setNursenote(res.data);
        } else {
          alert(`Request failed with status: ${res.status}`);
        }
        
        // Fetch comments for each note
        const commentPromises = res.data.map(async (note) => {
          const noteId = note.noteid
          const commentRes = await axios.get(`/getnursenotecomment/${noteId}`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          
          if (commentRes.status === 200) {
            note.comments = commentRes.data;
          } else {
            alert(`Request failed with status: ${commentRes.status}`);
          }
          
          return note;
        });
  
        // Wait for all comments to be fetched before updating state
        const notesWithComments = await Promise.all(commentPromises);
        setNursenote(notesWithComments);
      }
    } catch (error) {
      console.log(`An error occurred: ${error.message}`);
    }
  };
  
 
  const groupNurseNotesByTime = () => {
    const groupedNotes = {};
  
    nursenote.forEach((note) => {
      const rawDate = new Date(note.date);
  
      // Check if the date is valid before proceeding
      if (!isNaN(rawDate.getTime())) {
        const time = rawDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
  
        if (!groupedNotes[time]) {
          groupedNotes[time] = [];
        }
  
        groupedNotes[time].push(note);
      }
    });
  
    // Convert the object values to an array for mapping
    return Object.values(groupedNotes);
  };

  useEffect(() => {
    getNursenote();
  
  }, [userHospitalcode, userrole1])
  const handleNoteAdded = () => {
    // Function to fetch the updated NurseNote data
    getNursenote();
  };
  const handleEdit = (note) => {
    setEditNote(note);
    setPopupOpen(note.noteid); // Pass the noteid to setPopupOpen
  };
  const handleaddcomment = (note) => {
    setEditNote(note);
    setAddcomment(note)
    setPopupOpens(note.noteid); // Pass the noteid to setPopupOpen
  };

  const handleSubmit = async (values) => {
    try {
      // Close the pop-up after submission
      setPopupOpen(false);
    } catch (error) {
      console.log(`An error occurred: ${error.message}`);
    }
  };
 
  const handleSubmitComment = async (values) => {
    try {
      // Close the pop-up after submission
      setPopupOpen(false);
    } catch (error) {
      console.log(`An error occurred: ${error.message}`);
    }
  };
  const formatOperationTime = (inputDateString) => {
    const inputDate = new Date(inputDateString);
  
    // Format the date as MM/DD/YYYY hh:mm am/pm in the user's local time zone
    const formattedDate = inputDate.toLocaleString('en-US', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      hour12: true,
      timeZone: 'UTC', // Change this to the server's time zone
    });
  
    return formattedDate;
  };
    return (
        <div>
         {!hideForm && <NurseNoteForm onNoteAdded={handleNoteAdded} /> }{/* Pass handleNoteAdded as a prop */}
         
            {groupNurseNotesByTime().map((timeGroup, index) => (
                <div key={index}>
                    {timeGroup.map((note) => (
                        <Card key={note.noteid} style={{ marginBottom: '10px' }}>
                            <Typography variant="h8" style={{ padding: '10px', color: 'blue' }}>
                             {formatOperationTime(note.date)}
                        </Typography>
                        <IconButton color="primary" onClick={() => handleEdit(note)}>
                          <EditIcon />
                        </IconButton>
                        <IconButton color="primary" onClick={() => handleaddcomment(note)}>
                          <CommentIcon />
                        </IconButton>
                        <CardContent>
                          <Typography
                                    variant="body1"
                                    style={{ color: 'blue' }}
                                >
                                    {note.fname} {note.lname}
                                </Typography>
                                <Typography
                                    variant="body1"
                                >
                                    Note:
                                </Typography>
                                <Typography
                                    variant="body1"
                                    style={{ padding: '10px', border: '1px solid #D0D0D0', backgroundColor: '#F5F5F5' }}
                                >
                                    {note.note}
                                </Typography>
                                <Typography variant="body1">
      Comment:
    </Typography>
    {note.comments && note.comments.map((comment) => (
      <Typography
        key={comment.commentid}
        variant="body1"
        style={{
          padding: '10px',
          border: '1px solid #D0D0D0',
          backgroundColor: '#F5F5F5',
        }}
      >
        {comment.comment}
      </Typography>
    ))}
                
                            </CardContent>
                        </Card>
                        
                    ))}
                      {/* Pop-up/modal for editing */}
      {isPopupOpen && (
        <PopupNurseNote
          initialValues={editNote} // Pass the note data to populate the form
          noteId={editNote.noteid} // Pass the noteid as a prop
          onSubmit={handleSubmit}
          onClose={() => setPopupOpen(false)}
        />
      )}
       {isPopupOpens && (
        <PopupNurseComment
          initialValues={addcomment} // Pass the note data to populate the form
          noteId={addcomment.noteid} // Pass the noteid as a prop
          onSubmit={handleSubmitComment}
          onClose={() => setPopupOpens(false)}
        />
      )}
                </div>
            ))}
        </div>
    );


};

export default NurseNote;
