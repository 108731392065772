import React from "react";
import Header from "../../Component/Header/Header";
import Sidebar from "../../Component/Sidebar";
import { UserProvider } from "../../Provider";
import AddPathologyCategory from "./PathologyPages/AddPathologyCategory";
import ListViewPathologyCategory from "./PathologyPages/ListViewPathologyCategory";
import AddUnit from "./PathologyPages/AddUnit";
import ListViewUnit from "./PathologyPages/ListViewUnit";
import ListViewPathology from "./PathologyPages/ListViewPathology";
import AddPathology from "./PathologyPages/AddPathology";
import ListOfPathologyReport from "./PathologyPages/ListOfPathologyReport";
import AddPathologyReportForm from "./PathologyPages/AddPathologyReportForm";
import EditPathology from "./PathologyPages/EditPathology";
import UpdatePathologyCategory from "./PathologyPages/UpdatePathologyCategory";
import UpdatePathologyUnit from "./PathologyPages/UpdatePathologyUnit";
import UpdatePathologyParameter from "./PathologyPages/UpdatePathologyParameter";


export const AddPathologyCategoryComponent = () => {
  return (

    <UserProvider>  <div className=" ">
    <Header />
  <div className="row" style={{ marginTop: '50px' }}>
    {/* Sidebar */}
    <div className="col-md-2">
      <Sidebar />
    </div>
    
    {/* Main Content */}
    <div className="col-md-10">
    <AddPathologyCategory />
    </div>
  </div>
</div></UserProvider>
          
  );
};

export const ListViewPathologyCategoryComponent = () => {
  return (
    <UserProvider> <div className=" ">
      <Header />
      <div
        className="row"
        style={{ marginTop: "50px" }}
      >
        {/* Sidebar */}
        <div className="col-md-2">
          <Sidebar />
        </div>

        {/* Main Content */}
        <div className="col-md-10">
       
        <ListViewPathologyCategory/>
        
        </div>
      </div>
    </div></UserProvider>
  );
};


export const AddUnitComponent = () => {
  return (

    <UserProvider>  <div className=" ">
    <Header />
  <div className="row" style={{ marginTop: '50px' }}>
    {/* Sidebar */}
    <div className="col-md-2">
      <Sidebar />
    </div>
    
    {/* Main Content */}
    <div className="col-md-10">
    <AddUnit />
    </div>
  </div>
</div></UserProvider>
          
  );
};



export const ListViewUnitComponent = () => {
  return (
    <UserProvider> <div className=" ">
      <Header />
      <div
        className="row"
        style={{ marginTop: "50px" }}
      >
        {/* Sidebar */}
        <div className="col-md-2">
          <Sidebar />
        </div>

        {/* Main Content */}
        <div className="col-md-10">
       
        <ListViewUnit/>
        
        </div>
      </div>
    </div></UserProvider>
  );
};


export const AddPathologyComponent = () => {
  return (

    <UserProvider>  <div className=" ">
    <Header />
  <div className="row" style={{ marginTop: '50px' }}>
    {/* Sidebar */}
    <div className="col-md-2">
      <Sidebar />
    </div>
    
    {/* Main Content */}
    <div className="col-md-10">
    <AddPathology />
    </div>
  </div>
</div></UserProvider>
          
  );
};

export const ListViewPathologyComponent = () => {
  return (
    <UserProvider> <div className=" ">
      <Header />
      <div
        className="row"
        style={{ marginTop: "50px" }}
      >
        {/* Sidebar */}
        <div className="col-md-2">
          <Sidebar />
        </div>

        {/* Main Content */}
        <div className="col-md-10">
       
        <ListViewPathology/>
        
        </div>
      </div>
    </div></UserProvider>
  );
};
export const PathologyReportComponent = () => {
  return (
    <UserProvider> <div className=" ">
      <Header />
      <div
        className="row"
        style={{ marginTop: "50px" }}
      >
        {/* Sidebar */}
        <div className="col-md-2">
          <Sidebar />
        </div>

        {/* Main Content */}
        <div className="col-md-10">
        <ListOfPathologyReport/>
        </div>
      </div>
    </div></UserProvider>
  );
};
export const PathologyReportForm = () => {
  return (
    <UserProvider> <div className=" ">
      <Header />
      <div
        className="row"
        style={{ marginTop: "50px" }}
      >
        {/* Sidebar */}
        <div className="col-md-2">
          <Sidebar />
        </div>

        {/* Main Content */}
        <div className="col-md-10">
        <AddPathologyReportForm/>
        </div>
      </div>
    </div></UserProvider>
  );
};


export const EditPathologyReportComponent = () => {
  return (
    <UserProvider> <div className=" ">
      <Header />
      <div
        className="row"
        style={{ marginTop: "50px" }}
      >
        {/* Sidebar */}
        <div className="col-md-2">
          <Sidebar />
        </div>

        {/* Main Content */}
        <div className="col-md-10">
        <EditPathology/>
        </div>
      </div>
    </div></UserProvider>
  );
};


export const EditPathologyCategory = () => {
  return (
    <UserProvider> <div className=" ">
      <Header />
      <div
        className="row"
        style={{ marginTop: "50px" }}
      >
        {/* Sidebar */}
        <div className="col-md-2">
          <Sidebar />
        </div>

        {/* Main Content */}
        <div className="col-md-10">
       <UpdatePathologyCategory/>
        </div>
      </div>
    </div></UserProvider>
  );
};

export const EditPathologyUnit = () => {
  return (
    <UserProvider> <div className=" ">
      <Header />
      <div
        className="row"
        style={{ marginTop: "50px" }}
      >
        {/* Sidebar */}
        <div className="col-md-2">
          <Sidebar />
        </div>

        {/* Main Content */}
        <div className="col-md-10">
       <UpdatePathologyUnit/>
        </div>
      </div>
    </div></UserProvider>
  );
};

export const EditPathologyParameter = () => {
  return (
    <UserProvider> <div className=" ">
      <Header />
      <div
        className="row"
        style={{ marginTop: "50px" }}
      >
        {/* Sidebar */}
        <div className="col-md-2">
          <Sidebar />
        </div>

        {/* Main Content */}
        <div className="col-md-10">
       <UpdatePathologyParameter/>
        </div>
      </div>
    </div></UserProvider>
  );
};