import React, { useState, useEffect } from 'react';
import { Typography, Button, TextField, Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import axios from "../../../helper/axios";
import { useFormik } from "formik";
import {paymentvalidation } from "../../../helper/schemas";
import { useUser } from "../../../Provider";
import { useParams } from 'react-router-dom/cjs/react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const initialValues = {
      date:"",
      paymentmode:"",
      note:"",
      amount:""  
};

const PaymentForm = ({ onPaymentAdded }) => {
    const handlePayment = () => {
        setFormVisibility(!isFormVisible); // Toggle form visibility
    };
    const { admitnumber } = useParams();
    //console.log({ admitnumber })
    const [currentDateTime, setCurrentDateTime] = useState('');
    const { user, userHospitalcode, userrole1, isAuthRole, isAuthorized, gettokenId,token } = useUser();
   // console.log(user, userHospitalcode, userrole1, isAuthRole, isAuthorized, gettokenId)
   useEffect(() => {
    // Function to get the current date and time
    const getCurrentDateTime = () => {
      const now = new Date();
      const year = now.getFullYear();
      const month = String(now.getMonth() + 1).padStart(2, '0');
      const day = String(now.getDate()).padStart(2, '0');
      const hours = String(now.getHours()).padStart(2, '0');
      const minutes = String(now.getMinutes()).padStart(2, '0');
      const currentDateTimeString = `${year}-${month}-${day}T${hours}:${minutes}`;
      return currentDateTimeString;
    };

    // Set the current date and time when the component mounts
    setCurrentDateTime(getCurrentDateTime());
  }, []);
    const [isFormVisible, setFormVisibility] = useState(false);
    //const token = sessionStorage.getItem("jwtToken");
    const {
        values,
        errors,
        touched,
        handleBlur,
        handleChange,
        handleReset,
        handleSubmit,
        resetForm,
        setValues,
      } = useFormik({
        initialValues,
       validationSchema:paymentvalidation ,
        onSubmit: async (values, action) => {
            try {
      
            //  if (userHospitalcode !== undefined) {
                const data = new FormData();
                data.append("document", JSON.stringify(values));
               console.log(JSON.stringify(values))
               data.append("currentDateTime", JSON.stringify(currentDateTime));
                const response = await axios.post(`/payment/${admitnumber}`, data, {
                  headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "multipart/form-data",
                  },
                });
                if (response.status === 200) {
                  onPaymentAdded();
                 toast.success("Data Saved")
                 
                }
              //}
            } catch (err) {
              
            }
            action.resetForm();
          },
      
        });
        
    return (
        <div>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography variant="h6" style={{ padding: '20px' }}>
                Add Payment
                </Typography>
                <Button variant="contained" color="primary" onClick={handlePayment}>
                    {isFormVisible ? 'Close Form' : 'Add Payment'}
                </Button>
            </div>
            {isFormVisible && (
                <form onSubmit={handleSubmit} style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                    <div className="patient-new primary-info">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="col-md-12 mt-3">
                                            <div className="row">
                                                <div className="col-md-4">
                                                    <label
                                                        htmlFor="date"
                                                        className="col-form-label leftone"
                                                    >
                                                        Date
                                                    </label>
                                                </div>
                                                <div className="col-md-8">
                                                    <input
                                                        type="datetime-local"
                                                        autoComplete="off"
                                                        name="date"
                                                        id="date"
                                                        placeholder="Date"
                                                        value={currentDateTime}
                                                        onChange={(e) => setCurrentDateTime(e.target.value)}

                                                        className="form-control"
                                                    />
                                                      {/* {errors.date && touched.date ? (
                    <p className="form-erroremployee">{errors.date}</p>
                  ) : null} */}
                                                </div>
                                            </div>
                                        </div>

                                        
                 <div className="col-md-12 mt-3">
                  <div className="row">
                    <div className="col-md-4">
                      <label
                        htmlFor="paymentmode"
                        className="col-form-label"
                      >
                        Payment Mode
                      </label>
                    </div>
                    <div className="col-md-8">
                      <select
                        type="text"
                        autoComplete="off"
                        name="paymentmode"
                        id="paymentmode"
                        placeholder="Payment Mode"
                        value={values.paymentmode}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        style={{ width: '73%', height: '32px', border: '1px solid #ced4da ', color: '#495057' }}
                      >
                        <option>Select</option>
                        <option>Cash</option>
                        <option>Cheque</option>
                        <option>Transfer to Bank Account</option>
                        <option>UPI</option>
                        <option>Online</option>
                        <option>Other</option>
                      </select>
                      
                    </div>
                  </div>
                </div>
          </div>
                                    <div className="col-md-6">

                                    <div className="col-md-12 mt-3">
                                            <div className="row">
                                                <div className="col-md-4">
                                                    <label
                                                        htmlFor="amount"
                                                        className="col-form-label leftone"
                                                    >
                                                       Amount($)
                                                    </label>
                                                </div>
                                                <div className="col-md-8">
                                                    <input
                                                        type="number"
                                                        autoComplete="off"
                                                        name="amount"
                                                        id="amount"
                                                        placeholder="Amount"
                                                        value={values.amount}
                                                          onChange={handleChange}
                                                          onBlur={handleBlur}

                                                        className="form-control"
                                                    />
                                                    {errors.amount && touched.amount ? (
                    <p className="form-erroremployee">{errors.amount}</p>
                  ) : null}
                                                </div>
                                            </div>
                                        </div>         
                                        <div className="col-md-12 mt-3">
                                            <div className="row">
                                                <div className="col-md-4">
                                                    <label
                                                        htmlFor="note"
                                                        className="col-form-label "
                                                    >
                                                       Note
                                                    </label>
                                                </div>
                                                <div className="col-md-8">
                                                    <input
                                                        type="text"
                                                        autoComplete="off"
                                                        name="note"
                                                        id="note"
                                                        placeholder="Note"
                                                        value={values.note}
                                                          onChange={handleChange}
                                                          onBlur={handleBlur}

                                                        className="form-control"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-11 mt-6 text-center">
                    <Button type="submit" variant="contained" color="primary" size="small" style={{ width: 'auto' }}>
            Submit
          </Button>
          </div>
          <ToastContainer/>
                </form>
            )}
        </div>
    );
};

export default PaymentForm;



