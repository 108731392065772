import { useState, useEffect } from "react";
import axios from '../helper/axios';
import Autocomplete from "@mui/material/Autocomplete";
import {
  TextField,
  InputAdornment,
  IconButton,
} from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import {  toast } from 'react-toastify';
import {  useHistory } from 'react-router-dom';
import { useUser } from "../Provider";
const Searchbar = ({ onSearchChange }) => {
  const {user, userHospitalcode, userrole1, isAuthRole,isAuthorized,gettokenId,token  } = useUser();
  const [value, setValue] = useState("");
  const [data, setData] = useState([]);
  const [open, setOpen] = useState(false); // State to control dropdown visibility
  const [selectedValue, setSelectedValue] = useState(null); // State to store selected value
//when page load or refresh happen this function will got called
useEffect(() => {
  const loadData = async () => {
   // await loadAllDetails();
    //loadAllAppointment(); will wait for loadAllDetails(); to got execute
    await loadAllPatients();
  };

  loadData();
}, [userrole1,userHospitalcode]);
  const loadAllPatients = async () => {
    try {
    
      if (userHospitalcode !== undefined && userrole1 !== undefined) {
      const res = await axios.get("/GetPatients", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          hospitalcode: userHospitalcode, // Include the hospitalcode in the query parameters
          userrole: userrole1, // Include the userrole in the query parameters
        },
      });
      
      setData(res.data);
      console.log(res.data);
    } }catch (error) {
      // Handle client-side errors here
      if (error.request) {
        // The request was made, but no response was received
        toast.error("No response received from the server");
      } else {
        // Something else went wrong
        toast.error("Error:", error.message);
      }
    }
  };


  const onSearch = (searchTerm, item) => {
    console.log(item +"------------")
    console.log(value)
    // setValue("")
    onSearchChange(item);
    setSelectedValue(item);
    };

    const handleExpandClick = () => {
        setOpen(!open);
    };
  return (
    <>
      <div className="searchbar" >
   
          <Autocomplete
             freeSolo
             id="free-solo"
            options={data}
            getOptionLabel={(option) => `${option.patientname}`}
            open={open}
            onOpen={() => setOpen(true)}
            onClose={() => setOpen(false)}
            value={selectedValue}
            onChange={(event, newValue) => {
                onSearch(null, newValue);
                setSelectedValue(newValue);
            }}
            renderInput={(params) => 
            <TextField
             {...params} 
             placeholder="Search Patient"
             InputProps={{
              ...params.InputProps,
              endAdornment: (
                  <InputAdornment position="end">
                      <IconButton onClick={handleExpandClick}>
                       <SearchIcon />
                      </IconButton>
                  </InputAdornment>
              ),
          }}
             
           //  onChange={(event) => setValue(event.target.value)} 
              />
            }
            renderOption={(props, option) => (
              <li {...props}>
                {option.patientname}-{option.phoneno}
              </li>
            )}
          />
        </div>
 
    </>
  );
}
export default Searchbar;