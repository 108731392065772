// import React, { useState, useEffect } from "react";
// import axios from '../helper/axios';
// import Autocomplete from "@mui/material/Autocomplete";
// import {
//     TextField,
//     InputAdornment,
//     IconButton,
// } from "@mui/material";
// import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
// import { toast } from 'react-toastify';
// import { useHistory } from 'react-router-dom';
// import { useUser } from "../Provider";

// const AvailableBedSearch = ({ onSearchChangeBed, hospitalvalue }) => {
//     const history = useHistory();
//     const { userrole1, token } = useUser();
//     const [data, setDoctor] = useState([]);
//     const [value, setValue] = useState(""); // State for the selected value

//     useEffect(() => {
//         // Reset the value state when hospitalvalue changes
//         setValue("");
//         if (hospitalvalue && userrole1) {
//             getAvailableBedNumbers();
//         }
//     }, [hospitalvalue, userrole1]);

//     const getAvailableBedNumbers = async () => {
//         try {
//             const res = await axios.get(`/getAvailableBedNumbers`, {
//                 headers: {
//                     Authorization: `Bearer ${token}`,
//                 },
//                 params: {
//                     hospitalcode: hospitalvalue,
//                     userrole: userrole1,
//                 },
//             });

//             if (res.status === 200) {
//                 setDoctor(res.data);
//             } else {
//                 alert(`Request failed with status: ${res.status}`);
//             }
//         } catch (error) {
//             if (error.request) {
//                 // toast.error("No response received from the server");
//             } else {
//                 toast.error("Error:", error.message);
//             }
//         }
//     };

//     const onSearch = (event, newValue) => {
//         setValue(newValue); // Update the value state with the selected value
//         onSearchChangeBed(newValue); // Notify the parent component of the selected value
//     };
//     const getOptionLabel = (option) => {
//         // Handle the case when option is null or undefined
//         return option?.bedname || '';
//     };
//     return (
//         <div className="searchbar">
//             <Autocomplete
//                 freeSolo
//                 id="free-solo"
//                 options={data}
//                 getOptionLabel={getOptionLabel}

//                 onChange={onSearch}
//                 value={value} // Pass the value state as value to Autocomplete
//                 renderInput={(params) => (
//                     <TextField
//                         {...params}
//                         InputProps={{
//                             ...params.InputProps,
//                             endAdornment: (
//                                 <InputAdornment position="end">
//                                     <IconButton onClick={params.inputProps.onClick}>
//                                         <ExpandMoreIcon />
//                                     </IconButton>
//                                 </InputAdornment>
//                             ),
//                         }}
//                     />
//                 )}
//                 renderOption={(props, option) => (
//                     <li {...props}>
//                        {option.bedname}/{option.bedtype}/{option.groupname}/{option.floorname }
//                     </li>
//                 )}
//             />
//         </div>
//     );
// }

// export default AvailableBedSearch;
import React, { useState, useEffect } from "react";
import axios from '../helper/axios';
import Autocomplete from "@mui/material/Autocomplete";
import {
    TextField,
    InputAdornment,
    IconButton,
} from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import { useUser } from "../Provider";

const AvailableBedSearch = ({ onSearchChangeBed, hospitalvalue }) => {
    const history = useHistory();
    const { userrole1, token } = useUser();
    const [selectedBed, setSelectedBed] = useState('');
    const [data, setBed] = useState([]);
    const [openAutocomplete, setOpenAutocomplete] = useState(false);

    useEffect(() => {
        const loadData = async () => {
            await getAvailableBedNumbers();
            setSelectedBed("")
        };

        loadData();
    }, [userrole1, hospitalvalue]);

    const getAvailableBedNumbers = async () => {
        try {
            if (hospitalvalue && userrole1) {
                const res = await axios.get(`/getAvailableBedNumbers`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                    params: {
                        hospitalcode: hospitalvalue,
                        userrole: userrole1,
                    },
                });

                if (res.status === 200) {
                    setBed(res.data);
                } else {
                    alert(`Request failed with status: ${res.status}`);
                }
            }
        } catch (error) {
            if (error.request) {
                // toast.error("No response received from the server");
            } else {
                toast.error("Error:", error.message);
            }
        }
    };

    const onSearch = (event, value) => {
        setSelectedBed(value);
        onSearchChangeBed(value);
    };

    const getOptionLabel = (option) => {
        return option?.bedname || '';
    };

    const handleExpandClick = () => {
        setOpenAutocomplete(!openAutocomplete);
    };

    return (
        <div className="searchbar">
            <Autocomplete
                freeSolo
                id="free-solo"
                options={data}
                getOptionLabel={getOptionLabel}
                value={selectedBed}
                onChange={onSearch}
                open={openAutocomplete}
                onOpen={() => setOpenAutocomplete(true)}
                onClose={() => setOpenAutocomplete(false)}
                renderInput={(params) =>
                    <TextField
                        {...params}
                        placeholder="Select Bed"
                        InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton onClick={handleExpandClick}>
                                        <ExpandMoreIcon />
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                }
                renderOption={(props, option) => (
                    <li {...props}>
                        {option.bedname}/{option.bedtype}/{option.groupname}/{option.floorname}
                    </li>
                )}
            />
        </div>
    );
}

export default AvailableBedSearch;
