
import React, { useState } from 'react';
import Sidebar from '../../Component/Sidebar';

import { NavLink } from 'react-router-dom';
import NewAppoinment from './AppointmentPages/NewAppoinment';
import Header from '../../Component/Header/Header';
import ListofAppointment from './AppointmentPages/ListofAppointment';
import RescheduleAppointment from './AppointmentPages/RescheduleAppointment';
import { UserProvider } from "../../Provider";
import EditAppointment from './AppointmentPages/EditAppointment';
export const Appointment = () => {
  return (

    <UserProvider> <div className=" ">
    <Header />
 <div className="row" style={{ marginTop: '48px' }}>
   {/* Sidebar */}
   <div className="col-md-2">
  <Sidebar /> 
      </div>
      {/* Main Content */}
      <div className="col-md-10">
      <ListofAppointment/>
     
      </div>
    </div>
  </div></UserProvider>
  );
};
export const NewAppointment = () => {
  return (
    <UserProvider>  <div className=" ">
    <Header />
 <div className="row" style={{ marginTop: '48px' }}>
   {/* Sidebar */}
   <div className="col-md-2">
    <Sidebar /> 
   </div>
    
      
      {/* Main Content */}
      <div className="col-md-10">
      
      <NewAppoinment/>
      
      </div>
    </div>
  </div></UserProvider>
  );
};
export const Appointmentedit = () => {
  return (
    <UserProvider><div className=" ">
    <Header />
 <div className="row" style={{ marginTop: '48px' }}>
   {/* Sidebar */}
   <div className="col-md-2">
   <Sidebar /> 
      </div>
      
      {/* Main Content */}
      <div className="col-md-10">
     
      <RescheduleAppointment/>
    
      </div>
    </div>
  </div></UserProvider>  
  );
};
export const AppointmentDetailsedit = () => {
  return (
    <UserProvider> <div className=" ">
       <Header />
    <div className="row" style={{ marginTop: '48px' }}>
      {/* Sidebar */}
      <div className="col-md-2">
       <Sidebar /> 
      </div>
      
      {/* Main Content */}
      <div className="col-md-10">
      
     <EditAppointment/>
     
      </div>
    </div>
  </div> </UserProvider>
  );
};